export const scores = [
  {
    name: "The Eagles",
    points: 33,
    shooters: [
      {
        name: "Samantha Williams",
        trid: 3876,
      },
      {
        name: "Maxwell Nguyen",
        trid: 3877,
      },
      {
        name: "Mia Patel",
        trid: 3878,
      },
      {
        name: "Joshua Kim",
        trid: 3879,
      },
      {
        name: "Ava Chen",
        trid: 3880,
      },
      {
        name: "Ethan Brown",
        trid: 3881,
      },
      {
        name: "Isabella Lee",
        trid: 3882,
      },
      {
        name: "Gabriel Garcia",
        trid: 3883,
      },
    ],
    previousPoints: [
      {
        date: 1620339043,
        points: 30,
      },
    ],
  },
  {
    name: "The Sharks",
    points: 20,
    shooters: [
      {
        name: "Olivia Davis",
        trid: 3884,
      },
      {
        name: "Noah Hernandez",
        trid: 3885,
      },
      {
        name: "Emma Clark",
        trid: 3886,
      },
      {
        name: "Liam Nguyen",
        trid: 3887,
      },
      {
        name: "Sophia Rodriguez",
        trid: 3888,
      },
      {
        name: "Jacob Martinez",
        trid: 3889,
      },
      {
        name: "Chloe Davis",
        trid: 3890,
      },
      {
        name: "William Smith",
        trid: 3891,
      },
    ],
    previousPoints: [
      {
        date: 1620339043,
        points: 25,
      },
    ],
  },
  {
    name: "The Lions",
    points: 25,
    shooters: [
      {
        name: "Emily Lee",
        trid: 3892,
      },
      {
        name: "Jayden Brown",
        trid: 3893,
      },
      {
        name: "Madison Lee",
        trid: 3894,
      },
      {
        name: "Daniel Nguyen",
        trid: 3895,
      },
      {
        name: "Avery Rodriguez",
        trid: 3896,
      },
      {
        name: "Elijah Kim",
        trid: 3897,
      },
      {
        name: "Aria Hernandez",
        trid: 3898,
      },
      {
        name: "Benjamin Garcia",
        trid: 3899,
      },
    ],
    previousPoints: [
      {
        date: 1620339043,
        points: 30,
      },
    ],
  },
  {
    name: "The Thunderbolts",
    points: 25,
    shooters: [
      {
        name: "Jacob Smith",
        trid: 3888,
      },
      {
        name: "Emily Johnson",
        trid: 3889,
      },
      {
        name: "Ryan Lee",
        trid: 3890,
      },
      {
        name: "Natalie Brown",
        trid: 3891,
      },
      {
        name: "Michael Davis",
        trid: 3892,
      },
      {
        name: "Hannah Wilson",
        trid: 3893,
      },
      {
        name: "Daniel Kim",
        trid: 3894,
      },
      {
        name: "Victoria Martinez",
        trid: 3895,
      },
    ],
    previousPoints: [
      {
        date: 1620339043,
        points: 20,
      },
    ],
  },
  {
    name: "The Vipers",
    points: 15,
    shooters: [
      {
        name: "Alexander Hernandez",
        trid: 3896,
      },
      {
        name: "Avery Lewis",
        trid: 3897,
      },
      {
        name: "Julia Taylor",
        trid: 3898,
      },
      {
        name: "William Scott",
        trid: 3899,
      },
      {
        name: "Sofia Perez",
        trid: 3900,
      },
      {
        name: "Jackson Davis",
        trid: 3901,
      },
      {
        name: "Evelyn Johnson",
        trid: 3902,
      },
      {
        name: "Brandon Rodriguez",
        trid: 3903,
      },
    ],
    previousPoints: [
      {
        date: 1620339043,
        points: 25,
      },
    ],
  },
  {
    name: "The Falcons",
    points: 10,
    shooters: [
      {
        name: "Samantha Garcia",
        trid: 3904,
      },
      {
        name: "Ethan Hernandez",
        trid: 3905,
      },
      {
        name: "Isabella Smith",
        trid: 3906,
      },
      {
        name: "Jacob Johnson",
        trid: 3907,
      },
      {
        name: "Avery Martin",
        trid: 3908,
      },
      {
        name: "Mia Davis",
        trid: 3909,
      },
      {
        name: "William Jones",
        trid: 3910,
      },
      {
        name: "Emma Wilson",
        trid: 3911,
      },
    ],
    previousPoints: [
      {
        date: 1620339043,
        points: 5,
      },
    ],
  },
];
export const shooterDetails = [
  {
    trid: 985,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 985,
    seed_name: "Parag Patel",
    first_name: "Parag",
    last_name: "Patel",
    email: "paraguk@gmail.com",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 1875000,
    seed: 1,
    seed_change: "-",
    ga_23: 13,
    ga_22: 1,
    ga_21: 1,
    q_23: "",
    q_22: 2,
    q_21: 7,
    stg_23: 3,
    stg_22: 37,
    stg_21: 1,
    points_ff23: 87,
  },
  {
    trid: 616,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 616,
    seed_name: "David Luckman",
    first_name: "David",
    last_name: "Luckman",
    email: "Davidluckman@hotmail.com",
    club: "Sedgemoor TSC",
    county: "Somerset",
    country: "England",
    class: "X",
    cost: 1550000,
    seed: 2,
    seed_change: "-",
    ga_23: 25,
    ga_22: 3,
    ga_21: 2,
    q_23: 24,
    q_22: 33,
    q_21: 2,
    stg_23: 2,
    stg_22: 1,
    stg_21: "",
    points_ff23: 120,
  },
  {
    trid: 256,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 256,
    seed_name: "Rick Shouler",
    first_name: "Rick",
    last_name: "Shouler",
    email: "Rickshouler@hotmail.com",
    club: "Surrey RA",
    county: "Nottinghamshire",
    country: "England",
    class: "X",
    cost: 1025000,
    seed: 3,
    seed_change: 1,
    ga_23: 16,
    ga_22: 30,
    ga_21: 11,
    q_23: 11,
    q_22: 31,
    q_21: 1,
    stg_23: 7,
    stg_22: 7,
    stg_21: 7,
    points_ff23: 92,
  },
  {
    trid: 84,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 84,
    seed_name: "Matthew Ensor",
    first_name: "Matthew",
    last_name: "Ensor",
    email: "Matthewensor@gmail.com",
    club: "Old Guildfordians RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 900000,
    seed: 4,
    seed_change: 6,
    ga_23: 8,
    ga_22: 8,
    ga_21: 5,
    q_23: 6,
    q_22: 21,
    q_21: "",
    stg_23: 10,
    stg_22: 20,
    stg_21: 17,
    points_ff23: 91,
  },
  {
    trid: 1404,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1404,
    seed_name: "Steve Negus",
    first_name: "Steve",
    last_name: "Negus",
    email: "Steve.negus@outlook.com",
    club: "Canberra RC",
    county: "",
    country: "Australia",
    class: "X",
    cost: 900000,
    seed: 5,
    seed_change: 56,
    ga_23: 1,
    ga_22: 20,
    ga_21: "",
    q_23: 8,
    q_22: 47,
    q_21: "",
    stg_23: 41,
    stg_22: 34,
    stg_21: "",
    points_ff23: 281,
  },
  {
    trid: 6562,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Neels Jonck",
    first_name: "Neels",
    last_name: "Jonck",
    email: "neels.jonck@gmail.com",
    club: "Wonderboom",
    county: "",
    country: "South Africa",
    class: "X",
    cost: 900000,
    seed: 6,
    seed_change: 22,
    ga_23: 4,
    ga_22: 14,
    ga_21: "",
    q_23: 2,
    q_22: 6,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 122,
  },
  {
    trid: 299,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 299,
    seed_name: "Chris Watson",
    first_name: "Chris",
    last_name: "Watson",
    email: "Humanscar@gmail.com",
    club: "Uppingham Veterans RC",
    county: "Northants, Leics, Rutland",
    country: "Wales",
    class: "X",
    cost: 800000,
    seed: 7,
    seed_change: 10,
    ga_23: "",
    ga_22: 44,
    ga_21: 23,
    q_23: 1,
    q_22: 28,
    q_21: 30,
    stg_23: "",
    stg_22: 49,
    stg_21: 3,
    points_ff23: 4,
  },
  {
    trid: 827,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Glyn Barnett",
    first_name: "Glyn",
    last_name: "Barnett",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 800000,
    seed: 8,
    seed_change: -2,
    ga_23: "",
    ga_22: 4,
    ga_21: 9,
    q_23: 28,
    q_22: 14,
    q_21: 6,
    stg_23: 5,
    stg_22: "",
    stg_21: 10,
    points_ff23: 0,
  },
  {
    trid: 769,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 769,
    seed_name: "Ant Ringer",
    first_name: "Ant",
    last_name: "Ringer",
    club: "Uppingham Veterans RC",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 750000,
    seed: 9,
    seed_change: 27,
    ga_23: 10,
    ga_22: 15,
    ga_21: "",
    q_23: "",
    q_22: 41,
    q_21: "",
    stg_23: 1,
    stg_22: 36,
    stg_21: 16,
    points_ff23: 69,
  },
  {
    trid: 3318,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3318,
    seed_name: "Chris Mitchell",
    first_name: "Chris",
    last_name: "Mitchell",
    email: "Chris.j.mitchell5@gmail.com",
    club: "Cardinals",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 725000,
    seed: 10,
    seed_change: -5,
    ga_23: 29,
    ga_22: 7,
    ga_21: 3,
    q_23: "",
    q_22: "",
    q_21: 10,
    stg_23: 12,
    stg_22: 23,
    stg_21: 5,
    points_ff23: 55,
  },
  {
    trid: 3862,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Alex Coetzee",
    first_name: "Alex",
    last_name: "Coetzee",
    email: "",
    club: "South Africa",
    county: "",
    country: "South Africa",
    class: "X",
    cost: 650000,
    seed: 11,
    seed_change: "-",
    ga_23: 11,
    ga_22: 2,
    ga_21: "",
    q_23: 43,
    q_22: 18,
    q_21: "",
    stg_23: 29,
    stg_22: 26,
    stg_21: "",
    points_ff23: 83,
  },
  {
    trid: 3357,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3357,
    seed_name: "Rosanne Furniss",
    first_name: "Rosanne",
    last_name: "Furniss",
    email: "Rosanne_furniss@yahoo.co.uk",
    club: "City RC",
    county: "Northumberland",
    country: "England",
    class: "X",
    cost: 650000,
    seed: 12,
    seed_change: -9,
    ga_23: "",
    ga_22: 17,
    ga_21: 45,
    q_23: "",
    q_22: 4,
    q_21: 3,
    stg_23: "",
    stg_22: 3,
    stg_21: 48,
    points_ff23: 25,
  },
  {
    trid: 2186,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2186,
    seed_name: "Matthew Millar",
    first_name: "Matthew",
    last_name: "Millar",
    email: "mrmmillar22@outlook.com",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 550000,
    seed: 13,
    seed_change: 62,
    ga_23: 15,
    ga_22: "",
    ga_21: "",
    q_23: 3,
    q_22: "",
    q_21: 38,
    stg_23: 20,
    stg_22: 28,
    stg_21: 20,
    points_ff23: 119,
  },
  {
    trid: 5163,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Mitchell  Bailey",
    first_name: "Mitchell ",
    last_name: "Bailey",
    club: "",
    county: "",
    country: "",
    class: "X",
    cost: 525000,
    seed: 14,
    seed_change: "-",
    ga_23: 2,
    ga_22: "",
    ga_21: "",
    q_23: 15,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 198,
  },
  {
    trid: 1241,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1241,
    seed_name: "Jim Corbett",
    first_name: "Jim",
    last_name: "Corbett",
    email: "",
    club: "NRA",
    county: "",
    country: "Australia",
    class: "X",
    cost: 525000,
    seed: 15,
    seed_change: 7,
    ga_23: 7,
    ga_22: 5,
    ga_21: "",
    q_23: "",
    q_22: 8,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 130,
  },
  {
    trid: 495,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 495,
    seed_name: "Nigel Ball",
    first_name: "Nigel",
    last_name: "Ball",
    email: "Njb5010@gmail.com",
    club: "Wellington College",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 500000,
    seed: 16,
    seed_change: -1,
    ga_23: 39,
    ga_22: 29,
    ga_21: 6,
    q_23: "",
    q_22: "",
    q_21: 26,
    stg_23: 11,
    stg_22: 12,
    stg_21: 8,
    points_ff23: 33,
  },
  {
    trid: 2386,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2386,
    seed_name: "Alastair Haley",
    first_name: "Alastair",
    last_name: "Haley",
    email: "alastair.haley@gmail.com",
    club: "Welsh RA",
    county: "Gloucestershire",
    country: "Wales",
    class: "X",
    cost: 500000,
    seed: 17,
    seed_change: -9,
    ga_23: "",
    ga_22: 39,
    ga_21: 7,
    q_23: "",
    q_22: 3,
    q_21: 13,
    stg_23: 36,
    stg_22: "",
    stg_21: 38,
    points_ff23: 21,
  },
  {
    trid: 558,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 558,
    seed_name: "John Bellringer",
    first_name: "John",
    last_name: "Bellringer",
    email: "Johnbellringer@btinternet.com ",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 450000,
    seed: 18,
    seed_change: 9,
    ga_23: 14,
    ga_22: 22,
    ga_21: "",
    q_23: "",
    q_22: 15,
    q_21: "",
    stg_23: 15,
    stg_22: 6,
    stg_21: "",
    points_ff23: 68,
  },
  {
    trid: 4584,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Theo Dodds",
    first_name: "Theo",
    last_name: "Dodds",
    club: "RNTRC",
    county: "Kent",
    country: "Wales",
    class: "X",
    cost: 450000,
    seed: 18,
    seed_change: -6,
    ga_23: "",
    ga_22: 6,
    ga_21: 4,
    q_23: 29,
    q_22: "",
    q_21: 40,
    stg_23: "",
    stg_22: "",
    stg_21: 21,
    points_ff23: 0,
  },
  {
    trid: 5673,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5673,
    seed_name: "Jemima Hince",
    first_name: "Jemima",
    last_name: "Hince",
    email: "Jemimahince1@gmail.com",
    club: "Old Wellingtonians",
    county: "Northants, Leics, Rutland",
    country: "England",
    class: "X",
    cost: 450000,
    seed: 20,
    seed_change: 12,
    ga_23: 9,
    ga_22: 11,
    ga_21: 26,
    q_23: 19,
    q_22: "",
    q_21: 47,
    stg_23: 45,
    stg_22: 44,
    stg_21: "",
    points_ff23: 75,
  },
  {
    trid: 3919,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3919,
    seed_name: "Chloe Evans",
    first_name: "Chloe",
    last_name: "Evans",
    email: "caevans93@gmail.com",
    club: "Welsh RA",
    county: "Norfolk",
    country: "Wales",
    class: "X",
    cost: 450000,
    seed: 21,
    seed_change: -14,
    ga_23: "",
    ga_22: 9,
    ga_21: 40,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 2,
    stg_21: 13,
    points_ff23: 10,
  },
  {
    trid: 6054,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6054,
    seed_name: "Alice Good",
    first_name: "Alice",
    last_name: "Good",
    club: "Cambridge University RA",
    county: "Sussex",
    country: "England",
    class: "X",
    cost: 400000,
    seed: 22,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 1,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 11,
  },
  {
    trid: 1185,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Jim Bailey",
    first_name: "Jim",
    last_name: "Bailey",
    email: "jimbailey06@outlook.com",
    club: "Australian Rifle Team",
    county: "",
    country: "Australia",
    class: "X",
    cost: 400000,
    seed: 22,
    seed_change: "-",
    ga_23: 3,
    ga_22: "",
    ga_21: "",
    q_23: 41,
    q_22: "",
    q_21: "",
    stg_23: 34,
    stg_22: "",
    stg_21: "",
    points_ff23: 152,
  },
  {
    trid: 4098,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4098,
    seed_name: "Michael Cooper",
    first_name: "Michael",
    last_name: "Cooper",
    email: "michael@michaelcooper.com",
    club: "Ulster SASC",
    county: "Ulster",
    country: "Ireland",
    class: "X",
    cost: 400000,
    seed: 24,
    seed_change: 6,
    ga_23: 6,
    ga_22: 13,
    ga_21: "",
    q_23: 42,
    q_22: 34,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 113,
  },
  {
    trid: 591,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 591,
    seed_name: "Jeremy Langley",
    first_name: "Jeremy",
    last_name: "Langley",
    email: "jdslangley@gmail.com",
    club: "Exonia & Tiverton RC",
    county: "Devon",
    country: "England",
    class: "X",
    cost: 350000,
    seed: 25,
    seed_change: "-",
    ga_23: 36,
    ga_22: "",
    ga_21: "",
    q_23: 4,
    q_22: "",
    q_21: "",
    stg_23: 24,
    stg_22: "",
    stg_21: "",
    points_ff23: 34,
  },
  {
    trid: 928,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 928,
    seed_name: "Chris Weeden",
    first_name: "Chris",
    last_name: "Weeden",
    email: "Chrisweeden01@byinternet.com",
    club: "NLRC",
    county: "Kent",
    country: "England",
    class: "X",
    cost: 325000,
    seed: 26,
    seed_change: 14,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 5,
    q_22: "",
    q_21: 5,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 10,
  },
  {
    trid: 878,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 878,
    seed_name: "Jon Underwood",
    first_name: "Jon",
    last_name: "Underwood",
    email: "Junderwoodgm@gmail.com",
    club: "Old Guildfordians RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 325000,
    seed: 27,
    seed_change: -18,
    ga_23: 31,
    ga_22: "",
    ga_21: 12,
    q_23: "",
    q_22: 43,
    q_21: 4,
    stg_23: "",
    stg_22: "",
    stg_21: 44,
    points_ff23: 43,
  },
  {
    trid: 3486,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3486,
    seed_name: "William Broad",
    first_name: "William",
    last_name: "Broad",
    email: "wjdbroad@gmail.com",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 325000,
    seed: 28,
    seed_change: "-",
    ga_23: 5,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 17,
    stg_22: "",
    stg_21: "",
    points_ff23: 142,
  },
  {
    trid: 1850,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1850,
    seed_name: "Graham Nelson",
    first_name: "Graham",
    last_name: "Nelson",
    email: "g.p.nelson@doctors.org.uk",
    club: "Manchester RC",
    county: "Northumberland",
    country: "England",
    class: "X",
    cost: 300000,
    seed: 29,
    seed_change: "-",
    ga_23: 42,
    ga_22: 10,
    ga_21: "",
    q_23: 36,
    q_22: 45,
    q_21: 15,
    stg_23: "",
    stg_22: 48,
    stg_21: 37,
    points_ff23: 30,
  },
  {
    trid: 6650,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 6650,
    seed_name: "Jason Di Bona",
    first_name: "Jason",
    last_name: "Di Bona",
    club: "South Africa",
    county: "",
    country: "South Africa",
    class: "X",
    cost: 300000,
    seed: 30,
    seed_change: "-",
    ga_23: 26,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 3,
    stg_22: "",
    stg_21: "",
    points_ff23: 50,
  },
  {
    trid: 278,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 278,
    seed_name: "Paul Sykes",
    first_name: "Paul",
    last_name: "Sykes",
    email: "paul@pdsykes.co.uk",
    club: "Bedfordians RC",
    county: "Sussex",
    country: "England",
    class: "X",
    cost: 300000,
    seed: 31,
    seed_change: -10,
    ga_23: 20,
    ga_22: "",
    ga_21: 14,
    q_23: "",
    q_22: 16,
    q_21: 34,
    stg_23: "",
    stg_22: 27,
    stg_21: "",
    points_ff23: 27,
  },
  {
    trid: 2944,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2944,
    seed_name: "Henryk Golaszewski",
    first_name: "Henryk",
    last_name: "Golaszewski",
    email: "H.gola@hotmail.Co.uk",
    club: "Welsh RA",
    county: "Somerset",
    country: "Wales",
    class: "X",
    cost: 300000,
    seed: 32,
    seed_change: -8,
    ga_23: "",
    ga_22: "",
    ga_21: 20,
    q_23: "",
    q_22: 38,
    q_21: 25,
    stg_23: "",
    stg_22: "",
    stg_21: 2,
    points_ff23: 15,
  },
  {
    trid: 1403,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1403,
    seed_name: "Donna Negus",
    first_name: "Donna",
    last_name: "Negus",
    email: "",
    club: "Bungendore",
    county: "",
    country: "Australia",
    class: "X",
    cost: 300000,
    seed: 33,
    seed_change: "-",
    ga_23: 17,
    ga_22: "",
    ga_21: "",
    q_23: 9,
    q_22: "",
    q_21: "",
    stg_23: 23,
    stg_22: "",
    stg_21: "",
    points_ff23: 56,
  },
  {
    trid: 5850,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5850,
    seed_name: "Luca O'Flynn",
    first_name: "Luca",
    last_name: "O'Flynn",
    email: "",
    club: "Old Guildfordians RC",
    county: "Surrey",
    country: "Ireland",
    class: "X",
    cost: 275000,
    seed: 34,
    seed_change: 40,
    ga_23: 33,
    ga_22: "",
    ga_21: "",
    q_23: 17,
    q_22: 12,
    q_21: "",
    stg_23: 22,
    stg_22: "",
    stg_21: "",
    points_ff23: 41,
  },
  {
    trid: 1045,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1045,
    seed_name: "David Calvert",
    first_name: "David",
    last_name: "Calvert",
    club: "RAFTRC",
    county: "Ulster",
    country: "Ireland",
    class: "X",
    cost: 275000,
    seed: 35,
    seed_change: -16,
    ga_23: "",
    ga_22: "",
    ga_21: 25,
    q_23: "",
    q_22: 13,
    q_21: 12,
    stg_23: "",
    stg_22: 10,
    stg_21: "",
    points_ff23: 21,
  },
  {
    trid: 1564,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1564,
    seed_name: "Adam Jory",
    first_name: "Adam",
    last_name: "Jory",
    email: "adam.jory@gmail.com",
    club: "Guernsey RC",
    county: "London",
    country: "Guernsey",
    class: "X",
    cost: 275000,
    seed: 36,
    seed_change: -5,
    ga_23: "",
    ga_22: 21,
    ga_21: "",
    q_23: 30,
    q_22: 10,
    q_21: 42,
    stg_23: "",
    stg_22: "",
    stg_21: 28,
    points_ff23: 10,
  },
  {
    trid: 5262,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5262,
    seed_name: "Thomas Shaw",
    first_name: "Thomas",
    last_name: "Shaw",
    email: "Tpshaw25@gmail.com",
    club: "Cardinals",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 250000,
    seed: 37,
    seed_change: 65,
    ga_23: 49,
    ga_22: "",
    ga_21: 42,
    q_23: 7,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 30,
    stg_21: "",
    points_ff23: 54,
  },
  {
    trid: 5793,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5793,
    seed_name: "Toby Cubitt",
    first_name: "Toby",
    last_name: "Cubitt",
    email: "",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 225000,
    seed: 38,
    seed_change: 17,
    ga_23: "",
    ga_22: 40,
    ga_21: "",
    q_23: 12,
    q_22: "",
    q_21: 8,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 657,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 657,
    seed_name: "Nick Mace",
    first_name: "Nick",
    last_name: "Mace",
    email: "Nick.c.mace@gmail.com",
    club: "Guernsey RC",
    county: "Hampshire",
    country: "Guernsey",
    class: "X",
    cost: 200000,
    seed: 39,
    seed_change: 4,
    ga_23: "",
    ga_22: "",
    ga_21: 17,
    q_23: "",
    q_22: "",
    q_21: 31,
    stg_23: 19,
    stg_22: "",
    stg_21: 11,
    points_ff23: 7,
  },
  {
    trid: 248,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 248,
    seed_name: "Rob Sandlant",
    first_name: "Rob",
    last_name: "Sandlant",
    club: "Australia",
    county: "",
    country: "Australia",
    class: "X",
    cost: 200000,
    seed: 39,
    seed_change: "-",
    ga_23: 18,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 9,
    stg_22: "",
    stg_21: "",
    points_ff23: 73,
  },
  {
    trid: 5860,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Nicole Rossignol",
    first_name: "Nicole",
    last_name: "Rossignol",
    email: "",
    club: "Canada",
    county: "",
    country: "Canada",
    class: "X",
    cost: 200000,
    seed: 41,
    seed_change: -8,
    ga_23: "",
    ga_22: 12,
    ga_21: "",
    q_23: "",
    q_22: 24,
    q_21: "",
    stg_23: "",
    stg_22: 19,
    stg_21: "",
    points_ff23: 14,
  },
  {
    trid: 3230,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Matt Purdy",
    first_name: "Matt",
    last_name: "Purdy",
    email: "mattpurdy@hotmail.co.uk",
    club: "ATSC",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 200000,
    seed: 42,
    seed_change: 17,
    ga_23: "",
    ga_22: "",
    ga_21: 39,
    q_23: 26,
    q_22: 36,
    q_21: 14,
    stg_23: 40,
    stg_22: "",
    stg_21: "",
    points_ff23: 16,
  },
  {
    trid: 2936,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2936,
    seed_name: "Mark Buchanan",
    first_name: "Mark",
    last_name: "Buchanan",
    email: "m.buchanan308@gmail.com",
    club: "Roseville",
    county: "",
    country: "Australia",
    class: "X",
    cost: 200000,
    seed: 43,
    seed_change: -9,
    ga_23: "",
    ga_22: 16,
    ga_21: "",
    q_23: "",
    q_22: 19,
    q_21: "",
    stg_23: "",
    stg_22: 18,
    stg_21: "",
    points_ff23: 15,
  },
  {
    trid: 1021,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1021,
    seed_name: "Matthew Button",
    first_name: "Matthew",
    last_name: "Button",
    email: "Matthewbutton@gmail.com",
    club: "Wandsworth FBRC",
    county: "Lincolnshire",
    country: "England",
    class: "X",
    cost: 200000,
    seed: 44,
    seed_change: -24,
    ga_23: "",
    ga_22: 32,
    ga_21: "",
    q_23: 21,
    q_22: "",
    q_21: 29,
    stg_23: "",
    stg_22: 29,
    stg_21: 40,
    points_ff23: 12,
  },
  {
    trid: 1979,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1979,
    seed_name: "Toby Raincock",
    first_name: "Toby",
    last_name: "Raincock",
    email: "tobster2010@googlemail.com",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 200000,
    seed: 45,
    seed_change: -31,
    ga_23: "",
    ga_22: 23,
    ga_21: 30,
    q_23: "",
    q_22: "",
    q_21: 21,
    stg_23: "",
    stg_22: "",
    stg_21: 12,
    points_ff23: 2,
  },
  {
    trid: 3903,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Guy Hart",
    first_name: "Guy",
    last_name: "Hart",
    club: "ATSC",
    county: "London",
    country: "Ireland",
    class: "X",
    cost: 200000,
    seed: 46,
    seed_change: -11,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 32,
    q_21: "",
    stg_23: "",
    stg_22: 4,
    stg_21: "",
    points_ff23: 20,
  },
  {
    trid: 3868,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3868,
    seed_name: "Hannah Cook",
    first_name: "Hannah",
    last_name: "Cook",
    club: "OWRC",
    county: "Devon",
    country: "England",
    class: "X",
    cost: 175000,
    seed: 47,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 5,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 34,
    points_ff23: 7,
  },
  {
    trid: 771,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 771,
    seed_name: "Kelvin Ramsey",
    first_name: "Kelvin",
    last_name: "Ramsey",
    email: "kr@kelvinramsey.co.uk",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 175000,
    seed: 48,
    seed_change: -6,
    ga_23: 22,
    ga_22: "",
    ga_21: 21,
    q_23: "",
    q_22: "",
    q_21: 44,
    stg_23: 35,
    stg_22: "",
    stg_21: "",
    points_ff23: 70,
  },
  {
    trid: 3136,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3136,
    seed_name: "Jack Alexander",
    first_name: "Jack",
    last_name: "Alexander",
    email: "Fullboreplumbing@hotmail.com ",
    club: "Dungannon",
    county: "Co Tyrone",
    country: "Ireland",
    class: "X",
    cost: 175000,
    seed: 49,
    seed_change: 41,
    ga_23: 45,
    ga_22: 38,
    ga_21: "",
    q_23: 25,
    q_22: 46,
    q_21: "",
    stg_23: 33,
    stg_22: "",
    stg_21: "",
    points_ff23: 30,
  },
  {
    trid: 951,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 951,
    seed_name: "David Young",
    first_name: "David",
    last_name: "Young",
    email: "davidyoung.architect@gmail.com",
    club: "Manchester RC",
    county: "Lancashire",
    country: "England",
    class: "X",
    cost: 175000,
    seed: 50,
    seed_change: 57,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 10,
    q_22: 23,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 1124,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1124,
    seed_name: "David Armstrong",
    first_name: "David",
    last_name: "Armstrong",
    email: "milko@armstrong.uk.com",
    club: "Old Guildfordians RC",
    county: "London",
    country: "England",
    class: "X",
    cost: 175000,
    seed: 51,
    seed_change: -13,
    ga_23: "",
    ga_22: 28,
    ga_21: "",
    q_23: 33,
    q_22: 30,
    q_21: "",
    stg_23: "",
    stg_22: 31,
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 976,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 976,
    seed_name: "Pete Holden",
    first_name: "Pete",
    last_name: "Holden",
    email: "Peteh@angliafreight.co.uk",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 175000,
    seed: 52,
    seed_change: 25,
    ga_23: 48,
    ga_22: "",
    ga_21: 35,
    q_23: 16,
    q_22: "",
    q_21: 39,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 16,
  },
  {
    trid: 5613,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5613,
    seed_name: "Shaun Wingrove",
    first_name: "Shaun",
    last_name: "Wingrove",
    email: "Shaunwingrove@hotmail.com",
    club: "Canberra RC",
    county: "",
    country: "Australia",
    class: "X",
    cost: 175000,
    seed: 53,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 18,
    q_22: "",
    q_21: "",
    stg_23: 13,
    stg_22: "",
    stg_21: "",
    points_ff23: 8,
  },
  {
    trid: 2507,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2507,
    seed_name: "Robert Oxford",
    first_name: "Robert",
    last_name: "Oxford",
    email: "robert.fantasyfullbore@oxford.wales",
    club: "Welsh RA",
    county: "Kent",
    country: "Wales",
    class: "X",
    cost: 150000,
    seed: 54,
    seed_change: -28,
    ga_23: "",
    ga_22: 33,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 16,
    stg_23: "",
    stg_22: 14,
    stg_21: "",
    points_ff23: 37,
  },
  {
    trid: 6536,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "William Cooper",
    first_name: "William",
    last_name: "Cooper",
    club: "RAFTRC",
    county: "Gloucestershire",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 55,
    seed_change: 78,
    ga_23: 30,
    ga_22: "",
    ga_21: "",
    q_23: 22,
    q_22: 35,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 23,
  },
  {
    trid: 3080,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3080,
    seed_name: "Simon Hayton",
    first_name: "Simon",
    last_name: "Hayton",
    email: "simon_hayton@hotmail.com",
    club: "Old Cranleighans",
    county: "London",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 56,
    seed_change: 69,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 29,
    q_21: "",
    stg_23: 8,
    stg_22: "",
    stg_21: "",
    points_ff23: 16,
  },
  {
    trid: 891,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 891,
    seed_name: "John Warburton",
    first_name: "John",
    last_name: "Warburton",
    email: "Jdwarburton@btinternet.com",
    club: "Huddersfield RC",
    county: "Yorkshire",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 57,
    seed_change: -34,
    ga_23: 41,
    ga_22: "",
    ga_21: 34,
    q_23: "",
    q_22: 22,
    q_21: 37,
    stg_23: "",
    stg_22: 50,
    stg_21: "",
    points_ff23: 23,
  },
  {
    trid: 874,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 874,
    seed_name: "Jeremy Tuck",
    first_name: "Jeremy",
    last_name: "Tuck",
    email: "tuck@themaples.co.uk",
    club: "RNTRC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 58,
    seed_change: 22,
    ga_23: 27,
    ga_22: 35,
    ga_21: "",
    q_23: "",
    q_22: 40,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 32,
    points_ff23: 66,
  },
  {
    trid: 1820,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 1820,
    seed_name: "Ed Jeens",
    first_name: "Ed",
    last_name: "Jeens",
    club: "Welsh RA",
    county: "London",
    country: "Wales",
    class: "X",
    cost: 150000,
    seed: 59,
    seed_change: -19,
    ga_23: "",
    ga_22: "",
    ga_21: 18,
    q_23: "",
    q_22: 11,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 2551,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2551,
    seed_name: "Tom Drysdale",
    first_name: "Tom",
    last_name: "Drysdale",
    email: "tomdrysdale@gmail.com",
    club: "City of Newcastle RC",
    county: "County of Durham",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 60,
    seed_change: -44,
    ga_23: "",
    ga_22: 34,
    ga_21: 10,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 46,
    stg_22: "",
    stg_21: "",
    points_ff23: 2,
  },
  {
    trid: 658,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 658,
    seed_name: "Martin Millar",
    first_name: "Martin",
    last_name: "Millar",
    club: "Comber RC",
    county: "Ulster",
    country: "Ireland",
    class: "X",
    cost: 150000,
    seed: 61,
    seed_change: -3,
    ga_23: 40,
    ga_22: "",
    ga_21: 29,
    q_23: "",
    q_22: "",
    q_21: 11,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 48,
  },
  {
    trid: 1291,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1291,
    seed_name: "Sandy Gill",
    first_name: "Sandy",
    last_name: "Gill",
    club: "ATSC",
    county: "Hampshire",
    country: "Scotland",
    class: "X",
    cost: 150000,
    seed: 62,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 6,
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 2222,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Dan Richardson",
    first_name: "Dan",
    last_name: "Richardson",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    class: "X",
    cost: 150000,
    seed: 63,
    seed_change: -19,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 47,
    q_22: 9,
    q_21: "",
    stg_23: 48,
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 190,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 190,
    seed_name: "Jane Messer",
    first_name: "Jane",
    last_name: "Messer",
    email: "Janepalma2015@gmail.com",
    club: "NLRC",
    county: "Sussex",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 64,
    seed_change: -13,
    ga_23: "",
    ga_22: 36,
    ga_21: "",
    q_23: 49,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 6,
    points_ff23: 5,
  },
  {
    trid: 2935,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 2935,
    seed_name: "Dan Blake",
    first_name: "Dan",
    last_name: "Blake",
    club: "LMRA",
    county: "London",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 65,
    seed_change: 82,
    ga_23: 19,
    ga_22: 45,
    ga_21: "",
    q_23: 44,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 40,
  },
  {
    trid: 342,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 342,
    seed_name: "Charles Dickenson",
    first_name: "Charles",
    last_name: "Dickenson",
    email: "Charles.dickenson@hotmail.co.uk",
    club: "Windsor RC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 150000,
    seed: 66,
    seed_change: 11,
    ga_23: 28,
    ga_22: 18,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 27,
  },
  {
    trid: 614,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 614,
    seed_name: "David Le Quesne",
    first_name: "David",
    last_name: "Le Quesne",
    email: "",
    club: "Jersey RC",
    county: "",
    country: "Jersey",
    class: "X",
    cost: 125000,
    seed: 67,
    seed_change: "-",
    ga_23: 12,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 99,
  },
  {
    trid: 2181,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Ross McQuillan",
    first_name: "Ross",
    last_name: "McQuillan",
    club: "Ulster RA",
    county: "Ulster",
    country: "Ireland",
    class: "X",
    cost: 125000,
    seed: 68,
    seed_change: -16,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 5,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5071,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5071,
    seed_name: "Nick Kerins",
    first_name: "Nick",
    last_name: "Kerins",
    club: "Guernsey RC",
    county: "Northumberland",
    country: "Guernsey",
    class: "X",
    cost: 125000,
    seed: 69,
    seed_change: -16,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 7,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 6466,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6466,
    seed_name: "Rory Mace",
    first_name: "Rory",
    last_name: "Mace",
    email: "rory.j.mace@gmail.com",
    club: "Bradfield College",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 70,
    seed_change: 67,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 14,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 32,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 1175,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1175,
    seed_name: "Ed Welford",
    first_name: "Ed",
    last_name: "Welford",
    club: "OWRC",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 71,
    seed_change: -17,
    ga_23: "",
    ga_22: 37,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 23,
    stg_23: "",
    stg_22: "",
    stg_21: 14,
    points_ff23: 5,
  },
  {
    trid: 5794,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5794,
    seed_name: "Christina Cuming",
    first_name: "Christina",
    last_name: "Cuming",
    email: "Chc022@outlook.com",
    club: "NLRC",
    county: "Yorkshire",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 72,
    seed_change: -25,
    ga_23: "",
    ga_22: 19,
    ga_21: "",
    q_23: 32,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 862,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 862,
    seed_name: "Nick Tremlett",
    first_name: "Nick",
    last_name: "Tremlett",
    email: "Vcpalma23@gmail.com",
    club: "Windsor RC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 73,
    seed_change: -25,
    ga_23: "",
    ga_22: "",
    ga_21: 27,
    q_23: 35,
    q_22: "",
    q_21: 22,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 718,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 718,
    seed_name: "Keith Pugh",
    first_name: "Keith",
    last_name: "Pugh",
    club: "City of Newcastle RC",
    county: "Northumberland",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 74,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: 15,
    q_23: 31,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 7,
  },
  {
    trid: 749,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 749,
    seed_name: "Bill Richards",
    first_name: "Bill",
    last_name: "Richards",
    email: "Whizzybill@gmail.com",
    club: "Gunroom Lizards",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 75,
    seed_change: "-",
    ga_23: 24,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 30,
    stg_22: "",
    stg_21: "",
    points_ff23: 39,
  },
  {
    trid: 5015,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Michael Bumford",
    first_name: "Michael",
    last_name: "Bumford",
    email: "Michael.bumford@hotmail.co.uk",
    club: "Welsh RA",
    county: "Gloucestershire",
    country: "Wales",
    class: "X",
    cost: 125000,
    seed: 75,
    seed_change: 6,
    ga_23: "",
    ga_22: 50,
    ga_21: 28,
    q_23: "",
    q_22: "",
    q_21: 48,
    stg_23: 39,
    stg_22: 43,
    stg_21: "",
    points_ff23: 2,
  },
  {
    trid: 240,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 240,
    seed_name: "David Rose",
    first_name: "David",
    last_name: "Rose",
    email: "D_a_rose@yahoo.co.uk",
    club: "Old Epsomian RC",
    county: "Northants, Leics, Rutland",
    country: "England",
    class: "X",
    cost: 125000,
    seed: 77,
    seed_change: -12,
    ga_23: 50,
    ga_22: "",
    ga_21: 32,
    q_23: "",
    q_22: 26,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 45,
    points_ff23: 34,
  },
  {
    trid: 597,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 597,
    seed_name: "Andrew Le Cheminant",
    first_name: "Andrew",
    last_name: "Le Cheminant",
    email: "andrewlechem@gmail.com",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    class: "X",
    cost: 100000,
    seed: 78,
    seed_change: "-",
    ga_23: 34,
    ga_22: "",
    ga_21: "",
    q_23: 40,
    q_22: "",
    q_21: "",
    stg_23: 42,
    stg_22: "",
    stg_21: "",
    points_ff23: 25,
  },
  {
    trid: 4583,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Patrick Costello",
    first_name: "Patrick",
    last_name: "Costello",
    email: "Patrick.Costello@live.com",
    club: "NLRC",
    county: "West of Scotland",
    country: "Scotland",
    class: "X",
    cost: 100000,
    seed: 79,
    seed_change: -13,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 50,
    q_21: "",
    stg_23: "",
    stg_22: 8,
    stg_21: "",
    points_ff23: 3,
  },
  {
    trid: 5833,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5833,
    seed_name: "Jack Keates",
    first_name: "Jack",
    last_name: "Keates",
    club: "ATSC",
    county: "Northants, Leics, Rutland",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 80,
    seed_change: -18,
    ga_23: "",
    ga_22: "",
    ga_21: 8,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 9,
  },
  {
    trid: 619,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 619,
    seed_name: "Colin Mallett",
    first_name: "Colin",
    last_name: "Mallett",
    email: "colin.c.mallett@gmail.com",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    class: "X",
    cost: 100000,
    seed: 81,
    seed_change: 58,
    ga_23: "",
    ga_22: "",
    ga_21: 38,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 14,
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 765,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Tom Rylands",
    first_name: "Tom",
    last_name: "Rylands",
    club: "Manchester RC",
    county: "Cheshire",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 81,
    seed_change: -17,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 18,
    stg_23: "",
    stg_22: 33,
    stg_21: 25,
    points_ff23: 0,
  },
  {
    trid: 1625,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1625,
    seed_name: "James Postle",
    first_name: "James",
    last_name: "Postle",
    email: "postle.j@millfieldschool.com",
    club: "Old Epsomian RC",
    county: "Gloucestershire",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 83,
    seed_change: -26,
    ga_23: "",
    ga_22: 25,
    ga_21: 22,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 3717,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Rupert Knight",
    first_name: "Rupert",
    last_name: "Knight",
    club: "ATSC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 83,
    seed_change: "-",
    ga_23: 21,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 44,
    stg_22: "",
    stg_21: "",
    points_ff23: 38,
  },
  {
    trid: 6066,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: " Horsnell",
    last_name: "Horsnell",
    club: "Katandra RC",
    county: "",
    country: "Australia",
    class: "X",
    cost: 100000,
    seed: 85,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 13,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 10,
  },
  {
    trid: 544,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 544,
    seed_name: "Simon Belither",
    first_name: "Simon",
    last_name: "Belither",
    email: "sbelither@live.co.uk",
    club: "Uppingham Veterans RC",
    county: "Essex",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 86,
    seed_change: -17,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 42,
    q_21: "",
    stg_23: "",
    stg_22: 13,
    stg_21: 46,
    points_ff23: 0,
  },
  {
    trid: 3959,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3959,
    seed_name: "Henry Awin",
    first_name: "Henry",
    last_name: "Awin",
    email: "henry@awin.org.uk",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "Ireland",
    class: "X",
    cost: 100000,
    seed: 87,
    seed_change: -41,
    ga_23: "",
    ga_22: "",
    ga_21: 41,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 11,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5462,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Charlie Dart",
    first_name: "Charlie",
    last_name: "Dart",
    club: "Cardinals",
    county: "Berkshire",
    country: "Ireland",
    class: "X",
    cost: 100000,
    seed: 88,
    seed_change: -70,
    ga_23: "",
    ga_22: "",
    ga_21: 13,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 33,
    points_ff23: 0,
  },
  {
    trid: 5836,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Clifford Kiptarus",
    first_name: "Clifford",
    last_name: "Kiptarus",
    club: "Kenya RA",
    county: "",
    country: "Kenya",
    class: "X",
    cost: 100000,
    seed: 89,
    seed_change: 8,
    ga_23: 43,
    ga_22: 24,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 23,
  },
  {
    trid: 326,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 326,
    seed_name: "Jon Cload",
    first_name: "Jon",
    last_name: "Cload",
    club: "Surrey RA",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 90,
    seed_change: -19,
    ga_23: "",
    ga_22: "",
    ga_21: 16,
    q_23: "",
    q_22: "",
    q_21: 32,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5845,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: " Mulholland",
    last_name: "Mulholland",
    club: "Victoria College",
    county: "Jersey",
    country: "Jersey",
    class: "X",
    cost: 100000,
    seed: 91,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 15,
    stg_21: 22,
    points_ff23: 0,
  },
  {
    trid: 5028,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Amie Clarke",
    first_name: "Amie",
    last_name: "Clarke",
    club: "Surrey RA",
    county: "Nottinghamshire",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 92,
    seed_change: -47,
    ga_23: "",
    ga_22: "",
    ga_21: 48,
    q_23: "",
    q_22: "",
    q_21: 28,
    stg_23: "",
    stg_22: "",
    stg_21: 15,
    points_ff23: 0,
  },
  {
    trid: 896,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 896,
    seed_name: "John Webster",
    first_name: "John",
    last_name: "Webster",
    email: "Jwebster@me.com",
    club: "Uppingham Veterans RC",
    county: "Cheshire",
    country: "England",
    class: "X",
    cost: 100000,
    seed: 93,
    seed_change: -11,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 9,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 957,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Rob Lygoe",
    first_name: "Rob",
    last_name: "Lygoe",
    email: "fiatx19@hotmail.com",
    club: "Dursley",
    county: "Gloucestershire",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 94,
    seed_change: -44,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 16,
    stg_22: "",
    stg_21: 41,
    points_ff23: 27,
  },
  {
    trid: 2733,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2733,
    seed_name: "Sandy Walker",
    first_name: "Sandy",
    last_name: "Walker",
    email: "anr.walker@rocketmail.com",
    club: "Old Framlinghamians RC",
    county: "Surrey",
    country: "Scotland",
    class: "X",
    cost: 75000,
    seed: 94,
    seed_change: -31,
    ga_23: "",
    ga_22: 47,
    ga_21: 19,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 49,
    points_ff23: 5,
  },
  {
    trid: 468,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 468,
    seed_name: "Robin Hatcher",
    first_name: "Robin",
    last_name: "Hatcher",
    club: "Bedfordians RC",
    county: "Suffolk",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 96,
    seed_change: 59,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 18,
    stg_22: 42,
    stg_21: "",
    points_ff23: 4,
  },
  {
    trid: 2756,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Ed Compton",
    first_name: "Ed",
    last_name: "Compton",
    club: "Sussex CRA",
    county: "Sussex",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 97,
    seed_change: -41,
    ga_23: "",
    ga_22: 26,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 33,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 845,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 845,
    seed_name: "Rick Stewart",
    first_name: "Rick",
    last_name: "Stewart",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 98,
    seed_change: -14,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 9,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 3138,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3138,
    seed_name: "Oliver Spencer",
    first_name: "Oliver",
    last_name: "Spencer",
    email: "email@oliverspencer.com",
    club: "Manchester RC",
    county: "Cheshire",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 99,
    seed_change: -74,
    ga_23: "",
    ga_22: "",
    ga_21: 31,
    q_23: "",
    q_22: "",
    q_21: 41,
    stg_23: "",
    stg_22: "",
    stg_21: 29,
    points_ff23: 34,
  },
  {
    trid: 32,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 32,
    seed_name: "Peter Bromley",
    first_name: "Peter",
    last_name: "Bromley",
    club: "Old Sedberghians",
    county: "Kent",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 100,
    seed_change: "-",
    ga_23: 23,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 62,
  },
  {
    trid: 2127,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2127,
    seed_name: "Nick Healy",
    first_name: "Nick",
    last_name: "Healy",
    email: "N1ck@me.com",
    club: "ATSC",
    county: "Ulster",
    country: "Ireland",
    class: "X",
    cost: 75000,
    seed: 101,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: 33,
    q_23: "",
    q_22: "",
    q_21: 20,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 26,
  },
  {
    trid: 5634,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5634,
    seed_name: "Anthony Bromley",
    first_name: "Anthony",
    last_name: "Bromley",
    club: "Old Suttonians",
    county: "Kent",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 102,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 20,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 984,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 984,
    seed_name: "Simon Harding",
    first_name: "Simon",
    last_name: "Harding",
    club: "Surrey RA",
    county: "Kent",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 103,
    seed_change: "-",
    ga_23: 37,
    ga_22: "",
    ga_21: "",
    q_23: 48,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 36,
  },
  {
    trid: 748,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 748,
    seed_name: "David Richards",
    first_name: "David",
    last_name: "Richards",
    email: "davidr@capreolusfinefoods.co.uk",
    club: "Old Marlburian RC",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 104,
    seed_change: -8,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 17,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 2353,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2353,
    seed_name: "Edward Dickson",
    first_name: "Edward",
    last_name: "Dickson",
    email: "ecdickson@googlemail.com",
    club: "Old Marlburian RC",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 104,
    seed_change: -9,
    ga_23: "",
    ga_22: 46,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 35,
    stg_23: "",
    stg_22: 41,
    stg_21: "",
    points_ff23: 13,
  },
  {
    trid: 722,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 722,
    seed_name: "Jon Pugsley",
    first_name: "Jon",
    last_name: "Pugsley",
    club: "NRA",
    county: "Devon",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 106,
    seed_change: -19,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 9,
    points_ff23: 0,
  },
  {
    trid: 1024,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 1024,
    seed_name: "Richard Birtwistle",
    first_name: "Richard",
    last_name: "Birtwistle",
    club: "Manydown RC",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 75000,
    seed: 107,
    seed_change: -19,
    ga_23: "",
    ga_22: 31,
    ga_21: 47,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 1630,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 1630,
    seed_name: "Bruce Logan",
    first_name: "Bruce",
    last_name: "Logan",
    club: "Wandsworth FBRC",
    county: "West of Scotland",
    country: "Scotland",
    class: "X",
    cost: 75000,
    seed: 108,
    seed_change: 12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 23,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 4,
  },
  {
    trid: 6092,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Annabel Morriss",
    first_name: "Annabel",
    last_name: "Morriss",
    club: "NLRC",
    county: "London",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 109,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 20,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 543,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 543,
    seed_name: "Peter Jory",
    first_name: "Peter",
    last_name: "Jory",
    club: "Guernsey RC",
    county: "",
    country: "Guernsey",
    class: "X",
    cost: 50000,
    seed: 110,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 16,
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 3690,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 3690,
    seed_name: "Claire Halleran",
    first_name: "Claire",
    last_name: "Halleran",
    club: "West of Scotland RC",
    county: "West of Scotland",
    country: "Scotland",
    class: "X",
    cost: 50000,
    seed: 110,
    seed_change: 7,
    ga_23: 32,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 43,
  },
  {
    trid: 970,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 970,
    seed_name: "Rob Kitson",
    first_name: "Rob",
    last_name: "Kitson",
    club: "ATSC",
    county: "Oxfordshire",
    country: "Northern Ireland",
    class: "X",
    cost: 50000,
    seed: 110,
    seed_change: -43,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 21,
    stg_22: "",
    stg_21: "",
    points_ff23: 12,
  },
  {
    trid: 4394,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4394,
    seed_name: "James Shepherd",
    first_name: "James",
    last_name: "Shepherd",
    email: "jbg.shepherd@gmail.com",
    club: "Old Guildfordians RC",
    county: "London",
    country: "Scotland",
    class: "X",
    cost: 50000,
    seed: 113,
    seed_change: -24,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 37,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 30,
    points_ff23: 0,
  },
  {
    trid: 4285,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4285,
    seed_name: "Seth Dowley",
    first_name: "Seth",
    last_name: "Dowley",
    email: "dowley.saj@gmail.com",
    club: "Surrey RA",
    county: "Northants, Leics, Rutland",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 114,
    seed_change: 40,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 27,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 3,
  },
  {
    trid: 5556,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5556,
    seed_name: "Tobias Little",
    first_name: "Tobias",
    last_name: "Little",
    club: "OGRE",
    county: "Norfolk",
    country: "Wales",
    class: "X",
    cost: 50000,
    seed: 115,
    seed_change: -12,
    ga_23: "",
    ga_22: 27,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 7,
  },
  {
    trid: 3336,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Harriett Bennett",
    first_name: "Harriett",
    last_name: "Bennett",
    club: "ATSC",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 115,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 17,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 6224,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Sophie Hodge",
    first_name: "Sophie",
    last_name: "Hodge",
    club: "OWRC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 117,
    seed_change: 38,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 37,
    q_22: "",
    q_21: "",
    stg_23: 49,
    stg_22: "",
    stg_21: "",
    points_ff23: 10,
  },
  {
    trid: 1942,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Phillip Chapman-Sheath",
    first_name: "Phillip",
    last_name: "Chapman-Sheath",
    club: "NLRC",
    county: "Lincolnshire",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 118,
    seed_change: 13,
    ga_23: 35,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 12,
  },
  {
    trid: 3216,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3216,
    seed_name: "Scott Glanville",
    first_name: "Scott",
    last_name: "Glanville",
    club: "RAF Air Cmd",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 119,
    seed_change: -27,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 24,
    stg_23: "",
    stg_22: "",
    stg_21: 43,
    points_ff23: 21,
  },
  {
    trid: 852,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 852,
    seed_name: "Jon Taylor",
    first_name: "Jon",
    last_name: "Taylor",
    club: "Windsor RC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 120,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 17,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 13,
  },
  {
    trid: 5313,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5313,
    seed_name: "Jonathan Brooking",
    first_name: "Jonathan",
    last_name: "Brooking",
    club: "NLRC",
    county: "Sussex",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 121,
    seed_change: -9,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 25,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 6342,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 6342,
    seed_name: "Ryan Warren",
    first_name: "Ryan",
    last_name: "Warren",
    club: "LMRA",
    county: "London",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 122,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 25,
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 154,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Colin Johnston",
    first_name: "Colin",
    last_name: "Johnston",
    club: "ATSC",
    county: "Sussex",
    country: "Ireland",
    class: "X",
    cost: 50000,
    seed: 123,
    seed_change: -7,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 21,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 149,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Richard Jeens",
    first_name: "Richard",
    last_name: "Jeens",
    club: "Welsh RA",
    county: "London",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 124,
    seed_change: -75,
    ga_23: "",
    ga_22: "",
    ga_21: 37,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 39,
    points_ff23: 0,
  },
  {
    trid: 3870,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Laurynne Sanford Otundo",
    first_name: "Laurynne Sanford",
    last_name: "Otundo",
    club: "Kenya RA",
    county: "",
    country: "Kenya",
    class: "X",
    cost: 50000,
    seed: 125,
    seed_change: "-",
    ga_23: 38,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 27,
  },
  {
    trid: 966,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 966,
    seed_name: "Charles Brook",
    first_name: "Charles",
    last_name: "Brook",
    club: "LMRA",
    county: "Wiltshire",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 125,
    seed_change: 51,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 26,
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 1387,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1387,
    seed_name: "Faisal Mohideen",
    first_name: "Faisal",
    last_name: "Mohideen",
    club: "Canada",
    county: "",
    country: "Canada",
    class: "X",
    cost: 50000,
    seed: 125,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 27,
    stg_22: "",
    stg_21: "",
    points_ff23: 4,
  },
  {
    trid: 107,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 107,
    seed_name: " Gauvin",
    last_name: "Gauvin",
    club: "Canada",
    county: "",
    country: "Canada",
    class: "X",
    cost: 50000,
    seed: 128,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 27,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 2806,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 2806,
    seed_name: "Archie Whicher",
    first_name: "Archie",
    last_name: "Whicher",
    club: "Old Guildfordians RC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 129,
    seed_change: -57,
    ga_23: "",
    ga_22: "",
    ga_21: 24,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 2877,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2877,
    seed_name: "Jim Paton",
    first_name: "Jim",
    last_name: "Paton",
    club: "Canada",
    county: "",
    country: "Canada",
    class: "X",
    cost: 50000,
    seed: 130,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 34,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5225,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Katie Milner",
    first_name: "Katie",
    last_name: "Milner",
    club: "City of Newcastle RC",
    county: "Northumberland",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 130,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 28,
    stg_22: "",
    stg_21: "",
    points_ff23: 16,
  },
  {
    trid: 876,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 876,
    seed_name: "Andy Thomson",
    first_name: "Andy",
    last_name: "Thomson",
    club: "West Suffolk RC",
    county: "Suffolk",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 132,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 22,
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 999,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 999,
    seed_name: "Angus McLeod",
    first_name: "Angus",
    last_name: "McLeod",
    club: "ATSC",
    county: "East of Scotland",
    country: "Scotland",
    class: "X",
    cost: 50000,
    seed: 133,
    seed_change: -40,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 46,
    stg_23: "",
    stg_22: "",
    stg_21: 26,
    points_ff23: 8,
  },
  {
    trid: 5214,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5214,
    seed_name: "Rory MacLeod",
    first_name: "Rory",
    last_name: "MacLeod",
    club: "Old Wellingtonians",
    county: "East of Scotland",
    country: "Scotland",
    class: "X",
    cost: 50000,
    seed: 134,
    seed_change: -7,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 24,
    stg_21: "",
    points_ff23: 12,
  },
  {
    trid: 520,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 520,
    seed_name: " Hallows",
    last_name: "Hallows",
    club: "Cornwall RC",
    county: "Cornwall",
    country: "England",
    class: "X",
    cost: 50000,
    seed: 135,
    seed_change: -11,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 18,
    points_ff23: 0,
  },
  {
    trid: 416,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 416,
    seed_name: "Johann Ahrens",
    first_name: "Johann",
    last_name: "Ahrens",
    club: "Peninsula RC",
    county: "",
    country: "South Africa",
    class: "X",
    cost: 50000,
    seed: 136,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 31,
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 812,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Ian Shaw",
    first_name: "Ian",
    last_name: "Shaw",
    club: "Old Epsomian RC",
    county: "West of Scotland",
    country: "Scotland",
    class: "X",
    cost: 50000,
    seed: 137,
    seed_change: -52,
    ga_23: "",
    ga_22: 41,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 50,
    points_ff23: 0,
  },
  {
    trid: 2723,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Jackie McQuillan",
    first_name: "Jackie",
    last_name: "McQuillan",
    club: "Surrey RA",
    county: "East of Scotland",
    country: "Scotland",
    class: "X",
    cost: 50000,
    seed: 138,
    seed_change: -9,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 25,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 472,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 472,
    seed_name: "Christopher Heales",
    first_name: "Christopher",
    last_name: "Heales",
    email: "Christopherheales@yahoo.co.uk",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 139,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 38,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 21,
  },
  {
    trid: 323,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 323,
    seed_name: "Gary Alexander",
    first_name: "Gary",
    last_name: "Alexander",
    email: "garykalexander@aol.com",
    club: "Dungannon",
    county: "Co Tyrone",
    country: "Ireland",
    class: "X",
    cost: 25000,
    seed: 139,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 32,
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 158,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 158,
    seed_name: "Jonathan Kent",
    first_name: "Jonathan",
    last_name: "Kent",
    email: "kenty26@gmail.com",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 139,
    seed_change: -38,
    ga_23: "",
    ga_22: 48,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 43,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 23,
  },
  {
    trid: 1988,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 1988,
    seed_name: "Ben Stevens",
    first_name: "Ben",
    last_name: "Stevens",
    club: "City RC",
    county: "London",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 139,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 19,
    points_ff23: 0,
  },
  {
    trid: 5014,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Saxon Brewer-Marchant",
    first_name: "Saxon",
    last_name: "Brewer-Marchant",
    club: "Surrey RA",
    county: "Yorkshire",
    country: "Guernsey",
    class: "X",
    cost: 25000,
    seed: 143,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 39,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 16,
  },
  {
    trid: 5889,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 5889,
    seed_name: "Russell Woodger",
    first_name: "Russell",
    last_name: "Woodger",
    club: "Cambridge University RA",
    county: "Sussex",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 144,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 27,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 2439,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2439,
    seed_name: "David Nuthall",
    first_name: "David",
    last_name: "Nuthall",
    email: "dnut@hotmail.co.uk",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 145,
    seed_change: "-",
    ga_23: 44,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 20,
  },
  {
    trid: 4486,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4486,
    seed_name: "Barry Le Cheminant",
    first_name: "Barry",
    last_name: "Le Cheminant",
    email: "Barrylecheminant@yahoo.co.uk",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    class: "X",
    cost: 25000,
    seed: 146,
    seed_change: -32,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 37,
    stg_22: "",
    stg_21: "",
    points_ff23: 14,
  },
  {
    trid: 340,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 340,
    seed_name: "John Deane",
    first_name: "John",
    last_name: "Deane",
    club: "Dursley",
    county: "Gloucestershire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 146,
    seed_change: -38,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 23,
    points_ff23: 0,
  },
  {
    trid: 2595,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2595,
    seed_name: "Rebecca Laird",
    first_name: "Rebecca",
    last_name: "Laird",
    club: "Irish Rifle Club",
    county: "Ulster",
    country: "Ireland",
    class: "X",
    cost: 25000,
    seed: 146,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 37,
    stg_22: "",
    stg_21: "",
    points_ff23: 20,
  },
  {
    trid: 5645,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 5645,
    seed_name: "Ben Danziger",
    first_name: "Ben",
    last_name: "Danziger",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 149,
    seed_change: -12,
    ga_23: "",
    ga_22: 42,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 702,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 702,
    seed_name: "Stephen Penrose",
    first_name: "Stephen",
    last_name: "Penrose",
    email: "spenrose@aspects.net",
    club: "Stock Exchange RC",
    county: "Essex",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 150,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 24,
    points_ff23: 5,
  },
  {
    trid: 3754,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Henry Howard",
    first_name: "Henry",
    last_name: "Howard",
    club: "OGRE",
    county: "Norfolk",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 150,
    seed_change: -31,
    ga_23: "",
    ga_22: "",
    ga_21: 36,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 6639,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: " Brindley",
    last_name: "Brindley",
    club: "Canberra RC",
    county: "",
    country: "Australia",
    class: "X",
    cost: 25000,
    seed: 152,
    seed_change: "-",
    ga_23: 46,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 19,
  },
  {
    trid: 3581,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3581,
    seed_name: "Martin Liversage",
    first_name: "Martin",
    last_name: "Liversage",
    email: "martin.liversage@gmail.com",
    club: "Altcar RC",
    county: "Lancashire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 153,
    seed_change: -114,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 39,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 6159,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Henry Asquith-Beard",
    first_name: "Henry",
    last_name: "Asquith-Beard",
    club: "ATSC",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 153,
    seed_change: -11,
    ga_23: "",
    ga_22: 43,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 8,
  },
  {
    trid: 6417,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 6417,
    seed_name: "Maya Beaton-Jaramillo",
    first_name: "Maya",
    last_name: "Beaton-Jaramillo",
    club: "BCRT",
    county: "Berkshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 155,
    seed_change: -16,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 27,
    points_ff23: 0,
  },
  {
    trid: 6168,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "David Brewster",
    first_name: "David",
    last_name: "Brewster",
    club: "Lilydale Rifle Club",
    county: "",
    country: "Australia",
    class: "X",
    cost: 25000,
    seed: 156,
    seed_change: "-",
    ga_23: 47,
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 17,
  },
  {
    trid: 4291,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4291,
    seed_name: "Tom Bullock",
    first_name: "Tom",
    last_name: "Bullock",
    club: "NLRC",
    county: "Nottinghamshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 157,
    seed_change: -13,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 35,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 6141,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Henry Waldern",
    first_name: "Henry",
    last_name: "Waldern",
    email: "waldern_h@live.co.uk",
    club: "Surrey RA",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 158,
    seed_change: 12,
    ga_23: "",
    ga_22: "",
    ga_21: 49,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 50,
    stg_22: "",
    stg_21: "",
    points_ff23: 13,
  },
  {
    trid: 527,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 527,
    seed_name: "Phillip Hakim",
    first_name: "Phillip",
    last_name: "Hakim",
    club: "Old Johnian RC",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 159,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 36,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 977,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 977,
    seed_name: "Simon Glen",
    first_name: "Simon",
    last_name: "Glen",
    club: "West Atholl RC",
    county: "East of Scotland",
    country: "Scotland",
    class: "X",
    cost: 25000,
    seed: 159,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 45,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5179,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 5179,
    seed_name: "Brendan Creane",
    first_name: "Brendan",
    last_name: "Creane",
    club: "Wandsworth FBRC",
    county: "Surrey",
    country: "Ireland",
    class: "X",
    cost: 25000,
    seed: 161,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 31,
    points_ff23: 0,
  },
  {
    trid: 590,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 590,
    seed_name: "Andrew Lothian",
    first_name: "Andrew",
    last_name: "Lothian",
    email: "andrewlothian01@gmail.com",
    club: "Old Epsomian RC",
    county: "Middlesex",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 162,
    seed_change: -14,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 38,
    stg_21: "",
    points_ff23: 7,
  },
  {
    trid: 310,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 310,
    seed_name: "Bruce Winney",
    first_name: "Bruce",
    last_name: "Winney",
    email: "brucewinney@yahoo.co.uk",
    club: "Old Berkhamstedians",
    county: "Hertfordshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 162,
    seed_change: -52,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 39,
    stg_21: "",
    points_ff23: 11,
  },
  {
    trid: 3739,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Christopher Kiplimo Saina",
    first_name: "Christopher Kiplimo",
    last_name: "Saina",
    club: "Kenya RA",
    county: "",
    country: "Kenya",
    class: "X",
    cost: 25000,
    seed: 164,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 43,
    stg_22: "",
    stg_21: "",
    points_ff23: 9,
  },
  {
    trid: 6172,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 6172,
    seed_name: "James Burch",
    first_name: "James",
    last_name: "Burch",
    club: "GB U19",
    county: "Surrey",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 164,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 46,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 159,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 159,
    seed_name: "Susie Luckman",
    first_name: "Susie",
    last_name: "Luckman",
    email: "svk200@googlemail.com",
    club: "Old Epsomian RC",
    county: "Somerset",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 166,
    seed_change: -16,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 44,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 11,
  },
  {
    trid: 5383,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: " Hartung",
    last_name: "Hartung",
    club: "BDMP Germany",
    county: "",
    country: "Germany",
    class: "X",
    cost: 25000,
    seed: 166,
    seed_change: -16,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 40,
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 1655,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Dan Coleman",
    first_name: "Dan",
    last_name: "Coleman",
    club: "Surrey RA",
    county: "",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 168,
    seed_change: -93,
    ga_23: "",
    ga_22: "",
    ga_21: 43,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 1010,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1010,
    seed_name: "Simon Osmond",
    first_name: "Simon",
    last_name: "Osmond",
    email: "simon@hawksheadhouse.co.uk",
    club: "UVRC",
    county: "Lincolnshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 169,
    seed_change: -17,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 36,
    points_ff23: 16,
  },
  {
    trid: 363,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Ian Davison",
    first_name: "Ian",
    last_name: "Davison",
    club: "Dorchester",
    county: "Yorkshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 169,
    seed_change: -17,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 35,
    points_ff23: 0,
  },
  {
    trid: 898,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 898,
    seed_name: "Rob Welford",
    first_name: "Rob",
    last_name: "Welford",
    club: "OCRA",
    county: "London",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 169,
    seed_change: -35,
    ga_23: "",
    ga_22: "",
    ga_21: 44,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 4514,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Lindsey McKerrell",
    first_name: "Lindsey",
    last_name: "McKerrell",
    email: "lindseymckerrell@outlook.com",
    club: "BYSA",
    county: "West of Scotland",
    country: "Scotland",
    class: "X",
    cost: 25000,
    seed: 172,
    seed_change: -11,
    ga_23: "",
    ga_22: 49,
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 11,
  },
  {
    trid: 1110,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 1110,
    seed_name: "Richard Curtis",
    first_name: "Richard",
    last_name: "Curtis",
    club: "Uppingham Veterans RC",
    county: "Herefordshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 173,
    seed_change: -13,
    ga_23: "",
    ga_22: "",
    ga_21: 46,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 5,
  },
  {
    trid: 5361,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5361,
    seed_name: "Lewis Reid",
    first_name: "Lewis",
    last_name: "Reid",
    club: "Surrey RA",
    county: "Nottinghamshire",
    country: "Scotland",
    class: "X",
    cost: 25000,
    seed: 174,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: 47,
    stg_22: "",
    stg_21: "",
    points_ff23: 18,
  },
  {
    trid: 736,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 736,
    seed_name: "Simon Postins",
    first_name: "Simon",
    last_name: "Postins",
    club: "OO Troubleshooters",
    county: "Northants, Leics, Rutland",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 174,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 48,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 854,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 854,
    seed_name: "Steve Thomas",
    first_name: "Steve",
    last_name: "Thomas",
    club: "Central Bankers RC",
    county: "Hertfordshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 174,
    seed_change: -68,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 45,
    stg_21: "",
    points_ff23: 19,
  },
  {
    trid: 5516,
    consent: "N",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Neil Gibbins",
    first_name: "Neil",
    last_name: "Gibbins",
    club: "Mosman-Neutral Bay RC",
    county: "",
    country: "Australia",
    class: "X",
    cost: 25000,
    seed: 174,
    seed_change: "-",
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: 50,
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 16,
  },
  {
    trid: 995,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 995,
    seed_name: "James Bullock",
    first_name: "James",
    last_name: "Bullock",
    club: "Langar RC",
    county: "Nottinghamshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 178,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 46,
    stg_21: "",
    points_ff23: 32,
  },
  {
    trid: 4046,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 4046,
    seed_name: "Richard Webb",
    first_name: "Richard",
    last_name: "Webb",
    club: "Altcar RC",
    county: "Lancashire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 178,
    seed_change: -12,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: 49,
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 835,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 835,
    seed_name: "Nick Brasier",
    first_name: "Nick",
    last_name: "Brasier",
    club: "LMRA",
    county: "London",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 180,
    seed_change: -15,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 42,
    points_ff23: 0,
  },
  {
    trid: 2467,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2467,
    seed_name: "Richard Stebbings",
    first_name: "Richard",
    last_name: "Stebbings",
    email: "richard@oakmays.co.uk",
    club: "BYSA",
    county: "County of Durham",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 180,
    seed_change: -112,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 45,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5193,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5193,
    seed_name: "James Cook",
    first_name: "James",
    last_name: "Cook",
    club: "ARA",
    county: "Hampshire",
    country: "England",
    class: "X",
    cost: 25000,
    seed: 182,
    seed_change: -10,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: 47,
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 5326,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5326,
    seed_name: "Will Livesey",
    first_name: "Will",
    last_name: "Livesey",
    email: "willivesey@sky.com",
    club: "Old Guildfordians RC",
    county: "",
    country: "Ireland",
    class: "X",
    cost: 10000,
    seed: 183,
    seed_change: -11,
    ga_23: "",
    ga_22: "",
    ga_21: 50,
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 503,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 503,
    seed_name: "Alex Hunter",
    first_name: "Alex",
    last_name: "Hunter",
    club: "HAC",
    county: "Kent",
    country: "Scotland",
    class: "X",
    cost: 10000,
    seed: 184,
    seed_change: -61,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 49,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 18,
  },
  {
    trid: 556,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 556,
    seed_name: "Tim Kidner",
    first_name: "Tim",
    last_name: "Kidner",
    club: "East of Scotland RC",
    county: "Aberdeenshire",
    country: "Scotland",
    class: "X",
    cost: 10000,
    seed: 185,
    seed_change: -8,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: 50,
    stg_23: "",
    stg_22: "",
    stg_21: "",
    points_ff23: 0,
  },
  {
    trid: 857,
    consent: "N",
    grand_entrant: "YES",
    ind_num_24: 857,
    seed_name: "Jeremy Thompson",
    first_name: "Jeremy",
    last_name: "Thompson",
    club: "Central Bankers RC",
    county: "Hertfordshire",
    country: "England",
    class: "X",
    cost: 10000,
    seed: 185,
    seed_change: -64,
    ga_23: "",
    ga_22: "",
    ga_21: "",
    q_23: "",
    q_22: "",
    q_21: "",
    stg_23: "",
    stg_22: "",
    stg_21: 47,
    points_ff23: 0,
  },
  {
    trid: 6537,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6537,
    seed_name: "Luke Crisp",
    first_name: "Luke",
    last_name: "Crisp",
    club: "RAFTRC",
    county: "Lincolnshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4343,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Sam Livingston",
    first_name: "Sam",
    last_name: "Livingston",
    club: "RAFTRC",
    county: "County of Durham",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 926,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 926,
    seed_name: "Rosalind Wiltshire",
    first_name: "Rosalind",
    last_name: "Wiltshire",
    club: "Old Epsomian RC",
    county: "Yorkshire",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6298,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6298,
    seed_name: "Sasha Radkovskii",
    first_name: "Sasha",
    last_name: "Radkovskii",
    club: "Stock Exchange RC",
    county: "Oxfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4057,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4057,
    seed_name: "Ashley Abrahams",
    first_name: "Ashley",
    last_name: "Abrahams",
    club: "NLRC",
    county: "",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6773,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6773,
    seed_name: "Andrew Thomas Mohideen ",
    first_name: "Andrew",
    last_name: "Thomas Mohideen ",
    club: "DCRA",
    county: "Pickering",
    country: "Canada",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6597,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6597,
    seed_name: "Kaden Rivoallan",
    first_name: "Kaden",
    last_name: "Rivoallan",
    club: "BCRT",
    county: "Jersey",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6362,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Alex Le Quesne",
    first_name: "Alex",
    last_name: "Le Quesne",
    club: "Victoria College",
    county: "",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6646,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6646,
    seed_name: "Isaac Cotillard",
    first_name: "Isaac",
    last_name: "Cotillard",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6748,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6748,
    seed_name: "Mark Wilson ",
    first_name: "Mark",
    last_name: "Wilson ",
    club: "Bungendore",
    county: "",
    country: "Australia",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6474,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6474,
    seed_name: "Sam Oldridge",
    first_name: "Sam",
    last_name: "Oldridge",
    club: "BCRT",
    county: "Jersey",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5228,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5228,
    seed_name: "Edward Feast",
    first_name: "Edward",
    last_name: "Feast",
    club: "Hertfordshire RA",
    county: "Hertfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4603,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4603,
    seed_name: "Alex Sadler",
    first_name: "Alex",
    last_name: "Sadler",
    club: "BYSA",
    county: "Essex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4540,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Liam Cheek",
    first_name: "Liam",
    last_name: "Cheek",
    club: "Old Guildfordians RC",
    county: "London",
    country: "Ireland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3707,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3707,
    seed_name: "Oliver Russell",
    first_name: "Oliver",
    last_name: "Russell",
    club: "Old Guildfordians RC",
    county: "Sussex",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5456,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5456,
    seed_name: "Jason Pepera-Hibbert",
    first_name: "Jason",
    last_name: "Pepera-Hibbert",
    club: "Old Wellingtonians",
    county: "Lancashire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5818,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5818,
    seed_name: "Miles Horton-Baker",
    first_name: "Miles",
    last_name: "Horton-Baker",
    club: "Old Guildfordians RC",
    county: "Yorkshire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 755,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 755,
    seed_name: "Reggie Roberts",
    first_name: "Reggie",
    last_name: "Roberts",
    club: "Ibis RC",
    county: "Sussex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 583,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 583,
    seed_name: "James Lewis",
    first_name: "James",
    last_name: "Lewis",
    club: "ATRC",
    county: "Norfolk",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 2313,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2313,
    seed_name: "James Mehta",
    first_name: "James",
    last_name: "Mehta",
    club: "Old Framlinghamians RC",
    county: "Essex",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 606,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 606,
    seed_name: "Derek Lowe",
    first_name: "Derek",
    last_name: "Lowe",
    club: "Old Guildfordians RC",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 235,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 235,
    seed_name: "RHG Riley",
    first_name: "RHG",
    last_name: "Riley",
    club: "ATSC",
    county: "Bedfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 257,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 257,
    seed_name: "MW Silver",
    first_name: "MW",
    last_name: "Silver",
    club: "RAFTRC",
    county: "Oxfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 317,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 317,
    seed_name: "Matt Charlton",
    first_name: "Matt",
    last_name: "Charlton",
    club: "Old Guildfordians RC",
    county: "Northants, Leics, Rutland",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 330,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "JA Dallas",
    first_name: "JA",
    last_name: "Dallas",
    club: "NLRC",
    county: "London",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 337,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 337,
    seed_name: "APW Daw",
    first_name: "APW",
    last_name: "Daw",
    club: "North Herts Rifles",
    county: "Hertfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 429,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 429,
    seed_name: "PS Gray",
    first_name: "PS",
    last_name: "Gray",
    club: "Welsh RA",
    county: "Nottinghamshire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 547,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 547,
    seed_name: "MD Jenvey",
    first_name: "MD",
    last_name: "Jenvey",
    club: "RAFTRC",
    county: "Cambridgeshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 584,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "JR Lindsay",
    first_name: "JR",
    last_name: "Lindsay",
    club: "RAFTRC",
    county: "Berkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1001,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1001,
    seed_name: "A Ogilvie",
    first_name: "A",
    last_name: "Ogilvie",
    club: "NLRC",
    county: "East of Scotland",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1014,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1014,
    seed_name: "Bruce Young",
    first_name: "Bruce",
    last_name: "Young",
    club: "NRA",
    county: "Lancashire",
    country: "USA",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1017,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1017,
    seed_name: "AG McLean",
    first_name: "AG",
    last_name: "McLean",
    club: "Ulster SASC",
    county: "Ulster",
    country: "Ireland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1035,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1035,
    seed_name: "NS Roberts",
    first_name: "NS",
    last_name: "Roberts",
    club: "Ibis RC",
    county: "Sussex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1114,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1114,
    seed_name: "RI Coventry",
    first_name: "RI",
    last_name: "Coventry",
    club: "NRA",
    county: "Cheshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1119,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1119,
    seed_name: "RJS Clark",
    first_name: "RJS",
    last_name: "Clark",
    club: "RAFTRC",
    county: "Buckinghamshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1914,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "CM Fopp",
    first_name: "CM",
    last_name: "Fopp",
    club: "RAFTRC",
    county: "Middlesex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 2505,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Tim Roberts",
    first_name: "Tim",
    last_name: "Roberts",
    club: "ATSC",
    county: "Sussex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 2820,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2820,
    seed_name: "CB Cotillard",
    first_name: "CB",
    last_name: "Cotillard",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3720,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3720,
    seed_name: "NM Duncan",
    first_name: "NM",
    last_name: "Duncan",
    club: "Cardinals",
    county: "West of Scotland",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3962,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3962,
    seed_name: "Jamie Allum",
    first_name: "Jamie",
    last_name: "Allum",
    club: "Wellington College",
    county: "Berkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4017,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "PA Evans",
    first_name: "PA",
    last_name: "Evans",
    club: "Welsh XX",
    county: "Northants, Leics, Rutland",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4053,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4053,
    seed_name: "EL Sweet",
    first_name: "EL",
    last_name: "Sweet",
    club: "City RC",
    county: "Hertfordshire",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4510,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4510,
    seed_name: "CE Mawson",
    first_name: "CE",
    last_name: "Mawson",
    club: "RNTRC",
    county: "Norfolk",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5027,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Dan Clark",
    first_name: "Dan",
    last_name: "Clark",
    club: "ATSC",
    county: "Durham",
    country: "",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5237,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "TJ Johnston",
    first_name: "TJ",
    last_name: "Johnston",
    club: "Sussex RC",
    county: "Sussex",
    country: "Ireland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5272,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "HD Quilliam",
    first_name: "HD",
    last_name: "Quilliam",
    club: "ATSC",
    county: "Hampshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5305,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "RJS Clegg",
    first_name: "RJS",
    last_name: "Clegg",
    club: "RAFTRC",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5351,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5351,
    seed_name: "RB Fitzsimmons",
    first_name: "RB",
    last_name: "Fitzsimmons",
    club: "Old Guildfordians RC",
    county: "Middlesex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5390,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Karoline Fleck",
    first_name: "Karoline",
    last_name: "Fleck",
    club: "NLRC",
    county: "Yorkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5453,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5453,
    seed_name: "KA Hinkly",
    first_name: "KA",
    last_name: "Hinkly",
    club: "Old Wellingtonians",
    county: "Somerset",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5537,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "AF Jenvey",
    first_name: "AF",
    last_name: "Jenvey",
    club: "RAFTRC",
    county: "Cambridgeshire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5607,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5607,
    seed_name: "CR Vonchek",
    first_name: "CR",
    last_name: "Vonchek",
    club: "Old Wellingtonians",
    county: "Hampshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5617,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5617,
    seed_name: "CS Gray",
    first_name: "CS",
    last_name: "Gray",
    club: "Old Etonians",
    county: "Hertfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5718,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "MHN Palmer",
    first_name: "MHN",
    last_name: "Palmer",
    club: "Old Epsomian RC",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5791,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5791,
    seed_name: "LR Coventry",
    first_name: "LR",
    last_name: "Coventry",
    club: "Manchester RC",
    county: "Cheshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5859,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Sam Rorison",
    first_name: "Sam",
    last_name: "Rorison",
    club: "NRA",
    county: "Lancashire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6130,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "OMJ Taylor",
    first_name: "OMJ",
    last_name: "Taylor",
    club: "Durham University",
    county: "Cheshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6238,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6238,
    seed_name: "MJ Keel",
    first_name: "MJ",
    last_name: "Keel",
    club: "NLRC",
    county: "Berkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6280,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6280,
    seed_name: "JA Oakland",
    first_name: "JA",
    last_name: "Oakland",
    club: "Oxford University RC",
    county: "Oxfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6318,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "AA Singh",
    first_name: "AA",
    last_name: "Singh",
    club: "Old Wellingtonians",
    county: "Berkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6326,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6326,
    seed_name: "ECS Stewart",
    first_name: "ECS",
    last_name: "Stewart",
    club: "Guernsey RC",
    county: "Guernsey",
    country: "Channel Islands",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6361,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "F Lawry",
    first_name: "F",
    last_name: "Lawry",
    club: "UKCRT",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6421,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6421,
    seed_name: "AJ Browne",
    first_name: "AJ",
    last_name: "Browne",
    club: "BYSA",
    county: "West of Scotland",
    country: "Ireland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6426,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "G Clarke",
    first_name: "G",
    last_name: "Clarke",
    club: "GB U19",
    county: "Norfolk",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6460,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6460,
    seed_name: "K Kousetta",
    first_name: "K",
    last_name: "Kousetta",
    club: "BCRT",
    county: "London",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6462,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6462,
    seed_name: "GJ Coghlan",
    first_name: "GJ",
    last_name: "Coghlan",
    club: "Exeter University RC",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6470,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6470,
    seed_name: "A Moldoveanu",
    first_name: "A",
    last_name: "Moldoveanu",
    club: "BCRT",
    county: "Berkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6488,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6488,
    seed_name: "LG Skingley",
    first_name: "LG",
    last_name: "Skingley",
    club: "BCRT",
    county: "Surrey",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6502,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6502,
    seed_name: "GI Williams",
    first_name: "GI",
    last_name: "Williams",
    club: "BCRT",
    county: "Surrey",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6504,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "W Williamson",
    first_name: "W",
    last_name: "Williamson",
    club: "UKCRT",
    county: "",
    country: "",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6531,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6531,
    seed_name: "R Celesia",
    first_name: "R",
    last_name: "Celesia",
    club: "Wellington College",
    county: "Berkshire",
    country: "Italy",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6539,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6539,
    seed_name: "A Cui",
    first_name: "A",
    last_name: "Cui",
    club: "Wellington College",
    county: "Berkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6540,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6540,
    seed_name: "RS Darwish",
    first_name: "RS",
    last_name: "Darwish",
    club: "Epsom College",
    county: "Yorkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6553,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6553,
    seed_name: "HJ Hamlyn",
    first_name: "HJ",
    last_name: "Hamlyn",
    club: "Epsom College",
    county: "",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6625,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6625,
    seed_name: "JL Williams",
    first_name: "JL",
    last_name: "Williams",
    club: "Epsom College",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 144,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 144,
    seed_name: "Colin Hughes",
    first_name: "Colin",
    last_name: "Hughes",
    club: "Irish Rifle Club",
    county: "Tyne & Wear",
    country: "Ireland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 173,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 173,
    seed_name: "Adam Leech",
    first_name: "Adam",
    last_name: "Leech",
    club: "NLRC",
    county: "Hampshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 362,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "CJ Evans",
    first_name: "CJ",
    last_name: "Evans",
    club: "Wandsworth FBRC",
    county: "Norfolk",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 502,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 502,
    seed_name: "Andy Barnes",
    first_name: "Andy",
    last_name: "Barnes",
    club: "Bedfordians RC",
    county: "Hertfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 740,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 740,
    seed_name: "Charles Brooks",
    first_name: "Charles",
    last_name: "Brooks",
    club: "Old Marlburian RC",
    county: "Hampshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 803,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 803,
    seed_name: "DJ Stewart",
    first_name: "DJ",
    last_name: "Stewart",
    club: "LMRA",
    county: "London",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 808,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 808,
    seed_name: "JPL Sweet",
    first_name: "JPL",
    last_name: "Sweet",
    club: "NLRC",
    county: "Hertfordshire",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 860,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 860,
    seed_name: "Andrew Tompson",
    first_name: "Andrew",
    last_name: "Tompson",
    club: "Old Wellingburian",
    county: "Hertfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 881,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 881,
    seed_name: "TL Bedwell",
    first_name: "TL",
    last_name: "Bedwell",
    club: "Stock Exchange RC",
    county: "Buckinghamshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 933,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 933,
    seed_name: "James Watson",
    first_name: "James",
    last_name: "Watson",
    club: "Uppingham Veterans RC",
    county: "Northants, Leics, Rutland",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 994,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Dorothy Hume",
    first_name: "Dorothy",
    last_name: "Hume",
    club: "NLRC",
    county: "",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 1971,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 1971,
    seed_name: "James Lothian",
    first_name: "James",
    last_name: "Lothian",
    club: "Old Epsomian RC",
    county: "Middlesex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 2307,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2307,
    seed_name: "Simon Shouler",
    first_name: "Simon",
    last_name: "Shouler",
    club: "Surrey RA",
    county: "Nottinghamshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 2316,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2316,
    seed_name: "JA Anderson",
    first_name: "JA",
    last_name: "Anderson",
    club: "Wilbury RC",
    county: "Oxfordshire",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 2694,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 2694,
    seed_name: "G Trembath",
    first_name: "G",
    last_name: "Trembath",
    club: "Newcastle RC",
    county: "County of Durham",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3027,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3027,
    seed_name: "Stephanie Thorogood",
    first_name: "Stephanie",
    last_name: "Thorogood",
    club: "Welsh RA",
    county: "Gloucestershire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3128,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3128,
    seed_name: "John Evans",
    first_name: "John",
    last_name: "Evans",
    club: "Wandsworth FBRC",
    county: "Somerset",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3301,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3301,
    seed_name: "Nick Atkins",
    first_name: "Nick",
    last_name: "Atkins",
    club: "Jersey RC",
    county: "Jersey",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3385,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3385,
    seed_name: "BN Craig",
    first_name: "BN",
    last_name: "Craig",
    club: "OCRA",
    county: "Cheshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3443,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3443,
    seed_name: "M Rewston",
    first_name: "M",
    last_name: "Rewston",
    club: "Huddersfield RC",
    county: "Nottinghamshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3495,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3495,
    seed_name: "Mike Creber",
    first_name: "Mike",
    last_name: "Creber",
    club: "Guernsey RC",
    county: "",
    country: "Guernsey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3532,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3532,
    seed_name: "R Sampson",
    first_name: "R",
    last_name: "Sampson",
    club: "North Herts Rifles",
    county: "Hertfordshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3884,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Thomas Smith",
    first_name: "Thomas",
    last_name: "Smith",
    club: "English XX",
    county: "Northants, Leics, Rutland",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3971,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Laura Haley",
    first_name: "Laura",
    last_name: "Haley",
    club: "Nottingham City RC",
    county: "Nottinghamshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4196,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 4196,
    seed_name: "Lucinda Taylor",
    first_name: "Lucinda",
    last_name: "Taylor",
    club: "Old Epsomian RC",
    county: "Lancashire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 4293,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Alice Southall",
    first_name: "Alice",
    last_name: "Southall",
    club: "Rolls-Royce Derby R & PC",
    county: "Yorkshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5004,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5004,
    seed_name: "AI Ashworth",
    first_name: "AI",
    last_name: "Ashworth",
    club: "Dursley",
    county: "Gloucestershire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5195,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5195,
    seed_name: "Radford Ramage",
    first_name: "Radford",
    last_name: "Ramage",
    club: "Rolls-Royce Derby R & PC",
    county: "Nottinghamshire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5660,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5660,
    seed_name: "OR Farrell",
    first_name: "OR",
    last_name: "Farrell",
    club: "Old Wellingtonians",
    county: "Yorkshire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6002,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6002,
    seed_name: "Nia Barnes",
    first_name: "Nia",
    last_name: "Barnes",
    club: "Bedfordians RC",
    county: "Hertfordshire",
    country: "Wales",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6152,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6152,
    seed_name: "DH Acton",
    first_name: "DH",
    last_name: "Acton",
    club: "BYSA",
    county: "Essex",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6182,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "David Chalmers",
    first_name: "David",
    last_name: "Chalmers",
    club: "Dark Horse RC",
    county: "Hereford & Worcs",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6188,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6188,
    seed_name: "David Colegate",
    first_name: "David",
    last_name: "Colegate",
    club: "Ibis RC",
    county: "Gloucestershire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6199,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6199,
    seed_name: "Rob Edwards",
    first_name: "Rob",
    last_name: "Edwards",
    club: "North Herts Rifles",
    county: "Gloucestershire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6428,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6428,
    seed_name: "Robbie Cooper",
    first_name: "Robbie",
    last_name: "Cooper",
    club: "ATSC",
    county: "Ulster",
    country: "Ireland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6439,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Oliver Frost",
    first_name: "Oliver",
    last_name: "Frost",
    club: "GB U19",
    county: "Devon",
    country: "Scotland",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6467,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Julian Magalhaes",
    first_name: "Julian",
    last_name: "Magalhaes",
    club: "BCRT",
    county: "Jersey",
    country: "Jersey",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6590,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6590,
    seed_name: "Paddy Osmond",
    first_name: "Paddy",
    last_name: "Osmond",
    club: "UKCRT",
    county: "Lincolnshire",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6592,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 6592,
    seed_name: "Aaron Patel",
    first_name: "Aaron",
    last_name: "Patel",
    club: "Epsom College",
    county: "Surrey",
    country: "England",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 726,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "Hamish Pollock",
    first_name: "Hamish",
    last_name: "Pollock",
    club: "Australia",
    county: "Hampshire",
    country: "Australia",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3026,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 3026,
    seed_name: "PJ Westlake",
    first_name: "PJ",
    last_name: "Westlake",
    club: "DCRA",
    county: "",
    country: "Canada",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 3891,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "LRM Irungu",
    first_name: "LRM",
    last_name: "Irungu",
    club: "Kenya RA",
    county: "",
    country: "Kenya",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 5869,
    consent: "Y",
    grand_entrant: "YES",
    ind_num_24: 5869,
    seed_name: "G Stewart",
    first_name: "G",
    last_name: "Stewart",
    club: "Canada",
    county: "",
    country: "Canada",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6109,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "GL Rantile",
    first_name: "GL",
    last_name: "Rantile",
    club: "Kenya RA",
    county: "",
    country: "Kenya",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
  {
    trid: 6244,
    consent: "Y",
    grand_entrant: "NO",
    ind_num_24: "Not Shooting",
    seed_name: "C Koros",
    first_name: "C",
    last_name: "Koros",
    club: "Kenya RA",
    county: "",
    country: "Kenya",
    cost: 5000,
    seed: "-",
    seed_change: "-",
    ga_23: "-",
    ga_22: "-",
    ga_21: "-",
    q_23: "-",
    q_22: "-",
    q_21: "-",
    stg_23: "-",
    stg_22: "-",
    stg_21: "-",
    points_ff23: "-",
  },
];

export const pseudonyms = [
  "Dr J. Smith",
  "Capt A. Johnson",
  "Mr T. Williams",
  "Lt Col L. Brown",
  "Ms C. Davis",
  "Cdt B. Miller",
  "Sgt P. Anderson",
  "Mrs M. Taylor",
  "Dr K. Wilson",
  "Rev R. Moore",
  "Mr J. Jackson",
  "Ms L. Harris",
  "Sgt D. Clark",
  "Dr N. Lewis",
  "Mr S. Hall",
  "Capt E. Young",
  "Ms W. Lee",
  "Cdt G. Turner",
  "Dr F. Wright",
  "Lt Col I. Martin",
  "Ms H. Lewis",
  "Dr J. Harris",
  "Mrs E. Hill",
  "Mr S. Parker",
  "Capt A. Green",
  "Ms M. Martinez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
  "Sgt P. Clark",
  "Dr T. Martinez",
  "Mr D. Adams",
  "Capt K. Brown",
  "Ms R. Young",
  "Cdt A. Taylor",
  "Dr J. Wilson",
  "Lt Col N. Allen",
  "Ms L. Davis",
  "Dr K. Clark",
  "Mrs E. Thomas",
  "Mr J. Turner",
  "Capt C. Hall",
  "Ms V. Lopez",
  "Sgt G. Allen",
  "Dr W. Garcia",
  "Mr I. White",
  "Ms H. Martinez",
  "Dr J. Davis",
  "Lt Col S. Moore",
  "Ms R. Taylor",
  "Dr K. Wright",
  "Mrs E. Lewis",
  "Mr S. Harris",
  "Capt B. Thomas",
  "Ms M. Adams",
  "Sgt P. Anderson",
  "Dr T. Wilson",
  "Mr D. Rodriguez",
  "Ms C. Johnson",
  "Dr J. Clark",
  "Mrs E. Hill",
  "Mr J. Turner",
  "Capt A. Young",
  "Ms L. Lee",
  "Sgt N. Allen",
  "Dr H. Davis",
  "Mr J. White",
  "Ms K. Martinez",
  "Dr R. Thomas",
  "Mrs E. Moore",
  "Mr S. Hall",
  "Capt G. Johnson",
  "Ms M. Lopez",
  "Sgt L. Jackson",
  "Dr J. Rodriguez",
  "Cdt M. Wilson",
  "Mr B. Moore",
  "Ms C. Davis",
];

export const swearWords = [
  "anal",
  "anus",
  "arse",
  "ass",
  "ballsack",
  "balls",
  "bastard",
  "bitch",
  "biatch",
  "bloody",
  "blowjob",
  "blow job",
  "bollock",
  "bollok",
  "boner",
  "boob",
  "bugger",
  "bum",
  "butt",
  "buttplug",
  "clitoris",
  "cock",
  "coon",
  "crap",
  "cunt",
  "damn",
  "dick",
  "dildo",
  "dyke",
  "fag",
  "feck",
  "fellate",
  "fellatio",
  "felching",
  "fuck",
  "f u c k",
  "fudgepacker",
  "fudge packer",
  "flange",
  "Goddamn",
  "God damn",
  "hell",
  "homo",
  "jerk",
  "jizz",
  "knobend",
  "knob end",
  "labia",
  "lmao",
  "lmfao",
  "muff",
  "nigger",
  "nigga",
  "omg",
  "penis",
  "piss",
  "poo",
  "poop",
  "prick",
  "pube",
  "pussy",
  "queer",
  "scrotum",
  "sex",
  "shit",
  "s hit",
  "sh1t",
  "slut",
  "smegma",
  "spunk",
  "tit",
  "tosser",
  "turd",
  "twat",
  "vagina",
  "wank",
  "whore",
  "wtf",
];
