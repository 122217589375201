import { Box, Typography } from "@mui/material";
import React from "react";
import Shooters from "./shooters";

const AdminShooters = () => {
  return (
    <Box sx={{ mt: 10 }}>
      <Typography variant="h4" component="h1">
        Admin Shooters
      </Typography>
      <Box>
        <Shooters />
      </Box>
    </Box>
  );
};

export default AdminShooters;
