import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Close as CloseIcon } from "@mui/icons-material";
import { withFirebase } from "../Firebase";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import * as EmailValidator from "email-validator";
import Toast from "../Toast";
import firebase from "firebase/compat/app";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80vw", sm: "80vw", md: 600 },
  height: { xs: "90vh", sm: "70vh" },
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const ShooterProfile = (props) => {
  const [shooter, setShooter] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    props.setTrid(false);
    setShooter(false);
    setOpen(false);

    // setName("");
    // setEmail("");
    // setTeamName("");
  };

  useEffect(() => {
    if (props.trid) {
      props.firebase.db
        .doc(`shooters/${props.trid}`)
        .get()
        .then((result) => {
          if (result.exists) {
            setShooter({ trid: result.id, ...result.data() });
            setOpen(true);
          } else {
            props.setTrid(false);
            setOpen(false);
          }
        });
    }
  }, [props.trid]);

  function Years(comp) {
    const numbers = [];
    let number = 23;

    while (number >= 21) {
      numbers.push(
        <TableCell>
          <Typography key={number}>
            {shooter[`${comp.comp}_${number}`] === ""
              ? ` - `
              : `${shooter[`${comp.comp}_${number}`]}`}
          </Typography>
        </TableCell>
      );
      number--;
    }

    return numbers;
  }

  return (
    <div>
      {shooter && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={handleClose}
              style={{ position: "absolute", top: 10, right: 10 }}
            >
              <CloseIcon />
            </IconButton>

            <Box>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                {shooter.first_name && shooter.first_name}{" "}
                {shooter.last_name && shooter.last_name}
              </Typography>
              {console.log(shooter)}
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                  <List dense>
                    <ListItem>
                      <ListItemText
                        primary={`
                            Seed: ${shooter.seed}
                          `}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        sx={{ display: "flex", alignItems: "left" }}
                        primary={
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "start",
                              alignContent: "start",
                              justifyItems: "left",
                            }}
                          >
                            Seed Change from 2023:
                            {shooter.seed_change > 0 && (
                              <NorthIcon fontSize="small" color="success" />
                            )}
                            {shooter.seed_change < 0 && (
                              <SouthIcon fontSize="small" color="warning" />
                            )}
                            {` ${shooter.seed_change}`}
                          </Box>
                        }
                      />
                    </ListItem>
                    {shooter.teamCount && shooter.teamCount !== 0 && (
                      <ListItem>
                        <ListItemText
                          primary={`
                          Picked for ${shooter.teamCount} teams
                        `}
                        />
                      </ListItem>
                    )}
                  </List>
                </Box>
                <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                  <List dense>
                    <ListItem>
                      <ListItemText primary={`Class: ${shooter.class}`} />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Country: ${shooter.country || "-"}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`County: ${shooter.county || "-"}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary={`Club: ${shooter.club || "-"} `} />
                    </ListItem>
                  </List>
                </Box>
              </Box>
              <Divider />
              <Box>
                <Typography align="center" variant="h5">
                  Top 50 Results
                </Typography>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Competition</TableCell>
                        <TableCell>2023</TableCell>
                        <TableCell>2022</TableCell>
                        <TableCell>2021</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Grand Agg</TableCell>
                        <Years comp={"ga"} />
                      </TableRow>
                      <TableRow>
                        <TableCell>Queens/Kings</TableCell>
                        <Years comp={"q"} />
                      </TableRow>
                      <TableRow>
                        <TableCell>St Georges</TableCell>
                        <Years comp={"stg"} />
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Divider />
                <Box sx={{ alignItems: "center" }}>
                  <Typography align="center" variant="h3" color="primary">
                    £{shooter.cost.toLocaleString()}
                  </Typography>
                </Box>
                <Button
                  disabled={props.pickedTeam.some(
                    (item) => item.trid === shooter.trid
                  )}
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    props.handlePickUser(shooter);
                    handleClose();
                  }}
                >
                  Add to your team
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default withFirebase(ShooterProfile);
