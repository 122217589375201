import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { withFirebase } from "../Firebase";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import * as EmailValidator from "email-validator";
import Toast from "../Toast";
import firebase from "firebase/compat/app";
import CircularProgress from "@mui/material/CircularProgress";
import { swearWords } from "../constants/data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditName = (props) => {
  const [originalName, setOriginalName] = useState(props.name);
  const [name, setName] = useState(props.name);
  const [error, setError] = useState("");
  const handleClose = () => {
    props.setNameOpen(false);
    props.setTid(false);
  };

  function containsSwearWords(text) {
    const regexPattern = new RegExp(swearWords.join("|"), "i");
    return regexPattern.test(text);
  }
  async function updateName() {
    if (name === originalName) {
      handleClose();
    } else {
      if (name === "") {
        setError("You must enter a name");
      } else {
        const checkedName = await props.firebase.checkName(name);
        if (!checkedName) {
          setError("The name already exists, please pick another");
        } else if (containsSwearWords(name.toLowerCase())) {
          setError("Please use nice words");
        } else {
          setError("");
          const team = props.firebase.db.doc(`teams/${props.tid}`);
          try {
            await props.firebase.db.runTransaction(async (t) => {
              const doc = await t.get(team);
              console.log(doc.data());
              const userRef = props.firebase.db.doc(
                `users/${doc.data().uid}/teams/${props.tid}`
              );
              const teamIndex = props.firebase.db.doc(`teams/index`);
              t.update(team, {
                teamName: name,
                lowerCaseName: name.toLowerCase(),
              });
              t.update(userRef, { teamName: name });
              let indexData = {
                teamName: name,
                cost: doc.data().cost,
                name: doc.data().name,
                points: doc.data().points,
                position: null,
                previousPoints: 0,
                previousPointsDate: doc.data().previousPointsDate,
                uid: doc.data().uid,
              };
              t.update(teamIndex, { [props.tid]: indexData });

              // Add one person to the city population.
              // Note: this could be done without a transaction
              //       by updating the population using FieldValue.increment()
              // const newPopulation = doc.data().population + 1;
              // t.update(cityRef, {population: newPopulation});
            });
            handleClose();
            console.log("Transaction success!");
          } catch (e) {
            console.log("Transaction failure:", e);
          }
        }
      }
    }
  }

  return (
    <div>
      {props.nameOpen && (
        <Modal
          open={props.nameOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #999999",
                },
                mb: 2,
              }}
              label="Name"
              id="outlined-size-small"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              size="small"
              fullWidth
              error={error !== "" ? true : false}
              helperText={error}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateName()}
              >
                Update
              </Button>
              <Button color="primary" onClick={() => handleClose()}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default withFirebase(EditName);
