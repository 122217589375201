import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Shooters from "../Shooters";
import {
  Button,
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Modal,
  IconButton,
  ButtonBase,
} from "@mui/material";

import grand from "../static/images/1.png";
// import team from "../static/images/2.png";
import * as ROUTES from "../constants/routes";
import ScoreBoard from "./scoreBoard";
import EnteringGrand from "./enteringGrand";
import CountdownTimer from "./countDown";
import Instructions from "../HowToPlay/instructions";
// import background from "../static/images/bisleybackground.jpeg";
import background from "../static/images/background.png";
// import rules from "../static/images/rukes.png";
import rules from "../static/images/howto.png";
import team from "../static/images/team.png";
import Teams from "../Teams";
function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [localData, setLocalData] = useState(false);
  const [preGrand, setPreGrand] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleInstructionsOpen = () => setInstructionsOpen(true);
  const handleInstructionsclose = () => setInstructionsOpen(false);
  const navigate = useNavigate();

  const checkTime = () => {
    const currentDate = new Date();
    const targetDate = new Date("2024-07-20T07:00:00+01:00"); // 8am BST on 20th July

    if (currentDate > targetDate) {
      setPreGrand(false);
    } else {
      setPreGrand(true);
    }
  };

  useEffect(() => {
    checkTime();
  }, []);

  const handleClick = () => {
    navigate(ROUTES.PICK);
  };
  // useEffect(() => {
  //   const retrieveFromLocalStorage = () => {
  //     const storedData = localStorage.getItem("fantasyFullbore");
  //     if (storedData) {
  //       const parsedData = JSON.parse(storedData);
  //       setLocalData(parsedData);
  //       if (!parsedData.firstPopup) {
  //         setOpen(true);
  //       }
  //     } else {
  //       setLocalData(false);
  //       setOpen(true);
  //     }
  //   };
  //   retrieveFromLocalStorage();
  // }, []);

  return (
    <Box
      sx={{
        height: `calc(100vh - 104px)`,
        paddingTop: "104px",
        backgroundColor: "#f3f2f1",
        width: "100vw",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        // backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), #f3f2f1),url(${background})`,
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top  ",
      }}
    >
      {/* <EnteringGrand open={open} setOpen={setOpen} /> */}
      <Modal
        open={instructionsOpen}
        onClose={handleInstructionsclose}
        aria-labelledby="instructions"
        aria-describedby="instructions"
      >
        <Instructions modal={true} setInstructionsOpen={setInstructionsOpen} />
      </Modal>
      <Box sx={{ width: "100%", flexDirection: "row" }}>
        <CountdownTimer />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-evenly",
          mt: 3,
        }}
      >
        {preGrand ? (
          <>
            <ButtonBase
              variant="outlined"
              onClick={() => handleInstructionsOpen()}
              sx={{
                width: isMobile ? "50%" : "20%",
                m: "16px auto",
                p: 0,
                // display: "flex",
                // justifyContent: "space-evenly",
                // backgroundColor: "#fff",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   color: "#fff",
                // },
              }}
              component={Paper}
            >
              <img
                src={rules}
                alt="View instructions"
                width="100%"
                height="100%"
              />
              {/* <span>How to play</span> */}
            </ButtonBase>
            <ButtonBase
              onClick={handleClick}
              variant="outlined"
              sx={{
                width: isMobile ? "50%" : "20%",
                m: "16px auto",
                display: "flex",
                p: 0,
                justifyContent: "space-evenly",
                // backgroundColor: "#fff",
                // "&:hover": {
                //   backgroundColor: "primary.main",
                //   color: "#fff",
                // },
              }}
              component={Paper}
            >
              <img src={team} alt="Pick a team" width="100%" height="100%" />
              {/* <span>Pick your Team</span> */}
            </ButtonBase>
          </>
        ) : (
          <Box component={Paper}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Scores subject to confirmation/challenges
              </Typography>
              {/* <Button href="/teams" variant="contained">
                View Teams
              </Button> */}
            </Box>
            <Box sx={{ mt: -15 }}>
              <Teams dashboard={true} />
            </Box>
            {/* <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
              <ScoreBoard />
            </Box> */}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard;
