import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableBody,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HowToDetail = ({ setHowToDetailOpen }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75vw",
    height: "75vh",
    bgcolor: "background.paper",
    p: 4,
    overflow: "scroll",
    pt: 1,
  };

  return (
    <Box component={Paper} sx={style}>
      <Typography variant="h4" align="center" color="primary">
        How does my team score points?
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">PER SHOOT - Get a possible!</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            For each individual shoot (or King's altogether), a shooter earns 5
            points if they get a possible. 10 if with maximum vees!
          </Typography>
          <Typography variant="body1">
            E.G. A shooter in your team gets a 50.7 in the Alexandra (5 points),
            another scores a 50.10 and earns 10 points
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">PER DAY - Finish in the Top 50!</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Taking each day in isolation, if your shooter finishes in the top 50
            of the aggregate for that day, they earn your team points.
          </Typography>
          <Typography variant="body1">
            Points are banked so that it does not matter how they do in
            subsequent days
          </Typography>
          <Typography variant="body1">
            See the points table below for how points are allocated per
            position.
          </Typography>
          <Typography variant="body1">
            E.G. A shooter gets a 49.7 in the Donegall, 74.9 in the Daily
            Telegraph and 48.6 in the Conan Doyle. Their total for Saturday is
            171.22 and they finish 15th, so they would earn you 9 points!
          </Typography>
          <Table dense>
            <TableHead>
              <TableRow>
                <TableCell>Position</TableCell>
                <TableCell>Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1st</TableCell>
                <TableCell>50</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2nd</TableCell>
                <TableCell>35</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3rd</TableCell>
                <TableCell>28</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4th</TableCell>
                <TableCell>23</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5th</TableCell>
                <TableCell>20</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>6th</TableCell>
                <TableCell>18</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>7th</TableCell>
                <TableCell>16</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>8th</TableCell>
                <TableCell>14</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>9th</TableCell>
                <TableCell>13</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>10th</TableCell>
                <TableCell>12</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>11th-12th</TableCell>
                <TableCell>11</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>13th</TableCell>
                <TableCell>10</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>14th-15th</TableCell>
                <TableCell>9</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>16th-18th</TableCell>
                <TableCell>8</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>19th-21st</TableCell>
                <TableCell>7</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>22nd-24th</TableCell>
                <TableCell>6</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>25th-30th</TableCell>
                <TableCell>5</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>31st-36th</TableCell>
                <TableCell>4</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>37th-42nd</TableCell>
                <TableCell>3</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>43rd-48th</TableCell>
                <TableCell>2</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>49th-50th</TableCell>
                <TableCell>1</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">
            GRAND AGGREGATE - Finish in the Top 50!
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            A shooter earns your team points for how they place in the Grand
            Aggregate at its conclusion: Finish in the top 50 to earn points!
          </Typography>
          <Typography variant="body1">
            The points total is updated on a daily basis, and a scaled amount is
            shown as a prediction of final placing (Saturday - 0%, Sunday - 25%,
            Monday - 50%, Tuesday - 75%, Wednesday 100%)
          </Typography>
          <Typography variant="body1">See table below for points!</Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Points are NOT banked daily! It is only where you end up at the end
            that is allocated at the end of the competition
          </Typography>
          <Typography variant="body1">
            E.G. Over the Grand Aggregate a shooter is always in the top 50 of
            the Grand Aggregate, ending 17th at the end of Saturday, 22nd at the
            end of Sunday, 4th at the end of Monday, 38th at the end of Tuesday
            and finishes 20th in the end.
          </Typography>
          <Typography variant="body1">
            The scaled indications would be as follows: 0% of the points on
            Saturday (0 * 25), 25% of the points on Sunday (0.25*20 = 5), 50% of
            the points up to Monday (0.5*70 = 35), 75% at the end of Tuesday
            (0.75 * 11 = 8.25) and 100% at the end of the Grand Aggregate (22).
            <span style={{ fontWeight: "bold" }}>
              22 points only is the only allocation.
            </span>
          </Typography>
          <Table dense>
            <TableHead>
              <TableRow>
                <TableCell>Position</TableCell>
                <TableCell>Points</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1st</TableCell>
                <TableCell>150</TableCell>
                <TableCell>20th</TableCell>
                <TableCell>22</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2nd</TableCell>
                <TableCell>106</TableCell>
                <TableCell>21st</TableCell>
                <TableCell>21</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3rd</TableCell>
                <TableCell>84</TableCell>
                <TableCell>22nd</TableCell>
                <TableCell>20</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>4th</TableCell>
                <TableCell>70</TableCell>
                <TableCell>23rd</TableCell>
                <TableCell>19</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>5th</TableCell>
                <TableCell>61</TableCell>
                <TableCell>24th</TableCell>
                <TableCell>18</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>6th</TableCell>
                <TableCell>54</TableCell>
                <TableCell>25th - 26th</TableCell>
                <TableCell>17</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>7th</TableCell>
                <TableCell>48</TableCell>
                <TableCell>27th - 28th</TableCell>
                <TableCell>16</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>8th</TableCell>
                <TableCell>44</TableCell>
                <TableCell>29th - 30th</TableCell>
                <TableCell>15</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>9th</TableCell>
                <TableCell>40</TableCell>
                <TableCell>31st - 32nd</TableCell>
                <TableCell>14</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>10th</TableCell>
                <TableCell>37</TableCell>
                <TableCell>33rd - 34th</TableCell>
                <TableCell>13</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>11th</TableCell>
                <TableCell>35</TableCell>
                <TableCell>35th - 36th</TableCell>
                <TableCell>12</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>12th</TableCell>
                <TableCell>33</TableCell>
                <TableCell>37th - 38th</TableCell>
                <TableCell>11</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>13th</TableCell>
                <TableCell>31</TableCell>
                <TableCell>39th - 40th</TableCell>
                <TableCell>10</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>14th</TableCell>
                <TableCell>29</TableCell>
                <TableCell>41st - 42nd</TableCell>
                <TableCell>9</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>15th</TableCell>
                <TableCell>28</TableCell>
                <TableCell>43rd - 44th</TableCell>
                <TableCell>8</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>16th</TableCell>
                <TableCell>26</TableCell>
                <TableCell>45th - 46th</TableCell>
                <TableCell>7</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>17th</TableCell>
                <TableCell>25</TableCell>
                <TableCell>47th - 48th</TableCell>
                <TableCell>6</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>18th</TableCell>
                <TableCell>24</TableCell>
                <TableCell>49th - 50th</TableCell>
                <TableCell>5</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>19th</TableCell>
                <TableCell>23</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Button
        fullWidth
        sx={{ pt: 3 }}
        onClick={() => setHowToDetailOpen(false)}
      >
        Close
      </Button>
    </Box>
  );
};

export default HowToDetail;
