import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";

const MyProfile = (props) => {
  const [profile, setProfile] = useState(false);
  const [teamCount, setTeamCount] = useState(false);
  const [reconciled, setReconciled] = useState(0);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .doc(`users/${props.uid}`)
      .onSnapshot((result) => {
        if (result.exists) {
          setProfile({ uid: result.id, ...result.data() });
        }
      });
    return () => {
      unsubscribe();
      setProfile(false);
    };
  }, [props.uid]);

  useEffect(() => {
    if (props.teams) {
      setTeamCount(Object.keys(props.teams).length);
      let rec = 0;
      Object.entries(props.teams).map(([key, team]) => {
        if (team.reconciled) {
          rec += 1;
        }
      });
      setReconciled(rec);
    }
  }, [props.teams]);

  return (
    <Box>
      {profile ? (
        <Box sx={{ border: "1px solid", m: 2 }}>
          <Typography variant="h4">Profile</Typography>

          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                width: { xs: "100%", sm: "50%" },
              }}
            >
              <Typography variant="h5">Personal Details</Typography>
              <Typography>
                Name:
                {profile.name || `${profile.first_name} ${profile.last_name}`}
              </Typography>
              <Typography>Email:{profile.email}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                width: { xs: "100%", sm: "50%" },
              }}
            >
              <Typography variant="h5">Teams</Typography>
              <Typography>Teams entered: {teamCount}</Typography>
              <Typography>Payment reconciled for: {reconciled}</Typography>
              {reconciled < teamCount && (
                <Typography variant="caption">
                  It might take us a day or so to reconcile any payments made,
                  so please bare with us if this is incorrect.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
      {profile && reconciled < teamCount && (
        <Box sx={{ border: "1px solid", m: 2 }}>
          <Typography variant="h5">Payment due</Typography>
          <Typography>Please complete your payment to:</Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            GB Palma SA 2023
          </Typography>
          <Typography variant="caption">
            (Palma team are kindly allowing the use of the bank account whilst
            the NRA team set something up!)
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            50878979
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            20-96-89
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Please use reference:
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            "FF[SurnameFirstInitial]-[first five characters of team name]"
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Eg John Smith enters a team called “Superteam” then reference is:
            FFSMITHJ-SUPER
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(MyProfile);
