import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import {
  createTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ThemeProvider } from "@mui/material/styles";
import NavBar from "./NavBar";
import DashboardPage from "./Dashboard";
import TeamPickPage from "./TeamPick";
import TeamsPage from "./Teams";
import AdminPage from "./Admin";
import AdminTeamsPage from "./Admin/Teams";
import AdminSetupPage from "./Admin/Setup";
import AdminMigrationsPage from "./Admin/AdminMigrations";
import HowToPlayPage from "./HowToPlay";
import ProfilePage from "./Profile";
import AdminFunctionsPage from "./Admin/Functions";
import AdminShootersPage from "./Admin/AdminShooters";

function App() {
  const prefersDarkMode = false;
  //useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: {
            main: prefersDarkMode ? "#050B19" : "#44499E",
          },
          secondary: {
            main: prefersDarkMode ? "#00BFFF" : "#E73834",
          },

          text: {
            primary: prefersDarkMode ? "#fff" : "#000",
            secondary: prefersDarkMode ? "#999999" : "#545454",
            success: prefersDarkMode ? "#9ECF99" : "green",
            warning: prefersDarkMode ? "#F7E943" : "#F7C843",
          },
        },
        components: {
          MuiTableCell: {
            styleOverrides: {
              root: {
                borderBottomColor: "rgba(224, 224, 224, 1)",
              },
            },
          },
          MuiTableFooter: {
            styleOverrides: {
              root: {
                position: "sticky",
                zIndex: 999,
                bottom: 0,
                backgroundColor: prefersDarkMode ? "#424242" : "#fff",
              },
            },
          },
          MuiOutlinedInput: {
            root: {
              "& $notchedOutline": {
                borderColor: "#000",
                borderWidth: "1px",
                borderStyle: "solid", // Add the border style
              },
            },
          },
        },
        overrides: {},
      }),
    [prefersDarkMode]
  );

  const [globalUid, setGlobalUid] = useState(false);
  useEffect(() => {
    const retrieveFromSessionStorage = () => {
      const storedData = localStorage.getItem("fantasyFullboreLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData.profileLogin) {
          setGlobalUid(parsedData.profileLogin);
        } else {
          setGlobalUid(false);
        }
      }
    };
    retrieveFromSessionStorage();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App" style={{}}>
          <NavBar globalUid={globalUid} setGlobalUid={setGlobalUid} />
          <Routes>
            <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
            <Route path={ROUTES.ADMIN} element={<AdminPage />} />
            <Route path={ROUTES.ADMINTEAMS} element={<AdminTeamsPage />} />
            <Route path={ROUTES.ADMINSETUP} element={<AdminSetupPage />} />
            <Route
              path={ROUTES.ADMINMIGRATIONS}
              element={<AdminMigrationsPage />}
            />
            <Route
              path={ROUTES.ADMINFUNCTIONS}
              element={<AdminFunctionsPage />}
            />
            <Route path={ROUTES.PICK} element={<TeamPickPage />} />
            <Route
              path={ROUTES.TEAMS}
              element={<TeamsPage globalUid={globalUid} />}
            />
            <Route path={ROUTES.HOWTOPLAY} element={<HowToPlayPage />} />
            <Route
              path={ROUTES.PROFILE}
              element={<ProfilePage setGlobalUid={setGlobalUid} />}
            />
            <Route
              path={ROUTES.ADMINSHOOTERS}
              element={<AdminShootersPage />}
            />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
