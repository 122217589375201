import Shooters from "../Shooters";
import { Box } from "@mui/material";

function TeamPick() {
  return (
    <Box
      sx={{
        paddingTop: "64px",
        backgroundColor: "#f3f2f1",
        width: "100vw",
        overflow: "scroll",
      }}
    >
      <Shooters editTeam={false} />
    </Box>
  );
}

export default TeamPick;
