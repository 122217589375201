import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import {
  AccordionDetails,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import * as ROUTES from "../constants/routes";
import Toast from "../Toast";

const TeamEditDetails = (props) => {
  const [team, setTeam] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (props.tid) {
      const unsubscribe = props.firebase
        .getTeam(props.tid, props.year)
        .onSnapshot((result) => {
          if (result.exists) {
            setTeam({ tid: result.id, ...result.data() });
          }
        });
      return () => unsubscribe();
    }
  }, [props.tid, props.firebase]);

  return (
    <Box
      sx={{
        display: "flex",

        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>Team Owner : {team.name}</Typography>
        <Typography>
          Cost : {team.cost ? `£${team.cost.toLocaleString()}` : 0}
        </Typography>
        {props.preGrand && props.globalUid === team.uid && (
          <Button align="right" href={`${ROUTES.PROFILE}?uid=${team.uid}`}>
            Edit
          </Button>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        {props.preGrand && (
          <Typography variant="caption">
            Team members and points will show here after Message One
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default withFirebase(TeamEditDetails);
