import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const CountdownTimer = () => {
  const targetDate = new Date("July 20, 2024 08:00:00 GMT+0100");
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
  const [preGrand, setPreGrand] = useState(() => {
    if (calculateRemainingTime() > 0) {
      return true;
    } else {
      return false;
    }
  });

  function calculateRemainingTime() {
    const currentTime = new Date();
    const timeDifference = targetDate - currentTime;

    if (timeDifference <= 0) {
      // Countdown has reached the target date
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  const boxStyle = {
    backgroundColor: "#fff",
    p: 1,
    m: 1,
    borderRadius: "5px",
  };
  const timerText = {
    fontSize: { xs: "3rem", sm: "5rem" },
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {console.log(remainingTime)}
      <Box>
        <Typography
          variant="h2"
          component="h2"
          paragraph
          // color="primary"
          sx={{ color: "#ffffff" }}
        >
          {
            // pre and during grand
            // `Message One`
            //post grand
            `Message 10`
          }
        </Typography>
        {preGrand && (
          <Typography
            variant="h4"
            component="h2"
            // color="primary"
            sx={{ color: "#ffffff", pb: 0, mb: 0 }}
          >
            in
          </Typography>
        )}
        {!preGrand && (
          <Typography variant="h3" component="h3" color="#fff">
            has been passed to the butts.
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {remainingTime.minutes !== 0 && remainingTime.seconds !== 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Set the direction to row
              justifyContent: "center",
              alignItems: "center",
              mt: "0px",
            }}
          >
            <Box sx={boxStyle}>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={timerText}
              >
                {remainingTime.days}
              </Typography>
              <Typography variant="caption">Days</Typography>
            </Box>

            <Box sx={boxStyle}>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={timerText}
              >
                {remainingTime.hours}
              </Typography>
              <Typography variant="caption">Hours</Typography>
            </Box>
            <Box sx={boxStyle}>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={timerText}
              >
                {remainingTime.minutes}
              </Typography>
              <Typography variant="caption">Minutes</Typography>
            </Box>
            <Box sx={boxStyle}>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={timerText}
              >
                {remainingTime.seconds}
              </Typography>
              <Typography variant="caption">Seconds</Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            {/* <Typography variant="h4" component="h2" color="#fff">
              Please check back later today to view teams
            </Typography> */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CountdownTimer;
