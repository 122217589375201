import { Box } from "@mui/material";
import React from "react";
import Shooters from "../Shooters";
import { withFirebase } from "../Firebase";

const ProfileEditTeam = (props) => {
  return (
    <Box>
      <Shooters
        editTeam={true}
        setEditTeam={props.setEditTeam}
        tid={props.tid}
      />
    </Box>
  );
};
export default withFirebase(ProfileEditTeam);
