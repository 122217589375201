import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import {
  AccordionDetails,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

const TeamAccordion = (props) => {
  return (
    <List>
      {props.team &&
        props.team.shooters.map((shooter) => (
          <ListItem key={shooter.trid} sx={{ padding: 0 }}>
            <Box sx={{ width: "30%" }}>{shooter.name}</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "70%",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ flex: 1 }}>
                {props.team.day1points ? props.team.day1points : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {props.team.day2points ? props.team.day2points : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {props.team.day3points ? props.team.day3points : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {props.team.day4points ? props.team.day4points : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {props.team.day5points ? props.team.day5points : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {props.team.day5points ? props.team.day5points : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {props.team.day5points ? props.team.day5points : 0}
              </Box>
            </Box>
          </ListItem>
        ))}
    </List>
  );
};

export default withFirebase(TeamAccordion);
