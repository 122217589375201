import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Button } from "@mui/material";
import ExcelReader from "../../ExcelUploader";
import PDFReader from "../../PDFReader";
import CsvLoader from "./csvLoader";
import CsvToJsonConverter from "./csvToJsonConverter";
import { shooterDetails } from "../../constants/data";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";
import PasswordPopup from "../passwordPopup";
import AdminNav from "../AdminNav";
import { scoreData } from "../../constants/scores";

const Admin = (props) => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  useEffect(() => {
    const retrieveFromSessionStorage = () => {
      const storedData = sessionStorage.getItem("fantasyFullboreLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData.adminLogin) {
          setIsPasswordCorrect(true);
        }
      } else {
        setIsPasswordCorrect(false);
      }
    };
    retrieveFromSessionStorage();
  }, []);

  const handlePasswordSubmit = (password) => {
    // Check if the entered password is correct
    if (password === "Cr@wfurd") {
      setIsPasswordCorrect(true);
      const dataToStore = JSON.stringify({ adminLogin: true });
      sessionStorage.setItem("fantasyFullboreLogin", dataToStore);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };
  async function loadPeople() {
    //Dont use this anymore. Use the edit users page to change details about clubs etc. For 2025, the below needs to be edited so that the club, country
    // etc isn't edited. only the see, seed change and cost should be updated.
    let toSave = {};
    const chunkSize = 100;
    for (let i = 0; i < shooterDetails.length; i += chunkSize) {
      const chunk = shooterDetails.slice(i, i + chunkSize);

      const batch = props.firebase.db.batch();
      chunk.map((shooter) => {
        let shootingGrand = false;
        let consent = false;
        console.log(
          shooter.grand_entrant,
          shooter.consent,
          shooter.trid,
          "shooter"
        );
        if (shooter.grand_entrant === "YES") {
          shootingGrand = true;
        }
        if (shooter.consent === "Y") {
          consent = true;
        }
        console.log(shootingGrand, "grand", consent, "consent");
        if (shooter.email !== "null") {
          const userRef = props.firebase.db.doc(`users/${shooter.trid}`);
          let user = {
            class: shooter.class || null,
            club: shooter.club || null,
            county: shooter.county || null,
            country: shooter.country || null,
            cost: shooter.cost,
            seed: shooter.seed,
            consent,
            shootingGrand,
            email: shooter.email || null,
            seed_name: shooter.seed_name,
            trid: shooter.trid,
            name: `${shooter.first_name} ${shooter.last_name}`,
          };
          batch.set(userRef, user, { merge: true });
        }

        const shooterRef = props.firebase.db.doc(`shooters/${shooter.trid}`);
        toSave[shooter.trid] = {
          trid: shooter.trid,
          seed_name: shooter.seed_name,
          class: shooter.class || null,
          club: shooter.club || null,
          county: shooter.county || null,
          country: shooter.country || null,
          cost: shooter.cost,
          seed: shooter.seed,
          consent,
          shootingGrand,
        };
        shooter.consent = consent;
        shooter.shootingGrand = shootingGrand;
        batch.set(shooterRef, shooter, { merge: true });
      });
      console.log(chunk, "chunk");
      console.log(toSave, "tosave");
      try {
        // await props.firebase.db.doc(`shooters/index`).update(toSave);
        // await batch.commit();
      } catch (error) {
        console.warn(error);
      }
    }
  }
  // async function deleteShooters() {
  //   const batch = props.firebase.db.batch();
  //   const usersSnapshot = await props.firebase.db.collection("shooters").get();

  //   usersSnapshot.forEach((userDoc) => {
  //     batch.delete(userDoc.ref);
  //   });

  //   try {
  //     await batch.commit();
  //     console.log("Deletion successful!");
  //   } catch (error) {
  //     console.error("Error deleting users:", error);
  //   }
  // }
  // async function deleteUsers() {
  //   const batch = props.firebase.db.batch();
  //   const usersSnapshot = await props.firebase.db.collection("users").get();

  //   usersSnapshot.forEach((userDoc) => {
  //     batch.delete(userDoc.ref);
  //   });

  //   try {
  //     await batch.commit();
  //     console.log("Deletion successful!");
  //   } catch (error) {
  //     console.error("Error deleting users:", error);
  //   }
  // }
  async function TestEmail() {
    var email = firebase.functions().httpsCallable("emails-customEmail");
    let data = {
      subject: "Congratulations on picking your Fantasy Fullbore team",
      email: "kenty26@gmail.com",
      paragraphs: [
        `Hi Mr JCT Kent`,
        `Thank you for picking your team: the VeeBulls`,
        `By taking part in Fantasy Fullbore, you are helping the Great Britain Rifle Team to Canada 2023 and the Great Britain Palma Team to SouthAfrica 2024.`,
        `In order to complete your entry, you need to transfer your entry fee to the GB Palma Team account`,
        `Account Number: XXXXXX`,
        `Sort code: XX-XX-XX`,
        `Reference: ${`name reference`}`,
        `Thanks so much for your support`,
        `The Fantasy Fullbore Team`,
      ],
    };
    email(data)
      .then(() => {
        console.log(data, "email sent");
      })
      .catch((error) => {
        console.log(error, "email sending error");
      });
  }
  async function loadScores() {
    console.log(scoreData);
    const scoreDataEntries = Object.entries(scoreData);
    const batchSize = 150;

    for (let i = 0; i < scoreDataEntries.length; i += batchSize) {
      const batch = props.firebase.db.batch();
      const batchEntries = scoreDataEntries.slice(i, i + batchSize);

      batchEntries.forEach(([trid, personScores]) => {
        const shooterRef = props.firebase.db.doc(`shooters/${trid}`);

        Object.entries(personScores).forEach(([key, value]) => {
          if (
            key !== "NAME" &&
            key !== "full_NAME" &&
            key !== "Init" &&
            key !== "Index"
          ) {
            scoreData[trid][key] = Number(value);
          }
        });

        console.log({ ...scoreData[trid] });

        batch.set(
          shooterRef,
          { currentYear: { ...scoreData[trid] } },
          { merge: true }
        );
      });

      try {
        await batch.commit();
      } catch (error) {
        console.log(error, "Error loading Scores");
      }
    }

    console.log(scoreData);
  }

  async function publishTeamScores() {
    const teamIndex = await props.firebase.db.doc(`teams/index`).get();
    const shootersData = await props.firebase.db.collection("shooters").get();
    const teams = await props.firebase.db.collection("teams").get();
    let shooters = {};
    if (!shootersData.empty) {
      shootersData.forEach((shoot) => {
        shooters[shoot.id] = { ...shoot.data() };
      });
    }

    if (
      !teamIndex.empty &&
      !teams.empty &&
      Object.entries(shooters).length !== 0
    ) {
      for (const team of teams.docs) {
        if (team.id !== "index") {
          console.log(team.id);
          const batch = props.firebase.db.batch();
          if (team.data().shooters) {
            const teamRef = props.firebase.db.doc(`teams/${team.id}`);
            const userTeamRef = props.firebase.db.doc(
              `users/${team.data.uid}/teams/${team.id}`
            );
            const teamIndexRef = props.firebase.db.doc(`teams/index`);
            let thisTeam = [];
            let Day_1_Total = 0;
            let Day_2_Total = 0;
            let Day_3_Total = 0;
            let Day_4_Total = 0;
            let Day_5_Total = 0;
            let GRAND_MULTIPLIER = 0;
            for (const shooter of team.data().shooters) {
              let person = {
                name: shooter.name,
                trid: Number(shooter.trid),
                Day_1_Total: shooters[shooter.trid].currentYear?.Day_1_Total
                  ? shooters[shooter.trid].currentYear.Day_1_Total
                  : 0,
                Day_2_Total: shooters[shooter.trid].currentYear?.Day_2_Total
                  ? shooters[shooter.trid].currentYear.Day_2_Total
                  : 0,
                Day_3_Total: shooters[shooter.trid].currentYear?.Day_3_Total
                  ? shooters[shooter.trid].currentYear.Day_3_Total
                  : 0,
                Day_4_Total: shooters[shooter.trid].currentYear?.Day_4_Total
                  ? shooters[shooter.trid].currentYear.Day_4_Total
                  : 0,
                Day_5_Total: shooters[shooter.trid].currentYear?.Day_5_Total
                  ? shooters[shooter.trid].currentYear.Day_5_Total
                  : 0,
                GRAND_MULTIPLIER: shooters[shooter.trid].currentYear
                  ?.GRAND_MULTIPLIER
                  ? shooters[shooter.trid].currentYear.GRAND_MULTIPLIER
                  : 0,
              };

              let personTotal =
                person.Day_1_Total +
                person.Day_2_Total +
                person.Day_3_Total +
                person.Day_4_Total +
                person.Day_5_Total +
                person.GRAND_MULTIPLIER;

              person.TotalPoints = personTotal;
              Day_1_Total = Day_1_Total + person.Day_1_Total;
              Day_2_Total = Day_2_Total + person.Day_2_Total;
              Day_3_Total = Day_3_Total + person.Day_3_Total;
              Day_4_Total = Day_4_Total + person.Day_4_Total;
              Day_5_Total = Day_5_Total + person.Day_5_Total;
              GRAND_MULTIPLIER = GRAND_MULTIPLIER + person.GRAND_MULTIPLIER;

              thisTeam.push(person);
            }
            let TotalPoints =
              Day_1_Total +
              Day_2_Total +
              Day_3_Total +
              Day_4_Total +
              Day_5_Total +
              GRAND_MULTIPLIER;
            batch.set(
              teamRef,
              {
                shooters: [...thisTeam],
                Day_1_Total,
                Day_2_Total,
                Day_3_Total,
                Day_4_Total,
                Day_5_Total,
                TotalPoints,
                GRAND_MULTIPLIER,
              },
              { merge: true }
            );
            batch.set(
              userTeamRef,
              {
                shooters: [...thisTeam],
                Day_1_Total,
                Day_2_Total,
                Day_3_Total,
                Day_4_Total,
                Day_5_Total,
                TotalPoints,
                GRAND_MULTIPLIER,
              },
              { merge: true }
            );
            batch.set(
              teamIndexRef,
              {
                [team.id]: {
                  ...teamIndex.data()[team.id],
                  Day_1_Total,
                  Day_2_Total,
                  Day_3_Total,
                  Day_4_Total,
                  Day_5_Total,
                  TotalPoints,
                  GRAND_MULTIPLIER,
                },
              },
              { merge: true }
            );
          }

          try {
            batch.commit();
            console.log(`updated  ${team.id}, ${team.data().teamName}`);
          } catch (error) {
            console.log(
              error,
              `Error saving ${team.id}, ${team.data().teamName}`
            );
          }
        }
      }
    }
  }

  // async function loadTeamIndex() {
  //   const teams = await props.firebase.db.collection("teams").get();

  //   if (!teams.empty) {
  //     let teamsObject = {};
  //     teams.forEach((team) => {
  //       console.log(team.data());
  //       if (team.id !== "index" && team.id !== "teamsObject") {
  //         teamsObject[team.id] = {
  //           Day_1_Total: team.data().Day_1_Total,
  //           Day_2_Total: team.data().Day_2_Total,
  //           Day_3_Total: team.data().Day_3_Total,
  //           Day_4_Total: team.data().Day_4_Total,
  //           Day_5_Total: team.data().Day_5_Total,
  //           cost: team.data().cost,
  //           name: team.data().name,
  //           teamName: team.data().teamName,
  //           points: 0,
  //           tid: team.id,
  //           uid: team.data().uid,
  //         };
  //       }
  //     });
  //     console.log(teamsObject);

  //     // Create an array of promises for all the set() operations
  //     const setPromises = Object.entries(teamsObject).map(([teamId, data]) => {
  //       return props.firebase.db
  //         .doc(`teams/${teamId}`)
  //         .set(data, { merge: true });
  //     });

  //     try {
  //       // Wait for all the set() operations to complete
  //       await Promise.all(setPromises);
  //       console.log("All set() operations completed.");
  //     } catch (error) {
  //       console.log(error, "error");
  //     }
  //   }
  // }

  async function saveTeamPositions() {
    const teamsIndex = await props.firebase.db.doc(`teams/index`);
    let allTeams = await teamsIndex.get();

    if (allTeams.exists) {
      const batch = props.firebase.db.batch();
      let teamsArray = [];

      ///Uncomment after day 1

      //ADD OR REMOVE THE GRAND AGG MULTIPLIER FROM EACH DAY TO ENSURE THE CORRECT CALUTLATION

      Object.entries(allTeams.data()).map(([key, value]) => {
        let Day_2_agg = value.Day_1_Total + value.Day_2_Total;
        let Day_3_agg =
          value.Day_1_Total + value.Day_2_Total + value.Day_3_Total;

        //  + value.GRAND_MULTIPLIER;

        let Day_4_agg =
          value.Day_1_Total +
          value.Day_2_Total +
          value.Day_3_Total +
          value.Day_4_Total;
        //+  value.GRAND_MULTIPLIER;
        let Day_5_agg =
          value.Day_1_Total +
          value.Day_2_Total +
          value.Day_3_Total +
          value.Day_4_Total +
          value.Day_5_Total +
          value.GRAND_MULTIPLIER;
        teamsArray.push({
          tid: key,
          ...value,

          //Uncomment each day

          Day_2_agg,
          Day_3_agg,
          Day_4_agg,
          Day_5_agg,
        });
      });

      // Add the Day_1_position key-value pair to the first object in the array
      teamsArray.sort((a, b) => b.Day_1_Total - a.Day_1_Total);

      let prevTotal = null;
      let position = null;

      teamsArray.forEach((team, index) => {
        console.log(team, index);

        if (team.Day_1_Total !== prevTotal) {
          position = index + 1;
          prevTotal = team.Day_1_Total;
        }

        team.Day_1_position = position;
      });
      //day 2

      teamsArray.sort((a, b) => b.Day_2_agg - a.Day_2_agg);
      prevTotal = null;
      position = null;

      teamsArray.forEach((team, index) => {
        if (team.Day_2_agg !== prevTotal) {
          position = index + 1;
          prevTotal = team.Day_2_agg;
        }

        team.Day_2_position = position;
      });
      // //day3
      teamsArray.sort((a, b) => b.Day_3_agg - a.Day_3_agg);
      prevTotal = null;
      position = null;
      console.log(teamsArray);
      teamsArray.forEach((team, index) => {
        // console.log(position, team, team.Day_3_agg, prevTotal, "<<<<<TEams3");
        if (team.Day_3_agg !== prevTotal) {
          position = index + 1;
          prevTotal = team.Day_3_agg;
        }
        if (team.name == "Rick Shouler") {
          console.log({ team, position, prevTotal });
        }
        team.Day_3_position = position;
      });
      // //day 4
      teamsArray.sort((a, b) => b.Day_4_agg - a.Day_4_agg);
      prevTotal = null;
      position = null;

      teamsArray.forEach((team, index) => {
        if (team.Day_4_agg !== prevTotal) {
          position = index + 1;
          prevTotal = team.Day_4_agg;
        }
        team.Day_4_position = position;
      });
      //day 5
      teamsArray.sort((a, b) => b.Day_5_agg - a.Day_5_agg);
      prevTotal = null;
      position = null;

      teamsArray.forEach((team, index) => {
        if (team.Day_5_agg !== prevTotal) {
          position = index + 1;
          prevTotal = team.Day_5_agg;
        }
        team.Day_5_position = position;
      });
      console.log(teamsArray);
      teamsArray.map((team) => {
        // console.log(team);
        const teamRef = props.firebase.db.doc(`teams/${team.tid}`);
        batch.set(
          teamRef,
          {
            Day_1_position: team.Day_1_position,
            Day_2_position: team.Day_2_position,
            Day_3_position: team.Day_3_position,
            Day_4_position: team.Day_4_position,
            Day_5_position: team.Day_5_position,
          },
          { merge: true }
        );
      });
      const teamsObject = teamsArray.reduce((acc, team) => {
        acc[team.tid] = team;
        return acc;
      }, {});
      batch.set(teamsIndex, teamsObject, { merge: true });
      console.log(teamsArray, teamsObject);
      try {
        await batch.commit();
      } catch (error) {
        console.log(error, "Error - settign positions");
      }
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isPasswordCorrect && (
        <PasswordPopup onPasswordSubmit={handlePasswordSubmit} />
      )}
      {isPasswordCorrect && (
        <Box sx={{ mt: 10 }}>
          <AdminNav />
          <Box sx={{ w: "100%", minHeight: "150px" }}>
            <Typography variant="h4">
              Excel to JSON for Shooters details
            </Typography>
            <ExcelReader />
          </Box>
          <Box sx={{ w: "100%" }}>
            <Typography variant="h4">CSV to array of Objects</Typography>
            <CsvLoader />
          </Box>
          <Box sx={{ w: "100%" }}>
            <Typography variant="h4">CSV to an Object</Typography>
            <CsvToJsonConverter />
          </Box>
          <Box sx={{ w: "100%" }}>
            {/* <Typography variant="h4">Load people</Typography> */}

            {/* <Button onClick={() => loadPeople()}>Load Shooters</Button> */}
            {/* <Button disabled={true} onClick={() => deleteShooters()}>
              Delete Shooters
            </Button>

            <Button disabled={true} onClick={() => deleteUsers()}>
              Delete Users
            </Button> */}
          </Box>

          <Box>
            <Typography variant="h4">Test Email</Typography>
            Test Email
            <Button disabled={true} onClick={() => TestEmail()}>
              TEst Email
            </Button>
          </Box>
          <Box sx={{ mt: 8, display: "flex", justifyContent: "space-evenly" }}>
            <Box>
              <Button onClick={() => loadScores()}>Load Scores</Button>
            </Box>
            <Box>
              <Button onClick={() => publishTeamScores()}>
                Publish Team Scores
              </Button>
            </Box>
            <Box>
              <Button onClick={() => saveTeamPositions()}>
                Save Team Positions
              </Button>
            </Box>
            {/* <Box>
              <Button onClick={() => loadTeamIndex()}>Load index</Button>
            </Box> */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(Admin);
