import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { withFirebase } from "../Firebase";
import TridLookup from "../Dashboard/tridLookup";
import ConfirmProfile from "../Dashboard/confirmProfile";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Toolbar,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Close } from "@mui/icons-material";
import NunitoVariableFont from "../Fonts/Nunito-VariableFont_wght.ttf";
import LOGO from "../static/images/logo.png";
import MyLocationIcon from "@mui/icons-material/MyLocation";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  height: "75vh",
  bgcolor: "background.paper",
  p: 4,
  overflow: "scroll",
  pt: "90px",
};

const instructions = [
  {
    index: 1,
    header: `So what is this? Can you give me a quick summary?`,
    paras: [
      `Welcome to Fantasy Fullbore! A fundraising competition for the
    GB Palma Tour to South Africa in March 2024 and the GBRT Team to
    Canada in August 2023.`,
      `For a cost of £10, participants get to select a team of 8
    shooters from those who have entered the Grand Aggregate and
    consented to be picked.`,
      `Each shooter has a differing cost depending on their seed, and
    participants have a maximum team budget of £5,000,000 to play
    with.`,
      `Each member of your team earns points for how they do each day
    of the Grand, how many "possibles" they shoot and where they
    finish overall. Your team score is the sum of all 8 firers.`,
      `At the end of the Grand Aggregate there will be a cash prize for
    the team with the highest score, a prize for the highest scoring team with a maximum budget of £500,000 and a prize for the highest scoring team with a maximum budget of £40,000!`,
    ],
  },
  {
    index: 2,
    header: `Sounds fun! I want to learn more! So where do I start?`,
    paras: [
      ` First, you need to pick your team! You have £5,000,000 to pick
    eight shooters. Every shooter has been assigned a 'seed' and a
    cost amount based off this seed, the higher seeds cost more, and
    each shooter has a cost that ranges from £5000 to £2.5m!`,
    ],
  },
  {
    index: 3,
    header: `How have you determined everyone's seed?`,
    paras: [
      `Good question! The key here is "Top 50"! The idea here was to create
    a rule which was easy to understand, easy to implement and,
    considering these two points, also gave the best reflection of how
    well someone has performed. There are probably a whole host of ways
    to do this, so please do not take the seedings list as definitive or
    an "official" indication of any kind of ranking or take offence if
    you disagree with where a shooter places! To summarise how the
    seedings were calculated:`,
      ` - A shooter earns "ranking points" by finishing in the top 50 of the Grand Aggregate, Queen's (King's) Prize or the St George's. Points are summed across all 3 competitions to give each shooter a total amount of ranking points.`,
      ` - The higher up a shooter finishes in one of these competitions, the more points they earn! The Grand has the most points up for grabs, followed by the Queen's (King's) Prize, then the St George's.`,
      ` - Points are accumulated over a 3 year period, with more recent years earning you more than previous years.`,
      `Therefore a multiple "triple X" shot will have more ranking points, and therefore be seeded higher, than a shooter that finished 50th in the St George's Competition 3 years ago!`,
      `The ONLY exception is international representation from overseas (welcome friends!) who will have a "touring team premium" assigned to them to take into account they have done very well to be selected for their country to get here, but may not have shot in the UK for a while (or ever!)`,
    ],
  },
  {
    index: 4,
    header: `Right that makes sense, but what about non seeded shooters - can I still select them?`,
    paras: [
      `Absolutely! There are many reasons shooters are here (e.g. haven't shot for a while, brand new to the sport etc) so everyone here has an entry cost of £5000. This means quite a few shooters are costed this way, and we like to think a number of them in this group are massively under-valued, or bargain picks!`,
    ],
  },
  {
    index: 5,
    header: `I'm shooting the Grand Aggregate, do I have to pick myself?`,
    paras: [`No! But we encourage all shooters to believe in themselves! 🙂`],
  },
  {
    index: 6,
    header: `Hang on, I want to pick someone I know is shooting the Grand Aggregate but I can't see them? Why can't I pick them?`,
    paras: [
      `Every shooter needs to consent to be eligible for selection (due to GDPR regulations). Chances are they haven't consented to be on the site! If you get them to access this site then they can consent in less than a minute, then you should be able to pick them following this.`,
    ],
  },
  {
    index: 7,
    header: `I'm not a shooter can I still enter a team?`,
    paras: [
      `Absolutely! Please do! And share the competition with your friends and family!`,
    ],
  },
  {
    index: 8,
    header: `Can I enter more than 1 team?`,
    paras: [
      `Yes please! There are no limits to the number of teams you can enter!`,
    ],
  },
  {
    index: 9,
    header: `Argh! I made a mistake! Can I change my team composition after I've submitted it?`,
    paras: [
      `Yes you can make unlimited changes up until 8:00am Saturday 15th July, when all teams will be locked!`,
    ],
  },
  {
    index: 10,
    header: `So how do I win?`,
    paras: [
      `I like your confidence! Again, "Top 50" is the key here! Each shooter earns points in three specific ways:`,
      `1) Finishing Top 50 on each day (maximum 50 points for a shooter for being top of that day in isolation)`,
      `2) Getting a possible for each shoot (5 points for a possible, 10 points for a possible with max V count)`,
      `3) Finishing Top 50 of the grand aggregate (maximum 150 points for a shooter here)`,
      `We aggregate the points earned for your 8 shooters across each day and total where they finish in the end. The team with the highest points at the end wins!`,
    ],
  },
  {
    index: 11,
    header: `OK so I know how to win, but WHAT can I win?`,
    paras: [
      `Someone who cuts to the chase. I like it. Prizes are ultimately based on number of entrants but there are 3 key prizes on offer:`,
      `"The Overall Winner" : The team that scores the highest number of points over the Grand Aggregate.`,
      `"The Budget Winner": A team with the highest number of points BUT with a team that costs no more than £500,000`,
      `"The Unseeded": A team with the highest number of points BUT with a team that costs no more than £40,000. i.e. - A prize for the highest team of unseeded shooters!`,
    ],
  },
  {
    index: 12,
    header: `This is great! How much to enter?`,
    paras: [
      `Each team you enter costs £10. Once you have finalised a team you will receive a notification to bank transfer with reference:`,
      `SURNAME FIRST INITIAL - FANTR`,
      `E.G. If John Smith entered a team, the reference would be:`,

      `SMITHJ - FANTR`,
    ],
  },
  {
    index: 13,
    header: `Wonderful! I have another question though - but I can't think of it now, can I get in touch with anyone?`,
    paras: [
      `Just email fantasyfullbore@gmail.com and the team of lovely kind volunteers will endeavour to help you.`,
    ],
  },
];

const Instructions = (props) => {
  const [expanded, setExpanded] = React.useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={style}>
      <Box
        sx={{
          pl: { xs: 0, sm: 2, md: 4, lg: 8 },
          pr: { xs: 0, sm: 2, md: 4, lg: 8 },
        }}
      >
        {props.modal && (
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            onClick={() => props.setInstructionsOpen(false)}
          >
            <Close color="primary" />
          </IconButton>
        )}
        <Typography variant="h3" color="primary" align="center">
          How to play
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MyLocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Pick a team of 8 within your allotted budget" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MyLocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Points are scored over the Grand Aggregate" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MyLocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Score 5 points for every possible (50/50, 75/75, 105/105). 10 points
          if this comes with maximum Vs!"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MyLocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="If your shooter finishes in the top 50 on each day of the Grand
          Aggregate in isolation, they win points (up to 50)"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MyLocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Teams cost £10 each" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <MyLocationIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Prizes for highest team score with max budget limit, £500,000 budget
          limit and £40,000 budget limit."
            />
          </ListItem>
        </List>

        {/* {instructions.map((instruction) => (
          <Accordion
            key={instruction.index}
            onChange={handleChange(instruction.index)}
            expanded={expanded === instruction.index}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{instruction.header}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {instruction.paras.map((para, index) => (
                <Typography variant="body1" paragraph key={index}>
                  {para}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="body1" paragraph>
          Thanks, we hope you enjoy this first instalment of Fantasy Fullbore.
          We'll do our best to make it a seamless and enjoyable competition,
          thank you so much for entering, thank you for helping the upcoming GB
          tours as well, and best of luck!
        </Typography>
        <Typography variant="body1" paragraph>
          The Fantasy Fullbore Team 🙂
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Instructions;
