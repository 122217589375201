import React, { useState, useEffect } from "react";
import {
  Badge,
  Box,
  IconButton,
  Drawer,
  Stack,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Card,
  CardContent,
  Typography,
  Avatar,
  Tabs,
  Tab,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import CircularProgress from "@mui/material/CircularProgress";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import PickedTeam from "./pickedTeam.js";
import ShooterGrid from "./shooterGrid.js";
import ShooterTable from "./shooterTable.js";
import GroupIcon from "@mui/icons-material/Group";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { withFirebase } from "../Firebase/context.js";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const theme = createTheme();

const debounce = (callback, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};
const drawerWidth = 240;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const Shooters = (props) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [pickedTeam, setPickedTeam] = useState([]);
  const [disabledButton, setDisabledButton] = useState([]);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [shooterDetails, setShooterDetails] = useState(false);
  const [localData, setLocalData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [minCost, setMinCost] = useState(5000);
  const [maxCost, setMaxCost] = useState(2500000);
  const [filteredArray, setFilteredArray] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);
  const [teamToEdit, setTeamToEdit] = useState(false);

  const toggleDrawer = () => setOpen(!open);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePickUser = (user) => {
    let team = [...pickedTeam];
    team.push(user);
    setPickedTeam(team);
  };
  const compareBySeed = (a, b) => {
    if (a.cost > b.cost) {
      return -1;
    } else if (a.cost < b.cost) {
      return 1;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    props.firebase.db
      .doc("shooters/index")
      .get()
      .then((result) => {
        let ordered = [];
        Object.values(result.data()).map((indv) => {
          if (indv.shootingGrand && indv.consent) {
            ordered.push(indv);
          }
        });
        ordered.sort(compareBySeed);
        setShooterDetails(ordered);
        setFilteredArray(ordered);
      });

    return () => {
      setShooterDetails(false);
      setFilteredArray(false);
    };
  }, []);

  useEffect(() => {
    if (!props.editTeam) {
      const retrieveFromLocalStorage = () => {
        const storedData = localStorage.getItem("fantasyFullbore");
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setLocalData(parsedData);
          if (parsedData.teamPicking) {
            setPickedTeam(parsedData.teamPicking);
          }
        } else {
          setLocalData(false);
        }
      };
      retrieveFromLocalStorage();
    }
  }, [props.editTeam]);
  useEffect(() => {
    if (props.editTeam && props.tid) {
      const retrieveFromSessionStorage = async () => {
        const storedData = localStorage.getItem("fantasyFullboreLogin");
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          let profileUid = parsedData.profileLogin;
          if (profileUid !== null) {
            const team = await props.firebase.db
              .doc(`users/${profileUid}/teams/${props.tid}`)
              .get();
            if (team.exists) {
              const shooterPromises = team
                .data()
                .shooters.map(async (shooter) => {
                  const result = await props.firebase.db
                    .doc(`shooters/${shooter.trid}`)
                    .get();
                  return { trid: shooter.trid, ...result.data() };
                });

              const shooters = await Promise.all(shooterPromises);
              setTeamToEdit({ tid: team.id, ...team.data() });
              setPickedTeam(shooters);
            }
          }
        }
      };
      retrieveFromSessionStorage();
    }
  }, [props.editTeam, props.tid]);
  useEffect(() => {
    const filterArray = () => {
      if (shooterDetails) {
        const filtered = shooterDetails.filter((item) => {
          const matchSearchText = item.seed_name
            .toLowerCase()
            .includes(searchText.toLowerCase());
          const matchCost = item.cost >= minCost && item.cost <= maxCost;

          return matchSearchText && matchCost;
        });
        setFilteredArray(filtered);
        setIsFiltering(false);
      }
    };
    const delayedFilter = debounce(filterArray, 1000);
    delayedFilter();

    // Cleanup debounce timer when component unmounts
    return () => clearTimeout(delayedFilter);
  }, [searchText, minCost, maxCost]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "calc(100vh - 80px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "scroll",
          backgroundColor: "#f0f0f0",
          width: { xs: "100%", md: "70vw" },
        }}
      >
        <Box component={Paper} sx={{ m: 2, p: 1 }}>
          <Typography variant="h6" align="left" paragraph>
            Filters:
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "30%" } }}>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #999999",
                  },
                }}
                label="Name Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setIsFiltering(true);
                }}
                fullWidth
                InputProps={{
                  endAdornment: isFiltering && <CircularProgress size={20} />,
                }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "90%", sm: "30%" },
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mt: { xs: 2, sm: 0 },
              }}
            >
              <Slider
                label="Cost Range"
                value={[minCost, maxCost]}
                onChange={(_, newValue) => {
                  setMinCost(newValue[0]);
                  setMaxCost(newValue[1]);
                }}
                min={5000}
                max={2500000}
              />

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <Typography>£{minCost.toLocaleString()}</Typography>
                <Typography>£{maxCost.toLocaleString()}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: { xs: "100%", sm: "30%" } }}>
              <Button
                onClick={() => {
                  setFilteredArray(shooterDetails);
                  setMinCost(5000);
                  setMaxCost(2500000);
                  setSearchText("");
                }}
              >
                reset filters
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          component={Paper}
          sx={{
            ml: 2,
            mr: 2,
            p: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <AutoFixHighIcon sx={{ color: "#FFDC00" }} fontSize="large" />
          </Box>
          <Box>
            <Typography>
              Looking for inspiration? Check out last years teams:
            </Typography>
            <Button href="/teams?year=2023">Last years Teams</Button>
          </Box>
          <Box>
            <AutoFixHighIcon sx={{ color: "#FFDC00" }} fontSize="large" />
          </Box>
        </Box>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Grid" id="tab-0" />
          <Tab label="Table" id="tab-1" />
        </Tabs> */}
        <Box sx={{ height: "calc(100vh - 80px)", overFlowY: "scroll" }}>
          {/* <TabPanel value={value} index={0}> */}
          {shooterDetails && !filteredArray && (
            <ShooterGrid
              shooterDetails={shooterDetails}
              pickedTeam={pickedTeam}
              setPickedTeam={setPickedTeam}
              localData={localData}
              setLocalData={setLocalData}
              editTeam={props.editTeam}
            />
          )}
          {filteredArray && (
            <ShooterGrid
              shooterDetails={filteredArray}
              pickedTeam={pickedTeam}
              setPickedTeam={setPickedTeam}
              localData={localData}
              setLocalData={setLocalData}
              editTeam={props.editTeam}
            />
          )}
          {/* </TabPanel> */}
          {/* <TabPanel value={value} index={1}>
            {shooterDetails && (
              <ShooterTable
                shooterDetails={shooterDetails}
                pickedTeam={pickedTeam}
                setPickedTeam={setPickedTeam}
              />
            )}
          </TabPanel> */}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          height: "calc(100vh - 80px)",
          overflowY: "scroll",
          backgroundColor: "#fff",
          width: { xs: 0, md: "30vw" },
          display: { xs: "none", sm: "flex" },
        }}
      >
        <PickedTeam
          editTeam={props.editTeam}
          setEditTeam={props.setEditTeam}
          teamToEdit={teamToEdit}
          pickedTeam={pickedTeam}
          setPickedTeam={setPickedTeam}
          localData={localData}
          setLocalData={setLocalData}
        />
      </Box>
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#fff",
            // top: 64,
            // height: 'calc(100% - 64px)',
            [theme.breakpoints.up("md")]: {
              position: "relative",
            },
          },
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={toggleDrawer}
      >
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          spacing={0}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronRightIcon />
          </IconButton>
        </Stack>
        <PickedTeam
          pickedTeam={pickedTeam}
          setPickedTeam={setPickedTeam}
          editTeam={props.editTeam}
          setEditTeam={props.setEditTeam}
          localData={localData}
          setLocalData={setLocalData}
          teamToEdit={teamToEdit}
        />
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          height: "calc(100vh - 64px)",
          overflowY: "scroll",
          backgroundColor: "#fff",
          width: { xs: 50, md: "0" },
          display: { xs: "flex", md: "none" },
        }}
      >
        <Stack
          direction="column"
          justifyContent="start"
          alignItems="center"
          spacing={2}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
          <CurrencyPoundIcon color="success" />
          <Badge
            badgeContent={pickedTeam.length}
            color={pickedTeam.length < 9 ? "primary" : "error"}
          >
            <GroupIcon color="action" />
          </Badge>
        </Stack>
      </Box>
    </Box>
  );
};

export default withFirebase(Shooters);
