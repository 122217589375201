export const DASHBOARD = "/";
export const ADMIN = "/admin";
export const ADMINTEAMS = "/admin/teams";
export const ADMINSETUP = "/admin/setup";
export const ADMINFUNCTIONS = "/admin/functions";
export const ADMINMIGRATIONS = "/admin/migrations";
export const ADMINSHOOTERS = "/admin/shooters";
export const PICK = "/teamselection";
export const TEAMS = "/teams";

export const HOWTOPLAY = "/howtoplay";

export const PROFILE = "/profile";
