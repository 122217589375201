import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TeamAccordion from "./teamAccordion";
import { withFirebase } from "../Firebase";
import TeamEditDetails from "./teamEditDetails";
import EditName from "./editName";

const Teams = (props) => {
  const currentDate = new Date();
  const targetDate = new Date("2023-07-15T07:00:00+01:00"); // 8am BST on 15th July
  const [nameOpen, setNameOpen] = useState(false);
  const [tid, setTid] = useState(false);
  const [name, setName] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [preGrand, setPreGrand] = useState(false);

  useEffect(() => {
    checkTime();
  }, []);

  const checkTime = () => {
    const currentDate = new Date();
    const targetDate = new Date("2024-07-20T07:00:00+01:00"); // 8am BST on 15th July

    if (currentDate > targetDate) {
      setPreGrand(false);
    } else {
      setPreGrand(true);
    }
  };
  useEffect(() => {
    const unsubscribe = props.firebase
      .allUserTeams(props.uid)
      .onSnapshot((results) => {
        console.log(results);
        let loadedTeams = [];
        if (!results.empty) {
          let teams = {};
          results.forEach((result) => {
            teams[result.id] = { ...result.data() };
          });

          Object.entries(teams).map(([key, value]) => {
            loadedTeams.push({ tid: key, ...value });
          });
          loadedTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
        }
        console.log(loadedTeams);
        props.setTeams(loadedTeams);
      });
    return () => unsubscribe();
  }, [props.uid]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="h3" component="h1">
          My Teams
        </Typography>
      </Box>
      {nameOpen && (
        <EditName
          tid={tid}
          nameOpen={nameOpen}
          setTid={setTid}
          setNameOpen={setNameOpen}
          setName={setName}
          name={name}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {props.teams ? (
          <Paper sx={{ width: "750px" }}>
            <Box sx={{ p: "0 16px", display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "30%" }}>
                <Typography variant="caption">Name</Typography>
              </Box>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  pr: "24px",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 1</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 2</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 3</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 4</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 5</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">GM</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Total</Typography>
                </Box>
              </Box>
            </Box>
            {props.teams.map((team, index) => (
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box sx={{ width: "30%", display: "flex" }}>
                      {team.position !== null && (
                        <Typography sx={{ mr: 2 }}>{team.position}</Typography>
                      )}
                      <Box>
                        <Typography
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            textAlign: "left",
                          }}
                        >
                          {team.teamName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "70%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        {team.day1points ? team.day1points : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.day2points ? team.day2points : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.day3points ? team.day3points : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.day4points ? team.day4points : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.day5points ? team.day5points : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.day5points ? team.day5points : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.day5points ? team.day5points : 0}
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <TeamEditDetails
                    team={team}
                    preGrand={preGrand}
                    setTid={setTid}
                    setNameOpen={setNameOpen}
                    setName={setName}
                  />
                  <TeamAccordion team={team} />
                  {preGrand && (
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={() => {
                          props.setEditTeam(true);
                          props.setTid(team.tid);
                        }}
                      >
                        Edit members
                      </Button>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        ) : (
          <Box>
            <Typography>Loading...</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withFirebase(Teams);
