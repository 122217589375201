import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TeamConfirmationModal from "./teamConfirmationModal";
import { withFirebase } from "../Firebase";
import firebase from "firebase/compat/app";

const PickedTeam = (props) => {
  const teamof = 8;
  const [totalCost, setTotalCost] = useState(0);
  const [budgetRemaining, setBudgetRemaining] = useState(0);
  const [teamNumbers, setTeamNumbers] = useState(0);
  const [budgetExceeded, setBudgetExceeded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const budget = 5000000;
  const [updateLoading, setUpdateLoading] = useState(false);
  const [preGrand, setPreGrand] = useState(false);

  const checkTime = () => {
    const currentDate = new Date();
    const targetDate = new Date("2024-07-20T07:00:00+01:00"); // 8am BST on 15th July

    if (currentDate > targetDate) {
      setPreGrand(false);
    } else {
      setPreGrand(true);
    }
  };

  useEffect(() => {
    checkTime();
  }, []);
  useEffect(() => {
    const updateTeamInfo = () => {
      if (props.pickedTeam.length !== 0) {
        let cost = props.pickedTeam.reduce(
          (acc, shooter) => acc + shooter.cost,
          0
        );
        let budg = budget - cost;
        setTotalCost(cost);
        setBudgetRemaining(budg);
        console.log(props.pickedTeam.length);
        setTeamNumbers(teamof - props.pickedTeam.length);
        if (budg < 0) {
          setBudgetExceeded(true);
        } else {
          setBudgetExceeded(false);
        }
      } else {
        setTeamNumbers(8);
        setTotalCost(0);
        setBudgetRemaining(budget);
        setTeamNumbers(teamof);
      }
    };
    updateTeamInfo();

    return () => {
      console.log("cleanup function called");
    };
  }, [props.pickedTeam, budget]);

  function handleRemove(shooter) {
    const updatedPickedTeam = props.pickedTeam.filter(
      (item) => item.trid !== shooter.trid
    );
    props.setPickedTeam(updatedPickedTeam);

    const dataToStore = JSON.stringify({
      ...props.localData,
      teamPicking: updatedPickedTeam,
    });
    props.setLocalData({ ...props.localData, teamPicking: updatedPickedTeam });
    if (!props.editTeam) {
      localStorage.setItem("fantasyFullbore", dataToStore);
    }
  }

  async function saveTeam() {
    setModalOpen(true);
  }
  function compareArrays(array1, array2, key) {
    const addedValues = [];
    const removedValues = [];

    // Get all values for the specified key in the first array
    const values1 = array1.map((obj) => obj[key]);

    // Get all values for the specified key in the second array
    const values2 = array2.map((obj) => obj[key]);

    // Compare the values and identify added/removed values
    for (const value of values1) {
      if (!values2.includes(value)) {
        removedValues.push(value);
      }
    }

    for (const value of values2) {
      if (!values1.includes(value)) {
        addedValues.push(value);
      }
    }

    return { addedValues, removedValues };
  }
  async function updateTeam() {
    setUpdateLoading(true);
    console.log(props.teamToEdit, props.pickedTeam, props.teamToEdit);
    let adjustments = compareArrays(
      props.pickedTeam,
      props.teamToEdit.shooters,
      "trid"
    );
    let shooters = [];
    props.pickedTeam.map((shot) => {
      shooters.push({ name: shot.seed_name, trid: shot.trid });
    });
    const batch = props.firebase.db.batch();
    //remove
    adjustments.removedValues.map((trid) => {
      const shooterRef = props.firebase.db.doc(`shooters/${trid}`);
      batch.set(
        shooterRef,
        {
          teamCount: firebase.firestore.FieldValue.increment(-1),
          teams: {
            [props.teamToEdit.tid]: firebase.firestore.FieldValue.delete(),
          },
        },
        { merge: true }
      );
    });
    adjustments.addedValues.map((trid) => {
      const shooterRef = props.firebase.db.doc(`shooters/${trid}`);
      batch.set(
        shooterRef,
        {
          teamCount: firebase.firestore.FieldValue.increment(1),
          teams: {
            [props.teamToEdit.tid]: props.teamToEdit.teamName,
          },
        },
        { merge: true }
      );
    });
    const userTeamRef = props.firebase.db.doc(
      `users/${props.teamToEdit.uid}/teams/${props.teamToEdit.tid}`
    );
    batch.set(userTeamRef, { shooters, cost: totalCost }, { merge: true });
    const teamRef = props.firebase.db.doc(`teams/${props.teamToEdit.tid}`);
    batch.set(teamRef, { shooters, cost: totalCost }, { merge: true });
    const teamIndex = props.firebase.db.doc(`teams/index`);
    batch.set(
      teamIndex,
      { [props.teamToEdit.tid]: { cost: totalCost } },
      { merge: true }
    );
    console.log(adjustments);
    try {
      batch.commit();
      props.setEditTeam(false);
      props.setPickedTeam([]);
    } catch (error) {
      console.log(error, "ERROR updating team");
    }
  }
  async function clearTeam() {
    props.setPickedTeam([]);
    const dataToStore = JSON.stringify({
      ...props.localData,
      teamPicking: [],
    });
    props.setLocalData({ ...props.localData, teamPicking: [] });
    localStorage.setItem("fantasyFullbore", dataToStore);
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2, width: "100%" }}>
      <TeamConfirmationModal
        pickedTeam={props.pickedTeam}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setPickedTeam={props.setPickedTeam}
        setLocalData={props.setLocalData}
        localData={props.localData}
        totalCost={totalCost}
      />
      <Box sx={{ borderBottom: "1px solid", mb: 2 }}>
        <Typography variant="h5" align="left">
          {props.editTeam && props.teamToEdit?.teamName
            ? `Team: ${props.teamToEdit.teamName}`
            : `Your Team - £10 per play`}
        </Typography>
        <Typography align="left">Budget: £{budget.toLocaleString()}</Typography>
        <Typography align="left" color={budgetExceeded ? `red` : `black`}>
          Budget Remaining: £{budgetRemaining.toLocaleString()}
        </Typography>
        <Typography align="left">
          Total Cost: £{totalCost.toLocaleString()}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "65vh",
          overflowY: "scroll",
        }}
      >
        {props.pickedTeam &&
          props.pickedTeam.map((shooter) => (
            <Box
              key={shooter.trid}
              component={Paper}
              sx={{
                width: "100%",
                mr: 2,
                p: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <Box sx={{ width: 50, textAlign: "left" }}>
                <Avatar
                  sx={{ width: 20, height: 20 }}
                  alt={shooter.name}
                  src=""
                >
                  <AccountCircleIcon />
                </Avatar>
              </Box> */}
              <Box sx={{ flex: 1 }}>
                <Typography variant="body1" component="h5">
                  {shooter.seed_name}
                </Typography>

                <Typography variant="body2" component="h6" color="primary">
                  £{shooter.cost.toLocaleString()}
                </Typography>
              </Box>
              <Box sx={{ width: 50 }}>
                <IconButton
                  sx={{
                    color: "gray",
                  }}
                  onClick={() => handleRemove(shooter)}
                >
                  <CloseIcon />
                </IconButton>{" "}
              </Box>
            </Box>
          ))}
      </Box>
      {props.editTeam ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {preGrand && (
            <Button
              variant={
                !budgetExceeded && props.pickedTeam.length === 8
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              disabled={
                budgetExceeded || props.pickedTeam.length !== 8 || updateLoading
              }
              fullwidth="true"
              onClick={() => updateTeam()}
            >
              {props.pickedTeam.length < 8
                ? `Add ${teamNumbers} more firers`
                : "Update Team"}
            </Button>
          )}
          <Button
            sx={{ mt: 2 }}
            color="primary"
            fullwidth="true"
            onClick={() => {
              props.setPickedTeam();
              props.setEditTeam(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {preGrand ? (
            <Button
              variant={
                !budgetExceeded && props.pickedTeam.length === 8
                  ? "contained"
                  : "outlined"
              }
              color="primary"
              disabled={budgetExceeded || props.pickedTeam.length !== 8}
              fullwidth="true"
              onClick={() => saveTeam()}
            >
              {console.log(props.pickedTeam.length)}
              {props.pickedTeam.length < 8
                ? `Add ${teamNumbers} more firers`
                : "Submit"}
            </Button>
          ) : (
            <Button disabled={true}>
              Message one has been passed. No more entries please
            </Button>
          )}
          <Button
            sx={{ mt: 2 }}
            color="primary"
            fullwidth="true"
            onClick={() => clearTeam()}
          >
            Remove all
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(PickedTeam);
