import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { withFirebase } from "../Firebase";
import Toast from "../Toast";
import firebase from "firebase/compat/app";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",

  bgcolor: "background.paper",
  p: 4,
  overflow: "scroll",
};
const OTPModal = (props) => {
  const [sending, setSending] = useState(false);
  const [email, setEmail] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const handleClose = () => {
    props.setOtpModalOpen(false);
    setConfirmation(false);
    setSending(false);
  };
  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }
  async function sendEmailLink() {
    setSending(true);
    let player = await props.firebase.getPlayer(email.toLowerCase()).get();
    if (!player.empty) {
      player.forEach(async (doc) => {
        let otp = generateRandomString();

        console.log(doc.data());
        var emailSend = firebase
          .functions()
          .httpsCallable("emails-customEmail");
        let data = {
          subject: "Edit your Fantasy Fullbore Team",
          email: doc.data().email,
          paragraphs: [
            `Hi ${doc.data().name}`,

            `Thank you for supporting the NRA Channel Islands Team 2025.`,
            `In order to view your profile or edit your team (before Message one), please click on the link below and enter this one time password:`,
            `${otp}`,
            `Please note this will only work once, and you will need to request a new one if you have subsequently created a new team.`,
            `Thanks so much for your support`,
            `The Fantasy Fullbore Team`,
          ],
          actions: {
            0: {
              type: "outlined",
              title: "Edit your team",
              url: `https://FantasyFullbore.co.uk/profile?uid=${doc.id}`,
            },
          },
        };

        try {
          await props.firebase.db.doc(`users/${doc.id}`).update({ otp });
          await emailSend(data);
          setEmail("");

          setConfirmation(true);
        } catch (error) {
          console.log(error, "ERROR");
        }
      });
    }
  }

  return (
    <Box>
      <Modal
        open={props.otpModalOpen}
        onClose={handleClose}
        aria-labelledby="editBox"
        aria-describedby="editBox"
      >
        <Box sx={style}>
          {!confirmation ? (
            <Box>
              <Typography paragraph>
                Enter your email address to recieve a one time password to
                access your profile and edit your teams.
              </Typography>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    border: "1px solid #999999",
                  },
                  mb: 2,
                }}
                label="Email"
                id="outlined-size-small"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  // setError({ ...error, name: "" });
                }}
                size="small"
                fullWidth
              />

              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={sending}
                onClick={() => {
                  sendEmailLink();
                }}
              >
                Send One Time Password
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography paragraph>
                If your email matches our database you will recieve an email
                with a link to view your profile and edit your teams.
              </Typography>
              <Typography variant="caption" paragraph>
                Please check your junk folder too.
              </Typography>
              <Button
                color="primary"
                fullWidth
                onClick={() => {
                  handleClose();
                }}
              >
                close
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
export default withFirebase(OTPModal);
