import React, { useState } from "react";

const CsvToJsonConverter = () => {
  const [jsonObject, setJsonObject] = useState(null);
  const [keyField, setKeyField] = useState(""); // State for the key field

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csv = e.target.result;
      const lines = csv.split("\n");
      const headers = lines[0].split(",");

      const result = {}; // Change to an object instead of an array

      const keyFieldIndex = headers.findIndex((header) => {
        const sanitizedHeader = header.replace(/\r/g, ""); // Remove special characters from header
        return sanitizedHeader === keyField;
      });

      if (keyFieldIndex === -1) {
        // Key field not found
        console.error("Key field not found in headers");
        setJsonObject(null);
        return;
      }

      for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");

        if (currentLine.length === headers.length) {
          const objKey = currentLine[keyFieldIndex].replace(/\r/g, ""); // Use key field index to get the object key
          const obj = {};

          for (let j = 0; j < headers.length; j++) {
            let value = currentLine[j] || ""; // Assign empty string for undefined values

            if (value) {
              value = value.trim(); // Trim whitespace if value is defined
            }

            const sanitizedValue = value.replace(/\r/g, ""); // Remove special characters
            const sanitizedKey = headers[j].replace(/\r/g, ""); // Remove special characters from headers

            if (j !== keyFieldIndex) {
              obj[sanitizedKey] = sanitizedValue;
            }
          }

          result[objKey] = obj; // Assign object to the specified key
        }
      }

      setJsonObject(result);
    };

    reader.readAsText(file);
  };

  const handleKeyFieldChange = (event) => {
    setKeyField(event.target.value);
  };

  return (
    <div>
      <div>
        <label htmlFor="keyField">Key Field: </label>
        <input
          id="keyField"
          type="text"
          value={keyField}
          onChange={handleKeyFieldChange}
        />
      </div>
      <input type="file" onChange={handleFileUpload} />
      {jsonObject && <pre>{JSON.stringify(jsonObject, null, 2)}</pre>}
    </div>
  );
};

export default CsvToJsonConverter;
