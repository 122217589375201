import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";

const AdminFunctions = (props) => {
  const [teams, setTeams] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [teamDetails, setTeamDetails] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const unsubscribe = props.firebase.allTeams().onSnapshot((results) => {
      console.log(results);
      if (results.exists) {
        let loadedTeams = [];

        Object.entries(results.data()).map(([key, value]) => {
          let reconciled = false;
          if (value.reconciled) {
            reconciled = true;
          }
          loadedTeams.push({ tid: key, ...value, reconciled });
        });
        loadedTeams.sort((a, b) => {
          if (a.teamName === "" && b.teamName === "") {
            return 0; // no change in order for empty team names
          } else if (a.teamName === "") {
            return 1; // a comes after b if a has an empty team name
          } else if (b.teamName === "") {
            return -1; // a comes before b if b has an empty team name
          } else {
            return a.teamName.localeCompare(b.teamName); // sort by team name
          }
        });
        console.log(loadedTeams);
        setTeams(loadedTeams);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (expanded) {
      const unsubscribe = props.firebase
        .getTeam(expanded)
        .onSnapshot((result) => {
          if (result.exists) {
            setTeamDetails({ tid: expanded, ...result.data() });
          }
        });
      return () => {
        unsubscribe();
        setTeamDetails(false);
      };
    }
  }, [expanded]);

  function getLastChunk(string) {
    const chunks = string.split(" ");
    return chunks[chunks.length - 1];
  }

  async function sendEmail(tid) {
    console.log(tid);
    const thisTeam = await props.firebase.getTeam(tid).get();
    console.log(thisTeam.data());
    var emailSend = firebase.functions().httpsCallable("emails-customEmail");
    let data = {
      subject: `Payment reminder by end of shooting today for entry: ${
        thisTeam.data().teamName
      }`,
      email: thisTeam.data().email,
      paragraphs: [
        `Hi ${thisTeam.data().name}`,
        `This is a friendly reminder that our records show we are waiting for your entry payment for: ${
          thisTeam.data().teamName
        }`,
        `Please note that entries which are not fully paid by the END OF SHOOTING TODAY will not be included in the Fantasy Fullbore competition.`,
        `In order to complete your entry, you need to transfer your entry fee of £10 per team`,
        `Account Name: GB Palma SA 2023`,
        `(Palma team are kindly allowing the use of the bank account whilst the NRA team set something up!)`,
        `Account Number: 50878979`,
        `Sort code: 20-96-89`,

        `Reference: "FF${getLastChunk(thisTeam.data().name)}
        ${thisTeam.data().name.slice(0, 1)}-${thisTeam
          .data()
          .teamName.slice(0, 5)}"`,
        `Reference format: "FF[SurnameFirstInitial]-[first five characters of team name]"`,
      ],
    };
    data.paragraphs.push(
      `Thanks so much for your support`,
      `The Fantasy Fullbore Team`
    );

    try {
      // data.actions = {
      //   // 0: {
      //   //   type: "outlined",
      //   //   title: "View your Profile",
      //   //   url: `https://FantasyFullbore.co.uk/profile?uid=${uid}`,
      //   // },
      //   // 1: {
      //   //   type: "outlined",
      //   //   title: "Check out other teams",
      //   //   url: `https://FantasyFullbore.co.uk/teams`,
      //   // },
      // };

      await emailSend(data);
    } catch (error) {
      console.log(error, "Error");
    }
  }
  async function exportConsentingPeople() {
    const people = await props.firebase.db.doc(`shooters/index`).get();

    let data = [];
    Object.values(people.data()).map((value) => {
      data.push(value);
    });
    const headers = Object.keys(data[0]);

    // Create the CSV content
    const csvContent = [
      headers.join(","),
      ...data.map((obj) => headers.map((key) => obj[key]).join(",")),
    ].join("\n");
    console.log(csvContent);
  }

  return (
    <Box sx={{ mt: 10 }}>
      <Button onClick={() => exportConsentingPeople()}>
        exportConsentingPeople
      </Button>
      {teams && (
        <Box sx={{ width: "90vw", m: "0 auto" }}>
          {teams.map((team) => (
            <Accordion
              key={team.tid}
              expanded={expanded === team.tid}
              onChange={handleChange(team.tid)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>{team.teamName}</Typography>
                <Typography sx={{ ml: 8, color: "text.secondary" }}>
                  {team.name}
                </Typography>
                <Typography sx={{ ml: 8 }}>
                  {team.reconciled && "Reconciled"}
                </Typography>
                <Typography sx={{ ml: 8 }} color="secondary">
                  {!team.reconciled && "AWAITING"}
                </Typography>
                {!team.reconciled && (
                  <Button
                    sx={{ ml: 4 }}
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmail(team.tid);
                    }}
                  >
                    Send email
                  </Button>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {teamDetails && (
                  <Box>
                    <Box>DEtails</Box>
                    <Box>
                      <List>
                        {teamDetails &&
                          teamDetails.shooters.map((shooter) => (
                            <ListItem key={shooter.trid} sx={{ padding: 0 }}>
                              <Box sx={{ width: "30%" }}>{shooter.name}</Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "70%",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day1points
                                    ? teamDetails.day1points
                                    : 0}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day2points
                                    ? teamDetails.day2points
                                    : 0}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day3points
                                    ? teamDetails.day3points
                                    : 0}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day4points
                                    ? teamDetails.day4points
                                    : 0}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day5points
                                    ? teamDetails.day5points
                                    : 0}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day5points
                                    ? teamDetails.day5points
                                    : 0}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  {teamDetails.day5points
                                    ? teamDetails.day5points
                                    : 0}
                                </Box>
                              </Box>
                            </ListItem>
                          ))}
                      </List>
                    </Box>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(AdminFunctions);
