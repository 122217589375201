import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";

const Dashboard = (props) => {
  const [teamIndex, setTeamIndex] = useState(false);
  const [teamNo, setTeamNo] = useState(0);
  const [teamsReconciled, setTeamsReconciled] = useState(0);
  const [chase, setChase] = useState(false);

  useEffect(() => {
    const unsubscribe = props.firebase.allTeams().onSnapshot((results) => {
      console.log(results);
      if (results.exists) {
        let loadedTeams = [];
        let reconciled = 0;
        let teams = 0;
        let chasers = {};
        Object.entries(results.data()).map(([key, value]) => {
          teams += 1;
          if (value.reconciled) {
            reconciled += 1;
          } else {
            if (!chasers[value.uid]) {
              chasers[value.uid] = { count: 0, name: value.name };
            }
            chasers[value.uid].count += 1;
          }
          loadedTeams.push({ tid: key, ...value });
        });
        loadedTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
        console.log(loadedTeams);
        setTeamIndex(loadedTeams);
        setTeamsReconciled(reconciled);
        setTeamNo(teams);
        setChase(chasers);
      }
    });
    return () => {
      unsubscribe();
      setTeamIndex(false);
      setTeamsReconciled(0);
      setTeamNo(0);
      setChase(false);
    };
  }, []);
  return (
    <Box>
      <Paper sx={{ m: 2, p: 2 }}>
        <Typography align="left" variant="h3">
          Order summary
        </Typography>
        <Box>
          <Typography>Teams created: {teamNo}</Typography>
          <Typography>Teams reconciled: {teamsReconciled}</Typography>
          <Typography>Expected Revenue: £{teamNo * 10}</Typography>
          <Typography>Revenue Recieved: £{teamsReconciled * 10}</Typography>
        </Box>
      </Paper>
      <Paper sx={{ m: 2, p: 2 }}>
        <Typography align="left" variant="h3">
          To chase
        </Typography>
        <Box sx={{ m: 4, display: "flex", alignItems: "start" }}>
          {!chase ? (
            <Typography>Up to date</Typography>
          ) : (
            <Box>
              {Object.entries(chase).map(([key, value]) => (
                <Typography key={key}>
                  {value.name} | uid/trid {key} | {value.count} teams
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default withFirebase(Dashboard);
