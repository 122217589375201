import React, { useState, useEffect } from "react";
import { Snackbar, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const Toast = ({ message, onClose }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
    }, 500000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      sx={{
        color: "#fff", // Text color
        width: "100%",
        justifyContent: "center", // Center align
        "& .MuiSnackbarContent-root": {
          backgroundColor: "#43a047",
          // Success color for the toast box
        },
      }}
      message={message}
      action={
        <IconButton size="small" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

export default Toast;
