import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { withFirebase } from "../../Firebase";

const NewShooter = ({ closeNewUser, ...props }) => {
  const [form, setForm] = useState(() => {
    let data = {
      trid: "",
      first_name: "",
      last_name: "",
      seed_name: "",
      club: "",
      county: "",
      country: "",
      shootingGrand: false,
      consent: false,
      ind_num_24: "",
      email: "",
      class: "",
      cost: 5000,
      seed: "",
      seed_change: "",
      ga_23: "",
      ga_22: "",
      ga_21: "",
      q_23: "",
      q_22: "",
      q_21: "",
      stg_23: "",
      stg_22: "",
      stg_21: "",
      points_ff23: "",
    };
    return data;
  });

  const handleConsentChange = (event) => {
    setForm({ ...form, consent: event.target.value });
  };

  const handleShootingGrandChange = (event) => {
    setForm({ ...form, shootingGrand: event.target.value });
  };

  async function saveUser() {
    if (form.trid === "") {
      alert("Please enter a TRID");
      return;
    } else {
      const batch = props.firebase.db.batch();
      const shooterRef = props.firebase.db.doc(`shooters/${form.trid}`);
      batch.set(shooterRef, { ...form }, { merge: true });
      const userRef = props.firebase.db.doc(`users/${form.trid}`);
      batch.set(userRef, { ...form }, { merge: true });
      const indexRef = props.firebase.db.doc(`shooters/index`);
      batch.set(indexRef, { [form.trid]: { ...form } }, { merge: true });
      console.log(form);
      try {
        await batch.commit();
        closeNewUser();
      } catch (error) {
        console.error("Error updating document: ", error);
      }
      console.log({ ...form });
    }
  }

  return (
    <Box>
      <Box>
        <TextField
          id="outlined-basic"
          label="TRID"
          variant="outlined"
          value={form.trid}
          onChange={(event) => setForm({ ...form, trid: event.target.value })}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label="First name"
          variant="outlined"
          value={form.first_name}
          onChange={(event) => {
            setForm({
              ...form,
              first_name: event.target.value,
              seed_name: `${event.target.value} ${form.last_name}`,
            });
          }}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label="Last name"
          variant="outlined"
          value={form.last_name}
          onChange={(event) => {
            setForm({
              ...form,
              last_name: event.target.value,
              seed_name: `${form.first_name} ${event.target.value}`,
            });
          }}
          fullWidth
        />
        <TextField
          id="outlined-basic"
          label="Club"
          variant="outlined"
          value={form.club}
          fullWidth
          onChange={(event) => setForm({ ...form, club: event.target.value })}
        />
        <TextField
          id="outlined-basic"
          label="County"
          variant="outlined"
          value={form.county}
          fullWidth
          onChange={(event) => setForm({ ...form, county: event.target.value })}
        />
        <TextField
          id="outlined-basic"
          label="Country"
          variant="outlined"
          value={form.country}
          fullWidth
          onChange={(event) =>
            setForm({ ...form, country: event.target.value })
          }
        />
        <FormControl fullWidth>
          <InputLabel>Shooting Grand</InputLabel>
          {console.log(form.shootingGrand)}
          <Select
            label="Shooting Grand"
            value={form.shootingGrand}
            onChange={handleShootingGrandChange}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Consent</InputLabel>
          <Select
            label="Consent"
            value={form.consent}
            onChange={handleConsentChange}
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" onClick={() => saveUser()}>
          Save
        </Button>
        <Button onClick={() => closeNewUser()}>Close</Button>
      </Box>
    </Box>
  );
};

export default withFirebase(NewShooter);
