import React from "react";
import ReactDOM from "react-dom/client";
import { render } from "react-dom";

import "./index.css";
import App from "./App";
import Firebase, { FirebaseContext } from "./Firebase";
import * as serviceWorker from "./serviceWorker";

// Component
const AppProvider = () => (
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>
);

render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
