import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TeamAccordion from "./teamAccordion";
import { withFirebase } from "../Firebase";
import TeamEditDetails from "./teamEditDetails";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import HowToDetail from "./howToDetail";

const Teams = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const page = urlParams.get("redirect");
  const start2023 = new Date("2023-07-14T07:00:00+01:00");
  const start2024 = new Date("2024-07-20T07:00:00+01:00");
  const [day, setDay] = useState(() => {
    if (urlParams.get("year")) {
      return 5;
    } else {
      return 5;
    }
  });
  const [year, setYear] = useState(() => {
    if (urlParams.get("year")) {
      return urlParams.get("year");
    } else {
      return "2024";
    }
  });
  const [targetDate, setTargetDate] = useState(() => {
    if (urlParams.get("year")) {
      return start2023;
    } else {
      return start2024;
    }
  });
  const [teams, setTeams] = useState(false);
  const [expanded, setExpanded] = useState(0);
  const [preGrand, setPreGrand] = useState(false);
  const [filter, setFilter] = useState("all");
  const [howToDetailOpen, setHowToDetailOpen] = useState(false);

  useEffect(() => {
    checkTime();
  }, [teams, year, targetDate]);

  const checkTime = () => {
    const currentDate = new Date();
    console.log(currentDate, targetDate, currentDate > targetDate);
    if (currentDate > targetDate) {
      setPreGrand(false);
    } else {
      setPreGrand(true);
    }
  };
  useEffect(() => {
    const unsubscribe = props.firebase.allTeams(year).onSnapshot((results) => {
      if (results.exists) {
        let loadedTeams = [];
        console.log(results.data());
        Object.entries(results.data()).map(([key, value]) => {
          if (filter === "all") {
            loadedTeams.push({ tid: key, ...value });
          } else if (filter === 500 && value.cost <= 500000) {
            loadedTeams.push({ tid: key, ...value });
          } else if (filter === 40 && value.cost <= 40000) {
            loadedTeams.push({ tid: key, ...value });
          }
        });
        loadedTeams.sort((a, b) => {
          if (a[`Day_${day}_position`] !== b[`Day_${day}_position`]) {
            return a[`Day_${day}_position`] - b[`Day_${day}_position`]; // sort by Day_1_position
          } else if (
            a[`Day_${day}_position`] === b[`Day_${day}_position`] &&
            a.teamName === "" &&
            b.teamName === ""
          ) {
            return 0; // no change in order for empty team names
          } else if (
            a[`Day_${day}_position`] === b[`Day_${day}_position`] &&
            a.teamName === ""
          ) {
            return 1; // a comes after b if a has an empty team name
          } else if (
            a[`Day_${day}_position`] === b[`Day_${day}_position`] &&
            b.teamName === ""
          ) {
            return -1; // a comes before b if b has an empty team name
          } else {
            return a.teamName.localeCompare(b.teamName); // sort by team name
          }
        });
        console.log(loadedTeams, "<<LOADED TEAMS");
        setTeams(loadedTeams);
      }
    });
    return () => unsubscribe();
  }, [filter, year, targetDate]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function title() {
    let dateYear = new Date().getFullYear();
    console.log(dateYear);
    if (Number(year) < Number(dateYear)) {
      return `${year} teams`;
    } else {
      return "All teams";
    }
  }

  function changeYear() {
    let dateYear = new Date().getFullYear();
    console.log(dateYear);
    if (Number(year) < Number(dateYear)) {
      setYear("2024");
      setTargetDate(start2024);
    } else {
      setYear("2023");
      setTargetDate(start2023);
    }
  }
  function buttonTitle() {
    let dateYear = new Date().getFullYear();
    console.log(dateYear);
    if (Number(year) < Number(dateYear)) {
      return "Back to this year";
    } else {
      return "Previous Results";
    }
  }

  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Modal open={howToDetailOpen} onClose={() => setHowToDetailOpen(false)}>
        <Box>
          <HowToDetail setHowToDetailOpen={setHowToDetailOpen} />
        </Box>
      </Modal>
      {!props.dashboard && (
        <Box>
          <Box>
            <Typography variant="h3" component="h1">
              {title()}
            </Typography>
          </Box>
          <Box>
            <Button onClick={() => setHowToDetailOpen(true)}>
              Scoring Explanation
            </Button>
            {/* <Button onClick={() => changeYear()}>{buttonTitle()}</Button> */}
          </Box>
        </Box>
      )}

      <Box
        sx={{
          m: "0 auto",
          mt: 2,
          mb: 2,
          display: "flex",
          justifyContent: "space-evenly",
          border: "1px solid #000",
          borderRadius: "5px",
          maxWidth: "750px",
        }}
      >
        <Typography variant="caption">Filter by cost:</Typography>
        <Button onClick={() => setFilter("all")}>All</Button>
        <Button onClick={() => setFilter(500)}>Top £500,000</Button>
        <Button onClick={() => setFilter(40)}>Top £40,000</Button>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {teams ? (
          <Paper sx={{ width: "750px" }}>
            <Box sx={{ p: "0 16px", display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "30%" }}>
                <Typography variant="caption">Name</Typography>
              </Box>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  pr: "24px",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 1</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 2</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 3</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 4</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Day 5</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Grand Agg</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Total</Typography>
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption">Change</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="caption" fontWeight="bold">
                    POSITION
                  </Typography>
                </Box>
              </Box>
            </Box>
            {teams.map((team, index) => (
              <Accordion
                key={index}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Box
                      sx={{
                        width: "30%",
                        display: "block",
                        overflow: "hidden",
                      }}
                    >
                      {team.position !== null && (
                        <Typography sx={{ mr: 2 }}>{team.position}</Typography>
                      )}
                      <Box>
                        <Typography
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {team.teamName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "70%",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        {team.Day_1_Total ? team.Day_1_Total : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.Day_2_Total ? team.Day_2_Total : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.Day_3_Total ? team.Day_3_Total : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.Day_4_Total ? team.Day_4_Total : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        {team.Day_5_Total ? team.Day_5_Total : 0}
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography>
                          ({team.GRAND_MULTIPLIER ? team.GRAND_MULTIPLIER : 0})
                        </Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography sx={{ textDecoration: "underline" }}>
                          {team.TotalPoints ? team.TotalPoints : 0}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyItems: "center",
                        }}
                      >
                        {team[`Day_${day}_position`] <
                          team[`Day_${day - 1}_position`] && (
                          <NorthIcon fontSize="small" color="success" />
                        )}{" "}
                        {team[`Day_${day}_position`] >
                          team[`Day_${day - 1}_position`] && (
                          <SouthIcon fontSize="small" color="warning" />
                        )}
                        {Math.abs(
                          team[`Day_${day - 1}_position`] -
                            team[`Day_${day}_position`]
                        )}
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <Typography
                          fontWeight="bold"
                          align="right"
                          variant="body1"
                        >
                          {team[`Day_${day}_position`]
                            ? team[`Day_${day}_position`]
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <TeamEditDetails
                    tid={team.tid}
                    preGrand={preGrand}
                    globalUid={props.globalUid}
                    year={year}
                  />
                  {!preGrand && <TeamAccordion tid={team.tid} year={year} />}
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        ) : (
          <Box>
            <Typography>Loading...</Typography>
          </Box>
        )}
      </Box>
      {!props.dashboard && preGrand && (
        <Fab
          color="primary"
          variant="extended"
          href="/teamselection"
          sx={{
            position: "fixed",
            bottom: 16,
            left: 16,
            width: "auto",
          }}
        >
          <ControlPointIcon sx={{ mr: 2 }} />
          Pick your Team
        </Fab>
      )}
    </Box>
  );
};

export default withFirebase(Teams);
