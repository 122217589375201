import { Box, Typography } from "@mui/material";
import React from "react";
import TeamsMigration from "./teamsMigration";

const AdminMigrations = () => {
  return (
    <Box sx={{ mt: 10 }}>
      <Typography variant="h4">Admin Migrations</Typography>
      <Box>
        <TeamsMigration />
      </Box>
    </Box>
  );
};

export default AdminMigrations;
