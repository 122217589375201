import React, { useState } from 'react';
import { Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import pdfjsLib from 'pdfjs-dist/build/pdf';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  input: {
    display: 'none',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const PDFReader = () => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [json, setJson] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleConvertClick = async () => {
    if (!file) return;

    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      const dataBuffer = new Uint8Array(event.target.result);
      const pdfDoc = await pdfjsLib.getDocument({ data: dataBuffer }).promise;
      const numPages = pdfDoc.numPages;
      let text = '';

      for (let i = 1; i <= numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const content = await page.getTextContent();
        text += content.items.map((item) => item.str).join(' ');
      }

      const json = { text };
      setJson(json);
    };

    fileReader.readAsArrayBuffer(file);
  };

  return (
    <Container className={classes.root}>
      <Typography variant="h4" gutterBottom>
        PDF to JSON Converter
      </Typography>
      <input
        accept="application/pdf"
        className={classes.input}
        id="pdf-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="pdf-file">
        <Button
          className={classes.button}
          variant="contained"
          component="span"
        >
          Upload PDF
        </Button>
      </label>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleConvertClick}
      >
        Convert to JSON
      </Button>
      {json && (
        <pre>{JSON.stringify(json, null, 2)}</pre>
      )}
    </Container>
  );
};

export default PDFReader;
