export const scoreData = {
  32: {
    Index: "32",
    Init: "P",
    NAME: "Bromley",
    full_NAME: "P Bromley",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "4",
    Day_2_BONUS: "5",
    Day_2_Total: "9",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "1",
    Day_4_BONUS: "0",
    Day_4_Total: "1",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "147.015",
    GUNSCORE_DAY3: "167.011",
    GUNSCORE_DAY4: "171.019",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "758.079",
    DAILY_T: "75.01",
    DONEGALL: "49.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "50.007",
    CORP: "48.005",
    TIMES: "48.004",
    POW: "70.003",
    DOFC: "49.004",
    LOVELL: "48.003",
    WIMB: "49.006",
    STG: "74.01",
    KINGS: "101.012",
    DAY1_POS: "91",
    DAY2_POS: "32",
    DAY3_POS: "208",
    DAY4_POS: "50",
    DAY5_POS: "247",
  },
  34: {
    Index: "34",
    Init: "AI",
    NAME: "Brown",
    full_NAME: "AI Brown",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.012",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "165.012",
    GUNSCORE_DAY4: "170.017",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "740.063",
    DAILY_T: "69.004",
    DONEGALL: "49.004",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "46.005",
    CORP: "46.003",
    TIMES: "46.004",
    POW: "70.004",
    DOFC: "49.004",
    LOVELL: "49.006",
    WIMB: "48.004",
    STG: "73.007",
    KINGS: "101.011",
    DAY1_POS: "452",
    DAY2_POS: "305",
    DAY3_POS: "300",
    DAY4_POS: "82",
    DAY5_POS: "254",
  },
  59: {
    Index: "59",
    Init: "ML",
    NAME: "Cotillard",
    full_NAME: "ML Cotillard",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.013",
    GUNSCORE_DAY2: "140.008",
    GUNSCORE_DAY3: "162.012",
    GUNSCORE_DAY4: "164.012",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "728.055",
    DAILY_T: "70.005",
    DONEGALL: "46.003",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "47.005",
    CORP: "45",
    TIMES: "47.005",
    POW: "69.004",
    DOFC: "46.003",
    LOVELL: "48.004",
    WIMB: "47.003",
    STG: "69.005",
    KINGS: "99.01",
    DAY1_POS: "473",
    DAY2_POS: "320",
    DAY3_POS: "382",
    DAY4_POS: "329",
    DAY5_POS: "388",
  },
  84: {
    Index: "84",
    Init: "MJ",
    NAME: "Ensor",
    full_NAME: "MJ Ensor",
    Day_1: "6",
    Day_1_BONUS: "10",
    Day_1_Total: "16",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "13",
    Day_3_BONUS: "10",
    Day_3_Total: "23",
    Day_4: "28",
    Day_4_BONUS: "10",
    Day_4_Total: "38",
    Day_5: "35",
    Day_5_BONUS: "5",
    Day_5_Total: "40",
    GRAND_MULTIPLIER: "106",
    FINAL_TOTAL: "233",
    GUNSCORE_DAY1: "174.025",
    GUNSCORE_DAY2: "145.015",
    GUNSCORE_DAY3: "173.019",
    GUNSCORE_DAY4: "174.025",
    GUNSCORE_DAY5: "105.017",
    GUNSCORE_GA: "771.101",
    DAILY_T: "74.012",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.006",
    CORP: "45.002",
    TIMES: "50.004",
    POW: "73.007",
    DOFC: "50.008",
    LOVELL: "49.005",
    WIMB: "50.008",
    STG: "75.012",
    KINGS: "105.017",
    DAY1_POS: "24",
    DAY2_POS: "81",
    DAY3_POS: "9",
    DAY4_POS: "3",
    DAY5_POS: "2",
  },
  90: {
    Index: "90",
    Init: "DE",
    NAME: "Fields",
    full_NAME: "DE Fields",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.014",
    GUNSCORE_DAY2: "136.009",
    GUNSCORE_DAY3: "165.013",
    GUNSCORE_DAY4: "165.012",
    GUNSCORE_DAY5: "97.005",
    GUNSCORE_GA: "727.053",
    DAILY_T: "70.008",
    DONEGALL: "46.002",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "43.001",
    CORP: "43.001",
    TIMES: "46.005",
    POW: "70.005",
    DOFC: "49.003",
    LOVELL: "45.003",
    WIMB: "49.004",
    STG: "71.005",
    KINGS: "97.005",
    DAY1_POS: "443",
    DAY2_POS: "423",
    DAY3_POS: "294",
    DAY4_POS: "301",
    DAY5_POS: "497",
  },
  107: {
    Index: "107",
    Init: "E",
    NAME: "Gauvin",
    full_NAME: "E Gauvin",
    Day_1: "3",
    Day_1_BONUS: "10",
    Day_1_Total: "13",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "174.021",
    GUNSCORE_DAY2: "143.012",
    GUNSCORE_DAY3: "164.021",
    GUNSCORE_DAY4: "167.016",
    GUNSCORE_DAY5: "102.014",
    GUNSCORE_GA: "750.084",
    DAILY_T: "75.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.004",
    CORP: "47.004",
    TIMES: "48.007",
    POW: "72.012",
    DOFC: "44.002",
    LOVELL: "45.002",
    WIMB: "49.004",
    STG: "73.01",
    KINGS: "102.014",
    DAY1_POS: "38",
    DAY2_POS: "178",
    DAY3_POS: "305",
    DAY4_POS: "197",
    DAY5_POS: "170",
  },
  121: {
    Index: "121",
    Init: "CD",
    NAME: "Haley",
    full_NAME: "CD Haley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "2",
    Day_4_BONUS: "5",
    Day_4_Total: "7",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "16",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "145.017",
    GUNSCORE_DAY3: "167.016",
    GUNSCORE_DAY4: "171.021",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "758.084",
    DAILY_T: "73.007",
    DONEGALL: "49.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.006",
    CORP: "47.004",
    TIMES: "49.005",
    POW: "70.006",
    DOFC: "48.005",
    LOVELL: "50.006",
    WIMB: "48.005",
    STG: "73.01",
    KINGS: "104.014",
    DAY1_POS: "183",
    DAY2_POS: "70",
    DAY3_POS: "190",
    DAY4_POS: "48",
    DAY5_POS: "31",
  },
  144: {
    Index: "144",
    Init: "C",
    NAME: "Hughes",
    full_NAME: "C Hughes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.017",
    GUNSCORE_DAY2: "120.009",
    GUNSCORE_DAY3: "159.011",
    GUNSCORE_DAY4: "161.011",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "709.057",
    DAILY_T: "72.007",
    DONEGALL: "48.004",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "38.003",
    CORP: "34",
    TIMES: "46.003",
    POW: "68.007",
    DOFC: "45.001",
    LOVELL: "43.001",
    WIMB: "47.004",
    STG: "71.006",
    KINGS: "101.009",
    DAY1_POS: "304",
    DAY2_POS: "607",
    DAY3_POS: "467",
    DAY4_POS: "415",
    DAY5_POS: "286",
  },
  153: {
    Index: "153",
    Init: "MHH",
    NAME: "John",
    full_NAME: "MHH John",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.014",
    GUNSCORE_DAY2: "137.009",
    GUNSCORE_DAY3: "160.009",
    GUNSCORE_DAY4: "161.012",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "730.055",
    DAILY_T: "72.003",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "47.003",
    CORP: "43.001",
    TIMES: "48.003",
    POW: "68.005",
    DOFC: "44.001",
    LOVELL: "40.001",
    WIMB: "49.004",
    STG: "72.007",
    KINGS: "102.011",
    DAY1_POS: "239",
    DAY2_POS: "406",
    DAY3_POS: "451",
    DAY4_POS: "412",
    DAY5_POS: "191",
  },
  154: {
    Index: "154",
    Init: "CI",
    NAME: "Johnston",
    full_NAME: "CI Johnston",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "163.014",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "103.015",
    GUNSCORE_GA: "747.08",
    DAILY_T: "75.011",
    DONEGALL: "49.007",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "47.004",
    CORP: "44.003",
    TIMES: "46.004",
    POW: "73.008",
    DOFC: "44.002",
    LOVELL: "47.004",
    WIMB: "49.004",
    STG: "73.01",
    KINGS: "103.015",
    DAY1_POS: "91",
    DAY2_POS: "305",
    DAY3_POS: "342",
    DAY4_POS: "110",
    DAY5_POS: "85",
  },
  158: {
    Index: "158",
    Init: "JC",
    NAME: "Kent",
    full_NAME: "JC Kent",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "3",
    Day_3_BONUS: "0",
    Day_3_Total: "3",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "171.026",
    GUNSCORE_DAY2: "145.02",
    GUNSCORE_DAY3: "171.02",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "755.092",
    DAILY_T: "72.01",
    DONEGALL: "50.009",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "47.005",
    CORP: "48.007",
    TIMES: "49.007",
    POW: "74.009",
    DOFC: "48.004",
    LOVELL: "43.002",
    WIMB: "49.004",
    STG: "74.008",
    KINGS: "102.012",
    DAY1_POS: "134",
    DAY2_POS: "65",
    DAY3_POS: "37",
    DAY4_POS: "240",
    DAY5_POS: "180",
  },
  159: {
    Index: "159",
    Init: "SV",
    NAME: "Luckman",
    full_NAME: "SV Luckman",
    Day_1: "4",
    Day_1_BONUS: "10",
    Day_1_Total: "14",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "19",
    GUNSCORE_DAY1: "174.022",
    GUNSCORE_DAY2: "141.01",
    GUNSCORE_DAY3: "165.013",
    GUNSCORE_DAY4: "167.02",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "750.078",
    DAILY_T: "75.008",
    DONEGALL: "50.008",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "47.003",
    CORP: "45.002",
    TIMES: "48.003",
    POW: "71.007",
    DOFC: "46.003",
    LOVELL: "43.002",
    WIMB: "49.006",
    STG: "75.012",
    KINGS: "103.013",
    DAY1_POS: "36",
    DAY2_POS: "270",
    DAY3_POS: "294",
    DAY4_POS: "186",
    DAY5_POS: "97",
  },
  173: {
    Index: "173",
    Init: "AJ",
    NAME: "Leech",
    full_NAME: "AJ Leech",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "1",
    Day_2_BONUS: "5",
    Day_2_Total: "6",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "6",
    GUNSCORE_DAY1: "170.024",
    GUNSCORE_DAY2: "146.017",
    GUNSCORE_DAY3: "169.02",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "98.01",
    GUNSCORE_GA: "752.089",
    DAILY_T: "74.011",
    DONEGALL: "49.007",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.006",
    CORP: "47.003",
    TIMES: "49.008",
    POW: "74.01",
    DOFC: "46.002",
    LOVELL: "47.002",
    WIMB: "48.005",
    STG: "74.011",
    KINGS: "98.01",
    DAY1_POS: "198",
    DAY2_POS: "49",
    DAY3_POS: "94",
    DAY4_POS: "110",
    DAY5_POS: "436",
  },
  190: {
    Index: "190",
    Init: "JH",
    NAME: "Messer",
    full_NAME: "JH Messer",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "103.011",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "103.011",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "124",
  },
  196: {
    Index: "196",
    Init: "B",
    NAME: "Moore",
    full_NAME: "B Moore",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.01",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "110.002",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "415.024",
    DAILY_T: "70.006",
    DONEGALL: "46.001",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.006",
    CORP: "45.001",
    TIMES: "46.001",
    POW: "64.001",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "486",
    DAY2_POS: "216",
    DAY3_POS: "636",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  235: {
    Index: "235",
    Init: "RHG",
    NAME: "Riley",
    full_NAME: "RHG Riley",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.017",
    GUNSCORE_DAY2: "144.012",
    GUNSCORE_DAY3: "169.016",
    GUNSCORE_DAY4: "168.014",
    GUNSCORE_DAY5: "101.014",
    GUNSCORE_GA: "751.073",
    DAILY_T: "72.008",
    DONEGALL: "47.003",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "49.004",
    CORP: "48.004",
    TIMES: "48.005",
    POW: "73.006",
    DOFC: "48.005",
    LOVELL: "47.003",
    WIMB: "49.005",
    STG: "72.006",
    KINGS: "101.014",
    DAY1_POS: "267",
    DAY2_POS: "137",
    DAY3_POS: "113",
    DAY4_POS: "177",
    DAY5_POS: "241",
  },
  240: {
    Index: "240",
    Init: "DA",
    NAME: "Rose",
    full_NAME: "DA Rose",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "23",
    GUNSCORE_DAY1: "171.018",
    GUNSCORE_DAY2: "145.012",
    GUNSCORE_DAY3: "169.02",
    GUNSCORE_DAY4: "168.017",
    GUNSCORE_DAY5: "105.012",
    GUNSCORE_GA: "758.079",
    DAILY_T: "75.005",
    DONEGALL: "49.009",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "50.005",
    CORP: "47.003",
    TIMES: "48.004",
    POW: "72.009",
    DOFC: "49.007",
    LOVELL: "45.002",
    WIMB: "49.006",
    STG: "74.009",
    KINGS: "105.012",
    DAY1_POS: "172",
    DAY2_POS: "93",
    DAY3_POS: "94",
    DAY4_POS: "163",
    DAY5_POS: "18",
  },
  248: {
    Index: "248",
    Init: "RW",
    NAME: "Sandlant",
    full_NAME: "RW Sandlant",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "2",
    Day_4_BONUS: "5",
    Day_4_Total: "7",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "12",
    GUNSCORE_DAY1: "169.018",
    GUNSCORE_DAY2: "145.016",
    GUNSCORE_DAY3: "170.011",
    GUNSCORE_DAY4: "171.023",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "755.079",
    DAILY_T: "71.008",
    DONEGALL: "48.004",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "48.005",
    CORP: "49.005",
    TIMES: "49.001",
    POW: "74.007",
    DOFC: "47.003",
    LOVELL: "47.005",
    WIMB: "50.007",
    STG: "74.011",
    KINGS: "100.011",
    DAY1_POS: "261",
    DAY2_POS: "75",
    DAY3_POS: "89",
    DAY4_POS: "43",
    DAY5_POS: "323",
  },
  253: {
    Index: "253",
    Init: "MH",
    NAME: "Shaw",
    full_NAME: "MH Shaw",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.014",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "163.016",
    GUNSCORE_DAY4: "82.006",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "656.056",
    DAILY_T: "73.008",
    DONEGALL: "46.002",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "46.004",
    CORP: "45.001",
    TIMES: "46.006",
    POW: "70.004",
    DOFC: "47.006",
    LOVELL: "41.002",
    WIMB: "41.004",
    STG: "0",
    KINGS: "102.009",
    DAY1_POS: "320",
    DAY2_POS: "259",
    DAY3_POS: "338",
    DAY4_POS: "632",
    DAY5_POS: "216",
  },
  256: {
    Index: "256",
    Init: "RSF",
    NAME: "Shouler",
    full_NAME: "RSF Shouler",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "172.023",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "169.021",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "758.09",
    DAILY_T: "75.01",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "49.005",
    CORP: "47.003",
    TIMES: "50.009",
    POW: "73.009",
    DOFC: "47.002",
    LOVELL: "45.003",
    WIMB: "50.007",
    STG: "74.011",
    KINGS: "104.013",
    DAY1_POS: "85",
    DAY2_POS: "168",
    DAY3_POS: "58",
    DAY4_POS: "94",
    DAY5_POS: "41",
  },
  257: {
    Index: "257",
    Init: "MW",
    NAME: "Silver",
    full_NAME: "MW Silver",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "173.017",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "168.02",
    GUNSCORE_DAY4: "163.018",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "748.077",
    DAILY_T: "73.005",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "49.005",
    CORP: "48.004",
    TIMES: "47.005",
    POW: "73.01",
    DOFC: "48.005",
    LOVELL: "40",
    WIMB: "50.007",
    STG: "73.011",
    KINGS: "101.008",
    DAY1_POS: "79",
    DAY2_POS: "160",
    DAY3_POS: "129",
    DAY4_POS: "342",
    DAY5_POS: "295",
  },
  264: {
    Index: "264",
    Init: "RM",
    NAME: "Smith",
    full_NAME: "RM Smith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.007",
    GUNSCORE_DAY2: "139.011",
    GUNSCORE_DAY3: "149.007",
    GUNSCORE_DAY4: "146.004",
    GUNSCORE_DAY5: "95.005",
    GUNSCORE_GA: "687.034",
    DAILY_T: "66.001",
    DONEGALL: "45.001",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "49.007",
    CORP: "46.002",
    TIMES: "43.003",
    POW: "69.002",
    DOFC: "37.002",
    LOVELL: "39.001",
    WIMB: "45.001",
    STG: "62.002",
    KINGS: "95.005",
    DAY1_POS: "550",
    DAY2_POS: "333",
    DAY3_POS: "583",
    DAY4_POS: "581",
    DAY5_POS: "548",
  },
  270: {
    Index: "270",
    Init: "WR",
    NAME: "Steward",
    full_NAME: "WR Steward",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.01",
    GUNSCORE_DAY2: "141.008",
    GUNSCORE_DAY3: "161.015",
    GUNSCORE_DAY4: "166.017",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "733.061",
    DAILY_T: "71.006",
    DONEGALL: "49.004",
    CONAN_DOYLE: "44",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "48.003",
    CORP: "47.003",
    TIMES: "48.007",
    POW: "69.006",
    DOFC: "44.002",
    LOVELL: "47.005",
    WIMB: "47.002",
    STG: "72.01",
    KINGS: "101.011",
    DAY1_POS: "459",
    DAY2_POS: "279",
    DAY3_POS: "402",
    DAY4_POS: "223",
    DAY5_POS: "254",
  },
  271: {
    Index: "271",
    Init: "CA",
    NAME: "Stewart",
    full_NAME: "CA Stewart",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.017",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "167.013",
    GUNSCORE_DAY5: "103.006",
    GUNSCORE_GA: "737.06",
    DAILY_T: "74.009",
    DONEGALL: "44.002",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "46.005",
    CORP: "46.004",
    TIMES: "44.004",
    POW: "72.004",
    DOFC: "45.004",
    LOVELL: "46.001",
    WIMB: "49.007",
    STG: "72.005",
    KINGS: "103.006",
    DAY1_POS: "375",
    DAY2_POS: "298",
    DAY3_POS: "418",
    DAY4_POS: "208",
    DAY5_POS: "163",
  },
  278: {
    Index: "278",
    Init: "PD",
    NAME: "Sykes",
    full_NAME: "PD Sykes",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "4",
    Day_3_BONUS: "5",
    Day_3_Total: "9",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "6",
    Day_5_BONUS: "0",
    Day_5_Total: "6",
    GRAND_MULTIPLIER: "19",
    FINAL_TOTAL: "54",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "145.014",
    GUNSCORE_DAY3: "171.021",
    GUNSCORE_DAY4: "170.018",
    GUNSCORE_DAY5: "104.018",
    GUNSCORE_GA: "764.094",
    DAILY_T: "74.007",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "48.004",
    CORP: "48.006",
    TIMES: "50.008",
    POW: "72.009",
    DOFC: "49.004",
    LOVELL: "47.003",
    WIMB: "50.006",
    STG: "73.009",
    KINGS: "104.018",
    DAY1_POS: "29",
    DAY2_POS: "86",
    DAY3_POS: "35",
    DAY4_POS: "78",
    DAY5_POS: "24",
  },
  292: {
    Index: "292",
    Init: "RAH",
    NAME: "Vary",
    full_NAME: "RAH Vary",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "168.022",
    GUNSCORE_DAY4: "168.022",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "583.07",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.004",
    CORP: "46.004",
    TIMES: "49.008",
    POW: "73.011",
    DOFC: "46.003",
    LOVELL: "47.004",
    WIMB: "48.008",
    STG: "73.01",
    KINGS: "104.012",
    DAY1_POS: "639",
    DAY2_POS: "160",
    DAY3_POS: "127",
    DAY4_POS: "142",
    DAY5_POS: "53",
  },
  299: {
    Index: "299",
    Init: "CJ",
    NAME: "Watson",
    full_NAME: "CJ Watson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "2",
    Day_2_BONUS: "5",
    Day_2_Total: "7",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "30",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "146.019",
    GUNSCORE_DAY3: "169.018",
    GUNSCORE_DAY4: "168.016",
    GUNSCORE_DAY5: "105.012",
    GUNSCORE_GA: "760.086",
    DAILY_T: "73.009",
    DONEGALL: "50.008",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "50.009",
    CORP: "47.006",
    TIMES: "50.006",
    POW: "71.007",
    DOFC: "48.005",
    LOVELL: "47.002",
    WIMB: "47.006",
    STG: "74.008",
    KINGS: "105.012",
    DAY1_POS: "100",
    DAY2_POS: "45",
    DAY3_POS: "103",
    DAY4_POS: "168",
    DAY5_POS: "18",
  },
  310: {
    Index: "310",
    Init: "BJ",
    NAME: "Winney",
    full_NAME: "BJ Winney",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.019",
    GUNSCORE_DAY2: "146.014",
    GUNSCORE_DAY3: "169.022",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "99.009",
    GUNSCORE_GA: "744.079",
    DAILY_T: "69.011",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "49.007",
    CORP: "49.005",
    TIMES: "50.008",
    POW: "72.009",
    DOFC: "47.005",
    LOVELL: "49.003",
    WIMB: "49.003",
    STG: "66.009",
    KINGS: "99.009",
    DAY1_POS: "369",
    DAY2_POS: "57",
    DAY3_POS: "91",
    DAY4_POS: "316",
    DAY5_POS: "396",
  },
  317: {
    Index: "317",
    Init: "MJJ",
    NAME: "Charlton",
    full_NAME: "MJJ Charlton",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.01",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "166.019",
    GUNSCORE_DAY4: "155.007",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "721.055",
    DAILY_T: "68.004",
    DONEGALL: "45.001",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "45.001",
    CORP: "48.007",
    TIMES: "48.007",
    POW: "71.007",
    DOFC: "47.005",
    LOVELL: "42.001",
    WIMB: "48.004",
    STG: "65.002",
    KINGS: "99.008",
    DAY1_POS: "523",
    DAY2_POS: "259",
    DAY3_POS: "218",
    DAY4_POS: "520",
    DAY5_POS: "402",
  },
  319: {
    Index: "319",
    Init: "DJ",
    NAME: "Curtis",
    full_NAME: "DJ Curtis",
    Day_1: "11",
    Day_1_BONUS: "15",
    Day_1_Total: "26",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "36",
    GUNSCORE_DAY1: "175.023",
    GUNSCORE_DAY2: "139.012",
    GUNSCORE_DAY3: "167.018",
    GUNSCORE_DAY4: "160.018",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "743.084",
    DAILY_T: "75.01",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "50.003",
    CORP: "42.003",
    TIMES: "50.007",
    POW: "72.007",
    DOFC: "45.004",
    LOVELL: "40.003",
    WIMB: "47.004",
    STG: "73.011",
    KINGS: "102.013",
    DAY1_POS: "11",
    DAY2_POS: "328",
    DAY3_POS: "181",
    DAY4_POS: "426",
    DAY5_POS: "173",
  },
  323: {
    Index: "323",
    Init: "GK",
    NAME: "Alexander",
    full_NAME: "GK Alexander",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "172.023",
    GUNSCORE_DAY2: "141.009",
    GUNSCORE_DAY3: "168.009",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "746.066",
    DAILY_T: "74.01",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "46.002",
    CORP: "47.004",
    TIMES: "49.004",
    POW: "73.003",
    DOFC: "46.002",
    LOVELL: "45.003",
    WIMB: "49.005",
    STG: "71.006",
    KINGS: "100.011",
    DAY1_POS: "85",
    DAY2_POS: "273",
    DAY3_POS: "175",
    DAY4_POS: "287",
    DAY5_POS: "323",
  },
  326: {
    Index: "326",
    Init: "J",
    NAME: "Cload",
    full_NAME: "J Cload",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "10",
    Day_2_BONUS: "5",
    Day_2_Total: "15",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "20",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "148.018",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "167.013",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "757.083",
    DAILY_T: "74.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.007",
    CORP: "50.006",
    TIMES: "49.006",
    POW: "71.006",
    DOFC: "48.006",
    LOVELL: "47.002",
    WIMB: "48.005",
    STG: "72.006",
    KINGS: "102.012",
    DAY1_POS: "91",
    DAY2_POS: "13",
    DAY3_POS: "136",
    DAY4_POS: "208",
    DAY5_POS: "180",
  },
  337: {
    Index: "337",
    Init: "APW",
    NAME: "Daw",
    full_NAME: "APW Daw",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.013",
    GUNSCORE_DAY2: "144.01",
    GUNSCORE_DAY3: "170.017",
    GUNSCORE_DAY4: "168.01",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "750.058",
    DAILY_T: "72.007",
    DONEGALL: "47",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "48.002",
    CORP: "47.004",
    TIMES: "49.003",
    POW: "73.011",
    DOFC: "48.003",
    LOVELL: "46.001",
    WIMB: "48.004",
    STG: "74.005",
    KINGS: "101.008",
    DAY1_POS: "361",
    DAY2_POS: "144",
    DAY3_POS: "77",
    DAY4_POS: "183",
    DAY5_POS: "295",
  },
  338: {
    Index: "338",
    Init: "P",
    NAME: "Dawes",
    full_NAME: "P Dawes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "156.01",
    GUNSCORE_DAY2: "140.014",
    GUNSCORE_DAY3: "156.012",
    GUNSCORE_DAY4: "158.01",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "709.056",
    DAILY_T: "73.007",
    DONEGALL: "40.001",
    CONAN_DOYLE: "43.002",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "50.007",
    CORP: "42.002",
    TIMES: "46.005",
    POW: "68.006",
    DOFC: "42.001",
    LOVELL: "41",
    WIMB: "46.003",
    STG: "71.007",
    KINGS: "99.01",
    DAY1_POS: "570",
    DAY2_POS: "283",
    DAY3_POS: "522",
    DAY4_POS: "466",
    DAY5_POS: "388",
  },
  340: {
    Index: "340",
    Init: "JR",
    NAME: "Deane",
    full_NAME: "JR Deane",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "139.011",
    GUNSCORE_DAY3: "166.012",
    GUNSCORE_DAY4: "155.015",
    GUNSCORE_DAY5: "102.014",
    GUNSCORE_GA: "733.071",
    DAILY_T: "74.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "47.002",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.004",
    CORP: "44.002",
    TIMES: "49.004",
    POW: "69.006",
    DOFC: "48.002",
    LOVELL: "38.001",
    WIMB: "49.006",
    STG: "68.008",
    KINGS: "102.014",
    DAY1_POS: "165",
    DAY2_POS: "333",
    DAY3_POS: "257",
    DAY4_POS: "512",
    DAY5_POS: "170",
  },
  342: {
    Index: "342",
    Init: "CES",
    NAME: "Dickenson",
    full_NAME: "CES Dickenson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "10",
    Day_2_BONUS: "5",
    Day_2_Total: "15",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "169.019",
    GUNSCORE_DAY2: "148.018",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "165.017",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "754.079",
    DAILY_T: "73.008",
    DONEGALL: "47.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.006",
    CORP: "50.006",
    TIMES: "48.006",
    POW: "72.005",
    DOFC: "48.003",
    LOVELL: "44.004",
    WIMB: "49.005",
    STG: "72.008",
    KINGS: "104.011",
    DAY1_POS: "257",
    DAY2_POS: "13",
    DAY3_POS: "164",
    DAY4_POS: "274",
    DAY5_POS: "63",
  },
  343: {
    Index: "343",
    Init: "N",
    NAME: "Anderson",
    full_NAME: "N Anderson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.018",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "167.017",
    GUNSCORE_DAY4: "160.01",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "733.063",
    DAILY_T: "73.01",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "47.004",
    CORP: "46.005",
    TIMES: "49.005",
    POW: "72.009",
    DOFC: "46.003",
    LOVELL: "45.003",
    WIMB: "47.004",
    STG: "68.003",
    KINGS: "101.007",
    DAY1_POS: "401",
    DAY2_POS: "305",
    DAY3_POS: "187",
    DAY4_POS: "438",
    DAY5_POS: "304",
  },
  346: {
    Index: "346",
    Init: "AF",
    NAME: "Donald",
    full_NAME: "AF Donald",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "167.011",
    GUNSCORE_DAY4: "163.011",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "738.054",
    DAILY_T: "71.004",
    DONEGALL: "45.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "50.007",
    CORP: "45.001",
    TIMES: "48.004",
    POW: "74.006",
    DOFC: "45.001",
    LOVELL: "47.004",
    WIMB: "48.004",
    STG: "68.003",
    KINGS: "101.007",
    DAY1_POS: "417",
    DAY2_POS: "216",
    DAY3_POS: "208",
    DAY4_POS: "370",
    DAY5_POS: "304",
  },
  353: {
    Index: "353",
    Init: "SH",
    NAME: "Dickson",
    full_NAME: "SH Dickson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.012",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "167.015",
    GUNSCORE_DAY4: "157.009",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "717.052",
    DAILY_T: "70.005",
    DONEGALL: "43.002",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "42",
    ALEXANDRA: "46.004",
    CORP: "46.005",
    TIMES: "49.005",
    POW: "69.004",
    DOFC: "49.006",
    LOVELL: "42",
    WIMB: "46.003",
    STG: "69.006",
    KINGS: "99.007",
    DAY1_POS: "517",
    DAY2_POS: "466",
    DAY3_POS: "195",
    DAY4_POS: "497",
    DAY5_POS: "413",
  },
  364: {
    Index: "364",
    Init: "MR",
    NAME: "Ebbetts",
    full_NAME: "MR Ebbetts",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.015",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "157.008",
    GUNSCORE_DAY4: "158.012",
    GUNSCORE_DAY5: "97.009",
    GUNSCORE_GA: "716.057",
    DAILY_T: "71.006",
    DONEGALL: "46.004",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "48.004",
    CORP: "44.002",
    TIMES: "46.004",
    POW: "68.001",
    DOFC: "43.003",
    LOVELL: "41",
    WIMB: "46.005",
    STG: "71.007",
    KINGS: "97.009",
    DAY1_POS: "441",
    DAY2_POS: "288",
    DAY3_POS: "513",
    DAY4_POS: "462",
    DAY5_POS: "473",
  },
  368: {
    Index: "368",
    Init: "R",
    NAME: "Ellis",
    full_NAME: "R Ellis",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "140.009",
    GUNSCORE_DAY3: "170.015",
    GUNSCORE_DAY4: "89.003",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "675.058",
    DAILY_T: "74.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.008",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "48.005",
    CORP: "43.001",
    TIMES: "50.004",
    POW: "71.005",
    DOFC: "49.006",
    LOVELL: "42",
    WIMB: "47.003",
    STG: "0",
    KINGS: "104.01",
    DAY1_POS: "100",
    DAY2_POS: "315",
    DAY3_POS: "82",
    DAY4_POS: "631",
    DAY5_POS: "72",
  },
  386: {
    Index: "386",
    Init: "C",
    NAME: "Fitzpatrick",
    full_NAME: "C Fitzpatrick",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "145.017",
    GUNSCORE_DAY3: "48.005",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "463.051",
    DAILY_T: "71.008",
    DONEGALL: "48.004",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "48.006",
    CORP: "47.005",
    TIMES: "48.005",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "101.009",
    DAY1_POS: "249",
    DAY2_POS: "70",
    DAY3_POS: "641",
    DAY4_POS: "636",
    DAY5_POS: "286",
  },
  394: {
    Index: "394",
    Init: "MR",
    NAME: "Fugeman",
    full_NAME: "MR Fugeman",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "164.014",
    GUNSCORE_DAY4: "167.017",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "746.07",
    DAILY_T: "74.007",
    DONEGALL: "49.006",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "47.006",
    CORP: "47.001",
    TIMES: "49.007",
    POW: "72.006",
    DOFC: "43.001",
    LOVELL: "48.004",
    WIMB: "47.007",
    STG: "72.006",
    KINGS: "103.009",
    DAY1_POS: "69",
    DAY2_POS: "338",
    DAY3_POS: "321",
    DAY4_POS: "194",
    DAY5_POS: "147",
  },
  411: {
    Index: "411",
    Init: "DJ",
    NAME: "Gass",
    full_NAME: "DJ Gass",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.019",
    GUNSCORE_DAY2: "138.011",
    GUNSCORE_DAY3: "166.019",
    GUNSCORE_DAY4: "167.02",
    GUNSCORE_DAY5: "102.007",
    GUNSCORE_GA: "741.076",
    DAILY_T: "71.008",
    DONEGALL: "48.007",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "48.003",
    CORP: "42.002",
    TIMES: "48.005",
    POW: "71.007",
    DOFC: "47.007",
    LOVELL: "43.003",
    WIMB: "50.007",
    STG: "74.01",
    KINGS: "102.007",
    DAY1_POS: "296",
    DAY2_POS: "374",
    DAY3_POS: "218",
    DAY4_POS: "186",
    DAY5_POS: "233",
  },
  416: {
    Index: "416",
    Init: "JO",
    NAME: "Ahrens",
    full_NAME: "JO Ahrens",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "5",
    Day_5_BONUS: "0",
    Day_5_Total: "5",
    GRAND_MULTIPLIER: "5",
    FINAL_TOTAL: "30",
    GUNSCORE_DAY1: "172.019",
    GUNSCORE_DAY2: "144.016",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "170.019",
    GUNSCORE_DAY5: "104.015",
    GUNSCORE_GA: "760.088",
    DAILY_T: "75.011",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "50.007",
    CORP: "45.001",
    TIMES: "50.007",
    POW: "73.009",
    DOFC: "47.003",
    LOVELL: "48.005",
    WIMB: "50.008",
    STG: "72.006",
    KINGS: "104.015",
    DAY1_POS: "116",
    DAY2_POS: "111",
    DAY3_POS: "65",
    DAY4_POS: "75",
    DAY5_POS: "27",
  },
  421: {
    Index: "421",
    Init: "CP",
    NAME: "Goad",
    full_NAME: "CP Goad",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.02",
    GUNSCORE_DAY2: "141.013",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "159.011",
    GUNSCORE_DAY5: "96.004",
    GUNSCORE_GA: "731.063",
    DAILY_T: "73.009",
    DONEGALL: "49.004",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "47.004",
    CORP: "44.004",
    TIMES: "48.004",
    POW: "67.006",
    DOFC: "49.005",
    LOVELL: "42.001",
    WIMB: "44.003",
    STG: "73.007",
    KINGS: "96.004",
    DAY1_POS: "159",
    DAY2_POS: "245",
    DAY3_POS: "314",
    DAY4_POS: "453",
    DAY5_POS: "531",
  },
  429: {
    Index: "429",
    Init: "PS",
    NAME: "Gray",
    full_NAME: "PS Gray",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "135.007",
    GUNSCORE_DAY3: "159.014",
    GUNSCORE_DAY4: "160.008",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "720.052",
    DAILY_T: "73.006",
    DONEGALL: "46.004",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "49.002",
    ALEXANDRA: "42.003",
    CORP: "44.002",
    TIMES: "46.005",
    POW: "67.005",
    DOFC: "46.004",
    LOVELL: "46.001",
    WIMB: "45.003",
    STG: "69.004",
    KINGS: "99.007",
    DAY1_POS: "348",
    DAY2_POS: "456",
    DAY3_POS: "459",
    DAY4_POS: "444",
    DAY5_POS: "413",
  },
  431: {
    Index: "431",
    Init: "TM",
    NAME: "Green",
    full_NAME: "TM Green",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "149.009",
    GUNSCORE_DAY2: "136.007",
    GUNSCORE_DAY3: "165.017",
    GUNSCORE_DAY4: "164.01",
    GUNSCORE_DAY5: "93.008",
    GUNSCORE_GA: "707.051",
    DAILY_T: "68.004",
    DONEGALL: "41.002",
    CONAN_DOYLE: "40.003",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "43.001",
    CORP: "47.002",
    TIMES: "47.006",
    POW: "72.008",
    DOFC: "46.003",
    LOVELL: "44.001",
    WIMB: "48.001",
    STG: "72.008",
    KINGS: "93.008",
    DAY1_POS: "605",
    DAY2_POS: "430",
    DAY3_POS: "272",
    DAY4_POS: "338",
    DAY5_POS: "564",
  },
  434: {
    Index: "434",
    Init: "PD",
    NAME: "Griggs",
    full_NAME: "PD Griggs",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.014",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "163.011",
    GUNSCORE_DAY4: "170.012",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "749.06",
    DAILY_T: "73.006",
    DONEGALL: "49.004",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "49.005",
    CORP: "48.004",
    TIMES: "48.003",
    POW: "68.006",
    DOFC: "47.002",
    LOVELL: "48.004",
    WIMB: "49.002",
    STG: "73.006",
    KINGS: "103.01",
    DAY1_POS: "239",
    DAY2_POS: "168",
    DAY3_POS: "354",
    DAY4_POS: "90",
    DAY5_POS: "138",
  },
  439: {
    Index: "439",
    Init: "TJ",
    NAME: "Goodger",
    full_NAME: "TJ Goodger",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.014",
    GUNSCORE_DAY2: "134.008",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "160.013",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "730.064",
    DAILY_T: "68.002",
    DONEGALL: "48.005",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "47.003",
    CORP: "37.001",
    TIMES: "49.006",
    POW: "72.008",
    DOFC: "49.005",
    LOVELL: "42.003",
    WIMB: "45.003",
    STG: "73.007",
    KINGS: "101.01",
    DAY1_POS: "415",
    DAY2_POS: "474",
    DAY3_POS: "65",
    DAY4_POS: "431",
    DAY5_POS: "269",
  },
  444: {
    Index: "444",
    Init: "RR",
    NAME: "Grisenthwaite",
    full_NAME: "RR Grisenthwaite",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "139.008",
    GUNSCORE_DAY3: "166.013",
    GUNSCORE_DAY4: "163.012",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "740.064",
    DAILY_T: "74.009",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "45.001",
    CORP: "46.001",
    TIMES: "48.004",
    POW: "72.007",
    DOFC: "46.002",
    LOVELL: "45.004",
    WIMB: "47.002",
    STG: "71.006",
    KINGS: "100.009",
    DAY1_POS: "91",
    DAY2_POS: "352",
    DAY3_POS: "252",
    DAY4_POS: "368",
    DAY5_POS: "346",
  },
  458: {
    Index: "458",
    Init: "AG",
    NAME: "Harrison",
    full_NAME: "AG Harrison",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "173.016",
    GUNSCORE_DAY2: "141.009",
    GUNSCORE_DAY3: "159.01",
    GUNSCORE_DAY4: "158.012",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "730.054",
    DAILY_T: "75.008",
    DONEGALL: "49.002",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "47.002",
    CORP: "46.001",
    TIMES: "48.004",
    POW: "67.004",
    DOFC: "44.002",
    LOVELL: "37.003",
    WIMB: "47.004",
    STG: "74.005",
    KINGS: "99.007",
    DAY1_POS: "80",
    DAY2_POS: "273",
    DAY3_POS: "471",
    DAY4_POS: "462",
    DAY5_POS: "413",
  },
  463: {
    Index: "463",
    Init: "N",
    NAME: "Hart",
    full_NAME: "N Hart",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "151.006",
    GUNSCORE_DAY2: "133.008",
    GUNSCORE_DAY3: "150.011",
    GUNSCORE_DAY4: "148.008",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "683.045",
    DAILY_T: "65.002",
    DONEGALL: "42.001",
    CONAN_DOYLE: "44.003",
    DAILY_MAIL: "43.001",
    ALEXANDRA: "44.003",
    CORP: "46.004",
    TIMES: "47.006",
    POW: "61.003",
    DOFC: "42.002",
    LOVELL: "40.001",
    WIMB: "42.002",
    STG: "66.005",
    KINGS: "101.012",
    DAY1_POS: "596",
    DAY2_POS: "491",
    DAY3_POS: "575",
    DAY4_POS: "569",
    DAY5_POS: "247",
  },
  468: {
    Index: "468",
    Init: "RM",
    NAME: "Hatcher",
    full_NAME: "RM Hatcher",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "173.022",
    GUNSCORE_DAY2: "145.015",
    GUNSCORE_DAY3: "167.014",
    GUNSCORE_DAY4: "162.016",
    GUNSCORE_DAY5: "102.007",
    GUNSCORE_GA: "749.074",
    DAILY_T: "75.01",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "49.006",
    CORP: "46.003",
    TIMES: "46.002",
    POW: "73.009",
    DOFC: "48.003",
    LOVELL: "42.001",
    WIMB: "47.005",
    STG: "73.01",
    KINGS: "102.007",
    DAY1_POS: "59",
    DAY2_POS: "81",
    DAY3_POS: "201",
    DAY4_POS: "385",
    DAY5_POS: "233",
  },
  472: {
    Index: "472",
    Init: "CD",
    NAME: "Heales",
    full_NAME: "CD Heales",
    Day_1: "23",
    Day_1_BONUS: "15",
    Day_1_Total: "38",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "8",
    Day_3_BONUS: "5",
    Day_3_Total: "13",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "15",
    FINAL_TOTAL: "69",
    GUNSCORE_DAY1: "175.026",
    GUNSCORE_DAY2: "146.015",
    GUNSCORE_DAY3: "172.023",
    GUNSCORE_DAY4: "166.016",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "763.093",
    DAILY_T: "75.012",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.005",
    CORP: "48.004",
    TIMES: "50.007",
    POW: "73.01",
    DOFC: "49.006",
    LOVELL: "43.001",
    WIMB: "49.005",
    STG: "74.01",
    KINGS: "104.013",
    DAY1_POS: "4",
    DAY2_POS: "55",
    DAY3_POS: "17",
    DAY4_POS: "226",
    DAY5_POS: "41",
  },
  473: {
    Index: "473",
    Init: "RAV",
    NAME: "Hebblethwaite",
    full_NAME: "RAV Hebblethwaite",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.025",
    GUNSCORE_DAY2: "144.015",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "169.021",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "755.088",
    DAILY_T: "74.012",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "50.007",
    CORP: "48.006",
    TIMES: "50.006",
    POW: "73.004",
    DOFC: "45.004",
    LOVELL: "46.006",
    WIMB: "50.005",
    STG: "73.01",
    KINGS: "103.013",
    DAY1_POS: "135",
    DAY2_POS: "117",
    DAY3_POS: "164",
    DAY4_POS: "94",
    DAY5_POS: "97",
  },
  476: {
    Index: "476",
    Init: "KB",
    NAME: "Hill",
    full_NAME: "KB Hill",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.012",
    GUNSCORE_DAY2: "142.01",
    GUNSCORE_DAY3: "164.014",
    GUNSCORE_DAY4: "168.021",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "742.068",
    DAILY_T: "73.005",
    DONEGALL: "48.004",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "47.004",
    CORP: "46.002",
    TIMES: "50.004",
    POW: "69.006",
    DOFC: "45.004",
    LOVELL: "47.006",
    WIMB: "47.004",
    STG: "74.011",
    KINGS: "101.011",
    DAY1_POS: "362",
    DAY2_POS: "229",
    DAY3_POS: "321",
    DAY4_POS: "145",
    DAY5_POS: "254",
  },
  481: {
    Index: "481",
    Init: "CJ",
    NAME: "Hockley OBE",
    full_NAME: "CJ Hockley OBE",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "100.012",
    GUNSCORE_GA: "736.065",
    DAILY_T: "70.006",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "48.004",
    CORP: "44.004",
    TIMES: "47.004",
    POW: "70.007",
    DOFC: "49.004",
    LOVELL: "46.002",
    WIMB: "50.007",
    STG: "70.006",
    KINGS: "100.012",
    DAY1_POS: "417",
    DAY2_POS: "338",
    DAY3_POS: "240",
    DAY4_POS: "230",
    DAY5_POS: "321",
  },
  484: {
    Index: "484",
    Init: "PJ",
    NAME: "Holden",
    full_NAME: "PJ Holden",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "133.006",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "145.003",
    GUNSCORE_DAY5: "99.005",
    GUNSCORE_GA: "706.042",
    DAILY_T: "69.005",
    DONEGALL: "48.005",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "48.002",
    CORP: "36",
    TIMES: "45.002",
    POW: "71.006",
    DOFC: "48.007",
    LOVELL: "40",
    WIMB: "44.002",
    STG: "61.001",
    KINGS: "99.005",
    DAY1_POS: "417",
    DAY2_POS: "500",
    DAY3_POS: "314",
    DAY4_POS: "587",
    DAY5_POS: "428",
  },
  495: {
    Index: "495",
    Init: "NJ",
    NAME: "Ball",
    full_NAME: "NJ Ball",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "4",
    Day_2_BONUS: "10",
    Day_2_Total: "14",
    Day_3: "7",
    Day_3_BONUS: "5",
    Day_3_Total: "12",
    Day_4: "18",
    Day_4_BONUS: "10",
    Day_4_Total: "28",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "26",
    FINAL_TOTAL: "80",
    GUNSCORE_DAY1: "171.024",
    GUNSCORE_DAY2: "147.015",
    GUNSCORE_DAY3: "172.019",
    GUNSCORE_DAY4: "174.023",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "765.093",
    DAILY_T: "74.012",
    DONEGALL: "48.007",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.005",
    CORP: "47.003",
    TIMES: "50.009",
    POW: "73.005",
    DOFC: "49.005",
    LOVELL: "50.008",
    WIMB: "49.005",
    STG: "75.01",
    KINGS: "101.012",
    DAY1_POS: "137",
    DAY2_POS: "32",
    DAY3_POS: "21",
    DAY4_POS: "6",
    DAY5_POS: "247",
  },
  502: {
    Index: "502",
    Init: "AP",
    NAME: "Barnes",
    full_NAME: "AP Barnes",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "168.018",
    GUNSCORE_DAY2: "141.007",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "168.016",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "744.068",
    DAILY_T: "69.005",
    DONEGALL: "49.008",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.003",
    CORP: "43",
    TIMES: "50.004",
    POW: "73.01",
    DOFC: "43.002",
    LOVELL: "46.003",
    WIMB: "48.005",
    STG: "74.008",
    KINGS: "101.011",
    DAY1_POS: "299",
    DAY2_POS: "280",
    DAY3_POS: "231",
    DAY4_POS: "168",
    DAY5_POS: "254",
  },
  503: {
    Index: "503",
    Init: "AD",
    NAME: "Hunter",
    full_NAME: "AD Hunter",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "35",
    Day_3_BONUS: "10",
    Day_3_Total: "45",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "54",
    GUNSCORE_DAY1: "168.025",
    GUNSCORE_DAY2: "141.009",
    GUNSCORE_DAY3: "174.022",
    GUNSCORE_DAY4: "170.023",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "757.093",
    DAILY_T: "73.012",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.008",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "46.001",
    CORP: "46.002",
    TIMES: "50.007",
    POW: "74.01",
    DOFC: "50.005",
    LOVELL: "47.004",
    WIMB: "50.008",
    STG: "73.011",
    KINGS: "104.014",
    DAY1_POS: "288",
    DAY2_POS: "273",
    DAY3_POS: "2",
    DAY4_POS: "59",
    DAY5_POS: "31",
  },
  512: {
    Index: "512",
    Init: "RF",
    NAME: "Barrington",
    full_NAME: "RF Barrington",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "139.006",
    GUNSCORE_DAY3: "163.013",
    GUNSCORE_DAY4: "157.011",
    GUNSCORE_DAY5: "96.005",
    GUNSCORE_GA: "720.047",
    DAILY_T: "72.006",
    DONEGALL: "45.003",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "47.002",
    CORP: "46.001",
    TIMES: "44.001",
    POW: "73.009",
    DOFC: "46.003",
    LOVELL: "40.002",
    WIMB: "45.005",
    STG: "72.004",
    KINGS: "96.005",
    DAY1_POS: "425",
    DAY2_POS: "358",
    DAY3_POS: "347",
    DAY4_POS: "489",
    DAY5_POS: "527",
  },
  520: {
    Index: "520",
    Init: "RW",
    NAME: "Hallows",
    full_NAME: "RW Hallows",
    Day_1: "2",
    Day_1_BONUS: "10",
    Day_1_Total: "12",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "17",
    GUNSCORE_DAY1: "173.027",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "169.015",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "751.081",
    DAILY_T: "73.011",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "49.006",
    CORP: "45.003",
    TIMES: "50.006",
    POW: "72.006",
    DOFC: "47.003",
    LOVELL: "43.002",
    WIMB: "48.004",
    STG: "74.007",
    KINGS: "103.014",
    DAY1_POS: "47",
    DAY2_POS: "250",
    DAY3_POS: "116",
    DAY4_POS: "294",
    DAY5_POS: "90",
  },
  527: {
    Index: "527",
    Init: "PM",
    NAME: "Hakim",
    full_NAME: "PM Hakim",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "28",
    GUNSCORE_DAY1: "170.024",
    GUNSCORE_DAY2: "143.016",
    GUNSCORE_DAY3: "165.019",
    GUNSCORE_DAY4: "166.013",
    GUNSCORE_DAY5: "105.012",
    GUNSCORE_GA: "749.084",
    DAILY_T: "73.008",
    DONEGALL: "50.01",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.006",
    CORP: "45.003",
    TIMES: "47.005",
    POW: "72.009",
    DOFC: "46.005",
    LOVELL: "47.002",
    WIMB: "47.005",
    STG: "72.006",
    KINGS: "105.012",
    DAY1_POS: "198",
    DAY2_POS: "153",
    DAY3_POS: "268",
    DAY4_POS: "255",
    DAY5_POS: "18",
  },
  539: {
    Index: "539",
    Init: "MD",
    NAME: "Joyce",
    full_NAME: "MD Joyce",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.016",
    GUNSCORE_DAY2: "143.011",
    GUNSCORE_DAY3: "167.011",
    GUNSCORE_DAY4: "165.018",
    GUNSCORE_DAY5: "104.008",
    GUNSCORE_GA: "744.064",
    DAILY_T: "71.007",
    DONEGALL: "49.005",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "50.006",
    CORP: "45.003",
    TIMES: "48.004",
    POW: "70.003",
    DOFC: "49.004",
    LOVELL: "45.003",
    WIMB: "48.007",
    STG: "72.008",
    KINGS: "104.008",
    DAY1_POS: "408",
    DAY2_POS: "182",
    DAY3_POS: "208",
    DAY4_POS: "271",
    DAY5_POS: "83",
  },
  543: {
    Index: "543",
    Init: "PM",
    NAME: "Jory",
    full_NAME: "PM Jory",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.018",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "167.018",
    GUNSCORE_DAY4: "163.018",
    GUNSCORE_DAY5: "102.015",
    GUNSCORE_GA: "744.081",
    DAILY_T: "72.006",
    DONEGALL: "49.004",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.003",
    ALEXANDRA: "48.006",
    CORP: "43.003",
    TIMES: "47.006",
    POW: "73.008",
    DOFC: "47.004",
    LOVELL: "49.008",
    WIMB: "47.005",
    STG: "67.005",
    KINGS: "102.015",
    DAY1_POS: "172",
    DAY2_POS: "250",
    DAY3_POS: "181",
    DAY4_POS: "342",
    DAY5_POS: "165",
  },
  544: {
    Index: "544",
    Init: "S",
    NAME: "Belither",
    full_NAME: "S Belither",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "5",
    Day_4_BONUS: "5",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "142.014",
    GUNSCORE_DAY3: "160.007",
    GUNSCORE_DAY4: "172.021",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "746.068",
    DAILY_T: "73.006",
    DONEGALL: "49.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "50.006",
    CORP: "44.004",
    TIMES: "47.001",
    POW: "68.004",
    DOFC: "45.002",
    LOVELL: "48.005",
    WIMB: "50.008",
    STG: "74.008",
    KINGS: "102.008",
    DAY1_POS: "217",
    DAY2_POS: "206",
    DAY3_POS: "454",
    DAY4_POS: "30",
    DAY5_POS: "225",
  },
  545: {
    Index: "545",
    Init: "P",
    NAME: "Johnson",
    full_NAME: "P Johnson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.019",
    GUNSCORE_DAY2: "136.008",
    GUNSCORE_DAY3: "159.016",
    GUNSCORE_DAY4: "159.009",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "722.062",
    DAILY_T: "70.009",
    DONEGALL: "50.006",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "42.003",
    CORP: "45.002",
    TIMES: "48.008",
    POW: "66.005",
    DOFC: "45.003",
    LOVELL: "41",
    WIMB: "49.006",
    STG: "69.003",
    KINGS: "100.01",
    DAY1_POS: "296",
    DAY2_POS: "425",
    DAY3_POS: "457",
    DAY4_POS: "457",
    DAY5_POS: "333",
  },
  547: {
    Index: "547",
    Init: "MD",
    NAME: "Jenvey",
    full_NAME: "MD Jenvey",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "5",
    Day_3_BONUS: "0",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "20",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "143.01",
    GUNSCORE_DAY3: "172.014",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "758.069",
    DAILY_T: "75.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "48.005",
    CORP: "45",
    TIMES: "49.005",
    POW: "74.006",
    DOFC: "49.003",
    LOVELL: "48.005",
    WIMB: "49.008",
    STG: "72.005",
    KINGS: "101.007",
    DAY1_POS: "69",
    DAY2_POS: "183",
    DAY3_POS: "30",
    DAY4_POS: "110",
    DAY5_POS: "304",
  },
  556: {
    Index: "556",
    Init: "TLW",
    NAME: "Kidner",
    full_NAME: "TLW Kidner",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.011",
    GUNSCORE_DAY2: "134.007",
    GUNSCORE_DAY3: "150.01",
    GUNSCORE_DAY4: "151.01",
    GUNSCORE_DAY5: "95.004",
    GUNSCORE_GA: "694.042",
    DAILY_T: "67.003",
    DONEGALL: "48.003",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "42.001",
    ALEXANDRA: "44.001",
    CORP: "48.005",
    TIMES: "47.003",
    POW: "56.004",
    DOFC: "47.003",
    LOVELL: "43.003",
    WIMB: "39.001",
    STG: "69.006",
    KINGS: "95.004",
    DAY1_POS: "456",
    DAY2_POS: "476",
    DAY3_POS: "576",
    DAY4_POS: "549",
    DAY5_POS: "551",
  },
  558: {
    Index: "558",
    Init: "JEM",
    NAME: "Bellringer",
    full_NAME: "JEM Bellringer",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "50",
    Day_3_BONUS: "15",
    Day_3_Total: "65",
    Day_4: "23",
    Day_4_BONUS: "10",
    Day_4_Total: "33",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "15",
    FINAL_TOTAL: "118",
    GUNSCORE_DAY1: "171.015",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "174.026",
    GUNSCORE_DAY4: "174.024",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "763.092",
    DAILY_T: "75.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.001",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "48.004",
    CORP: "45.004",
    TIMES: "50.01",
    POW: "74.009",
    DOFC: "50.007",
    LOVELL: "50.006",
    WIMB: "50.006",
    STG: "74.012",
    KINGS: "102.012",
    DAY1_POS: "191",
    DAY2_POS: "196",
    DAY3_POS: "1",
    DAY4_POS: "4",
    DAY5_POS: "180",
  },
  567: {
    Index: "567",
    Init: "MA",
    NAME: "Kirkillo-Stacewicz",
    full_NAME: "MA Kirkillo-Stacewicz",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "167.011",
    GUNSCORE_DAY4: "166.009",
    GUNSCORE_DAY5: "100.004",
    GUNSCORE_GA: "747.053",
    DAILY_T: "74.011",
    DONEGALL: "47.002",
    CONAN_DOYLE: "50.003",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "48.005",
    CORP: "45.002",
    TIMES: "47.004",
    POW: "72.006",
    DOFC: "48.001",
    LOVELL: "48.002",
    WIMB: "46.003",
    STG: "72.004",
    KINGS: "100.004",
    DAY1_POS: "183",
    DAY2_POS: "168",
    DAY3_POS: "208",
    DAY4_POS: "268",
    DAY5_POS: "376",
  },
  568: {
    Index: "568",
    Init: "SEP",
    NAME: "Kent",
    full_NAME: "SEP Kent",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "107.006",
    GUNSCORE_DAY2: "127.005",
    GUNSCORE_DAY3: "160.013",
    GUNSCORE_DAY4: "166.012",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "660.046",
    DAILY_T: "16",
    DONEGALL: "45.004",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "47.003",
    CORP: "36.001",
    TIMES: "44.002",
    POW: "70.008",
    DOFC: "46.003",
    LOVELL: "46.004",
    WIMB: "49.002",
    STG: "71.006",
    KINGS: "100.01",
    DAY1_POS: "635",
    DAY2_POS: "581",
    DAY3_POS: "440",
    DAY4_POS: "259",
    DAY5_POS: "333",
  },
  569: {
    Index: "569",
    Init: "JRJ",
    NAME: "Kennedy",
    full_NAME: "JRJ Kennedy",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.017",
    GUNSCORE_DAY2: "135.012",
    GUNSCORE_DAY3: "163.013",
    GUNSCORE_DAY4: "163.013",
    GUNSCORE_DAY5: "97.009",
    GUNSCORE_GA: "723.064",
    DAILY_T: "71.008",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "48.004",
    CORP: "37.002",
    TIMES: "45.003",
    POW: "69.004",
    DOFC: "49.006",
    LOVELL: "47.002",
    WIMB: "47.003",
    STG: "69.008",
    KINGS: "97.009",
    DAY1_POS: "403",
    DAY2_POS: "436",
    DAY3_POS: "347",
    DAY4_POS: "362",
    DAY5_POS: "473",
  },
  570: {
    Index: "570",
    Init: "PG",
    NAME: "Kelly",
    full_NAME: "PG Kelly",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.011",
    GUNSCORE_DAY2: "139.009",
    GUNSCORE_DAY3: "161.011",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "96.01",
    GUNSCORE_GA: "724.056",
    DAILY_T: "70.003",
    DONEGALL: "46.005",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.005",
    CORP: "45",
    TIMES: "46.001",
    POW: "70.007",
    DOFC: "45.003",
    LOVELL: "46.003",
    WIMB: "46.005",
    STG: "72.007",
    KINGS: "96.01",
    DAY1_POS: "456",
    DAY2_POS: "346",
    DAY3_POS: "425",
    DAY4_POS: "316",
    DAY5_POS: "507",
  },
  573: {
    Index: "573",
    Init: "ARN",
    NAME: "Kinross",
    full_NAME: "ARN Kinross",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "135.01",
    GUNSCORE_DAY3: "163.01",
    GUNSCORE_DAY4: "157.011",
    GUNSCORE_DAY5: "89.004",
    GUNSCORE_GA: "707.047",
    DAILY_T: "69.002",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "46.005",
    CORP: "43.002",
    TIMES: "47.002",
    POW: "73.007",
    DOFC: "43.001",
    LOVELL: "44.003",
    WIMB: "43.001",
    STG: "70.007",
    KINGS: "89.004",
    DAY1_POS: "475",
    DAY2_POS: "443",
    DAY3_POS: "359",
    DAY4_POS: "489",
    DAY5_POS: "599",
  },
  583: {
    Index: "583",
    Init: "JWE",
    NAME: "Lewis",
    full_NAME: "JWE Lewis",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "131.009",
    GUNSCORE_DAY3: "97.011",
    GUNSCORE_DAY4: "157.013",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "653.059",
    DAILY_T: "72.009",
    DONEGALL: "46.004",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "44.003",
    CORP: "38.002",
    TIMES: "47.005",
    POW: "0",
    DOFC: "50.006",
    LOVELL: "44.004",
    WIMB: "45.004",
    STG: "68.005",
    KINGS: "101.01",
    DAY1_POS: "348",
    DAY2_POS: "520",
    DAY3_POS: "637",
    DAY4_POS: "484",
    DAY5_POS: "269",
  },
  590: {
    Index: "590",
    Init: "AWC",
    NAME: "Lothian",
    full_NAME: "AWC Lothian",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.02",
    GUNSCORE_DAY2: "143.009",
    GUNSCORE_DAY3: "161.014",
    GUNSCORE_DAY4: "165.018",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "736.072",
    DAILY_T: "71.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "49.005",
    CORP: "46.002",
    TIMES: "48.006",
    POW: "66.003",
    DOFC: "47.005",
    LOVELL: "44.003",
    WIMB: "49.007",
    STG: "72.008",
    KINGS: "100.011",
    DAY1_POS: "332",
    DAY2_POS: "187",
    DAY3_POS: "404",
    DAY4_POS: "271",
    DAY5_POS: "323",
  },
  591: {
    Index: "591",
    Init: "JDS",
    NAME: "Langley",
    full_NAME: "JDS Langley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "5",
    Day_2_BONUS: "5",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "11",
    Day_4_BONUS: "5",
    Day_4_Total: "16",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "35",
    GUNSCORE_DAY1: "165.019",
    GUNSCORE_DAY2: "147.017",
    GUNSCORE_DAY3: "164.016",
    GUNSCORE_DAY4: "173.024",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "753.09",
    DAILY_T: "72.01",
    DONEGALL: "49.005",
    CONAN_DOYLE: "44.004",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "49.005",
    CORP: "48.003",
    TIMES: "50.007",
    POW: "71.007",
    DOFC: "43.002",
    LOVELL: "49.007",
    WIMB: "49.007",
    STG: "75.01",
    KINGS: "104.014",
    DAY1_POS: "400",
    DAY2_POS: "26",
    DAY3_POS: "311",
    DAY4_POS: "12",
    DAY5_POS: "31",
  },
  597: {
    Index: "597",
    Init: "AD",
    NAME: "Le Cheminant",
    full_NAME: "AD Le Cheminant",
    Day_1: "13",
    Day_1_BONUS: "15",
    Day_1_Total: "28",
    Day_2: "1",
    Day_2_BONUS: "0",
    Day_2_Total: "1",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "1",
    Day_4_BONUS: "5",
    Day_4_Total: "6",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "35",
    GUNSCORE_DAY1: "175.024",
    GUNSCORE_DAY2: "146.017",
    GUNSCORE_DAY3: "168.01",
    GUNSCORE_DAY4: "171.019",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "759.077",
    DAILY_T: "75.011",
    DONEGALL: "50.005",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.007",
    CORP: "48.005",
    TIMES: "49.003",
    POW: "71.006",
    DOFC: "48.001",
    LOVELL: "48.004",
    WIMB: "50.005",
    STG: "73.01",
    KINGS: "99.007",
    DAY1_POS: "9",
    DAY2_POS: "49",
    DAY3_POS: "174",
    DAY4_POS: "50",
    DAY5_POS: "413",
  },
  606: {
    Index: "606",
    Init: "D",
    NAME: "Lowe",
    full_NAME: "D Lowe",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "1",
    Day_2_BONUS: "5",
    Day_2_Total: "6",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "8",
    Day_4_BONUS: "5",
    Day_4_Total: "13",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "26",
    FINAL_TOTAL: "60",
    GUNSCORE_DAY1: "174.024",
    GUNSCORE_DAY2: "146.017",
    GUNSCORE_DAY3: "170.022",
    GUNSCORE_DAY4: "173.022",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "765.093",
    DAILY_T: "74.012",
    DONEGALL: "50.005",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "47.004",
    CORP: "49.006",
    TIMES: "49.007",
    POW: "72.007",
    DOFC: "49.008",
    LOVELL: "50.007",
    WIMB: "49.006",
    STG: "74.009",
    KINGS: "102.008",
    DAY1_POS: "26",
    DAY2_POS: "49",
    DAY3_POS: "55",
    DAY4_POS: "16",
    DAY5_POS: "225",
  },
  610: {
    Index: "610",
    Init: "RJ",
    NAME: "Lusty",
    full_NAME: "RJ Lusty",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.017",
    GUNSCORE_DAY2: "139.007",
    GUNSCORE_DAY3: "92.006",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "398.03",
    DAILY_T: "72.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.002",
    CORP: "42.001",
    TIMES: "47.004",
    POW: "0",
    DOFC: "45.002",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "343",
    DAY2_POS: "356",
    DAY3_POS: "638",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  614: {
    Index: "614",
    Init: "DR",
    NAME: "Le Quesne",
    full_NAME: "DR Le Quesne",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "7",
    Day_4_BONUS: "5",
    Day_4_Total: "12",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "17",
    GUNSCORE_DAY1: "172.015",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "168.017",
    GUNSCORE_DAY4: "173.018",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "757.072",
    DAILY_T: "74.003",
    DONEGALL: "50.006",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "48.002",
    CORP: "47.004",
    TIMES: "49.004",
    POW: "70.007",
    DOFC: "49.006",
    LOVELL: "49.006",
    WIMB: "50.005",
    STG: "74.007",
    KINGS: "100.009",
    DAY1_POS: "131",
    DAY2_POS: "127",
    DAY3_POS: "143",
    DAY4_POS: "21",
    DAY5_POS: "346",
  },
  616: {
    Index: "616",
    Init: "DC",
    NAME: "Luckman",
    full_NAME: "DC Luckman",
    Day_1: "18",
    Day_1_BONUS: "15",
    Day_1_Total: "33",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "4",
    Day_3_BONUS: "0",
    Day_3_Total: "4",
    Day_4: "11",
    Day_4_BONUS: "10",
    Day_4_Total: "21",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "44",
    FINAL_TOTAL: "102",
    GUNSCORE_DAY1: "175.025",
    GUNSCORE_DAY2: "145.018",
    GUNSCORE_DAY3: "171.022",
    GUNSCORE_DAY4: "173.024",
    GUNSCORE_DAY5: "103.015",
    GUNSCORE_GA: "767.104",
    DAILY_T: "75.011",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.007",
    CORP: "47.006",
    TIMES: "49.005",
    POW: "74.011",
    DOFC: "48.006",
    LOVELL: "48.006",
    WIMB: "50.005",
    STG: "75.013",
    KINGS: "103.015",
    DAY1_POS: "6",
    DAY2_POS: "68",
    DAY3_POS: "33",
    DAY4_POS: "12",
    DAY5_POS: "85",
  },
  619: {
    Index: "619",
    Init: "C",
    NAME: "Mallett",
    full_NAME: "C Mallett",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "170.016",
    GUNSCORE_DAY2: "144.015",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "751.077",
    DAILY_T: "74.007",
    DONEGALL: "49.004",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "50.004",
    CORP: "48.006",
    TIMES: "50.007",
    POW: "71.008",
    DOFC: "49.005",
    LOVELL: "45.003",
    WIMB: "49.006",
    STG: "72.006",
    KINGS: "101.011",
    DAY1_POS: "230",
    DAY2_POS: "117",
    DAY3_POS: "58",
    DAY4_POS: "230",
    DAY5_POS: "254",
  },
  644: {
    Index: "644",
    Init: "RW",
    NAME: "McVeigh",
    full_NAME: "RW McVeigh",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.015",
    GUNSCORE_DAY2: "130.009",
    GUNSCORE_DAY3: "157.012",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "716.058",
    DAILY_T: "71.007",
    DONEGALL: "50.004",
    CONAN_DOYLE: "44.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "46.003",
    CORP: "36.001",
    TIMES: "44.004",
    POW: "67.003",
    DOFC: "46.005",
    LOVELL: "47.004",
    WIMB: "47.005",
    STG: "72.005",
    KINGS: "98.008",
    DAY1_POS: "413",
    DAY2_POS: "539",
    DAY3_POS: "508",
    DAY4_POS: "240",
    DAY5_POS: "446",
  },
  647: {
    Index: "647",
    Init: "R",
    NAME: "Merridew",
    full_NAME: "R Merridew",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.017",
    GUNSCORE_DAY2: "136.01",
    GUNSCORE_DAY3: "159.008",
    GUNSCORE_DAY4: "160.014",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "723.061",
    DAILY_T: "73.008",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "48.003",
    CORP: "41.001",
    TIMES: "45.003",
    POW: "67.003",
    DOFC: "47.002",
    LOVELL: "45.003",
    WIMB: "45.004",
    STG: "70.007",
    KINGS: "101.012",
    DAY1_POS: "343",
    DAY2_POS: "420",
    DAY3_POS: "480",
    DAY4_POS: "430",
    DAY5_POS: "247",
  },
  650: {
    Index: "650",
    Init: "SW",
    NAME: "Maris",
    full_NAME: "SW Maris",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "173.016",
    GUNSCORE_DAY2: "143.015",
    GUNSCORE_DAY3: "157.012",
    GUNSCORE_DAY4: "166.011",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "742.066",
    DAILY_T: "74.003",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.005",
    CORP: "45.003",
    TIMES: "44.003",
    POW: "68.005",
    DOFC: "45.004",
    LOVELL: "48.005",
    WIMB: "47.002",
    STG: "71.004",
    KINGS: "103.012",
    DAY1_POS: "80",
    DAY2_POS: "155",
    DAY3_POS: "508",
    DAY4_POS: "263",
    DAY5_POS: "112",
  },
  656: {
    Index: "656",
    Init: "D",
    NAME: "Morris",
    full_NAME: "D Morris",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "7",
    Day_4_BONUS: "5",
    Day_4_Total: "12",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "12",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "142.013",
    GUNSCORE_DAY3: "167.015",
    GUNSCORE_DAY4: "173.019",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "757.075",
    DAILY_T: "74.006",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "49.008",
    CORP: "46.002",
    TIMES: "49.005",
    POW: "72.006",
    DOFC: "46.004",
    LOVELL: "49.004",
    WIMB: "49.005",
    STG: "75.01",
    KINGS: "104.012",
    DAY1_POS: "183",
    DAY2_POS: "212",
    DAY3_POS: "195",
    DAY4_POS: "19",
    DAY5_POS: "53",
  },
  657: {
    Index: "657",
    Init: "NC",
    NAME: "Mace",
    full_NAME: "NC Mace",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "7",
    Day_2_BONUS: "0",
    Day_2_Total: "7",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "7",
    GUNSCORE_DAY1: "169.018",
    GUNSCORE_DAY2: "147.02",
    GUNSCORE_DAY3: "166.018",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "754.087",
    DAILY_T: "74.01",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.006",
    CORP: "49.007",
    TIMES: "47.006",
    POW: "70.005",
    DOFC: "49.007",
    LOVELL: "49.005",
    WIMB: "48.007",
    STG: "73.008",
    KINGS: "102.011",
    DAY1_POS: "261",
    DAY2_POS: "21",
    DAY3_POS: "220",
    DAY4_POS: "66",
    DAY5_POS: "191",
  },
  658: {
    Index: "658",
    Init: "ML",
    NAME: "Millar",
    full_NAME: "ML Millar",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.023",
    GUNSCORE_DAY2: "142.014",
    GUNSCORE_DAY3: "167.014",
    GUNSCORE_DAY4: "170.016",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "755.078",
    DAILY_T: "74.012",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "47.005",
    CORP: "45.003",
    TIMES: "49.005",
    POW: "70.006",
    DOFC: "48.003",
    LOVELL: "48.003",
    WIMB: "49.006",
    STG: "73.007",
    KINGS: "104.011",
    DAY1_POS: "85",
    DAY2_POS: "206",
    DAY3_POS: "201",
    DAY4_POS: "86",
    DAY5_POS: "63",
  },
  678: {
    Index: "678",
    Init: "T",
    NAME: "Newbold",
    full_NAME: "T Newbold",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.012",
    GUNSCORE_DAY2: "136.005",
    GUNSCORE_DAY3: "152.006",
    GUNSCORE_DAY4: "167.014",
    GUNSCORE_DAY5: "95.006",
    GUNSCORE_GA: "707.043",
    DAILY_T: "70.005",
    DONEGALL: "44.003",
    CONAN_DOYLE: "43.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "47.003",
    CORP: "42",
    TIMES: "38.001",
    POW: "69.004",
    DOFC: "45.001",
    LOVELL: "49.004",
    WIMB: "49.004",
    STG: "69.006",
    KINGS: "95.006",
    DAY1_POS: "553",
    DAY2_POS: "433",
    DAY3_POS: "567",
    DAY4_POS: "203",
    DAY5_POS: "543",
  },
  686: {
    Index: "686",
    Init: "JE",
    NAME: "Ouston",
    full_NAME: "JE Ouston",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.017",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "158.01",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "733.063",
    DAILY_T: "72.01",
    DONEGALL: "46.002",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "49.005",
    CORP: "46.003",
    TIMES: "45.004",
    POW: "71.004",
    DOFC: "42.002",
    LOVELL: "46.004",
    WIMB: "48.006",
    STG: "71.004",
    KINGS: "102.01",
    DAY1_POS: "375",
    DAY2_POS: "216",
    DAY3_POS: "493",
    DAY4_POS: "287",
    DAY5_POS: "202",
  },
  691: {
    Index: "691",
    Init: "SP",
    NAME: "OBrien",
    full_NAME: "SP OBrien",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "139.013",
    GUNSCORE_DAY3: "166.014",
    GUNSCORE_DAY4: "163.01",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "735.064",
    DAILY_T: "72.005",
    DONEGALL: "48.006",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "48.006",
    CORP: "42",
    TIMES: "47.004",
    POW: "72.007",
    DOFC: "47.003",
    LOVELL: "46.003",
    WIMB: "47.003",
    STG: "70.004",
    KINGS: "100.011",
    DAY1_POS: "348",
    DAY2_POS: "324",
    DAY3_POS: "247",
    DAY4_POS: "375",
    DAY5_POS: "323",
  },
  696: {
    Index: "696",
    Init: "DAF",
    NAME: "Parkinson",
    full_NAME: "DAF Parkinson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "111.006",
    GUNSCORE_DAY2: "126.005",
    GUNSCORE_DAY3: "146.006",
    GUNSCORE_DAY4: "140.004",
    GUNSCORE_DAY5: "97.005",
    GUNSCORE_GA: "620.026",
    DAILY_T: "26.002",
    DONEGALL: "39.001",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "43.001",
    ALEXANDRA: "42.003",
    CORP: "41.001",
    TIMES: "46.002",
    POW: "58.001",
    DOFC: "42.003",
    LOVELL: "42",
    WIMB: "39.001",
    STG: "59.003",
    KINGS: "97.005",
    DAY1_POS: "634",
    DAY2_POS: "585",
    DAY3_POS: "596",
    DAY4_POS: "611",
    DAY5_POS: "497",
  },
  699: {
    Index: "699",
    Init: "LM",
    NAME: "Peden",
    full_NAME: "LM Peden",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "141.009",
    GUNSCORE_DAY3: "161.008",
    GUNSCORE_DAY4: "166.011",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "733.052",
    DAILY_T: "67.003",
    DONEGALL: "49.004",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "48.004",
    CORP: "46.002",
    TIMES: "46.001",
    POW: "70.005",
    DOFC: "45.002",
    LOVELL: "47.002",
    WIMB: "49.005",
    STG: "70.004",
    KINGS: "100.011",
    DAY1_POS: "417",
    DAY2_POS: "273",
    DAY3_POS: "432",
    DAY4_POS: "263",
    DAY5_POS: "323",
  },
  702: {
    Index: "702",
    Init: "SNS",
    NAME: "Penrose",
    full_NAME: "SNS Penrose",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.016",
    GUNSCORE_DAY2: "137.012",
    GUNSCORE_DAY3: "160.011",
    GUNSCORE_DAY4: "164.012",
    GUNSCORE_DAY5: "92.007",
    GUNSCORE_GA: "723.058",
    DAILY_T: "75.009",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "45.004",
    CORP: "45.004",
    TIMES: "46.001",
    POW: "68.007",
    DOFC: "46.003",
    LOVELL: "43.001",
    WIMB: "49.007",
    STG: "72.004",
    KINGS: "92.007",
    DAY1_POS: "230",
    DAY2_POS: "393",
    DAY3_POS: "445",
    DAY4_POS: "329",
    DAY5_POS: "576",
  },
  718: {
    Index: "718",
    Init: "KO",
    NAME: "Pugh",
    full_NAME: "KO Pugh",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "2",
    Day_2_BONUS: "5",
    Day_2_Total: "7",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "12",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "146.018",
    GUNSCORE_DAY3: "165.013",
    GUNSCORE_DAY4: "165.015",
    GUNSCORE_DAY5: "99.011",
    GUNSCORE_GA: "745.077",
    DAILY_T: "72.007",
    DONEGALL: "48.007",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "50.007",
    CORP: "48.006",
    TIMES: "48.004",
    POW: "69.004",
    DOFC: "48.005",
    LOVELL: "47.004",
    WIMB: "44.002",
    STG: "74.009",
    KINGS: "99.011",
    DAY1_POS: "206",
    DAY2_POS: "47",
    DAY3_POS: "294",
    DAY4_POS: "282",
    DAY5_POS: "383",
  },
  722: {
    Index: "722",
    Init: "J",
    NAME: "Pugsley MRCVS",
    full_NAME: "J Pugsley MRCVS",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "166.011",
    GUNSCORE_DAY4: "170.019",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "754.075",
    DAILY_T: "74.009",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.004",
    CORP: "42.002",
    TIMES: "48.003",
    POW: "69.004",
    DOFC: "49.004",
    LOVELL: "48.004",
    WIMB: "48.006",
    STG: "74.009",
    KINGS: "104.011",
    DAY1_POS: "29",
    DAY2_POS: "305",
    DAY3_POS: "260",
    DAY4_POS: "75",
    DAY5_POS: "63",
  },
  730: {
    Index: "730",
    Init: "MJ",
    NAME: "Nathan",
    full_NAME: "MJ Nathan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.014",
    GUNSCORE_DAY2: "134.011",
    GUNSCORE_DAY3: "151.011",
    GUNSCORE_DAY4: "163.016",
    GUNSCORE_DAY5: "94.005",
    GUNSCORE_GA: "711.057",
    DAILY_T: "73.006",
    DONEGALL: "47.002",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "48.006",
    CORP: "42.004",
    TIMES: "47.004",
    POW: "66.005",
    DOFC: "38.002",
    LOVELL: "47.004",
    WIMB: "44.004",
    STG: "72.008",
    KINGS: "94.005",
    DAY1_POS: "282",
    DAY2_POS: "463",
    DAY3_POS: "570",
    DAY4_POS: "347",
    DAY5_POS: "558",
  },
  736: {
    Index: "736",
    Init: "SC",
    NAME: "Postins",
    full_NAME: "SC Postins",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.02",
    GUNSCORE_DAY2: "144.017",
    GUNSCORE_DAY3: "171.011",
    GUNSCORE_DAY4: "163.009",
    GUNSCORE_DAY5: "99.009",
    GUNSCORE_GA: "744.066",
    DAILY_T: "74.009",
    DONEGALL: "47.007",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "49.005",
    CORP: "47.005",
    TIMES: "50.001",
    POW: "72.007",
    DOFC: "49.003",
    LOVELL: "45.003",
    WIMB: "47.002",
    STG: "71.004",
    KINGS: "99.009",
    DAY1_POS: "332",
    DAY2_POS: "108",
    DAY3_POS: "53",
    DAY4_POS: "380",
    DAY5_POS: "396",
  },
  738: {
    Index: "738",
    Init: "GD",
    NAME: "Palmer",
    full_NAME: "GD Palmer",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "7",
    Day_3_BONUS: "10",
    Day_3_Total: "17",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "17",
    GUNSCORE_DAY1: "169.017",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "172.019",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "749.071",
    DAILY_T: "72.009",
    DONEGALL: "48.002",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "47.004",
    CORP: "45.002",
    TIMES: "50.006",
    POW: "75.01",
    DOFC: "47.003",
    LOVELL: "45.001",
    WIMB: "47.004",
    STG: "73.009",
    KINGS: "102.01",
    DAY1_POS: "267",
    DAY2_POS: "259",
    DAY3_POS: "21",
    DAY4_POS: "287",
    DAY5_POS: "202",
  },
  740: {
    Index: "740",
    Init: "CM",
    NAME: "Brooks",
    full_NAME: "CM Brooks",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.022",
    GUNSCORE_DAY2: "143.009",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "166.016",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "742.072",
    DAILY_T: "74.012",
    DONEGALL: "48.006",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "47.001",
    CORP: "46.002",
    TIMES: "43.002",
    POW: "71.005",
    DOFC: "47.005",
    LOVELL: "46.004",
    WIMB: "49.003",
    STG: "71.009",
    KINGS: "102.013",
    DAY1_POS: "201",
    DAY2_POS: "187",
    DAY3_POS: "418",
    DAY4_POS: "226",
    DAY5_POS: "173",
  },
  748: {
    Index: "748",
    Init: "DFP",
    NAME: "Richards",
    full_NAME: "DFP Richards",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "5",
    Day_3_BONUS: "5",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "144.018",
    GUNSCORE_DAY3: "172.016",
    GUNSCORE_DAY4: "167.018",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "758.083",
    DAILY_T: "72.01",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "47.006",
    CORP: "48.006",
    TIMES: "50.003",
    POW: "73.008",
    DOFC: "49.005",
    LOVELL: "47.004",
    WIMB: "47.006",
    STG: "73.008",
    KINGS: "104.01",
    DAY1_POS: "149",
    DAY2_POS: "104",
    DAY3_POS: "27",
    DAY4_POS: "192",
    DAY5_POS: "72",
  },
  749: {
    Index: "749",
    Init: "WCP",
    NAME: "Richards",
    full_NAME: "WCP Richards",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "4",
    Day_2_BONUS: "5",
    Day_2_Total: "9",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "14",
    GUNSCORE_DAY1: "167.02",
    GUNSCORE_DAY2: "147.015",
    GUNSCORE_DAY3: "170.017",
    GUNSCORE_DAY4: "169.02",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "756.083",
    DAILY_T: "74.01",
    DONEGALL: "48.004",
    CONAN_DOYLE: "45.006",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "49.003",
    CORP: "48.005",
    TIMES: "50.004",
    POW: "72.006",
    DOFC: "48.007",
    LOVELL: "48.004",
    WIMB: "48.007",
    STG: "73.009",
    KINGS: "103.011",
    DAY1_POS: "332",
    DAY2_POS: "32",
    DAY3_POS: "77",
    DAY4_POS: "100",
    DAY5_POS: "124",
  },
  755: {
    Index: "755",
    Init: "RM",
    NAME: "Roberts",
    full_NAME: "RM Roberts",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "8",
    Day_2_BONUS: "5",
    Day_2_Total: "13",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "173.023",
    GUNSCORE_DAY2: "148.017",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "753.079",
    DAILY_T: "74.01",
    DONEGALL: "49.008",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.007",
    CORP: "50.003",
    TIMES: "49.005",
    POW: "71.005",
    DOFC: "46.005",
    LOVELL: "44.003",
    WIMB: "49.005",
    STG: "72.006",
    KINGS: "101.01",
    DAY1_POS: "54",
    DAY2_POS: "17",
    DAY3_POS: "240",
    DAY4_POS: "287",
    DAY5_POS: "269",
  },
  758: {
    Index: "758",
    Init: "IW",
    NAME: "Robertson",
    full_NAME: "IW Robertson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "158.014",
    GUNSCORE_DAY5: "93.006",
    GUNSCORE_GA: "720.06",
    DAILY_T: "72.006",
    DONEGALL: "48.005",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "47.004",
    CORP: "47.002",
    TIMES: "45.005",
    POW: "66.002",
    DOFC: "50.006",
    LOVELL: "44.003",
    WIMB: "43.002",
    STG: "71.009",
    KINGS: "93.006",
    DAY1_POS: "348",
    DAY2_POS: "259",
    DAY3_POS: "410",
    DAY4_POS: "460",
    DAY5_POS: "566",
  },
  759: {
    Index: "759",
    Init: "GW",
    NAME: "Robinson",
    full_NAME: "GW Robinson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.013",
    GUNSCORE_DAY2: "131.01",
    GUNSCORE_DAY3: "158.011",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "549.041",
    DAILY_T: "68.006",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.001",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "38.003",
    CORP: "46.003",
    TIMES: "47.005",
    POW: "66.004",
    DOFC: "45.002",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "96.007",
    DAY1_POS: "448",
    DAY2_POS: "519",
    DAY3_POS: "488",
    DAY4_POS: "636",
    DAY5_POS: "512",
  },
  769: {
    Index: "769",
    Init: "TA",
    NAME: "Ringer",
    full_NAME: "TA Ringer",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "3",
    Day_3_BONUS: "5",
    Day_3_Total: "8",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "11",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "144.019",
    GUNSCORE_DAY3: "171.02",
    GUNSCORE_DAY4: "168.016",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "757.088",
    DAILY_T: "74.01",
    DONEGALL: "47.004",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.006",
    CORP: "46.006",
    TIMES: "50.009",
    POW: "72.007",
    DOFC: "49.004",
    LOVELL: "49.003",
    WIMB: "48.007",
    STG: "71.006",
    KINGS: "104.013",
    DAY1_POS: "206",
    DAY2_POS: "103",
    DAY3_POS: "37",
    DAY4_POS: "168",
    DAY5_POS: "41",
  },
  771: {
    Index: "771",
    Init: "KWD",
    NAME: "Ramsey",
    full_NAME: "KWD Ramsey",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "11",
    Day_3_BONUS: "10",
    Day_3_Total: "21",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "23",
    Day_5_BONUS: "5",
    Day_5_Total: "28",
    GRAND_MULTIPLIER: "14",
    FINAL_TOTAL: "83",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "145.011",
    GUNSCORE_DAY3: "173.017",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "105.016",
    GUNSCORE_GA: "763.081",
    DAILY_T: "74.01",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.003",
    CORP: "48.004",
    TIMES: "50.005",
    POW: "73.008",
    DOFC: "50.004",
    LOVELL: "42.001",
    WIMB: "50.004",
    STG: "74.009",
    KINGS: "105.016",
    DAY1_POS: "29",
    DAY2_POS: "99",
    DAY3_POS: "11",
    DAY4_POS: "240",
    DAY5_POS: "4",
  },
  772: {
    Index: "772",
    Init: "M",
    NAME: "Reid",
    full_NAME: "M Reid",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.017",
    GUNSCORE_DAY2: "135.008",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "157.013",
    GUNSCORE_DAY5: "100.005",
    GUNSCORE_GA: "725.059",
    DAILY_T: "73.009",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "46.002",
    CORP: "41.001",
    TIMES: "49.006",
    POW: "69.005",
    DOFC: "47.005",
    LOVELL: "44.003",
    WIMB: "49.007",
    STG: "64.003",
    KINGS: "100.005",
    DAY1_POS: "304",
    DAY2_POS: "453",
    DAY3_POS: "277",
    DAY4_POS: "484",
    DAY5_POS: "374",
  },
  775: {
    Index: "775",
    Init: "EBDS",
    NAME: "Reis",
    full_NAME: "EBDS Reis",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "173.019",
    GUNSCORE_DAY2: "128.013",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "164.012",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "566.055",
    DAILY_T: "74.009",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "48.003",
    CORP: "32.003",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "46.002",
    WIMB: "46.004",
    STG: "72.006",
    KINGS: "101.011",
    DAY1_POS: "77",
    DAY2_POS: "559",
    DAY3_POS: "643",
    DAY4_POS: "329",
    DAY5_POS: "254",
  },
  782: {
    Index: "782",
    Init: "RF",
    NAME: "Burden",
    full_NAME: "RF Burden",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.016",
    GUNSCORE_DAY2: "138.007",
    GUNSCORE_DAY3: "163.012",
    GUNSCORE_DAY4: "167.01",
    GUNSCORE_DAY5: "95.004",
    GUNSCORE_GA: "728.049",
    DAILY_T: "72.007",
    DONEGALL: "45.003",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "48.004",
    CORP: "44.001",
    TIMES: "46.004",
    POW: "70.005",
    DOFC: "47.003",
    LOVELL: "46.002",
    WIMB: "48.002",
    STG: "73.006",
    KINGS: "95.004",
    DAY1_POS: "408",
    DAY2_POS: "389",
    DAY3_POS: "351",
    DAY4_POS: "215",
    DAY5_POS: "551",
  },
  784: {
    Index: "784",
    Init: "GRP",
    NAME: "Shapland",
    full_NAME: "GRP Shapland",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.015",
    GUNSCORE_DAY2: "139.008",
    GUNSCORE_DAY3: "153.008",
    GUNSCORE_DAY4: "159.011",
    GUNSCORE_DAY5: "97.003",
    GUNSCORE_GA: "715.045",
    DAILY_T: "72.008",
    DONEGALL: "49.002",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "48.004",
    CORP: "46.002",
    TIMES: "43.001",
    POW: "67.005",
    DOFC: "43.002",
    LOVELL: "44.004",
    WIMB: "44",
    STG: "71.007",
    KINGS: "97.003",
    DAY1_POS: "356",
    DAY2_POS: "352",
    DAY3_POS: "558",
    DAY4_POS: "453",
    DAY5_POS: "503",
  },
  787: {
    Index: "787",
    Init: "DE",
    NAME: "Simkin",
    full_NAME: "DE Simkin",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.014",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "166.01",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "734.054",
    DAILY_T: "71.005",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.002",
    CORP: "43.003",
    TIMES: "48.001",
    POW: "71.005",
    DOFC: "47.004",
    LOVELL: "47.004",
    WIMB: "46.004",
    STG: "69.004",
    KINGS: "97.007",
    DAY1_POS: "282",
    DAY2_POS: "305",
    DAY3_POS: "261",
    DAY4_POS: "395",
    DAY5_POS: "483",
  },
  799: {
    Index: "799",
    Init: "ND",
    NAME: "Stangroom",
    full_NAME: "ND Stangroom",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "146.012",
    GUNSCORE_DAY3: "167.015",
    GUNSCORE_DAY4: "162.016",
    GUNSCORE_DAY5: "99.011",
    GUNSCORE_GA: "746.074",
    DAILY_T: "73.009",
    DONEGALL: "50.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "49.004",
    CORP: "47.002",
    TIMES: "49.004",
    POW: "71.009",
    DOFC: "47.002",
    LOVELL: "40.001",
    WIMB: "49.006",
    STG: "73.009",
    KINGS: "99.011",
    DAY1_POS: "107",
    DAY2_POS: "62",
    DAY3_POS: "195",
    DAY4_POS: "385",
    DAY5_POS: "383",
  },
  803: {
    Index: "803",
    Init: "DJ",
    NAME: "Stewart",
    full_NAME: "DJ Stewart",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.012",
    GUNSCORE_DAY2: "128.006",
    GUNSCORE_DAY3: "160.005",
    GUNSCORE_DAY4: "150.004",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "702.036",
    DAILY_T: "71.003",
    DONEGALL: "46.006",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "44.003",
    ALEXANDRA: "43",
    CORP: "41.003",
    TIMES: "45.001",
    POW: "70.001",
    DOFC: "45.003",
    LOVELL: "43.001",
    WIMB: "45.003",
    STG: "62",
    KINGS: "102.009",
    DAY1_POS: "500",
    DAY2_POS: "567",
    DAY3_POS: "456",
    DAY4_POS: "562",
    DAY5_POS: "216",
  },
  808: {
    Index: "808",
    Init: "JPL",
    NAME: "Sweet",
    full_NAME: "JPL Sweet",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "167.018",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "171.018",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "749.075",
    DAILY_T: "71.007",
    DONEGALL: "49.008",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.006",
    CORP: "42.003",
    TIMES: "50.008",
    POW: "72.006",
    DOFC: "48.005",
    LOVELL: "47.004",
    WIMB: "50.006",
    STG: "74.008",
    KINGS: "101.007",
    DAY1_POS: "337",
    DAY2_POS: "288",
    DAY3_POS: "65",
    DAY4_POS: "54",
    DAY5_POS: "304",
  },
  813: {
    Index: "813",
    Init: "PJ",
    NAME: "Bysshe",
    full_NAME: "PJ Bysshe",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.013",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "166.018",
    GUNSCORE_DAY4: "166.017",
    GUNSCORE_DAY5: "98.009",
    GUNSCORE_GA: "735.067",
    DAILY_T: "72.007",
    DONEGALL: "47.002",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "46.005",
    CORP: "44.001",
    TIMES: "48.005",
    POW: "70.007",
    DOFC: "48.006",
    LOVELL: "47.004",
    WIMB: "47.005",
    STG: "72.008",
    KINGS: "98.009",
    DAY1_POS: "389",
    DAY2_POS: "338",
    DAY3_POS: "220",
    DAY4_POS: "223",
    DAY5_POS: "441",
  },
  817: {
    Index: "817",
    Init: "JR",
    NAME: "Stirland",
    full_NAME: "JR Stirland",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.01",
    GUNSCORE_DAY2: "132.008",
    GUNSCORE_DAY3: "162.015",
    GUNSCORE_DAY4: "165.018",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "722.058",
    DAILY_T: "72.005",
    DONEGALL: "45.003",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "43.003",
    CORP: "42.001",
    TIMES: "47.005",
    POW: "69.005",
    DOFC: "46.005",
    LOVELL: "45.005",
    WIMB: "48.004",
    STG: "72.009",
    KINGS: "98.007",
    DAY1_POS: "435",
    DAY2_POS: "511",
    DAY3_POS: "371",
    DAY4_POS: "271",
    DAY5_POS: "455",
  },
  821: {
    Index: "821",
    Init: "H",
    NAME: "Sanders",
    full_NAME: "H Sanders",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "3",
    Day_3_BONUS: "0",
    Day_3_Total: "3",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "8",
    GUNSCORE_DAY1: "171.02",
    GUNSCORE_DAY2: "142.011",
    GUNSCORE_DAY3: "171.019",
    GUNSCORE_DAY4: "166.022",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "751.084",
    DAILY_T: "73.009",
    DONEGALL: "48.004",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.005",
    CORP: "46.002",
    TIMES: "49.004",
    POW: "74.011",
    DOFC: "48.004",
    LOVELL: "45.003",
    WIMB: "47.006",
    STG: "74.013",
    KINGS: "101.012",
    DAY1_POS: "159",
    DAY2_POS: "225",
    DAY3_POS: "39",
    DAY4_POS: "218",
    DAY5_POS: "247",
  },
  824: {
    Index: "824",
    Init: "JPM",
    NAME: "Stevens",
    full_NAME: "JPM Stevens",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.013",
    GUNSCORE_DAY2: "142.008",
    GUNSCORE_DAY3: "166.014",
    GUNSCORE_DAY4: "167.01",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "739.055",
    DAILY_T: "73.006",
    DONEGALL: "44.003",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "50.003",
    ALEXANDRA: "49.003",
    CORP: "43.002",
    TIMES: "47.005",
    POW: "71.007",
    DOFC: "48.002",
    LOVELL: "46.002",
    WIMB: "48.003",
    STG: "73.005",
    KINGS: "100.01",
    DAY1_POS: "448",
    DAY2_POS: "233",
    DAY3_POS: "247",
    DAY4_POS: "215",
    DAY5_POS: "333",
  },
  830: {
    Index: "830",
    Init: "AJW",
    NAME: "Sturrock",
    full_NAME: "AJW Sturrock",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "154.013",
    GUNSCORE_DAY4: "166.012",
    GUNSCORE_DAY5: "99.009",
    GUNSCORE_GA: "727.061",
    DAILY_T: "73.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "46.001",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "50.004",
    CORP: "45.004",
    TIMES: "41.002",
    POW: "66.007",
    DOFC: "47.004",
    LOVELL: "47.004",
    WIMB: "48.005",
    STG: "71.003",
    KINGS: "99.009",
    DAY1_POS: "311",
    DAY2_POS: "298",
    DAY3_POS: "540",
    DAY4_POS: "259",
    DAY5_POS: "396",
  },
  832: {
    Index: "832",
    Init: "RA",
    NAME: "Benest",
    full_NAME: "RA Benest",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.016",
    GUNSCORE_DAY2: "138.014",
    GUNSCORE_DAY3: "166.012",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "748.069",
    DAILY_T: "74.006",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "47.005",
    CORP: "41.001",
    TIMES: "48.003",
    POW: "71.004",
    DOFC: "47.005",
    LOVELL: "46.003",
    WIMB: "49.006",
    STG: "74.009",
    KINGS: "103.009",
    DAY1_POS: "129",
    DAY2_POS: "361",
    DAY3_POS: "257",
    DAY4_POS: "110",
    DAY5_POS: "147",
  },
  835: {
    Index: "835",
    Init: "NRJ",
    NAME: "Brasier",
    full_NAME: "NRJ Brasier",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "3",
    Day_2_BONUS: "15",
    Day_2_Total: "18",
    Day_3: "5",
    Day_3_BONUS: "0",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "13",
    FINAL_TOTAL: "46",
    GUNSCORE_DAY1: "172.019",
    GUNSCORE_DAY2: "146.024",
    GUNSCORE_DAY3: "172.014",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "102.016",
    GUNSCORE_GA: "762.093",
    DAILY_T: "72.006",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "50.01",
    CORP: "46.005",
    TIMES: "49.005",
    POW: "74.006",
    DOFC: "49.003",
    LOVELL: "49.005",
    WIMB: "47.005",
    STG: "74.01",
    KINGS: "102.016",
    DAY1_POS: "116",
    DAY2_POS: "41",
    DAY3_POS: "30",
    DAY4_POS: "66",
    DAY5_POS: "164",
  },
  841: {
    Index: "841",
    Init: "RK",
    NAME: "Sheead",
    full_NAME: "RK Sheead",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "153.005",
    GUNSCORE_DAY2: "128.005",
    GUNSCORE_DAY3: "153.009",
    GUNSCORE_DAY4: "150.005",
    GUNSCORE_DAY5: "94.005",
    GUNSCORE_GA: "678.029",
    DAILY_T: "68.004",
    DONEGALL: "42",
    CONAN_DOYLE: "43.001",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "47.001",
    CORP: "36.001",
    TIMES: "45.004",
    POW: "66.004",
    DOFC: "42.001",
    LOVELL: "42.001",
    WIMB: "42.002",
    STG: "66.002",
    KINGS: "94.005",
    DAY1_POS: "589",
    DAY2_POS: "569",
    DAY3_POS: "556",
    DAY4_POS: "560",
    DAY5_POS: "558",
  },
  843: {
    Index: "843",
    Init: "LK",
    NAME: "Mace",
    full_NAME: "LK Mace",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "138.011",
    GUNSCORE_DAY3: "166.018",
    GUNSCORE_DAY4: "168.016",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "749.077",
    DAILY_T: "75.008",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "46.006",
    ALEXANDRA: "47.004",
    CORP: "45.001",
    TIMES: "48.007",
    POW: "71.006",
    DOFC: "47.005",
    LOVELL: "48.004",
    WIMB: "48.004",
    STG: "72.008",
    KINGS: "103.009",
    DAY1_POS: "29",
    DAY2_POS: "374",
    DAY3_POS: "220",
    DAY4_POS: "168",
    DAY5_POS: "147",
  },
  844: {
    Index: "844",
    Init: "T",
    NAME: "Steele",
    full_NAME: "T Steele",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "166.009",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "744.059",
    DAILY_T: "67.002",
    DONEGALL: "49.004",
    CONAN_DOYLE: "50.003",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.004",
    CORP: "45.002",
    TIMES: "50.005",
    POW: "71.005",
    DOFC: "47.005",
    LOVELL: "48.004",
    WIMB: "46.003",
    STG: "71.006",
    KINGS: "103.01",
    DAY1_POS: "398",
    DAY2_POS: "216",
    DAY3_POS: "154",
    DAY4_POS: "294",
    DAY5_POS: "138",
  },
  845: {
    Index: "845",
    Init: "R",
    NAME: "Stewart",
    full_NAME: "R Stewart",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "2",
    Day_2_BONUS: "5",
    Day_2_Total: "7",
    Day_3: "11",
    Day_3_BONUS: "5",
    Day_3_Total: "16",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "6",
    Day_5_BONUS: "5",
    Day_5_Total: "11",
    GRAND_MULTIPLIER: "28",
    FINAL_TOTAL: "72",
    GUNSCORE_DAY1: "171.023",
    GUNSCORE_DAY2: "146.02",
    GUNSCORE_DAY3: "173.017",
    GUNSCORE_DAY4: "170.024",
    GUNSCORE_DAY5: "105.011",
    GUNSCORE_GA: "765.095",
    DAILY_T: "74.012",
    DONEGALL: "50.007",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "48.007",
    CORP: "48.007",
    TIMES: "50.006",
    POW: "74.009",
    DOFC: "49.002",
    LOVELL: "47.006",
    WIMB: "48.005",
    STG: "75.013",
    KINGS: "105.011",
    DAY1_POS: "140",
    DAY2_POS: "43",
    DAY3_POS: "11",
    DAY4_POS: "57",
    DAY5_POS: "23",
  },
  846: {
    Index: "846",
    Init: "CR",
    NAME: "Smith",
    full_NAME: "CR Smith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.011",
    GUNSCORE_DAY2: "137.012",
    GUNSCORE_DAY3: "159.011",
    GUNSCORE_DAY4: "155.009",
    GUNSCORE_DAY5: "62.003",
    GUNSCORE_GA: "678.046",
    DAILY_T: "71.005",
    DONEGALL: "47.003",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "47.004",
    CORP: "43.003",
    TIMES: "48.004",
    POW: "67.004",
    DOFC: "44.003",
    LOVELL: "45.004",
    WIMB: "40.001",
    STG: "70.004",
    KINGS: "62.003",
    DAY1_POS: "432",
    DAY2_POS: "393",
    DAY3_POS: "467",
    DAY4_POS: "517",
    DAY5_POS: "633",
  },
  852: {
    Index: "852",
    Init: "JM",
    NAME: "Taylor",
    full_NAME: "JM Taylor",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "5",
    Day_3_BONUS: "5",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "172.017",
    GUNSCORE_DAY4: "168.018",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "758.075",
    DAILY_T: "75.01",
    DONEGALL: "48.002",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.006",
    CORP: "46.003",
    TIMES: "48.005",
    POW: "74.008",
    DOFC: "50.004",
    LOVELL: "45.002",
    WIMB: "49.006",
    STG: "74.01",
    KINGS: "104.01",
    DAY1_POS: "176",
    DAY2_POS: "168",
    DAY3_POS: "26",
    DAY4_POS: "158",
    DAY5_POS: "72",
  },
  854: {
    Index: "854",
    Init: "SA",
    NAME: "Thomas",
    full_NAME: "SA Thomas",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.01",
    GUNSCORE_DAY2: "145.015",
    GUNSCORE_DAY3: "164.017",
    GUNSCORE_DAY4: "169.02",
    GUNSCORE_DAY5: "102.015",
    GUNSCORE_GA: "747.077",
    DAILY_T: "73.005",
    DONEGALL: "48.003",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.005",
    CORP: "47.005",
    TIMES: "48.006",
    POW: "72.006",
    DOFC: "44.005",
    LOVELL: "45.004",
    WIMB: "49.006",
    STG: "75.01",
    KINGS: "102.015",
    DAY1_POS: "365",
    DAY2_POS: "81",
    DAY3_POS: "306",
    DAY4_POS: "100",
    DAY5_POS: "165",
  },
  857: {
    Index: "857",
    Init: "JMA",
    NAME: "Thompson",
    full_NAME: "JMA Thompson",
    Day_1: "3",
    Day_1_BONUS: "10",
    Day_1_Total: "13",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "174.02",
    GUNSCORE_DAY2: "136.007",
    GUNSCORE_DAY3: "169.013",
    GUNSCORE_DAY4: "167.016",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "748.064",
    DAILY_T: "74.01",
    DONEGALL: "50.005",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "46.002",
    CORP: "44.003",
    TIMES: "50.004",
    POW: "71.006",
    DOFC: "48.003",
    LOVELL: "46.003",
    WIMB: "48.006",
    STG: "73.007",
    KINGS: "102.008",
    DAY1_POS: "42",
    DAY2_POS: "430",
    DAY3_POS: "124",
    DAY4_POS: "197",
    DAY5_POS: "225",
  },
  860: {
    Index: "860",
    Init: "ATB",
    NAME: "Tompson",
    full_NAME: "ATB Tompson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "1",
    Day_4_BONUS: "5",
    Day_4_Total: "6",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "11",
    GUNSCORE_DAY1: "165.018",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "171.011",
    GUNSCORE_DAY4: "171.019",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "745.069",
    DAILY_T: "73.011",
    DONEGALL: "46.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "46.004",
    CORP: "46.003",
    TIMES: "50.005",
    POW: "73.001",
    DOFC: "48.005",
    LOVELL: "49.003",
    WIMB: "50.007",
    STG: "72.009",
    KINGS: "98.008",
    DAY1_POS: "401",
    DAY2_POS: "288",
    DAY3_POS: "53",
    DAY4_POS: "50",
    DAY5_POS: "446",
  },
  861: {
    Index: "861",
    Init: "MK",
    NAME: "Townsend",
    full_NAME: "MK Townsend",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.01",
    GUNSCORE_DAY2: "131.005",
    GUNSCORE_DAY3: "156.008",
    GUNSCORE_DAY4: "154.006",
    GUNSCORE_DAY5: "82.003",
    GUNSCORE_GA: "680.032",
    DAILY_T: "67.005",
    DONEGALL: "43.001",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "44",
    ALEXANDRA: "47.003",
    CORP: "40.002",
    TIMES: "42",
    POW: "66.005",
    DOFC: "48.003",
    LOVELL: "46.003",
    WIMB: "44",
    STG: "64.003",
    KINGS: "82.003",
    DAY1_POS: "560",
    DAY2_POS: "532",
    DAY3_POS: "527",
    DAY4_POS: "532",
    DAY5_POS: "625",
  },
  862: {
    Index: "862",
    Init: "CN",
    NAME: "Tremlett",
    full_NAME: "CN Tremlett",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "144.011",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "168.014",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "758.072",
    DAILY_T: "75.01",
    DONEGALL: "50.005",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "48.005",
    CORP: "46.001",
    TIMES: "47.005",
    POW: "73.008",
    DOFC: "49.004",
    LOVELL: "47.002",
    WIMB: "48.006",
    STG: "73.006",
    KINGS: "104.01",
    DAY1_POS: "69",
    DAY2_POS: "140",
    DAY3_POS: "105",
    DAY4_POS: "177",
    DAY5_POS: "72",
  },
  874: {
    Index: "874",
    Init: "JR",
    NAME: "Tuck",
    full_NAME: "JR Tuck",
    Day_1: "8",
    Day_1_BONUS: "15",
    Day_1_Total: "23",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "28",
    GUNSCORE_DAY1: "174.027",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "169.023",
    GUNSCORE_DAY4: "169.01",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "759.082",
    DAILY_T: "75.009",
    DONEGALL: "50.01",
    CONAN_DOYLE: "49.008",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "47.004",
    CORP: "48.004",
    TIMES: "49.008",
    POW: "71.01",
    DOFC: "49.005",
    LOVELL: "46.002",
    WIMB: "48.004",
    STG: "75.004",
    KINGS: "103.009",
    DAY1_POS: "17",
    DAY2_POS: "127",
    DAY3_POS: "90",
    DAY4_POS: "139",
    DAY5_POS: "147",
  },
  876: {
    Index: "876",
    Init: "AC",
    NAME: "Thomson",
    full_NAME: "AC Thomson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "168.018",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "168.016",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "747.075",
    DAILY_T: "71.007",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "50.006",
    CORP: "46.002",
    TIMES: "50.007",
    POW: "72.006",
    DOFC: "46.005",
    LOVELL: "46.003",
    WIMB: "50.005",
    STG: "72.008",
    KINGS: "101.011",
    DAY1_POS: "299",
    DAY2_POS: "216",
    DAY3_POS: "136",
    DAY4_POS: "168",
    DAY5_POS: "254",
  },
  878: {
    Index: "878",
    Init: "JC",
    NAME: "Underwood",
    full_NAME: "JC Underwood",
    Day_1: "7",
    Day_1_BONUS: "10",
    Day_1_Total: "17",
    Day_2: "5",
    Day_2_BONUS: "10",
    Day_2_Total: "15",
    Day_3: "8",
    Day_3_BONUS: "5",
    Day_3_Total: "13",
    Day_4: "5",
    Day_4_BONUS: "5",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "54",
    FINAL_TOTAL: "109",
    GUNSCORE_DAY1: "174.026",
    GUNSCORE_DAY2: "147.017",
    GUNSCORE_DAY3: "172.023",
    GUNSCORE_DAY4: "172.025",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "768.102",
    DAILY_T: "75.01",
    DONEGALL: "50.009",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "50.006",
    CORP: "47.003",
    TIMES: "49.006",
    POW: "75.012",
    DOFC: "48.005",
    LOVELL: "48.006",
    WIMB: "50.008",
    STG: "74.011",
    KINGS: "103.011",
    DAY1_POS: "21",
    DAY2_POS: "26",
    DAY3_POS: "17",
    DAY4_POS: "26",
    DAY5_POS: "124",
  },
  880: {
    Index: "880",
    Init: "NJ",
    NAME: "Verduyn",
    full_NAME: "NJ Verduyn",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "7",
    Day_3_BONUS: "10",
    Day_3_Total: "17",
    Day_4: "5",
    Day_4_BONUS: "5",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "32",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "142.016",
    GUNSCORE_DAY3: "172.019",
    GUNSCORE_DAY4: "172.021",
    GUNSCORE_DAY5: "102.006",
    GUNSCORE_GA: "758.08",
    DAILY_T: "71.008",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "47.007",
    CORP: "46.002",
    TIMES: "50.007",
    POW: "72.006",
    DOFC: "50.006",
    LOVELL: "48.003",
    WIMB: "49.006",
    STG: "75.012",
    KINGS: "102.006",
    DAY1_POS: "217",
    DAY2_POS: "195",
    DAY3_POS: "21",
    DAY4_POS: "30",
    DAY5_POS: "238",
  },
  881: {
    Index: "881",
    Init: "TL",
    NAME: "Bedwell",
    full_NAME: "TL Bedwell",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "167.011",
    GUNSCORE_DAY4: "169.022",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "751.073",
    DAILY_T: "75.007",
    DONEGALL: "48.005",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "47.003",
    CORP: "48.004",
    TIMES: "49.005",
    POW: "70.005",
    DOFC: "48.001",
    LOVELL: "48.006",
    WIMB: "48.006",
    STG: "73.01",
    KINGS: "100.01",
    DAY1_POS: "183",
    DAY2_POS: "120",
    DAY3_POS: "208",
    DAY4_POS: "93",
    DAY5_POS: "333",
  },
  887: {
    Index: "887",
    Init: "R",
    NAME: "Winney",
    full_NAME: "R Winney",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.01",
    GUNSCORE_DAY2: "140.009",
    GUNSCORE_DAY3: "162.011",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "97.002",
    GUNSCORE_GA: "726.046",
    DAILY_T: "69",
    DONEGALL: "48.005",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "46.003",
    CORP: "46.004",
    TIMES: "49.005",
    POW: "70.004",
    DOFC: "43.002",
    LOVELL: "44.004",
    WIMB: "48.004",
    STG: "71.006",
    KINGS: "97.002",
    DAY1_POS: "459",
    DAY2_POS: "315",
    DAY3_POS: "388",
    DAY4_POS: "355",
    DAY5_POS: "506",
  },
  891: {
    Index: "891",
    Init: "JD",
    NAME: "Warburton",
    full_NAME: "JD Warburton",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "8",
    Day_3_BONUS: "5",
    Day_3_Total: "13",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "18",
    FINAL_TOTAL: "41",
    GUNSCORE_DAY1: "173.022",
    GUNSCORE_DAY2: "146.016",
    GUNSCORE_DAY3: "172.024",
    GUNSCORE_DAY4: "170.017",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "764.089",
    DAILY_T: "74.009",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "49.006",
    CORP: "50.007",
    TIMES: "50.009",
    POW: "73.008",
    DOFC: "49.007",
    LOVELL: "47.003",
    WIMB: "49.004",
    STG: "74.01",
    KINGS: "103.01",
    DAY1_POS: "59",
    DAY2_POS: "53",
    DAY3_POS: "16",
    DAY4_POS: "82",
    DAY5_POS: "138",
  },
  896: {
    Index: "896",
    Init: "JGM",
    NAME: "Webster",
    full_NAME: "JGM Webster",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.009",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "164.01",
    GUNSCORE_DAY5: "86.002",
    GUNSCORE_GA: "716.048",
    DAILY_T: "71.007",
    DONEGALL: "46.002",
    CONAN_DOYLE: "42",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "47.005",
    CORP: "46.002",
    TIMES: "49.005",
    POW: "71.006",
    DOFC: "46.005",
    LOVELL: "44.001",
    WIMB: "47.002",
    STG: "73.007",
    KINGS: "86.002",
    DAY1_POS: "533",
    DAY2_POS: "259",
    DAY3_POS: "231",
    DAY4_POS: "338",
    DAY5_POS: "619",
  },
  898: {
    Index: "898",
    Init: "RA",
    NAME: "Welford",
    full_NAME: "RA Welford",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "10",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "145.014",
    GUNSCORE_DAY3: "166.017",
    GUNSCORE_DAY4: "169.01",
    GUNSCORE_DAY5: "99.006",
    GUNSCORE_GA: "747.062",
    DAILY_T: "70.005",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.003",
    CORP: "48.007",
    TIMES: "49.008",
    POW: "69.004",
    DOFC: "48.005",
    LOVELL: "44.001",
    WIMB: "50.005",
    STG: "75.004",
    KINGS: "99.006",
    DAY1_POS: "311",
    DAY2_POS: "86",
    DAY3_POS: "225",
    DAY4_POS: "139",
    DAY5_POS: "425",
  },
  903: {
    Index: "903",
    Init: "JE",
    NAME: "White",
    full_NAME: "JE White",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.016",
    GUNSCORE_DAY2: "131.008",
    GUNSCORE_DAY3: "91.003",
    GUNSCORE_DAY4: "146.008",
    GUNSCORE_DAY5: "95.01",
    GUNSCORE_GA: "627.045",
    DAILY_T: "68.006",
    DONEGALL: "50.005",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "46.004",
    CORP: "41.003",
    TIMES: "48.002",
    POW: "0",
    DOFC: "43.001",
    LOVELL: "34",
    WIMB: "45.003",
    STG: "67.005",
    KINGS: "95.01",
    DAY1_POS: "438",
    DAY2_POS: "522",
    DAY3_POS: "639",
    DAY4_POS: "580",
    DAY5_POS: "534",
  },
  906: {
    Index: "906",
    Init: "LT",
    NAME: "Wicker",
    full_NAME: "LT Wicker",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.019",
    GUNSCORE_DAY2: "138.008",
    GUNSCORE_DAY3: "162.009",
    GUNSCORE_DAY4: "157.014",
    GUNSCORE_DAY5: "100.006",
    GUNSCORE_GA: "723.056",
    DAILY_T: "74.011",
    DONEGALL: "44.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.004",
    CORP: "44",
    TIMES: "49.004",
    POW: "68.004",
    DOFC: "45.001",
    LOVELL: "43.002",
    WIMB: "47.003",
    STG: "67.009",
    KINGS: "100.006",
    DAY1_POS: "369",
    DAY2_POS: "384",
    DAY3_POS: "395",
    DAY4_POS: "482",
    DAY5_POS: "372",
  },
  926: {
    Index: "926",
    Init: "RA",
    NAME: "Wiltshire",
    full_NAME: "RA Wiltshire",
    Day_1: "2",
    Day_1_BONUS: "5",
    Day_1_Total: "7",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "7",
    GUNSCORE_DAY1: "173.027",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "162.013",
    GUNSCORE_DAY4: "168.016",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "749.083",
    DAILY_T: "75.012",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.009",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.006",
    CORP: "47.004",
    TIMES: "46.005",
    POW: "72.006",
    DOFC: "44.002",
    LOVELL: "48.003",
    WIMB: "48.005",
    STG: "72.008",
    KINGS: "103.013",
    DAY1_POS: "47",
    DAY2_POS: "160",
    DAY3_POS: "377",
    DAY4_POS: "168",
    DAY5_POS: "97",
  },
  928: {
    Index: "928",
    Init: "CP",
    NAME: "Weeden",
    full_NAME: "CP Weeden",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "23",
    Day_5_BONUS: "5",
    Day_5_Total: "28",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "48",
    GUNSCORE_DAY1: "171.014",
    GUNSCORE_DAY2: "146.015",
    GUNSCORE_DAY3: "169.021",
    GUNSCORE_DAY4: "169.016",
    GUNSCORE_DAY5: "105.016",
    GUNSCORE_GA: "760.082",
    DAILY_T: "72.005",
    DONEGALL: "49.003",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "50.007",
    CORP: "46.003",
    TIMES: "50.008",
    POW: "72.006",
    DOFC: "47.007",
    LOVELL: "46.004",
    WIMB: "49.005",
    STG: "74.007",
    KINGS: "105.016",
    DAY1_POS: "195",
    DAY2_POS: "55",
    DAY3_POS: "93",
    DAY4_POS: "124",
    DAY5_POS: "4",
  },
  929: {
    Index: "929",
    Init: "MW",
    NAME: "Wrigley",
    full_NAME: "MW Wrigley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "164.012",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "162.013",
    GUNSCORE_DAY4: "169.017",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "738.066",
    DAILY_T: "68.003",
    DONEGALL: "48.004",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "50.006",
    CORP: "45.004",
    TIMES: "50.005",
    POW: "66.003",
    DOFC: "46.005",
    LOVELL: "48.005",
    WIMB: "47.003",
    STG: "74.009",
    KINGS: "100.011",
    DAY1_POS: "452",
    DAY2_POS: "168",
    DAY3_POS: "377",
    DAY4_POS: "120",
    DAY5_POS: "323",
  },
  933: {
    Index: "933",
    Init: "JA",
    NAME: "Watson",
    full_NAME: "JA Watson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "5",
    Day_4_BONUS: "10",
    Day_4_Total: "15",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "9",
    FINAL_TOTAL: "39",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "173.016",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "761.075",
    DAILY_T: "74.01",
    DONEGALL: "50.008",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "48.005",
    CORP: "46.003",
    TIMES: "50.006",
    POW: "73.008",
    DOFC: "45.002",
    LOVELL: "50.004",
    WIMB: "48.004",
    STG: "75.008",
    KINGS: "104.01",
    DAY1_POS: "107",
    DAY2_POS: "127",
    DAY3_POS: "147",
    DAY4_POS: "25",
    DAY5_POS: "72",
  },
  951: {
    Index: "951",
    Init: "DG",
    NAME: "Young",
    full_NAME: "DG Young",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.019",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "746.076",
    DAILY_T: "72.01",
    DONEGALL: "48.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.006",
    CORP: "44.003",
    TIMES: "49.005",
    POW: "73.009",
    DOFC: "46.004",
    LOVELL: "45.004",
    WIMB: "48.004",
    STG: "73.007",
    KINGS: "103.011",
    DAY1_POS: "257",
    DAY2_POS: "288",
    DAY3_POS: "136",
    DAY4_POS: "230",
    DAY5_POS: "124",
  },
  954: {
    Index: "954",
    Init: "DJH",
    NAME: "Birrell",
    full_NAME: "DJH Birrell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.017",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "734.065",
    DAILY_T: "70.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "48.004",
    CORP: "48.003",
    TIMES: "49.005",
    POW: "68.003",
    DOFC: "44.004",
    LOVELL: "46.004",
    WIMB: "46.003",
    STG: "71.007",
    KINGS: "99.008",
    DAY1_POS: "343",
    DAY2_POS: "120",
    DAY3_POS: "418",
    DAY4_POS: "355",
    DAY5_POS: "402",
  },
  961: {
    Index: "961",
    Init: "PM",
    NAME: "de Voil",
    full_NAME: "PM de Voil",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "145.018",
    GUNSCORE_DAY3: "167.018",
    GUNSCORE_DAY4: "166.013",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "748.079",
    DAILY_T: "72.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "50.009",
    CORP: "47.004",
    TIMES: "49.006",
    POW: "72.007",
    DOFC: "46.005",
    LOVELL: "43.001",
    WIMB: "50.006",
    STG: "73.006",
    KINGS: "101.01",
    DAY1_POS: "249",
    DAY2_POS: "68",
    DAY3_POS: "181",
    DAY4_POS: "255",
    DAY5_POS: "269",
  },
  966: {
    Index: "966",
    Init: "CA",
    NAME: "Brook",
    full_NAME: "CA Brook",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "168.025",
    GUNSCORE_DAY2: "145.015",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "169.021",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "752.084",
    DAILY_T: "73.013",
    DONEGALL: "47.006",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "50.007",
    CORP: "47.002",
    TIMES: "50.007",
    POW: "71.006",
    DOFC: "47.001",
    LOVELL: "48.003",
    WIMB: "49.009",
    STG: "72.009",
    KINGS: "102.009",
    DAY1_POS: "288",
    DAY2_POS: "81",
    DAY3_POS: "164",
    DAY4_POS: "94",
    DAY5_POS: "216",
  },
  970: {
    Index: "970",
    Init: "RH",
    NAME: "Kitson",
    full_NAME: "RH Kitson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.016",
    GUNSCORE_DAY2: "135.013",
    GUNSCORE_DAY3: "165.019",
    GUNSCORE_DAY4: "165.016",
    GUNSCORE_DAY5: "97.013",
    GUNSCORE_GA: "732.077",
    DAILY_T: "72.005",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "45.003",
    CORP: "42.004",
    TIMES: "48.006",
    POW: "71.008",
    DOFC: "46.005",
    LOVELL: "48.005",
    WIMB: "47.006",
    STG: "70.005",
    KINGS: "97.013",
    DAY1_POS: "230",
    DAY2_POS: "435",
    DAY3_POS: "268",
    DAY4_POS: "277",
    DAY5_POS: "471",
  },
  972: {
    Index: "972",
    Init: "BA",
    NAME: "Horwood",
    full_NAME: "BA Horwood",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "170.016",
    GUNSCORE_DAY2: "145.008",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "169.009",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "751.061",
    DAILY_T: "75.009",
    DONEGALL: "49.004",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "50.004",
    CORP: "46.001",
    TIMES: "47.006",
    POW: "71.008",
    DOFC: "47.002",
    LOVELL: "45.004",
    WIMB: "50.002",
    STG: "74.003",
    KINGS: "102.012",
    DAY1_POS: "230",
    DAY2_POS: "101",
    DAY3_POS: "277",
    DAY4_POS: "141",
    DAY5_POS: "180",
  },
  976: {
    Index: "976",
    Init: "PJR",
    NAME: "Holden",
    full_NAME: "PJR Holden",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "6",
    Day_2_BONUS: "5",
    Day_2_Total: "11",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "24",
    GUNSCORE_DAY1: "171.024",
    GUNSCORE_DAY2: "147.019",
    GUNSCORE_DAY3: "165.019",
    GUNSCORE_DAY4: "168.022",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "755.097",
    DAILY_T: "73.011",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.007",
    CORP: "50.007",
    TIMES: "50.007",
    POW: "70.009",
    DOFC: "45.003",
    LOVELL: "44.003",
    WIMB: "49.007",
    STG: "75.012",
    KINGS: "104.013",
    DAY1_POS: "137",
    DAY2_POS: "24",
    DAY3_POS: "268",
    DAY4_POS: "142",
    DAY5_POS: "41",
  },
  977: {
    Index: "977",
    Init: "SD",
    NAME: "Glen",
    full_NAME: "SD Glen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.018",
    GUNSCORE_DAY2: "139.009",
    GUNSCORE_DAY3: "167.016",
    GUNSCORE_DAY4: "165.011",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "740.065",
    DAILY_T: "73.009",
    DONEGALL: "44.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "44.003",
    CORP: "45.002",
    TIMES: "47.004",
    POW: "71.006",
    DOFC: "49.006",
    LOVELL: "47.002",
    WIMB: "46.001",
    STG: "72.008",
    KINGS: "103.011",
    DAY1_POS: "373",
    DAY2_POS: "346",
    DAY3_POS: "190",
    DAY4_POS: "304",
    DAY5_POS: "124",
  },
  981: {
    Index: "981",
    Init: "IDC",
    NAME: "Brechin",
    full_NAME: "IDC Brechin",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.013",
    GUNSCORE_DAY2: "139.011",
    GUNSCORE_DAY3: "164.013",
    GUNSCORE_DAY4: "167.017",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "735.065",
    DAILY_T: "72.005",
    DONEGALL: "46.003",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.005",
    CORP: "42.002",
    TIMES: "46.003",
    POW: "70.005",
    DOFC: "48.005",
    LOVELL: "47.003",
    WIMB: "49.007",
    STG: "71.007",
    KINGS: "101.011",
    DAY1_POS: "448",
    DAY2_POS: "333",
    DAY3_POS: "327",
    DAY4_POS: "194",
    DAY5_POS: "254",
  },
  984: {
    Index: "984",
    Init: "SP",
    NAME: "Harding",
    full_NAME: "SP Harding",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "4",
    Day_3_BONUS: "0",
    Day_3_Total: "4",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "9",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "144.016",
    GUNSCORE_DAY3: "171.022",
    GUNSCORE_DAY4: "168.017",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "755.086",
    DAILY_T: "74.009",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "49.005",
    CORP: "45.006",
    TIMES: "49.007",
    POW: "74.012",
    DOFC: "48.003",
    LOVELL: "46.005",
    WIMB: "49.004",
    STG: "73.008",
    KINGS: "101.01",
    DAY1_POS: "149",
    DAY2_POS: "111",
    DAY3_POS: "33",
    DAY4_POS: "163",
    DAY5_POS: "269",
  },
  985: {
    Index: "985",
    Init: "PM",
    NAME: "Patel",
    full_NAME: "PM Patel",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "35",
    Day_2_BONUS: "15",
    Day_2_Total: "50",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "21",
    FINAL_TOTAL: "91",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "150.018",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "168.021",
    GUNSCORE_DAY5: "102.015",
    GUNSCORE_GA: "764.096",
    DAILY_T: "74.011",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "50.009",
    CORP: "50.003",
    TIMES: "49.009",
    POW: "72.006",
    DOFC: "49.004",
    LOVELL: "44.001",
    WIMB: "49.007",
    STG: "75.013",
    KINGS: "102.015",
    DAY1_POS: "29",
    DAY2_POS: "2",
    DAY3_POS: "65",
    DAY4_POS: "145",
    DAY5_POS: "165",
  },
  992: {
    Index: "992",
    Init: "SS",
    NAME: "Horner",
    full_NAME: "SS Horner",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.021",
    GUNSCORE_DAY2: "143.01",
    GUNSCORE_DAY3: "159.011",
    GUNSCORE_DAY4: "167.02",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "738.072",
    DAILY_T: "73.008",
    DONEGALL: "50.008",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "47.002",
    CORP: "47.002",
    TIMES: "45.001",
    POW: "70.008",
    DOFC: "44.002",
    LOVELL: "47.004",
    WIMB: "48.006",
    STG: "72.01",
    KINGS: "99.01",
    DAY1_POS: "204",
    DAY2_POS: "183",
    DAY3_POS: "467",
    DAY4_POS: "186",
    DAY5_POS: "388",
  },
  993: {
    Index: "993",
    Init: "RL",
    NAME: "Eayrs",
    full_NAME: "RL Eayrs",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "4",
    Day_3_BONUS: "5",
    Day_3_Total: "9",
    Day_4: "3",
    Day_4_BONUS: "0",
    Day_4_Total: "3",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "17",
    GUNSCORE_DAY1: "173.019",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "171.021",
    GUNSCORE_DAY4: "172.017",
    GUNSCORE_DAY5: "99.015",
    GUNSCORE_GA: "758.086",
    DAILY_T: "74.01",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "49.006",
    CORP: "47.002",
    TIMES: "50.008",
    POW: "73.007",
    DOFC: "48.006",
    LOVELL: "49.005",
    WIMB: "49.003",
    STG: "74.009",
    KINGS: "99.015",
    DAY1_POS: "77",
    DAY2_POS: "160",
    DAY3_POS: "35",
    DAY4_POS: "38",
    DAY5_POS: "378",
  },
  995: {
    Index: "995",
    Init: "JA",
    NAME: "Bullock",
    full_NAME: "JA Bullock",
    Day_1: "2",
    Day_1_BONUS: "10",
    Day_1_Total: "12",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "5",
    FINAL_TOTAL: "27",
    GUNSCORE_DAY1: "174.019",
    GUNSCORE_DAY2: "145.015",
    GUNSCORE_DAY3: "168.019",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "760.087",
    DAILY_T: "75.008",
    DONEGALL: "50.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.003",
    CORP: "46.004",
    TIMES: "48.004",
    POW: "73.009",
    DOFC: "47.006",
    LOVELL: "46.002",
    WIMB: "49.006",
    STG: "75.012",
    KINGS: "103.014",
    DAY1_POS: "44",
    DAY2_POS: "81",
    DAY3_POS: "134",
    DAY4_POS: "66",
    DAY5_POS: "90",
  },
  999: {
    Index: "999",
    Init: "AR",
    NAME: "McLeod",
    full_NAME: "AR McLeod",
    Day_1: "8",
    Day_1_BONUS: "10",
    Day_1_Total: "18",
    Day_2: "11",
    Day_2_BONUS: "10",
    Day_2_Total: "21",
    Day_3: "20",
    Day_3_BONUS: "10",
    Day_3_Total: "30",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "24",
    FINAL_TOTAL: "93",
    GUNSCORE_DAY1: "174.028",
    GUNSCORE_DAY2: "148.019",
    GUNSCORE_DAY3: "173.023",
    GUNSCORE_DAY4: "166.02",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "764.103",
    DAILY_T: "75.012",
    DONEGALL: "50.007",
    CONAN_DOYLE: "49.009",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "50.006",
    CORP: "48.008",
    TIMES: "50.005",
    POW: "73.011",
    DOFC: "50.007",
    LOVELL: "45.003",
    WIMB: "49.009",
    STG: "72.008",
    KINGS: "103.013",
    DAY1_POS: "16",
    DAY2_POS: "11",
    DAY3_POS: "5",
    DAY4_POS: "219",
    DAY5_POS: "97",
  },
  1001: {
    Index: "1001",
    Init: "AMG",
    NAME: "Ogilvie",
    full_NAME: "AMG Ogilvie",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "4",
    Day_2_BONUS: "5",
    Day_2_Total: "9",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "14",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "147.014",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "751.075",
    DAILY_T: "74.008",
    DONEGALL: "45.004",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "49.005",
    CORP: "48.002",
    TIMES: "49.008",
    POW: "73.008",
    DOFC: "46.002",
    LOVELL: "46.002",
    WIMB: "49.004",
    STG: "70.007",
    KINGS: "102.01",
    DAY1_POS: "249",
    DAY2_POS: "36",
    DAY3_POS: "136",
    DAY4_POS: "294",
    DAY5_POS: "202",
  },
  1010: {
    Index: "1010",
    Init: "SJV",
    NAME: "Osmond",
    full_NAME: "SJV Osmond",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "171.022",
    GUNSCORE_DAY2: "144.018",
    GUNSCORE_DAY3: "170.016",
    GUNSCORE_DAY4: "168.02",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "757.089",
    DAILY_T: "73.008",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "50.007",
    CORP: "44.005",
    TIMES: "48.004",
    POW: "73.007",
    DOFC: "49.005",
    LOVELL: "47.006",
    WIMB: "49.007",
    STG: "72.007",
    KINGS: "104.013",
    DAY1_POS: "145",
    DAY2_POS: "104",
    DAY3_POS: "80",
    DAY4_POS: "148",
    DAY5_POS: "41",
  },
  1014: {
    Index: "1014",
    Init: "BA",
    NAME: "Young",
    full_NAME: "BA Young",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "167.016",
    GUNSCORE_DAY5: "101.014",
    GUNSCORE_GA: "744.075",
    DAILY_T: "72.006",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "45.005",
    CORP: "46.004",
    TIMES: "47.003",
    POW: "71.008",
    DOFC: "50.004",
    LOVELL: "47.004",
    WIMB: "47.005",
    STG: "73.007",
    KINGS: "101.014",
    DAY1_POS: "217",
    DAY2_POS: "367",
    DAY3_POS: "154",
    DAY4_POS: "197",
    DAY5_POS: "241",
  },
  1017: {
    Index: "1017",
    Init: "AG",
    NAME: "McLean",
    full_NAME: "AG McLean",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.015",
    GUNSCORE_DAY2: "145.016",
    GUNSCORE_DAY3: "162.013",
    GUNSCORE_DAY4: "169.016",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "750.071",
    DAILY_T: "73.004",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.005",
    CORP: "47.004",
    TIMES: "46.003",
    POW: "69.005",
    DOFC: "47.005",
    LOVELL: "46.002",
    WIMB: "49.006",
    STG: "74.008",
    KINGS: "103.011",
    DAY1_POS: "191",
    DAY2_POS: "75",
    DAY3_POS: "377",
    DAY4_POS: "124",
    DAY5_POS: "124",
  },
  1021: {
    Index: "1021",
    Init: "MCJ",
    NAME: "Button",
    full_NAME: "MCJ Button",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "18",
    Day_4_BONUS: "10",
    Day_4_Total: "28",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "11",
    FINAL_TOTAL: "49",
    GUNSCORE_DAY1: "171.022",
    GUNSCORE_DAY2: "145.017",
    GUNSCORE_DAY3: "168.02",
    GUNSCORE_DAY4: "174.023",
    GUNSCORE_DAY5: "103.008",
    GUNSCORE_GA: "761.09",
    DAILY_T: "74.01",
    DONEGALL: "48.007",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "49.007",
    CORP: "46.005",
    TIMES: "50.006",
    POW: "72.008",
    DOFC: "46.006",
    LOVELL: "49.007",
    WIMB: "50.003",
    STG: "75.013",
    KINGS: "103.008",
    DAY1_POS: "145",
    DAY2_POS: "70",
    DAY3_POS: "129",
    DAY4_POS: "6",
    DAY5_POS: "155",
  },
  1024: {
    Index: "1024",
    Init: "R",
    NAME: "Birtwistle",
    full_NAME: "R Birtwistle",
    Day_1: "8",
    Day_1_BONUS: "10",
    Day_1_Total: "18",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "11",
    Day_3_BONUS: "5",
    Day_3_Total: "16",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "39",
    GUNSCORE_DAY1: "174.027",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "173.017",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "760.086",
    DAILY_T: "74.011",
    DONEGALL: "50.009",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "49.006",
    CORP: "45.003",
    TIMES: "50.008",
    POW: "74.006",
    DOFC: "49.003",
    LOVELL: "45.002",
    WIMB: "50.005",
    STG: "74.008",
    KINGS: "102.012",
    DAY1_POS: "17",
    DAY2_POS: "196",
    DAY3_POS: "11",
    DAY4_POS: "127",
    DAY5_POS: "180",
  },
  1035: {
    Index: "1035",
    Init: "NS",
    NAME: "Roberts",
    full_NAME: "NS Roberts",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.019",
    GUNSCORE_DAY2: "146.012",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "749.073",
    DAILY_T: "73.007",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "49.006",
    CORP: "47.002",
    TIMES: "49.005",
    POW: "69.006",
    DOFC: "48.005",
    LOVELL: "45.002",
    WIMB: "46.003",
    STG: "74.009",
    KINGS: "104.012",
    DAY1_POS: "296",
    DAY2_POS: "62",
    DAY3_POS: "231",
    DAY4_POS: "287",
    DAY5_POS: "53",
  },
  1045: {
    Index: "1045",
    Init: "D",
    NAME: "Calvert",
    full_NAME: "D Calvert",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "20",
    Day_2_BONUS: "10",
    Day_2_Total: "30",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "35",
    Day_4_BONUS: "10",
    Day_4_Total: "45",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "23",
    FINAL_TOTAL: "113",
    GUNSCORE_DAY1: "173.022",
    GUNSCORE_DAY2: "149.021",
    GUNSCORE_DAY3: "165.018",
    GUNSCORE_DAY4: "174.026",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "764.099",
    DAILY_T: "73.006",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "50.006",
    CORP: "49.006",
    TIMES: "50.005",
    POW: "68.006",
    DOFC: "47.007",
    LOVELL: "49.007",
    WIMB: "50.008",
    STG: "75.011",
    KINGS: "103.012",
    DAY1_POS: "59",
    DAY2_POS: "5",
    DAY3_POS: "271",
    DAY4_POS: "2",
    DAY5_POS: "112",
  },
  1049: {
    Index: "1049",
    Init: "JS",
    NAME: "Rennie",
    full_NAME: "JS Rennie",
    Day_1: "3",
    Day_1_BONUS: "10",
    Day_1_Total: "13",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "174.021",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "103.008",
    GUNSCORE_GA: "751.073",
    DAILY_T: "74.007",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.006",
    CORP: "46.002",
    TIMES: "49.007",
    POW: "68.006",
    DOFC: "48.003",
    LOVELL: "46.004",
    WIMB: "50.006",
    STG: "70.004",
    KINGS: "103.008",
    DAY1_POS: "38",
    DAY2_POS: "160",
    DAY3_POS: "277",
    DAY4_POS: "240",
    DAY5_POS: "155",
  },
  1054: {
    Index: "1054",
    Init: "PA",
    NAME: "Hatfield",
    full_NAME: "PA Hatfield",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "2",
    Day_3_BONUS: "5",
    Day_3_Total: "7",
    Day_4: "2",
    Day_4_BONUS: "10",
    Day_4_Total: "12",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "34",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "143.01",
    GUNSCORE_DAY3: "171.016",
    GUNSCORE_DAY4: "171.022",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "760.078",
    DAILY_T: "74.006",
    DONEGALL: "48.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.003",
    ALEXANDRA: "50.005",
    CORP: "43.002",
    TIMES: "50.005",
    POW: "72.005",
    DOFC: "49.006",
    LOVELL: "46.005",
    WIMB: "50.006",
    STG: "75.011",
    KINGS: "103.01",
    DAY1_POS: "107",
    DAY2_POS: "183",
    DAY3_POS: "45",
    DAY4_POS: "46",
    DAY5_POS: "138",
  },
  1080: {
    Index: "1080",
    Init: "G",
    NAME: "Childs",
    full_NAME: "G Childs",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "162.015",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "165.015",
    GUNSCORE_DAY4: "166.01",
    GUNSCORE_DAY5: "104.009",
    GUNSCORE_GA: "737.062",
    DAILY_T: "73.01",
    DONEGALL: "44.001",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "50.005",
    CORP: "41.001",
    TIMES: "47.007",
    POW: "71.005",
    DOFC: "47.003",
    LOVELL: "46.002",
    WIMB: "48.001",
    STG: "72.007",
    KINGS: "104.009",
    DAY1_POS: "493",
    DAY2_POS: "288",
    DAY3_POS: "284",
    DAY4_POS: "266",
    DAY5_POS: "80",
  },
  1087: {
    Index: "1087",
    Init: "GL",
    NAME: "Clarke",
    full_NAME: "GL Clarke",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.018",
    GUNSCORE_DAY2: "131.006",
    GUNSCORE_DAY3: "158.012",
    GUNSCORE_DAY4: "167.012",
    GUNSCORE_DAY5: "97.008",
    GUNSCORE_GA: "719.056",
    DAILY_T: "74.011",
    DONEGALL: "45.004",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.001",
    CORP: "36",
    TIMES: "45.001",
    POW: "69.007",
    DOFC: "44.004",
    LOVELL: "44.002",
    WIMB: "49.003",
    STG: "74.007",
    KINGS: "97.008",
    DAY1_POS: "373",
    DAY2_POS: "530",
    DAY3_POS: "486",
    DAY4_POS: "214",
    DAY5_POS: "477",
  },
  1101: {
    Index: "1101",
    Init: "PAE",
    NAME: "Charlton",
    full_NAME: "PAE Charlton",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "142.011",
    GUNSCORE_DAY3: "166.018",
    GUNSCORE_DAY4: "168.022",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "749.082",
    DAILY_T: "74.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.004",
    CORP: "45.002",
    TIMES: "47.006",
    POW: "70.007",
    DOFC: "49.005",
    LOVELL: "46.006",
    WIMB: "49.006",
    STG: "73.01",
    KINGS: "100.011",
    DAY1_POS: "69",
    DAY2_POS: "225",
    DAY3_POS: "220",
    DAY4_POS: "142",
    DAY5_POS: "323",
  },
  1106: {
    Index: "1106",
    Init: "RM",
    NAME: "Culverwell",
    full_NAME: "RM Culverwell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.009",
    GUNSCORE_DAY2: "138.011",
    GUNSCORE_DAY3: "161.011",
    GUNSCORE_DAY4: "161.009",
    GUNSCORE_DAY5: "95.007",
    GUNSCORE_GA: "723.047",
    DAILY_T: "71.003",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "46.003",
    CORP: "45.003",
    TIMES: "46.002",
    POW: "66.004",
    DOFC: "49.005",
    LOVELL: "46.002",
    WIMB: "46.002",
    STG: "69.005",
    KINGS: "95.007",
    DAY1_POS: "330",
    DAY2_POS: "374",
    DAY3_POS: "425",
    DAY4_POS: "421",
    DAY5_POS: "541",
  },
  1110: {
    Index: "1110",
    Init: "RJS",
    NAME: "Curtis",
    full_NAME: "RJS Curtis",
    Day_1: "3",
    Day_1_BONUS: "10",
    Day_1_Total: "13",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "7",
    Day_3_BONUS: "5",
    Day_3_Total: "12",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "11",
    Day_5_BONUS: "5",
    Day_5_Total: "16",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "46",
    GUNSCORE_DAY1: "174.021",
    GUNSCORE_DAY2: "140.014",
    GUNSCORE_DAY3: "172.02",
    GUNSCORE_DAY4: "169.012",
    GUNSCORE_DAY5: "105.014",
    GUNSCORE_GA: "760.081",
    DAILY_T: "75.01",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "47.005",
    CORP: "43.002",
    TIMES: "49.004",
    POW: "73.01",
    DOFC: "50.006",
    LOVELL: "48.003",
    WIMB: "49.005",
    STG: "72.004",
    KINGS: "105.014",
    DAY1_POS: "38",
    DAY2_POS: "283",
    DAY3_POS: "19",
    DAY4_POS: "138",
    DAY5_POS: "12",
  },
  1114: {
    Index: "1114",
    Init: "RI",
    NAME: "Coventry",
    full_NAME: "RI Coventry",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.023",
    GUNSCORE_DAY2: "139.011",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "156.009",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "736.072",
    DAILY_T: "73.012",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "49.007",
    CORP: "42.001",
    TIMES: "50.005",
    POW: "72.009",
    DOFC: "48.006",
    LOVELL: "43.004",
    WIMB: "47.004",
    STG: "66.001",
    KINGS: "100.009",
    DAY1_POS: "140",
    DAY2_POS: "333",
    DAY3_POS: "58",
    DAY4_POS: "508",
    DAY5_POS: "346",
  },
  1116: {
    Index: "1116",
    Init: "GA",
    NAME: "Cann",
    full_NAME: "GA Cann",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "160.007",
    GUNSCORE_DAY4: "163.01",
    GUNSCORE_DAY5: "100.007",
    GUNSCORE_GA: "592.04",
    DAILY_T: "72.007",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "45.003",
    POW: "67",
    DOFC: "48.004",
    LOVELL: "47.002",
    WIMB: "44.001",
    STG: "72.007",
    KINGS: "100.007",
    DAY1_POS: "271",
    DAY2_POS: "641",
    DAY3_POS: "454",
    DAY4_POS: "375",
    DAY5_POS: "366",
  },
  1119: {
    Index: "1119",
    Init: "RJSG",
    NAME: "Clark",
    full_NAME: "RJSG Clark",
    Day_1: "7",
    Day_1_BONUS: "10",
    Day_1_Total: "17",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "5",
    Day_3_BONUS: "5",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "27",
    GUNSCORE_DAY1: "174.026",
    GUNSCORE_DAY2: "144.011",
    GUNSCORE_DAY3: "172.016",
    GUNSCORE_DAY4: "164.012",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "757.075",
    DAILY_T: "74.011",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.005",
    CORP: "46.001",
    TIMES: "50.005",
    POW: "74.007",
    DOFC: "48.004",
    LOVELL: "44.002",
    WIMB: "46.003",
    STG: "74.007",
    KINGS: "103.01",
    DAY1_POS: "21",
    DAY2_POS: "140",
    DAY3_POS: "27",
    DAY4_POS: "329",
    DAY5_POS: "138",
  },
  1124: {
    Index: "1124",
    Init: "DR",
    NAME: "Armstrong",
    full_NAME: "DR Armstrong",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "5",
    Day_4_BONUS: "0",
    Day_4_Total: "5",
    Day_5: "18",
    Day_5_BONUS: "5",
    Day_5_Total: "23",
    GRAND_MULTIPLIER: "6",
    FINAL_TOTAL: "39",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "167.018",
    GUNSCORE_DAY4: "172.022",
    GUNSCORE_DAY5: "105.015",
    GUNSCORE_GA: "760.09",
    DAILY_T: "73.007",
    DONEGALL: "49.008",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.006",
    CORP: "47.003",
    TIMES: "49.007",
    POW: "70.007",
    DOFC: "48.004",
    LOVELL: "49.004",
    WIMB: "49.008",
    STG: "74.01",
    KINGS: "105.015",
    DAY1_POS: "91",
    DAY2_POS: "127",
    DAY3_POS: "181",
    DAY4_POS: "29",
    DAY5_POS: "6",
  },
  1137: {
    Index: "1137",
    Init: "JRD",
    NAME: "Clegg",
    full_NAME: "JRD Clegg",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.015",
    GUNSCORE_DAY2: "135.011",
    GUNSCORE_DAY3: "148.007",
    GUNSCORE_DAY4: "158.01",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "703.05",
    DAILY_T: "70.007",
    DONEGALL: "46.004",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "45.005",
    ALEXANDRA: "48.005",
    CORP: "42.001",
    TIMES: "44.004",
    POW: "65.002",
    DOFC: "39.001",
    LOVELL: "44.004",
    WIMB: "47.003",
    STG: "67.003",
    KINGS: "99.007",
    DAY1_POS: "468",
    DAY2_POS: "439",
    DAY3_POS: "591",
    DAY4_POS: "466",
    DAY5_POS: "413",
  },
  1175: {
    Index: "1175",
    Init: "ER",
    NAME: "Welford",
    full_NAME: "ER Welford",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "4",
    Day_4_BONUS: "5",
    Day_4_Total: "9",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "19",
    GUNSCORE_DAY1: "171.018",
    GUNSCORE_DAY2: "144.02",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "172.02",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "756.085",
    DAILY_T: "74.01",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "50.008",
    CORP: "44.003",
    TIMES: "47.006",
    POW: "73.006",
    DOFC: "48.004",
    LOVELL: "48.004",
    WIMB: "49.006",
    STG: "75.01",
    KINGS: "101.011",
    DAY1_POS: "172",
    DAY2_POS: "102",
    DAY3_POS: "147",
    DAY4_POS: "32",
    DAY5_POS: "254",
  },
  1188: {
    Index: "1188",
    Init: "AM",
    NAME: "Bell",
    full_NAME: "AM Bell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.022",
    GUNSCORE_DAY2: "143.018",
    GUNSCORE_DAY3: "164.017",
    GUNSCORE_DAY4: "167.013",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "745.082",
    DAILY_T: "74.011",
    DONEGALL: "48.006",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "49.009",
    ALEXANDRA: "48.004",
    CORP: "46.005",
    TIMES: "48.008",
    POW: "70.005",
    DOFC: "46.004",
    LOVELL: "47.004",
    WIMB: "49.003",
    STG: "71.006",
    KINGS: "102.012",
    DAY1_POS: "246",
    DAY2_POS: "150",
    DAY3_POS: "306",
    DAY4_POS: "208",
    DAY5_POS: "180",
  },
  1195: {
    Index: "1195",
    Init: "A",
    NAME: "Betts",
    full_NAME: "A Betts",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "92.009",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "159.012",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "687.062",
    DAILY_T: "71.003",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "44.005",
    ALEXANDRA: "48.004",
    CORP: "0",
    TIMES: "49.005",
    POW: "70.005",
    DOFC: "47.005",
    LOVELL: "43.002",
    WIMB: "46.001",
    STG: "70.009",
    KINGS: "101.01",
    DAY1_POS: "271",
    DAY2_POS: "633",
    DAY3_POS: "240",
    DAY4_POS: "450",
    DAY5_POS: "269",
  },
  1241: {
    Index: "1241",
    Init: "J",
    NAME: "Corbett",
    full_NAME: "J Corbett",
    Day_1: "2",
    Day_1_BONUS: "10",
    Day_1_Total: "12",
    Day_2: "50",
    Day_2_BONUS: "15",
    Day_2_Total: "65",
    Day_3: "16",
    Day_3_BONUS: "10",
    Day_3_Total: "26",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "61",
    FINAL_TOTAL: "168",
    GUNSCORE_DAY1: "174.018",
    GUNSCORE_DAY2: "150.021",
    GUNSCORE_DAY3: "173.021",
    GUNSCORE_DAY4: "168.02",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "769.094",
    DAILY_T: "75.009",
    DONEGALL: "49.004",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "50.008",
    CORP: "50.004",
    TIMES: "50.006",
    POW: "75.008",
    DOFC: "48.007",
    LOVELL: "45.002",
    WIMB: "49.007",
    STG: "74.011",
    KINGS: "104.014",
    DAY1_POS: "45",
    DAY2_POS: "1",
    DAY3_POS: "7",
    DAY4_POS: "148",
    DAY5_POS: "31",
  },
  1261: {
    Index: "1261",
    Init: "S",
    NAME: "Douglas",
    full_NAME: "S Douglas",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.005",
    GUNSCORE_DAY2: "127.002",
    GUNSCORE_DAY3: "149.009",
    GUNSCORE_DAY4: "136.003",
    GUNSCORE_DAY5: "78.001",
    GUNSCORE_GA: "644.02",
    DAILY_T: "66.001",
    DONEGALL: "44.002",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "40",
    ALEXANDRA: "44.002",
    CORP: "43",
    TIMES: "38",
    POW: "66.005",
    DOFC: "45.004",
    LOVELL: "40.002",
    WIMB: "42.001",
    STG: "54",
    KINGS: "78.001",
    DAY1_POS: "586",
    DAY2_POS: "583",
    DAY3_POS: "582",
    DAY4_POS: "616",
    DAY5_POS: "629",
  },
  1291: {
    Index: "1291",
    Init: "AW",
    NAME: "Gill",
    full_NAME: "AW Gill",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "6",
    Day_3_BONUS: "5",
    Day_3_Total: "11",
    Day_4: "4",
    Day_4_BONUS: "10",
    Day_4_Total: "14",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "30",
    GUNSCORE_DAY1: "167.014",
    GUNSCORE_DAY2: "137.01",
    GUNSCORE_DAY3: "172.018",
    GUNSCORE_DAY4: "172.02",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "751.075",
    DAILY_T: "68.003",
    DONEGALL: "50.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "44.003",
    ALEXANDRA: "49.005",
    CORP: "44.002",
    TIMES: "49.005",
    POW: "73.007",
    DOFC: "50.006",
    LOVELL: "47.002",
    WIMB: "50.009",
    STG: "75.009",
    KINGS: "103.013",
    DAY1_POS: "358",
    DAY2_POS: "404",
    DAY3_POS: "24",
    DAY4_POS: "32",
    DAY5_POS: "97",
  },
  1301: {
    Index: "1301",
    Init: "GE",
    NAME: "Grosskreutz",
    full_NAME: "GE Grosskreutz",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.012",
    GUNSCORE_DAY2: "144.01",
    GUNSCORE_DAY3: "167.019",
    GUNSCORE_DAY4: "168.018",
    GUNSCORE_DAY5: "101.005",
    GUNSCORE_GA: "748.064",
    DAILY_T: "72.004",
    DONEGALL: "48.004",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "49.004",
    CORP: "47.003",
    TIMES: "49.004",
    POW: "69.008",
    DOFC: "49.007",
    LOVELL: "47.004",
    WIMB: "49.007",
    STG: "72.007",
    KINGS: "101.005",
    DAY1_POS: "325",
    DAY2_POS: "144",
    DAY3_POS: "180",
    DAY4_POS: "158",
    DAY5_POS: "318",
  },
  1387: {
    Index: "1387",
    Init: "F",
    NAME: "Mohideen",
    full_NAME: "F Mohideen",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "146.01",
    GUNSCORE_DAY3: "170.012",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "760.069",
    DAILY_T: "73.007",
    DONEGALL: "50.006",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "50.006",
    CORP: "47.001",
    TIMES: "50.002",
    POW: "72.008",
    DOFC: "48.002",
    LOVELL: "47.002",
    WIMB: "49.004",
    STG: "73.009",
    KINGS: "104.013",
    DAY1_POS: "165",
    DAY2_POS: "64",
    DAY3_POS: "87",
    DAY4_POS: "127",
    DAY5_POS: "41",
  },
  1403: {
    Index: "1403",
    Init: "DE",
    NAME: "Negus",
    full_NAME: "DE Negus",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "164.017",
    GUNSCORE_DAY4: "169.02",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "753.084",
    DAILY_T: "74.013",
    DONEGALL: "49.004",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "49.003",
    CORP: "45.002",
    TIMES: "49.006",
    POW: "68.007",
    DOFC: "47.004",
    LOVELL: "47.003",
    WIMB: "50.007",
    STG: "72.01",
    KINGS: "104.012",
    DAY1_POS: "100",
    DAY2_POS: "120",
    DAY3_POS: "306",
    DAY4_POS: "100",
    DAY5_POS: "53",
  },
  1404: {
    Index: "1404",
    Init: "SM",
    NAME: "Negus",
    full_NAME: "SM Negus",
    Day_1: "35",
    Day_1_BONUS: "15",
    Day_1_Total: "50",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "8",
    Day_4_BONUS: "5",
    Day_4_Total: "13",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "73",
    GUNSCORE_DAY1: "175.027",
    GUNSCORE_DAY2: "143.018",
    GUNSCORE_DAY3: "162.021",
    GUNSCORE_DAY4: "173.022",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "756.099",
    DAILY_T: "75.011",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "50.007",
    CORP: "43.002",
    TIMES: "49.008",
    POW: "71.007",
    DOFC: "42.006",
    LOVELL: "49.004",
    WIMB: "50.008",
    STG: "74.01",
    KINGS: "103.011",
    DAY1_POS: "2",
    DAY2_POS: "150",
    DAY3_POS: "365",
    DAY4_POS: "16",
    DAY5_POS: "124",
  },
  1423: {
    Index: "1423",
    Init: "M",
    NAME: "Perez",
    full_NAME: "M Perez",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "0",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  1530: {
    Index: "1530",
    Init: "HM",
    NAME: "Wong Shui",
    full_NAME: "HM Wong Shui",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.013",
    GUNSCORE_DAY2: "134.005",
    GUNSCORE_DAY3: "166.014",
    GUNSCORE_DAY4: "165.017",
    GUNSCORE_DAY5: "95.007",
    GUNSCORE_GA: "729.056",
    DAILY_T: "73.008",
    DONEGALL: "49.004",
    CONAN_DOYLE: "47.001",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "45.001",
    CORP: "41",
    TIMES: "47.004",
    POW: "71.006",
    DOFC: "48.004",
    LOVELL: "46.004",
    WIMB: "47.005",
    STG: "72.008",
    KINGS: "95.007",
    DAY1_POS: "286",
    DAY2_POS: "479",
    DAY3_POS: "247",
    DAY4_POS: "274",
    DAY5_POS: "541",
  },
  1552: {
    Index: "1552",
    Init: "JRJ",
    NAME: "Hinde",
    full_NAME: "JRJ Hinde",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "144.011",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "104.009",
    GUNSCORE_GA: "750.067",
    DAILY_T: "74.007",
    DONEGALL: "48.004",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.005",
    CORP: "46.002",
    TIMES: "48.006",
    POW: "72.007",
    DOFC: "45.003",
    LOVELL: "46.004",
    WIMB: "49.004",
    STG: "71.007",
    KINGS: "104.009",
    DAY1_POS: "183",
    DAY2_POS: "140",
    DAY3_POS: "277",
    DAY4_POS: "230",
    DAY5_POS: "80",
  },
  1561: {
    Index: "1561",
    Init: "JC",
    NAME: "Jobling",
    full_NAME: "JC Jobling",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "39.001",
    GUNSCORE_DAY4: "64.001",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "103.002",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "39.001",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "64.001",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "642",
    DAY4_POS: "634",
    DAY5_POS: "638",
  },
  1564: {
    Index: "1564",
    Init: "AM",
    NAME: "Jory",
    full_NAME: "AM Jory",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "10",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "146.016",
    GUNSCORE_DAY3: "170.018",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "761.084",
    DAILY_T: "75.011",
    DONEGALL: "48.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "49.004",
    CORP: "48.004",
    TIMES: "49.006",
    POW: "74.006",
    DOFC: "47.006",
    LOVELL: "47.006",
    WIMB: "48.005",
    STG: "74.007",
    KINGS: "104.012",
    DAY1_POS: "107",
    DAY2_POS: "53",
    DAY3_POS: "72",
    DAY4_POS: "110",
    DAY5_POS: "53",
  },
  1567: {
    Index: "1567",
    Init: "CST",
    NAME: "Lehmann",
    full_NAME: "CST Lehmann",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.015",
    GUNSCORE_DAY2: "136.012",
    GUNSCORE_DAY3: "161.015",
    GUNSCORE_DAY4: "160.012",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "726.061",
    DAILY_T: "73.008",
    DONEGALL: "47.003",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "47.005",
    CORP: "44.004",
    TIMES: "48.005",
    POW: "71.006",
    DOFC: "42.004",
    LOVELL: "43.003",
    WIMB: "49.005",
    STG: "68.004",
    KINGS: "99.007",
    DAY1_POS: "236",
    DAY2_POS: "418",
    DAY3_POS: "402",
    DAY4_POS: "434",
    DAY5_POS: "413",
  },
  1575: {
    Index: "1575",
    Init: "NP",
    NAME: "Moxon",
    full_NAME: "NP Moxon",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "135.01",
    GUNSCORE_DAY3: "162.011",
    GUNSCORE_DAY4: "168.017",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "737.07",
    DAILY_T: "73.009",
    DONEGALL: "48.007",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "46.004",
    CORP: "41.003",
    TIMES: "50.003",
    POW: "69.006",
    DOFC: "43.002",
    LOVELL: "48.004",
    WIMB: "49.006",
    STG: "71.007",
    KINGS: "103.012",
    DAY1_POS: "249",
    DAY2_POS: "443",
    DAY3_POS: "388",
    DAY4_POS: "163",
    DAY5_POS: "112",
  },
  1601: {
    Index: "1601",
    Init: "A",
    NAME: "Sainter",
    full_NAME: "A Sainter",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "146.007",
    GUNSCORE_DAY2: "111.003",
    GUNSCORE_DAY3: "139.006",
    GUNSCORE_DAY4: "143.005",
    GUNSCORE_DAY5: "89.002",
    GUNSCORE_GA: "628.023",
    DAILY_T: "67.005",
    DONEGALL: "38",
    CONAN_DOYLE: "41.002",
    DAILY_MAIL: "42.001",
    ALEXANDRA: "38.001",
    CORP: "31.001",
    TIMES: "36.002",
    POW: "59.003",
    DOFC: "44.001",
    LOVELL: "48.003",
    WIMB: "36.001",
    STG: "59.001",
    KINGS: "89.002",
    DAY1_POS: "617",
    DAY2_POS: "627",
    DAY3_POS: "620",
    DAY4_POS: "598",
    DAY5_POS: "605",
  },
  1602: {
    Index: "1602",
    Init: "C",
    NAME: "Sykes",
    full_NAME: "C Sykes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.018",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "744.072",
    DAILY_T: "72.006",
    DONEGALL: "48.005",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.002",
    CORP: "47.006",
    TIMES: "48.005",
    POW: "72.006",
    DOFC: "44.004",
    LOVELL: "46.004",
    WIMB: "46.001",
    STG: "74.009",
    KINGS: "101.011",
    DAY1_POS: "261",
    DAY2_POS: "120",
    DAY3_POS: "314",
    DAY4_POS: "240",
    DAY5_POS: "254",
  },
  1607: {
    Index: "1607",
    Init: "PJ",
    NAME: "Temperley",
    full_NAME: "PJ Temperley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.022",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "166.017",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "739.076",
    DAILY_T: "69.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.008",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "48.006",
    CORP: "42.002",
    TIMES: "47.006",
    POW: "71.006",
    DOFC: "48.005",
    LOVELL: "48.005",
    WIMB: "46.005",
    STG: "72.005",
    KINGS: "101.009",
    DAY1_POS: "368",
    DAY2_POS: "288",
    DAY3_POS: "225",
    DAY4_POS: "230",
    DAY5_POS: "286",
  },
  1625: {
    Index: "1625",
    Init: "JRW",
    NAME: "Postle",
    full_NAME: "JRW Postle",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "11",
    Day_4_BONUS: "10",
    Day_4_Total: "21",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "31",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "145.019",
    GUNSCORE_DAY3: "164.016",
    GUNSCORE_DAY4: "173.024",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "756.093",
    DAILY_T: "73.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.006",
    CORP: "47.006",
    TIMES: "47.004",
    POW: "72.008",
    DOFC: "45.004",
    LOVELL: "48.006",
    WIMB: "50.007",
    STG: "75.011",
    KINGS: "102.013",
    DAY1_POS: "100",
    DAY2_POS: "66",
    DAY3_POS: "311",
    DAY4_POS: "12",
    DAY5_POS: "173",
  },
  1627: {
    Index: "1627",
    Init: "TF",
    NAME: "Kurn",
    full_NAME: "TF Kurn",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.008",
    GUNSCORE_DAY2: "127.006",
    GUNSCORE_DAY3: "155.011",
    GUNSCORE_DAY4: "163.011",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "700.042",
    DAILY_T: "67.004",
    DONEGALL: "49.004",
    CONAN_DOYLE: "42",
    DAILY_MAIL: "43.002",
    ALEXANDRA: "44.004",
    CORP: "40",
    TIMES: "45.004",
    POW: "63.004",
    DOFC: "47.003",
    LOVELL: "45.001",
    WIMB: "47.004",
    STG: "71.006",
    KINGS: "97.006",
    DAY1_POS: "549",
    DAY2_POS: "578",
    DAY3_POS: "534",
    DAY4_POS: "370",
    DAY5_POS: "490",
  },
  1630: {
    Index: "1630",
    Init: "NBS",
    NAME: "Logan",
    full_NAME: "NBS Logan",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "25",
    GUNSCORE_DAY1: "172.023",
    GUNSCORE_DAY2: "140.016",
    GUNSCORE_DAY3: "168.017",
    GUNSCORE_DAY4: "168.017",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "750.081",
    DAILY_T: "75.009",
    DONEGALL: "47.005",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "43.004",
    CORP: "50.006",
    TIMES: "45.002",
    POW: "75.008",
    DOFC: "48.007",
    LOVELL: "46.003",
    WIMB: "47.003",
    STG: "75.011",
    KINGS: "102.008",
    DAY1_POS: "85",
    DAY2_POS: "282",
    DAY3_POS: "143",
    DAY4_POS: "163",
    DAY5_POS: "225",
  },
  1659: {
    Index: "1659",
    Init: "SL",
    NAME: "Cleveland",
    full_NAME: "SL Cleveland",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "8",
    Day_2_BONUS: "5",
    Day_2_Total: "13",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "10",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "23",
    GUNSCORE_DAY1: "170.017",
    GUNSCORE_DAY2: "148.016",
    GUNSCORE_DAY3: "118.012",
    GUNSCORE_DAY4: "170.024",
    GUNSCORE_DAY5: "32.002",
    GUNSCORE_GA: "638.071",
    DAILY_T: "73.008",
    DONEGALL: "48.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.006",
    CORP: "50.004",
    TIMES: "48.007",
    POW: "70.005",
    DOFC: "0",
    LOVELL: "45.004",
    WIMB: "50.009",
    STG: "75.011",
    KINGS: "32.002",
    DAY1_POS: "226",
    DAY2_POS: "18",
    DAY3_POS: "634",
    DAY4_POS: "57",
    DAY5_POS: "636",
  },
  1820: {
    Index: "1820",
    Init: "ERT",
    NAME: "Jeens",
    full_NAME: "ERT Jeens",
    Day_1: "11",
    Day_1_BONUS: "15",
    Day_1_Total: "26",
    Day_2: "28",
    Day_2_BONUS: "15",
    Day_2_Total: "43",
    Day_3: "16",
    Day_3_BONUS: "5",
    Day_3_Total: "21",
    Day_4: "2",
    Day_4_BONUS: "0",
    Day_4_Total: "2",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "70",
    FINAL_TOTAL: "162",
    GUNSCORE_DAY1: "175.023",
    GUNSCORE_DAY2: "150.016",
    GUNSCORE_DAY3: "173.021",
    GUNSCORE_DAY4: "171.022",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "770.091",
    DAILY_T: "75.007",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "50.005",
    CORP: "50.005",
    TIMES: "50.007",
    POW: "74.008",
    DOFC: "49.006",
    LOVELL: "49.005",
    WIMB: "49.008",
    STG: "73.009",
    KINGS: "101.009",
    DAY1_POS: "11",
    DAY2_POS: "3",
    DAY3_POS: "7",
    DAY4_POS: "46",
    DAY5_POS: "286",
  },
  1850: {
    Index: "1850",
    Init: "GP",
    NAME: "Nelson",
    full_NAME: "GP Nelson",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "3",
    Day_2_BONUS: "5",
    Day_2_Total: "8",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "7",
    FINAL_TOTAL: "28",
    GUNSCORE_DAY1: "173.023",
    GUNSCORE_DAY2: "146.022",
    GUNSCORE_DAY3: "169.014",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "760.091",
    DAILY_T: "75.01",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.009",
    ALEXANDRA: "49.007",
    CORP: "47.006",
    TIMES: "49.004",
    POW: "71.005",
    DOFC: "49.005",
    LOVELL: "45.003",
    WIMB: "49.006",
    STG: "74.01",
    KINGS: "104.013",
    DAY1_POS: "54",
    DAY2_POS: "42",
    DAY3_POS: "120",
    DAY4_POS: "151",
    DAY5_POS: "41",
  },
  1913: {
    Index: "1913",
    Init: "GV",
    NAME: "Feast",
    full_NAME: "GV Feast",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "168.018",
    GUNSCORE_DAY2: "145.013",
    GUNSCORE_DAY3: "170.016",
    GUNSCORE_DAY4: "169.017",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "756.075",
    DAILY_T: "70.006",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.007",
    CORP: "47.001",
    TIMES: "50.007",
    POW: "73.007",
    DOFC: "47.002",
    LOVELL: "45.003",
    WIMB: "49.006",
    STG: "75.008",
    KINGS: "104.011",
    DAY1_POS: "299",
    DAY2_POS: "89",
    DAY3_POS: "80",
    DAY4_POS: "120",
    DAY5_POS: "63",
  },
  1914: {
    Index: "1914",
    Init: "CM",
    NAME: "Fopp",
    full_NAME: "CM Fopp",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.017",
    GUNSCORE_DAY2: "137.009",
    GUNSCORE_DAY3: "156.015",
    GUNSCORE_DAY4: "157.015",
    GUNSCORE_DAY5: "100.006",
    GUNSCORE_GA: "716.062",
    DAILY_T: "72.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "50.005",
    CORP: "41.001",
    TIMES: "48.007",
    POW: "66.004",
    DOFC: "42.004",
    LOVELL: "40.003",
    WIMB: "45.005",
    STG: "72.007",
    KINGS: "100.006",
    DAY1_POS: "375",
    DAY2_POS: "406",
    DAY3_POS: "519",
    DAY4_POS: "481",
    DAY5_POS: "372",
  },
  1932: {
    Index: "1932",
    Init: "MM",
    NAME: "Norman",
    full_NAME: "MM Norman",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.012",
    GUNSCORE_DAY2: "140.01",
    GUNSCORE_DAY3: "169.014",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "32",
    GUNSCORE_GA: "509.036",
    DAILY_T: "73.006",
    DONEGALL: "46.002",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "50.005",
    CORP: "43.002",
    TIMES: "49.004",
    POW: "73.007",
    DOFC: "47.003",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "32",
    DAY1_POS: "325",
    DAY2_POS: "313",
    DAY3_POS: "120",
    DAY4_POS: "636",
    DAY5_POS: "637",
  },
  1971: {
    Index: "1971",
    Init: "JWE",
    NAME: "Lothian",
    full_NAME: "JWE Lothian",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "3",
    Day_2_BONUS: "10",
    Day_2_Total: "13",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "23",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "147.013",
    GUNSCORE_DAY3: "165.015",
    GUNSCORE_DAY4: "167.013",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "755.071",
    DAILY_T: "74.008",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "50.002",
    CORP: "47.003",
    TIMES: "49.006",
    POW: "69.005",
    DOFC: "47.004",
    LOVELL: "45.001",
    WIMB: "50.004",
    STG: "72.008",
    KINGS: "104.01",
    DAY1_POS: "107",
    DAY2_POS: "38",
    DAY3_POS: "284",
    DAY4_POS: "208",
    DAY5_POS: "72",
  },
  1979: {
    Index: "1979",
    Init: "TJD",
    NAME: "Raincock",
    full_NAME: "TJD Raincock",
    Day_1: "1",
    Day_1_BONUS: "5",
    Day_1_Total: "6",
    Day_2: "23",
    Day_2_BONUS: "15",
    Day_2_Total: "38",
    Day_3: "3",
    Day_3_BONUS: "5",
    Day_3_Total: "8",
    Day_4: "12",
    Day_4_BONUS: "5",
    Day_4_Total: "17",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "84",
    FINAL_TOTAL: "153",
    GUNSCORE_DAY1: "173.026",
    GUNSCORE_DAY2: "149.022",
    GUNSCORE_DAY3: "171.018",
    GUNSCORE_DAY4: "173.025",
    GUNSCORE_DAY5: "104.009",
    GUNSCORE_GA: "770.1",
    DAILY_T: "74.012",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.01",
    ALEXANDRA: "50.003",
    CORP: "49.009",
    TIMES: "50.006",
    POW: "74.008",
    DOFC: "47.004",
    LOVELL: "49.006",
    WIMB: "50.009",
    STG: "74.01",
    KINGS: "104.009",
    DAY1_POS: "49",
    DAY2_POS: "4",
    DAY3_POS: "42",
    DAY4_POS: "10",
    DAY5_POS: "80",
  },
  1988: {
    Index: "1988",
    Init: "BMA",
    NAME: "Stevens",
    full_NAME: "BMA Stevens",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "167.018",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "749.08",
    DAILY_T: "72.009",
    DONEGALL: "49.008",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "49.008",
    CORP: "46.004",
    TIMES: "50.005",
    POW: "69.005",
    DOFC: "49.006",
    LOVELL: "45.004",
    WIMB: "47.003",
    STG: "75.011",
    KINGS: "101.01",
    DAY1_POS: "149",
    DAY2_POS: "196",
    DAY3_POS: "147",
    DAY4_POS: "192",
    DAY5_POS: "269",
  },
  1994: {
    Index: "1994",
    Init: "J",
    NAME: "Wallace",
    full_NAME: "J Wallace",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "166.013",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "105.013",
    GUNSCORE_GA: "749.071",
    DAILY_T: "74.006",
    DONEGALL: "48.008",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "50.006",
    CORP: "43.003",
    TIMES: "49.004",
    POW: "71.007",
    DOFC: "46.002",
    LOVELL: "45.002",
    WIMB: "48.003",
    STG: "72.008",
    KINGS: "105.013",
    DAY1_POS: "176",
    DAY2_POS: "196",
    DAY3_POS: "252",
    DAY4_POS: "294",
    DAY5_POS: "16",
  },
  1997: {
    Index: "1997",
    Init: "SJ",
    NAME: "Whitby",
    full_NAME: "SJ Whitby",
    Day_1: "18",
    Day_1_BONUS: "15",
    Day_1_Total: "33",
    Day_2: "5",
    Day_2_BONUS: "10",
    Day_2_Total: "15",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "10",
    FINAL_TOTAL: "58",
    GUNSCORE_DAY1: "175.025",
    GUNSCORE_DAY2: "147.017",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "170.013",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "761.081",
    DAILY_T: "75.01",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "50.007",
    CORP: "47.004",
    TIMES: "47.004",
    POW: "72.008",
    DOFC: "49.006",
    LOVELL: "49.005",
    WIMB: "47.003",
    STG: "74.005",
    KINGS: "101.008",
    DAY1_POS: "6",
    DAY2_POS: "26",
    DAY3_POS: "136",
    DAY4_POS: "89",
    DAY5_POS: "295",
  },
  2008: {
    Index: "2008",
    Init: "RA",
    NAME: "Dyball",
    full_NAME: "RA Dyball",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "20",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "144.017",
    GUNSCORE_DAY3: "164.017",
    GUNSCORE_DAY4: "164.013",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "749.081",
    DAILY_T: "75.011",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "49.008",
    CORP: "45.003",
    TIMES: "49.007",
    POW: "69.006",
    DOFC: "46.004",
    LOVELL: "45.003",
    WIMB: "47.003",
    STG: "72.007",
    KINGS: "103.011",
    DAY1_POS: "29",
    DAY2_POS: "108",
    DAY3_POS: "306",
    DAY4_POS: "325",
    DAY5_POS: "124",
  },
  2017: {
    Index: "2017",
    Init: "JF",
    NAME: "Lawrie",
    full_NAME: "JF Lawrie",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.02",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "165.017",
    GUNSCORE_DAY4: "169.021",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "748.083",
    DAILY_T: "73.01",
    DONEGALL: "49.004",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.006",
    CORP: "42",
    TIMES: "50.003",
    POW: "73.01",
    DOFC: "42.004",
    LOVELL: "46.005",
    WIMB: "50.008",
    STG: "73.008",
    KINGS: "103.012",
    DAY1_POS: "159",
    DAY2_POS: "288",
    DAY3_POS: "272",
    DAY4_POS: "94",
    DAY5_POS: "112",
  },
  2053: {
    Index: "2053",
    Init: "B",
    NAME: "Bullock",
    full_NAME: "B Bullock",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "3",
    Day_2_BONUS: "5",
    Day_2_Total: "8",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "147.012",
    GUNSCORE_DAY3: "165.012",
    GUNSCORE_DAY4: "170.018",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "755.073",
    DAILY_T: "74.01",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "49.004",
    CORP: "48.004",
    TIMES: "49.004",
    POW: "71.005",
    DOFC: "45.003",
    LOVELL: "49.005",
    WIMB: "49.006",
    STG: "72.007",
    KINGS: "101.009",
    DAY1_POS: "91",
    DAY2_POS: "40",
    DAY3_POS: "300",
    DAY4_POS: "78",
    DAY5_POS: "286",
  },
  2117: {
    Index: "2117",
    Init: "M",
    NAME: "Grimsdell",
    full_NAME: "M Grimsdell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.004",
    GUNSCORE_DAY2: "129.003",
    GUNSCORE_DAY3: "159.008",
    GUNSCORE_DAY4: "145.01",
    GUNSCORE_DAY5: "83.006",
    GUNSCORE_GA: "664.031",
    DAILY_T: "66.003",
    DONEGALL: "41.001",
    CONAN_DOYLE: "41",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "42.001",
    CORP: "43.001",
    TIMES: "45.002",
    POW: "69.003",
    DOFC: "45.003",
    LOVELL: "42.003",
    WIMB: "40.003",
    STG: "63.004",
    KINGS: "83.006",
    DAY1_POS: "613",
    DAY2_POS: "558",
    DAY3_POS: "480",
    DAY4_POS: "582",
    DAY5_POS: "624",
  },
  2127: {
    Index: "2127",
    Init: "NT",
    NAME: "Healy",
    full_NAME: "NT Healy",
    Day_1: "8",
    Day_1_BONUS: "10",
    Day_1_Total: "18",
    Day_2: "2",
    Day_2_BONUS: "5",
    Day_2_Total: "7",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "2",
    Day_4_BONUS: "0",
    Day_4_Total: "2",
    Day_5: "6",
    Day_5_BONUS: "0",
    Day_5_Total: "6",
    GRAND_MULTIPLIER: "29",
    FINAL_TOTAL: "62",
    GUNSCORE_DAY1: "174.027",
    GUNSCORE_DAY2: "146.018",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "171.023",
    GUNSCORE_DAY5: "104.018",
    GUNSCORE_GA: "765.106",
    DAILY_T: "74.011",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.004",
    CORP: "47.006",
    TIMES: "49.006",
    POW: "73.008",
    DOFC: "48.006",
    LOVELL: "48.003",
    WIMB: "49.007",
    STG: "74.013",
    KINGS: "104.018",
    DAY1_POS: "17",
    DAY2_POS: "47",
    DAY3_POS: "58",
    DAY4_POS: "43",
    DAY5_POS: "24",
  },
  2181: {
    Index: "2181",
    Init: "RJ",
    NAME: "McQuillan",
    full_NAME: "RJ McQuillan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "10",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "164.017",
    GUNSCORE_DAY2: "138.013",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "168.014",
    GUNSCORE_DAY5: "99.011",
    GUNSCORE_GA: "734.071",
    DAILY_T: "72.008",
    DONEGALL: "44.004",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "50.006",
    CORP: "44.006",
    TIMES: "45.003",
    POW: "73.007",
    DOFC: "47.006",
    LOVELL: "43.001",
    WIMB: "50.003",
    STG: "75.01",
    KINGS: "99.011",
    DAY1_POS: "437",
    DAY2_POS: "363",
    DAY3_POS: "277",
    DAY4_POS: "177",
    DAY5_POS: "383",
  },
  2186: {
    Index: "2186",
    Init: "MRM",
    NAME: "Millar",
    full_NAME: "MRM Millar",
    Day_1: "11",
    Day_1_BONUS: "15",
    Day_1_Total: "26",
    Day_2: "6",
    Day_2_BONUS: "5",
    Day_2_Total: "11",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "7",
    Day_4_BONUS: "10",
    Day_4_Total: "17",
    Day_5: "18",
    Day_5_BONUS: "5",
    Day_5_Total: "23",
    GRAND_MULTIPLIER: "48",
    FINAL_TOTAL: "125",
    GUNSCORE_DAY1: "175.023",
    GUNSCORE_DAY2: "147.019",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "173.018",
    GUNSCORE_DAY5: "105.015",
    GUNSCORE_GA: "768.089",
    DAILY_T: "75.009",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.006",
    CORP: "48.005",
    TIMES: "49.003",
    POW: "74.011",
    DOFC: "45",
    LOVELL: "48.004",
    WIMB: "50.006",
    STG: "75.008",
    KINGS: "105.015",
    DAY1_POS: "11",
    DAY2_POS: "24",
    DAY3_POS: "164",
    DAY4_POS: "21",
    DAY5_POS: "6",
  },
  2307: {
    Index: "2307",
    Init: "SD",
    NAME: "Shouler",
    full_NAME: "SD Shouler",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "10",
    Day_4_Total: "10",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "24",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "143.016",
    GUNSCORE_DAY3: "167.011",
    GUNSCORE_DAY4: "169.014",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "755.077",
    DAILY_T: "74.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "48.005",
    CORP: "45.003",
    TIMES: "49.006",
    POW: "72.003",
    DOFC: "46.002",
    LOVELL: "44",
    WIMB: "50.005",
    STG: "75.009",
    KINGS: "104.014",
    DAY1_POS: "91",
    DAY2_POS: "153",
    DAY3_POS: "208",
    DAY4_POS: "134",
    DAY5_POS: "31",
  },
  2312: {
    Index: "2312",
    Init: "TS",
    NAME: "McDowell",
    full_NAME: "TS McDowell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.013",
    GUNSCORE_DAY2: "134.004",
    GUNSCORE_DAY3: "158.009",
    GUNSCORE_DAY4: "152.009",
    GUNSCORE_DAY5: "61.002",
    GUNSCORE_GA: "662.037",
    DAILY_T: "67.006",
    DONEGALL: "46.003",
    CONAN_DOYLE: "44.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "44.001",
    CORP: "43.001",
    TIMES: "44.002",
    POW: "71.006",
    DOFC: "43.001",
    LOVELL: "39.002",
    WIMB: "42.002",
    STG: "71.005",
    KINGS: "61.002",
    DAY1_POS: "551",
    DAY2_POS: "482",
    DAY3_POS: "497",
    DAY4_POS: "541",
    DAY5_POS: "634",
  },
  2313: {
    Index: "2313",
    Init: "J",
    NAME: "Mehta",
    full_NAME: "J Mehta",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.013",
    GUNSCORE_DAY2: "145.012",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "756.071",
    DAILY_T: "72.006",
    DONEGALL: "49.003",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.005",
    CORP: "47.003",
    TIMES: "47.002",
    POW: "74.009",
    DOFC: "47.003",
    LOVELL: "48.007",
    WIMB: "49.005",
    STG: "73.008",
    KINGS: "103.012",
    DAY1_POS: "243",
    DAY2_POS: "93",
    DAY3_POS: "164",
    DAY4_POS: "66",
    DAY5_POS: "112",
  },
  2316: {
    Index: "2316",
    Init: "J",
    NAME: "Anderson",
    full_NAME: "J Anderson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "170.025",
    GUNSCORE_DAY2: "145.016",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "749.081",
    DAILY_T: "71.01",
    DONEGALL: "50.008",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "49.008",
    CORP: "46.002",
    TIMES: "49.007",
    POW: "70.006",
    DOFC: "47.002",
    LOVELL: "44.001",
    WIMB: "50.007",
    STG: "72.006",
    KINGS: "102.011",
    DAY1_POS: "197",
    DAY2_POS: "75",
    DAY3_POS: "240",
    DAY4_POS: "240",
    DAY5_POS: "191",
  },
  2333: {
    Index: "2333",
    Init: "WDH",
    NAME: "Burden",
    full_NAME: "WDH Burden",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.024",
    GUNSCORE_DAY2: "141.015",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "736.075",
    DAILY_T: "75.014",
    DONEGALL: "50.007",
    CONAN_DOYLE: "44.003",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.006",
    CORP: "44.003",
    TIMES: "46.004",
    POW: "73.009",
    DOFC: "45.002",
    LOVELL: "45.005",
    WIMB: "46.003",
    STG: "72.006",
    KINGS: "99.007",
    DAY1_POS: "245",
    DAY2_POS: "238",
    DAY3_POS: "314",
    DAY4_POS: "355",
    DAY5_POS: "413",
  },
  2353: {
    Index: "2353",
    Init: "EC",
    NAME: "Dickson",
    full_NAME: "EC Dickson",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "173.021",
    GUNSCORE_DAY2: "143.015",
    GUNSCORE_DAY3: "167.014",
    GUNSCORE_DAY4: "168.015",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "754.076",
    DAILY_T: "73.006",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "48.005",
    CORP: "45.002",
    TIMES: "49.007",
    POW: "72.004",
    DOFC: "46.003",
    LOVELL: "46.002",
    WIMB: "48.003",
    STG: "74.01",
    KINGS: "103.011",
    DAY1_POS: "63",
    DAY2_POS: "155",
    DAY3_POS: "201",
    DAY4_POS: "174",
    DAY5_POS: "124",
  },
  2364: {
    Index: "2364",
    Init: "TH",
    NAME: "Emck",
    full_NAME: "TH Emck",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.01",
    GUNSCORE_DAY2: "128.004",
    GUNSCORE_DAY3: "150.007",
    GUNSCORE_DAY4: "149.013",
    GUNSCORE_DAY5: "91.01",
    GUNSCORE_GA: "674.044",
    DAILY_T: "67.005",
    DONEGALL: "45.003",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "46.002",
    CORP: "36",
    TIMES: "46.004",
    POW: "60.002",
    DOFC: "44.001",
    LOVELL: "44.004",
    WIMB: "44.004",
    STG: "61.005",
    KINGS: "91.01",
    DAY1_POS: "570",
    DAY2_POS: "573",
    DAY3_POS: "580",
    DAY4_POS: "563",
    DAY5_POS: "589",
  },
  2386: {
    Index: "2386",
    Init: "AC",
    NAME: "Haley",
    full_NAME: "AC Haley",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "1",
    Day_2_BONUS: "10",
    Day_2_Total: "11",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "29",
    GUNSCORE_DAY1: "171.024",
    GUNSCORE_DAY2: "146.017",
    GUNSCORE_DAY3: "163.01",
    GUNSCORE_DAY4: "170.018",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "754.082",
    DAILY_T: "73.007",
    DONEGALL: "50.01",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "50.006",
    CORP: "46.003",
    TIMES: "48.003",
    POW: "70.004",
    DOFC: "45.003",
    LOVELL: "47.004",
    WIMB: "48.002",
    STG: "75.012",
    KINGS: "104.013",
    DAY1_POS: "137",
    DAY2_POS: "49",
    DAY3_POS: "359",
    DAY4_POS: "78",
    DAY5_POS: "41",
  },
  2439: {
    Index: "2439",
    Init: "DE",
    NAME: "Nuthall",
    full_NAME: "DE Nuthall",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "163.013",
    GUNSCORE_DAY2: "139.009",
    GUNSCORE_DAY3: "167.014",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "739.062",
    DAILY_T: "69.007",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.001",
    DAILY_MAIL: "50.003",
    ALEXANDRA: "45.001",
    CORP: "44.005",
    TIMES: "49.005",
    POW: "70.005",
    DOFC: "48.004",
    LOVELL: "48.003",
    WIMB: "49.008",
    STG: "72.007",
    KINGS: "101.008",
    DAY1_POS: "473",
    DAY2_POS: "346",
    DAY3_POS: "201",
    DAY4_POS: "110",
    DAY5_POS: "295",
  },
  2467: {
    Index: "2467",
    Init: "R",
    NAME: "Stebbings",
    full_NAME: "R Stebbings",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.019",
    GUNSCORE_DAY2: "133.009",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "160.01",
    GUNSCORE_DAY5: "98.005",
    GUNSCORE_GA: "723.059",
    DAILY_T: "73.01",
    DONEGALL: "47.005",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "43.003",
    CORP: "43.002",
    TIMES: "49.006",
    POW: "70.005",
    DOFC: "47.005",
    LOVELL: "41.001",
    WIMB: "48.004",
    STG: "71.005",
    KINGS: "98.005",
    DAY1_POS: "369",
    DAY2_POS: "489",
    DAY3_POS: "231",
    DAY4_POS: "438",
    DAY5_POS: "465",
  },
  2487: {
    Index: "2487",
    Init: "HA",
    NAME: "Day",
    full_NAME: "HA Day",
    Day_1: "6",
    Day_1_BONUS: "10",
    Day_1_Total: "16",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "16",
    GUNSCORE_DAY1: "174.025",
    GUNSCORE_DAY2: "143.015",
    GUNSCORE_DAY3: "166.02",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "101.015",
    GUNSCORE_GA: "750.089",
    DAILY_T: "75.01",
    DONEGALL: "50.008",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "48.006",
    CORP: "47.002",
    TIMES: "49.007",
    POW: "70.007",
    DOFC: "47.006",
    LOVELL: "47.004",
    WIMB: "45.002",
    STG: "74.008",
    KINGS: "101.015",
    DAY1_POS: "24",
    DAY2_POS: "155",
    DAY3_POS: "216",
    DAY4_POS: "240",
    DAY5_POS: "240",
  },
  2492: {
    Index: "2492",
    Init: "AM",
    NAME: "Bullen",
    full_NAME: "AM Bullen",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "144.018",
    GUNSCORE_DAY3: "164.013",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "754.082",
    DAILY_T: "74.011",
    DONEGALL: "50.005",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.007",
    CORP: "47.006",
    TIMES: "48.004",
    POW: "71.005",
    DOFC: "45.004",
    LOVELL: "46.003",
    WIMB: "50.008",
    STG: "74.009",
    KINGS: "103.011",
    DAY1_POS: "69",
    DAY2_POS: "104",
    DAY3_POS: "327",
    DAY4_POS: "66",
    DAY5_POS: "124",
  },
  2507: {
    Index: "2507",
    Init: "RC",
    NAME: "Oxford",
    full_NAME: "RC Oxford",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "2",
    Day_2_BONUS: "0",
    Day_2_Total: "2",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "3",
    Day_4_BONUS: "15",
    Day_4_Total: "18",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "20",
    GUNSCORE_DAY1: "171.023",
    GUNSCORE_DAY2: "146.019",
    GUNSCORE_DAY3: "165.015",
    GUNSCORE_DAY4: "171.024",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "755.092",
    DAILY_T: "74.01",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "49.006",
    CORP: "49.006",
    TIMES: "49.006",
    POW: "68.003",
    DOFC: "48.006",
    LOVELL: "46.002",
    WIMB: "50.01",
    STG: "75.012",
    KINGS: "102.011",
    DAY1_POS: "140",
    DAY2_POS: "45",
    DAY3_POS: "284",
    DAY4_POS: "41",
    DAY5_POS: "191",
  },
  2510: {
    Index: "2510",
    Init: "DE",
    NAME: "Curtis",
    full_NAME: "DE Curtis",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.017",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "156.011",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "93.005",
    GUNSCORE_GA: "721.058",
    DAILY_T: "68.006",
    DONEGALL: "50.007",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.004",
    CORP: "47.003",
    TIMES: "48.004",
    POW: "65.004",
    DOFC: "43.003",
    LOVELL: "49.002",
    WIMB: "48.005",
    STG: "69.007",
    KINGS: "93.005",
    DAY1_POS: "403",
    DAY2_POS: "259",
    DAY3_POS: "523",
    DAY4_POS: "240",
    DAY5_POS: "568",
  },
  2551: {
    Index: "2551",
    Init: "TM",
    NAME: "Drysdale",
    full_NAME: "TM Drysdale",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "166.008",
    GUNSCORE_DAY4: "169.016",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "753.071",
    DAILY_T: "75.008",
    DONEGALL: "47.005",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.007",
    CORP: "45.003",
    TIMES: "48.001",
    POW: "71.004",
    DOFC: "47.003",
    LOVELL: "45.004",
    WIMB: "49.005",
    STG: "75.007",
    KINGS: "103.013",
    DAY1_POS: "107",
    DAY2_POS: "160",
    DAY3_POS: "265",
    DAY4_POS: "124",
    DAY5_POS: "97",
  },
  2587: {
    Index: "2587",
    Init: "TS",
    NAME: "Keen",
    full_NAME: "TS Keen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.016",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "169.012",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "101.014",
    GUNSCORE_GA: "748.07",
    DAILY_T: "73.009",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "49.002",
    CORP: "45.004",
    TIMES: "49.003",
    POW: "71.005",
    DOFC: "49.004",
    LOVELL: "45.003",
    WIMB: "45.003",
    STG: "74.009",
    KINGS: "101.014",
    DAY1_POS: "230",
    DAY2_POS: "127",
    DAY3_POS: "125",
    DAY4_POS: "316",
    DAY5_POS: "241",
  },
  2595: {
    Index: "2595",
    Init: "R",
    NAME: "Laird",
    full_NAME: "R Laird",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.025",
    GUNSCORE_DAY2: "138.013",
    GUNSCORE_DAY3: "169.015",
    GUNSCORE_DAY4: "165.01",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "746.073",
    DAILY_T: "73.012",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.005",
    CORP: "41.003",
    TIMES: "48.007",
    POW: "72.005",
    DOFC: "49.003",
    LOVELL: "47.003",
    WIMB: "47.003",
    STG: "71.004",
    KINGS: "103.01",
    DAY1_POS: "135",
    DAY2_POS: "363",
    DAY3_POS: "116",
    DAY4_POS: "307",
    DAY5_POS: "138",
  },
  2659: {
    Index: "2659",
    Init: "N",
    NAME: "Stebbings",
    full_NAME: "N Stebbings",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "149.006",
    GUNSCORE_DAY2: "123.005",
    GUNSCORE_DAY3: "144.006",
    GUNSCORE_DAY4: "152.008",
    GUNSCORE_DAY5: "96.004",
    GUNSCORE_GA: "664.029",
    DAILY_T: "63.002",
    DONEGALL: "45.003",
    CONAN_DOYLE: "41.001",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "45.002",
    CORP: "32",
    TIMES: "34.001",
    POW: "66.004",
    DOFC: "44.001",
    LOVELL: "43.002",
    WIMB: "44.001",
    STG: "65.005",
    KINGS: "96.004",
    DAY1_POS: "607",
    DAY2_POS: "600",
    DAY3_POS: "607",
    DAY4_POS: "544",
    DAY5_POS: "531",
  },
  2660: {
    Index: "2660",
    Init: "G",
    NAME: "Stevenson",
    full_NAME: "G Stevenson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.006",
    GUNSCORE_DAY2: "135.005",
    GUNSCORE_DAY3: "166.006",
    GUNSCORE_DAY4: "161.011",
    GUNSCORE_DAY5: "95.008",
    GUNSCORE_GA: "711.036",
    DAILY_T: "68.004",
    DONEGALL: "41",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "45",
    CORP: "43.002",
    TIMES: "47.002",
    POW: "71.004",
    DOFC: "48",
    LOVELL: "46.003",
    WIMB: "45.003",
    STG: "70.005",
    KINGS: "95.008",
    DAY1_POS: "583",
    DAY2_POS: "459",
    DAY3_POS: "266",
    DAY4_POS: "415",
    DAY5_POS: "537",
  },
  2675: {
    Index: "2675",
    Init: "G",
    NAME: "Woodman",
    full_NAME: "G Woodman",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "10",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "142.014",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "754.082",
    DAILY_T: "75.007",
    DONEGALL: "49.005",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "48.006",
    CORP: "45.003",
    TIMES: "50.007",
    POW: "70.005",
    DOFC: "50.008",
    LOVELL: "47.004",
    WIMB: "49.007",
    STG: "72.008",
    KINGS: "103.013",
    DAY1_POS: "183",
    DAY2_POS: "206",
    DAY3_POS: "58",
    DAY4_POS: "151",
    DAY5_POS: "97",
  },
  2694: {
    Index: "2694",
    Init: "JG",
    NAME: "Trembath",
    full_NAME: "JG Trembath",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "2",
    Day_2_BONUS: "0",
    Day_2_Total: "2",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "7",
    GUNSCORE_DAY1: "167.019",
    GUNSCORE_DAY2: "146.02",
    GUNSCORE_DAY3: "169.019",
    GUNSCORE_DAY4: "167.015",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "752.084",
    DAILY_T: "71.007",
    DONEGALL: "47.006",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.005",
    CORP: "48.008",
    TIMES: "50.007",
    POW: "70.007",
    DOFC: "49.005",
    LOVELL: "46.005",
    WIMB: "48.003",
    STG: "73.007",
    KINGS: "103.011",
    DAY1_POS: "336",
    DAY2_POS: "43",
    DAY3_POS: "98",
    DAY4_POS: "200",
    DAY5_POS: "124",
  },
  2723: {
    Index: "2723",
    Init: "JM",
    NAME: "McQuillan",
    full_NAME: "JM McQuillan",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.015",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "164.013",
    GUNSCORE_DAY4: "72.005",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "651.056",
    DAILY_T: "73.005",
    DONEGALL: "47.002",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "48.004",
    CORP: "46.004",
    TIMES: "46.003",
    POW: "72.007",
    DOFC: "46.003",
    LOVELL: "0",
    WIMB: "0",
    STG: "72.005",
    KINGS: "102.01",
    DAY1_POS: "236",
    DAY2_POS: "168",
    DAY3_POS: "327",
    DAY4_POS: "633",
    DAY5_POS: "202",
  },
  2733: {
    Index: "2733",
    Init: "ANR",
    NAME: "Walker",
    full_NAME: "ANR Walker",
    Day_1: "4",
    Day_1_BONUS: "10",
    Day_1_Total: "14",
    Day_2: "16",
    Day_2_BONUS: "10",
    Day_2_Total: "26",
    Day_3: "3",
    Day_3_BONUS: "5",
    Day_3_Total: "8",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "31",
    FINAL_TOTAL: "79",
    GUNSCORE_DAY1: "174.022",
    GUNSCORE_DAY2: "149.018",
    GUNSCORE_DAY3: "171.019",
    GUNSCORE_DAY4: "170.021",
    GUNSCORE_DAY5: "102.014",
    GUNSCORE_GA: "766.094",
    DAILY_T: "74.01",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.007",
    CORP: "49.004",
    TIMES: "50.006",
    POW: "73.008",
    DOFC: "48.005",
    LOVELL: "48.006",
    WIMB: "48.003",
    STG: "74.012",
    KINGS: "102.014",
    DAY1_POS: "36",
    DAY2_POS: "7",
    DAY3_POS: "39",
    DAY4_POS: "64",
    DAY5_POS: "170",
  },
  2755: {
    Index: "2755",
    Init: "LR",
    NAME: "Braithwaite",
    full_NAME: "LR Braithwaite",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "173.021",
    GUNSCORE_DAY2: "139.012",
    GUNSCORE_DAY3: "164.017",
    GUNSCORE_DAY4: "164.014",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "741.075",
    DAILY_T: "75.008",
    DONEGALL: "48.007",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "49.005",
    CORP: "42.001",
    TIMES: "49.005",
    POW: "70.009",
    DOFC: "45.003",
    LOVELL: "46.004",
    WIMB: "44.001",
    STG: "74.009",
    KINGS: "101.011",
    DAY1_POS: "63",
    DAY2_POS: "328",
    DAY3_POS: "306",
    DAY4_POS: "323",
    DAY5_POS: "254",
  },
  2800: {
    Index: "2800",
    Init: "CSTJ",
    NAME: "Marlow",
    full_NAME: "CSTJ Marlow",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.011",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "154.005",
    GUNSCORE_DAY4: "141.006",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "685.04",
    DAILY_T: "69.006",
    DONEGALL: "43.002",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "48.006",
    CORP: "43.003",
    TIMES: "43.002",
    POW: "63",
    DOFC: "48.003",
    LOVELL: "38.001",
    WIMB: "42.002",
    STG: "61.003",
    KINGS: "96.007",
    DAY1_POS: "557",
    DAY2_POS: "397",
    DAY3_POS: "551",
    DAY4_POS: "608",
    DAY5_POS: "512",
  },
  2806: {
    Index: "2806",
    Init: "MG",
    NAME: "Whicher",
    full_NAME: "MG Whicher",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "165.017",
    GUNSCORE_DAY2: "145.013",
    GUNSCORE_DAY3: "170.017",
    GUNSCORE_DAY4: "164.012",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "747.071",
    DAILY_T: "68.008",
    DONEGALL: "47.004",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.004",
    CORP: "47.002",
    TIMES: "49.004",
    POW: "72.008",
    DOFC: "49.005",
    LOVELL: "41.004",
    WIMB: "49.001",
    STG: "74.007",
    KINGS: "103.012",
    DAY1_POS: "403",
    DAY2_POS: "89",
    DAY3_POS: "77",
    DAY4_POS: "329",
    DAY5_POS: "112",
  },
  2820: {
    Index: "2820",
    Init: "CB",
    NAME: "Cotillard",
    full_NAME: "CB Cotillard",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.02",
    GUNSCORE_DAY2: "141.014",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "164.018",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "745.082",
    DAILY_T: "71.007",
    DONEGALL: "48.007",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "49.005",
    CORP: "44.002",
    TIMES: "49.003",
    POW: "73.009",
    DOFC: "48.007",
    LOVELL: "45.004",
    WIMB: "48.006",
    STG: "71.008",
    KINGS: "103.011",
    DAY1_POS: "332",
    DAY2_POS: "240",
    DAY3_POS: "65",
    DAY4_POS: "309",
    DAY5_POS: "124",
  },
  2877: {
    Index: "2877",
    Init: "JAM",
    NAME: "Paton",
    full_NAME: "JAM Paton",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "5",
    Day_2_BONUS: "0",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "147.017",
    GUNSCORE_DAY3: "170.014",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "99.013",
    GUNSCORE_GA: "755.082",
    DAILY_T: "72.006",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.007",
    CORP: "49.004",
    TIMES: "48.003",
    POW: "73.007",
    DOFC: "49.004",
    LOVELL: "45.004",
    WIMB: "48.005",
    STG: "75.01",
    KINGS: "99.013",
    DAY1_POS: "165",
    DAY2_POS: "26",
    DAY3_POS: "84",
    DAY4_POS: "151",
    DAY5_POS: "379",
  },
  2935: {
    Index: "2935",
    Init: "DJ",
    NAME: "Blake",
    full_NAME: "DJ Blake",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "3",
    Day_4_BONUS: "0",
    Day_4_Total: "3",
    Day_5: "5",
    Day_5_BONUS: "0",
    Day_5_Total: "5",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "169.019",
    GUNSCORE_DAY4: "172.018",
    GUNSCORE_DAY5: "104.016",
    GUNSCORE_GA: "753.085",
    DAILY_T: "73.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "48.004",
    CORP: "43.002",
    TIMES: "49.007",
    POW: "70.006",
    DOFC: "50.006",
    LOVELL: "49.004",
    WIMB: "49.004",
    STG: "74.01",
    KINGS: "104.016",
    DAY1_POS: "149",
    DAY2_POS: "397",
    DAY3_POS: "98",
    DAY4_POS: "37",
    DAY5_POS: "26",
  },
  2936: {
    Index: "2936",
    Init: "MI",
    NAME: "Buchanan",
    full_NAME: "MI Buchanan",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "172.024",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "170.012",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "756.08",
    DAILY_T: "75.011",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "46.003",
    CORP: "48.004",
    TIMES: "49.005",
    POW: "75.004",
    DOFC: "46.003",
    LOVELL: "47.005",
    WIMB: "49.007",
    STG: "74.008",
    KINGS: "100.01",
    DAY1_POS: "83",
    DAY2_POS: "120",
    DAY3_POS: "87",
    DAY4_POS: "66",
    DAY5_POS: "333",
  },
  2944: {
    Index: "2944",
    Init: "HJ",
    NAME: "Golaszewski",
    full_NAME: "HJ Golaszewski",
    Day_1: "7",
    Day_1_BONUS: "10",
    Day_1_Total: "17",
    Day_2: "3",
    Day_2_BONUS: "10",
    Day_2_Total: "13",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "35",
    GUNSCORE_DAY1: "174.026",
    GUNSCORE_DAY2: "147.013",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "170.015",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "760.082",
    DAILY_T: "75.013",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "50.007",
    CORP: "47",
    TIMES: "47.005",
    POW: "72.008",
    DOFC: "46.003",
    LOVELL: "47.002",
    WIMB: "50.005",
    STG: "73.008",
    KINGS: "104.012",
    DAY1_POS: "21",
    DAY2_POS: "38",
    DAY3_POS: "277",
    DAY4_POS: "88",
    DAY5_POS: "53",
  },
  2945: {
    Index: "2945",
    Init: "OP",
    NAME: "Henry",
    full_NAME: "OP Henry",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.013",
    GUNSCORE_DAY2: "128.005",
    GUNSCORE_DAY3: "159.006",
    GUNSCORE_DAY4: "157.013",
    GUNSCORE_DAY5: "94.005",
    GUNSCORE_GA: "694.042",
    DAILY_T: "64.004",
    DONEGALL: "44.003",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "45",
    ALEXANDRA: "45.005",
    CORP: "38",
    TIMES: "45.002",
    POW: "67.001",
    DOFC: "47.003",
    LOVELL: "42.003",
    WIMB: "47.003",
    STG: "68.007",
    KINGS: "94.005",
    DAY1_POS: "565",
    DAY2_POS: "569",
    DAY3_POS: "482",
    DAY4_POS: "484",
    DAY5_POS: "558",
  },
  3005: {
    Index: "3005",
    Init: "Y",
    NAME: "Sakakibara",
    full_NAME: "Y Sakakibara",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.009",
    GUNSCORE_DAY2: "137.009",
    GUNSCORE_DAY3: "145.004",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "448.022",
    DAILY_T: "71.004",
    DONEGALL: "46.002",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "48.004",
    CORP: "42.002",
    TIMES: "41.001",
    POW: "64.003",
    DOFC: "40",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "398",
    DAY2_POS: "406",
    DAY3_POS: "604",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  3009: {
    Index: "3009",
    Init: "DN",
    NAME: "Lindsay",
    full_NAME: "DN Lindsay",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "172.027",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "97.009",
    GUNSCORE_GA: "742.077",
    DAILY_T: "74.01",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.009",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "46.004",
    CORP: "46.001",
    TIMES: "47.005",
    POW: "72.008",
    DOFC: "47.003",
    LOVELL: "44.002",
    WIMB: "48.006",
    STG: "74.006",
    KINGS: "97.009",
    DAY1_POS: "82",
    DAY2_POS: "259",
    DAY3_POS: "231",
    DAY4_POS: "240",
    DAY5_POS: "473",
  },
  3018: {
    Index: "3018",
    Init: "MJ",
    NAME: "Barlow",
    full_NAME: "MJ Barlow",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "3",
    Day_4_BONUS: "5",
    Day_4_Total: "8",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "172.024",
    GUNSCORE_DAY2: "140.014",
    GUNSCORE_DAY3: "166.017",
    GUNSCORE_DAY4: "171.024",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "752.092",
    DAILY_T: "74.01",
    DONEGALL: "48.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.004",
    CORP: "42.003",
    TIMES: "48.007",
    POW: "69.006",
    DOFC: "49.004",
    LOVELL: "48.006",
    WIMB: "48.005",
    STG: "75.013",
    KINGS: "103.013",
    DAY1_POS: "83",
    DAY2_POS: "283",
    DAY3_POS: "225",
    DAY4_POS: "41",
    DAY5_POS: "97",
  },
  3026: {
    Index: "3026",
    Init: "PJ",
    NAME: "Westlake",
    full_NAME: "PJ Westlake",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "141.009",
    GUNSCORE_DAY3: "168.02",
    GUNSCORE_DAY4: "162.014",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "745.071",
    DAILY_T: "74.008",
    DONEGALL: "50.008",
    CONAN_DOYLE: "47.001",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "46.003",
    CORP: "46.001",
    TIMES: "47.003",
    POW: "73.01",
    DOFC: "48.007",
    LOVELL: "43",
    WIMB: "48.005",
    STG: "71.009",
    KINGS: "103.011",
    DAY1_POS: "176",
    DAY2_POS: "273",
    DAY3_POS: "129",
    DAY4_POS: "392",
    DAY5_POS: "124",
  },
  3027: {
    Index: "3027",
    Init: "SP",
    NAME: "Thorogood",
    full_NAME: "SP Thorogood",
    Day_1: "9",
    Day_1_BONUS: "15",
    Day_1_Total: "24",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "23",
    Day_4_BONUS: "10",
    Day_4_Total: "33",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "37",
    FINAL_TOTAL: "99",
    GUNSCORE_DAY1: "175.022",
    GUNSCORE_DAY2: "145.019",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "174.024",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "767.094",
    DAILY_T: "75.007",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "50.008",
    CORP: "46.003",
    TIMES: "49.006",
    POW: "73.009",
    DOFC: "47.002",
    LOVELL: "49.006",
    WIMB: "50.006",
    STG: "75.012",
    KINGS: "104.012",
    DAY1_POS: "15",
    DAY2_POS: "66",
    DAY3_POS: "105",
    DAY4_POS: "4",
    DAY5_POS: "53",
  },
  3047: {
    Index: "3047",
    Init: "SD",
    NAME: "Spinney",
    full_NAME: "SD Spinney",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.014",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "155.006",
    GUNSCORE_DAY4: "152.007",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "712.047",
    DAILY_T: "70.003",
    DONEGALL: "48.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "47.005",
    CORP: "45.003",
    TIMES: "41",
    POW: "68.002",
    DOFC: "46.004",
    LOVELL: "45.003",
    WIMB: "44.002",
    STG: "63.002",
    KINGS: "98.008",
    DAY1_POS: "358",
    DAY2_POS: "298",
    DAY3_POS: "537",
    DAY4_POS: "547",
    DAY5_POS: "446",
  },
  3055: {
    Index: "3055",
    Init: "GR",
    NAME: "Jeal",
    full_NAME: "GR Jeal",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.019",
    GUNSCORE_DAY2: "142.013",
    GUNSCORE_DAY3: "164.012",
    GUNSCORE_DAY4: "164.013",
    GUNSCORE_DAY5: "100.007",
    GUNSCORE_GA: "739.064",
    DAILY_T: "73.008",
    DONEGALL: "47.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "47.005",
    CORP: "47.004",
    TIMES: "48.003",
    POW: "70.005",
    DOFC: "46.004",
    LOVELL: "46.001",
    WIMB: "49.006",
    STG: "69.006",
    KINGS: "100.007",
    DAY1_POS: "257",
    DAY2_POS: "212",
    DAY3_POS: "333",
    DAY4_POS: "325",
    DAY5_POS: "366",
  },
  3080: {
    Index: "3080",
    Init: "SME",
    NAME: "Hayton",
    full_NAME: "SME Hayton",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "5",
    Day_2_BONUS: "5",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "16",
    FINAL_TOTAL: "41",
    GUNSCORE_DAY1: "174.024",
    GUNSCORE_DAY2: "147.017",
    GUNSCORE_DAY3: "168.019",
    GUNSCORE_DAY4: "170.023",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "763.094",
    DAILY_T: "75.013",
    DONEGALL: "50.007",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "49.005",
    CORP: "48.007",
    TIMES: "49.006",
    POW: "72.007",
    DOFC: "47.006",
    LOVELL: "48.005",
    WIMB: "48.007",
    STG: "74.011",
    KINGS: "104.011",
    DAY1_POS: "26",
    DAY2_POS: "26",
    DAY3_POS: "134",
    DAY4_POS: "59",
    DAY5_POS: "63",
  },
  3104: {
    Index: "3104",
    Init: "TJ",
    NAME: "Bryan",
    full_NAME: "TJ Bryan",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.017",
    GUNSCORE_DAY2: "136.008",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "158.011",
    GUNSCORE_DAY5: "99.005",
    GUNSCORE_GA: "730.056",
    DAILY_T: "70.003",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "47.002",
    CORP: "44.004",
    TIMES: "49.005",
    POW: "70.006",
    DOFC: "49.004",
    LOVELL: "41.001",
    WIMB: "46.005",
    STG: "71.005",
    KINGS: "99.005",
    DAY1_POS: "267",
    DAY2_POS: "425",
    DAY3_POS: "154",
    DAY4_POS: "464",
    DAY5_POS: "428",
  },
  3120: {
    Index: "3120",
    Init: "P",
    NAME: "Waldron",
    full_NAME: "P Waldron",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "135.01",
    GUNSCORE_DAY3: "155.005",
    GUNSCORE_DAY4: "162.011",
    GUNSCORE_DAY5: "99.005",
    GUNSCORE_GA: "714.043",
    DAILY_T: "69.003",
    DONEGALL: "46.003",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "46.002",
    CORP: "42.004",
    TIMES: "44.002",
    POW: "69.002",
    DOFC: "42.001",
    LOVELL: "47.002",
    WIMB: "47.005",
    STG: "68.004",
    KINGS: "99.005",
    DAY1_POS: "475",
    DAY2_POS: "443",
    DAY3_POS: "539",
    DAY4_POS: "402",
    DAY5_POS: "428",
  },
  3126: {
    Index: "3126",
    Init: "CRM",
    NAME: "Asquith",
    full_NAME: "CRM Asquith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "143.017",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "166.013",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "745.072",
    DAILY_T: "73.006",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "47.006",
    CORP: "48.005",
    TIMES: "49.006",
    POW: "71.006",
    DOFC: "44.003",
    LOVELL: "45.002",
    WIMB: "49.006",
    STG: "72.005",
    KINGS: "101.01",
    DAY1_POS: "176",
    DAY2_POS: "152",
    DAY3_POS: "314",
    DAY4_POS: "255",
    DAY5_POS: "269",
  },
  3128: {
    Index: "3128",
    Init: "JC",
    NAME: "Evans",
    full_NAME: "JC Evans",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.016",
    GUNSCORE_DAY2: "139.012",
    GUNSCORE_DAY3: "167.02",
    GUNSCORE_DAY4: "170.017",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "747.079",
    DAILY_T: "73.005",
    DONEGALL: "46.004",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "49.007",
    CORP: "42.003",
    TIMES: "49.008",
    POW: "69.007",
    DOFC: "49.005",
    LOVELL: "49.005",
    WIMB: "50.006",
    STG: "71.006",
    KINGS: "103.014",
    DAY1_POS: "309",
    DAY2_POS: "328",
    DAY3_POS: "176",
    DAY4_POS: "82",
    DAY5_POS: "90",
  },
  3136: {
    Index: "3136",
    Init: "JWA",
    NAME: "Alexander",
    full_NAME: "JWA Alexander",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "7",
    Day_2_BONUS: "5",
    Day_2_Total: "12",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "1",
    Day_4_BONUS: "5",
    Day_4_Total: "6",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "14",
    FINAL_TOTAL: "42",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "148.012",
    GUNSCORE_DAY3: "169.018",
    GUNSCORE_DAY4: "171.02",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "763.085",
    DAILY_T: "73.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "49.005",
    CORP: "50.004",
    TIMES: "50.007",
    POW: "71.006",
    DOFC: "48.005",
    LOVELL: "47.003",
    WIMB: "49.007",
    STG: "75.01",
    KINGS: "103.014",
    DAY1_POS: "100",
    DAY2_POS: "20",
    DAY3_POS: "103",
    DAY4_POS: "49",
    DAY5_POS: "90",
  },
  3137: {
    Index: "3137",
    Init: "JT",
    NAME: "Kennedy",
    full_NAME: "JT Kennedy",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.016",
    GUNSCORE_DAY2: "138.009",
    GUNSCORE_DAY3: "170.018",
    GUNSCORE_DAY4: "165.019",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "734.069",
    DAILY_T: "72.008",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "49.003",
    CORP: "44.002",
    TIMES: "50.005",
    POW: "72.007",
    DOFC: "48.006",
    LOVELL: "46.003",
    WIMB: "45.003",
    STG: "74.013",
    KINGS: "97.007",
    DAY1_POS: "438",
    DAY2_POS: "380",
    DAY3_POS: "72",
    DAY4_POS: "269",
    DAY5_POS: "483",
  },
  3138: {
    Index: "3138",
    Init: "OEJ",
    NAME: "Spencer",
    full_NAME: "OEJ Spencer",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "145.012",
    GUNSCORE_DAY3: "169.014",
    GUNSCORE_DAY4: "170.016",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "754.074",
    DAILY_T: "73.008",
    DONEGALL: "48.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "48.005",
    CORP: "48.004",
    TIMES: "48.004",
    POW: "75.007",
    DOFC: "46.003",
    LOVELL: "48.002",
    WIMB: "50.008",
    STG: "72.006",
    KINGS: "101.012",
    DAY1_POS: "249",
    DAY2_POS: "93",
    DAY3_POS: "120",
    DAY4_POS: "86",
    DAY5_POS: "247",
  },
  3145: {
    Index: "3145",
    Init: "IT",
    NAME: "Matsumoto",
    full_NAME: "IT Matsumoto",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "11",
    Day_5_BONUS: "5",
    Day_5_Total: "16",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "31",
    GUNSCORE_DAY1: "169.015",
    GUNSCORE_DAY2: "138.008",
    GUNSCORE_DAY3: "162.009",
    GUNSCORE_DAY4: "168.014",
    GUNSCORE_DAY5: "105.014",
    GUNSCORE_GA: "742.06",
    DAILY_T: "73.005",
    DONEGALL: "50.008",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "50.005",
    CORP: "41",
    TIMES: "47.003",
    POW: "66.001",
    DOFC: "49.005",
    LOVELL: "47.004",
    WIMB: "46.003",
    STG: "75.007",
    KINGS: "105.014",
    DAY1_POS: "279",
    DAY2_POS: "384",
    DAY3_POS: "395",
    DAY4_POS: "177",
    DAY5_POS: "12",
  },
  3216: {
    Index: "3216",
    Init: "SDJ",
    NAME: "Glanville",
    full_NAME: "SDJ Glanville",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "4",
    Day_4_BONUS: "5",
    Day_4_Total: "9",
    Day_5: "18",
    Day_5_BONUS: "5",
    Day_5_Total: "23",
    GRAND_MULTIPLIER: "12",
    FINAL_TOTAL: "59",
    GUNSCORE_DAY1: "173.021",
    GUNSCORE_DAY2: "144.016",
    GUNSCORE_DAY3: "168.012",
    GUNSCORE_DAY4: "172.02",
    GUNSCORE_DAY5: "105.015",
    GUNSCORE_GA: "762.084",
    DAILY_T: "75.01",
    DONEGALL: "48.005",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "48.005",
    CORP: "47.004",
    TIMES: "50.001",
    POW: "72.008",
    DOFC: "46.003",
    LOVELL: "48.007",
    WIMB: "50.003",
    STG: "74.01",
    KINGS: "105.015",
    DAY1_POS: "63",
    DAY2_POS: "111",
    DAY3_POS: "173",
    DAY4_POS: "32",
    DAY5_POS: "6",
  },
  3301: {
    Index: "3301",
    Init: "NJ",
    NAME: "Atkins",
    full_NAME: "NJ Atkins",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.009",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "165.016",
    GUNSCORE_DAY4: "161.009",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "723.052",
    DAILY_T: "67.001",
    DONEGALL: "48.003",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "48.005",
    CORP: "43.001",
    TIMES: "48.005",
    POW: "70.007",
    DOFC: "47.004",
    LOVELL: "45.001",
    WIMB: "45.003",
    STG: "71.005",
    KINGS: "96.007",
    DAY1_POS: "463",
    DAY2_POS: "397",
    DAY3_POS: "277",
    DAY4_POS: "421",
    DAY5_POS: "512",
  },
  3312: {
    Index: "3312",
    Init: "D",
    NAME: "de Vere",
    full_NAME: "D de Vere",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "142.011",
    GUNSCORE_DAY3: "169.011",
    GUNSCORE_DAY4: "168.02",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "745.065",
    DAILY_T: "71.005",
    DONEGALL: "49.007",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "46.001",
    ALEXANDRA: "49.005",
    CORP: "47.005",
    TIMES: "50.005",
    POW: "71.001",
    DOFC: "48.005",
    LOVELL: "45.004",
    WIMB: "49.007",
    STG: "74.009",
    KINGS: "99.007",
    DAY1_POS: "348",
    DAY2_POS: "225",
    DAY3_POS: "126",
    DAY4_POS: "148",
    DAY5_POS: "413",
  },
  3318: {
    Index: "3318",
    Init: "CJ",
    NAME: "Mitchell",
    full_NAME: "CJ Mitchell",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "8",
    Day_2_BONUS: "5",
    Day_2_Total: "13",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "35",
    Day_5_BONUS: "5",
    Day_5_Total: "40",
    GRAND_MULTIPLIER: "33",
    FINAL_TOTAL: "106",
    GUNSCORE_DAY1: "173.024",
    GUNSCORE_DAY2: "148.016",
    GUNSCORE_DAY3: "169.019",
    GUNSCORE_DAY4: "171.018",
    GUNSCORE_DAY5: "105.017",
    GUNSCORE_GA: "766.094",
    DAILY_T: "75.013",
    DONEGALL: "50.008",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "50.006",
    CORP: "49.005",
    TIMES: "50.006",
    POW: "73.007",
    DOFC: "46.006",
    LOVELL: "48.004",
    WIMB: "48.006",
    STG: "75.008",
    KINGS: "105.017",
    DAY1_POS: "53",
    DAY2_POS: "18",
    DAY3_POS: "98",
    DAY4_POS: "54",
    DAY5_POS: "2",
  },
  3320: {
    Index: "3320",
    Init: "G",
    NAME: "Bendik",
    full_NAME: "G Bendik",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.018",
    GUNSCORE_DAY2: "139.009",
    GUNSCORE_DAY3: "166.017",
    GUNSCORE_DAY4: "167.014",
    GUNSCORE_DAY5: "98.013",
    GUNSCORE_GA: "737.071",
    DAILY_T: "74.011",
    DONEGALL: "48.005",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.003",
    CORP: "43.002",
    TIMES: "47.005",
    POW: "71.006",
    DOFC: "48.006",
    LOVELL: "47.003",
    WIMB: "48.004",
    STG: "72.007",
    KINGS: "98.013",
    DAY1_POS: "337",
    DAY2_POS: "346",
    DAY3_POS: "225",
    DAY4_POS: "203",
    DAY5_POS: "433",
  },
  3321: {
    Index: "3321",
    Init: "K",
    NAME: "McCamley",
    full_NAME: "K McCamley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.012",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "159.009",
    GUNSCORE_DAY4: "164.017",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "729.058",
    DAILY_T: "72.007",
    DONEGALL: "44.002",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "48.005",
    CORP: "46.003",
    TIMES: "48.002",
    POW: "67.005",
    DOFC: "44.002",
    LOVELL: "45.004",
    WIMB: "48.004",
    STG: "71.009",
    KINGS: "103.01",
    DAY1_POS: "452",
    DAY2_POS: "338",
    DAY3_POS: "476",
    DAY4_POS: "311",
    DAY5_POS: "138",
  },
  3328: {
    Index: "3328",
    Init: "PB",
    NAME: "Cutts",
    full_NAME: "PB Cutts",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.012",
    GUNSCORE_DAY2: "135.006",
    GUNSCORE_DAY3: "146.009",
    GUNSCORE_DAY4: "162.016",
    GUNSCORE_DAY5: "97.008",
    GUNSCORE_GA: "698.051",
    DAILY_T: "67.006",
    DONEGALL: "44.002",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "46.003",
    CORP: "45.001",
    TIMES: "41.004",
    POW: "65.005",
    DOFC: "40",
    LOVELL: "44.003",
    WIMB: "48.005",
    STG: "70.008",
    KINGS: "97.008",
    DAY1_POS: "539",
    DAY2_POS: "457",
    DAY3_POS: "593",
    DAY4_POS: "385",
    DAY5_POS: "477",
  },
  3357: {
    Index: "3357",
    Init: "RL",
    NAME: "Furniss",
    full_NAME: "RL Furniss",
    Day_1: "35",
    Day_1_BONUS: "15",
    Day_1_Total: "50",
    Day_2: "12",
    Day_2_BONUS: "5",
    Day_2_Total: "17",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "18",
    Day_5_BONUS: "5",
    Day_5_Total: "23",
    GRAND_MULTIPLIER: "22",
    FINAL_TOTAL: "117",
    GUNSCORE_DAY1: "175.027",
    GUNSCORE_DAY2: "148.02",
    GUNSCORE_DAY3: "167.016",
    GUNSCORE_DAY4: "169.02",
    GUNSCORE_DAY5: "105.015",
    GUNSCORE_GA: "764.098",
    DAILY_T: "75.011",
    DONEGALL: "50.009",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.007",
    CORP: "49.005",
    TIMES: "47.002",
    POW: "74.009",
    DOFC: "46.005",
    LOVELL: "47.003",
    WIMB: "50.008",
    STG: "72.009",
    KINGS: "105.015",
    DAY1_POS: "2",
    DAY2_POS: "10",
    DAY3_POS: "190",
    DAY4_POS: "100",
    DAY5_POS: "6",
  },
  3385: {
    Index: "3385",
    Init: "BN",
    NAME: "Craig",
    full_NAME: "BN Craig",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "5",
    Day_5_BONUS: "0",
    Day_5_Total: "5",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "170.019",
    GUNSCORE_DAY2: "139.013",
    GUNSCORE_DAY3: "167.016",
    GUNSCORE_DAY4: "169.019",
    GUNSCORE_DAY5: "104.015",
    GUNSCORE_GA: "749.082",
    DAILY_T: "74.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "47.005",
    CORP: "42.002",
    TIMES: "49.006",
    POW: "71.005",
    DOFC: "47.005",
    LOVELL: "47.003",
    WIMB: "49.003",
    STG: "73.013",
    KINGS: "104.015",
    DAY1_POS: "214",
    DAY2_POS: "324",
    DAY3_POS: "190",
    DAY4_POS: "105",
    DAY5_POS: "27",
  },
  3443: {
    Index: "3443",
    Init: "M",
    NAME: "Rewston",
    full_NAME: "M Rewston",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "165.012",
    GUNSCORE_DAY4: "164.011",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "744.066",
    DAILY_T: "74.01",
    DONEGALL: "48.006",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "50.005",
    CORP: "46.002",
    TIMES: "49.004",
    POW: "71.006",
    DOFC: "45.002",
    LOVELL: "45.001",
    WIMB: "45.004",
    STG: "74.006",
    KINGS: "101.01",
    DAY1_POS: "206",
    DAY2_POS: "127",
    DAY3_POS: "300",
    DAY4_POS: "335",
    DAY5_POS: "269",
  },
  3486: {
    Index: "3486",
    Init: "WJD",
    NAME: "Broad",
    full_NAME: "WJD Broad",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "28",
    GUNSCORE_DAY1: "173.023",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "170.023",
    GUNSCORE_DAY5: "105.012",
    GUNSCORE_GA: "617.075",
    DAILY_T: "73.009",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "50.007",
    POW: "71.006",
    DOFC: "48.004",
    LOVELL: "48.004",
    WIMB: "48.006",
    STG: "74.013",
    KINGS: "105.012",
    DAY1_POS: "54",
    DAY2_POS: "641",
    DAY3_POS: "105",
    DAY4_POS: "59",
    DAY5_POS: "18",
  },
  3495: {
    Index: "3495",
    Init: "MJ",
    NAME: "Creber",
    full_NAME: "MJ Creber",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.022",
    GUNSCORE_DAY2: "145.012",
    GUNSCORE_DAY3: "170.015",
    GUNSCORE_DAY4: "163.013",
    GUNSCORE_DAY5: "102.006",
    GUNSCORE_GA: "751.068",
    DAILY_T: "75.01",
    DONEGALL: "49.008",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.004",
    CORP: "47.003",
    TIMES: "48.004",
    POW: "74.006",
    DOFC: "48.005",
    LOVELL: "49.003",
    WIMB: "46.003",
    STG: "68.007",
    KINGS: "102.006",
    DAY1_POS: "145",
    DAY2_POS: "93",
    DAY3_POS: "82",
    DAY4_POS: "362",
    DAY5_POS: "238",
  },
  3532: {
    Index: "3532",
    Init: "RL",
    NAME: "Sampson",
    full_NAME: "RL Sampson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "137.005",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "101.006",
    GUNSCORE_GA: "739.06",
    DAILY_T: "72.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "44",
    ALEXANDRA: "48.004",
    CORP: "45.001",
    TIMES: "49.004",
    POW: "72.008",
    DOFC: "48.005",
    LOVELL: "43.001",
    WIMB: "48.003",
    STG: "71.008",
    KINGS: "101.006",
    DAY1_POS: "206",
    DAY2_POS: "415",
    DAY3_POS: "105",
    DAY4_POS: "395",
    DAY5_POS: "312",
  },
  3560: {
    Index: "3560",
    Init: "RW",
    NAME: "Shaw",
    full_NAME: "RW Shaw",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.014",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "167.016",
    GUNSCORE_DAY4: "162.014",
    GUNSCORE_DAY5: "101.006",
    GUNSCORE_GA: "741.065",
    DAILY_T: "73.004",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "48.008",
    CORP: "47.005",
    TIMES: "49.005",
    POW: "70.007",
    DOFC: "48.004",
    LOVELL: "45.002",
    WIMB: "47.004",
    STG: "70.008",
    KINGS: "101.006",
    DAY1_POS: "282",
    DAY2_POS: "196",
    DAY3_POS: "190",
    DAY4_POS: "392",
    DAY5_POS: "312",
  },
  3568: {
    Index: "3568",
    Init: "FE",
    NAME: "Purdy",
    full_NAME: "FE Purdy",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.018",
    GUNSCORE_DAY2: "138.008",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "158.009",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "735.059",
    DAILY_T: "72.009",
    DONEGALL: "47.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "46.001",
    CORP: "47.003",
    TIMES: "49.001",
    POW: "72.009",
    DOFC: "47.005",
    LOVELL: "44.002",
    WIMB: "43.003",
    STG: "71.004",
    KINGS: "103.009",
    DAY1_POS: "299",
    DAY2_POS: "384",
    DAY3_POS: "154",
    DAY4_POS: "470",
    DAY5_POS: "147",
  },
  3581: {
    Index: "3581",
    Init: "M",
    NAME: "Liversage",
    full_NAME: "M Liversage",
    Day_1: "1",
    Day_1_BONUS: "5",
    Day_1_Total: "6",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "10",
    Day_3_Total: "10",
    Day_4: "8",
    Day_4_BONUS: "10",
    Day_4_Total: "18",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "7",
    FINAL_TOTAL: "54",
    GUNSCORE_DAY1: "173.025",
    GUNSCORE_DAY2: "139.013",
    GUNSCORE_DAY3: "170.018",
    GUNSCORE_DAY4: "173.022",
    GUNSCORE_DAY5: "105.013",
    GUNSCORE_GA: "760.091",
    DAILY_T: "75.013",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "48.004",
    CORP: "42.002",
    TIMES: "50.007",
    POW: "70.006",
    DOFC: "50.005",
    LOVELL: "48.006",
    WIMB: "50.005",
    STG: "75.011",
    KINGS: "105.013",
    DAY1_POS: "50",
    DAY2_POS: "324",
    DAY3_POS: "72",
    DAY4_POS: "16",
    DAY5_POS: "16",
  },
  3690: {
    Index: "3690",
    Init: "C",
    NAME: "Halleran",
    full_NAME: "C Halleran",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "8",
    GUNSCORE_DAY1: "170.024",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "169.015",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "754.083",
    DAILY_T: "73.01",
    DONEGALL: "48.007",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.003",
    CORP: "43.002",
    TIMES: "49.006",
    POW: "73.006",
    DOFC: "47.003",
    LOVELL: "47.005",
    WIMB: "50.004",
    STG: "73.011",
    KINGS: "104.013",
    DAY1_POS: "198",
    DAY2_POS: "259",
    DAY3_POS: "116",
    DAY4_POS: "66",
    DAY5_POS: "41",
  },
  3707: {
    Index: "3707",
    Init: "ORW",
    NAME: "Russell",
    full_NAME: "ORW Russell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.017",
    GUNSCORE_DAY2: "141.01",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "164.016",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "737.065",
    DAILY_T: "72.009",
    DONEGALL: "48.005",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "48.005",
    CORP: "44",
    TIMES: "48.005",
    POW: "74.006",
    DOFC: "46.003",
    LOVELL: "45.002",
    WIMB: "47.005",
    STG: "72.009",
    KINGS: "99.008",
    DAY1_POS: "403",
    DAY2_POS: "270",
    DAY3_POS: "164",
    DAY4_POS: "313",
    DAY5_POS: "402",
  },
  3711: {
    Index: "3711",
    Init: "ITR",
    NAME: "Jobling",
    full_NAME: "ITR Jobling",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "0",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  3720: {
    Index: "3720",
    Init: "NM",
    NAME: "Duncan",
    full_NAME: "NM Duncan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.011",
    GUNSCORE_DAY2: "132.005",
    GUNSCORE_DAY3: "166.017",
    GUNSCORE_DAY4: "166.02",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "735.067",
    DAILY_T: "74.007",
    DONEGALL: "46",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "44.002",
    CORP: "42",
    TIMES: "43.001",
    POW: "73.009",
    DOFC: "50.007",
    LOVELL: "46.004",
    WIMB: "47.005",
    STG: "73.011",
    KINGS: "103.014",
    DAY1_POS: "328",
    DAY2_POS: "516",
    DAY3_POS: "225",
    DAY4_POS: "219",
    DAY5_POS: "90",
  },
  3743: {
    Index: "3743",
    Init: "CR",
    NAME: "hirst",
    full_NAME: "CR hirst",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "168.012",
    GUNSCORE_DAY2: "138.009",
    GUNSCORE_DAY3: "167.015",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "745.067",
    DAILY_T: "69.002",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "48.004",
    CORP: "44.003",
    TIMES: "48.003",
    POW: "72.007",
    DOFC: "47.005",
    LOVELL: "47.005",
    WIMB: "47.003",
    STG: "75.01",
    KINGS: "103.013",
    DAY1_POS: "325",
    DAY2_POS: "380",
    DAY3_POS: "195",
    DAY4_POS: "110",
    DAY5_POS: "97",
  },
  3781: {
    Index: "3781",
    Init: "S",
    NAME: "Ponter",
    full_NAME: "S Ponter",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "5",
    Day_5_BONUS: "0",
    Day_5_Total: "5",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "135.005",
    GUNSCORE_DAY3: "161.008",
    GUNSCORE_DAY4: "163.013",
    GUNSCORE_DAY5: "104.015",
    GUNSCORE_GA: "732.061",
    DAILY_T: "71.007",
    DONEGALL: "48.007",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "45.002",
    CORP: "42.001",
    TIMES: "49.004",
    POW: "70.003",
    DOFC: "42.001",
    LOVELL: "45.003",
    WIMB: "49.004",
    STG: "69.006",
    KINGS: "104.015",
    DAY1_POS: "249",
    DAY2_POS: "459",
    DAY3_POS: "432",
    DAY4_POS: "362",
    DAY5_POS: "27",
  },
  3783: {
    Index: "3783",
    Init: "CE",
    NAME: "Choquette",
    full_NAME: "CE Choquette",
    Day_1: "18",
    Day_1_BONUS: "15",
    Day_1_Total: "33",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "3",
    Day_3_BONUS: "0",
    Day_3_Total: "3",
    Day_4: "14",
    Day_4_BONUS: "10",
    Day_4_Total: "24",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "40",
    FINAL_TOTAL: "109",
    GUNSCORE_DAY1: "175.025",
    GUNSCORE_DAY2: "143.02",
    GUNSCORE_DAY3: "171.019",
    GUNSCORE_DAY4: "174.021",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "767.099",
    DAILY_T: "75.011",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "50.009",
    CORP: "44.003",
    TIMES: "49.005",
    POW: "73.007",
    DOFC: "49.007",
    LOVELL: "50.005",
    WIMB: "49.007",
    STG: "75.009",
    KINGS: "104.014",
    DAY1_POS: "6",
    DAY2_POS: "148",
    DAY3_POS: "39",
    DAY4_POS: "8",
    DAY5_POS: "31",
  },
  3802: {
    Index: "3802",
    Init: "KE",
    NAME: "Heath",
    full_NAME: "KE Heath",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "159.015",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "730.062",
    DAILY_T: "71.006",
    DONEGALL: "48.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.006",
    CORP: "42.002",
    TIMES: "48.002",
    POW: "74.011",
    DOFC: "46.002",
    LOVELL: "39.001",
    WIMB: "49.004",
    STG: "71.01",
    KINGS: "98.008",
    DAY1_POS: "425",
    DAY2_POS: "298",
    DAY3_POS: "154",
    DAY4_POS: "447",
    DAY5_POS: "446",
  },
  3868: {
    Index: "3868",
    Init: "HA",
    NAME: "Cook",
    full_NAME: "HA Cook",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "11",
    Day_5_BONUS: "5",
    Day_5_Total: "16",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "31",
    GUNSCORE_DAY1: "173.021",
    GUNSCORE_DAY2: "142.017",
    GUNSCORE_DAY3: "162.016",
    GUNSCORE_DAY4: "160.017",
    GUNSCORE_DAY5: "105.014",
    GUNSCORE_GA: "742.085",
    DAILY_T: "75.01",
    DONEGALL: "48.006",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.006",
    CORP: "47.006",
    TIMES: "48.007",
    POW: "73.008",
    DOFC: "41.001",
    LOVELL: "48.004",
    WIMB: "37.004",
    STG: "75.009",
    KINGS: "105.014",
    DAY1_POS: "63",
    DAY2_POS: "191",
    DAY3_POS: "367",
    DAY4_POS: "428",
    DAY5_POS: "12",
  },
  3873: {
    Index: "3873",
    Init: "PS",
    NAME: "Holmes",
    full_NAME: "PS Holmes",
    Day_1: "3",
    Day_1_BONUS: "10",
    Day_1_Total: "13",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "174.02",
    GUNSCORE_DAY2: "141.015",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "750.081",
    DAILY_T: "74.009",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.005",
    CORP: "43.003",
    TIMES: "47.004",
    POW: "71.007",
    DOFC: "48.005",
    LOVELL: "48.008",
    WIMB: "47.004",
    STG: "73.007",
    KINGS: "101.011",
    DAY1_POS: "42",
    DAY2_POS: "238",
    DAY3_POS: "231",
    DAY4_POS: "151",
    DAY5_POS: "254",
  },
  3906: {
    Index: "3906",
    Init: "TJG",
    NAME: "Hendriks",
    full_NAME: "TJG Hendriks",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.017",
    GUNSCORE_DAY2: "131.009",
    GUNSCORE_DAY3: "158.011",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "721.061",
    DAILY_T: "69.004",
    DONEGALL: "48.007",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.003",
    CORP: "37.002",
    TIMES: "46.004",
    POW: "69.005",
    DOFC: "43.002",
    LOVELL: "43.003",
    WIMB: "49.005",
    STG: "72.007",
    KINGS: "102.009",
    DAY1_POS: "375",
    DAY2_POS: "520",
    DAY3_POS: "488",
    DAY4_POS: "316",
    DAY5_POS: "216",
  },
  3907: {
    Index: "3907",
    Init: "C",
    NAME: "Freeman",
    full_NAME: "C Freeman",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.012",
    GUNSCORE_DAY2: "133.008",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "155.008",
    GUNSCORE_DAY5: "99.011",
    GUNSCORE_GA: "710.051",
    DAILY_T: "69.006",
    DONEGALL: "44.004",
    CONAN_DOYLE: "49.002",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "46.005",
    CORP: "43.002",
    TIMES: "48.003",
    POW: "68.006",
    DOFC: "45.003",
    LOVELL: "42.003",
    WIMB: "45.003",
    STG: "68.002",
    KINGS: "99.011",
    DAY1_POS: "500",
    DAY2_POS: "491",
    DAY3_POS: "418",
    DAY4_POS: "518",
    DAY5_POS: "383",
  },
  3919: {
    Index: "3919",
    Init: "CA",
    NAME: "Evans",
    full_NAME: "CA Evans",
    Day_1: "50",
    Day_1_BONUS: "15",
    Day_1_Total: "65",
    Day_2: "11",
    Day_2_BONUS: "10",
    Day_2_Total: "21",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "9",
    Day_4_BONUS: "5",
    Day_4_Total: "14",
    Day_5: "18",
    Day_5_BONUS: "5",
    Day_5_Total: "23",
    GRAND_MULTIPLIER: "150",
    FINAL_TOTAL: "273",
    GUNSCORE_DAY1: "175.032",
    GUNSCORE_DAY2: "148.019",
    GUNSCORE_DAY3: "170.021",
    GUNSCORE_DAY4: "173.023",
    GUNSCORE_DAY5: "105.015",
    GUNSCORE_GA: "771.11",
    DAILY_T: "75.014",
    DONEGALL: "50.009",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "50.009",
    CORP: "48.005",
    TIMES: "49.006",
    POW: "73.011",
    DOFC: "48.004",
    LOVELL: "49.006",
    WIMB: "50.006",
    STG: "74.011",
    KINGS: "105.015",
    DAY1_POS: "1",
    DAY2_POS: "11",
    DAY3_POS: "57",
    DAY4_POS: "15",
    DAY5_POS: "6",
  },
  3957: {
    Index: "3957",
    Init: "K",
    NAME: "Gladding",
    full_NAME: "K Gladding",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "132.005",
    GUNSCORE_DAY3: "150.005",
    GUNSCORE_DAY4: "157.01",
    GUNSCORE_DAY5: "95.006",
    GUNSCORE_GA: "697.038",
    DAILY_T: "73.007",
    DONEGALL: "46.003",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "49.003",
    CORP: "37",
    TIMES: "45.002",
    POW: "63.002",
    DOFC: "42.001",
    LOVELL: "45.001",
    WIMB: "40.001",
    STG: "72.008",
    KINGS: "95.006",
    DAY1_POS: "475",
    DAY2_POS: "516",
    DAY3_POS: "581",
    DAY4_POS: "495",
    DAY5_POS: "543",
  },
  3959: {
    Index: "3959",
    Init: "HJM",
    NAME: "Awin",
    full_NAME: "HJM Awin",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "11",
    Day_3_BONUS: "5",
    Day_3_Total: "16",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "16",
    GUNSCORE_DAY1: "169.014",
    GUNSCORE_DAY2: "137.008",
    GUNSCORE_DAY3: "173.017",
    GUNSCORE_DAY4: "160.012",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "741.064",
    DAILY_T: "72.006",
    DONEGALL: "48.005",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "47.005",
    CORP: "46.002",
    TIMES: "50.004",
    POW: "74.008",
    DOFC: "49.005",
    LOVELL: "43.003",
    WIMB: "45.004",
    STG: "72.005",
    KINGS: "102.013",
    DAY1_POS: "282",
    DAY2_POS: "411",
    DAY3_POS: "11",
    DAY4_POS: "434",
    DAY5_POS: "173",
  },
  3962: {
    Index: "3962",
    Init: "JR",
    NAME: "Allum",
    full_NAME: "JR Allum",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "166.015",
    GUNSCORE_DAY2: "144.016",
    GUNSCORE_DAY3: "163.016",
    GUNSCORE_DAY4: "167.017",
    GUNSCORE_DAY5: "98.01",
    GUNSCORE_GA: "738.074",
    DAILY_T: "74.006",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.007",
    CORP: "44.002",
    TIMES: "48.005",
    POW: "66.004",
    DOFC: "49.007",
    LOVELL: "48.006",
    WIMB: "47.004",
    STG: "72.007",
    KINGS: "98.01",
    DAY1_POS: "384",
    DAY2_POS: "111",
    DAY3_POS: "338",
    DAY4_POS: "194",
    DAY5_POS: "436",
  },
  4031: {
    Index: "4031",
    Init: "AC",
    NAME: "Morley",
    full_NAME: "AC Morley",
    Day_1: "3",
    Day_1_BONUS: "10",
    Day_1_Total: "13",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "174.021",
    GUNSCORE_DAY2: "141.014",
    GUNSCORE_DAY3: "166.021",
    GUNSCORE_DAY4: "167.015",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "745.078",
    DAILY_T: "75.01",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "45.006",
    CORP: "47.004",
    TIMES: "49.007",
    POW: "71.01",
    DOFC: "46.004",
    LOVELL: "46.004",
    WIMB: "50.005",
    STG: "71.006",
    KINGS: "97.007",
    DAY1_POS: "38",
    DAY2_POS: "240",
    DAY3_POS: "215",
    DAY4_POS: "200",
    DAY5_POS: "483",
  },
  4046: {
    Index: "4046",
    Init: "RA",
    NAME: "Webb",
    full_NAME: "RA Webb",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "3",
    GUNSCORE_DAY1: "168.021",
    GUNSCORE_DAY2: "142.01",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "165.016",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "745.076",
    DAILY_T: "71.01",
    DONEGALL: "49.004",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "48.004",
    CORP: "46.004",
    TIMES: "49.006",
    POW: "71.007",
    DOFC: "46.003",
    LOVELL: "46.006",
    WIMB: "47.003",
    STG: "72.007",
    KINGS: "104.013",
    DAY1_POS: "291",
    DAY2_POS: "229",
    DAY3_POS: "231",
    DAY4_POS: "277",
    DAY5_POS: "41",
  },
  4053: {
    Index: "4053",
    Init: "EL",
    NAME: "Sweet",
    full_NAME: "EL Sweet",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.016",
    GUNSCORE_DAY2: "142.014",
    GUNSCORE_DAY3: "165.014",
    GUNSCORE_DAY4: "168.015",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "749.072",
    DAILY_T: "73.006",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.004",
    CORP: "44.003",
    TIMES: "48.006",
    POW: "70.003",
    DOFC: "47.005",
    LOVELL: "47.005",
    WIMB: "49.003",
    STG: "72.007",
    KINGS: "102.013",
    DAY1_POS: "129",
    DAY2_POS: "206",
    DAY3_POS: "289",
    DAY4_POS: "174",
    DAY5_POS: "173",
  },
  4057: {
    Index: "4057",
    Init: "AC",
    NAME: "Abrahams",
    full_NAME: "AC Abrahams",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.015",
    GUNSCORE_DAY2: "143.012",
    GUNSCORE_DAY3: "162.01",
    GUNSCORE_DAY4: "167.021",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "741.069",
    DAILY_T: "73.008",
    DONEGALL: "48.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "48.006",
    CORP: "46.002",
    TIMES: "47.003",
    POW: "69.004",
    DOFC: "46.003",
    LOVELL: "47.006",
    WIMB: "47.007",
    STG: "73.008",
    KINGS: "100.011",
    DAY1_POS: "279",
    DAY2_POS: "178",
    DAY3_POS: "392",
    DAY4_POS: "185",
    DAY5_POS: "323",
  },
  4098: {
    Index: "4098",
    Init: "M",
    NAME: "Cooper",
    full_NAME: "M Cooper",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "4",
    Day_2_BONUS: "10",
    Day_2_Total: "14",
    Day_3: "2",
    Day_3_BONUS: "5",
    Day_3_Total: "7",
    Day_4: "50",
    Day_4_BONUS: "15",
    Day_4_Total: "65",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "35",
    FINAL_TOTAL: "126",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "147.014",
    GUNSCORE_DAY3: "171.016",
    GUNSCORE_DAY4: "175.023",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "767.083",
    DAILY_T: "75.01",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.005",
    CORP: "47.002",
    TIMES: "50.006",
    POW: "74.007",
    DOFC: "47.003",
    LOVELL: "50.006",
    WIMB: "50.006",
    STG: "75.011",
    KINGS: "102.012",
    DAY1_POS: "121",
    DAY2_POS: "36",
    DAY3_POS: "45",
    DAY4_POS: "1",
    DAY5_POS: "180",
  },
  4196: {
    Index: "4196",
    Init: "LG",
    NAME: "Taylor",
    full_NAME: "LG Taylor",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "141.018",
    GUNSCORE_DAY3: "166.009",
    GUNSCORE_DAY4: "168.015",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "748.069",
    DAILY_T: "74.01",
    DONEGALL: "49.002",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "47.007",
    CORP: "46.004",
    TIMES: "50.004",
    POW: "69.003",
    DOFC: "47.002",
    LOVELL: "46.004",
    WIMB: "48.003",
    STG: "74.008",
    KINGS: "103.009",
    DAY1_POS: "217",
    DAY2_POS: "236",
    DAY3_POS: "264",
    DAY4_POS: "174",
    DAY5_POS: "147",
  },
  4285: {
    Index: "4285",
    Init: "SAJ",
    NAME: "Dowley",
    full_NAME: "SAJ Dowley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "143.02",
    GUNSCORE_DAY3: "160.013",
    GUNSCORE_DAY4: "165.015",
    GUNSCORE_DAY5: "103.008",
    GUNSCORE_GA: "740.072",
    DAILY_T: "73.007",
    DONEGALL: "47.005",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "47.006",
    CORP: "47.008",
    TIMES: "48.004",
    POW: "69.007",
    DOFC: "43.002",
    LOVELL: "47.002",
    WIMB: "46.005",
    STG: "72.008",
    KINGS: "103.008",
    DAY1_POS: "271",
    DAY2_POS: "148",
    DAY3_POS: "440",
    DAY4_POS: "282",
    DAY5_POS: "155",
  },
  4291: {
    Index: "4291",
    Init: "TJ",
    NAME: "Bullock",
    full_NAME: "TJ Bullock",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "173.023",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "165.014",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "745.071",
    DAILY_T: "73.009",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.005",
    CORP: "43.002",
    TIMES: "50.005",
    POW: "70.007",
    DOFC: "45.002",
    LOVELL: "46.003",
    WIMB: "46.003",
    STG: "74.008",
    KINGS: "100.008",
    DAY1_POS: "54",
    DAY2_POS: "250",
    DAY3_POS: "289",
    DAY4_POS: "240",
    DAY5_POS: "355",
  },
  4313: {
    Index: "4313",
    Init: "PW",
    NAME: "Glen",
    full_NAME: "PW Glen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "3",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "154.013",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "739.074",
    DAILY_T: "73.008",
    DONEGALL: "48.004",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "45.002",
    CORP: "49.005",
    TIMES: "48.007",
    POW: "73.007",
    DOFC: "49.005",
    LOVELL: "42.002",
    WIMB: "45.004",
    STG: "67.007",
    KINGS: "104.013",
    DAY1_POS: "217",
    DAY2_POS: "259",
    DAY3_POS: "65",
    DAY4_POS: "524",
    DAY5_POS: "41",
  },
  4343: {
    Index: "4343",
    Init: "S",
    NAME: "Livingston",
    full_NAME: "S Livingston",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.022",
    GUNSCORE_DAY2: "141.013",
    GUNSCORE_DAY3: "167.02",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "101.013",
    GUNSCORE_GA: "748.086",
    DAILY_T: "73.008",
    DONEGALL: "49.008",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.004",
    CORP: "46.004",
    TIMES: "48.005",
    POW: "72.01",
    DOFC: "47.005",
    LOVELL: "48.004",
    WIMB: "48.005",
    STG: "73.009",
    KINGS: "101.013",
    DAY1_POS: "201",
    DAY2_POS: "245",
    DAY3_POS: "176",
    DAY4_POS: "110",
    DAY5_POS: "245",
  },
  4394: {
    Index: "4394",
    Init: "JBG",
    NAME: "Shepherd",
    full_NAME: "JBG Shepherd",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.014",
    GUNSCORE_DAY2: "135.01",
    GUNSCORE_DAY3: "159.011",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "729.06",
    DAILY_T: "70.006",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "46.004",
    CORP: "41.002",
    TIMES: "48.004",
    POW: "68.005",
    DOFC: "43.002",
    LOVELL: "47.004",
    WIMB: "48.004",
    STG: "71.007",
    KINGS: "102.01",
    DAY1_POS: "358",
    DAY2_POS: "443",
    DAY3_POS: "467",
    DAY4_POS: "230",
    DAY5_POS: "202",
  },
  4401: {
    Index: "4401",
    Init: "BDW",
    NAME: "Parsons",
    full_NAME: "BDW Parsons",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.015",
    GUNSCORE_DAY2: "142.017",
    GUNSCORE_DAY3: "170.022",
    GUNSCORE_DAY4: "167.009",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "746.073",
    DAILY_T: "70.005",
    DONEGALL: "49.006",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "49.007",
    CORP: "44.003",
    TIMES: "49.007",
    POW: "73.01",
    DOFC: "48.005",
    LOVELL: "44",
    WIMB: "49.003",
    STG: "74.006",
    KINGS: "102.01",
    DAY1_POS: "413",
    DAY2_POS: "191",
    DAY3_POS: "55",
    DAY4_POS: "217",
    DAY5_POS: "202",
  },
  4436: {
    Index: "4436",
    Init: "J",
    NAME: "Fraser",
    full_NAME: "J Fraser",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.004",
    GUNSCORE_DAY2: "129.005",
    GUNSCORE_DAY3: "145.007",
    GUNSCORE_DAY4: "149.006",
    GUNSCORE_DAY5: "93.002",
    GUNSCORE_GA: "664.024",
    DAILY_T: "61.001",
    DONEGALL: "44.002",
    CONAN_DOYLE: "43.001",
    DAILY_MAIL: "42.001",
    ALEXANDRA: "43.002",
    CORP: "44.002",
    TIMES: "42.002",
    POW: "65.003",
    DOFC: "38.002",
    LOVELL: "37.001",
    WIMB: "44.002",
    STG: "68.003",
    KINGS: "93.002",
    DAY1_POS: "613",
    DAY2_POS: "553",
    DAY3_POS: "600",
    DAY4_POS: "565",
    DAY5_POS: "573",
  },
  4441: {
    Index: "4441",
    Init: "S",
    NAME: "Hamano",
    full_NAME: "S Hamano",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "0",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  4486: {
    Index: "4486",
    Init: "BJ",
    NAME: "Le Cheminant",
    full_NAME: "BJ Le Cheminant",
    Day_1: "2",
    Day_1_BONUS: "10",
    Day_1_Total: "12",
    Day_2: "20",
    Day_2_BONUS: "10",
    Day_2_Total: "30",
    Day_3: "4",
    Day_3_BONUS: "0",
    Day_3_Total: "4",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "16",
    FINAL_TOTAL: "67",
    GUNSCORE_DAY1: "173.028",
    GUNSCORE_DAY2: "149.021",
    GUNSCORE_DAY3: "171.023",
    GUNSCORE_DAY4: "170.022",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "763.104",
    DAILY_T: "74.01",
    DONEGALL: "49.008",
    CONAN_DOYLE: "50.01",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.006",
    CORP: "50.007",
    TIMES: "49.008",
    POW: "73.008",
    DOFC: "49.007",
    LOVELL: "46.003",
    WIMB: "50.008",
    STG: "74.011",
    KINGS: "100.01",
    DAY1_POS: "46",
    DAY2_POS: "5",
    DAY3_POS: "32",
    DAY4_POS: "62",
    DAY5_POS: "333",
  },
  4510: {
    Index: "4510",
    Init: "CE",
    NAME: "Holland",
    full_NAME: "CE Holland",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "3",
    Day_4_BONUS: "5",
    Day_4_Total: "8",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "13",
    GUNSCORE_DAY1: "173.021",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "172.017",
    GUNSCORE_DAY5: "104.008",
    GUNSCORE_GA: "755.072",
    DAILY_T: "74.007",
    DONEGALL: "50.007",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "47.003",
    CORP: "44.003",
    TIMES: "48.005",
    POW: "73.009",
    DOFC: "45.001",
    LOVELL: "49.004",
    WIMB: "48.004",
    STG: "75.009",
    KINGS: "104.008",
    DAY1_POS: "63",
    DAY2_POS: "305",
    DAY3_POS: "240",
    DAY4_POS: "38",
    DAY5_POS: "83",
  },
  4536: {
    Index: "4536",
    Init: "TM",
    NAME: "Stevenson",
    full_NAME: "TM Stevenson",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "4",
    Day_2_BONUS: "5",
    Day_2_Total: "9",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "11",
    Day_5_BONUS: "5",
    Day_5_Total: "16",
    GRAND_MULTIPLIER: "12",
    FINAL_TOTAL: "52",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "147.015",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "169.019",
    GUNSCORE_DAY5: "105.014",
    GUNSCORE_GA: "762.086",
    DAILY_T: "73.007",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "48.003",
    CORP: "49.004",
    TIMES: "49.006",
    POW: "74.01",
    DOFC: "45.002",
    LOVELL: "46.002",
    WIMB: "48.007",
    STG: "75.01",
    KINGS: "105.014",
    DAY1_POS: "69",
    DAY2_POS: "32",
    DAY3_POS: "136",
    DAY4_POS: "105",
    DAY5_POS: "12",
  },
  4537: {
    Index: "4537",
    Init: "OJ",
    NAME: "Rolfe",
    full_NAME: "OJ Rolfe",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "144.012",
    GUNSCORE_DAY3: "167.018",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "750.077",
    DAILY_T: "71.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "48.006",
    CORP: "47.003",
    TIMES: "49.005",
    POW: "70.007",
    DOFC: "48.006",
    LOVELL: "46.003",
    WIMB: "49.007",
    STG: "73.009",
    KINGS: "103.013",
    DAY1_POS: "311",
    DAY2_POS: "137",
    DAY3_POS: "181",
    DAY4_POS: "151",
    DAY5_POS: "97",
  },
  4603: {
    Index: "4603",
    Init: "AM",
    NAME: "Sadler",
    full_NAME: "AM Sadler",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "142.013",
    GUNSCORE_DAY3: "165.015",
    GUNSCORE_DAY4: "165.012",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "744.07",
    DAILY_T: "74.01",
    DONEGALL: "49.004",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.004",
    CORP: "45.003",
    TIMES: "45.002",
    POW: "72.009",
    DOFC: "48.004",
    LOVELL: "46.001",
    WIMB: "46.003",
    STG: "73.008",
    KINGS: "101.009",
    DAY1_POS: "149",
    DAY2_POS: "212",
    DAY3_POS: "284",
    DAY4_POS: "301",
    DAY5_POS: "286",
  },
  5004: {
    Index: "5004",
    Init: "AI",
    NAME: "Ashworth",
    full_NAME: "AI Ashworth",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.016",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "167.014",
    GUNSCORE_DAY4: "159.011",
    GUNSCORE_DAY5: "98.015",
    GUNSCORE_GA: "728.066",
    DAILY_T: "71.007",
    DONEGALL: "45.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "49.006",
    CORP: "42.001",
    TIMES: "47.004",
    POW: "72.006",
    DOFC: "48.004",
    LOVELL: "42",
    WIMB: "47.004",
    STG: "70.007",
    KINGS: "98.015",
    DAY1_POS: "408",
    DAY2_POS: "338",
    DAY3_POS: "201",
    DAY4_POS: "453",
    DAY5_POS: "432",
  },
  5071: {
    Index: "5071",
    Init: "NNT",
    NAME: "Kerins",
    full_NAME: "NNT Kerins",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "5",
    Day_3_BONUS: "5",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "20",
    GUNSCORE_DAY1: "172.019",
    GUNSCORE_DAY2: "145.016",
    GUNSCORE_DAY3: "172.016",
    GUNSCORE_DAY4: "167.02",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "756.081",
    DAILY_T: "74.005",
    DONEGALL: "48.007",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "49.006",
    CORP: "49.005",
    TIMES: "50.007",
    POW: "74.006",
    DOFC: "48.003",
    LOVELL: "44.003",
    WIMB: "48.003",
    STG: "75.014",
    KINGS: "100.01",
    DAY1_POS: "116",
    DAY2_POS: "75",
    DAY3_POS: "27",
    DAY4_POS: "186",
    DAY5_POS: "333",
  },
  5129: {
    Index: "5129",
    Init: "DA",
    NAME: "Thompson",
    full_NAME: "DA Thompson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "128.009",
    GUNSCORE_DAY3: "161.014",
    GUNSCORE_DAY4: "157.014",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "713.062",
    DAILY_T: "71.008",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "45.003",
    CORP: "35",
    TIMES: "49.007",
    POW: "70.005",
    DOFC: "42.002",
    LOVELL: "38.001",
    WIMB: "46.004",
    STG: "73.009",
    KINGS: "99.01",
    DAY1_POS: "311",
    DAY2_POS: "562",
    DAY3_POS: "404",
    DAY4_POS: "482",
    DAY5_POS: "388",
  },
  5159: {
    Index: "5159",
    Init: "PA",
    NAME: "Maher",
    full_NAME: "PA Maher",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "164.013",
    GUNSCORE_DAY4: "164.012",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "740.063",
    DAILY_T: "72.007",
    DONEGALL: "50.007",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.004",
    CORP: "46.003",
    TIMES: "48.003",
    POW: "68.006",
    DOFC: "48.004",
    LOVELL: "45.002",
    WIMB: "47.002",
    STG: "72.008",
    KINGS: "101.01",
    DAY1_POS: "176",
    DAY2_POS: "305",
    DAY3_POS: "327",
    DAY4_POS: "329",
    DAY5_POS: "269",
  },
  5160: {
    Index: "5160",
    Init: "BJ",
    NAME: "Wood",
    full_NAME: "BJ Wood",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.011",
    GUNSCORE_DAY2: "141.009",
    GUNSCORE_DAY3: "160.01",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "103.007",
    GUNSCORE_GA: "733.052",
    DAILY_T: "65.003",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "48.003",
    CORP: "45.001",
    TIMES: "46.002",
    POW: "68.005",
    DOFC: "46.003",
    LOVELL: "45.004",
    WIMB: "49.006",
    STG: "72.005",
    KINGS: "103.007",
    DAY1_POS: "483",
    DAY2_POS: "273",
    DAY3_POS: "447",
    DAY4_POS: "230",
    DAY5_POS: "161",
  },
  5169: {
    Index: "5169",
    Init: "DTA",
    NAME: "Cobb",
    full_NAME: "DTA Cobb",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.014",
    GUNSCORE_DAY2: "134.005",
    GUNSCORE_DAY3: "158.012",
    GUNSCORE_DAY4: "161.007",
    GUNSCORE_DAY5: "90.005",
    GUNSCORE_GA: "708.043",
    DAILY_T: "73.008",
    DONEGALL: "46.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "47.001",
    ALEXANDRA: "45.003",
    CORP: "42.001",
    TIMES: "47.004",
    POW: "65.005",
    DOFC: "46.003",
    LOVELL: "45.003",
    WIMB: "46.001",
    STG: "70.003",
    KINGS: "90.005",
    DAY1_POS: "415",
    DAY2_POS: "479",
    DAY3_POS: "486",
    DAY4_POS: "425",
    DAY5_POS: "594",
  },
  5171: {
    Index: "5171",
    Init: "JA",
    NAME: "Ford",
    full_NAME: "JA Ford",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.01",
    GUNSCORE_DAY2: "137.009",
    GUNSCORE_DAY3: "151.009",
    GUNSCORE_DAY4: "165.012",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "718.048",
    DAILY_T: "68.003",
    DONEGALL: "49.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "47.003",
    CORP: "44.002",
    TIMES: "47.005",
    POW: "63.003",
    DOFC: "41.001",
    LOVELL: "45.001",
    WIMB: "47.005",
    STG: "73.006",
    KINGS: "102.008",
    DAY1_POS: "486",
    DAY2_POS: "406",
    DAY3_POS: "571",
    DAY4_POS: "301",
    DAY5_POS: "225",
  },
  5179: {
    Index: "5179",
    Init: "BP",
    NAME: "Creane",
    full_NAME: "BP Creane",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "145.012",
    GUNSCORE_DAY3: "170.018",
    GUNSCORE_DAY4: "168.017",
    GUNSCORE_DAY5: "101.014",
    GUNSCORE_GA: "755.077",
    DAILY_T: "72.008",
    DONEGALL: "49.003",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "50.005",
    CORP: "45.002",
    TIMES: "48.005",
    POW: "74.009",
    DOFC: "48.004",
    LOVELL: "47.003",
    WIMB: "48.007",
    STG: "73.007",
    KINGS: "101.014",
    DAY1_POS: "183",
    DAY2_POS: "93",
    DAY3_POS: "72",
    DAY4_POS: "163",
    DAY5_POS: "241",
  },
  5193: {
    Index: "5193",
    Init: "JME",
    NAME: "Cook",
    full_NAME: "JME Cook",
    Day_1: "13",
    Day_1_BONUS: "15",
    Day_1_Total: "28",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "2",
    Day_3_BONUS: "0",
    Day_3_Total: "2",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "35",
    GUNSCORE_DAY1: "175.024",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "171.016",
    GUNSCORE_DAY4: "168.014",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "760.079",
    DAILY_T: "75.013",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "49.004",
    CORP: "43.004",
    TIMES: "49.003",
    POW: "74.01",
    DOFC: "48.003",
    LOVELL: "47.005",
    WIMB: "47.003",
    STG: "74.006",
    KINGS: "104.01",
    DAY1_POS: "9",
    DAY2_POS: "196",
    DAY3_POS: "45",
    DAY4_POS: "177",
    DAY5_POS: "72",
  },
  5195: {
    Index: "5195",
    Init: "RW",
    NAME: "Ramage",
    full_NAME: "RW Ramage",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "3",
    Day_3_BONUS: "0",
    Day_3_Total: "3",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "3",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "139.012",
    GUNSCORE_DAY3: "171.018",
    GUNSCORE_DAY4: "171.018",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "749.078",
    DAILY_T: "72.007",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "49.006",
    CORP: "43.002",
    TIMES: "49.007",
    POW: "74.007",
    DOFC: "48.004",
    LOVELL: "49.005",
    WIMB: "49.005",
    STG: "73.008",
    KINGS: "99.01",
    DAY1_POS: "249",
    DAY2_POS: "328",
    DAY3_POS: "42",
    DAY4_POS: "54",
    DAY5_POS: "388",
  },
  5214: {
    Index: "5214",
    Init: "RT",
    NAME: "MacLeod",
    full_NAME: "RT MacLeod",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "4",
    Day_2_BONUS: "0",
    Day_2_Total: "4",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "9",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "147.016",
    GUNSCORE_DAY3: "167.018",
    GUNSCORE_DAY4: "168.021",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "755.087",
    DAILY_T: "73.01",
    DONEGALL: "48.005",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.008",
    CORP: "49.003",
    TIMES: "49.007",
    POW: "73.008",
    DOFC: "45.003",
    LOVELL: "44.004",
    WIMB: "50.008",
    STG: "74.009",
    KINGS: "103.012",
    DAY1_POS: "206",
    DAY2_POS: "31",
    DAY3_POS: "181",
    DAY4_POS: "145",
    DAY5_POS: "112",
  },
  5217: {
    Index: "5217",
    Init: "MR",
    NAME: "Horrell",
    full_NAME: "MR Horrell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "170.014",
    GUNSCORE_DAY4: "168.013",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "747.062",
    DAILY_T: "72.008",
    DONEGALL: "49.003",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.005",
    CORP: "44.002",
    TIMES: "48.005",
    POW: "72.003",
    DOFC: "50.006",
    LOVELL: "46.002",
    WIMB: "48.005",
    STG: "74.006",
    KINGS: "100.009",
    DAY1_POS: "311",
    DAY2_POS: "259",
    DAY3_POS: "84",
    DAY4_POS: "182",
    DAY5_POS: "346",
  },
  5228: {
    Index: "5228",
    Init: "EWW",
    NAME: "Feast",
    full_NAME: "EWW Feast",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "145.017",
    GUNSCORE_DAY3: "169.022",
    GUNSCORE_DAY4: "161.017",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "747.086",
    DAILY_T: "72.007",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "48.006",
    CORP: "48.003",
    TIMES: "50.007",
    POW: "72.011",
    DOFC: "47.004",
    LOVELL: "42.003",
    WIMB: "48.007",
    STG: "71.007",
    KINGS: "101.011",
    DAY1_POS: "165",
    DAY2_POS: "70",
    DAY3_POS: "91",
    DAY4_POS: "405",
    DAY5_POS: "254",
  },
  5237: {
    Index: "5237",
    Init: "T",
    NAME: "Johnston",
    full_NAME: "T Johnston",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "144.018",
    GUNSCORE_DAY3: "169.019",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "745.079",
    DAILY_T: "74.009",
    DONEGALL: "47.004",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "49.006",
    CORP: "47.006",
    TIMES: "47.005",
    POW: "73.008",
    DOFC: "49.006",
    LOVELL: "43.001",
    WIMB: "45.001",
    STG: "74.01",
    KINGS: "100.01",
    DAY1_POS: "206",
    DAY2_POS: "104",
    DAY3_POS: "98",
    DAY4_POS: "395",
    DAY5_POS: "333",
  },
  5262: {
    Index: "5262",
    Init: "TP",
    NAME: "Shaw",
    full_NAME: "TP Shaw",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.018",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "169.017",
    GUNSCORE_DAY5: "99.009",
    GUNSCORE_GA: "741.071",
    DAILY_T: "71.007",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "48.006",
    CORP: "43.001",
    TIMES: "48.005",
    POW: "70.005",
    DOFC: "48.005",
    LOVELL: "49.005",
    WIMB: "46.003",
    STG: "74.009",
    KINGS: "99.009",
    DAY1_POS: "337",
    DAY2_POS: "298",
    DAY3_POS: "240",
    DAY4_POS: "120",
    DAY5_POS: "396",
  },
  5281: {
    Index: "5281",
    Init: "EG",
    NAME: "Lawrence",
    full_NAME: "EG Lawrence",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.014",
    GUNSCORE_DAY2: "138.007",
    GUNSCORE_DAY3: "169.014",
    GUNSCORE_DAY4: "167.019",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "741.066",
    DAILY_T: "71.006",
    DONEGALL: "46.005",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "48.003",
    CORP: "46.003",
    TIMES: "48.004",
    POW: "72.007",
    DOFC: "49.003",
    LOVELL: "47.005",
    WIMB: "46.003",
    STG: "74.011",
    KINGS: "103.012",
    DAY1_POS: "443",
    DAY2_POS: "389",
    DAY3_POS: "120",
    DAY4_POS: "191",
    DAY5_POS: "112",
  },
  5300: {
    Index: "5300",
    Init: "RD",
    NAME: "Lindsay",
    full_NAME: "RD Lindsay",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "173.022",
    GUNSCORE_DAY2: "138.01",
    GUNSCORE_DAY3: "163.011",
    GUNSCORE_DAY4: "166.016",
    GUNSCORE_DAY5: "96.005",
    GUNSCORE_GA: "736.064",
    DAILY_T: "75.01",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "48.007",
    CORP: "43.001",
    TIMES: "50.003",
    POW: "70.005",
    DOFC: "43.003",
    LOVELL: "45.001",
    WIMB: "48.005",
    STG: "73.01",
    KINGS: "96.005",
    DAY1_POS: "59",
    DAY2_POS: "377",
    DAY3_POS: "354",
    DAY4_POS: "226",
    DAY5_POS: "527",
  },
  5313: {
    Index: "5313",
    Init: "JL",
    NAME: "Brooking",
    full_NAME: "JL Brooking",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "18",
    Day_3_BONUS: "5",
    Day_3_Total: "23",
    Day_4: "4",
    Day_4_BONUS: "5",
    Day_4_Total: "9",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "11",
    FINAL_TOTAL: "48",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "142.017",
    GUNSCORE_DAY3: "173.022",
    GUNSCORE_DAY4: "172.019",
    GUNSCORE_DAY5: "103.007",
    GUNSCORE_GA: "762.083",
    DAILY_T: "74.009",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "48.006",
    CORP: "44.004",
    TIMES: "50.007",
    POW: "74.009",
    DOFC: "49.006",
    LOVELL: "48.005",
    WIMB: "50.008",
    STG: "74.006",
    KINGS: "103.007",
    DAY1_POS: "121",
    DAY2_POS: "191",
    DAY3_POS: "6",
    DAY4_POS: "35",
    DAY5_POS: "161",
  },
  5326: {
    Index: "5326",
    Init: "WF",
    NAME: "Livesey",
    full_NAME: "WF Livesey",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "174.024",
    GUNSCORE_DAY2: "142.014",
    GUNSCORE_DAY3: "163.014",
    GUNSCORE_DAY4: "169.026",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "748.088",
    DAILY_T: "75.012",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "49.006",
    CORP: "47.004",
    TIMES: "48.006",
    POW: "74.007",
    DOFC: "41.001",
    LOVELL: "47.007",
    WIMB: "49.009",
    STG: "73.01",
    KINGS: "100.01",
    DAY1_POS: "26",
    DAY2_POS: "206",
    DAY3_POS: "342",
    DAY4_POS: "91",
    DAY5_POS: "333",
  },
  5330: {
    Index: "5330",
    Init: "JM",
    NAME: "Baldwin",
    full_NAME: "JM Baldwin",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "142.013",
    GUNSCORE_DAY3: "162.015",
    GUNSCORE_DAY4: "162.016",
    GUNSCORE_DAY5: "97.008",
    GUNSCORE_GA: "734.071",
    DAILY_T: "73.007",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "47.004",
    CORP: "47.003",
    TIMES: "47.005",
    POW: "71.008",
    DOFC: "44.002",
    LOVELL: "45.003",
    WIMB: "45.006",
    STG: "72.007",
    KINGS: "97.008",
    DAY1_POS: "165",
    DAY2_POS: "212",
    DAY3_POS: "371",
    DAY4_POS: "385",
    DAY5_POS: "477",
  },
  5349: {
    Index: "5349",
    Init: "RR",
    NAME: "Bird",
    full_NAME: "RR Bird",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.01",
    GUNSCORE_DAY2: "144.009",
    GUNSCORE_DAY3: "161.01",
    GUNSCORE_DAY4: "158.007",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "730.045",
    DAILY_T: "71.006",
    DONEGALL: "47.001",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "47.002",
    CORP: "47.002",
    TIMES: "47.004",
    POW: "69.005",
    DOFC: "45.001",
    LOVELL: "43",
    WIMB: "45.001",
    STG: "70.006",
    KINGS: "100.009",
    DAY1_POS: "365",
    DAY2_POS: "147",
    DAY3_POS: "429",
    DAY4_POS: "478",
    DAY5_POS: "346",
  },
  5351: {
    Index: "5351",
    Init: "RB",
    NAME: "Fitzsimmons",
    full_NAME: "RB Fitzsimmons",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "9",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "167.013",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "754.077",
    DAILY_T: "75.008",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.006",
    CORP: "45.004",
    TIMES: "48.004",
    POW: "72.008",
    DOFC: "48.003",
    LOVELL: "48.003",
    WIMB: "46.005",
    STG: "73.005",
    KINGS: "104.014",
    DAY1_POS: "69",
    DAY2_POS: "196",
    DAY3_POS: "154",
    DAY4_POS: "208",
    DAY5_POS: "31",
  },
  5361: {
    Index: "5361",
    Init: "LA",
    NAME: "Reid",
    full_NAME: "LA Reid",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "13",
    Day_4_BONUS: "5",
    Day_4_Total: "18",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "23",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "161.014",
    GUNSCORE_DAY4: "173.026",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "747.082",
    DAILY_T: "73.009",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "49.009",
    ALEXANDRA: "47.001",
    CORP: "42.002",
    TIMES: "48.006",
    POW: "69.006",
    DOFC: "44.002",
    LOVELL: "49.006",
    WIMB: "49.008",
    STG: "75.012",
    KINGS: "103.012",
    DAY1_POS: "121",
    DAY2_POS: "367",
    DAY3_POS: "404",
    DAY4_POS: "9",
    DAY5_POS: "112",
  },
  5382: {
    Index: "5382",
    Init: "HG",
    NAME: "Asquith-Beard",
    full_NAME: "HG Asquith-Beard",
    Day_1: "11",
    Day_1_BONUS: "15",
    Day_1_Total: "26",
    Day_2: "10",
    Day_2_BONUS: "10",
    Day_2_Total: "20",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "3",
    Day_4_BONUS: "0",
    Day_4_Total: "3",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "49",
    GUNSCORE_DAY1: "175.023",
    GUNSCORE_DAY2: "148.018",
    GUNSCORE_DAY3: "161.011",
    GUNSCORE_DAY4: "171.025",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "756.086",
    DAILY_T: "75.01",
    DONEGALL: "50.008",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "50.008",
    CORP: "48.002",
    TIMES: "46.003",
    POW: "71.005",
    DOFC: "44.003",
    LOVELL: "49.006",
    WIMB: "49.008",
    STG: "73.011",
    KINGS: "101.009",
    DAY1_POS: "11",
    DAY2_POS: "13",
    DAY3_POS: "425",
    DAY4_POS: "40",
    DAY5_POS: "286",
  },
  5421: {
    Index: "5421",
    Init: "SJ",
    NAME: "Bates",
    full_NAME: "SJ Bates",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.01",
    GUNSCORE_DAY2: "133.013",
    GUNSCORE_DAY3: "159.013",
    GUNSCORE_DAY4: "141.013",
    GUNSCORE_DAY5: "91.006",
    GUNSCORE_GA: "690.055",
    DAILY_T: "71.005",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "46.005",
    CORP: "41.004",
    TIMES: "47.004",
    POW: "69.007",
    DOFC: "43.002",
    LOVELL: "30.001",
    WIMB: "46.004",
    STG: "65.008",
    KINGS: "91.006",
    DAY1_POS: "396",
    DAY2_POS: "483",
    DAY3_POS: "461",
    DAY4_POS: "604",
    DAY5_POS: "590",
  },
  5423: {
    Index: "5423",
    Init: "MR",
    NAME: "Clewer",
    full_NAME: "MR Clewer",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "140.008",
    GUNSCORE_DAY3: "157.007",
    GUNSCORE_DAY4: "164.011",
    GUNSCORE_DAY5: "94.004",
    GUNSCORE_GA: "720.042",
    DAILY_T: "68.002",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "47.003",
    CORP: "44.001",
    TIMES: "44.003",
    POW: "66.003",
    DOFC: "47.001",
    LOVELL: "44.002",
    WIMB: "49.004",
    STG: "71.005",
    KINGS: "94.004",
    DAY1_POS: "425",
    DAY2_POS: "320",
    DAY3_POS: "514",
    DAY4_POS: "335",
    DAY5_POS: "561",
  },
  5453: {
    Index: "5453",
    Init: "KA",
    NAME: "Hinkly",
    full_NAME: "KA Hinkly",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "139.009",
    GUNSCORE_DAY3: "158.009",
    GUNSCORE_DAY4: "157.011",
    GUNSCORE_DAY5: "98.009",
    GUNSCORE_GA: "717.05",
    DAILY_T: "70.001",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "47.002",
    CORP: "43.001",
    TIMES: "49.003",
    POW: "65.003",
    DOFC: "44.003",
    LOVELL: "40.001",
    WIMB: "45.003",
    STG: "72.007",
    KINGS: "98.009",
    DAY1_POS: "425",
    DAY2_POS: "346",
    DAY3_POS: "497",
    DAY4_POS: "489",
    DAY5_POS: "441",
  },
  5456: {
    Index: "5456",
    Init: "JEK",
    NAME: "Pepera-Hibbert",
    full_NAME: "JEK Pepera-Hibbert",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "146.013",
    GUNSCORE_DAY3: "166.014",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "755.07",
    DAILY_T: "74.009",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "49.004",
    CORP: "47.004",
    TIMES: "49.005",
    POW: "72.006",
    DOFC: "45.003",
    LOVELL: "49.002",
    WIMB: "46.004",
    STG: "74.009",
    KINGS: "102.01",
    DAY1_POS: "121",
    DAY2_POS: "59",
    DAY3_POS: "247",
    DAY4_POS: "127",
    DAY5_POS: "202",
  },
  5477: {
    Index: "5477",
    Init: "EGH",
    NAME: "Atkins",
    full_NAME: "EGH Atkins",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.011",
    GUNSCORE_DAY2: "137.007",
    GUNSCORE_DAY3: "155.006",
    GUNSCORE_DAY4: "166.013",
    GUNSCORE_DAY5: "100.005",
    GUNSCORE_GA: "716.042",
    DAILY_T: "67.004",
    DONEGALL: "44.002",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "47.002",
    CORP: "45.003",
    TIMES: "41.001",
    POW: "68.001",
    DOFC: "46.004",
    LOVELL: "47.004",
    WIMB: "49.003",
    STG: "70.006",
    KINGS: "100.005",
    DAY1_POS: "543",
    DAY2_POS: "412",
    DAY3_POS: "537",
    DAY4_POS: "255",
    DAY5_POS: "374",
  },
  5489: {
    Index: "5489",
    Init: "AJ",
    NAME: "Carnell",
    full_NAME: "AJ Carnell",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "20",
    GUNSCORE_DAY1: "170.014",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "169.014",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "750.069",
    DAILY_T: "74.008",
    DONEGALL: "50.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.005",
    CORP: "40.001",
    TIMES: "49.005",
    POW: "72.005",
    DOFC: "47.005",
    LOVELL: "46.004",
    WIMB: "48.004",
    STG: "75.006",
    KINGS: "103.013",
    DAY1_POS: "239",
    DAY2_POS: "288",
    DAY3_POS: "154",
    DAY4_POS: "134",
    DAY5_POS: "97",
  },
  5498: {
    Index: "5498",
    Init: "WC",
    NAME: "Cowen",
    full_NAME: "WC Cowen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.022",
    GUNSCORE_DAY2: "142.017",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "166.017",
    GUNSCORE_DAY5: "103.008",
    GUNSCORE_GA: "751.084",
    DAILY_T: "74.011",
    DONEGALL: "48.006",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "48.005",
    CORP: "46.005",
    TIMES: "48.007",
    POW: "74.01",
    DOFC: "48.003",
    LOVELL: "47.005",
    WIMB: "49.006",
    STG: "70.006",
    KINGS: "103.008",
    DAY1_POS: "201",
    DAY2_POS: "191",
    DAY3_POS: "58",
    DAY4_POS: "223",
    DAY5_POS: "155",
  },
  5499: {
    Index: "5499",
    Init: "WP",
    NAME: "Cubitt",
    full_NAME: "WP Cubitt",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.017",
    GUNSCORE_DAY2: "138.007",
    GUNSCORE_DAY3: "164.016",
    GUNSCORE_DAY4: "167.014",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "737.066",
    DAILY_T: "72.006",
    DONEGALL: "47.005",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "48.001",
    CORP: "44.001",
    TIMES: "50.007",
    POW: "71.007",
    DOFC: "43.002",
    LOVELL: "46.003",
    WIMB: "48.003",
    STG: "73.008",
    KINGS: "102.012",
    DAY1_POS: "375",
    DAY2_POS: "389",
    DAY3_POS: "311",
    DAY4_POS: "203",
    DAY5_POS: "180",
  },
  5550: {
    Index: "5550",
    Init: "MDE",
    NAME: "Larcombe",
    full_NAME: "MDE Larcombe",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.021",
    GUNSCORE_DAY2: "136.013",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "159.014",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "733.076",
    DAILY_T: "72.012",
    DONEGALL: "47.006",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "45.004",
    CORP: "43.004",
    TIMES: "48.005",
    POW: "72.004",
    DOFC: "48.006",
    LOVELL: "39",
    WIMB: "46.004",
    STG: "74.01",
    KINGS: "102.013",
    DAY1_POS: "291",
    DAY2_POS: "417",
    DAY3_POS: "154",
    DAY4_POS: "448",
    DAY5_POS: "173",
  },
  5551: {
    Index: "5551",
    Init: "AJ",
    NAME: "Le Cheminant",
    full_NAME: "AJ Le Cheminant",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.014",
    GUNSCORE_DAY2: "141.01",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "156.01",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "724.054",
    DAILY_T: "70.007",
    DONEGALL: "49.003",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "48.006",
    CORP: "45.002",
    TIMES: "46.003",
    POW: "70.007",
    DOFC: "45.002",
    LOVELL: "45.002",
    WIMB: "43.001",
    STG: "68.007",
    KINGS: "100.008",
    DAY1_POS: "386",
    DAY2_POS: "270",
    DAY3_POS: "418",
    DAY4_POS: "506",
    DAY5_POS: "355",
  },
  5556: {
    Index: "5556",
    Init: "TO",
    NAME: "Little",
    full_NAME: "TO Little",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.023",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "170.018",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "750.079",
    DAILY_T: "74.009",
    DONEGALL: "45.005",
    CONAN_DOYLE: "49.009",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "49.006",
    CORP: "48.004",
    TIMES: "48.004",
    POW: "72.01",
    DOFC: "50.004",
    LOVELL: "47.003",
    WIMB: "47.004",
    STG: "72.007",
    KINGS: "102.01",
    DAY1_POS: "290",
    DAY2_POS: "120",
    DAY3_POS: "72",
    DAY4_POS: "240",
    DAY5_POS: "202",
  },
  5590: {
    Index: "5590",
    Init: "EA",
    NAME: "Sewell",
    full_NAME: "EA Sewell",
    Day_1: "5",
    Day_1_BONUS: "10",
    Day_1_Total: "15",
    Day_2: "14",
    Day_2_BONUS: "10",
    Day_2_Total: "24",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "10",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "8",
    FINAL_TOTAL: "57",
    GUNSCORE_DAY1: "174.023",
    GUNSCORE_DAY2: "149.014",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "168.01",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "761.073",
    DAILY_T: "75.01",
    DONEGALL: "50.008",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "50.003",
    ALEXANDRA: "50.008",
    CORP: "49.003",
    TIMES: "49.005",
    POW: "71.006",
    DOFC: "48.005",
    LOVELL: "43.002",
    WIMB: "50.002",
    STG: "75.006",
    KINGS: "102.01",
    DAY1_POS: "29",
    DAY2_POS: "8",
    DAY3_POS: "147",
    DAY4_POS: "183",
    DAY5_POS: "202",
  },
  5607: {
    Index: "5607",
    Init: "CR",
    NAME: "Vonchek",
    full_NAME: "CR Vonchek",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "167.017",
    GUNSCORE_DAY4: "169.017",
    GUNSCORE_DAY5: "97.011",
    GUNSCORE_GA: "739.07",
    DAILY_T: "72.007",
    DONEGALL: "47.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "47.003",
    CORP: "45.004",
    TIMES: "48.006",
    POW: "70.006",
    DOFC: "49.005",
    LOVELL: "47.004",
    WIMB: "49.007",
    STG: "73.006",
    KINGS: "97.011",
    DAY1_POS: "417",
    DAY2_POS: "250",
    DAY3_POS: "187",
    DAY4_POS: "120",
    DAY5_POS: "472",
  },
  5612: {
    Index: "5612",
    Init: "ML",
    NAME: "Wilkins",
    full_NAME: "ML Wilkins",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "139.007",
    GUNSCORE_DAY3: "162.012",
    GUNSCORE_DAY4: "159.014",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "728.059",
    DAILY_T: "73.007",
    DONEGALL: "49.005",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "47.001",
    CORP: "42",
    TIMES: "47.005",
    POW: "72.005",
    DOFC: "43.002",
    LOVELL: "45.001",
    WIMB: "45.005",
    STG: "69.008",
    KINGS: "99.01",
    DAY1_POS: "271",
    DAY2_POS: "356",
    DAY3_POS: "382",
    DAY4_POS: "448",
    DAY5_POS: "388",
  },
  5613: {
    Index: "5613",
    Init: "SH",
    NAME: "Wingrove",
    full_NAME: "SH Wingrove",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "146.013",
    GUNSCORE_DAY3: "169.015",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "757.079",
    DAILY_T: "73.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "49.006",
    CORP: "47.002",
    TIMES: "50.005",
    POW: "71.006",
    DOFC: "48.004",
    LOVELL: "47.004",
    WIMB: "47.005",
    STG: "74.01",
    KINGS: "103.013",
    DAY1_POS: "165",
    DAY2_POS: "59",
    DAY3_POS: "116",
    DAY4_POS: "151",
    DAY5_POS: "97",
  },
  5617: {
    Index: "5617",
    Init: "CSP",
    NAME: "Gray",
    full_NAME: "CSP Gray",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.014",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "163.011",
    GUNSCORE_DAY4: "162.018",
    GUNSCORE_DAY5: "94.007",
    GUNSCORE_GA: "717.061",
    DAILY_T: "67.003",
    DONEGALL: "45.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "46.003",
    CORP: "44.002",
    TIMES: "48.002",
    POW: "71.007",
    DOFC: "44.002",
    LOVELL: "49.008",
    WIMB: "42.002",
    STG: "71.008",
    KINGS: "94.007",
    DAY1_POS: "506",
    DAY2_POS: "397",
    DAY3_POS: "354",
    DAY4_POS: "383",
    DAY5_POS: "555",
  },
  5634: {
    Index: "5634",
    Init: "AE",
    NAME: "Bromley",
    full_NAME: "AE Bromley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "141.011",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "163.01",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "744.066",
    DAILY_T: "73.01",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.001",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "46.003",
    CORP: "46.002",
    TIMES: "48.004",
    POW: "71.007",
    DOFC: "50.006",
    LOVELL: "41",
    WIMB: "48.004",
    STG: "74.006",
    KINGS: "104.012",
    DAY1_POS: "348",
    DAY2_POS: "259",
    DAY3_POS: "105",
    DAY4_POS: "375",
    DAY5_POS: "53",
  },
  5645: {
    Index: "5645",
    Init: "BZ",
    NAME: "Danziger",
    full_NAME: "BZ Danziger",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "4",
    Day_4_BONUS: "5",
    Day_4_Total: "9",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "14",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "137.007",
    GUNSCORE_DAY3: "165.013",
    GUNSCORE_DAY4: "172.019",
    GUNSCORE_DAY5: "101.006",
    GUNSCORE_GA: "747.063",
    DAILY_T: "74.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "46.001",
    CORP: "41",
    TIMES: "48.005",
    POW: "73.001",
    DOFC: "44.007",
    LOVELL: "48.004",
    WIMB: "49.004",
    STG: "75.011",
    KINGS: "101.006",
    DAY1_POS: "121",
    DAY2_POS: "412",
    DAY3_POS: "294",
    DAY4_POS: "35",
    DAY5_POS: "312",
  },
  5647: {
    Index: "5647",
    Init: "OD",
    NAME: "de Gruchy",
    full_NAME: "OD de Gruchy",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.018",
    GUNSCORE_DAY2: "140.006",
    GUNSCORE_DAY3: "159.009",
    GUNSCORE_DAY4: "168.018",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "738.062",
    DAILY_T: "74.009",
    DONEGALL: "46.003",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "49.004",
    CORP: "44",
    TIMES: "46.001",
    POW: "67.004",
    DOFC: "46.004",
    LOVELL: "46.002",
    WIMB: "47.003",
    STG: "75.013",
    KINGS: "102.011",
    DAY1_POS: "261",
    DAY2_POS: "322",
    DAY3_POS: "476",
    DAY4_POS: "158",
    DAY5_POS: "191",
  },
  5660: {
    Index: "5660",
    Init: "OR",
    NAME: "Farrell",
    full_NAME: "OR Farrell",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "6",
    Day_3_BONUS: "5",
    Day_3_Total: "11",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "18",
    Day_5_BONUS: "5",
    Day_5_Total: "23",
    GRAND_MULTIPLIER: "17",
    FINAL_TOTAL: "61",
    GUNSCORE_DAY1: "172.023",
    GUNSCORE_DAY2: "146.014",
    GUNSCORE_DAY3: "172.018",
    GUNSCORE_DAY4: "169.013",
    GUNSCORE_DAY5: "105.015",
    GUNSCORE_GA: "764.083",
    DAILY_T: "73.011",
    DONEGALL: "49.006",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "50.004",
    CORP: "47.002",
    TIMES: "50.007",
    POW: "73.008",
    DOFC: "49.003",
    LOVELL: "47.004",
    WIMB: "48.004",
    STG: "74.005",
    KINGS: "105.015",
    DAY1_POS: "85",
    DAY2_POS: "57",
    DAY3_POS: "24",
    DAY4_POS: "136",
    DAY5_POS: "6",
  },
  5673: {
    Index: "5673",
    Init: "JM",
    NAME: "Hince",
    full_NAME: "JM Hince",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "13",
    Day_2_BONUS: "15",
    Day_2_Total: "28",
    Day_3: "0",
    Day_3_BONUS: "10",
    Day_3_Total: "10",
    Day_4: "5",
    Day_4_BONUS: "5",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "8",
    FINAL_TOTAL: "61",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "148.022",
    GUNSCORE_DAY3: "168.02",
    GUNSCORE_DAY4: "172.025",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "760.099",
    DAILY_T: "74.008",
    DONEGALL: "50.009",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "50.01",
    ALEXANDRA: "50.007",
    CORP: "48.005",
    TIMES: "50.01",
    POW: "72.007",
    DOFC: "46.003",
    LOVELL: "48.005",
    WIMB: "50.008",
    STG: "74.012",
    KINGS: "102.012",
    DAY1_POS: "206",
    DAY2_POS: "9",
    DAY3_POS: "129",
    DAY4_POS: "26",
    DAY5_POS: "180",
  },
  5677: {
    Index: "5677",
    Init: "OM",
    NAME: "Hudson",
    full_NAME: "OM Hudson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.019",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "165.011",
    GUNSCORE_DAY5: "103.008",
    GUNSCORE_GA: "740.062",
    DAILY_T: "75.009",
    DONEGALL: "48.005",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "46.003",
    CORP: "46.003",
    TIMES: "48.003",
    POW: "70.007",
    DOFC: "43.002",
    LOVELL: "42",
    WIMB: "49.004",
    STG: "74.007",
    KINGS: "103.008",
    DAY1_POS: "214",
    DAY2_POS: "250",
    DAY3_POS: "418",
    DAY4_POS: "304",
    DAY5_POS: "155",
  },
  5696: {
    Index: "5696",
    Init: "LT",
    NAME: "Malcic",
    full_NAME: "LT Malcic",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.015",
    GUNSCORE_DAY2: "139.006",
    GUNSCORE_DAY3: "165.017",
    GUNSCORE_DAY4: "168.019",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "743.068",
    DAILY_T: "70.007",
    DONEGALL: "50.005",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "49.002",
    ALEXANDRA: "46.003",
    CORP: "44.001",
    TIMES: "49.006",
    POW: "69.005",
    DOFC: "47.006",
    LOVELL: "47.005",
    WIMB: "48.004",
    STG: "73.01",
    KINGS: "102.011",
    DAY1_POS: "279",
    DAY2_POS: "358",
    DAY3_POS: "272",
    DAY4_POS: "151",
    DAY5_POS: "191",
  },
  5719: {
    Index: "5719",
    Init: "JJ",
    NAME: "Parsons",
    full_NAME: "JJ Parsons",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.018",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "163.007",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "737.058",
    DAILY_T: "72.008",
    DONEGALL: "49.004",
    CONAN_DOYLE: "46.006",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "50.005",
    CORP: "45.002",
    TIMES: "47.002",
    POW: "70.004",
    DOFC: "46.001",
    LOVELL: "44",
    WIMB: "45.004",
    STG: "73.008",
    KINGS: "101.008",
    DAY1_POS: "337",
    DAY2_POS: "127",
    DAY3_POS: "364",
    DAY4_POS: "395",
    DAY5_POS: "295",
  },
  5721: {
    Index: "5721",
    Init: "H",
    NAME: "Percival",
    full_NAME: "H Percival",
    Day_1: "1",
    Day_1_BONUS: "5",
    Day_1_Total: "6",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "6",
    GUNSCORE_DAY1: "173.025",
    GUNSCORE_DAY2: "136.009",
    GUNSCORE_DAY3: "164.014",
    GUNSCORE_DAY4: "163.015",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "738.072",
    DAILY_T: "74.01",
    DONEGALL: "50.009",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "45.001",
    ALEXANDRA: "47.007",
    CORP: "44.001",
    TIMES: "47.004",
    POW: "69.006",
    DOFC: "48.004",
    LOVELL: "41.003",
    WIMB: "48.003",
    STG: "74.009",
    KINGS: "102.009",
    DAY1_POS: "50",
    DAY2_POS: "423",
    DAY3_POS: "321",
    DAY4_POS: "349",
    DAY5_POS: "216",
  },
  5755: {
    Index: "5755",
    Init: "HEJ",
    NAME: "Welch",
    full_NAME: "HEJ Welch",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.016",
    GUNSCORE_DAY2: "135.009",
    GUNSCORE_DAY3: "167.01",
    GUNSCORE_DAY4: "167.014",
    GUNSCORE_DAY5: "98.005",
    GUNSCORE_GA: "733.054",
    DAILY_T: "73.009",
    DONEGALL: "48.004",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "45.002",
    CORP: "43.003",
    TIMES: "50.004",
    POW: "74.005",
    DOFC: "43.001",
    LOVELL: "49.003",
    WIMB: "49.004",
    STG: "69.007",
    KINGS: "98.005",
    DAY1_POS: "381",
    DAY2_POS: "449",
    DAY3_POS: "214",
    DAY4_POS: "203",
    DAY5_POS: "465",
  },
  5777: {
    Index: "5777",
    Init: "GPC",
    NAME: "Bramwell",
    full_NAME: "GPC Bramwell",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "2",
    Day_3_BONUS: "5",
    Day_3_Total: "7",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "22",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "136.007",
    GUNSCORE_DAY3: "171.016",
    GUNSCORE_DAY4: "170.018",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "749.072",
    DAILY_T: "71.007",
    DONEGALL: "50.006",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.002",
    ALEXANDRA: "49.005",
    CORP: "38",
    TIMES: "50.006",
    POW: "73.007",
    DOFC: "48.003",
    LOVELL: "50.008",
    WIMB: "48.005",
    STG: "72.005",
    KINGS: "101.01",
    DAY1_POS: "149",
    DAY2_POS: "430",
    DAY3_POS: "45",
    DAY4_POS: "78",
    DAY5_POS: "269",
  },
  5791: {
    Index: "5791",
    Init: "LR",
    NAME: "Coventry",
    full_NAME: "LR Coventry",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.012",
    GUNSCORE_DAY2: "135.005",
    GUNSCORE_DAY3: "163.01",
    GUNSCORE_DAY4: "156.009",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "717.044",
    DAILY_T: "72.006",
    DONEGALL: "47.003",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "42.002",
    ALEXANDRA: "50.002",
    CORP: "43.001",
    TIMES: "46.002",
    POW: "68.002",
    DOFC: "49.006",
    LOVELL: "46.004",
    WIMB: "45.002",
    STG: "65.003",
    KINGS: "99.008",
    DAY1_POS: "452",
    DAY2_POS: "459",
    DAY3_POS: "359",
    DAY4_POS: "508",
    DAY5_POS: "402",
  },
  5793: {
    Index: "5793",
    Init: "T",
    NAME: "Cubitt",
    full_NAME: "T Cubitt",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "28",
    Day_3_BONUS: "10",
    Day_3_Total: "38",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "50",
    Day_5_BONUS: "5",
    Day_5_Total: "55",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "98",
    GUNSCORE_DAY1: "168.017",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "174.02",
    GUNSCORE_DAY4: "170.022",
    GUNSCORE_DAY5: "105.019",
    GUNSCORE_GA: "759.09",
    DAILY_T: "73.005",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "49.003",
    CORP: "46.004",
    TIMES: "50.006",
    POW: "75.007",
    DOFC: "49.007",
    LOVELL: "48.004",
    WIMB: "47.005",
    STG: "75.013",
    KINGS: "105.019",
    DAY1_POS: "304",
    DAY2_POS: "216",
    DAY3_POS: "3",
    DAY4_POS: "62",
    DAY5_POS: "1",
  },
  5794: {
    Index: "5794",
    Init: "CH",
    NAME: "Cuming",
    full_NAME: "CH Cuming",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.02",
    GUNSCORE_DAY2: "141.014",
    GUNSCORE_DAY3: "165.014",
    GUNSCORE_DAY4: "169.021",
    GUNSCORE_DAY5: "103.015",
    GUNSCORE_GA: "749.084",
    DAILY_T: "75.007",
    DONEGALL: "49.006",
    CONAN_DOYLE: "47.007",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "49.005",
    CORP: "42.003",
    TIMES: "48.006",
    POW: "72.006",
    DOFC: "45.002",
    LOVELL: "47.006",
    WIMB: "47.006",
    STG: "75.009",
    KINGS: "103.015",
    DAY1_POS: "159",
    DAY2_POS: "240",
    DAY3_POS: "289",
    DAY4_POS: "94",
    DAY5_POS: "85",
  },
  5815: {
    Index: "5815",
    Init: "BAM",
    NAME: "Hebblethwaite",
    full_NAME: "BAM Hebblethwaite",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.016",
    GUNSCORE_DAY2: "144.011",
    GUNSCORE_DAY3: "160.012",
    GUNSCORE_DAY4: "156.011",
    GUNSCORE_DAY5: "99.006",
    GUNSCORE_GA: "722.056",
    DAILY_T: "67.005",
    DONEGALL: "47.004",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "49.004",
    CORP: "46.003",
    TIMES: "42.003",
    POW: "71.007",
    DOFC: "47.002",
    LOVELL: "37.001",
    WIMB: "46.003",
    STG: "73.007",
    KINGS: "99.006",
    DAY1_POS: "466",
    DAY2_POS: "140",
    DAY3_POS: "443",
    DAY4_POS: "504",
    DAY5_POS: "425",
  },
  5818: {
    Index: "5818",
    Init: "M",
    NAME: "Horton-Baker",
    full_NAME: "M Horton-Baker",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.019",
    GUNSCORE_DAY2: "144.01",
    GUNSCORE_DAY3: "168.022",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "103.015",
    GUNSCORE_GA: "752.08",
    DAILY_T: "74.008",
    DONEGALL: "48.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "50.005",
    CORP: "47.002",
    TIMES: "49.006",
    POW: "73.01",
    DOFC: "46.006",
    LOVELL: "42.001",
    WIMB: "49.004",
    STG: "75.009",
    KINGS: "103.015",
    DAY1_POS: "165",
    DAY2_POS: "144",
    DAY3_POS: "127",
    DAY4_POS: "240",
    DAY5_POS: "85",
  },
  5833: {
    Index: "5833",
    Init: "JH",
    NAME: "Keates",
    full_NAME: "JH Keates",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "7",
    Day_4_BONUS: "5",
    Day_4_Total: "12",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "12",
    GUNSCORE_DAY1: "171.015",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "164.014",
    GUNSCORE_DAY4: "173.018",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "753.07",
    DAILY_T: "74.008",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "47.004",
    CORP: "45.002",
    TIMES: "44.002",
    POW: "73.009",
    DOFC: "47.003",
    LOVELL: "49.006",
    WIMB: "50.007",
    STG: "74.005",
    KINGS: "104.011",
    DAY1_POS: "191",
    DAY2_POS: "250",
    DAY3_POS: "321",
    DAY4_POS: "21",
    DAY5_POS: "63",
  },
  5850: {
    Index: "5850",
    Init: "LVG",
    NAME: "OFlynn",
    full_NAME: "LVG OFlynn",
    Day_1: "8",
    Day_1_BONUS: "10",
    Day_1_Total: "18",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "6",
    FINAL_TOTAL: "34",
    GUNSCORE_DAY1: "174.027",
    GUNSCORE_DAY2: "145.013",
    GUNSCORE_DAY3: "170.019",
    GUNSCORE_DAY4: "169.02",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "760.09",
    DAILY_T: "74.011",
    DONEGALL: "50.007",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "49.005",
    CORP: "46.003",
    TIMES: "47.004",
    POW: "73.008",
    DOFC: "50.007",
    LOVELL: "47.005",
    WIMB: "48.004",
    STG: "74.011",
    KINGS: "102.011",
    DAY1_POS: "17",
    DAY2_POS: "89",
    DAY3_POS: "65",
    DAY4_POS: "100",
    DAY5_POS: "191",
  },
  5869: {
    Index: "5869",
    Init: "G",
    NAME: "Stewart",
    full_NAME: "G Stewart",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "163.012",
    GUNSCORE_DAY5: "104.01",
    GUNSCORE_GA: "747.07",
    DAILY_T: "73.009",
    DONEGALL: "49.006",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "46.004",
    CORP: "44.002",
    TIMES: "48.005",
    POW: "73.006",
    DOFC: "47.005",
    LOVELL: "44.003",
    WIMB: "47.003",
    STG: "72.006",
    KINGS: "104.01",
    DAY1_POS: "107",
    DAY2_POS: "298",
    DAY3_POS: "147",
    DAY4_POS: "368",
    DAY5_POS: "72",
  },
  5875: {
    Index: "5875",
    Init: "GR",
    NAME: "Thomas",
    full_NAME: "GR Thomas",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.006",
    GUNSCORE_DAY2: "126.005",
    GUNSCORE_DAY3: "152.004",
    GUNSCORE_DAY4: "148.008",
    GUNSCORE_DAY5: "95.006",
    GUNSCORE_GA: "678.029",
    DAILY_T: "70.005",
    DONEGALL: "44.001",
    CONAN_DOYLE: "43",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "42.002",
    CORP: "39.001",
    TIMES: "45.001",
    POW: "67.003",
    DOFC: "40",
    LOVELL: "37.003",
    WIMB: "44",
    STG: "67.005",
    KINGS: "95.006",
    DAY1_POS: "564",
    DAY2_POS: "585",
    DAY3_POS: "569",
    DAY4_POS: "569",
    DAY5_POS: "543",
  },
  5889: {
    Index: "5889",
    Init: "RG",
    NAME: "Woodger",
    full_NAME: "RG Woodger",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "142.014",
    GUNSCORE_DAY3: "159.016",
    GUNSCORE_DAY4: "164.017",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "737.073",
    DAILY_T: "74.01",
    DONEGALL: "49.004",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "48.004",
    CORP: "46.004",
    TIMES: "46.003",
    POW: "66.007",
    DOFC: "47.006",
    LOVELL: "40.002",
    WIMB: "50.005",
    STG: "74.01",
    KINGS: "102.008",
    DAY1_POS: "217",
    DAY2_POS: "206",
    DAY3_POS: "457",
    DAY4_POS: "311",
    DAY5_POS: "225",
  },
  5994: {
    Index: "5994",
    Init: "LJ",
    NAME: "Smith",
    full_NAME: "LJ Smith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.012",
    GUNSCORE_DAY2: "132.009",
    GUNSCORE_DAY3: "159.01",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "94.009",
    GUNSCORE_GA: "716.054",
    DAILY_T: "69.006",
    DONEGALL: "48.003",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "44.003",
    ALEXANDRA: "45.002",
    CORP: "43.004",
    TIMES: "48.004",
    POW: "68.004",
    DOFC: "43.002",
    LOVELL: "47.005",
    WIMB: "45.001",
    STG: "73.008",
    KINGS: "94.009",
    DAY1_POS: "391",
    DAY2_POS: "508",
    DAY3_POS: "471",
    DAY4_POS: "287",
    DAY5_POS: "554",
  },
  6002: {
    Index: "6002",
    Init: "NE",
    NAME: "Barnes",
    full_NAME: "NE Barnes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.012",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "166.012",
    GUNSCORE_DAY4: "160.013",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "733.058",
    DAILY_T: "72.008",
    DONEGALL: "46.003",
    CONAN_DOYLE: "48.001",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "47.004",
    CORP: "43.001",
    TIMES: "46.004",
    POW: "72.006",
    DOFC: "48.002",
    LOVELL: "42.001",
    WIMB: "48.006",
    STG: "70.006",
    KINGS: "103.009",
    DAY1_POS: "391",
    DAY2_POS: "367",
    DAY3_POS: "257",
    DAY4_POS: "431",
    DAY5_POS: "147",
  },
  6016: {
    Index: "6016",
    Init: "H",
    NAME: "Bull",
    full_NAME: "H Bull",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.012",
    GUNSCORE_DAY2: "131.005",
    GUNSCORE_DAY3: "159.006",
    GUNSCORE_DAY4: "157.007",
    GUNSCORE_DAY5: "88.004",
    GUNSCORE_GA: "701.034",
    DAILY_T: "72.008",
    DONEGALL: "49.002",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "46.002",
    CORP: "39",
    TIMES: "41.002",
    POW: "71.001",
    DOFC: "47.003",
    LOVELL: "42.003",
    WIMB: "45.001",
    STG: "70.003",
    KINGS: "88.004",
    DAY1_POS: "391",
    DAY2_POS: "532",
    DAY3_POS: "482",
    DAY4_POS: "501",
    DAY5_POS: "609",
  },
  6031: {
    Index: "6031",
    Init: "TS",
    NAME: "Craig-Fleming",
    full_NAME: "TS Craig-Fleming",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.015",
    GUNSCORE_DAY2: "139.014",
    GUNSCORE_DAY3: "161.008",
    GUNSCORE_DAY4: "164.02",
    GUNSCORE_DAY5: "102.015",
    GUNSCORE_GA: "736.072",
    DAILY_T: "73.005",
    DONEGALL: "50.007",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.006",
    CORP: "42.002",
    TIMES: "49.005",
    POW: "70.003",
    DOFC: "42",
    LOVELL: "45.004",
    WIMB: "48.007",
    STG: "71.009",
    KINGS: "102.015",
    DAY1_POS: "236",
    DAY2_POS: "323",
    DAY3_POS: "432",
    DAY4_POS: "308",
    DAY5_POS: "165",
  },
  6034: {
    Index: "6034",
    Init: "H",
    NAME: "Dodds",
    full_NAME: "H Dodds",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.01",
    GUNSCORE_DAY2: "125.006",
    GUNSCORE_DAY3: "149.006",
    GUNSCORE_DAY4: "152.008",
    GUNSCORE_DAY5: "88.002",
    GUNSCORE_GA: "672.032",
    DAILY_T: "68.003",
    DONEGALL: "48.007",
    CONAN_DOYLE: "42",
    DAILY_MAIL: "46.001",
    ALEXANDRA: "42.005",
    CORP: "37",
    TIMES: "47.003",
    POW: "61.001",
    DOFC: "41.002",
    LOVELL: "43.002",
    WIMB: "43.003",
    STG: "66.003",
    KINGS: "88.002",
    DAY1_POS: "545",
    DAY2_POS: "594",
    DAY3_POS: "587",
    DAY4_POS: "544",
    DAY5_POS: "613",
  },
  6054: {
    Index: "6054",
    Init: "AP",
    NAME: "Good",
    full_NAME: "AP Good",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "10",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "10",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "25",
    GUNSCORE_DAY1: "172.023",
    GUNSCORE_DAY2: "144.015",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "166.019",
    GUNSCORE_DAY5: "99.012",
    GUNSCORE_GA: "750.086",
    DAILY_T: "74.011",
    DONEGALL: "50.008",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.007",
    CORP: "46.002",
    TIMES: "50.01",
    POW: "70.004",
    DOFC: "49.003",
    LOVELL: "41.002",
    WIMB: "50.009",
    STG: "75.008",
    KINGS: "99.012",
    DAY1_POS: "85",
    DAY2_POS: "117",
    DAY3_POS: "105",
    DAY4_POS: "222",
    DAY5_POS: "380",
  },
  6094: {
    Index: "6094",
    Init: "EN",
    NAME: "Munro",
    full_NAME: "EN Munro",
    Day_1: "1",
    Day_1_BONUS: "10",
    Day_1_Total: "11",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "5",
    Day_5_BONUS: "0",
    Day_5_Total: "5",
    GRAND_MULTIPLIER: "13",
    FINAL_TOTAL: "39",
    GUNSCORE_DAY1: "173.025",
    GUNSCORE_DAY2: "145.017",
    GUNSCORE_DAY3: "170.02",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "104.015",
    GUNSCORE_GA: "762.097",
    DAILY_T: "75.014",
    DONEGALL: "50.005",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "50.006",
    CORP: "46.003",
    TIMES: "49.006",
    POW: "73.009",
    DOFC: "48.005",
    LOVELL: "46.005",
    WIMB: "49.003",
    STG: "75.012",
    KINGS: "104.015",
    DAY1_POS: "50",
    DAY2_POS: "70",
    DAY3_POS: "58",
    DAY4_POS: "66",
    DAY5_POS: "27",
  },
  6101: {
    Index: "6101",
    Init: "T",
    NAME: "Pantke",
    full_NAME: "T Pantke",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.011",
    GUNSCORE_DAY2: "125.005",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "150.008",
    GUNSCORE_DAY5: "91.005",
    GUNSCORE_GA: "687.042",
    DAILY_T: "72.004",
    DONEGALL: "43.003",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "45.001",
    ALEXANDRA: "38.001",
    CORP: "42.003",
    TIMES: "48.006",
    POW: "69.004",
    DOFC: "44.003",
    LOVELL: "42.004",
    WIMB: "42.004",
    STG: "66",
    KINGS: "91.005",
    DAY1_POS: "520",
    DAY2_POS: "595",
    DAY3_POS: "410",
    DAY4_POS: "556",
    DAY5_POS: "591",
  },
  6117: {
    Index: "6117",
    Init: "CA",
    NAME: "Shelley",
    full_NAME: "CA Shelley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.011",
    GUNSCORE_DAY2: "121.008",
    GUNSCORE_DAY3: "153.008",
    GUNSCORE_DAY4: "154.01",
    GUNSCORE_DAY5: "87.002",
    GUNSCORE_GA: "672.039",
    DAILY_T: "69.007",
    DONEGALL: "43.002",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "43.004",
    ALEXANDRA: "45.003",
    CORP: "33.001",
    TIMES: "44.003",
    POW: "63.002",
    DOFC: "46.003",
    LOVELL: "44.003",
    WIMB: "46.002",
    STG: "64.005",
    KINGS: "87.002",
    DAY1_POS: "557",
    DAY2_POS: "604",
    DAY3_POS: "558",
    DAY4_POS: "527",
    DAY5_POS: "614",
  },
  6124: {
    Index: "6124",
    Init: "EN",
    NAME: "Smith",
    full_NAME: "EN Smith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "129.006",
    GUNSCORE_DAY3: "111.008",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "240.014",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "46.002",
    CORP: "36.002",
    TIMES: "45.003",
    POW: "66.005",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "550",
    DAY3_POS: "635",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6152: {
    Index: "6152",
    Init: "DH",
    NAME: "Acton",
    full_NAME: "DH Acton",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "10",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.015",
    GUNSCORE_DAY2: "140.01",
    GUNSCORE_DAY3: "171.013",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "750.063",
    DAILY_T: "74.005",
    DONEGALL: "50.006",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "48.002",
    CORP: "46.006",
    TIMES: "50.006",
    POW: "71.005",
    DOFC: "50.002",
    LOVELL: "46.003",
    WIMB: "46.002",
    STG: "73.008",
    KINGS: "103.012",
    DAY1_POS: "191",
    DAY2_POS: "313",
    DAY3_POS: "52",
    DAY4_POS: "294",
    DAY5_POS: "112",
  },
  6162: {
    Index: "6162",
    Init: "AC",
    NAME: "Barrell",
    full_NAME: "AC Barrell",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.019",
    GUNSCORE_DAY2: "131.004",
    GUNSCORE_DAY3: "153.011",
    GUNSCORE_DAY4: "157.01",
    GUNSCORE_DAY5: "95.006",
    GUNSCORE_GA: "698.05",
    DAILY_T: "68.006",
    DONEGALL: "47.006",
    CONAN_DOYLE: "47.007",
    DAILY_MAIL: "45.001",
    ALEXANDRA: "44",
    CORP: "42.003",
    TIMES: "48.004",
    POW: "62.005",
    DOFC: "43.002",
    LOVELL: "41.001",
    WIMB: "45.004",
    STG: "71.005",
    KINGS: "95.006",
    DAY1_POS: "490",
    DAY2_POS: "535",
    DAY3_POS: "554",
    DAY4_POS: "495",
    DAY5_POS: "543",
  },
  6166: {
    Index: "6166",
    Init: "TM",
    NAME: "Booth",
    full_NAME: "TM Booth",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.02",
    GUNSCORE_DAY2: "143.015",
    GUNSCORE_DAY3: "166.014",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "102.007",
    GUNSCORE_GA: "746.07",
    DAILY_T: "70.006",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.008",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "49.003",
    CORP: "48.007",
    TIMES: "48.005",
    POW: "70.006",
    DOFC: "48.003",
    LOVELL: "46.004",
    WIMB: "48.004",
    STG: "72.006",
    KINGS: "102.007",
    DAY1_POS: "249",
    DAY2_POS: "155",
    DAY3_POS: "247",
    DAY4_POS: "240",
    DAY5_POS: "233",
  },
  6172: {
    Index: "6172",
    Init: "JRL",
    NAME: "Burch",
    full_NAME: "JRL Burch",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "3",
    Day_3_BONUS: "5",
    Day_3_Total: "8",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "18",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "171.018",
    GUNSCORE_DAY4: "169.023",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "752.086",
    DAILY_T: "75.01",
    DONEGALL: "49.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "50.008",
    CORP: "42.001",
    TIMES: "50.007",
    POW: "73.008",
    DOFC: "48.003",
    LOVELL: "46.003",
    WIMB: "49.008",
    STG: "74.012",
    KINGS: "102.011",
    DAY1_POS: "91",
    DAY2_POS: "367",
    DAY3_POS: "42",
    DAY4_POS: "92",
    DAY5_POS: "191",
  },
  6188: {
    Index: "6188",
    Init: "D",
    NAME: "Colegate",
    full_NAME: "D Colegate",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.021",
    GUNSCORE_DAY2: "133.008",
    GUNSCORE_DAY3: "163.013",
    GUNSCORE_DAY4: "160.009",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "723.061",
    DAILY_T: "72.009",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "48.003",
    CORP: "40.001",
    TIMES: "48.003",
    POW: "71.008",
    DOFC: "44.002",
    LOVELL: "44.003",
    WIMB: "48.003",
    STG: "68.003",
    KINGS: "100.01",
    DAY1_POS: "331",
    DAY2_POS: "491",
    DAY3_POS: "347",
    DAY4_POS: "441",
    DAY5_POS: "333",
  },
  6189: {
    Index: "6189",
    Init: "B",
    NAME: "Colman-Sadd",
    full_NAME: "B Colman-Sadd",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "7",
    Day_2_BONUS: "0",
    Day_2_Total: "7",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "7",
    GUNSCORE_DAY1: "171.028",
    GUNSCORE_DAY2: "147.02",
    GUNSCORE_DAY3: "167.02",
    GUNSCORE_DAY4: "162.018",
    GUNSCORE_DAY5: "103.015",
    GUNSCORE_GA: "750.101",
    DAILY_T: "73.013",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.008",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.007",
    CORP: "49.007",
    TIMES: "48.006",
    POW: "71.01",
    DOFC: "48.004",
    LOVELL: "43.002",
    WIMB: "48.007",
    STG: "71.009",
    KINGS: "103.015",
    DAY1_POS: "133",
    DAY2_POS: "21",
    DAY3_POS: "176",
    DAY4_POS: "383",
    DAY5_POS: "85",
  },
  6194: {
    Index: "6194",
    Init: "CAJ",
    NAME: "Crosby",
    full_NAME: "CAJ Crosby",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.021",
    GUNSCORE_DAY2: "145.011",
    GUNSCORE_DAY3: "165.014",
    GUNSCORE_DAY4: "169.019",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "749.075",
    DAILY_T: "74.011",
    DONEGALL: "49.006",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "48.004",
    CORP: "48.003",
    TIMES: "47.003",
    POW: "75.009",
    DOFC: "43.002",
    LOVELL: "45.002",
    WIMB: "50.006",
    STG: "74.011",
    KINGS: "101.01",
    DAY1_POS: "248",
    DAY2_POS: "99",
    DAY3_POS: "289",
    DAY4_POS: "105",
    DAY5_POS: "269",
  },
  6195: {
    Index: "6195",
    Init: "CW",
    NAME: "Dale",
    full_NAME: "CW Dale",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.012",
    GUNSCORE_DAY2: "126.005",
    GUNSCORE_DAY3: "158.008",
    GUNSCORE_DAY4: "158.009",
    GUNSCORE_DAY5: "95.004",
    GUNSCORE_GA: "693.038",
    DAILY_T: "65.005",
    DONEGALL: "45.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "42.001",
    ALEXANDRA: "43.001",
    CORP: "41.003",
    TIMES: "47.003",
    POW: "65.002",
    DOFC: "46.003",
    LOVELL: "44.001",
    WIMB: "44.002",
    STG: "70.006",
    KINGS: "95.004",
    DAY1_POS: "566",
    DAY2_POS: "585",
    DAY3_POS: "501",
    DAY4_POS: "470",
    DAY5_POS: "551",
  },
  6199: {
    Index: "6199",
    Init: "RK",
    NAME: "Edwards",
    full_NAME: "RK Edwards",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.013",
    GUNSCORE_DAY2: "139.013",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "166.011",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "744.058",
    DAILY_T: "73.008",
    DONEGALL: "49.003",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "49.008",
    ALEXANDRA: "47.004",
    CORP: "43.001",
    TIMES: "48.004",
    POW: "73.006",
    DOFC: "47.004",
    LOVELL: "48.004",
    WIMB: "46.004",
    STG: "72.003",
    KINGS: "101.007",
    DAY1_POS: "243",
    DAY2_POS: "324",
    DAY3_POS: "164",
    DAY4_POS: "263",
    DAY5_POS: "304",
  },
  6227: {
    Index: "6227",
    Init: "OJ",
    NAME: "Hughes",
    full_NAME: "OJ Hughes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.014",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "157.012",
    GUNSCORE_DAY4: "155.008",
    GUNSCORE_DAY5: "101.006",
    GUNSCORE_GA: "714.051",
    DAILY_T: "69.005",
    DONEGALL: "49.004",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "45.003",
    CORP: "43.002",
    TIMES: "45.002",
    POW: "65.005",
    DOFC: "47.005",
    LOVELL: "42.001",
    WIMB: "45.004",
    STG: "68.003",
    KINGS: "101.006",
    DAY1_POS: "443",
    DAY2_POS: "397",
    DAY3_POS: "508",
    DAY4_POS: "518",
    DAY5_POS: "312",
  },
  6233: {
    Index: "6233",
    Init: "JAMM",
    NAME: "Ingham",
    full_NAME: "JAMM Ingham",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.018",
    GUNSCORE_DAY2: "132.01",
    GUNSCORE_DAY3: "166.02",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "728.07",
    DAILY_T: "72.009",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "45.004",
    CORP: "39.003",
    TIMES: "44.004",
    POW: "73.009",
    DOFC: "49.007",
    LOVELL: "46.005",
    WIMB: "49.004",
    STG: "71.006",
    KINGS: "97.007",
    DAY1_POS: "337",
    DAY2_POS: "505",
    DAY3_POS: "216",
    DAY4_POS: "230",
    DAY5_POS: "483",
  },
  6237: {
    Index: "6237",
    Init: "SL",
    NAME: "Josling",
    full_NAME: "SL Josling",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.009",
    GUNSCORE_DAY2: "133.007",
    GUNSCORE_DAY3: "153.006",
    GUNSCORE_DAY4: "142.008",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "687.038",
    DAILY_T: "74.006",
    DONEGALL: "42.001",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "45.001",
    ALEXANDRA: "45.001",
    CORP: "43.005",
    TIMES: "45.001",
    POW: "65.003",
    DOFC: "43.002",
    LOVELL: "37.001",
    WIMB: "43.003",
    STG: "62.004",
    KINGS: "98.008",
    DAY1_POS: "511",
    DAY2_POS: "497",
    DAY3_POS: "562",
    DAY4_POS: "601",
    DAY5_POS: "446",
  },
  6238: {
    Index: "6238",
    Init: "MJ",
    NAME: "Keel",
    full_NAME: "MJ Keel",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "10",
    Day_2_BONUS: "5",
    Day_2_Total: "15",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "7",
    Day_4_BONUS: "10",
    Day_4_Total: "17",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "17",
    FINAL_TOTAL: "59",
    GUNSCORE_DAY1: "173.021",
    GUNSCORE_DAY2: "148.018",
    GUNSCORE_DAY3: "166.017",
    GUNSCORE_DAY4: "173.018",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "764.086",
    DAILY_T: "75.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "50.007",
    CORP: "49.006",
    TIMES: "50.008",
    POW: "72.007",
    DOFC: "44.002",
    LOVELL: "48.006",
    WIMB: "50.005",
    STG: "75.007",
    KINGS: "104.012",
    DAY1_POS: "63",
    DAY2_POS: "13",
    DAY3_POS: "225",
    DAY4_POS: "21",
    DAY5_POS: "53",
  },
  6251: {
    Index: "6251",
    Init: "AR",
    NAME: "Le Quesne",
    full_NAME: "AR Le Quesne",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "132.009",
    GUNSCORE_DAY3: "163.015",
    GUNSCORE_DAY4: "158.009",
    GUNSCORE_DAY5: "100.007",
    GUNSCORE_GA: "725.06",
    DAILY_T: "74.005",
    DONEGALL: "50.008",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "44.003",
    ALEXANDRA: "47.004",
    CORP: "41.002",
    TIMES: "49.007",
    POW: "71.006",
    DOFC: "43.002",
    LOVELL: "42.001",
    WIMB: "46.002",
    STG: "70.006",
    KINGS: "100.007",
    DAY1_POS: "107",
    DAY2_POS: "508",
    DAY3_POS: "340",
    DAY4_POS: "470",
    DAY5_POS: "366",
  },
  6275: {
    Index: "6275",
    Init: "N",
    NAME: "Murrin",
    full_NAME: "N Murrin",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "142.011",
    GUNSCORE_DAY3: "160.014",
    GUNSCORE_DAY4: "152.014",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "722.065",
    DAILY_T: "72.009",
    DONEGALL: "48.003",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "50.006",
    CORP: "44.002",
    TIMES: "48.005",
    POW: "65.003",
    DOFC: "47.006",
    LOVELL: "35.002",
    WIMB: "47.005",
    STG: "70.007",
    KINGS: "98.008",
    DAY1_POS: "217",
    DAY2_POS: "225",
    DAY3_POS: "438",
    DAY4_POS: "538",
    DAY5_POS: "446",
  },
  6280: {
    Index: "6280",
    Init: "JA",
    NAME: "Oakland",
    full_NAME: "JA Oakland",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "2",
    Day_3_BONUS: "5",
    Day_3_Total: "7",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "12",
    GUNSCORE_DAY1: "172.019",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "171.016",
    GUNSCORE_DAY4: "162.016",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "747.072",
    DAILY_T: "75.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "47.005",
    CORP: "48.005",
    TIMES: "49.006",
    POW: "75.007",
    DOFC: "47.003",
    LOVELL: "41.001",
    WIMB: "49.006",
    STG: "72.009",
    KINGS: "99.008",
    DAY1_POS: "116",
    DAY2_POS: "168",
    DAY3_POS: "45",
    DAY4_POS: "385",
    DAY5_POS: "402",
  },
  6284: {
    Index: "6284",
    Init: "NAD",
    NAME: "Pasternak-Albert",
    full_NAME: "NAD Pasternak-Albert",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.01",
    GUNSCORE_DAY2: "133.009",
    GUNSCORE_DAY3: "162.014",
    GUNSCORE_DAY4: "163.015",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "718.056",
    DAILY_T: "67.004",
    DONEGALL: "48.004",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "46.004",
    CORP: "38.001",
    TIMES: "49.007",
    POW: "66.003",
    DOFC: "47.004",
    LOVELL: "45.006",
    WIMB: "47.003",
    STG: "71.006",
    KINGS: "99.008",
    DAY1_POS: "509",
    DAY2_POS: "489",
    DAY3_POS: "375",
    DAY4_POS: "349",
    DAY5_POS: "402",
  },
  6288: {
    Index: "6288",
    Init: "CI",
    NAME: "Pawlik",
    full_NAME: "CI Pawlik",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.017",
    GUNSCORE_DAY2: "143.012",
    GUNSCORE_DAY3: "164.007",
    GUNSCORE_DAY4: "163.011",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "740.055",
    DAILY_T: "73.008",
    DONEGALL: "48.005",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "48.003",
    CORP: "45.001",
    TIMES: "47.003",
    POW: "69.003",
    DOFC: "48.001",
    LOVELL: "49.002",
    WIMB: "45.003",
    STG: "69.006",
    KINGS: "102.008",
    DAY1_POS: "304",
    DAY2_POS: "178",
    DAY3_POS: "336",
    DAY4_POS: "370",
    DAY5_POS: "225",
  },
  6290: {
    Index: "6290",
    Init: "TA",
    NAME: "Peters",
    full_NAME: "TA Peters",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.013",
    GUNSCORE_DAY2: "126.011",
    GUNSCORE_DAY3: "156.013",
    GUNSCORE_DAY4: "145.004",
    GUNSCORE_DAY5: "92.006",
    GUNSCORE_GA: "679.047",
    DAILY_T: "66.004",
    DONEGALL: "48.004",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "43.002",
    CORP: "36.003",
    TIMES: "48.005",
    POW: "63.003",
    DOFC: "45.005",
    LOVELL: "43.002",
    WIMB: "41.001",
    STG: "61.001",
    KINGS: "92.006",
    DAY1_POS: "515",
    DAY2_POS: "584",
    DAY3_POS: "520",
    DAY4_POS: "585",
    DAY5_POS: "579",
  },
  6295: {
    Index: "6295",
    Init: "RE",
    NAME: "Pike",
    full_NAME: "RE Pike",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.013",
    GUNSCORE_DAY2: "138.01",
    GUNSCORE_DAY3: "164.013",
    GUNSCORE_DAY4: "162.01",
    GUNSCORE_DAY5: "97.008",
    GUNSCORE_GA: "721.054",
    DAILY_T: "70.006",
    DONEGALL: "44.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "46.003",
    CORP: "46.002",
    TIMES: "49.006",
    POW: "68.004",
    DOFC: "47.003",
    LOVELL: "45.003",
    WIMB: "43",
    STG: "74.007",
    KINGS: "97.008",
    DAY1_POS: "515",
    DAY2_POS: "377",
    DAY3_POS: "327",
    DAY4_POS: "403",
    DAY5_POS: "477",
  },
  6298: {
    Index: "6298",
    Init: "AR",
    NAME: "Radkovskii",
    full_NAME: "AR Radkovskii",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "173.02",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "168.018",
    GUNSCORE_DAY4: "165.016",
    GUNSCORE_DAY5: "103.011",
    GUNSCORE_GA: "752.078",
    DAILY_T: "75.009",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.003",
    CORP: "47.006",
    TIMES: "50.009",
    POW: "70.005",
    DOFC: "48.004",
    LOVELL: "46.002",
    WIMB: "49.007",
    STG: "70.007",
    KINGS: "103.011",
    DAY1_POS: "69",
    DAY2_POS: "168",
    DAY3_POS: "136",
    DAY4_POS: "277",
    DAY5_POS: "124",
  },
  6303: {
    Index: "6303",
    Init: "AD",
    NAME: "Robertson",
    full_NAME: "AD Robertson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.011",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "158.011",
    GUNSCORE_DAY4: "151.01",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "715.055",
    DAILY_T: "73.005",
    DONEGALL: "43.002",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "48.006",
    CORP: "45.004",
    TIMES: "48.004",
    POW: "67.006",
    DOFC: "43.001",
    LOVELL: "34.001",
    WIMB: "46.004",
    STG: "71.005",
    KINGS: "100.008",
    DAY1_POS: "456",
    DAY2_POS: "196",
    DAY3_POS: "488",
    DAY4_POS: "549",
    DAY5_POS: "355",
  },
  6307: {
    Index: "6307",
    Init: "OEC",
    NAME: "Ross",
    full_NAME: "OEC Ross",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.017",
    GUNSCORE_DAY2: "133.008",
    GUNSCORE_DAY3: "156.013",
    GUNSCORE_DAY4: "160.009",
    GUNSCORE_DAY5: "100.013",
    GUNSCORE_GA: "716.06",
    DAILY_T: "73.007",
    DONEGALL: "47.004",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "46.005",
    CORP: "40.001",
    TIMES: "44.004",
    POW: "66.004",
    DOFC: "46.005",
    LOVELL: "46.004",
    WIMB: "46.002",
    STG: "68.003",
    KINGS: "100.013",
    DAY1_POS: "343",
    DAY2_POS: "491",
    DAY3_POS: "520",
    DAY4_POS: "441",
    DAY5_POS: "319",
  },
  6317: {
    Index: "6317",
    Init: "AL",
    NAME: "Shine",
    full_NAME: "AL Shine",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "135.008",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "161.016",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "728.065",
    DAILY_T: "74.007",
    DONEGALL: "50.009",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "46.003",
    CORP: "45.003",
    TIMES: "46.005",
    POW: "71.004",
    DOFC: "44.004",
    LOVELL: "40.004",
    WIMB: "49.007",
    STG: "72.005",
    KINGS: "99.007",
    DAY1_POS: "100",
    DAY2_POS: "453",
    DAY3_POS: "410",
    DAY4_POS: "406",
    DAY5_POS: "413",
  },
  6326: {
    Index: "6326",
    Init: "FC",
    NAME: "Stewart",
    full_NAME: "FC Stewart",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.019",
    GUNSCORE_DAY2: "141.014",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "165.017",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "734.07",
    DAILY_T: "71.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "48.006",
    CORP: "45.003",
    TIMES: "48.004",
    POW: "68.005",
    DOFC: "45.004",
    LOVELL: "45.003",
    WIMB: "48.005",
    STG: "72.009",
    KINGS: "97.007",
    DAY1_POS: "214",
    DAY2_POS: "240",
    DAY3_POS: "410",
    DAY4_POS: "274",
    DAY5_POS: "483",
  },
  6327: {
    Index: "6327",
    Init: "AG",
    NAME: "Stewart",
    full_NAME: "AG Stewart",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.017",
    GUNSCORE_DAY2: "140.009",
    GUNSCORE_DAY3: "162.012",
    GUNSCORE_DAY4: "163.011",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "738.059",
    DAILY_T: "73.005",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "47.003",
    CORP: "47.002",
    TIMES: "49.004",
    POW: "68.006",
    DOFC: "45.002",
    LOVELL: "45.002",
    WIMB: "48.004",
    STG: "70.005",
    KINGS: "101.01",
    DAY1_POS: "128",
    DAY2_POS: "315",
    DAY3_POS: "382",
    DAY4_POS: "370",
    DAY5_POS: "269",
  },
  6329: {
    Index: "6329",
    Init: "DJ",
    NAME: "Studholme",
    full_NAME: "DJ Studholme",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.017",
    GUNSCORE_DAY2: "145.013",
    GUNSCORE_DAY3: "169.02",
    GUNSCORE_DAY4: "161.014",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "746.072",
    DAILY_T: "73.008",
    DONEGALL: "49.004",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "49.004",
    CORP: "46.002",
    TIMES: "47.005",
    POW: "73.009",
    DOFC: "49.006",
    LOVELL: "44.004",
    WIMB: "45.002",
    STG: "72.008",
    KINGS: "101.008",
    DAY1_POS: "226",
    DAY2_POS: "89",
    DAY3_POS: "94",
    DAY4_POS: "408",
    DAY5_POS: "295",
  },
  6342: {
    Index: "6342",
    Init: "RR",
    NAME: "Warren",
    full_NAME: "RR Warren",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "1",
    Day_4_BONUS: "5",
    Day_4_Total: "6",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "9",
    FINAL_TOTAL: "30",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "145.014",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "171.019",
    GUNSCORE_DAY5: "104.012",
    GUNSCORE_GA: "761.08",
    DAILY_T: "73.009",
    DONEGALL: "49.005",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "50.005",
    CORP: "47.004",
    TIMES: "47.004",
    POW: "72.01",
    DOFC: "50.003",
    LOVELL: "50.008",
    WIMB: "49.004",
    STG: "72.007",
    KINGS: "104.012",
    DAY1_POS: "121",
    DAY2_POS: "86",
    DAY3_POS: "105",
    DAY4_POS: "50",
    DAY5_POS: "53",
  },
  6366: {
    Index: "6366",
    Init: "DA",
    NAME: "Roberts",
    full_NAME: "DA Roberts",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.014",
    GUNSCORE_DAY2: "141.013",
    GUNSCORE_DAY3: "169.019",
    GUNSCORE_DAY4: "163.016",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "736.068",
    DAILY_T: "72.007",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "48.006",
    CORP: "46.003",
    TIMES: "48.006",
    POW: "72.008",
    DOFC: "49.005",
    LOVELL: "44.002",
    WIMB: "47.006",
    STG: "72.008",
    KINGS: "97.006",
    DAY1_POS: "386",
    DAY2_POS: "245",
    DAY3_POS: "98",
    DAY4_POS: "347",
    DAY5_POS: "490",
  },
  6371: {
    Index: "6371",
    Init: "HB",
    NAME: "Thomson",
    full_NAME: "HB Thomson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.02",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "169.016",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "750.072",
    DAILY_T: "73.008",
    DONEGALL: "48.003",
    CONAN_DOYLE: "50.009",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "49.006",
    CORP: "49.004",
    TIMES: "50.006",
    POW: "73.005",
    DOFC: "46.005",
    LOVELL: "45.002",
    WIMB: "50.007",
    STG: "74.006",
    KINGS: "98.007",
    DAY1_POS: "159",
    DAY2_POS: "160",
    DAY3_POS: "113",
    DAY4_POS: "127",
    DAY5_POS: "455",
  },
  6415: {
    Index: "6415",
    Init: "NI",
    NAME: "Barnes",
    full_NAME: "NI Barnes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.016",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "161.019",
    GUNSCORE_DAY4: "167.02",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "742.078",
    DAILY_T: "74.007",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "46.006",
    ALEXANDRA: "48.005",
    CORP: "48.004",
    TIMES: "50.007",
    POW: "71.01",
    DOFC: "40.002",
    LOVELL: "43.003",
    WIMB: "50.007",
    STG: "74.01",
    KINGS: "101.008",
    DAY1_POS: "183",
    DAY2_POS: "196",
    DAY3_POS: "399",
    DAY4_POS: "186",
    DAY5_POS: "295",
  },
  6417: {
    Index: "6417",
    Init: "MD",
    NAME: "Beaton-Jaramillo",
    full_NAME: "MD Beaton-Jaramillo",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "14",
    GUNSCORE_DAY1: "169.019",
    GUNSCORE_DAY2: "125.009",
    GUNSCORE_DAY3: "163.014",
    GUNSCORE_DAY4: "162.016",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "723.072",
    DAILY_T: "73.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "40.002",
    CORP: "35.001",
    TIMES: "49.004",
    POW: "72.007",
    DOFC: "42.003",
    LOVELL: "40.001",
    WIMB: "47.004",
    STG: "75.011",
    KINGS: "104.014",
    DAY1_POS: "257",
    DAY2_POS: "593",
    DAY3_POS: "342",
    DAY4_POS: "385",
    DAY5_POS: "31",
  },
  6419: {
    Index: "6419",
    Init: "GM",
    NAME: "Bohn",
    full_NAME: "GM Bohn",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "144.014",
    GUNSCORE_DAY3: "170.013",
    GUNSCORE_DAY4: "163.015",
    GUNSCORE_DAY5: "100.013",
    GUNSCORE_GA: "748.076",
    DAILY_T: "73.009",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "49.004",
    CORP: "45.004",
    TIMES: "50.005",
    POW: "71.004",
    DOFC: "49.004",
    LOVELL: "45.002",
    WIMB: "46.003",
    STG: "72.01",
    KINGS: "100.013",
    DAY1_POS: "149",
    DAY2_POS: "120",
    DAY3_POS: "86",
    DAY4_POS: "349",
    DAY5_POS: "319",
  },
  6421: {
    Index: "6421",
    Init: "AJ",
    NAME: "Browne",
    full_NAME: "AJ Browne",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "138.001",
    GUNSCORE_DAY2: "108.004",
    GUNSCORE_DAY3: "129.009",
    GUNSCORE_DAY4: "131.002",
    GUNSCORE_DAY5: "89.003",
    GUNSCORE_GA: "595.019",
    DAILY_T: "65",
    DONEGALL: "34",
    CONAN_DOYLE: "39.001",
    DAILY_MAIL: "38.001",
    ALEXANDRA: "41.002",
    CORP: "29.001",
    TIMES: "28.001",
    POW: "61.005",
    DOFC: "40.003",
    LOVELL: "34",
    WIMB: "47.001",
    STG: "50.001",
    KINGS: "89.003",
    DAY1_POS: "624",
    DAY2_POS: "629",
    DAY3_POS: "629",
    DAY4_POS: "621",
    DAY5_POS: "602",
  },
  6424: {
    Index: "6424",
    Init: "OJ",
    NAME: "Christy",
    full_NAME: "OJ Christy",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "125.012",
    GUNSCORE_DAY2: "133.008",
    GUNSCORE_DAY3: "159.009",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "681.052",
    DAILY_T: "71.007",
    DONEGALL: "48.004",
    CONAN_DOYLE: "6.001",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "47.003",
    CORP: "38.001",
    TIMES: "49.006",
    POW: "67.002",
    DOFC: "43.001",
    LOVELL: "47.004",
    WIMB: "46.003",
    STG: "73.008",
    KINGS: "98.008",
    DAY1_POS: "631",
    DAY2_POS: "491",
    DAY3_POS: "476",
    DAY4_POS: "230",
    DAY5_POS: "446",
  },
  6428: {
    Index: "6428",
    Init: "RJ",
    NAME: "Cooper",
    full_NAME: "RJ Cooper",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "2",
    Day_3_BONUS: "5",
    Day_3_Total: "7",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "12",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "143.014",
    GUNSCORE_DAY3: "171.016",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "747.072",
    DAILY_T: "73.008",
    DONEGALL: "50.005",
    CONAN_DOYLE: "48.008",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "49.006",
    CORP: "45.002",
    TIMES: "48.005",
    POW: "73.007",
    DOFC: "50.004",
    LOVELL: "49.002",
    WIMB: "48.005",
    STG: "66.007",
    KINGS: "99.007",
    DAY1_POS: "149",
    DAY2_POS: "160",
    DAY3_POS: "45",
    DAY4_POS: "355",
    DAY5_POS: "413",
  },
  6429: {
    Index: "6429",
    Init: "ICN",
    NAME: "Cox",
    full_NAME: "ICN Cox",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "46.004",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "147.012",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "46.004",
    STG: "0",
    KINGS: "101.008",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "635",
    DAY5_POS: "295",
  },
  6434: {
    Index: "6434",
    Init: "MJ",
    NAME: "Dowe",
    full_NAME: "MJ Dowe",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "157.01",
    GUNSCORE_DAY2: "131.005",
    GUNSCORE_DAY3: "165.013",
    GUNSCORE_DAY4: "91.008",
    GUNSCORE_DAY5: "98.01",
    GUNSCORE_GA: "642.046",
    DAILY_T: "70.005",
    DONEGALL: "41",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "46.001",
    ALEXANDRA: "46.003",
    CORP: "39.001",
    TIMES: "48.004",
    POW: "67.004",
    DOFC: "50.005",
    LOVELL: "45.004",
    WIMB: "46.004",
    STG: "0",
    KINGS: "98.01",
    DAY1_POS: "560",
    DAY2_POS: "532",
    DAY3_POS: "294",
    DAY4_POS: "630",
    DAY5_POS: "436",
  },
  6444: {
    Index: "6444",
    Init: "HD",
    NAME: "Grindon",
    full_NAME: "HD Grindon",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "158.012",
    GUNSCORE_DAY2: "140.012",
    GUNSCORE_DAY3: "161.012",
    GUNSCORE_DAY4: "168.018",
    GUNSCORE_DAY5: "97.003",
    GUNSCORE_GA: "724.057",
    DAILY_T: "69.004",
    DONEGALL: "49.007",
    CONAN_DOYLE: "40.001",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "47.004",
    CORP: "46.003",
    TIMES: "46.003",
    POW: "73.008",
    DOFC: "42.001",
    LOVELL: "46.003",
    WIMB: "50.007",
    STG: "72.008",
    KINGS: "97.003",
    DAY1_POS: "539",
    DAY2_POS: "298",
    DAY3_POS: "418",
    DAY4_POS: "158",
    DAY5_POS: "503",
  },
  6446: {
    Index: "6446",
    Init: "EJ",
    NAME: "Harriss",
    full_NAME: "EJ Harriss",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.016",
    GUNSCORE_DAY2: "138.013",
    GUNSCORE_DAY3: "147.008",
    GUNSCORE_DAY4: "161.008",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "709.055",
    DAILY_T: "68.005",
    DONEGALL: "47.006",
    CONAN_DOYLE: "45.005",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "45.004",
    CORP: "45.003",
    TIMES: "42.002",
    POW: "59.003",
    DOFC: "46.003",
    LOVELL: "47.003",
    WIMB: "45",
    STG: "69.005",
    KINGS: "103.01",
    DAY1_POS: "514",
    DAY2_POS: "363",
    DAY3_POS: "592",
    DAY4_POS: "424",
    DAY5_POS: "138",
  },
  6452: {
    Index: "6452",
    Init: "P",
    NAME: "Hobbs",
    full_NAME: "P Hobbs",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "159.01",
    GUNSCORE_DAY4: "161.01",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "729.056",
    DAILY_T: "73.007",
    DONEGALL: "48.003",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "48.004",
    CORP: "43.003",
    TIMES: "43.003",
    POW: "67.003",
    DOFC: "49.004",
    LOVELL: "46.002",
    WIMB: "44.002",
    STG: "71.006",
    KINGS: "104.011",
    DAY1_POS: "425",
    DAY2_POS: "288",
    DAY3_POS: "471",
    DAY4_POS: "418",
    DAY5_POS: "63",
  },
  6457: {
    Index: "6457",
    Init: "JJ",
    NAME: "Kahtava",
    full_NAME: "JJ Kahtava",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.014",
    GUNSCORE_DAY2: "138.009",
    GUNSCORE_DAY3: "158.006",
    GUNSCORE_DAY4: "161.012",
    GUNSCORE_DAY5: "90.007",
    GUNSCORE_GA: "711.048",
    DAILY_T: "74.007",
    DONEGALL: "45.004",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "47.003",
    CORP: "45.003",
    TIMES: "43.001",
    POW: "69.002",
    DOFC: "46.003",
    LOVELL: "47.003",
    WIMB: "45.002",
    STG: "69.007",
    KINGS: "90.007",
    DAY1_POS: "443",
    DAY2_POS: "380",
    DAY3_POS: "505",
    DAY4_POS: "412",
    DAY5_POS: "593",
  },
  6460: {
    Index: "6460",
    Init: "KS",
    NAME: "Kousetta",
    full_NAME: "KS Kousetta",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "0",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6462: {
    Index: "6462",
    Init: "GJ",
    NAME: "Lake Coghlan",
    full_NAME: "GJ Lake Coghlan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.014",
    GUNSCORE_DAY2: "132.011",
    GUNSCORE_DAY3: "153.008",
    GUNSCORE_DAY4: "158.009",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "707.05",
    DAILY_T: "72.008",
    DONEGALL: "47.001",
    CONAN_DOYLE: "45.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "44.003",
    CORP: "40.004",
    TIMES: "45.001",
    POW: "67.005",
    DOFC: "41.002",
    LOVELL: "45.003",
    WIMB: "44.002",
    STG: "69.004",
    KINGS: "100.008",
    DAY1_POS: "443",
    DAY2_POS: "504",
    DAY3_POS: "558",
    DAY4_POS: "470",
    DAY5_POS: "355",
  },
  6466: {
    Index: "6466",
    Init: "RJ",
    NAME: "Mace",
    full_NAME: "RJ Mace",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "7",
    Day_3_BONUS: "5",
    Day_3_Total: "12",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "22",
    GUNSCORE_DAY1: "171.022",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "172.02",
    GUNSCORE_DAY4: "166.012",
    GUNSCORE_DAY5: "101.013",
    GUNSCORE_GA: "751.079",
    DAILY_T: "73.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "46.004",
    CORP: "45.002",
    TIMES: "50.005",
    POW: "73.011",
    DOFC: "49.004",
    LOVELL: "47.004",
    WIMB: "48.003",
    STG: "71.005",
    KINGS: "101.013",
    DAY1_POS: "145",
    DAY2_POS: "250",
    DAY3_POS: "19",
    DAY4_POS: "259",
    DAY5_POS: "245",
  },
  6470: {
    Index: "6470",
    Init: "AL",
    NAME: "Moldoveanu",
    full_NAME: "AL Moldoveanu",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "143.01",
    GUNSCORE_DAY3: "165.01",
    GUNSCORE_DAY4: "163.018",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "735.059",
    DAILY_T: "72.004",
    DONEGALL: "47.004",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "48.004",
    CORP: "48.004",
    TIMES: "47.003",
    POW: "70.004",
    DOFC: "48.003",
    LOVELL: "42.004",
    WIMB: "49.006",
    STG: "72.008",
    KINGS: "99.008",
    DAY1_POS: "417",
    DAY2_POS: "183",
    DAY3_POS: "304",
    DAY4_POS: "342",
    DAY5_POS: "402",
  },
  6471: {
    Index: "6471",
    Init: "JM",
    NAME: "Nippers",
    full_NAME: "JM Nippers",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.011",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "159.01",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "738.058",
    DAILY_T: "69.003",
    DONEGALL: "49.001",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "49.006",
    CORP: "46",
    TIMES: "43.002",
    POW: "69.004",
    DOFC: "47.004",
    LOVELL: "44.003",
    WIMB: "48.005",
    STG: "74.006",
    KINGS: "102.01",
    DAY1_POS: "328",
    DAY2_POS: "168",
    DAY3_POS: "471",
    DAY4_POS: "240",
    DAY5_POS: "202",
  },
  6472: {
    Index: "6472",
    Init: "HP",
    NAME: "Nippers",
    full_NAME: "HP Nippers",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.011",
    GUNSCORE_DAY2: "139.008",
    GUNSCORE_DAY3: "158.01",
    GUNSCORE_DAY4: "143.006",
    GUNSCORE_DAY5: "92.01",
    GUNSCORE_GA: "688.045",
    DAILY_T: "63.002",
    DONEGALL: "47.007",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "46.002",
    CORP: "48.004",
    TIMES: "47.005",
    POW: "67.004",
    DOFC: "44.001",
    LOVELL: "30",
    WIMB: "46.005",
    STG: "67.001",
    KINGS: "92.01",
    DAY1_POS: "567",
    DAY2_POS: "352",
    DAY3_POS: "493",
    DAY4_POS: "597",
    DAY5_POS: "574",
  },
  6473: {
    Index: "6473",
    Init: "I",
    NAME: "Noor",
    full_NAME: "I Noor",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "48.001",
    GUNSCORE_DAY2: "73.002",
    GUNSCORE_DAY3: "163.01",
    GUNSCORE_DAY4: "119.011",
    GUNSCORE_DAY5: "88.005",
    GUNSCORE_GA: "491.029",
    DAILY_T: "48.001",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "40.001",
    CORP: "33.001",
    TIMES: "48.003",
    POW: "65.003",
    DOFC: "50.004",
    LOVELL: "0",
    WIMB: "45.002",
    STG: "74.009",
    KINGS: "88.005",
    DAY1_POS: "638",
    DAY2_POS: "639",
    DAY3_POS: "359",
    DAY4_POS: "624",
    DAY5_POS: "607",
  },
  6474: {
    Index: "6474",
    Init: "SF",
    NAME: "Oldridge",
    full_NAME: "SF Oldridge",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.023",
    GUNSCORE_DAY2: "142.015",
    GUNSCORE_DAY3: "162.016",
    GUNSCORE_DAY4: "164.018",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "741.083",
    DAILY_T: "73.01",
    DONEGALL: "50.006",
    CONAN_DOYLE: "48.007",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "49.006",
    CORP: "45.003",
    TIMES: "49.005",
    POW: "70.009",
    DOFC: "43.002",
    LOVELL: "44.002",
    WIMB: "47.005",
    STG: "73.011",
    KINGS: "102.011",
    DAY1_POS: "140",
    DAY2_POS: "196",
    DAY3_POS: "367",
    DAY4_POS: "309",
    DAY5_POS: "191",
  },
  6480: {
    Index: "6480",
    Init: "HJ",
    NAME: "Purslow",
    full_NAME: "HJ Purslow",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.016",
    GUNSCORE_DAY2: "138.013",
    GUNSCORE_DAY3: "165.011",
    GUNSCORE_DAY4: "163.013",
    GUNSCORE_DAY5: "102.007",
    GUNSCORE_GA: "734.06",
    DAILY_T: "72.006",
    DONEGALL: "46.004",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "46.004",
    CORP: "42.003",
    TIMES: "48.006",
    POW: "68.002",
    DOFC: "49.003",
    LOVELL: "47.006",
    WIMB: "46.002",
    STG: "70.005",
    KINGS: "102.007",
    DAY1_POS: "381",
    DAY2_POS: "363",
    DAY3_POS: "303",
    DAY4_POS: "362",
    DAY5_POS: "233",
  },
  6482: {
    Index: "6482",
    Init: "OW",
    NAME: "Rankin",
    full_NAME: "OW Rankin",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "163.009",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "737.058",
    DAILY_T: "70.007",
    DONEGALL: "47.002",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.005",
    CORP: "46.002",
    TIMES: "49.006",
    POW: "71.004",
    DOFC: "48.005",
    LOVELL: "47.003",
    WIMB: "43",
    STG: "73.006",
    KINGS: "100.009",
    DAY1_POS: "417",
    DAY2_POS: "250",
    DAY3_POS: "154",
    DAY4_POS: "380",
    DAY5_POS: "346",
  },
  6484: {
    Index: "6484",
    Init: "EM",
    NAME: "Robinson",
    full_NAME: "EM Robinson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.01",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "155.011",
    GUNSCORE_DAY4: "156.012",
    GUNSCORE_DAY5: "96.008",
    GUNSCORE_GA: "699.05",
    DAILY_T: "71.005",
    DONEGALL: "43.003",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "47.003",
    CORP: "43.004",
    TIMES: "45.005",
    POW: "65.003",
    DOFC: "45.003",
    LOVELL: "38.001",
    WIMB: "46.005",
    STG: "72.006",
    KINGS: "96.008",
    DAY1_POS: "545",
    DAY2_POS: "466",
    DAY3_POS: "534",
    DAY4_POS: "503",
    DAY5_POS: "508",
  },
  6486: {
    Index: "6486",
    Init: "MWS",
    NAME: "Oliver",
    full_NAME: "MWS Oliver",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.017",
    GUNSCORE_DAY2: "137.005",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "158.008",
    GUNSCORE_DAY5: "101.006",
    GUNSCORE_GA: "722.049",
    DAILY_T: "70.007",
    DONEGALL: "46.005",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "47.001",
    ALEXANDRA: "47.004",
    CORP: "43",
    TIMES: "49.004",
    POW: "65.006",
    DOFC: "47.003",
    LOVELL: "46.003",
    WIMB: "45.001",
    STG: "67.004",
    KINGS: "101.006",
    DAY1_POS: "403",
    DAY2_POS: "415",
    DAY3_POS: "410",
    DAY4_POS: "476",
    DAY5_POS: "312",
  },
  6488: {
    Index: "6488",
    Init: "LG",
    NAME: "Skingley",
    full_NAME: "LG Skingley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "168.021",
    GUNSCORE_DAY2: "143.012",
    GUNSCORE_DAY3: "165.013",
    GUNSCORE_DAY4: "163.018",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "742.077",
    DAILY_T: "74.012",
    DONEGALL: "47.006",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "47.001",
    CORP: "46.004",
    TIMES: "48.006",
    POW: "73.007",
    DOFC: "44",
    LOVELL: "41.002",
    WIMB: "50.009",
    STG: "72.007",
    KINGS: "103.013",
    DAY1_POS: "291",
    DAY2_POS: "178",
    DAY3_POS: "294",
    DAY4_POS: "342",
    DAY5_POS: "97",
  },
  6492: {
    Index: "6492",
    Init: "ASO",
    NAME: "Stewart",
    full_NAME: "ASO Stewart",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "12",
    Day_3_BONUS: "10",
    Day_3_Total: "22",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "31",
    GUNSCORE_DAY1: "172.015",
    GUNSCORE_DAY2: "145.012",
    GUNSCORE_DAY3: "173.018",
    GUNSCORE_DAY4: "166.015",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "760.074",
    DAILY_T: "75.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "49.005",
    CORP: "48.001",
    TIMES: "50.005",
    POW: "75.009",
    DOFC: "48.004",
    LOVELL: "45.002",
    WIMB: "47.004",
    STG: "74.009",
    KINGS: "104.014",
    DAY1_POS: "131",
    DAY2_POS: "93",
    DAY3_POS: "10",
    DAY4_POS: "230",
    DAY5_POS: "31",
  },
  6493: {
    Index: "6493",
    Init: "MJ",
    NAME: "Stewart",
    full_NAME: "MJ Stewart",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.017",
    GUNSCORE_DAY2: "138.007",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "164.016",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "741.07",
    DAILY_T: "74.009",
    DONEGALL: "48.005",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "46.003",
    CORP: "45.001",
    TIMES: "49.006",
    POW: "70.006",
    DOFC: "47.004",
    LOVELL: "46.003",
    WIMB: "47.005",
    STG: "71.008",
    KINGS: "103.014",
    DAY1_POS: "226",
    DAY2_POS: "389",
    DAY3_POS: "231",
    DAY4_POS: "313",
    DAY5_POS: "90",
  },
  6497: {
    Index: "6497",
    Init: "B",
    NAME: "Vary",
    full_NAME: "B Vary",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "136.012",
    GUNSCORE_DAY3: "162.013",
    GUNSCORE_DAY4: "159.012",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "718.056",
    DAILY_T: "70.003",
    DONEGALL: "49.006",
    CONAN_DOYLE: "44.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "47.004",
    CORP: "43.005",
    TIMES: "49.006",
    POW: "67.003",
    DOFC: "46.004",
    LOVELL: "43.003",
    WIMB: "47.005",
    STG: "69.004",
    KINGS: "98.007",
    DAY1_POS: "475",
    DAY2_POS: "418",
    DAY3_POS: "377",
    DAY4_POS: "450",
    DAY5_POS: "455",
  },
  6500: {
    Index: "6500",
    Init: "JJ",
    NAME: "Weir",
    full_NAME: "JJ Weir",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.013",
    GUNSCORE_DAY2: "133.01",
    GUNSCORE_DAY3: "167.012",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "101.012",
    GUNSCORE_GA: "731.062",
    DAILY_T: "71.007",
    DONEGALL: "47.002",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "45.004",
    CORP: "42.003",
    TIMES: "47.002",
    POW: "72.007",
    DOFC: "48.003",
    LOVELL: "47.006",
    WIMB: "47.005",
    STG: "70.004",
    KINGS: "101.012",
    DAY1_POS: "389",
    DAY2_POS: "486",
    DAY3_POS: "207",
    DAY4_POS: "316",
    DAY5_POS: "247",
  },
  6501: {
    Index: "6501",
    Init: "ACM",
    NAME: "White",
    full_NAME: "ACM White",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.018",
    GUNSCORE_DAY2: "129.006",
    GUNSCORE_DAY3: "166.013",
    GUNSCORE_DAY4: "160.01",
    GUNSCORE_DAY5: "96.006",
    GUNSCORE_GA: "715.053",
    DAILY_T: "72.01",
    DONEGALL: "44.002",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "45.002",
    CORP: "39.001",
    TIMES: "48.006",
    POW: "70.005",
    DOFC: "48.002",
    LOVELL: "46.004",
    WIMB: "45.002",
    STG: "69.004",
    KINGS: "96.006",
    DAY1_POS: "436",
    DAY2_POS: "550",
    DAY3_POS: "252",
    DAY4_POS: "438",
    DAY5_POS: "521",
  },
  6502: {
    Index: "6502",
    Init: "GI",
    NAME: "Williams",
    full_NAME: "GI Williams",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "94.011",
    GUNSCORE_DAY2: "41.002",
    GUNSCORE_DAY3: "48.007",
    GUNSCORE_DAY4: "165.019",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "452.05",
    DAILY_T: "0",
    DONEGALL: "48.006",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "41.002",
    TIMES: "48.007",
    POW: "0",
    DOFC: "0",
    LOVELL: "47.004",
    WIMB: "47.004",
    STG: "71.011",
    KINGS: "104.011",
    DAY1_POS: "636",
    DAY2_POS: "640",
    DAY3_POS: "640",
    DAY4_POS: "269",
    DAY5_POS: "63",
  },
  6508: {
    Index: "6508",
    Init: "G",
    NAME: "Wood",
    full_NAME: "G Wood",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.017",
    GUNSCORE_DAY2: "139.011",
    GUNSCORE_DAY3: "152.012",
    GUNSCORE_DAY4: "163.015",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "711.061",
    DAILY_T: "70.006",
    DONEGALL: "45.004",
    CONAN_DOYLE: "45.007",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "49.004",
    CORP: "43.003",
    TIMES: "48.007",
    POW: "66.003",
    DOFC: "38.002",
    LOVELL: "46.003",
    WIMB: "47.004",
    STG: "70.008",
    KINGS: "97.006",
    DAY1_POS: "513",
    DAY2_POS: "333",
    DAY3_POS: "563",
    DAY4_POS: "349",
    DAY5_POS: "490",
  },
  6525: {
    Index: "6525",
    Init: "HM",
    NAME: "Anselm",
    full_NAME: "HM Anselm",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "151.004",
    GUNSCORE_DAY2: "128.007",
    GUNSCORE_DAY3: "149.007",
    GUNSCORE_DAY4: "153.011",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "683.038",
    DAILY_T: "70.003",
    DONEGALL: "39.001",
    CONAN_DOYLE: "42",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "40.001",
    CORP: "41.003",
    TIMES: "47.004",
    POW: "63.002",
    DOFC: "39.001",
    LOVELL: "38.003",
    WIMB: "45.002",
    STG: "70.006",
    KINGS: "102.009",
    DAY1_POS: "598",
    DAY2_POS: "566",
    DAY3_POS: "583",
    DAY4_POS: "533",
    DAY5_POS: "216",
  },
  6526: {
    Index: "6526",
    Init: "WJM",
    NAME: "Bacon",
    full_NAME: "WJM Bacon",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "138.009",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "167.013",
    GUNSCORE_DAY5: "102.015",
    GUNSCORE_GA: "742.069",
    DAILY_T: "72.007",
    DONEGALL: "48.004",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "48.006",
    CORP: "41",
    TIMES: "50.005",
    POW: "71.007",
    DOFC: "47.004",
    LOVELL: "45.001",
    WIMB: "47.003",
    STG: "75.009",
    KINGS: "102.015",
    DAY1_POS: "348",
    DAY2_POS: "380",
    DAY3_POS: "147",
    DAY4_POS: "208",
    DAY5_POS: "165",
  },
  6527: {
    Index: "6527",
    Init: "GJ",
    NAME: "Baker",
    full_NAME: "GJ Baker",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.013",
    GUNSCORE_DAY2: "130.004",
    GUNSCORE_DAY3: "159.012",
    GUNSCORE_DAY4: "152.011",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "707.047",
    DAILY_T: "71.003",
    DONEGALL: "49.006",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "42",
    ALEXANDRA: "45.003",
    CORP: "43.001",
    TIMES: "44.004",
    POW: "69.006",
    DOFC: "46.002",
    LOVELL: "35",
    WIMB: "49.007",
    STG: "68.004",
    KINGS: "101.007",
    DAY1_POS: "417",
    DAY2_POS: "544",
    DAY3_POS: "464",
    DAY4_POS: "540",
    DAY5_POS: "304",
  },
  6531: {
    Index: "6531",
    Init: "R",
    NAME: "Celesia",
    full_NAME: "R Celesia",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.013",
    GUNSCORE_DAY2: "142.018",
    GUNSCORE_DAY3: "163.013",
    GUNSCORE_DAY4: "163.015",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "739.071",
    DAILY_T: "72.005",
    DONEGALL: "49.004",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "49.006",
    CORP: "45.005",
    TIMES: "49.007",
    POW: "67.004",
    DOFC: "47.002",
    LOVELL: "43.003",
    WIMB: "47.003",
    STG: "73.009",
    KINGS: "102.012",
    DAY1_POS: "286",
    DAY2_POS: "190",
    DAY3_POS: "347",
    DAY4_POS: "349",
    DAY5_POS: "180",
  },
  6533: {
    Index: "6533",
    Init: "AF",
    NAME: "Chidlow",
    full_NAME: "AF Chidlow",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.01",
    GUNSCORE_DAY2: "136.01",
    GUNSCORE_DAY3: "162.015",
    GUNSCORE_DAY4: "163.013",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "720.056",
    DAILY_T: "71.004",
    DONEGALL: "43.002",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "49.005",
    CORP: "40.001",
    TIMES: "44.004",
    POW: "71.008",
    DOFC: "47.003",
    LOVELL: "48.003",
    WIMB: "46.005",
    STG: "69.005",
    KINGS: "99.008",
    DAY1_POS: "523",
    DAY2_POS: "420",
    DAY3_POS: "371",
    DAY4_POS: "362",
    DAY5_POS: "402",
  },
  6536: {
    Index: "6536",
    Init: "WD",
    NAME: "Cooper",
    full_NAME: "WD Cooper",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "172.022",
    GUNSCORE_DAY2: "135.009",
    GUNSCORE_DAY3: "161.016",
    GUNSCORE_DAY4: "166.01",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "732.064",
    DAILY_T: "74.009",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "49.003",
    CORP: "40.001",
    TIMES: "49.007",
    POW: "67.005",
    DOFC: "45.004",
    LOVELL: "44.001",
    WIMB: "48.004",
    STG: "74.005",
    KINGS: "98.007",
    DAY1_POS: "91",
    DAY2_POS: "449",
    DAY3_POS: "400",
    DAY4_POS: "266",
    DAY5_POS: "455",
  },
  6537: {
    Index: "6537",
    Init: "LS",
    NAME: "Crisp",
    full_NAME: "LS Crisp",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "10",
    Day_2_Total: "10",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "2",
    Day_4_BONUS: "5",
    Day_4_Total: "7",
    Day_5: "8",
    Day_5_BONUS: "5",
    Day_5_Total: "13",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "35",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "144.016",
    GUNSCORE_DAY3: "165.015",
    GUNSCORE_DAY4: "171.023",
    GUNSCORE_DAY5: "105.012",
    GUNSCORE_GA: "756.087",
    DAILY_T: "72.008",
    DONEGALL: "49.006",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "50.007",
    CORP: "44.002",
    TIMES: "46.005",
    POW: "72.008",
    DOFC: "47.002",
    LOVELL: "48.006",
    WIMB: "48.008",
    STG: "75.009",
    KINGS: "105.012",
    DAY1_POS: "149",
    DAY2_POS: "111",
    DAY3_POS: "284",
    DAY4_POS: "43",
    DAY5_POS: "18",
  },
  6538: {
    Index: "6538",
    Init: "LK",
    NAME: "Crosby",
    full_NAME: "LK Crosby",
    Day_1: "0",
    Day_1_BONUS: "10",
    Day_1_Total: "10",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.021",
    GUNSCORE_DAY2: "136.01",
    GUNSCORE_DAY3: "162.012",
    GUNSCORE_DAY4: "160.018",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "729.069",
    DAILY_T: "75.01",
    DONEGALL: "50.006",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "46.004",
    CORP: "43.003",
    TIMES: "47.006",
    POW: "70.005",
    DOFC: "45.001",
    LOVELL: "39.002",
    WIMB: "49.007",
    STG: "72.009",
    KINGS: "100.008",
    DAY1_POS: "149",
    DAY2_POS: "420",
    DAY3_POS: "382",
    DAY4_POS: "426",
    DAY5_POS: "355",
  },
  6539: {
    Index: "6539",
    Init: "A",
    NAME: "Cui",
    full_NAME: "A Cui",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "140.011",
    GUNSCORE_DAY3: "167.017",
    GUNSCORE_DAY4: "161.009",
    GUNSCORE_DAY5: "100.007",
    GUNSCORE_GA: "736.059",
    DAILY_T: "72.007",
    DONEGALL: "47.003",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "48.004",
    CORP: "45.003",
    TIMES: "47.003",
    POW: "72.009",
    DOFC: "48.005",
    LOVELL: "43.001",
    WIMB: "46.003",
    STG: "72.005",
    KINGS: "100.007",
    DAY1_POS: "311",
    DAY2_POS: "305",
    DAY3_POS: "187",
    DAY4_POS: "421",
    DAY5_POS: "366",
  },
  6540: {
    Index: "6540",
    Init: "RS",
    NAME: "Darwish",
    full_NAME: "RS Darwish",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.018",
    GUNSCORE_DAY2: "135.012",
    GUNSCORE_DAY3: "165.017",
    GUNSCORE_DAY4: "169.019",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "736.074",
    DAILY_T: "70.007",
    DONEGALL: "49.003",
    CONAN_DOYLE: "50.008",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "46.004",
    CORP: "40.002",
    TIMES: "49.006",
    POW: "69.004",
    DOFC: "47.007",
    LOVELL: "47.007",
    WIMB: "49.005",
    STG: "73.007",
    KINGS: "98.008",
    DAY1_POS: "261",
    DAY2_POS: "436",
    DAY3_POS: "272",
    DAY4_POS: "105",
    DAY5_POS: "446",
  },
  6544: {
    Index: "6544",
    Init: "A",
    NAME: "Downie",
    full_NAME: "A Downie",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.009",
    GUNSCORE_DAY2: "128.003",
    GUNSCORE_DAY3: "165.017",
    GUNSCORE_DAY4: "165.016",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "717.054",
    DAILY_T: "70.004",
    DONEGALL: "44.002",
    CONAN_DOYLE: "44.003",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "46.001",
    CORP: "37",
    TIMES: "45.003",
    POW: "72.008",
    DOFC: "48.006",
    LOVELL: "45.003",
    WIMB: "47.004",
    STG: "73.009",
    KINGS: "101.009",
    DAY1_POS: "547",
    DAY2_POS: "574",
    DAY3_POS: "272",
    DAY4_POS: "277",
    DAY5_POS: "286",
  },
  6545: {
    Index: "6545",
    Init: "S",
    NAME: "Franck",
    full_NAME: "S Franck",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.015",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "157.007",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "729.058",
    DAILY_T: "69.008",
    DONEGALL: "48.003",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "47.002",
    CORP: "42.001",
    TIMES: "44.002",
    POW: "68.004",
    DOFC: "45.001",
    LOVELL: "47.003",
    WIMB: "48.003",
    STG: "74.009",
    KINGS: "100.011",
    DAY1_POS: "441",
    DAY2_POS: "338",
    DAY3_POS: "514",
    DAY4_POS: "127",
    DAY5_POS: "323",
  },
  6547: {
    Index: "6547",
    Init: "AJD",
    NAME: "Germain",
    full_NAME: "AJD Germain",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.015",
    GUNSCORE_DAY2: "137.007",
    GUNSCORE_DAY3: "161.014",
    GUNSCORE_DAY4: "150.006",
    GUNSCORE_DAY5: "98.008",
    GUNSCORE_GA: "707.05",
    DAILY_T: "68.006",
    DONEGALL: "45.003",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "46.001",
    ALEXANDRA: "46.001",
    CORP: "45.005",
    TIMES: "45.005",
    POW: "71.007",
    DOFC: "45.002",
    LOVELL: "39.001",
    WIMB: "40.001",
    STG: "71.004",
    KINGS: "98.008",
    DAY1_POS: "505",
    DAY2_POS: "412",
    DAY3_POS: "404",
    DAY4_POS: "559",
    DAY5_POS: "446",
  },
  6552: {
    Index: "6552",
    Init: "SR",
    NAME: "Hall",
    full_NAME: "SR Hall",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.014",
    GUNSCORE_DAY2: "130.004",
    GUNSCORE_DAY3: "164.014",
    GUNSCORE_DAY4: "150.012",
    GUNSCORE_DAY5: "98.004",
    GUNSCORE_GA: "704.048",
    DAILY_T: "67.004",
    DONEGALL: "48.006",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "47.002",
    CORP: "36",
    TIMES: "47.005",
    POW: "69.006",
    DOFC: "48.003",
    LOVELL: "38.001",
    WIMB: "46.006",
    STG: "66.005",
    KINGS: "98.004",
    DAY1_POS: "495",
    DAY2_POS: "544",
    DAY3_POS: "321",
    DAY4_POS: "554",
    DAY5_POS: "469",
  },
  6553: {
    Index: "6553",
    Init: "HJ",
    NAME: "Hamlyn",
    full_NAME: "HJ Hamlyn",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.014",
    GUNSCORE_DAY2: "142.006",
    GUNSCORE_DAY3: "169.016",
    GUNSCORE_DAY4: "166.012",
    GUNSCORE_DAY5: "103.014",
    GUNSCORE_GA: "748.062",
    DAILY_T: "73.006",
    DONEGALL: "48.004",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "46.001",
    CORP: "48.001",
    TIMES: "50.007",
    POW: "73.006",
    DOFC: "46.003",
    LOVELL: "44",
    WIMB: "48.005",
    STG: "74.007",
    KINGS: "103.014",
    DAY1_POS: "320",
    DAY2_POS: "234",
    DAY3_POS: "113",
    DAY4_POS: "259",
    DAY5_POS: "90",
  },
  6554: {
    Index: "6554",
    Init: "DF",
    NAME: "Harrison",
    full_NAME: "DF Harrison",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.013",
    GUNSCORE_DAY2: "127.008",
    GUNSCORE_DAY3: "160.015",
    GUNSCORE_DAY4: "150.008",
    GUNSCORE_DAY5: "100.007",
    GUNSCORE_GA: "698.051",
    DAILY_T: "69.006",
    DONEGALL: "45.002",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "49.003",
    CORP: "33.002",
    TIMES: "45.002",
    POW: "69.008",
    DOFC: "46.005",
    LOVELL: "34.001",
    WIMB: "46.003",
    STG: "70.004",
    KINGS: "100.007",
    DAY1_POS: "507",
    DAY2_POS: "577",
    DAY3_POS: "436",
    DAY4_POS: "556",
    DAY5_POS: "366",
  },
  6556: {
    Index: "6556",
    Init: "LG",
    NAME: "Hetherington",
    full_NAME: "LG Hetherington",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.015",
    GUNSCORE_DAY2: "132.01",
    GUNSCORE_DAY3: "153.009",
    GUNSCORE_DAY4: "144.003",
    GUNSCORE_DAY5: "92.007",
    GUNSCORE_GA: "683.044",
    DAILY_T: "72.009",
    DONEGALL: "47.005",
    CONAN_DOYLE: "43.001",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "43.003",
    CORP: "45.005",
    TIMES: "44.002",
    POW: "68.004",
    DOFC: "41.003",
    LOVELL: "43",
    WIMB: "38.001",
    STG: "63.002",
    KINGS: "92.007",
    DAY1_POS: "493",
    DAY2_POS: "505",
    DAY3_POS: "556",
    DAY4_POS: "593",
    DAY5_POS: "576",
  },
  6558: {
    Index: "6558",
    Init: "LI",
    NAME: "Hughes",
    full_NAME: "LI Hughes",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.006",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "154.006",
    DAILY_T: "65.001",
    DONEGALL: "43.002",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "583",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6560: {
    Index: "6560",
    Init: "T",
    NAME: "Ishikawa",
    full_NAME: "T Ishikawa",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "133.004",
    GUNSCORE_DAY2: "89.004",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "222.008",
    DAILY_T: "59.002",
    DONEGALL: "37.001",
    CONAN_DOYLE: "37.001",
    DAILY_MAIL: "44",
    ALEXANDRA: "45.004",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "628",
    DAY2_POS: "634",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6569: {
    Index: "6569",
    Init: "LA",
    NAME: "Lawrence",
    full_NAME: "LA Lawrence",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.012",
    GUNSCORE_DAY2: "137.01",
    GUNSCORE_DAY3: "166.01",
    GUNSCORE_DAY4: "151.009",
    GUNSCORE_DAY5: "94.006",
    GUNSCORE_GA: "706.047",
    DAILY_T: "67.005",
    DONEGALL: "48.004",
    CONAN_DOYLE: "43.003",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "49.003",
    CORP: "42.003",
    TIMES: "47.003",
    POW: "71.005",
    DOFC: "48.002",
    LOVELL: "42.002",
    WIMB: "44.004",
    STG: "65.003",
    KINGS: "94.006",
    DAY1_POS: "539",
    DAY2_POS: "404",
    DAY3_POS: "261",
    DAY4_POS: "551",
    DAY5_POS: "556",
  },
  6577: {
    Index: "6577",
    Init: "TZ",
    NAME: "Maxwell Randeria",
    full_NAME: "TZ Maxwell Randeria",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.018",
    GUNSCORE_DAY2: "135.008",
    GUNSCORE_DAY3: "162.016",
    GUNSCORE_DAY4: "166.014",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "732.065",
    DAILY_T: "72.008",
    DONEGALL: "47.004",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "45.003",
    CORP: "44.002",
    TIMES: "48.008",
    POW: "70.006",
    DOFC: "44.002",
    LOVELL: "48.005",
    WIMB: "49.004",
    STG: "69.005",
    KINGS: "102.009",
    DAY1_POS: "337",
    DAY2_POS: "453",
    DAY3_POS: "367",
    DAY4_POS: "240",
    DAY5_POS: "216",
  },
  6579: {
    Index: "6579",
    Init: "RPF",
    NAME: "McKenna",
    full_NAME: "RPF McKenna",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.014",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "158.013",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "728.062",
    DAILY_T: "70.004",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "48.006",
    CORP: "42.001",
    TIMES: "47.003",
    POW: "66.002",
    DOFC: "45.008",
    LOVELL: "49.004",
    WIMB: "45.004",
    STG: "70.007",
    KINGS: "100.008",
    DAY1_POS: "320",
    DAY2_POS: "367",
    DAY3_POS: "485",
    DAY4_POS: "316",
    DAY5_POS: "355",
  },
  6583: {
    Index: "6583",
    Init: "ZNK",
    NAME: "Moir",
    full_NAME: "ZNK Moir",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "136.008",
    GUNSCORE_DAY3: "163.019",
    GUNSCORE_DAY4: "163.01",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "731.062",
    DAILY_T: "73.007",
    DONEGALL: "47.004",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "43.002",
    CORP: "45.003",
    TIMES: "49.006",
    POW: "68.01",
    DOFC: "46.003",
    LOVELL: "45.002",
    WIMB: "49.004",
    STG: "69.004",
    KINGS: "101.01",
    DAY1_POS: "311",
    DAY2_POS: "425",
    DAY3_POS: "337",
    DAY4_POS: "375",
    DAY5_POS: "269",
  },
  6584: {
    Index: "6584",
    Init: "CJ",
    NAME: "Monk",
    full_NAME: "CJ Monk",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.012",
    GUNSCORE_DAY2: "143.009",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "152.009",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "722.053",
    DAILY_T: "70.004",
    DONEGALL: "48.006",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "50.003",
    ALEXANDRA: "48.004",
    CORP: "45.002",
    TIMES: "47.002",
    POW: "66.005",
    DOFC: "48.006",
    LOVELL: "36.001",
    WIMB: "46.001",
    STG: "70.007",
    KINGS: "100.01",
    DAY1_POS: "391",
    DAY2_POS: "187",
    DAY3_POS: "410",
    DAY4_POS: "541",
    DAY5_POS: "333",
  },
  6587: {
    Index: "6587",
    Init: "WA",
    NAME: "Morriss",
    full_NAME: "WA Morriss",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "140.014",
    GUNSCORE_DAY3: "165.014",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "99.011",
    GUNSCORE_GA: "736.069",
    DAILY_T: "74.008",
    DONEGALL: "50.006",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "49.005",
    CORP: "43.004",
    TIMES: "49.007",
    POW: "71.005",
    DOFC: "45.002",
    LOVELL: "43.001",
    WIMB: "50.005",
    STG: "69.006",
    KINGS: "99.011",
    DAY1_POS: "217",
    DAY2_POS: "283",
    DAY3_POS: "289",
    DAY4_POS: "395",
    DAY5_POS: "383",
  },
  6590: {
    Index: "6590",
    Init: "PEV",
    NAME: "Osmond",
    full_NAME: "PEV Osmond",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.012",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "160.012",
    GUNSCORE_DAY4: "165.015",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "730.058",
    DAILY_T: "74.007",
    DONEGALL: "47.002",
    CONAN_DOYLE: "50.003",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "45.002",
    CORP: "43.003",
    TIMES: "47.004",
    POW: "66.005",
    DOFC: "47.003",
    LOVELL: "48.002",
    WIMB: "48.007",
    STG: "69.006",
    KINGS: "96.007",
    DAY1_POS: "196",
    DAY2_POS: "367",
    DAY3_POS: "443",
    DAY4_POS: "282",
    DAY5_POS: "512",
  },
  6592: {
    Index: "6592",
    Init: "A",
    NAME: "Patel",
    full_NAME: "A Patel",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "10",
    Day_3_Total: "10",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "171.015",
    GUNSCORE_DAY4: "165.015",
    GUNSCORE_DAY5: "100.012",
    GUNSCORE_GA: "747.07",
    DAILY_T: "73.007",
    DONEGALL: "47.004",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "48.005",
    CORP: "46.003",
    TIMES: "50.008",
    POW: "71.003",
    DOFC: "50.004",
    LOVELL: "45.003",
    WIMB: "49.005",
    STG: "71.007",
    KINGS: "100.012",
    DAY1_POS: "271",
    DAY2_POS: "216",
    DAY3_POS: "51",
    DAY4_POS: "282",
    DAY5_POS: "321",
  },
  6594: {
    Index: "6594",
    Init: "JN",
    NAME: "Prout",
    full_NAME: "JN Prout",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "135.011",
    GUNSCORE_DAY3: "163.011",
    GUNSCORE_DAY4: "161.014",
    GUNSCORE_DAY5: "97.003",
    GUNSCORE_GA: "721.051",
    DAILY_T: "67.002",
    DONEGALL: "49.004",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "44.003",
    CORP: "44.002",
    TIMES: "48.002",
    POW: "66.002",
    DOFC: "49.007",
    LOVELL: "45.003",
    WIMB: "46.004",
    STG: "70.007",
    KINGS: "97.003",
    DAY1_POS: "425",
    DAY2_POS: "439",
    DAY3_POS: "354",
    DAY4_POS: "408",
    DAY5_POS: "503",
  },
  6595: {
    Index: "6595",
    Init: "OM",
    NAME: "Pryke",
    full_NAME: "OM Pryke",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "172.02",
    GUNSCORE_DAY2: "135.011",
    GUNSCORE_DAY3: "168.017",
    GUNSCORE_DAY4: "162.015",
    GUNSCORE_DAY5: "104.011",
    GUNSCORE_GA: "741.074",
    DAILY_T: "74.01",
    DONEGALL: "48.006",
    CONAN_DOYLE: "50.004",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "46.003",
    CORP: "40.001",
    TIMES: "48.005",
    POW: "71.007",
    DOFC: "49.005",
    LOVELL: "44.001",
    WIMB: "47.004",
    STG: "71.01",
    KINGS: "104.011",
    DAY1_POS: "107",
    DAY2_POS: "439",
    DAY3_POS: "143",
    DAY4_POS: "391",
    DAY5_POS: "63",
  },
  6597: {
    Index: "6597",
    Init: "KM",
    NAME: "Rivoallan",
    full_NAME: "KM Rivoallan",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.018",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "162.007",
    GUNSCORE_DAY4: "160.009",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "721.05",
    DAILY_T: "73.007",
    DONEGALL: "46.005",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "41.001",
    CORP: "48.004",
    TIMES: "45.001",
    POW: "70.004",
    DOFC: "47.002",
    LOVELL: "44.002",
    WIMB: "45.002",
    STG: "71.005",
    KINGS: "96.007",
    DAY1_POS: "261",
    DAY2_POS: "466",
    DAY3_POS: "398",
    DAY4_POS: "441",
    DAY5_POS: "512",
  },
  6599: {
    Index: "6599",
    Init: "A",
    NAME: "Ross",
    full_NAME: "A Ross",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.022",
    GUNSCORE_DAY2: "133.008",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "165.016",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "731.072",
    DAILY_T: "74.011",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "43.001",
    CORP: "43.003",
    TIMES: "50.007",
    POW: "72.006",
    DOFC: "39",
    LOVELL: "45.006",
    WIMB: "47.002",
    STG: "73.008",
    KINGS: "103.013",
    DAY1_POS: "246",
    DAY2_POS: "491",
    DAY3_POS: "410",
    DAY4_POS: "277",
    DAY5_POS: "97",
  },
  6603: {
    Index: "6603",
    Init: "J",
    NAME: "Scheuerl",
    full_NAME: "J Scheuerl",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "166.01",
    GUNSCORE_DAY4: "168.018",
    GUNSCORE_DAY5: "98.006",
    GUNSCORE_GA: "737.06",
    DAILY_T: "75.01",
    DONEGALL: "47.001",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "46.005",
    CORP: "43.002",
    TIMES: "47.004",
    POW: "73.005",
    DOFC: "46.001",
    LOVELL: "48.003",
    WIMB: "48.006",
    STG: "72.009",
    KINGS: "98.006",
    DAY1_POS: "311",
    DAY2_POS: "397",
    DAY3_POS: "261",
    DAY4_POS: "158",
    DAY5_POS: "462",
  },
  6604: {
    Index: "6604",
    Init: "LC",
    NAME: "Schwarz",
    full_NAME: "LC Schwarz",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.015",
    GUNSCORE_DAY2: "136.008",
    GUNSCORE_DAY3: "163.012",
    GUNSCORE_DAY4: "154.008",
    GUNSCORE_DAY5: "96.006",
    GUNSCORE_GA: "712.049",
    DAILY_T: "71.006",
    DONEGALL: "45.004",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "47.004",
    CORP: "44.001",
    TIMES: "45.005",
    POW: "72.003",
    DOFC: "46.004",
    LOVELL: "43.002",
    WIMB: "46.002",
    STG: "65.004",
    KINGS: "96.006",
    DAY1_POS: "468",
    DAY2_POS: "425",
    DAY3_POS: "351",
    DAY4_POS: "529",
    DAY5_POS: "521",
  },
  6605: {
    Index: "6605",
    Init: "C",
    NAME: "Sekine",
    full_NAME: "C Sekine",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.008",
    GUNSCORE_DAY2: "131.007",
    GUNSCORE_DAY3: "154.012",
    GUNSCORE_DAY4: "155.01",
    GUNSCORE_DAY5: "94.004",
    GUNSCORE_GA: "693.041",
    DAILY_T: "69.004",
    DONEGALL: "42.002",
    CONAN_DOYLE: "48.002",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "45.002",
    CORP: "40.002",
    TIMES: "46.006",
    POW: "71.006",
    DOFC: "37",
    LOVELL: "44.001",
    WIMB: "43.004",
    STG: "68.005",
    KINGS: "94.004",
    DAY1_POS: "534",
    DAY2_POS: "527",
    DAY3_POS: "541",
    DAY4_POS: "514",
    DAY5_POS: "561",
  },
  6606: {
    Index: "6606",
    Init: "T",
    NAME: "Snaith",
    full_NAME: "T Snaith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.01",
    GUNSCORE_DAY2: "133.01",
    GUNSCORE_DAY3: "163.014",
    GUNSCORE_DAY4: "159.008",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "721.051",
    DAILY_T: "71.002",
    DONEGALL: "47.005",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "44.003",
    CORP: "41.002",
    TIMES: "48.005",
    POW: "71.007",
    DOFC: "44.002",
    LOVELL: "42.002",
    WIMB: "45.001",
    STG: "72.005",
    KINGS: "100.009",
    DAY1_POS: "396",
    DAY2_POS: "486",
    DAY3_POS: "342",
    DAY4_POS: "458",
    DAY5_POS: "346",
  },
  6607: {
    Index: "6607",
    Init: "RP",
    NAME: "Snaith",
    full_NAME: "RP Snaith",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "159.013",
    GUNSCORE_DAY2: "135.006",
    GUNSCORE_DAY3: "154.009",
    GUNSCORE_DAY4: "158.011",
    GUNSCORE_DAY5: "96.005",
    GUNSCORE_GA: "702.044",
    DAILY_T: "70.008",
    DONEGALL: "44.003",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "44.001",
    CORP: "41.001",
    TIMES: "45.002",
    POW: "62.003",
    DOFC: "47.004",
    LOVELL: "43.001",
    WIMB: "48.005",
    STG: "67.005",
    KINGS: "96.005",
    DAY1_POS: "527",
    DAY2_POS: "457",
    DAY3_POS: "544",
    DAY4_POS: "464",
    DAY5_POS: "527",
  },
  6613: {
    Index: "6613",
    Init: "DRM",
    NAME: "Tuke",
    full_NAME: "DRM Tuke",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "152.007",
    GUNSCORE_DAY2: "116.005",
    GUNSCORE_DAY3: "149.006",
    GUNSCORE_DAY4: "145.009",
    GUNSCORE_DAY5: "90.005",
    GUNSCORE_GA: "652.032",
    DAILY_T: "61.001",
    DONEGALL: "45.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "31.001",
    CORP: "40.002",
    TIMES: "46.003",
    POW: "64.003",
    DOFC: "39",
    LOVELL: "34.001",
    WIMB: "41.001",
    STG: "70.007",
    KINGS: "90.005",
    DAY1_POS: "591",
    DAY2_POS: "618",
    DAY3_POS: "587",
    DAY4_POS: "583",
    DAY5_POS: "594",
  },
  6614: {
    Index: "6614",
    Init: "J",
    NAME: "Viney",
    full_NAME: "J Viney",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "137.006",
    GUNSCORE_DAY2: "119.002",
    GUNSCORE_DAY3: "144.004",
    GUNSCORE_DAY4: "148.008",
    GUNSCORE_DAY5: "98.009",
    GUNSCORE_GA: "646.029",
    DAILY_T: "60.003",
    DONEGALL: "35",
    CONAN_DOYLE: "42.003",
    DAILY_MAIL: "39.001",
    ALEXANDRA: "43.001",
    CORP: "37",
    TIMES: "40",
    POW: "64.004",
    DOFC: "40",
    LOVELL: "43.002",
    WIMB: "41.001",
    STG: "64.005",
    KINGS: "98.009",
    DAY1_POS: "625",
    DAY2_POS: "615",
    DAY3_POS: "609",
    DAY4_POS: "569",
    DAY5_POS: "441",
  },
  6615: {
    Index: "6615",
    Init: "PM",
    NAME: "Viney",
    full_NAME: "PM Viney",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.004",
    GUNSCORE_DAY2: "123.004",
    GUNSCORE_DAY3: "145.004",
    GUNSCORE_DAY4: "145.004",
    GUNSCORE_DAY5: "88.004",
    GUNSCORE_GA: "649.02",
    DAILY_T: "65.001",
    DONEGALL: "37",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "42.001",
    ALEXANDRA: "43.002",
    CORP: "38.001",
    TIMES: "40.002",
    POW: "61",
    DOFC: "44.002",
    LOVELL: "40.002",
    WIMB: "43.001",
    STG: "62.001",
    KINGS: "88.004",
    DAY1_POS: "613",
    DAY2_POS: "601",
    DAY3_POS: "604",
    DAY4_POS: "585",
    DAY5_POS: "609",
  },
  6616: {
    Index: "6616",
    Init: "W",
    NAME: "Viney",
    full_NAME: "W Viney",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.01",
    GUNSCORE_DAY2: "134.01",
    GUNSCORE_DAY3: "154.009",
    GUNSCORE_DAY4: "141.01",
    GUNSCORE_DAY5: "96.004",
    GUNSCORE_GA: "687.043",
    DAILY_T: "71.005",
    DONEGALL: "44.001",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "43.002",
    CORP: "44.005",
    TIMES: "43.002",
    POW: "68.005",
    DOFC: "43.002",
    LOVELL: "32.002",
    WIMB: "44.003",
    STG: "65.005",
    KINGS: "96.004",
    DAY1_POS: "503",
    DAY2_POS: "464",
    DAY3_POS: "544",
    DAY4_POS: "605",
    DAY5_POS: "531",
  },
  6620: {
    Index: "6620",
    Init: "RE",
    NAME: "Warden",
    full_NAME: "RE Warden",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "169.017",
    GUNSCORE_DAY2: "142.01",
    GUNSCORE_DAY3: "162.018",
    GUNSCORE_DAY4: "167.014",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "741.066",
    DAILY_T: "70.007",
    DONEGALL: "49.007",
    CONAN_DOYLE: "50.003",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "48.003",
    CORP: "48.005",
    TIMES: "48.004",
    POW: "69.009",
    DOFC: "45.005",
    LOVELL: "50.003",
    WIMB: "46.003",
    STG: "71.008",
    KINGS: "101.007",
    DAY1_POS: "267",
    DAY2_POS: "229",
    DAY3_POS: "366",
    DAY4_POS: "203",
    DAY5_POS: "304",
  },
  6623: {
    Index: "6623",
    Init: "A",
    NAME: "Wild",
    full_NAME: "A Wild",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.013",
    GUNSCORE_DAY2: "132.007",
    GUNSCORE_DAY3: "152.009",
    GUNSCORE_DAY4: "161.012",
    GUNSCORE_DAY5: "93.005",
    GUNSCORE_GA: "695.046",
    DAILY_T: "67.006",
    DONEGALL: "48.006",
    CONAN_DOYLE: "42.001",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "46.002",
    CORP: "39.001",
    TIMES: "47.002",
    POW: "57.003",
    DOFC: "48.004",
    LOVELL: "47.002",
    WIMB: "45.003",
    STG: "69.007",
    KINGS: "93.005",
    DAY1_POS: "551",
    DAY2_POS: "513",
    DAY3_POS: "565",
    DAY4_POS: "412",
    DAY5_POS: "568",
  },
  6624: {
    Index: "6624",
    Init: "D",
    NAME: "Wild",
    full_NAME: "D Wild",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.009",
    GUNSCORE_DAY2: "138.017",
    GUNSCORE_DAY3: "158.01",
    GUNSCORE_DAY4: "163.018",
    GUNSCORE_DAY5: "101.009",
    GUNSCORE_GA: "721.063",
    DAILY_T: "70.005",
    DONEGALL: "48.002",
    CONAN_DOYLE: "43.002",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "47.006",
    CORP: "44.006",
    TIMES: "49.007",
    POW: "65.001",
    DOFC: "44.002",
    LOVELL: "45.004",
    WIMB: "46.004",
    STG: "72.01",
    KINGS: "101.009",
    DAY1_POS: "511",
    DAY2_POS: "360",
    DAY3_POS: "493",
    DAY4_POS: "342",
    DAY5_POS: "286",
  },
  6625: {
    Index: "6625",
    Init: "JL",
    NAME: "Williams",
    full_NAME: "JL Williams",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.011",
    GUNSCORE_DAY2: "132.009",
    GUNSCORE_DAY3: "164.013",
    GUNSCORE_DAY4: "152.008",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "717.05",
    DAILY_T: "72.004",
    DONEGALL: "49.004",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "47.005",
    CORP: "39.001",
    TIMES: "48.006",
    POW: "70.005",
    DOFC: "46.002",
    LOVELL: "44.003",
    WIMB: "43",
    STG: "65.005",
    KINGS: "102.009",
    DAY1_POS: "364",
    DAY2_POS: "508",
    DAY3_POS: "327",
    DAY4_POS: "544",
    DAY5_POS: "216",
  },
  6627: {
    Index: "6627",
    Init: "P",
    NAME: "Wright",
    full_NAME: "P Wright",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.016",
    GUNSCORE_DAY2: "130.004",
    GUNSCORE_DAY3: "151.007",
    GUNSCORE_DAY4: "114.006",
    GUNSCORE_DAY5: "95.008",
    GUNSCORE_GA: "654.041",
    DAILY_T: "71.006",
    DONEGALL: "49.006",
    CONAN_DOYLE: "44.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "44.002",
    CORP: "39",
    TIMES: "45.004",
    POW: "59",
    DOFC: "47.003",
    LOVELL: "10",
    WIMB: "41.003",
    STG: "63.003",
    KINGS: "95.008",
    DAY1_POS: "438",
    DAY2_POS: "544",
    DAY3_POS: "573",
    DAY4_POS: "628",
    DAY5_POS: "537",
  },
  6631: {
    Index: "6631",
    Init: "A",
    NAME: "Zhang",
    full_NAME: "A Zhang",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "139.008",
    GUNSCORE_DAY3: "163.014",
    GUNSCORE_DAY4: "160.013",
    GUNSCORE_DAY5: "98.011",
    GUNSCORE_GA: "723.058",
    DAILY_T: "70.002",
    DONEGALL: "48.005",
    CONAN_DOYLE: "45.005",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "45.004",
    CORP: "47.001",
    TIMES: "42.001",
    POW: "74.009",
    DOFC: "47.004",
    LOVELL: "44.005",
    WIMB: "48.006",
    STG: "68.002",
    KINGS: "98.011",
    DAY1_POS: "475",
    DAY2_POS: "352",
    DAY3_POS: "342",
    DAY4_POS: "431",
    DAY5_POS: "435",
  },
  6634: {
    Index: "6634",
    Init: "ICL",
    NAME: "Bartlette",
    full_NAME: "ICL Bartlette",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.02",
    GUNSCORE_DAY2: "131.006",
    GUNSCORE_DAY3: "150.008",
    GUNSCORE_DAY4: "147.008",
    GUNSCORE_DAY5: "92.005",
    GUNSCORE_GA: "688.047",
    DAILY_T: "71.009",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "44.002",
    CORP: "39",
    TIMES: "40.002",
    POW: "69.004",
    DOFC: "41.002",
    LOVELL: "45.002",
    WIMB: "41.003",
    STG: "61.003",
    KINGS: "92.005",
    DAY1_POS: "294",
    DAY2_POS: "530",
    DAY3_POS: "577",
    DAY4_POS: "577",
    DAY5_POS: "581",
  },
  6636: {
    Index: "6636",
    Init: "MP",
    NAME: "Blythe",
    full_NAME: "MP Blythe",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.017",
    GUNSCORE_DAY2: "140.009",
    GUNSCORE_DAY3: "161.014",
    GUNSCORE_DAY4: "170.019",
    GUNSCORE_DAY5: "102.009",
    GUNSCORE_GA: "741.068",
    DAILY_T: "74.01",
    DONEGALL: "48.002",
    CONAN_DOYLE: "46.005",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "47.002",
    CORP: "44.002",
    TIMES: "47.005",
    POW: "72.005",
    DOFC: "42.004",
    LOVELL: "48.006",
    WIMB: "48.004",
    STG: "74.009",
    KINGS: "102.009",
    DAY1_POS: "304",
    DAY2_POS: "315",
    DAY3_POS: "404",
    DAY4_POS: "75",
    DAY5_POS: "216",
  },
  6637: {
    Index: "6637",
    Init: "TJ",
    NAME: "Bourne",
    full_NAME: "TJ Bourne",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "15",
    GUNSCORE_DAY1: "172.019",
    GUNSCORE_DAY2: "141.014",
    GUNSCORE_DAY3: "166.016",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "102.008",
    GUNSCORE_GA: "746.07",
    DAILY_T: "74.007",
    DONEGALL: "48.006",
    CONAN_DOYLE: "50.006",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "50.007",
    CORP: "42.003",
    TIMES: "50.008",
    POW: "69.005",
    DOFC: "47.003",
    LOVELL: "47.002",
    WIMB: "47.003",
    STG: "71.008",
    KINGS: "102.008",
    DAY1_POS: "116",
    DAY2_POS: "240",
    DAY3_POS: "231",
    DAY4_POS: "294",
    DAY5_POS: "225",
  },
  6642: {
    Index: "6642",
    Init: "GE",
    NAME: "Bush",
    full_NAME: "GE Bush",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "140.009",
    GUNSCORE_DAY2: "132.008",
    GUNSCORE_DAY3: "145.007",
    GUNSCORE_DAY4: "150.005",
    GUNSCORE_DAY5: "98.01",
    GUNSCORE_GA: "665.039",
    DAILY_T: "66.002",
    DONEGALL: "27.001",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "45.005",
    CORP: "42.001",
    TIMES: "42.001",
    POW: "66.006",
    DOFC: "37",
    LOVELL: "39.001",
    WIMB: "44.003",
    STG: "67.001",
    KINGS: "98.01",
    DAY1_POS: "623",
    DAY2_POS: "511",
    DAY3_POS: "600",
    DAY4_POS: "560",
    DAY5_POS: "436",
  },
  6643: {
    Index: "6643",
    Init: "HAB",
    NAME: "Cardozo",
    full_NAME: "HAB Cardozo",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "120.003",
    GUNSCORE_DAY3: "151.006",
    GUNSCORE_DAY4: "158.01",
    GUNSCORE_DAY5: "93.007",
    GUNSCORE_GA: "685.038",
    DAILY_T: "71.007",
    DONEGALL: "43.002",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "43.001",
    ALEXANDRA: "44.002",
    CORP: "33",
    TIMES: "40",
    POW: "68.004",
    DOFC: "43.002",
    LOVELL: "44.001",
    WIMB: "47.005",
    STG: "67.004",
    KINGS: "93.007",
    DAY1_POS: "475",
    DAY2_POS: "610",
    DAY3_POS: "574",
    DAY4_POS: "466",
    DAY5_POS: "565",
  },
  6644: {
    Index: "6644",
    Init: "CHY",
    NAME: "Chan",
    full_NAME: "CHY Chan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.02",
    GUNSCORE_DAY2: "133.006",
    GUNSCORE_DAY3: "161.013",
    GUNSCORE_DAY4: "164.016",
    GUNSCORE_DAY5: "97.008",
    GUNSCORE_GA: "723.063",
    DAILY_T: "72.007",
    DONEGALL: "49.007",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "47.003",
    CORP: "42.001",
    TIMES: "47.003",
    POW: "69.006",
    DOFC: "45.004",
    LOVELL: "42.003",
    WIMB: "48.006",
    STG: "74.007",
    KINGS: "97.008",
    DAY1_POS: "294",
    DAY2_POS: "500",
    DAY3_POS: "410",
    DAY4_POS: "313",
    DAY5_POS: "477",
  },
  6646: {
    Index: "6646",
    Init: "I",
    NAME: "Cotillard",
    full_NAME: "I Cotillard",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.011",
    GUNSCORE_DAY2: "131.007",
    GUNSCORE_DAY3: "142.004",
    GUNSCORE_DAY4: "154.011",
    GUNSCORE_DAY5: "87.002",
    GUNSCORE_GA: "671.035",
    DAILY_T: "68.003",
    DONEGALL: "44.004",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "43.001",
    CORP: "42.002",
    TIMES: "42.001",
    POW: "59.003",
    DOFC: "41",
    LOVELL: "47.004",
    WIMB: "42.003",
    STG: "65.004",
    KINGS: "87.002",
    DAY1_POS: "557",
    DAY2_POS: "527",
    DAY3_POS: "615",
    DAY4_POS: "526",
    DAY5_POS: "614",
  },
  6647: {
    Index: "6647",
    Init: "FWC",
    NAME: "Cowling",
    full_NAME: "FWC Cowling",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "163.008",
    GUNSCORE_DAY4: "150.013",
    GUNSCORE_DAY5: "98.013",
    GUNSCORE_GA: "713.056",
    DAILY_T: "69.005",
    DONEGALL: "48.005",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "44.002",
    CORP: "47.004",
    TIMES: "46.002",
    POW: "73.004",
    DOFC: "44.002",
    LOVELL: "33.002",
    WIMB: "45.003",
    STG: "72.008",
    KINGS: "98.013",
    DAY1_POS: "475",
    DAY2_POS: "338",
    DAY3_POS: "363",
    DAY4_POS: "553",
    DAY5_POS: "433",
  },
  6649: {
    Index: "6649",
    Init: "B",
    NAME: "Cunningham",
    full_NAME: "B Cunningham",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "156.008",
    GUNSCORE_DAY2: "134.008",
    GUNSCORE_DAY3: "161.014",
    GUNSCORE_DAY4: "162.02",
    GUNSCORE_DAY5: "98.005",
    GUNSCORE_GA: "711.055",
    DAILY_T: "69.005",
    DONEGALL: "43.001",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "44.004",
    CORP: "40",
    TIMES: "46.005",
    POW: "66.003",
    DOFC: "49.006",
    LOVELL: "42.002",
    WIMB: "48.007",
    STG: "72.011",
    KINGS: "98.005",
    DAY1_POS: "572",
    DAY2_POS: "474",
    DAY3_POS: "404",
    DAY4_POS: "382",
    DAY5_POS: "465",
  },
  6650: {
    Index: "6650",
    Init: "JM",
    NAME: "Di Bona",
    full_NAME: "JM Di Bona",
    Day_1: "23",
    Day_1_BONUS: "15",
    Day_1_Total: "38",
    Day_2: "7",
    Day_2_BONUS: "5",
    Day_2_Total: "12",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "5",
    Day_4_BONUS: "5",
    Day_4_Total: "10",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "21",
    FINAL_TOTAL: "81",
    GUNSCORE_DAY1: "175.026",
    GUNSCORE_DAY2: "147.02",
    GUNSCORE_DAY3: "168.016",
    GUNSCORE_DAY4: "172.024",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "764.096",
    DAILY_T: "75.012",
    DONEGALL: "50.009",
    CONAN_DOYLE: "50.005",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "49.007",
    CORP: "48.005",
    TIMES: "49.006",
    POW: "74.006",
    DOFC: "45.004",
    LOVELL: "48.004",
    WIMB: "50.008",
    STG: "74.012",
    KINGS: "102.01",
    DAY1_POS: "4",
    DAY2_POS: "21",
    DAY3_POS: "147",
    DAY4_POS: "28",
    DAY5_POS: "202",
  },
  6651: {
    Index: "6651",
    Init: "M",
    NAME: "Di Bona",
    full_NAME: "M Di Bona",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "141.013",
    GUNSCORE_DAY3: "160.017",
    GUNSCORE_DAY4: "169.021",
    GUNSCORE_DAY5: "97.009",
    GUNSCORE_GA: "736.076",
    DAILY_T: "75.01",
    DONEGALL: "47.001",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.004",
    CORP: "47.005",
    TIMES: "47.004",
    POW: "70.009",
    DOFC: "43.004",
    LOVELL: "48.007",
    WIMB: "48.006",
    STG: "73.008",
    KINGS: "97.009",
    DAY1_POS: "271",
    DAY2_POS: "245",
    DAY3_POS: "435",
    DAY4_POS: "94",
    DAY5_POS: "473",
  },
  6652: {
    Index: "6652",
    Init: "AB",
    NAME: "Dorey",
    full_NAME: "AB Dorey",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.017",
    GUNSCORE_DAY2: "141.013",
    GUNSCORE_DAY3: "162.01",
    GUNSCORE_DAY4: "164.01",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "728.057",
    DAILY_T: "71.006",
    DONEGALL: "48.005",
    CONAN_DOYLE: "43.006",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.006",
    CORP: "46.002",
    TIMES: "49.004",
    POW: "70.006",
    DOFC: "43",
    LOVELL: "43.002",
    WIMB: "49.002",
    STG: "72.006",
    KINGS: "99.007",
    DAY1_POS: "492",
    DAY2_POS: "245",
    DAY3_POS: "392",
    DAY4_POS: "338",
    DAY5_POS: "413",
  },
  6653: {
    Index: "6653",
    Init: "SA",
    NAME: "Dudler",
    full_NAME: "SA Dudler",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.012",
    GUNSCORE_DAY2: "123.002",
    GUNSCORE_DAY3: "154.007",
    GUNSCORE_DAY4: "147.004",
    GUNSCORE_DAY5: "93.004",
    GUNSCORE_GA: "676.029",
    DAILY_T: "68.005",
    DONEGALL: "45.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "43.002",
    ALEXANDRA: "45",
    CORP: "35",
    TIMES: "40.001",
    POW: "69.003",
    DOFC: "45.003",
    LOVELL: "42.002",
    WIMB: "45.001",
    STG: "60.001",
    KINGS: "93.004",
    DAY1_POS: "530",
    DAY2_POS: "602",
    DAY3_POS: "548",
    DAY4_POS: "578",
    DAY5_POS: "570",
  },
  6655: {
    Index: "6655",
    Init: "S",
    NAME: "Farrow",
    full_NAME: "S Farrow",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.011",
    GUNSCORE_DAY2: "128.003",
    GUNSCORE_DAY3: "158.011",
    GUNSCORE_DAY4: "143.008",
    GUNSCORE_DAY5: "101.008",
    GUNSCORE_GA: "695.041",
    DAILY_T: "70.004",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "44.001",
    ALEXANDRA: "43.001",
    CORP: "41.001",
    TIMES: "45.004",
    POW: "69.003",
    DOFC: "44.004",
    LOVELL: "35",
    WIMB: "41.001",
    STG: "67.007",
    KINGS: "101.008",
    DAY1_POS: "432",
    DAY2_POS: "574",
    DAY3_POS: "488",
    DAY4_POS: "595",
    DAY5_POS: "295",
  },
  6657: {
    Index: "6657",
    Init: "TLM",
    NAME: "Flanagan",
    full_NAME: "TLM Flanagan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "163.01",
    GUNSCORE_DAY2: "140.013",
    GUNSCORE_DAY3: "160.01",
    GUNSCORE_DAY4: "170.02",
    GUNSCORE_DAY5: "100.007",
    GUNSCORE_GA: "733.06",
    DAILY_T: "71.003",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "45.003",
    CORP: "46.003",
    TIMES: "47.002",
    POW: "70.004",
    DOFC: "43.004",
    LOVELL: "47.003",
    WIMB: "48.005",
    STG: "75.012",
    KINGS: "100.007",
    DAY1_POS: "486",
    DAY2_POS: "288",
    DAY3_POS: "447",
    DAY4_POS: "66",
    DAY5_POS: "366",
  },
  6659: {
    Index: "6659",
    Init: "AC",
    NAME: "Gauci",
    full_NAME: "AC Gauci",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.012",
    GUNSCORE_DAY2: "139.009",
    GUNSCORE_DAY3: "162.011",
    GUNSCORE_DAY4: "144.009",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "702.051",
    DAILY_T: "74.008",
    DONEGALL: "46.004",
    CONAN_DOYLE: "37",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "48.002",
    CORP: "42.002",
    TIMES: "49.005",
    POW: "71.005",
    DOFC: "42.001",
    LOVELL: "32",
    WIMB: "38.001",
    STG: "74.008",
    KINGS: "100.01",
    DAY1_POS: "553",
    DAY2_POS: "346",
    DAY3_POS: "388",
    DAY4_POS: "589",
    DAY5_POS: "333",
  },
  6662: {
    Index: "6662",
    Init: "PL",
    NAME: "Green",
    full_NAME: "PL Green",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "159.013",
    GUNSCORE_DAY2: "132.007",
    GUNSCORE_DAY3: "154.009",
    GUNSCORE_DAY4: "156.01",
    GUNSCORE_DAY5: "96.006",
    GUNSCORE_GA: "697.045",
    DAILY_T: "73.006",
    DONEGALL: "36",
    CONAN_DOYLE: "50.007",
    DAILY_MAIL: "45.001",
    ALEXANDRA: "45.002",
    CORP: "42.004",
    TIMES: "38",
    POW: "70.007",
    DOFC: "46.002",
    LOVELL: "43.002",
    WIMB: "48.005",
    STG: "65.003",
    KINGS: "96.006",
    DAY1_POS: "527",
    DAY2_POS: "513",
    DAY3_POS: "544",
    DAY4_POS: "506",
    DAY5_POS: "521",
  },
  6663: {
    Index: "6663",
    Init: "A",
    NAME: "Greenlees",
    full_NAME: "A Greenlees",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "137.001",
    GUNSCORE_DAY2: "114.007",
    GUNSCORE_DAY3: "135.006",
    GUNSCORE_DAY4: "140.005",
    GUNSCORE_DAY5: "84.005",
    GUNSCORE_GA: "610.024",
    DAILY_T: "56.001",
    DONEGALL: "38",
    CONAN_DOYLE: "43",
    DAILY_MAIL: "42.003",
    ALEXANDRA: "43.002",
    CORP: "29.002",
    TIMES: "44.002",
    POW: "58.004",
    DOFC: "33",
    LOVELL: "41.002",
    WIMB: "41.002",
    STG: "58.001",
    KINGS: "84.005",
    DAY1_POS: "626",
    DAY2_POS: "623",
    DAY3_POS: "623",
    DAY4_POS: "610",
    DAY5_POS: "623",
  },
  6664: {
    Index: "6664",
    Init: "M",
    NAME: "Griesemann",
    full_NAME: "M Griesemann",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.011",
    GUNSCORE_DAY2: "132.003",
    GUNSCORE_DAY3: "167.015",
    GUNSCORE_DAY4: "163.015",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "727.054",
    DAILY_T: "69.005",
    DONEGALL: "47.003",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "47",
    ALEXANDRA: "48.002",
    CORP: "37.001",
    TIMES: "48.005",
    POW: "70.005",
    DOFC: "49.005",
    LOVELL: "46.003",
    WIMB: "46.004",
    STG: "71.008",
    KINGS: "102.01",
    DAY1_POS: "483",
    DAY2_POS: "518",
    DAY3_POS: "195",
    DAY4_POS: "349",
    DAY5_POS: "202",
  },
  6665: {
    Index: "6665",
    Init: "G",
    NAME: "Griffiths",
    full_NAME: "G Griffiths",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "134.004",
    GUNSCORE_DAY2: "119.004",
    GUNSCORE_DAY3: "134.002",
    GUNSCORE_DAY4: "138.006",
    GUNSCORE_DAY5: "85.005",
    GUNSCORE_GA: "610.021",
    DAILY_T: "61.001",
    DONEGALL: "36.002",
    CONAN_DOYLE: "37.001",
    DAILY_MAIL: "41.002",
    ALEXANDRA: "40",
    CORP: "38.002",
    TIMES: "30",
    POW: "64.002",
    DOFC: "40",
    LOVELL: "34",
    WIMB: "42.004",
    STG: "62.002",
    KINGS: "85.005",
    DAY1_POS: "627",
    DAY2_POS: "614",
    DAY3_POS: "625",
    DAY4_POS: "614",
    DAY5_POS: "620",
  },
  6666: {
    Index: "6666",
    Init: "DK",
    NAME: "Hakim",
    full_NAME: "DK Hakim",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "149.01",
    GUNSCORE_DAY2: "124.005",
    GUNSCORE_DAY3: "143.008",
    GUNSCORE_DAY4: "143.007",
    GUNSCORE_DAY5: "89.003",
    GUNSCORE_GA: "648.033",
    DAILY_T: "66.004",
    DONEGALL: "40.003",
    CONAN_DOYLE: "43.003",
    DAILY_MAIL: "40",
    ALEXANDRA: "46.004",
    CORP: "38.001",
    TIMES: "46.005",
    POW: "61.003",
    DOFC: "36",
    LOVELL: "39",
    WIMB: "35.001",
    STG: "69.006",
    KINGS: "89.003",
    DAY1_POS: "604",
    DAY2_POS: "596",
    DAY3_POS: "612",
    DAY4_POS: "596",
    DAY5_POS: "602",
  },
  6669: {
    Index: "6669",
    Init: "CD",
    NAME: "Hiles",
    full_NAME: "CD Hiles",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "151.008",
    GUNSCORE_DAY2: "117.003",
    GUNSCORE_DAY3: "152.01",
    GUNSCORE_DAY4: "153.007",
    GUNSCORE_DAY5: "96.008",
    GUNSCORE_GA: "669.036",
    DAILY_T: "65.002",
    DONEGALL: "45.005",
    CONAN_DOYLE: "41.001",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "33",
    CORP: "39.001",
    TIMES: "45.004",
    POW: "63.004",
    DOFC: "44.002",
    LOVELL: "39.001",
    WIMB: "46.003",
    STG: "68.003",
    KINGS: "96.008",
    DAY1_POS: "595",
    DAY2_POS: "616",
    DAY3_POS: "564",
    DAY4_POS: "534",
    DAY5_POS: "508",
  },
  6674: {
    Index: "6674",
    Init: "L",
    NAME: "Jack",
    full_NAME: "L Jack",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.009",
    GUNSCORE_DAY2: "113.005",
    GUNSCORE_DAY3: "154.01",
    GUNSCORE_DAY4: "152.006",
    GUNSCORE_DAY5: "98.006",
    GUNSCORE_GA: "674.036",
    DAILY_T: "68.004",
    DONEGALL: "45.003",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "44.002",
    CORP: "24",
    TIMES: "46.005",
    POW: "63.003",
    DOFC: "45.002",
    LOVELL: "42.002",
    WIMB: "48.002",
    STG: "62.002",
    KINGS: "98.006",
    DAY1_POS: "562",
    DAY2_POS: "624",
    DAY3_POS: "543",
    DAY4_POS: "548",
    DAY5_POS: "462",
  },
  6676: {
    Index: "6676",
    Init: "AE",
    NAME: "Jallot",
    full_NAME: "AE Jallot",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.02",
    GUNSCORE_DAY2: "137.012",
    GUNSCORE_DAY3: "162.013",
    GUNSCORE_DAY4: "162.013",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "727.065",
    DAILY_T: "74.01",
    DONEGALL: "47.003",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "45.001",
    CORP: "42.003",
    TIMES: "49.004",
    POW: "67.005",
    DOFC: "46.004",
    LOVELL: "46.004",
    WIMB: "46.003",
    STG: "70.006",
    KINGS: "96.007",
    DAY1_POS: "206",
    DAY2_POS: "393",
    DAY3_POS: "377",
    DAY4_POS: "394",
    DAY5_POS: "512",
  },
  6679: {
    Index: "6679",
    Init: "HS",
    NAME: "Jones",
    full_NAME: "HS Jones",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "132.008",
    GUNSCORE_DAY2: "86.001",
    GUNSCORE_DAY3: "138.005",
    GUNSCORE_DAY4: "115",
    GUNSCORE_DAY5: "82.002",
    GUNSCORE_GA: "553.016",
    DAILY_T: "61.003",
    DONEGALL: "35.001",
    CONAN_DOYLE: "36.004",
    DAILY_MAIL: "37.001",
    ALEXANDRA: "35",
    CORP: "14",
    TIMES: "36.002",
    POW: "58.002",
    DOFC: "44.001",
    LOVELL: "34",
    WIMB: "36",
    STG: "45",
    KINGS: "82.002",
    DAY1_POS: "629",
    DAY2_POS: "636",
    DAY3_POS: "621",
    DAY4_POS: "627",
    DAY5_POS: "626",
  },
  6681: {
    Index: "6681",
    Init: "G",
    NAME: "Kendall",
    full_NAME: "G Kendall",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "155.008",
    GUNSCORE_DAY2: "137.009",
    GUNSCORE_DAY3: "154.006",
    GUNSCORE_DAY4: "148.01",
    GUNSCORE_DAY5: "90.003",
    GUNSCORE_GA: "684.036",
    DAILY_T: "66.001",
    DONEGALL: "44.003",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "47.003",
    CORP: "43.003",
    TIMES: "43.001",
    POW: "67.003",
    DOFC: "44.002",
    LOVELL: "40.003",
    WIMB: "49.003",
    STG: "59.004",
    KINGS: "90.003",
    DAY1_POS: "575",
    DAY2_POS: "406",
    DAY3_POS: "549",
    DAY4_POS: "567",
    DAY5_POS: "597",
  },
  6685: {
    Index: "6685",
    Init: "JRW",
    NAME: "Latimer",
    full_NAME: "JRW Latimer",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "138.01",
    GUNSCORE_DAY3: "159.012",
    GUNSCORE_DAY4: "164.013",
    GUNSCORE_DAY5: "95.008",
    GUNSCORE_GA: "725.059",
    DAILY_T: "73.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.003",
    CORP: "43.002",
    TIMES: "48.005",
    POW: "66.004",
    DOFC: "45.003",
    LOVELL: "46.003",
    WIMB: "47.002",
    STG: "71.008",
    KINGS: "95.008",
    DAY1_POS: "271",
    DAY2_POS: "377",
    DAY3_POS: "464",
    DAY4_POS: "325",
    DAY5_POS: "537",
  },
  6687: {
    Index: "6687",
    Init: "A",
    NAME: "Lebedeva",
    full_NAME: "A Lebedeva",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "167.012",
    GUNSCORE_DAY2: "128.011",
    GUNSCORE_DAY3: "153.007",
    GUNSCORE_DAY4: "146.011",
    GUNSCORE_DAY5: "97.005",
    GUNSCORE_GA: "691.046",
    DAILY_T: "71.007",
    DONEGALL: "50.004",
    CONAN_DOYLE: "46.001",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "46.003",
    CORP: "32.002",
    TIMES: "37.001",
    POW: "70.002",
    DOFC: "46.004",
    LOVELL: "30.001",
    WIMB: "47.004",
    STG: "69.006",
    KINGS: "97.005",
    DAY1_POS: "362",
    DAY2_POS: "560",
    DAY3_POS: "561",
    DAY4_POS: "579",
    DAY5_POS: "497",
  },
  6688: {
    Index: "6688",
    Init: "A",
    NAME: "Lerwill",
    full_NAME: "A Lerwill",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "142.005",
    GUNSCORE_DAY2: "117.003",
    GUNSCORE_DAY3: "143.006",
    GUNSCORE_DAY4: "141.01",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "639.031",
    DAILY_T: "60.001",
    DONEGALL: "41.003",
    CONAN_DOYLE: "41.001",
    DAILY_MAIL: "45",
    ALEXANDRA: "39.001",
    CORP: "33.002",
    TIMES: "41",
    POW: "61.003",
    DOFC: "41.003",
    LOVELL: "28.001",
    WIMB: "47.003",
    STG: "66.006",
    KINGS: "96.007",
    DAY1_POS: "621",
    DAY2_POS: "616",
    DAY3_POS: "613",
    DAY4_POS: "605",
    DAY5_POS: "512",
  },
  6689: {
    Index: "6689",
    Init: "LKJ",
    NAME: "Leung",
    full_NAME: "LKJ Leung",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "163.015",
    GUNSCORE_DAY2: "142.009",
    GUNSCORE_DAY3: "168.017",
    GUNSCORE_DAY4: "170.017",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "744.069",
    DAILY_T: "74.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "40.002",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "47.002",
    CORP: "47.003",
    TIMES: "50.006",
    POW: "73.007",
    DOFC: "45.004",
    LOVELL: "46.004",
    WIMB: "50.006",
    STG: "74.007",
    KINGS: "101.011",
    DAY1_POS: "468",
    DAY2_POS: "232",
    DAY3_POS: "143",
    DAY4_POS: "82",
    DAY5_POS: "254",
  },
  6693: {
    Index: "6693",
    Init: "AJM",
    NAME: "Lyle",
    full_NAME: "AJM Lyle",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "150.003",
    GUNSCORE_DAY2: "120",
    GUNSCORE_DAY3: "144.005",
    GUNSCORE_DAY4: "148.008",
    GUNSCORE_DAY5: "89.001",
    GUNSCORE_GA: "651.017",
    DAILY_T: "65",
    DONEGALL: "44.002",
    CONAN_DOYLE: "41.001",
    DAILY_MAIL: "40",
    ALEXANDRA: "40",
    CORP: "40",
    TIMES: "39.001",
    POW: "62.001",
    DOFC: "43.003",
    LOVELL: "38.001",
    WIMB: "47.004",
    STG: "63.003",
    KINGS: "89.001",
    DAY1_POS: "603",
    DAY2_POS: "612",
    DAY3_POS: "608",
    DAY4_POS: "569",
    DAY5_POS: "606",
  },
  6694: {
    Index: "6694",
    Init: "KS",
    NAME: "Lynnyk-Ali",
    full_NAME: "KS Lynnyk-Ali",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.016",
    GUNSCORE_DAY2: "132.012",
    GUNSCORE_DAY3: "159.01",
    GUNSCORE_DAY4: "163.01",
    GUNSCORE_DAY5: "103.008",
    GUNSCORE_GA: "725.056",
    DAILY_T: "72.008",
    DONEGALL: "48.003",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "46.003",
    CORP: "37.002",
    TIMES: "47.002",
    POW: "66.004",
    DOFC: "46.004",
    LOVELL: "46.005",
    WIMB: "48.004",
    STG: "69.001",
    KINGS: "103.008",
    DAY1_POS: "309",
    DAY2_POS: "503",
    DAY3_POS: "471",
    DAY4_POS: "375",
    DAY5_POS: "155",
  },
  6698: {
    Index: "6698",
    Init: "GC",
    NAME: "McCaig",
    full_NAME: "GC McCaig",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.021",
    GUNSCORE_DAY2: "136.008",
    GUNSCORE_DAY3: "160.015",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "98.01",
    GUNSCORE_GA: "727.068",
    DAILY_T: "73.009",
    DONEGALL: "49.006",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "44.003",
    CORP: "44.002",
    TIMES: "49.006",
    POW: "69.008",
    DOFC: "42.001",
    LOVELL: "42.002",
    WIMB: "48.002",
    STG: "73.01",
    KINGS: "98.01",
    DAY1_POS: "204",
    DAY2_POS: "425",
    DAY3_POS: "436",
    DAY4_POS: "355",
    DAY5_POS: "436",
  },
  6704: {
    Index: "6704",
    Init: "AR",
    NAME: "Munday",
    full_NAME: "AR Munday",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.009",
    GUNSCORE_DAY2: "88.006",
    GUNSCORE_DAY3: "154.005",
    GUNSCORE_DAY4: "158.009",
    GUNSCORE_DAY5: "92.001",
    GUNSCORE_GA: "655.03",
    DAILY_T: "70.005",
    DONEGALL: "48.003",
    CONAN_DOYLE: "45.001",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "41.004",
    CORP: "0",
    TIMES: "44.003",
    POW: "63",
    DOFC: "47.002",
    LOVELL: "45.003",
    WIMB: "40.002",
    STG: "73.004",
    KINGS: "92.001",
    DAY1_POS: "489",
    DAY2_POS: "635",
    DAY3_POS: "551",
    DAY4_POS: "470",
    DAY5_POS: "588",
  },
  6705: {
    Index: "6705",
    Init: "J",
    NAME: "Myers",
    full_NAME: "J Myers",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "170.014",
    GUNSCORE_DAY2: "138.012",
    GUNSCORE_DAY3: "160.01",
    GUNSCORE_DAY4: "165.014",
    GUNSCORE_DAY5: "98.009",
    GUNSCORE_GA: "731.059",
    DAILY_T: "71.004",
    DONEGALL: "50.005",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "48.006",
    CORP: "43.003",
    TIMES: "47.005",
    POW: "71.003",
    DOFC: "42.002",
    LOVELL: "44.002",
    WIMB: "49.002",
    STG: "72.01",
    KINGS: "98.009",
    DAY1_POS: "239",
    DAY2_POS: "367",
    DAY3_POS: "447",
    DAY4_POS: "287",
    DAY5_POS: "441",
  },
  6706: {
    Index: "6706",
    Init: "AS",
    NAME: "Nanrey",
    full_NAME: "AS Nanrey",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "173.023",
    GUNSCORE_DAY2: "145.016",
    GUNSCORE_DAY3: "169.02",
    GUNSCORE_DAY4: "170.021",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "758.09",
    DAILY_T: "74.008",
    DONEGALL: "50.008",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "48.005",
    CORP: "47.003",
    TIMES: "47.004",
    POW: "73.009",
    DOFC: "49.007",
    LOVELL: "49.006",
    WIMB: "48.005",
    STG: "73.01",
    KINGS: "101.01",
    DAY1_POS: "54",
    DAY2_POS: "75",
    DAY3_POS: "94",
    DAY4_POS: "64",
    DAY5_POS: "269",
  },
  6708: {
    Index: "6708",
    Init: "J",
    NAME: "Nicholas",
    full_NAME: "J Nicholas",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.012",
    GUNSCORE_DAY2: "135.011",
    GUNSCORE_DAY3: "161.01",
    GUNSCORE_DAY4: "165.011",
    GUNSCORE_DAY5: "97.008",
    GUNSCORE_GA: "723.052",
    DAILY_T: "71.007",
    DONEGALL: "48.003",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "48.005",
    CORP: "41.002",
    TIMES: "48.002",
    POW: "67.004",
    DOFC: "46.004",
    LOVELL: "44.002",
    WIMB: "48.003",
    STG: "73.006",
    KINGS: "97.008",
    DAY1_POS: "425",
    DAY2_POS: "439",
    DAY3_POS: "429",
    DAY4_POS: "304",
    DAY5_POS: "477",
  },
  6712: {
    Index: "6712",
    Init: "O",
    NAME: "Parkins",
    full_NAME: "O Parkins",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "150.006",
    GUNSCORE_DAY2: "134.005",
    GUNSCORE_DAY3: "162.014",
    GUNSCORE_DAY4: "160.008",
    GUNSCORE_DAY5: "100.004",
    GUNSCORE_GA: "706.037",
    DAILY_T: "68.003",
    DONEGALL: "41.001",
    CONAN_DOYLE: "41.002",
    DAILY_MAIL: "47.001",
    ALEXANDRA: "43.003",
    CORP: "44.001",
    TIMES: "47.005",
    POW: "72.007",
    DOFC: "43.002",
    LOVELL: "47.002",
    WIMB: "46.003",
    STG: "67.003",
    KINGS: "100.004",
    DAY1_POS: "602",
    DAY2_POS: "479",
    DAY3_POS: "375",
    DAY4_POS: "444",
    DAY5_POS: "376",
  },
  6713: {
    Index: "6713",
    Init: "S",
    NAME: "Pasarkar",
    full_NAME: "S Pasarkar",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.01",
    GUNSCORE_DAY2: "133.013",
    GUNSCORE_DAY3: "159.013",
    GUNSCORE_DAY4: "164.014",
    GUNSCORE_DAY5: "98.005",
    GUNSCORE_GA: "718.055",
    DAILY_T: "67.002",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.002",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "44.004",
    CORP: "43.005",
    TIMES: "45.003",
    POW: "66.004",
    DOFC: "48.006",
    LOVELL: "44.003",
    WIMB: "50.007",
    STG: "70.004",
    KINGS: "98.005",
    DAY1_POS: "459",
    DAY2_POS: "483",
    DAY3_POS: "461",
    DAY4_POS: "323",
    DAY5_POS: "465",
  },
  6714: {
    Index: "6714",
    Init: "P",
    NAME: "Persaud",
    full_NAME: "P Persaud",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.013",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "159.009",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "725.054",
    DAILY_T: "70.006",
    DONEGALL: "46.002",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "49.004",
    CORP: "45.004",
    TIMES: "46.004",
    POW: "68.005",
    DOFC: "45",
    LOVELL: "42.002",
    WIMB: "48.006",
    STG: "73.006",
    KINGS: "97.006",
    DAY1_POS: "448",
    DAY2_POS: "216",
    DAY3_POS: "476",
    DAY4_POS: "355",
    DAY5_POS: "490",
  },
  6716: {
    Index: "6716",
    Init: "O",
    NAME: "Polat",
    full_NAME: "O Polat",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.015",
    GUNSCORE_DAY2: "131.008",
    GUNSCORE_DAY3: "164.012",
    GUNSCORE_DAY4: "158.006",
    GUNSCORE_DAY5: "99.005",
    GUNSCORE_GA: "715.046",
    DAILY_T: "71.006",
    DONEGALL: "45.003",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "45.004",
    CORP: "40.002",
    TIMES: "46.002",
    POW: "70.006",
    DOFC: "48.004",
    LOVELL: "45.001",
    WIMB: "46.002",
    STG: "67.003",
    KINGS: "99.005",
    DAY1_POS: "468",
    DAY2_POS: "522",
    DAY3_POS: "333",
    DAY4_POS: "480",
    DAY5_POS: "428",
  },
  6720: {
    Index: "6720",
    Init: "ETB",
    NAME: "Redman",
    full_NAME: "ETB Redman",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.016",
    GUNSCORE_DAY2: "133.007",
    GUNSCORE_DAY3: "149.007",
    GUNSCORE_DAY4: "155.01",
    GUNSCORE_DAY5: "91.004",
    GUNSCORE_GA: "691.044",
    DAILY_T: "69.006",
    DONEGALL: "48.006",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "46.001",
    ALEXANDRA: "46.004",
    CORP: "41.002",
    TIMES: "45.002",
    POW: "67.003",
    DOFC: "37.002",
    LOVELL: "43.003",
    WIMB: "45.003",
    STG: "67.004",
    KINGS: "91.004",
    DAY1_POS: "466",
    DAY2_POS: "497",
    DAY3_POS: "583",
    DAY4_POS: "514",
    DAY5_POS: "592",
  },
  6722: {
    Index: "6722",
    Init: "OE",
    NAME: "Roussel",
    full_NAME: "OE Roussel",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "167.017",
    GUNSCORE_DAY2: "143.015",
    GUNSCORE_DAY3: "162.015",
    GUNSCORE_DAY4: "158.007",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "726.061",
    DAILY_T: "72.006",
    DONEGALL: "46.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.002",
    ALEXANDRA: "49.007",
    CORP: "46.006",
    TIMES: "47.008",
    POW: "69.003",
    DOFC: "46.004",
    LOVELL: "46.001",
    WIMB: "45.001",
    STG: "67.005",
    KINGS: "96.007",
    DAY1_POS: "343",
    DAY2_POS: "155",
    DAY3_POS: "371",
    DAY4_POS: "478",
    DAY5_POS: "512",
  },
  6725: {
    Index: "6725",
    Init: "M",
    NAME: "Saberton",
    full_NAME: "M Saberton",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.014",
    GUNSCORE_DAY2: "132.01",
    GUNSCORE_DAY3: "160.009",
    GUNSCORE_DAY4: "164.013",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "718.055",
    DAILY_T: "70.007",
    DONEGALL: "43",
    CONAN_DOYLE: "49.007",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "45.003",
    CORP: "40.002",
    TIMES: "46.001",
    POW: "70.004",
    DOFC: "44.004",
    LOVELL: "46.004",
    WIMB: "48.003",
    STG: "70.006",
    KINGS: "100.009",
    DAY1_POS: "495",
    DAY2_POS: "505",
    DAY3_POS: "451",
    DAY4_POS: "325",
    DAY5_POS: "346",
  },
  6726: {
    Index: "6726",
    Init: "NJ",
    NAME: "Sales Scott",
    full_NAME: "NJ Sales Scott",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "171.023",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "168.02",
    GUNSCORE_DAY4: "169.019",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "755.087",
    DAILY_T: "73.01",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "50.007",
    ALEXANDRA: "49.004",
    CORP: "45.002",
    TIMES: "49.005",
    POW: "72.01",
    DOFC: "47.005",
    LOVELL: "46.003",
    WIMB: "49.005",
    STG: "74.011",
    KINGS: "103.012",
    DAY1_POS: "140",
    DAY2_POS: "127",
    DAY3_POS: "129",
    DAY4_POS: "105",
    DAY5_POS: "112",
  },
  6727: {
    Index: "6727",
    Init: "RA",
    NAME: "Sampson",
    full_NAME: "RA Sampson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.017",
    GUNSCORE_DAY2: "130.011",
    GUNSCORE_DAY3: "145.006",
    GUNSCORE_DAY4: "135.006",
    GUNSCORE_DAY5: "93.003",
    GUNSCORE_GA: "669.043",
    DAILY_T: "72.005",
    DONEGALL: "48.006",
    CONAN_DOYLE: "46.006",
    DAILY_MAIL: "43.003",
    ALEXANDRA: "48.006",
    CORP: "39.002",
    TIMES: "39.002",
    POW: "65.004",
    DOFC: "41",
    LOVELL: "38.002",
    WIMB: "32",
    STG: "65.004",
    KINGS: "93.003",
    DAY1_POS: "375",
    DAY2_POS: "536",
    DAY3_POS: "602",
    DAY4_POS: "617",
    DAY5_POS: "571",
  },
  6728: {
    Index: "6728",
    Init: "GE",
    NAME: "Sanderson",
    full_NAME: "GE Sanderson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "168.014",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "157.016",
    GUNSCORE_DAY4: "167.015",
    GUNSCORE_DAY5: "96.008",
    GUNSCORE_GA: "722.062",
    DAILY_T: "71.005",
    DONEGALL: "50.007",
    CONAN_DOYLE: "47.002",
    DAILY_MAIL: "46.005",
    ALEXANDRA: "48.004",
    CORP: "40",
    TIMES: "48.007",
    POW: "71.006",
    DOFC: "38.003",
    LOVELL: "45.002",
    WIMB: "48.004",
    STG: "74.009",
    KINGS: "96.008",
    DAY1_POS: "320",
    DAY2_POS: "466",
    DAY3_POS: "507",
    DAY4_POS: "200",
    DAY5_POS: "508",
  },
  6730: {
    Index: "6730",
    Init: "BD",
    NAME: "Scott Langley",
    full_NAME: "BD Scott Langley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.012",
    GUNSCORE_DAY2: "130.006",
    GUNSCORE_DAY3: "158.008",
    GUNSCORE_DAY4: "161.016",
    GUNSCORE_DAY5: "98.004",
    GUNSCORE_GA: "704.046",
    DAILY_T: "69.005",
    DONEGALL: "42.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "42.002",
    ALEXANDRA: "46.002",
    CORP: "42.002",
    TIMES: "44.002",
    POW: "70.004",
    DOFC: "44.002",
    LOVELL: "46.003",
    WIMB: "46.009",
    STG: "69.004",
    KINGS: "98.004",
    DAY1_POS: "553",
    DAY2_POS: "541",
    DAY3_POS: "501",
    DAY4_POS: "406",
    DAY5_POS: "469",
  },
  6733: {
    Index: "6733",
    Init: "J",
    NAME: "Shen",
    full_NAME: "J Shen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.016",
    GUNSCORE_DAY2: "143.013",
    GUNSCORE_DAY3: "158.011",
    GUNSCORE_DAY4: "159.011",
    GUNSCORE_DAY5: "95.01",
    GUNSCORE_GA: "720.061",
    DAILY_T: "73.009",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.005",
    CORP: "48.003",
    TIMES: "49.007",
    POW: "65.003",
    DOFC: "44.001",
    LOVELL: "42.001",
    WIMB: "45.003",
    STG: "72.007",
    KINGS: "95.01",
    DAY1_POS: "408",
    DAY2_POS: "168",
    DAY3_POS: "488",
    DAY4_POS: "453",
    DAY5_POS: "534",
  },
  6737: {
    Index: "6737",
    Init: "N",
    NAME: "Tercjak",
    full_NAME: "N Tercjak",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.015",
    GUNSCORE_DAY2: "139.01",
    GUNSCORE_DAY3: "159.012",
    GUNSCORE_DAY4: "152.012",
    GUNSCORE_DAY5: "103.009",
    GUNSCORE_GA: "721.058",
    DAILY_T: "70.004",
    DONEGALL: "49.005",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "49.007",
    ALEXANDRA: "45.002",
    CORP: "45.001",
    TIMES: "45.002",
    POW: "69.006",
    DOFC: "45.004",
    LOVELL: "38.001",
    WIMB: "46.004",
    STG: "68.007",
    KINGS: "103.009",
    DAY1_POS: "311",
    DAY2_POS: "338",
    DAY3_POS: "464",
    DAY4_POS: "539",
    DAY5_POS: "147",
  },
  6740: {
    Index: "6740",
    Init: "J",
    NAME: "Tsai",
    full_NAME: "J Tsai",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "164.01",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "162.008",
    GUNSCORE_DAY4: "166.016",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "725.051",
    DAILY_T: "70.003",
    DONEGALL: "47.003",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "45.002",
    CORP: "42.002",
    TIMES: "48.003",
    POW: "71.004",
    DOFC: "43.001",
    LOVELL: "50.006",
    WIMB: "47.003",
    STG: "69.007",
    KINGS: "99.008",
    DAY1_POS: "459",
    DAY2_POS: "466",
    DAY3_POS: "397",
    DAY4_POS: "226",
    DAY5_POS: "402",
  },
  6741: {
    Index: "6741",
    Init: "CSM",
    NAME: "Tuke",
    full_NAME: "CSM Tuke",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.01",
    GUNSCORE_DAY2: "128.008",
    GUNSCORE_DAY3: "157.006",
    GUNSCORE_DAY4: "154.007",
    GUNSCORE_DAY5: "85.004",
    GUNSCORE_GA: "686.035",
    DAILY_T: "71.005",
    DONEGALL: "47.004",
    CONAN_DOYLE: "44.001",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "47.002",
    CORP: "35.004",
    TIMES: "44.002",
    POW: "70.002",
    DOFC: "43.002",
    LOVELL: "41",
    WIMB: "48.004",
    STG: "65.003",
    KINGS: "85.004",
    DAY1_POS: "503",
    DAY2_POS: "564",
    DAY3_POS: "518",
    DAY4_POS: "530",
    DAY5_POS: "621",
  },
  6742: {
    Index: "6742",
    Init: "SE",
    NAME: "Watson",
    full_NAME: "SE Watson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.012",
    GUNSCORE_DAY2: "144.012",
    GUNSCORE_DAY3: "157.007",
    GUNSCORE_DAY4: "155.01",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "711.048",
    DAILY_T: "67.005",
    DONEGALL: "41.002",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "47.003",
    CORP: "49.004",
    TIMES: "47.003",
    POW: "65.003",
    DOFC: "45.001",
    LOVELL: "41.001",
    WIMB: "46.003",
    STG: "68.006",
    KINGS: "98.007",
    DAY1_POS: "553",
    DAY2_POS: "137",
    DAY3_POS: "514",
    DAY4_POS: "514",
    DAY5_POS: "455",
  },
  6743: {
    Index: "6743",
    Init: "AA",
    NAME: "Weinberger",
    full_NAME: "AA Weinberger",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "4",
    Day_5_BONUS: "0",
    Day_5_Total: "4",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "4",
    GUNSCORE_DAY1: "167.016",
    GUNSCORE_DAY2: "140.009",
    GUNSCORE_DAY3: "168.015",
    GUNSCORE_DAY4: "164.015",
    GUNSCORE_DAY5: "104.014",
    GUNSCORE_GA: "743.069",
    DAILY_T: "73.005",
    DONEGALL: "49.007",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "48.006",
    CORP: "45.001",
    TIMES: "49.005",
    POW: "72.008",
    DOFC: "47.002",
    LOVELL: "42.001",
    WIMB: "48.005",
    STG: "74.009",
    KINGS: "104.014",
    DAY1_POS: "348",
    DAY2_POS: "315",
    DAY3_POS: "154",
    DAY4_POS: "316",
    DAY5_POS: "31",
  },
  6744: {
    Index: "6744",
    Init: "RM",
    NAME: "Weinberger",
    full_NAME: "RM Weinberger",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "170.018",
    GUNSCORE_DAY2: "142.012",
    GUNSCORE_DAY3: "162.016",
    GUNSCORE_DAY4: "161.01",
    GUNSCORE_DAY5: "95.005",
    GUNSCORE_GA: "730.061",
    DAILY_T: "73.007",
    DONEGALL: "48.006",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "48.003",
    CORP: "45.005",
    TIMES: "49.004",
    POW: "70.008",
    DOFC: "43.004",
    LOVELL: "42.003",
    WIMB: "49.002",
    STG: "70.005",
    KINGS: "95.005",
    DAY1_POS: "217",
    DAY2_POS: "216",
    DAY3_POS: "367",
    DAY4_POS: "418",
    DAY5_POS: "548",
  },
  6746: {
    Index: "6746",
    Init: "BHC",
    NAME: "Whitley",
    full_NAME: "BHC Whitley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "5",
    Day_3_Total: "5",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "162.013",
    GUNSCORE_DAY2: "129.008",
    GUNSCORE_DAY3: "168.014",
    GUNSCORE_DAY4: "148.008",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "708.053",
    DAILY_T: "72.007",
    DONEGALL: "44.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "47.007",
    ALEXANDRA: "43.001",
    CORP: "39",
    TIMES: "50.004",
    POW: "71.007",
    DOFC: "47.003",
    LOVELL: "35.001",
    WIMB: "44.003",
    STG: "69.004",
    KINGS: "101.01",
    DAY1_POS: "498",
    DAY2_POS: "547",
    DAY3_POS: "164",
    DAY4_POS: "569",
    DAY5_POS: "269",
  },
  6748: {
    Index: "6748",
    Init: "MJ",
    NAME: "Wilson",
    full_NAME: "MJ Wilson",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "170.016",
    GUNSCORE_DAY2: "144.017",
    GUNSCORE_DAY3: "169.017",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "751.073",
    DAILY_T: "72.007",
    DONEGALL: "50.006",
    CONAN_DOYLE: "48.003",
    DAILY_MAIL: "49.006",
    ALEXANDRA: "50.006",
    CORP: "45.005",
    TIMES: "49.004",
    POW: "72.006",
    DOFC: "48.007",
    LOVELL: "48.003",
    WIMB: "48.004",
    STG: "73.008",
    KINGS: "99.008",
    DAY1_POS: "230",
    DAY2_POS: "108",
    DAY3_POS: "105",
    DAY4_POS: "127",
    DAY5_POS: "402",
  },
  6749: {
    Index: "6749",
    Init: "CL",
    NAME: "Wiseman",
    full_NAME: "CL Wiseman",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "141.006",
    GUNSCORE_DAY3: "168.013",
    GUNSCORE_DAY4: "160.011",
    GUNSCORE_DAY5: "100.011",
    GUNSCORE_GA: "740.058",
    DAILY_T: "72.008",
    DONEGALL: "50.006",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "46",
    ALEXANDRA: "48.003",
    CORP: "47.003",
    TIMES: "48.003",
    POW: "72.005",
    DOFC: "48.005",
    LOVELL: "45.002",
    WIMB: "44.001",
    STG: "71.008",
    KINGS: "100.011",
    DAY1_POS: "176",
    DAY2_POS: "281",
    DAY3_POS: "172",
    DAY4_POS: "436",
    DAY5_POS: "323",
  },
  6750: {
    Index: "6750",
    Init: "O",
    NAME: "Witt",
    full_NAME: "O Witt",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.015",
    GUNSCORE_DAY2: "119.005",
    GUNSCORE_DAY3: "158.006",
    GUNSCORE_DAY4: "158.009",
    GUNSCORE_DAY5: "102.007",
    GUNSCORE_GA: "695.042",
    DAILY_T: "66.008",
    DONEGALL: "47.005",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "41.002",
    ALEXANDRA: "41.002",
    CORP: "37.001",
    TIMES: "44.002",
    POW: "65.001",
    DOFC: "49.003",
    LOVELL: "46.003",
    WIMB: "43",
    STG: "69.006",
    KINGS: "102.007",
    DAY1_POS: "537",
    DAY2_POS: "613",
    DAY3_POS: "505",
    DAY4_POS: "470",
    DAY5_POS: "233",
  },
  6762: {
    Index: "6762",
    Init: "GH",
    NAME: "Oliver",
    full_NAME: "GH Oliver",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.009",
    GUNSCORE_DAY2: "131.008",
    GUNSCORE_DAY3: "151.009",
    GUNSCORE_DAY4: "153.007",
    GUNSCORE_DAY5: "94.006",
    GUNSCORE_GA: "683.039",
    DAILY_T: "62.003",
    DONEGALL: "45.003",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "43.002",
    ALEXANDRA: "46.004",
    CORP: "42.002",
    TIMES: "43.002",
    POW: "60.002",
    DOFC: "48.005",
    LOVELL: "39.001",
    WIMB: "42.001",
    STG: "72.005",
    KINGS: "94.006",
    DAY1_POS: "580",
    DAY2_POS: "522",
    DAY3_POS: "571",
    DAY4_POS: "534",
    DAY5_POS: "556",
  },
  6767: {
    Index: "6767",
    Init: "RD",
    NAME: "Ford",
    full_NAME: "RD Ford",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "10",
    GUNSCORE_DAY1: "172.018",
    GUNSCORE_DAY2: "141.016",
    GUNSCORE_DAY3: "167.02",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "480.054",
    DAILY_T: "74.007",
    DONEGALL: "50.007",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "50.008",
    ALEXANDRA: "48.004",
    CORP: "43.004",
    TIMES: "47.006",
    POW: "72.009",
    DOFC: "48.005",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "121",
    DAY2_POS: "237",
    DAY3_POS: "176",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6772: {
    Index: "6772",
    Init: "NA",
    NAME: "Mohideen",
    full_NAME: "NA Mohideen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "171.017",
    GUNSCORE_DAY2: "140.014",
    GUNSCORE_DAY3: "161.011",
    GUNSCORE_DAY4: "160.011",
    GUNSCORE_DAY5: "96.008",
    GUNSCORE_GA: "728.061",
    DAILY_T: "73.005",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "49.006",
    CORP: "43.002",
    TIMES: "44.004",
    POW: "71.004",
    DOFC: "46.003",
    LOVELL: "45.001",
    WIMB: "44.003",
    STG: "71.007",
    KINGS: "96.008",
    DAY1_POS: "176",
    DAY2_POS: "283",
    DAY3_POS: "425",
    DAY4_POS: "436",
    DAY5_POS: "508",
  },
  6773: {
    Index: "6773",
    Init: "AT",
    NAME: "Mohideen",
    full_NAME: "AT Mohideen",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "172.021",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "167.013",
    GUNSCORE_DAY4: "166.02",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "751.077",
    DAILY_T: "74.009",
    DONEGALL: "49.007",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.005",
    CORP: "46.003",
    TIMES: "47.005",
    POW: "72.005",
    DOFC: "48.003",
    LOVELL: "45.006",
    WIMB: "47.003",
    STG: "74.011",
    KINGS: "102.01",
    DAY1_POS: "100",
    DAY2_POS: "127",
    DAY3_POS: "206",
    DAY4_POS: "219",
    DAY5_POS: "202",
  },
  6774: {
    Index: "6774",
    Init: "RA",
    NAME: "Tewari",
    full_NAME: "RA Tewari",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.014",
    GUNSCORE_DAY2: "134.006",
    GUNSCORE_DAY3: "166.013",
    GUNSCORE_DAY4: "156.008",
    GUNSCORE_DAY5: "99.012",
    GUNSCORE_GA: "717.053",
    DAILY_T: "72.006",
    DONEGALL: "43.002",
    CONAN_DOYLE: "47.006",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "46.001",
    CORP: "41.002",
    TIMES: "45.004",
    POW: "73.005",
    DOFC: "48.004",
    LOVELL: "42",
    WIMB: "42.002",
    STG: "72.006",
    KINGS: "99.012",
    DAY1_POS: "495",
    DAY2_POS: "478",
    DAY3_POS: "252",
    DAY4_POS: "510",
    DAY5_POS: "380",
  },
  6777: {
    Index: "6777",
    Init: "SA",
    NAME: "Felicien",
    full_NAME: "SA Felicien",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.015",
    GUNSCORE_DAY2: "133.011",
    GUNSCORE_DAY3: "157.007",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "94.003",
    GUNSCORE_GA: "709.048",
    DAILY_T: "72.008",
    DONEGALL: "46.003",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "44.007",
    CORP: "43.002",
    TIMES: "45.001",
    POW: "69.005",
    DOFC: "43.001",
    LOVELL: "44.003",
    WIMB: "48.004",
    STG: "70.005",
    KINGS: "94.003",
    DAY1_POS: "468",
    DAY2_POS: "485",
    DAY3_POS: "514",
    DAY4_POS: "395",
    DAY5_POS: "563",
  },
  6780: {
    Index: "6780",
    Init: "IJ",
    NAME: "Saturley",
    full_NAME: "IJ Saturley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.011",
    GUNSCORE_DAY2: "134.01",
    GUNSCORE_DAY3: "162.012",
    GUNSCORE_DAY4: "148.006",
    GUNSCORE_DAY5: "92.006",
    GUNSCORE_GA: "699.045",
    DAILY_T: "71.004",
    DONEGALL: "46.003",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "43.003",
    CORP: "43.002",
    TIMES: "47.001",
    POW: "69.005",
    DOFC: "46.006",
    LOVELL: "42.003",
    WIMB: "42",
    STG: "64.003",
    KINGS: "92.006",
    DAY1_POS: "483",
    DAY2_POS: "464",
    DAY3_POS: "382",
    DAY4_POS: "575",
    DAY5_POS: "579",
  },
  6785: {
    Index: "6785",
    Init: "DD",
    NAME: "Charles",
    full_NAME: "DD Charles",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "132.001",
    GUNSCORE_DAY2: "124.004",
    GUNSCORE_DAY3: "143.011",
    GUNSCORE_DAY4: "147.009",
    GUNSCORE_DAY5: "90.005",
    GUNSCORE_GA: "636.03",
    DAILY_T: "60.001",
    DONEGALL: "37",
    CONAN_DOYLE: "35",
    DAILY_MAIL: "41",
    ALEXANDRA: "45.002",
    CORP: "38.002",
    TIMES: "39.003",
    POW: "69.007",
    DOFC: "35.001",
    LOVELL: "37.001",
    WIMB: "42.003",
    STG: "68.005",
    KINGS: "90.005",
    DAY1_POS: "630",
    DAY2_POS: "597",
    DAY3_POS: "610",
    DAY4_POS: "576",
    DAY5_POS: "594",
  },
  6786: {
    Index: "6786",
    Init: "MMV",
    NAME: "James",
    full_NAME: "MMV James",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "143.007",
    GUNSCORE_DAY2: "105.002",
    GUNSCORE_DAY3: "140.002",
    GUNSCORE_DAY4: "143.005",
    GUNSCORE_DAY5: "87.002",
    GUNSCORE_GA: "618.018",
    DAILY_T: "64.003",
    DONEGALL: "36",
    CONAN_DOYLE: "43.004",
    DAILY_MAIL: "42.001",
    ALEXANDRA: "33",
    CORP: "30.001",
    TIMES: "41.001",
    POW: "62.001",
    DOFC: "37",
    LOVELL: "37.001",
    WIMB: "41.001",
    STG: "65.003",
    KINGS: "87.002",
    DAY1_POS: "620",
    DAY2_POS: "631",
    DAY3_POS: "619",
    DAY4_POS: "598",
    DAY5_POS: "614",
  },
  6787: {
    Index: "6787",
    Init: "A",
    NAME: "Bhagat",
    full_NAME: "A Bhagat",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.012",
    GUNSCORE_DAY2: "128.005",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "142.006",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "700.046",
    DAILY_T: "69.003",
    DONEGALL: "48.004",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "49.003",
    CORP: "35",
    TIMES: "48.004",
    POW: "69.008",
    DOFC: "47.003",
    LOVELL: "35",
    WIMB: "47.002",
    STG: "60.004",
    KINGS: "100.008",
    DAY1_POS: "391",
    DAY2_POS: "569",
    DAY3_POS: "314",
    DAY4_POS: "603",
    DAY5_POS: "355",
  },
  6793: {
    Index: "6793",
    Init: "K",
    NAME: "OLoghlen",
    full_NAME: "K OLoghlen",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "9",
    Day_3_BONUS: "5",
    Day_3_Total: "14",
    Day_4: "7",
    Day_4_BONUS: "5",
    Day_4_Total: "12",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "36",
    GUNSCORE_DAY1: "166.019",
    GUNSCORE_DAY2: "144.016",
    GUNSCORE_DAY3: "173.016",
    GUNSCORE_DAY4: "173.019",
    GUNSCORE_DAY5: "103.01",
    GUNSCORE_GA: "759.08",
    DAILY_T: "74.01",
    DONEGALL: "50.004",
    CONAN_DOYLE: "42.005",
    DAILY_MAIL: "50.006",
    ALEXANDRA: "48.007",
    CORP: "46.003",
    TIMES: "50.006",
    POW: "74.007",
    DOFC: "49.003",
    LOVELL: "49.004",
    WIMB: "50.007",
    STG: "74.008",
    KINGS: "103.01",
    DAY1_POS: "369",
    DAY2_POS: "111",
    DAY3_POS: "15",
    DAY4_POS: "19",
    DAY5_POS: "138",
  },
  6794: {
    Index: "6794",
    Init: "Z",
    NAME: "PATEL",
    full_NAME: "Z PATEL",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "0",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6795: {
    Index: "6795",
    Init: "EJ",
    NAME: "Gadd-Chapman",
    full_NAME: "EJ Gadd-Chapman",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "155.008",
    GUNSCORE_DAY2: "116.003",
    GUNSCORE_DAY3: "152.006",
    GUNSCORE_DAY4: "144.006",
    GUNSCORE_DAY5: "89.003",
    GUNSCORE_GA: "656.026",
    DAILY_T: "67.002",
    DONEGALL: "40.001",
    CONAN_DOYLE: "48.005",
    DAILY_MAIL: "39",
    ALEXANDRA: "42.001",
    CORP: "35.002",
    TIMES: "42.002",
    POW: "64.001",
    DOFC: "46.003",
    LOVELL: "37.002",
    WIMB: "45.004",
    STG: "62",
    KINGS: "89.003",
    DAY1_POS: "575",
    DAY2_POS: "620",
    DAY3_POS: "567",
    DAY4_POS: "591",
    DAY5_POS: "602",
  },
  6796: {
    Index: "6796",
    Init: "KE",
    NAME: "Farley",
    full_NAME: "KE Farley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.02",
    GUNSCORE_DAY2: "141.012",
    GUNSCORE_DAY3: "166.015",
    GUNSCORE_DAY4: "169.015",
    GUNSCORE_DAY5: "99.012",
    GUNSCORE_GA: "746.074",
    DAILY_T: "74.009",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "50.007",
    CORP: "43",
    TIMES: "48.008",
    POW: "71.006",
    DOFC: "47.001",
    LOVELL: "47.004",
    WIMB: "49.002",
    STG: "73.009",
    KINGS: "99.012",
    DAY1_POS: "159",
    DAY2_POS: "250",
    DAY3_POS: "240",
    DAY4_POS: "127",
    DAY5_POS: "380",
  },
  6800: {
    Index: "6800",
    Init: "AJT",
    NAME: "Key",
    full_NAME: "AJT Key",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "130.006",
    GUNSCORE_DAY3: "167.015",
    GUNSCORE_DAY4: "163.011",
    GUNSCORE_DAY5: "76.003",
    GUNSCORE_GA: "536.035",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "47.001",
    ALEXANDRA: "46.002",
    CORP: "37.003",
    TIMES: "47.005",
    POW: "71.005",
    DOFC: "49.005",
    LOVELL: "48.004",
    WIMB: "45.001",
    STG: "70.006",
    KINGS: "76.003",
    DAY1_POS: "639",
    DAY2_POS: "541",
    DAY3_POS: "195",
    DAY4_POS: "370",
    DAY5_POS: "630",
  },
  6801: {
    Index: "6801",
    Init: "TD",
    NAME: "Bailey",
    full_NAME: "TD Bailey",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.018",
    GUNSCORE_DAY2: "126.004",
    GUNSCORE_DAY3: "161.016",
    GUNSCORE_DAY4: "157.009",
    GUNSCORE_DAY5: "92.004",
    GUNSCORE_GA: "704.051",
    DAILY_T: "72.006",
    DONEGALL: "47.004",
    CONAN_DOYLE: "49.008",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "40.001",
    CORP: "42.001",
    TIMES: "46.004",
    POW: "71.007",
    DOFC: "44.005",
    LOVELL: "43.003",
    WIMB: "48.004",
    STG: "66.002",
    KINGS: "92.004",
    DAY1_POS: "299",
    DAY2_POS: "589",
    DAY3_POS: "400",
    DAY4_POS: "497",
    DAY5_POS: "585",
  },
  6804: {
    Index: "6804",
    Init: "TDB",
    NAME: "Hebblethwaite",
    full_NAME: "TDB Hebblethwaite",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "152.004",
    GUNSCORE_DAY2: "128.008",
    GUNSCORE_DAY3: "146.005",
    GUNSCORE_DAY4: "144.006",
    GUNSCORE_DAY5: "88.005",
    GUNSCORE_GA: "658.028",
    DAILY_T: "65.001",
    DONEGALL: "43.002",
    CONAN_DOYLE: "44.001",
    DAILY_MAIL: "44.003",
    ALEXANDRA: "43.004",
    CORP: "41.001",
    TIMES: "42",
    POW: "66.003",
    DOFC: "38.002",
    LOVELL: "41.002",
    WIMB: "40.001",
    STG: "63.003",
    KINGS: "88.005",
    DAY1_POS: "592",
    DAY2_POS: "564",
    DAY3_POS: "598",
    DAY4_POS: "591",
    DAY5_POS: "607",
  },
  6805: {
    Index: "6805",
    Init: "GR",
    NAME: "Bowman",
    full_NAME: "GR Bowman",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "166.015",
    GUNSCORE_DAY2: "145.016",
    GUNSCORE_DAY3: "162.011",
    GUNSCORE_DAY4: "156.007",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "726.056",
    DAILY_T: "72.007",
    DONEGALL: "46.004",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "50.007",
    CORP: "47.004",
    TIMES: "48.005",
    POW: "66.001",
    DOFC: "48.005",
    LOVELL: "44.001",
    WIMB: "45.003",
    STG: "67.003",
    KINGS: "97.007",
    DAY1_POS: "384",
    DAY2_POS: "75",
    DAY3_POS: "388",
    DAY4_POS: "511",
    DAY5_POS: "483",
  },
  6806: {
    Index: "6806",
    Init: "NJ",
    NAME: "Harris",
    full_NAME: "NJ Harris",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "155.006",
    GUNSCORE_DAY2: "129.004",
    GUNSCORE_DAY3: "156.008",
    GUNSCORE_DAY4: "148.007",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "685.031",
    DAILY_T: "70.003",
    DONEGALL: "43.002",
    CONAN_DOYLE: "42.001",
    DAILY_MAIL: "45.001",
    ALEXANDRA: "43",
    CORP: "41.003",
    TIMES: "47.003",
    POW: "63.003",
    DOFC: "46.002",
    LOVELL: "35.002",
    WIMB: "47.002",
    STG: "66.003",
    KINGS: "97.006",
    DAY1_POS: "578",
    DAY2_POS: "556",
    DAY3_POS: "527",
    DAY4_POS: "574",
    DAY5_POS: "490",
  },
  6809: {
    Index: "6809",
    Init: "RA",
    NAME: "Monaghan",
    full_NAME: "RA Monaghan",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.013",
    GUNSCORE_DAY2: "127.006",
    GUNSCORE_DAY3: "145.008",
    GUNSCORE_DAY4: "141.008",
    GUNSCORE_DAY5: "96.006",
    GUNSCORE_GA: "670.041",
    DAILY_T: "69.007",
    DONEGALL: "46.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "45.002",
    CORP: "37.001",
    TIMES: "43.004",
    POW: "65.002",
    DOFC: "37.002",
    LOVELL: "28",
    WIMB: "40.001",
    STG: "73.007",
    KINGS: "96.006",
    DAY1_POS: "507",
    DAY2_POS: "578",
    DAY3_POS: "599",
    DAY4_POS: "607",
    DAY5_POS: "521",
  },
  6810: {
    Index: "6810",
    Init: "P",
    NAME: "Gillies",
    full_NAME: "P Gillies",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.005",
    GUNSCORE_DAY2: "120.003",
    GUNSCORE_DAY3: "146.006",
    GUNSCORE_DAY4: "137.004",
    GUNSCORE_DAY5: "95.006",
    GUNSCORE_GA: "646.024",
    DAILY_T: "59",
    DONEGALL: "42.001",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "43",
    ALEXANDRA: "42.002",
    CORP: "35.001",
    TIMES: "40.002",
    POW: "65.002",
    DOFC: "41.002",
    LOVELL: "29.001",
    WIMB: "41.002",
    STG: "67.001",
    KINGS: "95.006",
    DAY1_POS: "611",
    DAY2_POS: "610",
    DAY3_POS: "596",
    DAY4_POS: "615",
    DAY5_POS: "543",
  },
  6811: {
    Index: "6811",
    Init: "FJ",
    NAME: "Lawry",
    full_NAME: "FJ Lawry",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.016",
    GUNSCORE_DAY2: "132.006",
    GUNSCORE_DAY3: "160.013",
    GUNSCORE_DAY4: "153.006",
    GUNSCORE_DAY5: "101.01",
    GUNSCORE_GA: "711.051",
    DAILY_T: "73.009",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "48.003",
    CORP: "38",
    TIMES: "46.006",
    POW: "69.004",
    DOFC: "45.003",
    LOVELL: "42.001",
    WIMB: "48.003",
    STG: "63.002",
    KINGS: "101.01",
    DAY1_POS: "408",
    DAY2_POS: "515",
    DAY3_POS: "440",
    DAY4_POS: "536",
    DAY5_POS: "269",
  },
  6813: {
    Index: "6813",
    Init: "B",
    NAME: "Morris",
    full_NAME: "B Morris",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "12",
    Day_4_BONUS: "5",
    Day_4_Total: "17",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "17",
    GUNSCORE_DAY1: "166.016",
    GUNSCORE_DAY2: "134.013",
    GUNSCORE_DAY3: "163.012",
    GUNSCORE_DAY4: "173.025",
    GUNSCORE_DAY5: "102.012",
    GUNSCORE_GA: "738.078",
    DAILY_T: "73.01",
    DONEGALL: "48.004",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "48.007",
    ALEXANDRA: "49.005",
    CORP: "37.001",
    TIMES: "49.005",
    POW: "72.007",
    DOFC: "42",
    LOVELL: "49.008",
    WIMB: "50.009",
    STG: "74.008",
    KINGS: "102.012",
    DAY1_POS: "381",
    DAY2_POS: "462",
    DAY3_POS: "351",
    DAY4_POS: "10",
    DAY5_POS: "180",
  },
  6814: {
    Index: "6814",
    Init: "HL",
    NAME: "Yiu",
    full_NAME: "HL Yiu",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.015",
    GUNSCORE_DAY2: "129.005",
    GUNSCORE_DAY3: "158.009",
    GUNSCORE_DAY4: "155.007",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "699.046",
    DAILY_T: "71.008",
    DONEGALL: "40.002",
    CONAN_DOYLE: "47.005",
    DAILY_MAIL: "43.002",
    ALEXANDRA: "46.001",
    CORP: "40.002",
    TIMES: "47.003",
    POW: "71.004",
    DOFC: "40.002",
    LOVELL: "42.002",
    WIMB: "45.004",
    STG: "68.001",
    KINGS: "99.01",
    DAY1_POS: "537",
    DAY2_POS: "553",
    DAY3_POS: "497",
    DAY4_POS: "520",
    DAY5_POS: "388",
  },
  6815: {
    Index: "6815",
    Init: "SE",
    NAME: "Fry",
    full_NAME: "SE Fry",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "115.001",
    GUNSCORE_DAY2: "121.005",
    GUNSCORE_DAY3: "135.003",
    GUNSCORE_DAY4: "139.006",
    GUNSCORE_DAY5: "79.003",
    GUNSCORE_GA: "589.018",
    DAILY_T: "55.001",
    DONEGALL: "21",
    CONAN_DOYLE: "39",
    DAILY_MAIL: "39.001",
    ALEXANDRA: "44.002",
    CORP: "38.002",
    TIMES: "38",
    POW: "54",
    DOFC: "43.003",
    LOVELL: "39.002",
    WIMB: "42.002",
    STG: "58.002",
    KINGS: "79.003",
    DAY1_POS: "633",
    DAY2_POS: "605",
    DAY3_POS: "624",
    DAY4_POS: "612",
    DAY5_POS: "628",
  },
  6817: {
    Index: "6817",
    Init: "M",
    NAME: "Nikolic",
    full_NAME: "M Nikolic",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.011",
    GUNSCORE_DAY2: "135.01",
    GUNSCORE_DAY3: "156.011",
    GUNSCORE_DAY4: "150.009",
    GUNSCORE_DAY5: "99.006",
    GUNSCORE_GA: "698.047",
    DAILY_T: "70.004",
    DONEGALL: "43.004",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "46.004",
    CORP: "42.001",
    TIMES: "45.004",
    POW: "65.003",
    DOFC: "46.004",
    LOVELL: "37.001",
    WIMB: "44.002",
    STG: "69.006",
    KINGS: "99.006",
    DAY1_POS: "543",
    DAY2_POS: "443",
    DAY3_POS: "523",
    DAY4_POS: "555",
    DAY5_POS: "425",
  },
  6818: {
    Index: "6818",
    Init: "M",
    NAME: "Clark",
    full_NAME: "M Clark",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "168.014",
    GUNSCORE_DAY2: "130.01",
    GUNSCORE_DAY3: "166.018",
    GUNSCORE_DAY4: "163.013",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "729.066",
    DAILY_T: "74.007",
    DONEGALL: "47.005",
    CONAN_DOYLE: "47.002",
    DAILY_MAIL: "49.004",
    ALEXANDRA: "38.004",
    CORP: "43.002",
    TIMES: "47.003",
    POW: "71.009",
    DOFC: "48.006",
    LOVELL: "46.004",
    WIMB: "44.002",
    STG: "73.007",
    KINGS: "102.011",
    DAY1_POS: "320",
    DAY2_POS: "537",
    DAY3_POS: "220",
    DAY4_POS: "362",
    DAY5_POS: "191",
  },
  6819: {
    Index: "6819",
    Init: "BJ",
    NAME: "Reeves",
    full_NAME: "BJ Reeves",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.011",
    GUNSCORE_DAY2: "123.009",
    GUNSCORE_DAY3: "161.01",
    GUNSCORE_DAY4: "165.013",
    GUNSCORE_DAY5: "97.005",
    GUNSCORE_GA: "706.048",
    DAILY_T: "71.007",
    DONEGALL: "45.002",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "40.001",
    CORP: "36.002",
    TIMES: "42.001",
    POW: "73.008",
    DOFC: "46.001",
    LOVELL: "43.001",
    WIMB: "48.005",
    STG: "74.007",
    KINGS: "97.005",
    DAY1_POS: "520",
    DAY2_POS: "599",
    DAY3_POS: "429",
    DAY4_POS: "294",
    DAY5_POS: "497",
  },
  6820: {
    Index: "6820",
    Init: "KTA",
    NAME: "Tam",
    full_NAME: "KTA Tam",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "155.009",
    GUNSCORE_DAY2: "127.006",
    GUNSCORE_DAY3: "157.01",
    GUNSCORE_DAY4: "157.009",
    GUNSCORE_DAY5: "102.01",
    GUNSCORE_GA: "698.044",
    DAILY_T: "67.006",
    DONEGALL: "39",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "44",
    CORP: "35.001",
    TIMES: "45.003",
    POW: "69.005",
    DOFC: "43.002",
    LOVELL: "43.002",
    WIMB: "42.002",
    STG: "72.005",
    KINGS: "102.01",
    DAY1_POS: "574",
    DAY2_POS: "578",
    DAY3_POS: "511",
    DAY4_POS: "497",
    DAY5_POS: "202",
  },
  6821: {
    Index: "6821",
    Init: "LFG",
    NAME: "Castro",
    full_NAME: "LFG Castro",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "164.009",
    GUNSCORE_DAY2: "130.01",
    GUNSCORE_DAY3: "145.006",
    GUNSCORE_DAY4: "164.009",
    GUNSCORE_DAY5: "99.007",
    GUNSCORE_GA: "702.041",
    DAILY_T: "69.003",
    DONEGALL: "47.002",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "45.005",
    CORP: "37",
    TIMES: "45.003",
    POW: "58.003",
    DOFC: "42",
    LOVELL: "48.002",
    WIMB: "46.001",
    STG: "70.006",
    KINGS: "99.007",
    DAY1_POS: "463",
    DAY2_POS: "537",
    DAY3_POS: "602",
    DAY4_POS: "341",
    DAY5_POS: "413",
  },
  6822: {
    Index: "6822",
    Init: "AC",
    NAME: "Meagher",
    full_NAME: "AC Meagher",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "126.003",
    GUNSCORE_DAY3: "133.005",
    GUNSCORE_DAY4: "115.003",
    GUNSCORE_DAY5: "92.003",
    GUNSCORE_GA: "466.014",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "40",
    CORP: "40.001",
    TIMES: "40.001",
    POW: "49.003",
    DOFC: "44.001",
    LOVELL: "22",
    WIMB: "34",
    STG: "59.003",
    KINGS: "92.003",
    DAY1_POS: "639",
    DAY2_POS: "590",
    DAY3_POS: "626",
    DAY4_POS: "626",
    DAY5_POS: "586",
  },
  6823: {
    Index: "6823",
    Init: "S",
    NAME: "Bland",
    full_NAME: "S Bland",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "23",
    Day_3_BONUS: "10",
    Day_3_Total: "33",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "33",
    GUNSCORE_DAY1: "170.017",
    GUNSCORE_DAY2: "146.013",
    GUNSCORE_DAY3: "174.018",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "101.011",
    GUNSCORE_GA: "760.077",
    DAILY_T: "73.008",
    DONEGALL: "49.005",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "49.005",
    ALEXANDRA: "49.004",
    CORP: "48.004",
    TIMES: "50.007",
    POW: "75.007",
    DOFC: "49.004",
    LOVELL: "48.005",
    WIMB: "47.004",
    STG: "74.009",
    KINGS: "101.011",
    DAY1_POS: "226",
    DAY2_POS: "59",
    DAY3_POS: "4",
    DAY4_POS: "110",
    DAY5_POS: "254",
  },
  6826: {
    Index: "6826",
    Init: "NP",
    NAME: "Gibaut",
    full_NAME: "NP Gibaut",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "0",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "0",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "0",
    POW: "0",
    DOFC: "0",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "643",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6827: {
    Index: "6827",
    Init: "EM",
    NAME: "Gibaut",
    full_NAME: "EM Gibaut",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "161.01",
    GUNSCORE_DAY2: "133.007",
    GUNSCORE_DAY3: "157.009",
    GUNSCORE_DAY4: "156.011",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "705.044",
    DAILY_T: "70.004",
    DONEGALL: "48.004",
    CONAN_DOYLE: "43.002",
    DAILY_MAIL: "49.003",
    ALEXANDRA: "45.003",
    CORP: "39.001",
    TIMES: "45.004",
    POW: "67.004",
    DOFC: "45.001",
    LOVELL: "44.004",
    WIMB: "46.004",
    STG: "66.003",
    KINGS: "98.007",
    DAY1_POS: "509",
    DAY2_POS: "497",
    DAY3_POS: "512",
    DAY4_POS: "504",
    DAY5_POS: "455",
  },
  6829: {
    Index: "6829",
    Init: "M",
    NAME: "Ghorashi",
    full_NAME: "M Ghorashi",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.006",
    GUNSCORE_DAY2: "127.009",
    GUNSCORE_DAY3: "155.011",
    GUNSCORE_DAY4: "161.011",
    GUNSCORE_DAY5: "93.006",
    GUNSCORE_GA: "684.043",
    DAILY_T: "65.001",
    DONEGALL: "37.002",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "41.003",
    ALEXANDRA: "45.003",
    CORP: "41.003",
    TIMES: "46.005",
    POW: "63.002",
    DOFC: "46.004",
    LOVELL: "49.005",
    WIMB: "45.002",
    STG: "67.004",
    KINGS: "93.006",
    DAY1_POS: "608",
    DAY2_POS: "576",
    DAY3_POS: "534",
    DAY4_POS: "415",
    DAY5_POS: "566",
  },
  6830: {
    Index: "6830",
    Init: "ZZ",
    NAME: "Grosvenor",
    full_NAME: "ZZ Grosvenor",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.011",
    GUNSCORE_DAY2: "131.007",
    GUNSCORE_DAY3: "158.009",
    GUNSCORE_DAY4: "157.011",
    GUNSCORE_DAY5: "89.004",
    GUNSCORE_GA: "694.042",
    DAILY_T: "73.005",
    DONEGALL: "41.002",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "46.003",
    CORP: "38.001",
    TIMES: "44.001",
    POW: "68.004",
    DOFC: "46.004",
    LOVELL: "44.003",
    WIMB: "47.005",
    STG: "66.003",
    KINGS: "89.004",
    DAY1_POS: "531",
    DAY2_POS: "527",
    DAY3_POS: "497",
    DAY4_POS: "489",
    DAY5_POS: "599",
  },
  6831: {
    Index: "6831",
    Init: "F",
    NAME: "Cheung",
    full_NAME: "F Cheung",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "155.008",
    GUNSCORE_DAY2: "129.008",
    GUNSCORE_DAY3: "131.006",
    GUNSCORE_DAY4: "138.007",
    GUNSCORE_DAY5: "90.003",
    GUNSCORE_GA: "643.032",
    DAILY_T: "68.005",
    DONEGALL: "46.001",
    CONAN_DOYLE: "41.002",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "46.004",
    CORP: "38.001",
    TIMES: "41.004",
    POW: "53.001",
    DOFC: "37.001",
    LOVELL: "35.002",
    WIMB: "43.003",
    STG: "60.002",
    KINGS: "90.003",
    DAY1_POS: "575",
    DAY2_POS: "547",
    DAY3_POS: "627",
    DAY4_POS: "613",
    DAY5_POS: "597",
  },
  6832: {
    Index: "6832",
    Init: "JHN",
    NAME: "Pattison",
    full_NAME: "JHN Pattison",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "169.016",
    GUNSCORE_DAY2: "142.006",
    GUNSCORE_DAY3: "162.01",
    GUNSCORE_DAY4: "169.013",
    GUNSCORE_DAY5: "102.011",
    GUNSCORE_GA: "744.056",
    DAILY_T: "74.01",
    DONEGALL: "46.003",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "48.003",
    ALEXANDRA: "46.001",
    CORP: "48.002",
    TIMES: "46.001",
    POW: "70.006",
    DOFC: "46.003",
    LOVELL: "49.005",
    WIMB: "48.003",
    STG: "72.005",
    KINGS: "102.011",
    DAY1_POS: "271",
    DAY2_POS: "234",
    DAY3_POS: "392",
    DAY4_POS: "136",
    DAY5_POS: "191",
  },
  6833: {
    Index: "6833",
    Init: "HB",
    NAME: "Johnstone",
    full_NAME: "HB Johnstone",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "0",
    GUNSCORE_DAY3: "156.008",
    GUNSCORE_DAY4: "163.014",
    GUNSCORE_DAY5: "102.013",
    GUNSCORE_GA: "421.035",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "0",
    ALEXANDRA: "0",
    CORP: "0",
    TIMES: "46.003",
    POW: "66.003",
    DOFC: "44.002",
    LOVELL: "43.003",
    WIMB: "47.002",
    STG: "73.009",
    KINGS: "102.013",
    DAY1_POS: "639",
    DAY2_POS: "641",
    DAY3_POS: "527",
    DAY4_POS: "355",
    DAY5_POS: "173",
  },
  6834: {
    Index: "6834",
    Init: "A",
    NAME: "Coombs",
    full_NAME: "A Coombs",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "151.006",
    GUNSCORE_DAY2: "115.002",
    GUNSCORE_DAY3: "145.004",
    GUNSCORE_DAY4: "144.009",
    GUNSCORE_DAY5: "93.003",
    GUNSCORE_GA: "648.024",
    DAILY_T: "64.001",
    DONEGALL: "41.003",
    CONAN_DOYLE: "46.002",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "37",
    CORP: "34",
    TIMES: "38.001",
    POW: "64.001",
    DOFC: "43.002",
    LOVELL: "42.002",
    WIMB: "40.002",
    STG: "62.005",
    KINGS: "93.003",
    DAY1_POS: "596",
    DAY2_POS: "622",
    DAY3_POS: "604",
    DAY4_POS: "589",
    DAY5_POS: "571",
  },
  6835: {
    Index: "6835",
    Init: "RCG",
    NAME: "Thomas",
    full_NAME: "RCG Thomas",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.013",
    GUNSCORE_DAY2: "131.008",
    GUNSCORE_DAY3: "141.004",
    GUNSCORE_DAY4: "125.01",
    GUNSCORE_DAY5: "89.004",
    GUNSCORE_GA: "648.039",
    DAILY_T: "70.005",
    DONEGALL: "46.004",
    CONAN_DOYLE: "46.004",
    DAILY_MAIL: "46.002",
    ALEXANDRA: "43.004",
    CORP: "42.002",
    TIMES: "45.002",
    POW: "63.002",
    DOFC: "33",
    LOVELL: "14.001",
    WIMB: "42.003",
    STG: "69.006",
    KINGS: "89.004",
    DAY1_POS: "498",
    DAY2_POS: "522",
    DAY3_POS: "617",
    DAY4_POS: "623",
    DAY5_POS: "599",
  },
  6837: {
    Index: "6837",
    Init: "ER",
    NAME: "Cunningham",
    full_NAME: "ER Cunningham",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.012",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "163.015",
    GUNSCORE_DAY4: "157.011",
    GUNSCORE_DAY5: "96.006",
    GUNSCORE_GA: "708.053",
    DAILY_T: "71.003",
    DONEGALL: "48.007",
    CONAN_DOYLE: "39.002",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "44.002",
    CORP: "45.003",
    TIMES: "48.005",
    POW: "68.005",
    DOFC: "47.005",
    LOVELL: "42.003",
    WIMB: "44.003",
    STG: "71.005",
    KINGS: "96.006",
    DAY1_POS: "539",
    DAY2_POS: "466",
    DAY3_POS: "340",
    DAY4_POS: "489",
    DAY5_POS: "521",
  },
  6838: {
    Index: "6838",
    Init: "L",
    NAME: "Vitek",
    full_NAME: "L Vitek",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.008",
    GUNSCORE_DAY2: "105.002",
    GUNSCORE_DAY3: "149.007",
    GUNSCORE_DAY4: "155.007",
    GUNSCORE_DAY5: "97.004",
    GUNSCORE_GA: "660.028",
    DAILY_T: "65.005",
    DONEGALL: "43",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "36",
    ALEXANDRA: "37.001",
    CORP: "32.001",
    TIMES: "36",
    POW: "68.004",
    DOFC: "45.003",
    LOVELL: "46.003",
    WIMB: "40",
    STG: "69.004",
    KINGS: "97.004",
    DAY1_POS: "582",
    DAY2_POS: "631",
    DAY3_POS: "583",
    DAY4_POS: "520",
    DAY5_POS: "501",
  },
  6839: {
    Index: "6839",
    Init: "AM",
    NAME: "Poulton",
    full_NAME: "AM Poulton",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.011",
    GUNSCORE_DAY2: "135.009",
    GUNSCORE_DAY3: "164.015",
    GUNSCORE_DAY4: "154.009",
    GUNSCORE_DAY5: "100.01",
    GUNSCORE_GA: "709.054",
    DAILY_T: "66.004",
    DONEGALL: "48.005",
    CONAN_DOYLE: "42.002",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "47.002",
    CORP: "41.003",
    TIMES: "44.003",
    POW: "72.006",
    DOFC: "48.006",
    LOVELL: "42.003",
    WIMB: "46.004",
    STG: "66.002",
    KINGS: "100.01",
    DAY1_POS: "567",
    DAY2_POS: "449",
    DAY3_POS: "314",
    DAY4_POS: "528",
    DAY5_POS: "333",
  },
  6840: {
    Index: "6840",
    Init: "ZA",
    NAME: "Sayers",
    full_NAME: "ZA Sayers",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "153.012",
    GUNSCORE_DAY2: "128.01",
    GUNSCORE_DAY3: "154.005",
    GUNSCORE_DAY4: "145.007",
    GUNSCORE_DAY5: "88.004",
    GUNSCORE_GA: "668.038",
    DAILY_T: "58.003",
    DONEGALL: "50.006",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "48.006",
    ALEXANDRA: "48.004",
    CORP: "32",
    TIMES: "45.002",
    POW: "66.003",
    DOFC: "43",
    LOVELL: "39.001",
    WIMB: "43.002",
    STG: "63.004",
    KINGS: "88.004",
    DAY1_POS: "587",
    DAY2_POS: "561",
    DAY3_POS: "551",
    DAY4_POS: "584",
    DAY5_POS: "609",
  },
  6841: {
    Index: "6841",
    Init: "SO",
    NAME: "Brumby",
    full_NAME: "SO Brumby",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.01",
    GUNSCORE_DAY2: "131.008",
    GUNSCORE_DAY3: "158.008",
    GUNSCORE_DAY4: "162.012",
    GUNSCORE_DAY5: "88.004",
    GUNSCORE_GA: "699.042",
    DAILY_T: "71.006",
    DONEGALL: "44.001",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "43.002",
    CORP: "42.003",
    TIMES: "44.003",
    POW: "65.001",
    DOFC: "49.004",
    LOVELL: "48.004",
    WIMB: "44.002",
    STG: "70.006",
    KINGS: "88.004",
    DAY1_POS: "523",
    DAY2_POS: "522",
    DAY3_POS: "501",
    DAY4_POS: "395",
    DAY5_POS: "609",
  },
  6842: {
    Index: "6842",
    Init: "VM",
    NAME: "Churchouse",
    full_NAME: "VM Churchouse",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.008",
    GUNSCORE_DAY2: "144.013",
    GUNSCORE_DAY3: "160.014",
    GUNSCORE_DAY4: "161.014",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "724.057",
    DAILY_T: "72.004",
    DONEGALL: "42.001",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "49.002",
    ALEXANDRA: "49.006",
    CORP: "46.005",
    TIMES: "47.005",
    POW: "66.004",
    DOFC: "47.005",
    LOVELL: "49.006",
    WIMB: "43.002",
    STG: "69.006",
    KINGS: "100.008",
    DAY1_POS: "534",
    DAY2_POS: "127",
    DAY3_POS: "438",
    DAY4_POS: "408",
    DAY5_POS: "355",
  },
  6843: {
    Index: "6843",
    Init: "FJS",
    NAME: "Reis",
    full_NAME: "FJS Reis",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "171.018",
    GUNSCORE_DAY2: "139.012",
    GUNSCORE_DAY3: "164.011",
    GUNSCORE_DAY4: "164.011",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "738.06",
    DAILY_T: "73.007",
    DONEGALL: "49.006",
    CONAN_DOYLE: "49.005",
    DAILY_MAIL: "50.005",
    ALEXANDRA: "47.005",
    CORP: "42.002",
    TIMES: "46.002",
    POW: "73.006",
    DOFC: "45.003",
    LOVELL: "48.004",
    WIMB: "46.002",
    STG: "70.005",
    KINGS: "100.008",
    DAY1_POS: "172",
    DAY2_POS: "328",
    DAY3_POS: "335",
    DAY4_POS: "335",
    DAY5_POS: "355",
  },
  6844: {
    Index: "6844",
    Init: "M",
    NAME: "Garcia-Oliver",
    full_NAME: "M Garcia-Oliver",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "150.01",
    GUNSCORE_DAY2: "128.006",
    GUNSCORE_DAY3: "142.004",
    GUNSCORE_DAY4: "162.009",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "682.037",
    DAILY_T: "71.008",
    DONEGALL: "44.002",
    CONAN_DOYLE: "35",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "43.002",
    CORP: "38",
    TIMES: "39",
    POW: "57.001",
    DOFC: "46.003",
    LOVELL: "43.002",
    WIMB: "48.004",
    STG: "71.003",
    KINGS: "100.008",
    DAY1_POS: "599",
    DAY2_POS: "567",
    DAY3_POS: "615",
    DAY4_POS: "404",
    DAY5_POS: "355",
  },
  6846: {
    Index: "6846",
    Init: "CH",
    NAME: "Kwok",
    full_NAME: "CH Kwok",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.009",
    GUNSCORE_DAY2: "138.014",
    GUNSCORE_DAY3: "156.008",
    GUNSCORE_DAY4: "152.009",
    GUNSCORE_DAY5: "95.008",
    GUNSCORE_GA: "701.048",
    DAILY_T: "69.004",
    DONEGALL: "45.002",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "47.005",
    ALEXANDRA: "48.006",
    CORP: "43.003",
    TIMES: "44.003",
    POW: "67.004",
    DOFC: "45.001",
    LOVELL: "45.002",
    WIMB: "39.002",
    STG: "68.005",
    KINGS: "95.008",
    DAY1_POS: "526",
    DAY2_POS: "361",
    DAY3_POS: "527",
    DAY4_POS: "541",
    DAY5_POS: "537",
  },
  6848: {
    Index: "6848",
    Init: "IW",
    NAME: "Fraser",
    full_NAME: "IW Fraser",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.005",
    GUNSCORE_DAY2: "105.003",
    GUNSCORE_DAY3: "126.004",
    GUNSCORE_DAY4: "132.005",
    GUNSCORE_DAY5: "86.003",
    GUNSCORE_GA: "597.02",
    DAILY_T: "65.002",
    DONEGALL: "41.001",
    CONAN_DOYLE: "42.002",
    DAILY_MAIL: "41.002",
    ALEXANDRA: "33.001",
    CORP: "31",
    TIMES: "34.001",
    POW: "55.001",
    DOFC: "37.002",
    LOVELL: "34.001",
    WIMB: "41",
    STG: "57.004",
    KINGS: "86.003",
    DAY1_POS: "611",
    DAY2_POS: "630",
    DAY3_POS: "631",
    DAY4_POS: "620",
    DAY5_POS: "618",
  },
  6849: {
    Index: "6849",
    Init: "EM",
    NAME: "Page",
    full_NAME: "EM Page",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "89.005",
    GUNSCORE_DAY2: "136.005",
    GUNSCORE_DAY3: "143.01",
    GUNSCORE_DAY4: "153.006",
    GUNSCORE_DAY5: "98.007",
    GUNSCORE_GA: "619.033",
    DAILY_T: "0",
    DONEGALL: "49.005",
    CONAN_DOYLE: "40",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "47.002",
    CORP: "42",
    TIMES: "48.005",
    POW: "68.003",
    DOFC: "27.002",
    LOVELL: "41.001",
    WIMB: "46.002",
    STG: "66.003",
    KINGS: "98.007",
    DAY1_POS: "637",
    DAY2_POS: "433",
    DAY3_POS: "611",
    DAY4_POS: "536",
    DAY5_POS: "455",
  },
  6850: {
    Index: "6850",
    Init: "EAV",
    NAME: "Barrett",
    full_NAME: "EAV Barrett",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "166.014",
    GUNSCORE_DAY2: "137.012",
    GUNSCORE_DAY3: "158.007",
    GUNSCORE_DAY4: "157.013",
    GUNSCORE_DAY5: "99.008",
    GUNSCORE_GA: "717.054",
    DAILY_T: "71.005",
    DONEGALL: "48.005",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "48.006",
    CORP: "41.001",
    TIMES: "46.001",
    POW: "65.003",
    DOFC: "47.003",
    LOVELL: "38.002",
    WIMB: "47.003",
    STG: "72.008",
    KINGS: "99.008",
    DAY1_POS: "386",
    DAY2_POS: "393",
    DAY3_POS: "504",
    DAY4_POS: "484",
    DAY5_POS: "402",
  },
  6853: {
    Index: "6853",
    Init: "CAE",
    NAME: "Renart",
    full_NAME: "CAE Renart",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "163.012",
    GUNSCORE_DAY2: "130.006",
    GUNSCORE_DAY3: "158.016",
    GUNSCORE_DAY4: "161.013",
    GUNSCORE_DAY5: "103.013",
    GUNSCORE_GA: "715.06",
    DAILY_T: "68.005",
    DONEGALL: "48.003",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "44.003",
    CORP: "41",
    TIMES: "43.001",
    POW: "68.008",
    DOFC: "47.007",
    LOVELL: "46.004",
    WIMB: "46.002",
    STG: "69.007",
    KINGS: "103.013",
    DAY1_POS: "475",
    DAY2_POS: "541",
    DAY3_POS: "484",
    DAY4_POS: "411",
    DAY5_POS: "97",
  },
  6856: {
    Index: "6856",
    Init: "T",
    NAME: "Temperley",
    full_NAME: "T Temperley",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "146.008",
    GUNSCORE_DAY2: "111.004",
    GUNSCORE_DAY3: "131.004",
    GUNSCORE_DAY4: "133.007",
    GUNSCORE_DAY5: "92.007",
    GUNSCORE_GA: "613.03",
    DAILY_T: "66.004",
    DONEGALL: "36.001",
    CONAN_DOYLE: "44.003",
    DAILY_MAIL: "43.001",
    ALEXANDRA: "35.002",
    CORP: "33.001",
    TIMES: "38",
    POW: "49.001",
    DOFC: "44.003",
    LOVELL: "36.002",
    WIMB: "48.004",
    STG: "49.001",
    KINGS: "92.007",
    DAY1_POS: "616",
    DAY2_POS: "626",
    DAY3_POS: "628",
    DAY4_POS: "619",
    DAY5_POS: "576",
  },
  6857: {
    Index: "6857",
    Init: "GJ",
    NAME: "Maas",
    full_NAME: "GJ Maas",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.015",
    GUNSCORE_DAY2: "138.008",
    GUNSCORE_DAY3: "155.012",
    GUNSCORE_DAY4: "157.011",
    GUNSCORE_DAY5: "97.004",
    GUNSCORE_GA: "714.05",
    DAILY_T: "68.004",
    DONEGALL: "50.007",
    CONAN_DOYLE: "49.004",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "46.002",
    CORP: "44.001",
    TIMES: "48.008",
    POW: "68.004",
    DOFC: "39",
    LOVELL: "43.003",
    WIMB: "48.003",
    STG: "66.005",
    KINGS: "97.004",
    DAY1_POS: "356",
    DAY2_POS: "384",
    DAY3_POS: "533",
    DAY4_POS: "489",
    DAY5_POS: "501",
  },
  6859: {
    Index: "6859",
    Init: "LM",
    NAME: "OGrady",
    full_NAME: "LM OGrady",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.018",
    GUNSCORE_DAY2: "137.011",
    GUNSCORE_DAY3: "162.012",
    GUNSCORE_DAY4: "157.013",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "715.06",
    DAILY_T: "71.007",
    DONEGALL: "43.005",
    CONAN_DOYLE: "48.006",
    DAILY_MAIL: "47.006",
    ALEXANDRA: "46.003",
    CORP: "44.002",
    TIMES: "48.004",
    POW: "70.005",
    DOFC: "44.003",
    LOVELL: "40",
    WIMB: "45.002",
    STG: "72.011",
    KINGS: "97.006",
    DAY1_POS: "491",
    DAY2_POS: "397",
    DAY3_POS: "382",
    DAY4_POS: "484",
    DAY5_POS: "490",
  },
  6860: {
    Index: "6860",
    Init: "KM",
    NAME: "Chung",
    full_NAME: "KM Chung",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "151.009",
    GUNSCORE_DAY2: "116.003",
    GUNSCORE_DAY3: "146.007",
    GUNSCORE_DAY4: "134.006",
    GUNSCORE_DAY5: "92.005",
    GUNSCORE_GA: "639.03",
    DAILY_T: "62.003",
    DONEGALL: "40",
    CONAN_DOYLE: "49.006",
    DAILY_MAIL: "39.001",
    ALEXANDRA: "37.001",
    CORP: "40.001",
    TIMES: "40.002",
    POW: "62.004",
    DOFC: "44.001",
    LOVELL: "35.001",
    WIMB: "40.003",
    STG: "59.002",
    KINGS: "92.005",
    DAY1_POS: "593",
    DAY2_POS: "620",
    DAY3_POS: "594",
    DAY4_POS: "618",
    DAY5_POS: "581",
  },
  6862: {
    Index: "6862",
    Init: "MEF",
    NAME: "Bartlette",
    full_NAME: "MEF Bartlette",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "151.009",
    GUNSCORE_DAY2: "120.006",
    GUNSCORE_DAY3: "159.014",
    GUNSCORE_DAY4: "154.007",
    GUNSCORE_DAY5: "98.006",
    GUNSCORE_GA: "682.042",
    DAILY_T: "68.005",
    DONEGALL: "44.003",
    CONAN_DOYLE: "39.001",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "31.001",
    CORP: "42.002",
    TIMES: "45.005",
    POW: "69.007",
    DOFC: "45.002",
    LOVELL: "43.002",
    WIMB: "44",
    STG: "67.005",
    KINGS: "98.006",
    DAY1_POS: "593",
    DAY2_POS: "608",
    DAY3_POS: "459",
    DAY4_POS: "530",
    DAY5_POS: "462",
  },
  6863: {
    Index: "6863",
    Init: "JM",
    NAME: "Picariello",
    full_NAME: "JM Picariello",
    Day_1: "0",
    Day_1_BONUS: "5",
    Day_1_Total: "5",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "167.009",
    GUNSCORE_DAY2: "138.008",
    GUNSCORE_DAY3: "165.02",
    GUNSCORE_DAY4: "169.018",
    GUNSCORE_DAY5: "100.008",
    GUNSCORE_GA: "739.063",
    DAILY_T: "70.003",
    DONEGALL: "50.003",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "46.002",
    CORP: "44.002",
    TIMES: "49.007",
    POW: "70.009",
    DOFC: "46.004",
    LOVELL: "48.005",
    WIMB: "47.005",
    STG: "74.008",
    KINGS: "100.008",
    DAY1_POS: "367",
    DAY2_POS: "384",
    DAY3_POS: "267",
    DAY4_POS: "110",
    DAY5_POS: "355",
  },
  6864: {
    Index: "6864",
    Init: "AG",
    NAME: "Simpson",
    full_NAME: "AG Simpson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.011",
    GUNSCORE_DAY2: "129.004",
    GUNSCORE_DAY3: "156.009",
    GUNSCORE_DAY4: "158.008",
    GUNSCORE_DAY5: "99.01",
    GUNSCORE_GA: "696.042",
    DAILY_T: "67.005",
    DONEGALL: "43.003",
    CONAN_DOYLE: "44.003",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "42.001",
    CORP: "42.001",
    TIMES: "40.001",
    POW: "67.004",
    DOFC: "49.004",
    LOVELL: "46.004",
    WIMB: "48.003",
    STG: "64.001",
    KINGS: "99.01",
    DAY1_POS: "579",
    DAY2_POS: "556",
    DAY3_POS: "526",
    DAY4_POS: "476",
    DAY5_POS: "388",
  },
  6871: {
    Index: "6871",
    Init: "J",
    NAME: "Bideau",
    full_NAME: "J Bideau",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "3",
    Day_5_BONUS: "0",
    Day_5_Total: "3",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "3",
    GUNSCORE_DAY1: "163.018",
    GUNSCORE_DAY2: "135.009",
    GUNSCORE_DAY3: "166.013",
    GUNSCORE_DAY4: "161.01",
    GUNSCORE_DAY5: "104.013",
    GUNSCORE_GA: "729.063",
    DAILY_T: "65.007",
    DONEGALL: "49.008",
    CONAN_DOYLE: "49.003",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "46.004",
    CORP: "43.002",
    TIMES: "46.002",
    POW: "71.005",
    DOFC: "49.006",
    LOVELL: "45.003",
    WIMB: "45.003",
    STG: "71.004",
    KINGS: "104.013",
    DAY1_POS: "465",
    DAY2_POS: "449",
    DAY3_POS: "252",
    DAY4_POS: "418",
    DAY5_POS: "41",
  },
  6872: {
    Index: "6872",
    Init: "IA",
    NAME: "Matson",
    full_NAME: "IA Matson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.011",
    GUNSCORE_DAY2: "127.004",
    GUNSCORE_DAY3: "149.006",
    GUNSCORE_DAY4: "143.009",
    GUNSCORE_DAY5: "92.003",
    GUNSCORE_GA: "667.033",
    DAILY_T: "71.005",
    DONEGALL: "46.005",
    CONAN_DOYLE: "39.001",
    DAILY_MAIL: "41.001",
    ALEXANDRA: "48.002",
    CORP: "38.001",
    TIMES: "44.003",
    POW: "65.003",
    DOFC: "40",
    LOVELL: "41.002",
    WIMB: "35",
    STG: "67.007",
    KINGS: "92.003",
    DAY1_POS: "567",
    DAY2_POS: "582",
    DAY3_POS: "587",
    DAY4_POS: "594",
    DAY5_POS: "586",
  },
  6873: {
    Index: "6873",
    Init: "T",
    NAME: "Starr",
    full_NAME: "T Starr",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "158.009",
    GUNSCORE_DAY2: "128.005",
    GUNSCORE_DAY3: "154.011",
    GUNSCORE_DAY4: "149.01",
    GUNSCORE_DAY5: "63.005",
    GUNSCORE_GA: "652.04",
    DAILY_T: "71.006",
    DONEGALL: "42.001",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "43.001",
    ALEXANDRA: "45.002",
    CORP: "40.002",
    TIMES: "46.005",
    POW: "68.005",
    DOFC: "40.001",
    LOVELL: "33",
    WIMB: "47.003",
    STG: "69.007",
    KINGS: "63.005",
    DAY1_POS: "547",
    DAY2_POS: "569",
    DAY3_POS: "542",
    DAY4_POS: "564",
    DAY5_POS: "632",
  },
  6874: {
    Index: "6874",
    Init: "AS",
    NAME: "Hood",
    full_NAME: "AS Hood",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "162.011",
    GUNSCORE_DAY2: "133.01",
    GUNSCORE_DAY3: "164.014",
    GUNSCORE_DAY4: "160.017",
    GUNSCORE_DAY5: "103.012",
    GUNSCORE_GA: "722.064",
    DAILY_T: "67.003",
    DONEGALL: "48.004",
    CONAN_DOYLE: "47.004",
    DAILY_MAIL: "44.004",
    ALEXANDRA: "43.002",
    CORP: "46.004",
    TIMES: "49.005",
    POW: "71.006",
    DOFC: "44.003",
    LOVELL: "39.002",
    WIMB: "48.005",
    STG: "73.01",
    KINGS: "103.012",
    DAY1_POS: "502",
    DAY2_POS: "486",
    DAY3_POS: "321",
    DAY4_POS: "428",
    DAY5_POS: "112",
  },
  6875: {
    Index: "6875",
    Init: "D",
    NAME: "Chapman",
    full_NAME: "D Chapman",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "0",
    GUNSCORE_DAY2: "82.002",
    GUNSCORE_DAY3: "153.01",
    GUNSCORE_DAY4: "115.009",
    GUNSCORE_DAY5: "60.002",
    GUNSCORE_GA: "410.023",
    DAILY_T: "0",
    DONEGALL: "0",
    CONAN_DOYLE: "0",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "38",
    CORP: "0",
    TIMES: "44.003",
    POW: "67.004",
    DOFC: "42.003",
    LOVELL: "0",
    WIMB: "48.003",
    STG: "67.006",
    KINGS: "60.002",
    DAY1_POS: "639",
    DAY2_POS: "638",
    DAY3_POS: "555",
    DAY4_POS: "625",
    DAY5_POS: "635",
  },
  6876: {
    Index: "6876",
    Init: "PS",
    NAME: "Abram",
    full_NAME: "PS Abram",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "152.01",
    GUNSCORE_DAY2: "134.007",
    GUNSCORE_DAY3: "160.011",
    GUNSCORE_DAY4: "160.008",
    GUNSCORE_DAY5: "99.009",
    GUNSCORE_GA: "705.045",
    DAILY_T: "64.004",
    DONEGALL: "40.002",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "46.003",
    CORP: "41",
    TIMES: "46.001",
    POW: "71.007",
    DOFC: "43.003",
    LOVELL: "45.003",
    WIMB: "47.002",
    STG: "68.003",
    KINGS: "99.009",
    DAY1_POS: "590",
    DAY2_POS: "476",
    DAY3_POS: "445",
    DAY4_POS: "444",
    DAY5_POS: "396",
  },
  6877: {
    Index: "6877",
    Init: "N",
    NAME: "Luchmun",
    full_NAME: "N Luchmun",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.009",
    GUNSCORE_DAY2: "129.006",
    GUNSCORE_DAY3: "156.007",
    GUNSCORE_DAY4: "145.003",
    GUNSCORE_DAY5: "101.006",
    GUNSCORE_GA: "685.031",
    DAILY_T: "71.003",
    DONEGALL: "40.004",
    CONAN_DOYLE: "43.002",
    DAILY_MAIL: "45.004",
    ALEXANDRA: "42",
    CORP: "42.002",
    TIMES: "45.001",
    POW: "67.004",
    DOFC: "44.002",
    LOVELL: "42",
    WIMB: "39.001",
    STG: "64.002",
    KINGS: "101.006",
    DAY1_POS: "580",
    DAY2_POS: "550",
    DAY3_POS: "531",
    DAY4_POS: "587",
    DAY5_POS: "312",
  },
  6878: {
    Index: "6878",
    Init: "AM",
    NAME: "Jones",
    full_NAME: "AM Jones",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "5",
    Day_4_Total: "5",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "160.011",
    GUNSCORE_DAY2: "135.012",
    GUNSCORE_DAY3: "160.01",
    GUNSCORE_DAY4: "155.013",
    GUNSCORE_DAY5: "100.009",
    GUNSCORE_GA: "710.055",
    DAILY_T: "67.005",
    DONEGALL: "47.003",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "48.004",
    ALEXANDRA: "45.004",
    CORP: "42.004",
    TIMES: "47.003",
    POW: "69.005",
    DOFC: "44.002",
    LOVELL: "34.002",
    WIMB: "50.005",
    STG: "71.006",
    KINGS: "100.009",
    DAY1_POS: "520",
    DAY2_POS: "436",
    DAY3_POS: "447",
    DAY4_POS: "513",
    DAY5_POS: "346",
  },
  6879: {
    Index: "6879",
    Init: "L",
    NAME: "Vaz Pinto",
    full_NAME: "L Vaz Pinto",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.01",
    GUNSCORE_DAY2: "135.01",
    GUNSCORE_DAY3: "150.008",
    GUNSCORE_DAY4: "157.009",
    GUNSCORE_DAY5: "97.007",
    GUNSCORE_GA: "698.044",
    DAILY_T: "67.003",
    DONEGALL: "47.004",
    CONAN_DOYLE: "45.003",
    DAILY_MAIL: "47.004",
    ALEXANDRA: "46.004",
    CORP: "42.002",
    TIMES: "40.002",
    POW: "70.004",
    DOFC: "40.002",
    LOVELL: "43",
    WIMB: "44.005",
    STG: "70.004",
    KINGS: "97.007",
    DAY1_POS: "532",
    DAY2_POS: "443",
    DAY3_POS: "577",
    DAY4_POS: "497",
    DAY5_POS: "483",
  },
  6880: {
    Index: "6880",
    Init: "JWD",
    NAME: "Grace",
    full_NAME: "JWD Grace",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "150.01",
    GUNSCORE_DAY2: "122.003",
    GUNSCORE_DAY3: "148.01",
    GUNSCORE_DAY4: "143.003",
    GUNSCORE_DAY5: "97.006",
    GUNSCORE_GA: "660.032",
    DAILY_T: "66.005",
    DONEGALL: "38.002",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "41.002",
    ALEXANDRA: "43.001",
    CORP: "38",
    TIMES: "42.002",
    POW: "68.006",
    DOFC: "38.002",
    LOVELL: "37.001",
    WIMB: "40.001",
    STG: "66.001",
    KINGS: "97.006",
    DAY1_POS: "599",
    DAY2_POS: "603",
    DAY3_POS: "590",
    DAY4_POS: "600",
    DAY5_POS: "490",
  },
  6881: {
    Index: "6881",
    Init: "EG",
    NAME: "Button",
    full_NAME: "EG Button",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "153.008",
    GUNSCORE_DAY2: "121.005",
    GUNSCORE_DAY3: "123.005",
    GUNSCORE_DAY4: "142.008",
    GUNSCORE_DAY5: "80.002",
    GUNSCORE_GA: "619.028",
    DAILY_T: "64.002",
    DONEGALL: "44.002",
    CONAN_DOYLE: "45.004",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "42.001",
    CORP: "35.002",
    TIMES: "32.001",
    POW: "54.002",
    DOFC: "37.002",
    LOVELL: "34.001",
    WIMB: "43.003",
    STG: "65.004",
    KINGS: "80.002",
    DAY1_POS: "588",
    DAY2_POS: "605",
    DAY3_POS: "633",
    DAY4_POS: "601",
    DAY5_POS: "627",
  },
  6887: {
    Index: "6887",
    Init: "CH",
    NAME: "Maxwell Randeria",
    full_NAME: "CH Maxwell Randeria",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "156.002",
    GUNSCORE_DAY2: "124.003",
    GUNSCORE_DAY3: "137.007",
    GUNSCORE_DAY4: "150.007",
    GUNSCORE_DAY5: "87.002",
    GUNSCORE_GA: "654.021",
    DAILY_T: "70",
    DONEGALL: "41.001",
    CONAN_DOYLE: "45.001",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "39",
    CORP: "41.001",
    TIMES: "42.001",
    POW: "53.003",
    DOFC: "42.003",
    LOVELL: "43.002",
    WIMB: "44.002",
    STG: "63.003",
    KINGS: "87.002",
    DAY1_POS: "573",
    DAY2_POS: "598",
    DAY3_POS: "622",
    DAY4_POS: "558",
    DAY5_POS: "614",
  },
  6889: {
    Index: "6889",
    Init: "MA",
    NAME: "Chammings",
    full_NAME: "MA Chammings",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "5",
    Day_2_Total: "5",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "5",
    GUNSCORE_DAY1: "160.012",
    GUNSCORE_DAY2: "129.007",
    GUNSCORE_DAY3: "154.006",
    GUNSCORE_DAY4: "157.007",
    GUNSCORE_DAY5: "96.005",
    GUNSCORE_GA: "696.037",
    DAILY_T: "68.005",
    DONEGALL: "48.005",
    CONAN_DOYLE: "44.002",
    DAILY_MAIL: "50.004",
    ALEXANDRA: "41.002",
    CORP: "38.001",
    TIMES: "44.001",
    POW: "67.003",
    DOFC: "43.002",
    LOVELL: "41.002",
    WIMB: "48.001",
    STG: "68.004",
    KINGS: "96.005",
    DAY1_POS: "517",
    DAY2_POS: "549",
    DAY3_POS: "549",
    DAY4_POS: "501",
    DAY5_POS: "527",
  },
  6899: {
    Index: "6899",
    Init: "M",
    NAME: "Hannaford",
    full_NAME: "M Hannaford",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "154.006",
    GUNSCORE_DAY2: "109.003",
    GUNSCORE_DAY3: "129.003",
    GUNSCORE_DAY4: "148.009",
    GUNSCORE_DAY5: "92.005",
    GUNSCORE_GA: "632.026",
    DAILY_T: "66.001",
    DONEGALL: "41.002",
    CONAN_DOYLE: "47.003",
    DAILY_MAIL: "41.002",
    ALEXANDRA: "45.001",
    CORP: "23",
    TIMES: "38",
    POW: "63.003",
    DOFC: "28",
    LOVELL: "38.003",
    WIMB: "45.004",
    STG: "65.002",
    KINGS: "92.005",
    DAY1_POS: "583",
    DAY2_POS: "628",
    DAY3_POS: "630",
    DAY4_POS: "568",
    DAY5_POS: "581",
  },
  6900: {
    Index: "6900",
    Init: "HT",
    NAME: "Fearn",
    full_NAME: "HT Fearn",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "145.007",
    GUNSCORE_DAY2: "85.004",
    GUNSCORE_DAY3: "146.007",
    GUNSCORE_DAY4: "140.006",
    GUNSCORE_DAY5: "85.004",
    GUNSCORE_GA: "601.028",
    DAILY_T: "63.004",
    DONEGALL: "40.001",
    CONAN_DOYLE: "42.002",
    DAILY_MAIL: "42.003",
    ALEXANDRA: "43.001",
    CORP: "0",
    TIMES: "42.002",
    POW: "63.004",
    DOFC: "41.001",
    LOVELL: "38.002",
    WIMB: "38.002",
    STG: "64.002",
    KINGS: "85.004",
    DAY1_POS: "618",
    DAY2_POS: "637",
    DAY3_POS: "594",
    DAY4_POS: "609",
    DAY5_POS: "621",
  },
  6901: {
    Index: "6901",
    Init: "A",
    NAME: "Nishikawa",
    full_NAME: "A Nishikawa",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.007",
    GUNSCORE_DAY2: "116.004",
    GUNSCORE_DAY3: "154.009",
    GUNSCORE_DAY4: "111.005",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "540.025",
    DAILY_T: "67.002",
    DONEGALL: "47.003",
    CONAN_DOYLE: "45.002",
    DAILY_MAIL: "43.003",
    ALEXANDRA: "45",
    CORP: "28.001",
    TIMES: "46.004",
    POW: "61.001",
    DOFC: "47.004",
    LOVELL: "42.001",
    WIMB: "0",
    STG: "69.004",
    KINGS: "0",
    DAY1_POS: "536",
    DAY2_POS: "619",
    DAY3_POS: "544",
    DAY4_POS: "629",
    DAY5_POS: "638",
  },
  6902: {
    Index: "6902",
    Init: "MML",
    NAME: "Holt",
    full_NAME: "MML Holt",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "160.012",
    GUNSCORE_DAY2: "126.002",
    GUNSCORE_DAY3: "163.011",
    GUNSCORE_DAY4: "149.006",
    GUNSCORE_DAY5: "95.005",
    GUNSCORE_GA: "693.036",
    DAILY_T: "72.006",
    DONEGALL: "46.006",
    CONAN_DOYLE: "42",
    DAILY_MAIL: "47.002",
    ALEXANDRA: "41",
    CORP: "38",
    TIMES: "44.003",
    POW: "72.005",
    DOFC: "47.003",
    LOVELL: "40.001",
    WIMB: "44.003",
    STG: "65.002",
    KINGS: "95.005",
    DAY1_POS: "517",
    DAY2_POS: "592",
    DAY3_POS: "354",
    DAY4_POS: "565",
    DAY5_POS: "548",
  },
  6903: {
    Index: "6903",
    Init: "IG",
    NAME: "Ogilvie Robinson",
    full_NAME: "IG Ogilvie Robinson",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "150.008",
    GUNSCORE_DAY2: "128.009",
    GUNSCORE_DAY3: "158.01",
    GUNSCORE_DAY4: "155.007",
    GUNSCORE_DAY5: "95.009",
    GUNSCORE_GA: "686.043",
    DAILY_T: "65.003",
    DONEGALL: "43.002",
    CONAN_DOYLE: "42.003",
    DAILY_MAIL: "48.005",
    ALEXANDRA: "40.002",
    CORP: "40.002",
    TIMES: "45.001",
    POW: "71.007",
    DOFC: "42.002",
    LOVELL: "41.002",
    WIMB: "42.002",
    STG: "72.003",
    KINGS: "95.009",
    DAY1_POS: "601",
    DAY2_POS: "562",
    DAY3_POS: "493",
    DAY4_POS: "520",
    DAY5_POS: "536",
  },
  6904: {
    Index: "6904",
    Init: "ARB",
    NAME: "Curtis",
    full_NAME: "ARB Curtis",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "149.009",
    GUNSCORE_DAY2: "133.005",
    GUNSCORE_DAY3: "160.009",
    GUNSCORE_DAY4: "165.015",
    GUNSCORE_DAY5: "101.007",
    GUNSCORE_GA: "708.045",
    DAILY_T: "65.004",
    DONEGALL: "47.005",
    CONAN_DOYLE: "37",
    DAILY_MAIL: "47.003",
    ALEXANDRA: "47.001",
    CORP: "39.001",
    TIMES: "46.001",
    POW: "67.006",
    DOFC: "47.002",
    LOVELL: "46.004",
    WIMB: "45.002",
    STG: "74.009",
    KINGS: "101.007",
    DAY1_POS: "605",
    DAY2_POS: "502",
    DAY3_POS: "451",
    DAY4_POS: "282",
    DAY5_POS: "304",
  },
  6905: {
    Index: "6905",
    Init: "AA",
    NAME: "Gardiner",
    full_NAME: "AA Gardiner",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "123.001",
    GUNSCORE_DAY2: "113.005",
    GUNSCORE_DAY3: "140.007",
    GUNSCORE_DAY4: "151.008",
    GUNSCORE_DAY5: "92.008",
    GUNSCORE_GA: "619.029",
    DAILY_T: "54.001",
    DONEGALL: "36",
    CONAN_DOYLE: "33",
    DAILY_MAIL: "44.003",
    ALEXANDRA: "36.001",
    CORP: "33.001",
    TIMES: "47.005",
    POW: "58.001",
    DOFC: "35.001",
    LOVELL: "48.005",
    WIMB: "43.001",
    STG: "60.002",
    KINGS: "92.008",
    DAY1_POS: "632",
    DAY2_POS: "624",
    DAY3_POS: "618",
    DAY4_POS: "552",
    DAY5_POS: "575",
  },
  6907: {
    Index: "6907",
    Init: "L",
    NAME: "Warden",
    full_NAME: "L Warden",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.006",
    GUNSCORE_DAY2: "126.003",
    GUNSCORE_DAY3: "143.003",
    GUNSCORE_DAY4: "159.007",
    GUNSCORE_DAY5: "98.009",
    GUNSCORE_GA: "674.028",
    DAILY_T: "68.003",
    DONEGALL: "34",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "46",
    ALEXANDRA: "43.002",
    CORP: "37.001",
    TIMES: "43.001",
    POW: "59.002",
    DOFC: "41",
    LOVELL: "45.001",
    WIMB: "44.003",
    STG: "70.003",
    KINGS: "98.009",
    DAY1_POS: "608",
    DAY2_POS: "590",
    DAY3_POS: "614",
    DAY4_POS: "459",
    DAY5_POS: "441",
  },
  6910: {
    Index: "6910",
    Init: "N",
    NAME: "Kanayama",
    full_NAME: "N Kanayama",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "157.009",
    GUNSCORE_DAY2: "130.007",
    GUNSCORE_DAY3: "155.013",
    GUNSCORE_DAY4: "0",
    GUNSCORE_DAY5: "0",
    GUNSCORE_GA: "442.029",
    DAILY_T: "68.006",
    DONEGALL: "43",
    CONAN_DOYLE: "46.003",
    DAILY_MAIL: "46.004",
    ALEXANDRA: "46.002",
    CORP: "38.001",
    TIMES: "47.006",
    POW: "63.004",
    DOFC: "45.003",
    LOVELL: "0",
    WIMB: "0",
    STG: "0",
    KINGS: "0",
    DAY1_POS: "562",
    DAY2_POS: "540",
    DAY3_POS: "532",
    DAY4_POS: "636",
    DAY5_POS: "638",
  },
  6912: {
    Index: "6912",
    Init: "E",
    NAME: "Woodhead",
    full_NAME: "E Woodhead",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "142.005",
    GUNSCORE_DAY2: "120.005",
    GUNSCORE_DAY3: "156.011",
    GUNSCORE_DAY4: "158.013",
    GUNSCORE_DAY5: "92.005",
    GUNSCORE_GA: "668.039",
    DAILY_T: "65.004",
    DONEGALL: "34.001",
    CONAN_DOYLE: "43",
    DAILY_MAIL: "44.002",
    ALEXANDRA: "45.002",
    CORP: "31.001",
    TIMES: "45.001",
    POW: "71.009",
    DOFC: "40.001",
    LOVELL: "42.003",
    WIMB: "45.002",
    STG: "71.008",
    KINGS: "92.005",
    DAY1_POS: "621",
    DAY2_POS: "609",
    DAY3_POS: "523",
    DAY4_POS: "461",
    DAY5_POS: "581",
  },
  6913: {
    Index: "6913",
    Init: "R",
    NAME: "Barrett",
    full_NAME: "R Barrett",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "148.006",
    GUNSCORE_DAY2: "126.005",
    GUNSCORE_DAY3: "126.004",
    GUNSCORE_DAY4: "154.013",
    GUNSCORE_DAY5: "96.006",
    GUNSCORE_GA: "650.034",
    DAILY_T: "64.002",
    DONEGALL: "41.002",
    CONAN_DOYLE: "43.002",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "41.001",
    CORP: "40.002",
    TIMES: "41.002",
    POW: "59.001",
    DOFC: "26.001",
    LOVELL: "45.007",
    WIMB: "47.004",
    STG: "62.002",
    KINGS: "96.006",
    DAY1_POS: "608",
    DAY2_POS: "585",
    DAY3_POS: "631",
    DAY4_POS: "524",
    DAY5_POS: "521",
  },
  6915: {
    Index: "6915",
    Init: "H",
    NAME: "Bishop",
    full_NAME: "H Bishop",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "159.013",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "150.008",
    GUNSCORE_DAY4: "159.012",
    GUNSCORE_DAY5: "96.007",
    GUNSCORE_GA: "698.049",
    DAILY_T: "69.005",
    DONEGALL: "46.004",
    CONAN_DOYLE: "44.004",
    DAILY_MAIL: "46.003",
    ALEXANDRA: "46.003",
    CORP: "42.003",
    TIMES: "40",
    POW: "66.006",
    DOFC: "44.002",
    LOVELL: "42.001",
    WIMB: "47.005",
    STG: "70.006",
    KINGS: "96.007",
    DAY1_POS: "527",
    DAY2_POS: "466",
    DAY3_POS: "577",
    DAY4_POS: "450",
    DAY5_POS: "512",
  },
  6916: {
    Index: "6916",
    Init: "E",
    NAME: "Chrystal",
    full_NAME: "E Chrystal",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "145.005",
    GUNSCORE_DAY2: "129.005",
    GUNSCORE_DAY3: "152.009",
    GUNSCORE_DAY4: "129.011",
    GUNSCORE_DAY5: "99.009",
    GUNSCORE_GA: "654.039",
    DAILY_T: "64.003",
    DONEGALL: "39.001",
    CONAN_DOYLE: "42.001",
    DAILY_MAIL: "45.002",
    ALEXANDRA: "45.003",
    CORP: "39",
    TIMES: "38.001",
    POW: "70.006",
    DOFC: "44.002",
    LOVELL: "13",
    WIMB: "47.006",
    STG: "69.005",
    KINGS: "99.009",
    DAY1_POS: "619",
    DAY2_POS: "553",
    DAY3_POS: "565",
    DAY4_POS: "622",
    DAY5_POS: "396",
  },
  6919: {
    Index: "6919",
    Init: "HS",
    NAME: "Jones",
    full_NAME: "HS Jones",
    Day_1: "0",
    Day_1_BONUS: "0",
    Day_1_Total: "0",
    Day_2: "0",
    Day_2_BONUS: "0",
    Day_2_Total: "0",
    Day_3: "0",
    Day_3_BONUS: "0",
    Day_3_Total: "0",
    Day_4: "0",
    Day_4_BONUS: "0",
    Day_4_Total: "0",
    Day_5: "0",
    Day_5_BONUS: "0",
    Day_5_Total: "0",
    GRAND_MULTIPLIER: "0",
    FINAL_TOTAL: "0",
    GUNSCORE_DAY1: "165.011",
    GUNSCORE_DAY2: "134.009",
    GUNSCORE_DAY3: "159.013",
    GUNSCORE_DAY4: "158.01",
    GUNSCORE_DAY5: "64.006",
    GUNSCORE_GA: "680.049",
    DAILY_T: "70.004",
    DONEGALL: "47.003",
    CONAN_DOYLE: "48.004",
    DAILY_MAIL: "45.003",
    ALEXANDRA: "48.005",
    CORP: "41.001",
    TIMES: "49.007",
    POW: "62.005",
    DOFC: "48.001",
    LOVELL: "41.002",
    WIMB: "47.003",
    STG: "70.005",
    KINGS: "64.006",
    DAY1_POS: "432",
    DAY2_POS: "466",
    DAY3_POS: "461",
    DAY4_POS: "466",
    DAY5_POS: "631",
  },
};
