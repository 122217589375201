import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Switch,
  FormControlLabel,
  CardActions,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { shooterDetails, pseudonyms } from "../constants/data.js";
import PickedTeam from "./pickedTeam.js";
import ShooterProfile from "./shooterProfile.js";

import { countryNameToCode } from "../constants/functions.js";

const ShooterGrid = (props) => {
  const [trid, setTrid] = useState(false);

  const handlePickUser = (user) => {
    console.log(user);
    let team = [...props.pickedTeam];
    team.push(user);
    props.setPickedTeam(team);
    const dataToStore = JSON.stringify({
      ...props.localData,
      teamPicking: team,
    });
    localStorage.setItem("fantasyFullbore", dataToStore);
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <ShooterProfile
        trid={trid}
        setTrid={setTrid}
        handlePickUser={handlePickUser}
        pickedTeam={props.pickedTeam}
      />
      {props.shooterDetails &&
        props.shooterDetails.map((user) => (
          <Grid key={user.trid} item xs={12} sm={6} md={4} lg={3}>
            {(user.consent = true)}
            <Card
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Button onClick={() => setTrid(user.trid)}>
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      aria-label={user.country}
                      src={countryNameToCode(user.country)}
                    />
                  }
                  title={user.consent ? user.seed_name : pseudonyms[user.seed]}
                  titleTypographyProps={{
                    sx: { fontSize: "16px", textAlign: "left" },
                  }}
                  subheader={user.country}
                  subheaderTypographyProps={{
                    sx: { fontSize: "12px", textAlign: "left" },
                  }}
                />
              </Button>
              <CardContent sx={{ flexGrow: 1, pt: 0 }}>
                <Divider sx={{ mb: 1 }} />
                <Typography variant="h5" color="primary">
                  £{user.cost.toLocaleString()}
                </Typography>
                <Divider sx={{ mt: 1, mb: 1 }} />
                <Typography variant="body1">Class:{user.class}</Typography>
                {user.consent && (
                  <React.Fragment>
                    <Typography variant="body2">Club:{user.club}</Typography>
                    <Typography variant="body2">
                      County: {user.county}
                    </Typography>
                  </React.Fragment>
                )}
              </CardContent>
              <CardActions sx={{ bottom: 0 }}>
                <Button
                  disabled={props.pickedTeam.some(
                    (item) => item.trid === user.trid
                  )}
                  fullWidth
                  variant="outlined"
                  onClick={() => handlePickUser(user)}
                >
                  Add to your team
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default ShooterGrid;
