import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import SelectedShooter from "./selectedShooter";
import { withFirebase } from "../../Firebase";
import NewShooter from "./newShooter";

const Shooters = (props) => {
  const [allShooters, setAllShooters] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [newUserOpen, setNewUserOpen] = useState(false);

  useEffect(() => {
    const subscribe = props.firebase.db
      .doc(`shooters/index`)
      .onSnapshot((snapshot) => {
        const shooters = [];
        Object.entries(snapshot.data()).map(([key, value]) => {
          shooters.push({ id: key, ...value });
        });
        const compareBySeed = (a, b) => {
          if (a.cost > b.cost) {
            return -1;
          } else if (a.cost < b.cost) {
            return 1;
          } else {
            return 0;
          }
        };
        shooters.sort(compareBySeed);
        setAllShooters(shooters);
      });
    return () => subscribe();
  }, []);

  async function openModal(id) {
    console.log(id);
    const snapshot = await props.firebase.db.doc(`shooters/${id}`).get();
    const data = snapshot.data();
    setSelected({ id, ...data[id], ...data });
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
    setSelected(null);
  }

  function closeNewUser() {
    setNewUserOpen(false);
  }
  return (
    <Box>
      <Button variant="contained" onClick={() => setNewUserOpen(true)}>
        Add New Shooter
      </Button>
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <Modal open={modalOpen} onClose={() => closeModal()}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            {console.log(selected)}
            {selected !== null && (
              <SelectedShooter selected={selected} closeModal={closeModal} />
            )}
          </Box>
        </Modal>
        <Modal open={newUserOpen} onClose={() => closeNewUser()}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <NewShooter closeNewUser={closeNewUser} />
          </Box>
        </Modal>
        {allShooters !== null
          ? allShooters.map((shooter) => (
              <Card
                sx={{
                  width: "300px",
                  margin: "5px",
                }}
                key={shooter.id}
              >
                <CardContent>
                  <Typography>{shooter.id}</Typography>
                  <Typography variant="h6">{shooter.seed_name}</Typography>
                  <Typography>{shooter.email}</Typography>
                  <Typography>Club: {shooter.club}</Typography>
                  <Typography>County: {shooter.county}</Typography>
                  <Typography>Country: {shooter.country}</Typography>
                  <Typography>
                    Shooting Grand: {shooter.shootingGrand ? `TRUE` : `FALSE`}
                  </Typography>
                  <Typography>
                    consent: {shooter.consent ? `TRUE` : `FALSE`}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => openModal(shooter.id)}>Edit</Button>
                </CardActions>
              </Card>
            ))
          : `Loading`}
      </Box>
    </Box>
  );
};

export default withFirebase(Shooters);
