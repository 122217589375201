import React, { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { trids } from "../constants/trids";

const ResultListComponent = ({
  results,
  onSelectResult,
  onSubmit,
  setResults,
  setLastName,
  setTrid,
}) => {
  const [selectedResult, setSelectedResult] = useState("");

  const handleRadioChange = (e) => {
    setSelectedResult(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSelectResult(selectedResult);
    onSubmit();
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <RadioGroup value={selectedResult} onChange={handleRadioChange}>
          {results.map((result, index) => (
            <FormControlLabel
              key={index}
              value={JSON.stringify(result)}
              control={<Radio />}
              label={`${result.title} ${result.initials} ${result.surname} - ${result.trid}`}
            />
          ))}
        </RadioGroup>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="submit" variant="contained" color="secondary">
            Select
          </Button>
          <Button
            onClick={() => {
              setResults([]);
              setLastName("");
              setTrid("");
            }}
          >
            Back
          </Button>
        </Box>
      </form>
    </Box>
  );
};

const FormComponent = (props) => {
  const [lastName, setLastName] = useState("");
  const [results, setResults] = useState([]);
  const [trid, setTrid] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const keys = Object.keys(trids);
    const matchingResults = keys.reduce((results, key) => {
      const item = trids[key];
      if (
        item &&
        item.Surname &&
        item.Surname.toLowerCase() === lastName.toLowerCase()
      ) {
        console.log(item);
        results.push({
          trid: key,
          title: item.Title,
          initials: item.Initials,
          surname: item.Surname,
          primaryClub: item["Primary Club"],
          county: item.County,
          country: item.Country,
        });
      }
      return results;
    }, []);
    console.log(matchingResults);
    setResults(matchingResults);
  };

  const handleTridSubmit = (e) => {
    e.preventDefault();
    console.log(trids[trid]);
    if (trids[trid]) {
      setResults([
        {
          trid,
          title: trids[trid].Title,
          initials: trids[trid].Initials,
          surname: trids[trid].Surname,
          primaryClub: trids[trid]["Primary Club"],
          county: trids[trid].County,
          country: trids[trid].Country,
        },
      ]);
    }
  };
  const handleSelectResult = (selectedResult) => {
    console.log("Selected Result:", JSON.parse(selectedResult));
    // You can perform any further actions with the selected result here
    props.setShooterProfile(JSON.parse(selectedResult));
    props.setStage(3);
  };

  const handleReset = () => {
    setResults([]);
    setLastName("");
    setTrid("");
  };

  return (
    <div>
      {results.length === 0 && (
        <React.Fragment>
          <Typography>If you know your TRID, please enter it here.</Typography>
          <form onSubmit={handleTridSubmit}>
            <TextField
              label="Trid"
              value={trid}
              onChange={(e) => {
                setTrid(e.target.value);
                setLastName("");
              }}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #999999",
                },

                mt: 2,
              }}
            />
            <Button
              sx={{ mt: 2, mb: 2 }}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </form>
          <Typography>
            Alternatively you can search for it using your last name:
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setTrid("");
              }}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  border: "1px solid #999999",
                },
                mt: 2,
              }}
            />
            <Button
              sx={{ mt: 2, mb: 2 }}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Submit
            </Button>
          </form>
        </React.Fragment>
      )}
      {results.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h5">Details entered:</Typography>
          {trid !== "" && (
            <Typography variant="body1" paragraph>
              Trid - {trid}
            </Typography>
          )}
          {lastName !== "" && (
            <Typography variant="body1" paragraph>
              Last name - {lastName}
            </Typography>
          )}
          <Typography variant="h5">Now select your name:</Typography>
          <ResultListComponent
            results={results}
            onSelectResult={handleSelectResult}
            onSubmit={handleReset}
            setResults={setResults}
            setLastName={setLastName}
            setTrid={setTrid}
          />
        </Box>
      )}
    </div>
  );
};

export default FormComponent;
