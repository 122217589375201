import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import * as EmailValidator from "email-validator";
import { withFirebase } from "../Firebase";

const ConfirmProfile = (props) => {
  const [formData, setFormData] = useState({
    class: "",
    consent: true,
    email: "",
  });
  const [profile, setProfile] = useState(false);
  const [errors, setErrors] = useState({ class: false, email: false });
  const [submitted, setSubmitted] = useState(false);
  const [fullProfile, setFullProfile] = useState(false);

  useEffect(() => {
    if (props.shooterProfile) {
      const unsubscribe = props.firebase.db
        .doc(`shooters/${props.shooterProfile.trid}`)
        .onSnapshot((result) => {
          if (result.exists) {
            setProfile({
              ...props.shooterProfile,
              seed: result.data().seed,
              cost: result.data().cost,
            });
            setFullProfile(result.data());
            setFormData((prevFormData) => ({
              ...prevFormData,
              class: result.data().class,
            }));
          } else {
            setProfile({ ...props.shooterProfile, seed: 9999, cost: 5000 });
            setFullProfile(false);
          }
        });
      return () => unsubscribe();
    }
  }, [props.shooterProfile]);

  function isValidString(str) {
    return str !== "" && ["T", "A", "O", "X", "INT"].includes(str);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    const emailValid = EmailValidator.validate(formData.email);
    const classValid = isValidString(formData.class);

    setErrors((prevErrors) => ({
      ...prevErrors,
      email: !emailValid,
      class: !classValid,
    }));

    if (emailValid && classValid) {
      try {
        await props.firebase.saveConsent(profile, formData);
        props.setStage(4);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {console.log(fullProfile)}
      <Typography variant="h4">Please check your details</Typography>
      <Box sx={{ width: "100%", border: "1px solid grey", m: 2, p: 2 }}>
        <Typography>TRID: {props.shooterProfile.trid}</Typography>
        <Typography>
          Name: {props.shooterProfile.title} {props.shooterProfile.initials}{" "}
          {props.shooterProfile.surname}
        </Typography>
        <Typography>
          Primary Club: {props.shooterProfile.primaryClub}
        </Typography>
        <Typography>County: {props.shooterProfile.county}</Typography>
        <Typography>Country: {props.shooterProfile.country}</Typography>
        {fullProfile && (
          <Box sx={{ mt: 2 }}>
            <Divider />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Past performance top 50 finishes:
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Grand Agg</TableCell>
                  <TableCell>HM Queens Prize</TableCell>
                  <TableCell>St Georges</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: 4 }, (_, index) => {
                  const i = 19 + index;
                  return (
                    <TableRow key={i}>
                      <TableCell>20{i}</TableCell>
                      <TableCell>{fullProfile[`ga_` + i]}</TableCell>
                      <TableCell>{fullProfile[`q_` + i]}</TableCell>
                      <TableCell>{fullProfile[`stg_` + i]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
      <Box>
        <Typography>
          If the above details are incorrect, please conntact us at:
          fantasyfullbore@gmail.com
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">
          To join in with Fantasy Fullbore, please enter the following details:
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email address"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            fullWidth
            error={errors.email}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #999999",
              },
              mb: 2,
              mt: 2,
            }}
          />
          <TextField
            label="2023 TR Class"
            value={formData.class}
            onChange={(e) =>
              setFormData({ ...formData, class: e.target.value.toUpperCase() })
            }
            fullWidth
            error={errors.class}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "1px solid #999999",
              },
              mb: 2,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.consent}
                onChange={() =>
                  setFormData({ ...formData, consent: !formData.consent })
                }
              />
            }
            label={"Add me to selection pool"}
          />
          <Button type="submit" variant="contained" color="secondary">
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default withFirebase(ConfirmProfile);
