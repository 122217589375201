import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import {
  AccordionDetails,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import firebase from "firebase/compat/app";
import Toast from "../Toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",

  bgcolor: "background.paper",
  p: 4,
  overflow: "scroll",
};
const TeamEditDetails = (props) => {
  const [team, setTeam] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleToastClose = () => {
    setShowToast(false);
  };
  function editNameButton(tid, name) {
    props.setTid(tid);
    props.setName(name);
    props.setNameOpen(true);
  }
  return (
    <Box
      sx={{
        display: "flex",

        flexDirection: "column",
      }}
    >
      {showToast && (
        <Toast
          message="Thanks, we're sending you a link to edit your teams. Please check all email folders (including junk)."
          onClose={handleToastClose}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: props.team.reconciled ? "green" : "red" }}>
          Team Payment : {props.team.reconciled ? "Recieved" : "Awaiting"}
        </Typography>
        <Typography>
          Cost : {props.team.cost ? `£${props.team.cost.toLocaleString()}` : 0}
        </Typography>
        {props.preGrand && (
          <Button
            align="right"
            onClick={() => editNameButton(props.team.tid, props.team.teamName)}
          >
            Edit Name
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default withFirebase(TeamEditDetails);
