export const trids = {
  1: {
    Title: "Mr",
    Initials: "AH",
    Surname: "Abbott",
    "Primary Club": "Concord Rifle Club",
    County: "",
    Country: "Australia",
  },
  2: {
    Title: "",
    Initials: "BJ",
    Surname: "Adams",
    "Primary Club": "Spalding RC",
    County: "Lincolnshire",
    Country: "England",
  },
  3: {
    Title: "",
    Initials: "MJ",
    Surname: "Allender",
    "Primary Club": "West Suffolk R.C",
    County: "Suffolk",
    Country: "England",
  },
  4: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Amin",
    "Primary Club": "Epsom College",
    County: "",
    Country: "",
  },
  5: {
    Title: "POA(SE)",
    Initials: "TT",
    Surname: "Amy",
    "Primary Club": "Stock Exchange RC",
    County: "Cornwall",
    Country: "England",
  },
  6: {
    Title: "",
    Initials: "AI",
    Surname: "Anderson",
    "Primary Club": "Oxford University R.C",
    County: "",
    Country: "",
  },
  7: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Anyan",
    "Primary Club": "Louth RC",
    County: "Lincolnshire",
    Country: "England",
  },
  8: {
    Title: "Mr",
    Initials: "DT",
    Surname: "Argent",
    "Primary Club": "NLRC",
    County: "Suffolk",
    Country: "",
  },
  9: {
    Title: "Cdt",
    Initials: "EH",
    Surname: "Arthur",
    "Primary Club": "Athelings",
    County: "Kent",
    Country: "England",
  },
  10: {
    Title: "",
    Initials: "D",
    Surname: "Augier de Montgremier",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  11: {
    Title: "Mrs",
    Initials: "G",
    Surname: "Augier de Montgremier",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  12: {
    Title: "",
    Initials: "GV",
    Surname: "Aust",
    "Primary Club": "Barclays Bank R.C",
    County: "Dumphries & Galloway",
    Country: "England",
  },
  13: {
    Title: "Cdt",
    Initials: "BD",
    Surname: "Austin",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  14: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Awong",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "",
  },
  15: {
    Title: "JCpl",
    Initials: "D",
    Surname: "Barker",
    "Primary Club": "Malvern College",
    County: "",
    Country: "",
  },
  16: {
    Title: "Miss",
    Initials: "SM",
    Surname: "Bates",
    "Primary Club": "St Andrews Uni. R.C",
    County: "Kent",
    Country: "Scotland",
  },
  17: {
    Title: "",
    Initials: "RGP",
    Surname: "Baujot-Julien",
    "Primary Club": "U.N.C",
    County: "",
    Country: "France",
  },
  18: {
    Title: "Cdt",
    Initials: "CM",
    Surname: "Beaudreau",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  19: {
    Title: "Cdt",
    Initials: "DJG",
    Surname: "Bell",
    "Primary Club": "Athelings",
    County: "Hampshire",
    Country: "England",
  },
  20: {
    Title: "Miss",
    Initials: "KA",
    Surname: "Berman",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  21: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Bhargava",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  22: {
    Title: "Cdt",
    Initials: "PS",
    Surname: "Bhogal",
    "Primary Club": "Kelvinside Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  23: {
    Title: "Miss",
    Initials: "C",
    Surname: "Bisseux",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "",
  },
  24: {
    Title: "",
    Initials: "SG",
    Surname: "Black",
    "Primary Club": "OURC",
    County: "Ulster",
    Country: "N. Ireland",
  },
  25: {
    Title: "",
    Initials: "D",
    Surname: "Blotz",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  26: {
    Title: "Cdt",
    Initials: "AJ",
    Surname: "Bogle",
    "Primary Club": "Athelings",
    County: "",
    Country: "",
  },
  27: {
    Title: "Cdt",
    Initials: "SA",
    Surname: "Breeze",
    "Primary Club": "Athelings",
    County: "Norfolk",
    Country: "England",
  },
  28: {
    Title: "Miss",
    Initials: "JK",
    Surname: "Brekke",
    "Primary Club": "Torfaen RC",
    County: "Glamorgan",
    Country: "Wales",
  },
  29: {
    Title: "",
    Initials: "JD",
    Surname: "Brettell",
    "Primary Club": "Bristol University",
    County: "",
    Country: "",
  },
  30: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Brister",
    "Primary Club": "Sussex CRA",
    County: "Sussex",
    Country: "England",
  },
  31: {
    Title: "",
    Initials: "CA",
    Surname: "Britton",
    "Primary Club": "East of Scotland R.C",
    County: "",
    Country: "Scotland",
  },
  32: {
    Title: "Mr",
    Initials: "PB",
    Surname: "Bromley",
    "Primary Club": "Old Sedberghians",
    County: "Kent",
    Country: "England",
  },
  33: {
    Title: "",
    Initials: "AA",
    Surname: "Brown",
    "Primary Club": "Edinburgh University R.C",
    County: "East of Scotland",
    Country: "England",
  },
  34: {
    Title: "Mr",
    Initials: "AI",
    Surname: "Brown",
    "Primary Club": "Wandsworth FBRC",
    County: "",
    Country: "England",
  },
  35: {
    Title: "",
    Initials: "EDJ",
    Surname: "Brown",
    "Primary Club": "Old Wellingtonians",
    County: "Oxfordshire",
    Country: "England",
  },
  36: {
    Title: "Miss",
    Initials: "KA",
    Surname: "Buck",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  37: {
    Title: "",
    Initials: "AJ",
    Surname: "Burnell",
    "Primary Club": "OWRC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  38: {
    Title: "",
    Initials: "MJ",
    Surname: "Burns",
    "Primary Club": "Oxford University R.C",
    County: "Berkshire",
    Country: "England",
  },
  39: {
    Title: "",
    Initials: "JEC",
    Surname: "Burridge",
    "Primary Club": "British Snipers",
    County: "",
    Country: "",
  },
  40: {
    Title: "",
    Initials: "G",
    Surname: "Campbell",
    "Primary Club": "West of Scotland R.C",
    County: "West of Scotland",
    Country: "Scotland",
  },
  42: {
    Title: "CI",
    Initials: "ML",
    Surname: "Carew",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  43: {
    Title: "",
    Initials: "RCS",
    Surname: "Carter",
    "Primary Club": "Old Malvernians",
    County: "Hereford & Worcs",
    Country: "England",
  },
  44: {
    Title: "Dr",
    Initials: "M",
    Surname: "Cassidy",
    "Primary Club": "Bombscare 89",
    County: "Surrey",
    Country: "England",
  },
  45: {
    Title: "Lt Sir",
    Initials: "DWD",
    Surname: "Cayley",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  46: {
    Title: "",
    Initials: "JA",
    Surname: "Chapman",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  47: {
    Title: "",
    Initials: "DAR",
    Surname: "Chase",
    "Primary Club": "DURC",
    County: "",
    Country: "England",
  },
  48: {
    Title: "",
    Initials: "GSG",
    Surname: "Chittock",
    "Primary Club": "Leeds Service R.C",
    County: "Yorkshire",
    Country: "England",
  },
  49: {
    Title: "Capt",
    Initials: "MK",
    Surname: "Chivers",
    "Primary Club": "5 Regt AAC",
    County: "Sussex",
    Country: "England",
  },
  50: {
    Title: "Mr",
    Initials: "ASJ",
    Surname: "Clark",
    "Primary Club": "Uppingham Veterans RC",
    County: "",
    Country: "",
  },
  51: {
    Title: "",
    Initials: "CR",
    Surname: "Clark",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  52: {
    Title: "",
    Initials: "BS",
    Surname: "Clarke",
    "Primary Club": "Darlington R & P.C",
    County: "Northumberland",
    Country: "England",
  },
  53: {
    Title: "Cdt",
    Initials: "V",
    Surname: "Cloutier",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  54: {
    Title: "",
    Initials: "RJ",
    Surname: "Coertze",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  56: {
    Title: "Cdt",
    Initials: "CE",
    Surname: "Collier",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  57: {
    Title: "SI",
    Initials: "E",
    Surname: "Cooper",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  58: {
    Title: "",
    Initials: "PA",
    Surname: "Cordell",
    "Primary Club": "Nottingham University",
    County: "Bedfordshire",
    Country: "England",
  },
  59: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Cotillard",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  60: {
    Title: "Cdt",
    Initials: "F",
    Surname: "Cousineau",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  61: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Cousineau",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  62: {
    Title: "",
    Initials: "J",
    Surname: "Craig",
    "Primary Club": "DCRA",
    County: "Alberta",
    Country: "Canada",
  },
  63: {
    Title: "",
    Initials: "DB",
    Surname: "Croxford",
    "Primary Club": "OURC",
    County: "",
    Country: "England",
  },
  64: {
    Title: "LCpl",
    Initials: "TE",
    Surname: "Crump",
    "Primary Club": "Malvern College",
    County: "",
    Country: "",
  },
  65: {
    Title: "",
    Initials: "MR",
    Surname: "Culbertson Jr",
    "Primary Club": "",
    County: "",
    Country: "United States of America",
  },
  66: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Cummings",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  67: {
    Title: "",
    Initials: "RAJ",
    Surname: "Dabson",
    "Primary Club": "Old Wellingtonians",
    County: "Berkshire",
    Country: "England",
  },
  68: {
    Title: "Sgt",
    Initials: "GC",
    Surname: "Davies",
    "Primary Club": "R.G.S Guildford",
    County: "Surrey",
    Country: "England",
  },
  69: {
    Title: "Flt Lt",
    Initials: "SG",
    Surname: "Davies",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "Great Britain",
  },
  70: {
    Title: "",
    Initials: "MP",
    Surname: "Davis",
    "Primary Club": "C.U.R.A",
    County: "Gloucestershire",
    Country: "United Kingdom",
  },
  71: {
    Title: "",
    Initials: "RA",
    Surname: "Dawson",
    "Primary Club": "Roseville",
    County: "",
    Country: "South Africa",
  },
  72: {
    Title: "Off Cdt",
    Initials: "NJ",
    Surname: "Dehnel",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  73: {
    Title: "",
    Initials: "ALJM",
    Surname: "Derks",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Holland",
  },
  74: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Desrochers",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  75: {
    Title: "Cdt",
    Initials: "CW",
    Surname: "Dewar",
    "Primary Club": "Athelings",
    County: "",
    Country: "Scotland",
  },
  76: {
    Title: "",
    Initials: "C",
    Surname: "Dewhurst",
    "Primary Club": "Leeds Service R.C",
    County: "Yorkshire",
    Country: "England",
  },
  77: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Dobie",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "",
  },
  78: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Dubreuil",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  79: {
    Title: "Mr",
    Initials: "PD",
    Surname: "Dudzinski",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  80: {
    Title: "",
    Initials: "TJ",
    Surname: "Duncan",
    "Primary Club": "East Grinstead TSC",
    County: "Sussex",
    Country: "",
  },
  81: {
    Title: "Mrs",
    Initials: "BA",
    Surname: "Dyas",
    "Primary Club": "Greensleeves",
    County: "Gloucestershire",
    Country: "England",
  },
  82: {
    Title: "Miss",
    Initials: "REA",
    Surname: "Eatwell",
    "Primary Club": "Sussex CRA",
    County: "Sussex",
    Country: "England",
  },
  83: {
    Title: "",
    Initials: "DA",
    Surname: "Eckles",
    "Primary Club": "Old Oakhamians RC",
    County: "",
    Country: "",
  },
  84: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Ensor",
    "Primary Club": "Old Guildfordians RC",
    County: "Surrey",
    Country: "England",
  },
  85: {
    Title: "Cdt",
    Initials: "LK",
    Surname: "Evans",
    "Primary Club": "Cranbrook School",
    County: "Kent",
    Country: "England",
  },
  86: {
    Title: "Cdt",
    Initials: "PD",
    Surname: "Eyre",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "United Kingdom",
  },
  87: {
    Title: "",
    Initials: "MG",
    Surname: "Farnan",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  88: {
    Title: "Cdt",
    Initials: "WG",
    Surname: "Feardon",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  89: {
    Title: "Mr",
    Initials: "IJ",
    Surname: "Ferreira",
    "Primary Club": "NLRC",
    County: "County of London",
    Country: "England",
  },
  90: {
    Title: "Mr",
    Initials: "DE",
    Surname: "Fields",
    "Primary Club": "Guyana NRA",
    County: "",
    Country: "West Indies",
  },
  91: {
    Title: "Mr",
    Initials: "RB",
    Surname: "Fields",
    "Primary Club": "Guyana NRA",
    County: "",
    Country: "Guyana",
  },
  92: {
    Title: "",
    Initials: "AT",
    Surname: "Finch",
    "Primary Club": "A.T.R.C.",
    County: "",
    Country: "",
  },
  93: {
    Title: "Mid",
    Initials: "MJ",
    Surname: "Flegg",
    "Primary Club": "NACRA",
    County: "",
    Country: "",
  },
  94: {
    Title: "",
    Initials: "A",
    Surname: "Forsyth",
    "Primary Club": "Bromsgrove R.C",
    County: "Hereford & Worcs",
    Country: "",
  },
  95: {
    Title: "Cdt",
    Initials: "JA",
    Surname: "Forsyth",
    "Primary Club": "Athelings",
    County: "Cumbria",
    Country: "England",
  },
  96: {
    Title: "",
    Initials: "PL",
    Surname: "Foster",
    "Primary Club": "Edinburgh University R.C",
    County: "Berkshire",
    Country: "England",
  },
  97: {
    Title: "Maj (Retd)",
    Initials: "SB",
    Surname: "Fraser",
    "Primary Club": "Somerset R.A",
    County: "Somerset",
    Country: "England",
  },
  98: {
    Title: "",
    Initials: "RJ",
    Surname: "Friesen",
    "Primary Club": "DCRA",
    County: "British Columbia",
    Country: "Canada",
  },
  99: {
    Title: "Mr",
    Initials: "AGM",
    Surname: "Fyfe",
    "Primary Club": "Uppingham Veterans RC",
    County: "Surrey",
    Country: "Scotland",
  },
  100: {
    Title: "",
    Initials: "TM",
    Surname: "Gadsby",
    "Primary Club": "LMRA",
    County: "Hampshire",
    Country: "England",
  },
  101: {
    Title: "",
    Initials: "RJ",
    Surname: "Gamboa",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  102: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Ganter",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  103: {
    Title: "Sgt",
    Initials: "S",
    Surname: "Garbutt",
    "Primary Club": "RMRA",
    County: "",
    Country: "",
  },
  104: {
    Title: "Mr",
    Initials: "PH",
    Surname: "Gardner",
    "Primary Club": "Fareham R & PC",
    County: "Hampshire",
    Country: "England",
  },
  105: {
    Title: "",
    Initials: "MRT",
    Surname: "Garnier",
    "Primary Club": "NRA",
    County: "Hereford & Worcs",
    Country: "England",
  },
  106: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Gauthier",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  107: {
    Title: "Mr",
    Initials: "E",
    Surname: "Gauvin",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  108: {
    Title: "Mr",
    Initials: "AJT",
    Surname: "Gemmill",
    "Primary Club": "West Suffolk RC",
    County: "Suffolk",
    Country: "England",
  },
  109: {
    Title: "Mr",
    Initials: "GMLN",
    Surname: "Gilpin",
    "Primary Club": "NRA",
    County: "Devon",
    Country: "Ireland",
  },
  110: {
    Title: "Cllr",
    Initials: "JK",
    Surname: "Glen",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "Scotland",
  },
  111: {
    Title: "",
    Initials: "PJ",
    Surname: "Glenton",
    "Primary Club": "OURC",
    County: "",
    Country: "",
  },
  112: {
    Title: "",
    Initials: "JRJ",
    Surname: "Goddin",
    "Primary Club": "Bath University R.C",
    County: "Somerset",
    Country: "England",
  },
  113: {
    Title: "Mr",
    Initials: "J",
    Surname: "Golling",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  114: {
    Title: "Cdt",
    Initials: "PA",
    Surname: "Gorsuch",
    "Primary Club": "Sevenoaks School",
    County: "Sussex",
    Country: "England",
  },
  115: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Gougeon",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  116: {
    Title: "",
    Initials: "CH",
    Surname: "Gough",
    "Primary Club": "Bath University R.C",
    County: "",
    Country: "",
  },
  117: {
    Title: "Cdt",
    Initials: "BA",
    Surname: "Grant-Jones",
    "Primary Club": "Athelings",
    County: "Bedfordshire",
    Country: "Wales",
  },
  118: {
    Title: "Mr",
    Initials: "J",
    Surname: "Grant-Jones",
    "Primary Club": "ATSC",
    County: "",
    Country: "",
  },
  119: {
    Title: "Miss",
    Initials: "DL",
    Surname: "Griffin",
    "Primary Club": "Horsham",
    County: "Sussex",
    Country: "England",
  },
  120: {
    Title: "Mrs",
    Initials: "AI",
    Surname: "Gravett-Hakim",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  121: {
    Title: "Dr",
    Initials: "CD",
    Surname: "Haley",
    "Primary Club": "OCRA",
    County: "London",
    Country: "England",
  },
  122: {
    Title: "Mr",
    Initials: "NP",
    Surname: "Haley",
    "Primary Club": "WRA Bromsgrove",
    County: "",
    Country: "Wales",
  },
  123: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Halliday",
    "Primary Club": "URA",
    County: "Ulster",
    Country: "Ireland",
  },
  124: {
    Title: "",
    Initials: "GF",
    Surname: "Hallidie",
    "Primary Club": "O.G.R.E",
    County: "Norfolk",
    Country: "England",
  },
  125: {
    Title: "Mr",
    Initials: "V",
    Surname: "Hanna",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  126: {
    Title: "UO",
    Initials: "MR",
    Surname: "Harbour",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  127: {
    Title: "Cdt",
    Initials: "NCB",
    Surname: "Harden",
    "Primary Club": "URA",
    County: "Ulster",
    Country: "Ireland",
  },
  128: {
    Title: "",
    Initials: "AW",
    Surname: "Harriss",
    "Primary Club": "Ibis R.C",
    County: "Worcestershire",
    Country: "England",
  },
  129: {
    Title: "",
    Initials: "RK",
    Surname: "Hawley",
    "Primary Club": "OCRA",
    County: "County of London",
    Country: "Ireland",
  },
  130: {
    Title: "",
    Initials: "MW",
    Surname: "Hayden",
    "Primary Club": "NLRC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  131: {
    Title: "Mr",
    Initials: "JWT",
    Surname: "Headon",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "",
  },
  132: {
    Title: "Mr",
    Initials: "S",
    Surname: "Heard",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  133: {
    Title: "Capt",
    Initials: "FA",
    Surname: "Hearn",
    "Primary Club": "A.T.S.C",
    County: "Hertfordshire",
    Country: "England",
  },
  134: {
    Title: "Cdt",
    Initials: "WA",
    Surname: "Herdman",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "",
  },
  135: {
    Title: "",
    Initials: "A",
    Surname: "Herron",
    "Primary Club": "East of Scotland R.C",
    County: "East of Scotland",
    Country: "Ireland",
  },
  136: {
    Title: "Mr",
    Initials: "C",
    Surname: "Herter",
    "Primary Club": "Bayern",
    County: "",
    Country: "Germany",
  },
  137: {
    Title: "Major",
    Initials: "P",
    Surname: "Heyburn",
    "Primary Club": "A.T.R.C.",
    County: "Ulster",
    Country: "Ireland",
  },
  138: {
    Title: "",
    Initials: "LLDL",
    Surname: "Hibbert",
    "Primary Club": "Suffolk CRA",
    County: "Suffolk",
    Country: "England",
  },
  139: {
    Title: "Mr",
    Initials: "S",
    Surname: "Hijman",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  140: {
    Title: "",
    Initials: "JAR",
    Surname: "Hodge",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "Scotland",
  },
  141: {
    Title: "Mr",
    Initials: "RM",
    Surname: "Hodgins",
    "Primary Club": "HBSA",
    County: "",
    Country: "",
  },
  142: {
    Title: "",
    Initials: "MI",
    Surname: "Hohenberg",
    "Primary Club": "U.L.R.C",
    County: "Surrey",
    Country: "England",
  },
  143: {
    Title: "",
    Initials: "OJ",
    Surname: "Holbrook",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  144: {
    Title: "Mr",
    Initials: "C",
    Surname: "Hughes",
    "Primary Club": "Dungannon",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  145: {
    Title: "",
    Initials: "GC",
    Surname: "Hulbert",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  146: {
    Title: "Mrs",
    Initials: "CE",
    Surname: "Jeens",
    "Primary Club": "Old Epsomian RC",
    County: "",
    Country: "Wales",
  },
  147: {
    Title: "Cdt",
    Initials: "DT",
    Surname: "Jackson",
    "Primary Club": "Gresham's R.C",
    County: "Essex",
    Country: "England",
  },
  148: {
    Title: "Dr.",
    Initials: "MP",
    Surname: "James",
    "Primary Club": "OCRA",
    County: "County of London",
    Country: "",
  },
  149: {
    Title: "Mr",
    Initials: "RCT",
    Surname: "Jeens",
    "Primary Club": "Welsh RA",
    County: "London",
    Country: "England",
  },
  150: {
    Title: "Miss",
    Initials: "HA",
    Surname: "Jenkins",
    "Primary Club": "OURC",
    County: "Kent",
    Country: "England",
  },
  151: {
    Title: "",
    Initials: "PC",
    Surname: "Jess",
    "Primary Club": "Dungannon",
    County: "Tyrone",
    Country: "Ireland",
  },
  152: {
    Title: "Mr",
    Initials: "JI",
    Surname: "Jobson",
    "Primary Club": "NRA",
    County: "",
    Country: "Wales",
  },
  153: {
    Title: "Mr",
    Initials: "MHH",
    Surname: "John",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  154: {
    Title: "Dr",
    Initials: "CI",
    Surname: "Johnston",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "Ireland",
  },
  155: {
    Title: "Mr",
    Initials: "IT",
    Surname: "Jones",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  156: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Kellington",
    "Primary Club": "RCAC Bisley Team",
    County: "Saskatchewan",
    Country: "Canada",
  },
  157: {
    Title: "Mr",
    Initials: "CP",
    Surname: "Kelsey",
    "Primary Club": "Uppingham Veterans RC",
    County: "Yorkshire",
    Country: "England",
  },
  158: {
    Title: "Mr",
    Initials: "JCT",
    Surname: "Kent",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  159: {
    Title: "Mrs",
    Initials: "SV",
    Surname: "Luckman",
    "Primary Club": "Old Epsomian RC",
    County: "Somerset",
    Country: "England",
  },
  160: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Smith",
    "Primary Club": "English VIII",
    County: "Cambridgeshire",
    Country: "England",
  },
  161: {
    Title: "Sgt",
    Initials: "S",
    Surname: "Kerr",
    "Primary Club": "111 (Sunderland) Sqn",
    County: "County Durham",
    Country: "England",
  },
  162: {
    Title: "Cpl",
    Initials: "S",
    Surname: "Kingdom",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "Glamorgan",
    Country: "Wales",
  },
  163: {
    Title: "",
    Initials: "JA",
    Surname: "Kirby",
    "Primary Club": "W.R.A",
    County: "Glamorgan",
    Country: "Wales",
  },
  164: {
    Title: "",
    Initials: "DAH",
    Surname: "Klingebiel",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  165: {
    Title: "",
    Initials: "JH",
    Surname: "Klopper",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  166: {
    Title: "Cdt",
    Initials: "PM",
    Surname: "Koronka",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  167: {
    Title: "Cpl",
    Initials: "IK",
    Surname: "Laine",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  168: {
    Title: "",
    Initials: "IR",
    Surname: "Larsen",
    "Primary Club": "Hamilton",
    County: "",
    Country: "New Zealand",
  },
  169: {
    Title: "",
    Initials: "CW",
    Surname: "Lauterbach",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  170: {
    Title: "",
    Initials: "CH",
    Surname: "Lawson",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "Wales",
  },
  171: {
    Title: "Cdt",
    Initials: "JF",
    Surname: "Lawson",
    "Primary Club": "Athelings",
    County: "Yorkshire",
    Country: "England",
  },
  172: {
    Title: "Cdt",
    Initials: "PD",
    Surname: "Lawson",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  173: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Leech",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  174: {
    Title: "",
    Initials: "RW",
    Surname: "LePrevost",
    "Primary Club": "Guernsey R.C",
    County: "",
    Country: "Channel Islands",
  },
  175: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Linehan",
    "Primary Club": "RCAC Bisley Team",
    County: "Newfoundland",
    Country: "Canada",
  },
  176: {
    Title: "",
    Initials: "DB",
    Surname: "Lubbe",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  177: {
    Title: "",
    Initials: "D",
    Surname: "Lynagh",
    "Primary Club": "Heriot-Watt URC",
    County: "Ulster",
    Country: "Ireland",
  },
  178: {
    Title: "",
    Initials: "BW",
    Surname: "MacDonald",
    "Primary Club": "",
    County: "Manitoba",
    Country: "Canada",
  },
  179: {
    Title: "C/c",
    Initials: "P",
    Surname: "Mandaron",
    "Primary Club": "U.N.C",
    County: "",
    Country: "France",
  },
  180: {
    Title: "",
    Initials: "MG",
    Surname: "Marchetti",
    "Primary Club": "St Andrews Uni. R.C",
    County: "Kent",
    Country: "Scotland",
  },
  181: {
    Title: "Sgt",
    Initials: "RJ",
    Surname: "Marshall",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  182: {
    Title: "",
    Initials: "CDA",
    Surname: "Mattey",
    "Primary Club": "OURC",
    County: "Surrey",
    Country: "England",
  },
  183: {
    Title: "",
    Initials: "TA",
    Surname: "McCallum",
    "Primary Club": "Falkland Islands R.A.",
    County: "",
    Country: "Falkland Islands",
  },
  184: {
    Title: "Ms",
    Initials: "SR",
    Surname: "McCullough",
    "Primary Club": "URA",
    County: "Ulster",
    Country: "N. Ireland",
  },
  185: {
    Title: "",
    Initials: "FE",
    Surname: "McDermott",
    "Primary Club": "Mosman-Neutral Bay R.C",
    County: "",
    Country: "Australia",
  },
  186: {
    Title: "Mr",
    Initials: "CN",
    Surname: "McEachran",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  187: {
    Title: "Miss",
    Initials: "J",
    Surname: "McEachran",
    "Primary Club": "East of Scotland R.C",
    County: "East of Scotland",
    Country: "Scotland",
  },
  188: {
    Title: "Mrs",
    Initials: "VR",
    Surname: "Measures",
    "Primary Club": "Old Oakhamians RC",
    County: "Lincolnshire",
    Country: "England",
  },
  189: {
    Title: "CdtSgt",
    Initials: "FLS",
    Surname: "Medlycott",
    "Primary Club": "Uppingham Veterans RC",
    County: "Yorkshire",
    Country: "",
  },
  190: {
    Title: "Miss",
    Initials: "JH",
    Surname: "Messer",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  191: {
    Title: "Cpl",
    Initials: "T",
    Surname: "Metcalf",
    "Primary Club": "A.T.R.C.",
    County: "Ulster",
    Country: "Ireland",
  },
  192: {
    Title: "Mr",
    Initials: "AG",
    Surname: "Mitchell",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "Ireland",
  },
  193: {
    Title: "",
    Initials: "AP",
    Surname: "Moller",
    "Primary Club": "South Africa",
    County: "Gauteng North",
    Country: "South Africa",
  },
  194: {
    Title: "L Col",
    Initials: "WJ",
    Surname: "Molnar",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  195: {
    Title: "",
    Initials: "RJ",
    Surname: "Moncur",
    "Primary Club": "Old Wellingtonians",
    County: "Northumberland",
    Country: "England",
  },
  196: {
    Title: "Ms",
    Initials: "BM",
    Surname: "Moore",
    "Primary Club": "West Suffolk RC",
    County: "Suffolk",
    Country: "England",
  },
  197: {
    Title: "Cdt",
    Initials: "JB",
    Surname: "Moreton",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "England",
  },
  198: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Morgan",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  199: {
    Title: "",
    Initials: "SEN",
    Surname: "Mortenson",
    "Primary Club": "OURC",
    County: "Oxfordshire",
    Country: "",
  },
  200: {
    Title: "",
    Initials: "G",
    Surname: "Mortimer",
    "Primary Club": "W.R.A",
    County: "Oxfordshire",
    Country: "Wales",
  },
  201: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Morton",
    "Primary Club": "RAFTRC",
    County: "Hampshire",
    Country: "England",
  },
  202: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Morton",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "",
  },
  203: {
    Title: "",
    Initials: "P",
    Surname: "Mowat",
    "Primary Club": "R.A.F.T.R.C",
    County: "",
    Country: "Scotland",
  },
  204: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Murray",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  205: {
    Title: "Mr",
    Initials: "HDC",
    Surname: "Murray",
    "Primary Club": "Dursley",
    County: "Gloucestershire",
    Country: "",
  },
  207: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Napier",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  208: {
    Title: "WO2",
    Initials: "KP",
    Surname: "Nixon",
    "Primary Club": "A.T.R.C.",
    County: "",
    Country: "",
  },
  209: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Norman",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  210: {
    Title: "",
    Initials: "AT",
    Surname: "Nussey",
    "Primary Club": "East of Scotland R.C",
    County: "",
    Country: "Scotland",
  },
  211: {
    Title: "",
    Initials: "J",
    Surname: "Orpen-Smellie",
    "Primary Club": "Porthcawl RC",
    County: "",
    Country: "Wales",
  },
  212: {
    Title: "Cdt",
    Initials: "DWS",
    Surname: "Osborne",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "",
  },
  213: {
    Title: "",
    Initials: "GD",
    Surname: "Paetkau",
    "Primary Club": "Canada",
    County: "Manitoba",
    Country: "Canada",
  },
  214: {
    Title: "",
    Initials: "CD",
    Surname: "Pask",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "England",
  },
  215: {
    Title: "Miss",
    Initials: "EJ",
    Surname: "Paton",
    "Primary Club": "Nottingham University",
    County: "",
    Country: "",
  },
  216: {
    Title: "Cpl",
    Initials: "I",
    Surname: "Patton",
    "Primary Club": "A.T.R.C.",
    County: "Ulster",
    Country: "Ireland",
  },
  217: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Pears",
    "Primary Club": "CURA",
    County: "Yorkshire",
    Country: "England",
  },
  218: {
    Title: "Mr",
    Initials: "S",
    Surname: "Pearson",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  219: {
    Title: "Cpl",
    Initials: "PI",
    Surname: "Perry",
    "Primary Club": "RAF Air Cmd",
    County: "Oxfordshire",
    Country: "England",
  },
  220: {
    Title: "",
    Initials: "RA",
    Surname: "Pitcairn",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  221: {
    Title: "",
    Initials: "RL",
    Surname: "Pitcher",
    "Primary Club": "Jersey R.C",
    County: "",
    Country: "Jersey",
  },
  222: {
    Title: "",
    Initials: "RT",
    Surname: "Pollock",
    "Primary Club": "'89 Athelings",
    County: "",
    Country: "England",
  },
  223: {
    Title: "",
    Initials: "CGH",
    Surname: "Pope",
    "Primary Club": "Wandsworth F.B.R.C",
    County: "Surrey",
    Country: "England",
  },
  224: {
    Title: "",
    Initials: "SR",
    Surname: "Pope",
    "Primary Club": "Nottingham University",
    County: "Sussex",
    Country: "",
  },
  225: {
    Title: "Mr",
    Initials: "D",
    Surname: "Pote",
    "Primary Club": "WBSURC",
    County: "Somerset",
    Country: "United Kingdom",
  },
  226: {
    Title: "Cdt",
    Initials: "GF",
    Surname: "Pott",
    "Primary Club": "Gresham's R.C",
    County: "Suffolk",
    Country: "England",
  },
  227: {
    Title: "Mr",
    Initials: "JL",
    Surname: "Poulin",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  228: {
    Title: "Mr",
    Initials: "A F",
    Surname: "Powell",
    "Primary Club": "Katandra RC",
    County: "",
    Country: "Australia",
  },
  229: {
    Title: "Mrs",
    Initials: "UA",
    Surname: "Powell",
    "Primary Club": "Sussex CRA",
    County: "Kent",
    Country: "England",
  },
  230: {
    Title: "",
    Initials: "AJ",
    Surname: "Purvey",
    "Primary Club": "Bath University R.C",
    County: "Somerset",
    Country: "England",
  },
  231: {
    Title: "",
    Initials: "MTD",
    Surname: "Read",
    "Primary Club": "Old Epsomians",
    County: "",
    Country: "",
  },
  232: {
    Title: "Mr",
    Initials: "CRV",
    Surname: "Reed",
    "Primary Club": "Harwell TSC",
    County: "",
    Country: "England",
  },
  233: {
    Title: "",
    Initials: "GO",
    Surname: "Rich",
    "Primary Club": "Guernsey R.C",
    County: "",
    Country: "Guernsey",
  },
  234: {
    Title: "Mrs",
    Initials: "KG",
    Surname: "Richards",
    "Primary Club": "Hampshire",
    County: "Hampshire",
    Country: "England",
  },
  235: {
    Title: "Lt Col",
    Initials: "RHG",
    Surname: "Riley",
    "Primary Club": "ATSC",
    County: "Bedfordshire",
    Country: "England",
  },
  236: {
    Title: "Mr",
    Initials: "MH",
    Surname: "Roberts",
    "Primary Club": "ATSC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  237: {
    Title: "",
    Initials: "G",
    Surname: "Robinson",
    "Primary Club": "N.L.R.C.",
    County: "Norfolk",
    Country: "England",
  },
  238: {
    Title: "",
    Initials: "S",
    Surname: "Romose",
    "Primary Club": "Old Wellingburian",
    County: "",
    Country: "",
  },
  239: {
    Title: "",
    Initials: "R",
    Surname: "Romses",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  240: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Rose",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  241: {
    Title: "Mr",
    Initials: "PCW",
    Surname: "Rose",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  242: {
    Title: "Dr",
    Initials: "SL",
    Surname: "Rosling",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "",
  },
  243: {
    Title: "Cdt",
    Initials: "CJ",
    Surname: "Rowe",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  244: {
    Title: "Mr",
    Initials: "TC",
    Surname: "Rowntree",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  245: {
    Title: "Mr",
    Initials: "H",
    Surname: "Rumbelow",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  246: {
    Title: "",
    Initials: "MG",
    Surname: "Rushton",
    "Primary Club": "LMRA",
    County: "Cambridgeshire",
    Country: "England",
  },
  247: {
    Title: "",
    Initials: "CS",
    Surname: "Salva",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  248: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Sandlant",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  249: {
    Title: "Miss",
    Initials: "AV",
    Surname: "Scattergood",
    "Primary Club": "Uppingham Veterans RC",
    County: "Essex",
    Country: "England",
  },
  250: {
    Title: "",
    Initials: "T",
    Surname: "Schioler",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "",
  },
  251: {
    Title: "Mr",
    Initials: "WS",
    Surname: "Scholze",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  252: {
    Title: "",
    Initials: "RM",
    Surname: "Sewrey",
    "Primary Club": "St Andrews Uni. R.C",
    County: "Dorset",
    Country: "England",
  },
  253: {
    Title: "Mr",
    Initials: "MH",
    Surname: "Shaw",
    "Primary Club": "Langar RC",
    County: "",
    Country: "England",
  },
  254: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Sheill",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  255: {
    Title: "Miss",
    Initials: "P",
    Surname: "Shori",
    "Primary Club": "Ulster RC",
    County: "",
    Country: "Ireland",
  },
  256: {
    Title: "Mr",
    Initials: "RSF",
    Surname: "Shouler",
    "Primary Club": "Surrey RA",
    County: "Nottinghamshire",
    Country: "England",
  },
  257: {
    Title: "Mr",
    Initials: "MW",
    Surname: "Silver",
    "Primary Club": "RAF Air Cmd",
    County: "Oxfordshire",
    Country: "England",
  },
  258: {
    Title: "Cdt",
    Initials: "MC",
    Surname: "Silvester",
    "Primary Club": "St. Johns School",
    County: "Surrey",
    Country: "England",
  },
  259: {
    Title: "",
    Initials: "NP",
    Surname: "Silvester",
    "Primary Club": "NUOTC",
    County: "",
    Country: "",
  },
  260: {
    Title: "",
    Initials: "PS",
    Surname: "Simon",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  261: {
    Title: "Miss",
    Initials: "M",
    Surname: "Smekal",
    "Primary Club": "Edinburgh University R.C",
    County: "Cambridgeshire",
    Country: "England",
  },
  262: {
    Title: "",
    Initials: "DR",
    Surname: "Smith",
    "Primary Club": "Oxford University R.C",
    County: "Hertfordshire",
    Country: "England",
  },
  263: {
    Title: "",
    Initials: "J",
    Surname: "Smith",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  264: {
    Title: "Mr",
    Initials: "RM",
    Surname: "Smith",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  265: {
    Title: "",
    Initials: "SL",
    Surname: "Smith",
    "Primary Club": "Langar",
    County: "Nottinghamshire",
    Country: "England",
  },
  266: {
    Title: "",
    Initials: "RW",
    Surname: "Soden",
    "Primary Club": "OURC",
    County: "",
    Country: "",
  },
  267: {
    Title: "",
    Initials: "WM",
    Surname: "Sryman",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  268: {
    Title: "",
    Initials: "NM",
    Surname: "Stanwix",
    "Primary Club": "A.T.S.C",
    County: "County Durham",
    Country: "England",
  },
  269: {
    Title: "PI",
    Initials: "E",
    Surname: "Stephenson",
    "Primary Club": "Derbyshire RA",
    County: "Derbyshire",
    Country: "England",
  },
  270: {
    Title: "Mr",
    Initials: "WR",
    Surname: "Steward",
    "Primary Club": "Old Epsomian RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  271: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Stewart",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  272: {
    Title: "Mr",
    Initials: "CAS",
    Surname: "Stewart",
    "Primary Club": "NLRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  273: {
    Title: "Cdt",
    Initials: "BM",
    Surname: "Stoate",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "England",
  },
  274: {
    Title: "Cdt",
    Initials: "V",
    Surname: "Stobbart",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  275: {
    Title: "Mr",
    Initials: "IE",
    Surname: "Stolk",
    "Primary Club": "KSV Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  276: {
    Title: "",
    Initials: "KRM",
    Surname: "Swain",
    "Primary Club": "Cheltenham College C.C.F",
    County: "Worcestershire",
    Country: "United Kingdom",
  },
  277: {
    Title: "",
    Initials: "JP",
    Surname: "Sykes",
    "Primary Club": "Bedfordians",
    County: "Cambridgeshire",
    Country: "England",
  },
  278: {
    Title: "Mr",
    Initials: "PD",
    Surname: "Sykes",
    "Primary Club": "Bedfordians RC",
    County: "Sussex",
    Country: "England",
  },
  279: {
    Title: "",
    Initials: "MA",
    Surname: "Szymankiewics",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  280: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Tayler",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  281: {
    Title: "",
    Initials: "B",
    Surname: "Thomas",
    "Primary Club": "Trinidad R.C",
    County: "",
    Country: "Trinidad",
  },
  282: {
    Title: "Dr",
    Initials: "JC",
    Surname: "Thompson",
    "Primary Club": "GWDWPAMU",
    County: "",
    Country: "Canada",
  },
  283: {
    Title: "",
    Initials: "M",
    Surname: "Tibaldi",
    "Primary Club": "OERC",
    County: "",
    Country: "",
  },
  284: {
    Title: "Mr",
    Initials: "GB",
    Surname: "Tompson",
    "Primary Club": "Old Berkhamstedians",
    County: "Hertfordshire",
    Country: "England",
  },
  285: {
    Title: "Cdt",
    Initials: "CT",
    Surname: "Treadwell",
    "Primary Club": "Wellington College",
    County: "Surrey",
    Country: "England",
  },
  286: {
    Title: "Cpl",
    Initials: "D",
    Surname: "Tuck",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "Glamorgan",
    Country: "Wales",
  },
  287: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Tuck",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "Glamorgan",
    Country: "Wales",
  },
  288: {
    Title: "Cdt",
    Initials: "ASCS",
    Surname: "Turner",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Great Britain",
  },
  289: {
    Title: "Capt",
    Initials: "WG",
    Surname: "Turner",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  290: {
    Title: "",
    Initials: "CD",
    Surname: "Turney",
    "Primary Club": "",
    County: "",
    Country: "Canada",
  },
  291: {
    Title: "",
    Initials: "JF",
    Surname: "Ufferte",
    "Primary Club": "U.N.C",
    County: "",
    Country: "France",
  },
  292: {
    Title: "Mr",
    Initials: "RAH",
    Surname: "Vary",
    "Primary Club": "CURA",
    County: "Berkshire",
    Country: "England",
  },
  293: {
    Title: "Mrs",
    Initials: "EM",
    Surname: "Verduyn",
    "Primary Club": "Welwyn Phoenix",
    County: "Hertfordshire",
    Country: "England",
  },
  294: {
    Title: "Cdt",
    Initials: "NDD",
    Surname: "Vigors",
    "Primary Club": "Athelings",
    County: "Wiltshire",
    Country: "England",
  },
  295: {
    Title: "Miss",
    Initials: "M",
    Surname: "Visser",
    "Primary Club": "South African U25",
    County: "Gauteng North",
    Country: "South Africa",
  },
  296: {
    Title: "",
    Initials: "ST",
    Surname: "Walmsley",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  297: {
    Title: "SSgt",
    Initials: "KS",
    Surname: "Walsh",
    "Primary Club": "",
    County: "Ulster",
    Country: "Ireland",
  },
  298: {
    Title: "Plt Off",
    Initials: "PHJC",
    Surname: "Ward",
    "Primary Club": "JEFTS",
    County: "Glamorgan",
    Country: "Wales",
  },
  299: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Watson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Northants - Leics - Rutland",
    Country: "Wales",
  },
  300: {
    Title: "",
    Initials: "E",
    Surname: "Weigand",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  301: {
    Title: "",
    Initials: "SJ",
    Surname: "West",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "",
  },
  302: {
    Title: "Dr",
    Initials: "PR",
    Surname: "Wheeler",
    "Primary Club": "Old Guildfordians RC",
    County: "Hampshire",
    Country: "England",
  },
  303: {
    Title: "",
    Initials: "WS",
    Surname: "Wilkinson",
    "Primary Club": "Sheffield RC",
    County: "Derbyshire",
    Country: "England",
  },
  304: {
    Title: "",
    Initials: "ME",
    Surname: "Willan",
    "Primary Club": "Edinburgh University R.C",
    County: "Sussex",
    Country: "England",
  },
  305: {
    Title: "",
    Initials: "DP",
    Surname: "Williams",
    "Primary Club": "W.R.A",
    County: "S Glamorgan",
    Country: "Wales",
  },
  306: {
    Title: "Mr",
    Initials: "S",
    Surname: "Williams",
    "Primary Club": "ACRHQ (W&W)",
    County: "Glamorgan",
    Country: "Wales",
  },
  307: {
    Title: "Cdt",
    Initials: "AE",
    Surname: "Wilson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  308: {
    Title: "Lt",
    Initials: "L",
    Surname: "Wilson",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  309: {
    Title: "",
    Initials: "LTL",
    Surname: "Winkworth",
    "Primary Club": "Old Wellingtonians",
    County: "Surrey",
    Country: "England",
  },
  310: {
    Title: "Dr",
    Initials: "BJ",
    Surname: "Winney",
    "Primary Club": "Old Berkhamstedians",
    County: "Hertfordshire",
    Country: "England",
  },
  311: {
    Title: "",
    Initials: "DR",
    Surname: "Winney",
    "Primary Club": "Old Haberdashers",
    County: "Hertfordshire",
    Country: "England",
  },
  312: {
    Title: "",
    Initials: "GCY",
    Surname: "Wong",
    "Primary Club": "N.L.R.C.",
    County: "Berkshire",
    Country: "England",
  },
  313: {
    Title: "Capt",
    Initials: "HJ",
    Surname: "Woods",
    "Primary Club": "A.T.R.C.",
    County: "Buckinghamshire",
    Country: "England",
  },
  314: {
    Title: "",
    Initials: "RMB",
    Surname: "Young",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "Wales",
  },
  315: {
    Title: "Lt Col",
    Initials: "CR",
    Surname: "Claridge",
    "Primary Club": "ATSC",
    County: "Sussex",
    Country: "England",
  },
  316: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Chance",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  317: {
    Title: "Mr",
    Initials: "MJJ",
    Surname: "Charlton",
    "Primary Club": "Old Guildfordians RC",
    County: "Northants - Leics - Rutland",
    Country: "Scotland",
  },
  318: {
    Title: "",
    Initials: "JG",
    Surname: "Collins",
    "Primary Club": "N.L.R.C.",
    County: "County of London",
    Country: "Ireland",
  },
  319: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Curtis",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  320: {
    Title: "Mrs",
    Initials: "C",
    Surname: "Critchley",
    "Primary Club": "",
    County: "Buckinghamshire",
    Country: "",
  },
  321: {
    Title: "",
    Initials: "J",
    Surname: "Carswell",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "Scotland",
  },
  322: {
    Title: "Mr",
    Initials: "JRS",
    Surname: "Cantlay",
    "Primary Club": "Harare RC",
    County: "Mashonaland",
    Country: "Zimbabwe",
  },
  323: {
    Title: "Mr",
    Initials: "GK",
    Surname: "Alexander",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  324: {
    Title: "",
    Initials: "O",
    Surname: "Catchpole",
    "Primary Club": "Comber R.C",
    County: "Ulster",
    Country: "Ireland",
  },
  325: {
    Title: "Mrs",
    Initials: "KD",
    Surname: "Robertson",
    "Primary Club": "",
    County: "Oxfordshire",
    Country: "Ireland",
  },
  326: {
    Title: "Maj",
    Initials: "J",
    Surname: "Cload",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  327: {
    Title: "Mr",
    Initials: "GS",
    Surname: "Cotton",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  328: {
    Title: "Mr",
    Initials: "PMG",
    Surname: "Campbell",
    "Primary Club": "Irish Guards",
    County: "",
    Country: "Ireland",
  },
  329: {
    Title: "",
    Initials: "DJ",
    Surname: "Daglish",
    "Primary Club": "City of Newcastle R.C",
    County: "Northumberland",
    Country: "England",
  },
  330: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Dallas",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  331: {
    Title: "",
    Initials: "PE",
    Surname: "Daniell",
    "Primary Club": "R.N.T.R.C",
    County: "Hampshire",
    Country: "England",
  },
  332: {
    Title: "Mr",
    Initials: "D",
    Surname: "Dashwood",
    "Primary Club": "Harrovian RA",
    County: "",
    Country: "England",
  },
  333: {
    Title: "Mr",
    Initials: "D",
    Surname: "Davies",
    "Primary Club": "Lloyds TSBRC",
    County: "Sussex",
    Country: "Wales",
  },
  334: {
    Title: "",
    Initials: "JG",
    Surname: "Davies",
    "Primary Club": "City RC",
    County: "Hertfordshire",
    Country: "England",
  },
  335: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Dare",
    "Primary Club": "Tetbury",
    County: "Wiltshire",
    Country: "England",
  },
  336: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Davis",
    "Primary Club": "ATSC",
    County: "Buckinghamshire",
    Country: "Scotland",
  },
  337: {
    Title: "Mr",
    Initials: "APW",
    Surname: "Daw",
    "Primary Club": "North Herts Rifles",
    County: "Hertfordshire",
    Country: "England",
  },
  338: {
    Title: "Mr",
    Initials: "P",
    Surname: "Dawes",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "England",
  },
  339: {
    Title: "Mr",
    Initials: "KJ",
    Surname: "Day",
    "Primary Club": "LMRA",
    County: "Suffolk",
    Country: "England",
  },
  340: {
    Title: "Dr",
    Initials: "JR",
    Surname: "Deane",
    "Primary Club": "Dursley",
    County: "Gloucestershire",
    Country: "England",
  },
  341: {
    Title: "Mr",
    Initials: "AJCB",
    Surname: "de Launay",
    "Primary Club": "OMRC",
    County: "",
    Country: "England",
  },
  342: {
    Title: "Mr",
    Initials: "CES",
    Surname: "Dickenson",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  343: {
    Title: "Mr",
    Initials: "ND",
    Surname: "Anderson",
    "Primary Club": "NLRC",
    County: "Wiltshire",
    Country: "Ireland",
  },
  344: {
    Title: "Mr",
    Initials: "SCW",
    Surname: "Dixon",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  345: {
    Title: "",
    Initials: "PM",
    Surname: "Dodd",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  346: {
    Title: "Mr",
    Initials: "AF",
    Surname: "Donald",
    "Primary Club": "Wandsworth FBRC",
    County: "London",
    Country: "England",
  },
  347: {
    Title: "Lt Cdr",
    Initials: "PW",
    Surname: "Drummy",
    "Primary Club": "City RC",
    County: "",
    Country: "Guernsey",
  },
  348: {
    Title: "Mr",
    Initials: "WJG",
    Surname: "Dixon",
    "Primary Club": "NLRC",
    County: "County of London",
    Country: "England",
  },
  349: {
    Title: "Mr",
    Initials: "J",
    Surname: "Dunn",
    "Primary Club": "ATRC",
    County: "Berkshire",
    Country: "Wales",
  },
  350: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Dunn",
    "Primary Club": "SLRC",
    County: "Berkshire",
    Country: "England",
  },
  351: {
    Title: "",
    Initials: "RA",
    Surname: "Davison",
    "Primary Club": "N.R.A",
    County: "Sussex",
    Country: "England",
  },
  352: {
    Title: "Mrs",
    Initials: "HL",
    Surname: "Webb",
    "Primary Club": "Lloyds TSBRC",
    County: "Suffolk",
    Country: "England",
  },
  353: {
    Title: "Mr",
    Initials: "SH",
    Surname: "Dickson",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  354: {
    Title: "Mr",
    Initials: "J",
    Surname: "Howard Davies",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  355: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Dix",
    "Primary Club": "Old Guildfordians RC",
    County: "Somerset",
    Country: "England",
  },
  356: {
    Title: "Mr",
    Initials: "SL",
    Surname: "Dampney",
    "Primary Club": "Lloyds TSBRC",
    County: "Somerset",
    Country: "England",
  },
  357: {
    Title: "",
    Initials: "JA",
    Surname: "Davies",
    "Primary Club": "LMRA",
    County: "Hampshire",
    Country: "",
  },
  358: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Dyson",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  359: {
    Title: "Mr",
    Initials: "IJ",
    Surname: "Donaldson",
    "Primary Club": "Guernsey Rifle Club",
    County: "Guernsey",
    Country: "Guernsey",
  },
  360: {
    Title: "Mr",
    Initials: "WHC",
    Surname: "Alexander",
    "Primary Club": "Parthian",
    County: "Oxfordshire",
    Country: "Scotland",
  },
  361: {
    Title: "",
    Initials: "H",
    Surname: "Duch",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  362: {
    Title: "Mrs",
    Initials: "CJ",
    Surname: "Clifford Evans",
    "Primary Club": "Hogleas",
    County: "Norfolk",
    Country: "England",
  },
  363: {
    Title: "Mr",
    Initials: "I",
    Surname: "Davison",
    "Primary Club": "Dorchester",
    County: "Yorkshire",
    Country: "England",
  },
  364: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Ebbetts",
    "Primary Club": "Bedfordians RC",
    County: "Berkshire",
    Country: "England",
  },
  365: {
    Title: "Cdr",
    Initials: "RH",
    Surname: "Edwardes-Evans",
    "Primary Club": "WNSC",
    County: "Gloucestershire",
    Country: "Wales",
  },
  366: {
    Title: "Mr",
    Initials: "PR",
    Surname: "Edwards",
    "Primary Club": "Rainham & District",
    County: "Essex",
    Country: "England",
  },
  367: {
    Title: "Mr",
    Initials: "RCP",
    Surname: "Elgood",
    "Primary Club": "Kent CRA",
    County: "Kent",
    Country: "England",
  },
  368: {
    Title: "Mr",
    Initials: "RE",
    Surname: "Ellis",
    "Primary Club": "Surrey RA",
    County: "Bedfordshire",
    Country: "England",
  },
  369: {
    Title: "",
    Initials: "AR",
    Surname: "Emmerson",
    "Primary Club": "Huddersfield R.C",
    County: "Yorkshire",
    Country: "England",
  },
  370: {
    Title: "Major",
    Initials: "JJ",
    Surname: "Earle",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "Ireland",
  },
  371: {
    Title: "",
    Initials: "TJ",
    Surname: "Elliott",
    "Primary Club": "Bedfordians",
    County: "Berkshire",
    Country: "England",
  },
  372: {
    Title: "",
    Initials: "JA",
    Surname: "Elliot-Smith",
    "Primary Club": "Ricochet R.C",
    County: "",
    Country: "",
  },
  373: {
    Title: "Mr",
    Initials: "N",
    Surname: "Edwards",
    "Primary Club": "27th London Putney RC",
    County: "County of London",
    Country: "England",
  },
  374: {
    Title: "",
    Initials: "CR",
    Surname: "Emmerson",
    "Primary Club": "RAFTRC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  375: {
    Title: "Mr",
    Initials: "RM",
    Surname: "Allan",
    "Primary Club": "NLRC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  376: {
    Title: "Mr",
    Initials: "ES",
    Surname: "Eilley",
    "Primary Club": "Old Cranleighians",
    County: "",
    Country: "",
  },
  377: {
    Title: "Mrs",
    Initials: "JR",
    Surname: "Elcombe",
    "Primary Club": "NLRC",
    County: "Essex",
    Country: "Wales",
  },
  378: {
    Title: "Mr",
    Initials: "SC",
    Surname: "Ennion",
    "Primary Club": "Warwickshire Firearms",
    County: "",
    Country: "England",
  },
  379: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "East",
    "Primary Club": "RAFTRC",
    County: "Berkshire",
    Country: "England",
  },
  380: {
    Title: "Mr",
    Initials: "RP",
    Surname: "Elvins",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "Scotland",
  },
  381: {
    Title: "Mr",
    Initials: "AD",
    Surname: "Eldridge",
    "Primary Club": "Old Guildfordians",
    County: "Surrey",
    Country: "England",
  },
  382: {
    Title: "",
    Initials: "M",
    Surname: "Eustace",
    "Primary Club": "Old Guildfordians R.C",
    County: "Surrey",
    Country: "England",
  },
  383: {
    Title: "Mr",
    Initials: "KG",
    Surname: "Firth",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  384: {
    Title: "",
    Initials: "DC",
    Surname: "Fishleigh",
    "Primary Club": "N.L.R.C.",
    County: "Berkshire",
    Country: "England",
  },
  385: {
    Title: "Mr",
    Initials: "A",
    Surname: "Fitch",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  386: {
    Title: "Mr",
    Initials: "C",
    Surname: "Fitzpatrick",
    "Primary Club": "RAFTRC",
    County: "Oxfordshire",
    Country: "Ireland",
  },
  387: {
    Title: "Miss",
    Initials: "TJ",
    Surname: "Fitzsimons",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  388: {
    Title: "",
    Initials: "WN",
    Surname: "Fitzsimons",
    "Primary Club": "Manchester R.C",
    County: "Cheshire",
    Country: "England",
  },
  389: {
    Title: "",
    Initials: "BT",
    Surname: "Ford",
    "Primary Club": "Leeds Service R.C",
    County: "Yorkshire",
    Country: "England",
  },
  390: {
    Title: "Mr",
    Initials: "BAI",
    Surname: "Fraser",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "United Kingdom",
  },
  391: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Friend",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  392: {
    Title: "Mr",
    Initials: "NAC",
    Surname: "Fawcett",
    "Primary Club": "SLRC",
    County: "Devon",
    Country: "England",
  },
  393: {
    Title: "",
    Initials: "G",
    Surname: "Fudger",
    "Primary Club": "Manydown Rifle Club",
    County: "Hampshire",
    Country: "England",
  },
  394: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Fugeman",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  395: {
    Title: "Mr",
    Initials: "KL",
    Surname: "Farmer",
    "Primary Club": "Leatherhead RC",
    County: "Surrey",
    Country: "England",
  },
  396: {
    Title: "Dr",
    Initials: "NCM",
    Surname: "Fyfe",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  397: {
    Title: "",
    Initials: "JJ",
    Surname: "Felton",
    "Primary Club": "West Wales ML",
    County: "Glamorgan",
    Country: "Wales",
  },
  398: {
    Title: "Miss",
    Initials: "JM",
    Surname: "Felstead",
    "Primary Club": "S.E.R.C.",
    County: "Wiltshire",
    Country: "",
  },
  399: {
    Title: "",
    Initials: "AD",
    Surname: "Fitzpatrick",
    "Primary Club": "Watford O.C.A.",
    County: "Hertfordshire",
    Country: "England",
  },
  400: {
    Title: "",
    Initials: "RL",
    Surname: "Field",
    "Primary Club": "Wandsworth F.B.R.C",
    County: "Surrey",
    Country: "England",
  },
  401: {
    Title: "Mr",
    Initials: "BP",
    Surname: "Adams",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  402: {
    Title: "Mrs",
    Initials: "JC",
    Surname: "Scott Langley",
    "Primary Club": "LMRA",
    County: "Hertfordshire",
    Country: "Ireland",
  },
  403: {
    Title: "Mr",
    Initials: "G",
    Surname: "French",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  404: {
    Title: "Mr",
    Initials: "SE",
    Surname: "Frost",
    "Primary Club": "Canada",
    County: "Wiltshire",
    Country: "Canada",
  },
  405: {
    Title: "Mr",
    Initials: "DH",
    Surname: "Allen",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  406: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Faulkner",
    "Primary Club": "Dungannon",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  407: {
    Title: "",
    Initials: "AH",
    Surname: "Ford",
    "Primary Club": "Falkland Islands R.A.",
    County: "",
    Country: "Falkland Islands",
  },
  408: {
    Title: "Mr",
    Initials: "RH",
    Surname: "Gardiner",
    "Primary Club": "Club 25",
    County: "Berkshire",
    Country: "England",
  },
  409: {
    Title: "",
    Initials: "R",
    Surname: "Garside",
    "Primary Club": "LMRA",
    County: "Wiltshire",
    Country: "England",
  },
  410: {
    Title: "Mrs",
    Initials: "VP",
    Surname: "Garside",
    "Primary Club": "LMRA",
    County: "Wiltshire",
    Country: "England",
  },
  411: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Gass",
    "Primary Club": "City of Newcastle RC",
    County: "Cheshire",
    Country: "England",
  },
  412: {
    Title: "Mr",
    Initials: "JB",
    Surname: "Geach",
    "Primary Club": "Kent CRA",
    County: "Kent",
    Country: "England",
  },
  413: {
    Title: "Mr",
    Initials: "KW",
    Surname: "Genders",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  414: {
    Title: "",
    Initials: "JM",
    Surname: "Gray",
    "Primary Club": "LMRA",
    County: "Norfolk",
    Country: "England",
  },
  415: {
    Title: "",
    Initials: "J",
    Surname: "Gilmour",
    "Primary Club": "Old Glenalmond R.C",
    County: "Hampshire",
    Country: "Scotland",
  },
  416: {
    Title: "Dr",
    Initials: "JO",
    Surname: "Ahrens",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  417: {
    Title: "Mme.",
    Initials: "A",
    Surname: "Ginestet",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  418: {
    Title: "Mr",
    Initials: "P",
    Surname: "Ginestet",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  419: {
    Title: "",
    Initials: "DGW",
    Surname: "Gladwin",
    "Primary Club": "West Atholl",
    County: "Lincolnshire",
    Country: "England",
  },
  420: {
    Title: "",
    Initials: "P",
    Surname: "Glasscock",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  421: {
    Title: "Mr",
    Initials: "CP",
    Surname: "Goad",
    "Primary Club": "Huddersfield RC",
    County: "Lancashire",
    Country: "England",
  },
  422: {
    Title: "Mr",
    Initials: "J",
    Surname: "Gould",
    "Primary Club": "West Suffolk RC",
    County: "Suffolk",
    Country: "England",
  },
  423: {
    Title: "Mr",
    Initials: "KHJ",
    Surname: "Gover",
    "Primary Club": "Scarborough",
    County: "Yorkshire",
    Country: "England",
  },
  424: {
    Title: "",
    Initials: "IM",
    Surname: "Graham",
    "Primary Club": "LMRA",
    County: "Hampshire",
    Country: "",
  },
  425: {
    Title: "Mr",
    Initials: "NC",
    Surname: "Granger",
    "Primary Club": "Farncombe & Godalming RC",
    County: "Surrey",
    Country: "England",
  },
  426: {
    Title: "",
    Initials: "DRS",
    Surname: "George",
    "Primary Club": "N.R.A",
    County: "Lancashire",
    Country: "England",
  },
  427: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Grant",
    "Primary Club": "Bullet Lodge RC",
    County: "Sussex",
    Country: "England",
  },
  428: {
    Title: "",
    Initials: "P",
    Surname: "Gray",
    "Primary Club": "W.R.A",
    County: "West of Scotland",
    Country: "Wales",
  },
  429: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Gray",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  430: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Green",
    "Primary Club": "RAFTRC",
    County: "Somerset",
    Country: "England",
  },
  431: {
    Title: "Mr",
    Initials: "TM",
    Surname: "Green",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  432: {
    Title: "",
    Initials: "RA",
    Surname: "Amy",
    "Primary Club": "Jersey R.A",
    County: "",
    Country: "Jersey",
  },
  433: {
    Title: "Major",
    Initials: "RA",
    Surname: "Greenwood",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  434: {
    Title: "Mr",
    Initials: "PD",
    Surname: "Griggs",
    "Primary Club": "Ibis English",
    County: "Kent",
    Country: "England",
  },
  435: {
    Title: "Mrs",
    Initials: "MF",
    Surname: "Barry",
    "Primary Club": "Welsh RA",
    County: "Norfolk",
    Country: "Wales",
  },
  436: {
    Title: "",
    Initials: "GF",
    Surname: "Gooden",
    "Primary Club": "West Suffolk R.C",
    County: "Suffolk",
    Country: "England",
  },
  437: {
    Title: "Wg Cdr",
    Initials: "MJ",
    Surname: "Gregory",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  438: {
    Title: "Mr",
    Initials: "M",
    Surname: "Goberville",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  439: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Goodger",
    "Primary Club": "Room RC",
    County: "Middlesex",
    Country: "England",
  },
  440: {
    Title: "Mr",
    Initials: "KG",
    Surname: "Garside",
    "Primary Club": "Bunhill Rifle Club",
    County: "County of London",
    Country: "England",
  },
  441: {
    Title: "",
    Initials: "G",
    Surname: "Glasscock",
    "Primary Club": "Pumas R.C",
    County: "Hertfordshire",
    Country: "England",
  },
  442: {
    Title: "",
    Initials: "CD",
    Surname: "Golland",
    "Primary Club": "Freelancers R.C",
    County: "Nottinghamshire",
    Country: "England",
  },
  443: {
    Title: "Mrs",
    Initials: "LC",
    Surname: "Golland",
    "Primary Club": "Freelancers R.C",
    County: "Nottinghamshire",
    Country: "England",
  },
  444: {
    Title: "Mr",
    Initials: "RR",
    Surname: "Grisenthwaite",
    "Primary Club": "NLRC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  445: {
    Title: "Mr",
    Initials: "G",
    Surname: "Atkinson",
    "Primary Club": "Old Guildfordians RC",
    County: "London",
    Country: "England",
  },
  446: {
    Title: "Mr",
    Initials: "T",
    Surname: "Galliers",
    "Primary Club": "Welsh RA",
    County: "Devon",
    Country: "Wales",
  },
  447: {
    Title: "Mr",
    Initials: "ND",
    Surname: "Gomez",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "West Indies",
  },
  448: {
    Title: "Mr",
    Initials: "ADE",
    Surname: "Grounds",
    "Primary Club": "Uppingham Veterans RC",
    County: "",
    Country: "England",
  },
  449: {
    Title: "",
    Initials: "A",
    Surname: "Nys",
    "Primary Club": "LMRA",
    County: "Cambridgeshire",
    Country: "England",
  },
  450: {
    Title: "",
    Initials: "MJ",
    Surname: "Le Gallais",
    "Primary Club": "Jersey R.C",
    County: "Jersey",
    Country: "",
  },
  451: {
    Title: "Mrs",
    Initials: "M",
    Surname: "Pantaleon-Ginestet",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "",
  },
  452: {
    Title: "",
    Initials: "HM",
    Surname: "Gray",
    "Primary Club": "Bullet Lodge RC",
    County: "Hampshire",
    Country: "",
  },
  453: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Hakim",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  454: {
    Title: "Mr",
    Initials: "PE",
    Surname: "Hammond",
    "Primary Club": "LMRA",
    County: "Hertfordshire",
    Country: "England",
  },
  455: {
    Title: "Mr",
    Initials: "WR",
    Surname: "Hinton",
    "Primary Club": "Old Haberdashers",
    County: "Buckinghamshire",
    Country: "Wales",
  },
  456: {
    Title: "Mrs",
    Initials: "SM",
    Surname: "Hind",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  457: {
    Title: "Mr",
    Initials: "G",
    Surname: "Harris",
    "Primary Club": "Jersey RA",
    County: "",
    Country: "England",
  },
  458: {
    Title: "Mr",
    Initials: "AG",
    Surname: "Harrison",
    "Primary Club": "LMRA",
    County: "Middlesex",
    Country: "England",
  },
  459: {
    Title: "Mr",
    Initials: "EPJ",
    Surname: "Harrison",
    "Primary Club": "City RC",
    County: "Sussex",
    Country: "England",
  },
  460: {
    Title: "Mr",
    Initials: "TW",
    Surname: "Harrison",
    "Primary Club": "LMRA",
    County: "Surrey",
    Country: "England",
  },
  461: {
    Title: "Mr",
    Initials: "FO",
    Surname: "Harriss",
    "Primary Club": "Ibis RC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  462: {
    Title: "Mr",
    Initials: "JMBH",
    Surname: "Buchanan",
    "Primary Club": "West Atholl RC",
    County: "",
    Country: "Scotland",
  },
  463: {
    Title: "Dr",
    Initials: "N",
    Surname: "Hart",
    "Primary Club": "Bedfordians RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  464: {
    Title: "Mr",
    Initials: "JEM",
    Surname: "Hartwell",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "England",
  },
  465: {
    Title: "Mr",
    Initials: "SP",
    Surname: "Hill",
    "Primary Club": "Stock Exchange RC",
    County: "Cornwall",
    Country: "Ireland",
  },
  466: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Burnip",
    "Primary Club": "Old Framlinghamians RC",
    County: "Derbyshire",
    Country: "England",
  },
  467: {
    Title: "Mr",
    Initials: "MJC",
    Surname: "Haszlakiewicz",
    "Primary Club": "OCRA",
    County: "",
    Country: "Scotland",
  },
  468: {
    Title: "Mr",
    Initials: "RM",
    Surname: "Hatcher",
    "Primary Club": "Bedfordians RC",
    County: "Suffolk",
    Country: "England",
  },
  469: {
    Title: "Mr",
    Initials: "R",
    Surname: "Hawley",
    "Primary Club": "Nottingham City RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  470: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Hayden",
    "Primary Club": "NLRC",
    County: "Hertfordshire",
    Country: "England",
  },
  471: {
    Title: "",
    Initials: "DF",
    Surname: "Hazelton",
    "Primary Club": "",
    County: "Kent",
    Country: "England",
  },
  472: {
    Title: "Mr",
    Initials: "CD",
    Surname: "Heales",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  473: {
    Title: "Mr",
    Initials: "RAV",
    Surname: "Hebblethwaite",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  474: {
    Title: "",
    Initials: "GA",
    Surname: "Baker",
    "Primary Club": "RAFTRC",
    County: "County of London",
    Country: "England",
  },
  475: {
    Title: "Mr",
    Initials: "C",
    Surname: "Higginbottom",
    "Primary Club": "Midland Counties RC",
    County: "Warwickshire",
    Country: "England",
  },
  476: {
    Title: "Mr",
    Initials: "KB",
    Surname: "Hill",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  477: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Hilyard",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  478: {
    Title: "Mr",
    Initials: "PN",
    Surname: "Hinchliffe",
    "Primary Club": "Uppingham Veterans RC",
    County: "Cheshire",
    Country: "England",
  },
  479: {
    Title: "Mr",
    Initials: "RE",
    Surname: "Hind",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  480: {
    Title: "Mr",
    Initials: "PH",
    Surname: "Hobson",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  481: {
    Title: "Wg Cdr",
    Initials: "CJ",
    Surname: "Hockley",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  482: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Hodgson",
    "Primary Club": "City RC",
    County: "Surrey",
    Country: "England",
  },
  483: {
    Title: "",
    Initials: "DA",
    Surname: "Hodson",
    "Primary Club": "ATSC",
    County: "Buckinghamshire",
    Country: "England",
  },
  484: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Holden",
    "Primary Club": "LMRA",
    County: "Hertfordshire",
    Country: "England",
  },
  485: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Ball",
    "Primary Club": "Whipps Cross RC",
    County: "Suffolk",
    Country: "England",
  },
  486: {
    Title: "",
    Initials: "ANC",
    Surname: "Holdsworth",
    "Primary Club": "City of Newcastle R.C",
    County: "Yorkshire",
    Country: "England",
  },
  487: {
    Title: "",
    Initials: "JA",
    Surname: "Holmes",
    "Primary Club": "W.R.A",
    County: "Glamorgan",
    Country: "Wales",
  },
  488: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Holmes",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "England",
  },
  489: {
    Title: "",
    Initials: "J",
    Surname: "Holt",
    "Primary Club": "LMRA",
    County: "Nottinghamshire",
    Country: "England",
  },
  490: {
    Title: "Mr",
    Initials: "FG",
    Surname: "Ball",
    "Primary Club": "32nd Essex RC",
    County: "Essex",
    Country: "England",
  },
  491: {
    Title: "",
    Initials: "M",
    Surname: "Hook Sinclair",
    "Primary Club": "KCS Old Boys R.C",
    County: "Sussex",
    Country: "England",
  },
  492: {
    Title: "Dr",
    Initials: "AR",
    Surname: "Horrocks",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  493: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Horton",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "England",
  },
  494: {
    Title: "Mr",
    Initials: "JDI",
    Surname: "Hossack",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "Scotland",
  },
  495: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Ball",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  496: {
    Title: "Mr",
    Initials: "JCB",
    Surname: "Humphrey",
    "Primary Club": "Uppingham Veterans RC",
    County: "Sussex",
    Country: "England",
  },
  497: {
    Title: "Mr",
    Initials: "HL",
    Surname: "Hunter",
    "Primary Club": "NRC of Scotland",
    County: "West of Scotland",
    Country: "Scotland",
  },
  498: {
    Title: "",
    Initials: "BK",
    Surname: "Hyam",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  499: {
    Title: "Mr",
    Initials: "WH",
    Surname: "Hanley",
    "Primary Club": "Ricochet RC",
    County: "Wiltshire",
    Country: "England",
  },
  500: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Haggerty",
    "Primary Club": "Wandsworth FBRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  501: {
    Title: "Mr",
    Initials: "T",
    Surname: "Hill",
    "Primary Club": "Stock Exchange RC",
    County: "Essex",
    Country: "England",
  },
  502: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Barnes",
    "Primary Club": "Bedfordians RC",
    County: "Hertfordshire",
    Country: "England",
  },
  503: {
    Title: "Mr",
    Initials: "AD",
    Surname: "Hunter",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  504: {
    Title: "Mr",
    Initials: "DQM",
    Surname: "Harvey",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  505: {
    Title: "",
    Initials: "JE",
    Surname: "Horn",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "",
  },
  506: {
    Title: "Mr",
    Initials: "NE",
    Surname: "Harvey",
    "Primary Club": "UVRC",
    County: "Hertfordshire",
    Country: "England",
  },
  507: {
    Title: "Mr",
    Initials: "ME",
    Surname: "Barr",
    "Primary Club": "City RC",
    County: "Hertfordshire",
    Country: "England",
  },
  508: {
    Title: "Mr",
    Initials: "TP",
    Surname: "Hammond",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  509: {
    Title: "",
    Initials: "J",
    Surname: "Hanton",
    "Primary Club": "Manchester R.C",
    County: "Lancashire",
    Country: "England",
  },
  510: {
    Title: "Mr",
    Initials: "T",
    Surname: "Hembrow",
    "Primary Club": "Ibis RC",
    County: "Somerset",
    Country: "England",
  },
  511: {
    Title: "Mr",
    Initials: "MW",
    Surname: "Hallows",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  512: {
    Title: "Mr",
    Initials: "RF",
    Surname: "Barrington",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  513: {
    Title: "Mr",
    Initials: "J",
    Surname: "Holmes",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  514: {
    Title: "Mr",
    Initials: "AK",
    Surname: "Henderson",
    "Primary Club": "NLRC",
    County: "Berkshire",
    Country: "Scotland",
  },
  515: {
    Title: "Mr",
    Initials: "K",
    Surname: "Hardyman",
    "Primary Club": "South Hants P & RC",
    County: "Hampshire",
    Country: "England",
  },
  516: {
    Title: "Lt",
    Initials: "GD",
    Surname: "Bartle",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  517: {
    Title: "",
    Initials: "RN",
    Surname: "Hall",
    "Primary Club": "NLRC",
    County: "Hertfordshire",
    Country: "",
  },
  518: {
    Title: "",
    Initials: "WJ",
    Surname: "Haag",
    "Primary Club": "Suffolk CRA",
    County: "Suffolk",
    Country: "England",
  },
  519: {
    Title: "Mr",
    Initials: "DB",
    Surname: "Harrison",
    "Primary Club": "Watford & District RC",
    County: "Hertfordshire",
    Country: "England",
  },
  520: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Hallows",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  521: {
    Title: "Mr",
    Initials: "D",
    Surname: "Hanson",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  522: {
    Title: "Miss",
    Initials: "JF",
    Surname: "Hossack",
    "Primary Club": "NLRC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  523: {
    Title: "Mr",
    Initials: "JSW",
    Surname: "Hunter",
    "Primary Club": "RNTRC",
    County: "Hampshire",
    Country: "England",
  },
  524: {
    Title: "Mr",
    Initials: "G",
    Surname: "Baylis",
    "Primary Club": "Lloyds TSBRC",
    County: "Essex",
    Country: "England",
  },
  525: {
    Title: "",
    Initials: "WO",
    Surname: "Hancock",
    "Primary Club": "LMRA",
    County: "Norfolk",
    Country: "England",
  },
  526: {
    Title: "Miss",
    Initials: "KM",
    Surname: "Haag",
    "Primary Club": "West Suffolk R.C",
    County: "Suffolk",
    Country: "England",
  },
  527: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Hakim",
    "Primary Club": "Old Johnian RC",
    County: "Lincolnshire",
    Country: "England",
  },
  528: {
    Title: "",
    Initials: "AL",
    Surname: "Hills",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "",
  },
  529: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Jackman",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  530: {
    Title: "Mr",
    Initials: "P",
    Surname: "Jacques",
    "Primary Club": "MCRC",
    County: "Staffordshire",
    Country: "England",
  },
  531: {
    Title: "Mrs",
    Initials: "VD",
    Surname: "Jewess",
    "Primary Club": "Woodstock RC",
    County: "Kent",
    Country: "England",
  },
  532: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Jobson",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  533: {
    Title: "Mr",
    Initials: "ARH",
    Surname: "Jones",
    "Primary Club": "Somerset RA",
    County: "Somerset",
    Country: "Netherlands",
  },
  534: {
    Title: "",
    Initials: "DE",
    Surname: "Jones",
    "Primary Club": "RAFTRC",
    County: "Lincolnshire",
    Country: "Wales",
  },
  535: {
    Title: "",
    Initials: "H",
    Surname: "Jones",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  536: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Jones",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  537: {
    Title: "Mr",
    Initials: "MF",
    Surname: "Jordan",
    "Primary Club": "RNTRC",
    County: "Sussex",
    Country: "England",
  },
  538: {
    Title: "Mr",
    Initials: "DM",
    Surname: "Jory",
    "Primary Club": "Guernsey RC",
    County: "",
    Country: "Guernsey",
  },
  539: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Joyce",
    "Primary Club": "Cawdor",
    County: "Buckinghamshire",
    Country: "Scotland",
  },
  540: {
    Title: "Mr",
    Initials: "B",
    Surname: "Jones",
    "Primary Club": "RAFTRC",
    County: "Lincolnshire",
    Country: "Wales",
  },
  541: {
    Title: "Mr",
    Initials: "RK",
    Surname: "Jackson",
    "Primary Club": "Welwyn Phoenix",
    County: "Hertfordshire",
    Country: "England",
  },
  542: {
    Title: "Mr",
    Initials: "CR",
    Surname: "Behn",
    "Primary Club": "SLRC",
    County: "Essex",
    Country: "England",
  },
  543: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Jory",
    "Primary Club": "Guernsey RC",
    County: "",
    Country: "Guernsey",
  },
  544: {
    Title: "Mr",
    Initials: "S",
    Surname: "Belither",
    "Primary Club": "Uppingham Veterans RC",
    County: "Essex",
    Country: "England",
  },
  545: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Johnson",
    "Primary Club": "Lloyds TSBRC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  546: {
    Title: "Mrs",
    Initials: "GMP",
    Surname: "Jarvis-Brasier",
    "Primary Club": "LMRA",
    County: "Buckinghamshire",
    Country: "",
  },
  547: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Jenvey",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  548: {
    Title: "Mrs",
    Initials: "LA",
    Surname: "Johnson",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  549: {
    Title: "Mr",
    Initials: "CTS",
    Surname: "Belk",
    "Primary Club": "Uppingham Veterans RC",
    County: "County Durham",
    Country: "England",
  },
  550: {
    Title: "Dr",
    Initials: "NG",
    Surname: "Jeffs",
    "Primary Club": "NLRC",
    County: "Middlesex",
    Country: "England",
  },
  551: {
    Title: "",
    Initials: "C",
    Surname: "Jeffery",
    "Primary Club": "Manydown Rifle Club",
    County: "",
    Country: "",
  },
  552: {
    Title: "",
    Initials: "M",
    Surname: "Jeffery",
    "Primary Club": "Manydown Rifle Club",
    County: "",
    Country: "",
  },
  553: {
    Title: "",
    Initials: "B",
    Surname: "Kendrick",
    "Primary Club": "MCRC",
    County: "Staffordshire",
    Country: "England",
  },
  554: {
    Title: "Mr",
    Initials: "DN",
    Surname: "Kent",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  555: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Kent",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  556: {
    Title: "Mr",
    Initials: "TLW",
    Surname: "Kidner",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  557: {
    Title: "",
    Initials: "JR",
    Surname: "Killian",
    "Primary Club": "A.T.R.C.",
    County: "Warwickshire",
    Country: "England",
  },
  558: {
    Title: "Mr",
    Initials: "JEM",
    Surname: "Bellringer",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  559: {
    Title: "",
    Initials: "LE",
    Surname: "King",
    "Primary Club": "",
    County: "Essex",
    Country: "England",
  },
  560: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Knight",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  561: {
    Title: "Mr",
    Initials: "PA",
    Surname: "King",
    "Primary Club": "Artists RC",
    County: "Surrey",
    Country: "England",
  },
  562: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Benham",
    "Primary Club": "South London RC",
    County: "Essex",
    Country: "",
  },
  563: {
    Title: "",
    Initials: "GAA",
    Surname: "Khalek",
    "Primary Club": "Lebanon",
    County: "",
    Country: "Lebanon",
  },
  564: {
    Title: "",
    Initials: "DR",
    Surname: "Kirk",
    "Primary Club": "Telepost R.C",
    County: "Shropshire",
    Country: "United Kingdom",
  },
  565: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Kyle",
    "Primary Club": "Dungannon",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  566: {
    Title: "Mr",
    Initials: "C",
    Surname: "Kennish",
    "Primary Club": "Isle of Man",
    County: "",
    Country: "Isle of Man",
  },
  567: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Kirkillo-Stacewicz",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "England",
  },
  568: {
    Title: "Mrs",
    Initials: "SEP",
    Surname: "Kent",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  569: {
    Title: "Mr",
    Initials: "JRJ",
    Surname: "Kennedy",
    "Primary Club": "Nottingham City RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  570: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Kelly",
    "Primary Club": "WFC",
    County: "Staffordshire",
    Country: "England",
  },
  571: {
    Title: "Mr",
    Initials: "JG",
    Surname: "Bennett",
    "Primary Club": "Stock Exchange RC",
    County: "Worcestershire",
    Country: "England",
  },
  572: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Bennett",
    "Primary Club": "Kidderminster RC",
    County: "Worcestershire",
    Country: "England",
  },
  573: {
    Title: "Mr",
    Initials: "A",
    Surname: "Kinross",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  574: {
    Title: "",
    Initials: "PR",
    Surname: "Klein",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "Scotland",
  },
  575: {
    Title: "",
    Initials: "R",
    Surname: "Bennett",
    "Primary Club": "Club 25",
    County: "",
    Country: "",
  },
  576: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Leaper",
    "Primary Club": "SLRC",
    County: "Essex",
    Country: "England",
  },
  577: {
    Title: "Mr",
    Initials: "CRF",
    Surname: "Leather",
    "Primary Club": "Old Glenalmond RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  578: {
    Title: "",
    Initials: "JL",
    Surname: "Le Breton",
    "Primary Club": "Jersey R.A",
    County: "Jersey",
    Country: "Jersey",
  },
  579: {
    Title: "",
    Initials: "DP",
    Surname: "Lee",
    "Primary Club": "N.R.A",
    County: "",
    Country: "",
  },
  580: {
    Title: "Mr",
    Initials: "AE",
    Surname: "Le Page",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Channel Islands",
  },
  581: {
    Title: "Mr",
    Initials: "DH",
    Surname: "Le Vesconte",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  582: {
    Title: "",
    Initials: "B",
    Surname: "Lewis",
    "Primary Club": "Bromsgrove R.C",
    County: "Worcestershire",
    Country: "",
  },
  583: {
    Title: "Mr",
    Initials: "JWE",
    Surname: "Lewis",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  584: {
    Title: "Wg Cdr",
    Initials: "JR",
    Surname: "Lindsay",
    "Primary Club": "RAFTRC",
    County: "Berkshire",
    Country: "England",
  },
  585: {
    Title: "",
    Initials: "PA",
    Surname: "Bennison",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  586: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Sharma",
    "Primary Club": "Welsh RA",
    County: "Gloucestershire",
    Country: "Wales",
  },
  587: {
    Title: "Mr",
    Initials: "DCW",
    Surname: "Lloyd",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  588: {
    Title: "Miss",
    Initials: "SS",
    Surname: "Lohmann",
    "Primary Club": "NLRC",
    County: "",
    Country: "Germany",
  },
  589: {
    Title: "Mrs",
    Initials: "CEE",
    Surname: "Lomax",
    "Primary Club": "Midland Counties RC",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  590: {
    Title: "Mr",
    Initials: "A",
    Surname: "Lothian",
    "Primary Club": "Old Epsomian RC",
    County: "Middlesex",
    Country: "England",
  },
  591: {
    Title: "Dr",
    Initials: "JDS",
    Surname: "Langley",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  592: {
    Title: "Mr",
    Initials: "DW",
    Surname: "Bentley",
    "Primary Club": "Leics & District RPC",
    County: "Leicestershire",
    Country: "England",
  },
  593: {
    Title: "Mr",
    Initials: "MLR",
    Surname: "Lunnon",
    "Primary Club": "Club 25",
    County: "Hertfordshire",
    Country: "England",
  },
  594: {
    Title: "Mr",
    Initials: "DH",
    Surname: "Lury",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  595: {
    Title: "Mrs",
    Initials: "SJ",
    Surname: "Le Cheminant",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  596: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Livett",
    "Primary Club": "NLRC",
    County: "Oxfordshire",
    Country: "England",
  },
  597: {
    Title: "Mr",
    Initials: "AD",
    Surname: "Le Cheminant",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  598: {
    Title: "",
    Initials: "GW",
    Surname: "Berman",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  599: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Llewellyn",
    "Primary Club": "Chepstow RC",
    County: "South Wales",
    Country: "Wales",
  },
  600: {
    Title: "Mr",
    Initials: "IF",
    Surname: "Luckman",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  601: {
    Title: "Mr",
    Initials: "RK",
    Surname: "Betts",
    "Primary Club": "Darwin",
    County: "Northern Territory",
    Country: "Falkland Islands",
  },
  602: {
    Title: "Miss",
    Initials: "EA",
    Surname: "Lantau",
    "Primary Club": "O.G.R.E",
    County: "Norfolk",
    Country: "Wales",
  },
  603: {
    Title: "Mr",
    Initials: "GV",
    Surname: "Barnard",
    "Primary Club": "Northamptonshire RA",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  604: {
    Title: "Mrs",
    Initials: "RSL",
    Surname: "Cargill Thompson",
    "Primary Club": "NLRC",
    County: "Hertfordshire",
    Country: "England",
  },
  605: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Luckman",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  606: {
    Title: "Mr",
    Initials: "D",
    Surname: "Lowe",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  607: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Le Sueur",
    "Primary Club": "Jersey RA",
    County: "",
    Country: "Jersey",
  },
  608: {
    Title: "",
    Initials: "M",
    Surname: "Longmire",
    "Primary Club": "Old Guildfordians RC",
    County: "",
    Country: "",
  },
  609: {
    Title: "Miss",
    Initials: "SG",
    Surname: "Le Sueur",
    "Primary Club": "Jersey R.A",
    County: "Jersey",
    Country: "Jersey",
  },
  610: {
    Title: "Miss",
    Initials: "R",
    Surname: "Lusty",
    "Primary Club": "Old Guildfordians RC",
    County: "",
    Country: "",
  },
  611: {
    Title: "Mr",
    Initials: "CL",
    Surname: "Leighton",
    "Primary Club": "KCS Old Boys RC",
    County: "Berkshire",
    Country: "England",
  },
  612: {
    Title: "Mr",
    Initials: "CS",
    Surname: "Lamont",
    "Primary Club": "Surrey RA",
    County: "West of Scotland",
    Country: "Scotland",
  },
  613: {
    Title: "",
    Initials: "TJ",
    Surname: "Lury",
    "Primary Club": "N.L.R.C.",
    County: "Aberdeenshire",
    Country: "Great Britain",
  },
  614: {
    Title: "Mr",
    Initials: "DR",
    Surname: "Le Quesne",
    "Primary Club": "Jersey RC",
    County: "",
    Country: "Jersey",
  },
  615: {
    Title: "Mr",
    Initials: "A",
    Surname: "Bishop",
    "Primary Club": "Rainham & District",
    County: "Essex",
    Country: "England",
  },
  616: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Luckman",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  617: {
    Title: "Mr",
    Initials: "S",
    Surname: "Lenyk",
    "Primary Club": "NRA",
    County: "Essex",
    Country: "England",
  },
  618: {
    Title: "Mr",
    Initials: "A",
    Surname: "Mabon",
    "Primary Club": "West of Scotland RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  619: {
    Title: "Mr",
    Initials: "C",
    Surname: "Mallett",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  620: {
    Title: "Mr",
    Initials: "CC",
    Surname: "Mallett Jnr",
    "Primary Club": "Jersey RC",
    County: "",
    Country: "Jersey",
  },
  621: {
    Title: "",
    Initials: "AG",
    Surname: "Marr",
    "Primary Club": "Manydown Rifle Club",
    County: "West of Scotland",
    Country: "Scotland",
  },
  622: {
    Title: "Mr",
    Initials: "MF",
    Surname: "Martel",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  623: {
    Title: "Mr",
    Initials: "MJM",
    Surname: "Black",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "England",
  },
  624: {
    Title: "Mr",
    Initials: "RS",
    Surname: "Mason MRCVS",
    "Primary Club": "9th Somerset HG RC",
    County: "Somerset",
    Country: "England",
  },
  625: {
    Title: "",
    Initials: "GS",
    Surname: "Matthews",
    "Primary Club": "Ruislip R.C",
    County: "",
    Country: "",
  },
  626: {
    Title: "Mr",
    Initials: "RGK",
    Surname: "Matthews",
    "Primary Club": "Lyndhurst RC",
    County: "Wiltshire",
    Country: "Australia",
  },
  627: {
    Title: "Mr",
    Initials: "PW",
    Surname: "Medhurst",
    "Primary Club": "LMRA",
    County: "Berkshire",
    Country: "England",
  },
  628: {
    Title: "",
    Initials: "RDA",
    Surname: "Melling",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  629: {
    Title: "Mr",
    Initials: "P",
    Surname: "Merry",
    "Primary Club": "Kenya RA",
    County: "Kent",
    Country: "England",
  },
  630: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Merton Jones",
    "Primary Club": "Uppingham Veterans RC",
    County: "Yorkshire",
    Country: "England",
  },
  631: {
    Title: "Mr",
    Initials: "LE",
    Surname: "Messer",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  632: {
    Title: "Mr",
    Initials: "D",
    Surname: "Markie",
    "Primary Club": "BBC RC",
    County: "Surrey",
    Country: "England",
  },
  633: {
    Title: "Capt",
    Initials: "FR",
    Surname: "Miles",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  634: {
    Title: "Mr",
    Initials: "PJN",
    Surname: "Molesworth",
    "Primary Club": "ATRC",
    County: "Gloucestershire",
    Country: "Wales",
  },
  635: {
    Title: "Mr",
    Initials: "CP",
    Surname: "Moon",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  636: {
    Title: "Mr",
    Initials: "HW",
    Surname: "Morley",
    "Primary Club": "",
    County: "",
    Country: "Wales",
  },
  637: {
    Title: "Dr",
    Initials: "AE",
    Surname: "Morris",
    "Primary Club": "Old Haberdashers",
    County: "Buckinghamshire",
    Country: "England",
  },
  638: {
    Title: "",
    Initials: "TD",
    Surname: "Morris",
    "Primary Club": "RAFTRC",
    County: "Middlesex",
    Country: "England",
  },
  639: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Morton",
    "Primary Club": "Stock Exchange RC",
    County: "County of London",
    Country: "England",
  },
  640: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Mundy",
    "Primary Club": "NRA",
    County: "Berkshire",
    Country: "England",
  },
  641: {
    Title: "",
    Initials: "C",
    Surname: "Macfadyen",
    "Primary Club": "Old Marlburian R.C",
    County: "",
    Country: "",
  },
  642: {
    Title: "Mr",
    Initials: "ID",
    Surname: "Macdonald",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  643: {
    Title: "Miss",
    Initials: "HR",
    Surname: "Mackintosh",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  644: {
    Title: "Mr",
    Initials: "RW",
    Surname: "McVeigh",
    "Primary Club": "Dungannon",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  645: {
    Title: "Mr",
    Initials: "JPS",
    Surname: "Bloomfield",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  646: {
    Title: "Mr",
    Initials: "KJ",
    Surname: "Boaden",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  647: {
    Title: "Mrs",
    Initials: "RM",
    Surname: "Merridew",
    "Primary Club": "Wandsworth FBRC",
    County: "Buckinghamshire",
    Country: "Ireland",
  },
  648: {
    Title: "",
    Initials: "IA",
    Surname: "Moir",
    "Primary Club": "O.G.R.E",
    County: "Cambridgeshire",
    Country: "Scotland",
  },
  649: {
    Title: "",
    Initials: "C",
    Surname: "Markie",
    "Primary Club": "Midland Counties R.C",
    County: "",
    Country: "",
  },
  650: {
    Title: "Mr",
    Initials: "SW",
    Surname: "Maris",
    "Primary Club": "Huddersfield RC",
    County: "Derbyshire",
    Country: "England",
  },
  651: {
    Title: "Mr",
    Initials: "GD",
    Surname: "Moffatt",
    "Primary Club": "Windsor RC",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  652: {
    Title: "Mr",
    Initials: "RJR",
    Surname: "Mott",
    "Primary Club": "LMRA",
    County: "Middlesex",
    Country: "England",
  },
  653: {
    Title: "Mr",
    Initials: "JT",
    Surname: "Miller",
    "Primary Club": "Tageme RC",
    County: "",
    Country: "England",
  },
  654: {
    Title: "",
    Initials: "JA",
    Surname: "Matthews",
    "Primary Club": "Artists",
    County: "",
    Country: "",
  },
  655: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Booth",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  656: {
    Title: "Mr",
    Initials: "D",
    Surname: "Morris",
    "Primary Club": "ATSC",
    County: "Yorkshire",
    Country: "England",
  },
  657: {
    Title: "Mr",
    Initials: "NC",
    Surname: "Mace",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  658: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Millar",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  659: {
    Title: "Mr",
    Initials: "RCQ",
    Surname: "Martin",
    "Primary Club": "ATSC",
    County: "Ulster",
    Country: "Ireland",
  },
  660: {
    Title: "Mr",
    Initials: "KL",
    Surname: "Murray",
    "Primary Club": "Leatherhead RC",
    County: "Surrey",
    Country: "England",
  },
  661: {
    Title: "",
    Initials: "IR",
    Surname: "McNee",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "",
  },
  662: {
    Title: "",
    Initials: "JC",
    Surname: "Musso",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  663: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Moran",
    "Primary Club": "Midland RC",
    County: "",
    Country: "Ireland",
  },
  664: {
    Title: "",
    Initials: "AS",
    Surname: "Mehers",
    "Primary Club": "O.G.R.E",
    County: "Jersey",
    Country: "Ireland",
  },
  665: {
    Title: "",
    Initials: "AJ",
    Surname: "MacDonald",
    "Primary Club": "Kent",
    County: "Kent",
    Country: "England",
  },
  666: {
    Title: "",
    Initials: "MS",
    Surname: "Merry",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  667: {
    Title: "",
    Initials: "AM",
    Surname: "Newman",
    "Primary Club": "Twickenham R.C",
    County: "",
    Country: "",
  },
  668: {
    Title: "",
    Initials: "S",
    Surname: "Napper",
    "Primary Club": "West of Scotland R.C",
    County: "West of Scotland",
    Country: "England",
  },
  669: {
    Title: "",
    Initials: "RJG",
    Surname: "Nation",
    "Primary Club": "",
    County: "Kent",
    Country: "England",
  },
  670: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Needham",
    "Primary Club": "Ricochet RC",
    County: "Oxfordshire",
    Country: "England",
  },
  671: {
    Title: "Mr",
    Initials: "RGM",
    Surname: "Newman",
    "Primary Club": "Lloyds TSBRC",
    County: "Oxfordshire",
    Country: "England",
  },
  672: {
    Title: "",
    Initials: "W",
    Surname: "Norman",
    "Primary Club": "Huddersfield R.C",
    County: "Yorkshire",
    Country: "England",
  },
  673: {
    Title: "",
    Initials: "T",
    Surname: "Nowell",
    "Primary Club": "Huddersfield R.C",
    County: "Yorkshire",
    Country: "England",
  },
  674: {
    Title: "A.V.M.",
    Initials: "D",
    Surname: "Bower",
    "Primary Club": "RAFTRC",
    County: "Yorkshire",
    Country: "England",
  },
  675: {
    Title: "Mrs",
    Initials: "CM",
    Surname: "Noyes",
    "Primary Club": "WFBRC",
    County: "",
    Country: "Scotland",
  },
  676: {
    Title: "Mr",
    Initials: "FE",
    Surname: "Neale",
    "Primary Club": "West Suffolk RC",
    County: "Lincolnshire",
    Country: "England",
  },
  677: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Boyce",
    "Primary Club": "Bullet Lodge RC",
    County: "Dorset",
    Country: "England",
  },
  678: {
    Title: "Mrs",
    Initials: "T",
    Surname: "Newbold",
    "Primary Club": "City RC",
    County: "",
    Country: "England",
  },
  679: {
    Title: "",
    Initials: "CS",
    Surname: "O'Brien",
    "Primary Club": "Manchester R.C",
    County: "Cheshire",
    Country: "England",
  },
  680: {
    Title: "Mr",
    Initials: "WA",
    Surname: "O'Dair",
    "Primary Club": "Bullet Lodge RC",
    County: "Oxfordshire",
    Country: "England",
  },
  681: {
    Title: "Mr",
    Initials: "DT",
    Surname: "O'Leary",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  682: {
    Title: "Lt. Col.",
    Initials: "HJ",
    Surname: "Orpen-Smellie",
    "Primary Club": "Late Para",
    County: "Essex",
    Country: "England",
  },
  683: {
    Title: "Mr",
    Initials: "CAJ",
    Surname: "Oliver-Bellasis",
    "Primary Club": "NRA",
    County: "",
    Country: "Ireland",
  },
  684: {
    Title: "",
    Initials: "TR",
    Surname: "Osborne",
    "Primary Club": "Chichester R.C",
    County: "Sussex",
    Country: "England",
  },
  685: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Oxley",
    "Primary Club": "Kidderminster RC",
    County: "Worcestershire",
    Country: "England",
  },
  686: {
    Title: "Miss",
    Initials: "JE",
    Surname: "Ouston",
    "Primary Club": "Old Epsomian RC",
    County: "Hertfordshire",
    Country: "England",
  },
  687: {
    Title: "Dr",
    Initials: "PJ",
    Surname: "O'Brien",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  688: {
    Title: "Mrs",
    Initials: "GC",
    Surname: "O'Leary",
    "Primary Club": "Welsh RA",
    County: "County of London",
    Country: "Wales",
  },
  689: {
    Title: "",
    Initials: "RC",
    Surname: "Oliver-Bellasis",
    "Primary Club": "Manydown Rifle Club",
    County: "",
    Country: "",
  },
  690: {
    Title: "",
    Initials: "DE",
    Surname: "Osborne",
    "Primary Club": "Maritzburg R.A",
    County: "",
    Country: "",
  },
  691: {
    Title: "Mr",
    Initials: "SP",
    Surname: "OBrien",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  692: {
    Title: "Eur Ing",
    Initials: "CA",
    Surname: "Painting",
    "Primary Club": "Pumas RC",
    County: "Hampshire",
    Country: "England",
  },
  693: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Painting",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  694: {
    Title: "Mrs",
    Initials: "JA",
    Surname: "Painting",
    "Primary Club": "Club 25",
    County: "Hampshire",
    Country: "England",
  },
  695: {
    Title: "",
    Initials: "RJ",
    Surname: "Bramley",
    "Primary Club": "LMRA",
    County: "Nottinghamshire",
    Country: "England",
  },
  696: {
    Title: "Mr",
    Initials: "DAF",
    Surname: "Parkinson",
    "Primary Club": "Ricochet RC",
    County: "Gloucestershire",
    Country: "Scotland",
  },
  697: {
    Title: "Mr",
    Initials: "PR",
    Surname: "Pines",
    "Primary Club": "Chichester RC",
    County: "Sussex",
    Country: "England",
  },
  698: {
    Title: "Mr",
    Initials: "J",
    Surname: "Payne",
    "Primary Club": "Hailsham RC",
    County: "Sussex",
    Country: "England",
  },
  699: {
    Title: "Mr",
    Initials: "LM",
    Surname: "Peden",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  700: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Penfold",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "England",
  },
  701: {
    Title: "Mr",
    Initials: "NW",
    Surname: "Penn",
    "Primary Club": "Bedfordians RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  702: {
    Title: "Mr",
    Initials: "SNS",
    Surname: "Penrose",
    "Primary Club": "Stock Exchange RC",
    County: "Essex",
    Country: "England",
  },
  703: {
    Title: "M",
    Initials: "PL",
    Surname: "Personne",
    "Primary Club": "A.E.T",
    County: "",
    Country: "France",
  },
  704: {
    Title: "",
    Initials: "AG",
    Surname: "Pettit",
    "Primary Club": "Uckfield (1944) HG RC",
    County: "Sussex",
    Country: "",
  },
  705: {
    Title: "",
    Initials: "AR",
    Surname: "Pettman",
    "Primary Club": "Cornwall R.C",
    County: "Cornwall",
    Country: "England",
  },
  706: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Pickering",
    "Primary Club": "NRA",
    County: "Somerset",
    Country: "England",
  },
  707: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Pitt",
    "Primary Club": "NRA",
    County: "Hampshire",
    Country: "England",
  },
  708: {
    Title: "Dr",
    Initials: "R",
    Surname: "Pizer",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  709: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Place",
    "Primary Club": "NLRC",
    County: "Lancashire",
    Country: "England",
  },
  710: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Pollard",
    "Primary Club": "North London Rifle Club",
    County: "Kent",
    Country: "England",
  },
  711: {
    Title: "Mr",
    Initials: "R",
    Surname: "Braybrooke",
    "Primary Club": "Duchy Shooting Assn",
    County: "Hertfordshire",
    Country: "England",
  },
  712: {
    Title: "Mr",
    Initials: "RS",
    Surname: "Pool",
    "Primary Club": "Bullet Lodge RC",
    County: "Dorset",
    Country: "England",
  },
  713: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Powell",
    "Primary Club": "Darlington R & PC",
    County: "",
    Country: "England",
  },
  714: {
    Title: "Mr",
    Initials: "JG",
    Surname: "Powell",
    "Primary Club": "",
    County: "Sussex",
    Country: "England",
  },
  715: {
    Title: "",
    Initials: "C",
    Surname: "Pope",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  716: {
    Title: "Dr",
    Initials: "WLV",
    Surname: "Price",
    "Primary Club": "LMRA",
    County: "Surrey",
    Country: "",
  },
  717: {
    Title: "",
    Initials: "CH",
    Surname: "Paul",
    "Primary Club": "Stock Exchange RC",
    County: "Buckinghamshire",
    Country: "England",
  },
  718: {
    Title: "Mr",
    Initials: "KO",
    Surname: "Pugh",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "England",
  },
  719: {
    Title: "",
    Initials: "R",
    Surname: "Purdom",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  720: {
    Title: "Mr",
    Initials: "EM",
    Surname: "Brice",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  721: {
    Title: "",
    Initials: "GH",
    Surname: "Pybus",
    "Primary Club": "Manchester R.C",
    County: "Lancashire",
    Country: "England",
  },
  722: {
    Title: "Mr",
    Initials: "J",
    Surname: "Pugsley",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  723: {
    Title: "Mr",
    Initials: "WT",
    Surname: "Bridger",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  724: {
    Title: "Mrs",
    Initials: "RK",
    Surname: "Penrose",
    "Primary Club": "Stock Exchange RC",
    County: "Essex",
    Country: "England",
  },
  725: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Pattinson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Rutland",
    Country: "England",
  },
  726: {
    Title: "Dr",
    Initials: "HHG",
    Surname: "Pollock",
    "Primary Club": "Australian Rifle Team",
    County: "Hampshire",
    Country: "Australia",
  },
  727: {
    Title: "Mr",
    Initials: "MC",
    Surname: "Parker",
    "Primary Club": "Lloyds TSBRC",
    County: "Sussex",
    Country: "England",
  },
  728: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Pickard",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "",
  },
  729: {
    Title: "",
    Initials: "A",
    Surname: "Perry",
    "Primary Club": "Sheffield R.C",
    County: "",
    Country: "",
  },
  730: {
    Title: "Dr",
    Initials: "MJ",
    Surname: "Pearse",
    "Primary Club": "Chobham & District RC",
    County: "Surrey",
    Country: "England",
  },
  731: {
    Title: "",
    Initials: "GE",
    Surname: "Panella",
    "Primary Club": "N.L.R.C.",
    County: "Norfolk",
    Country: "England",
  },
  732: {
    Title: "Mr",
    Initials: "RN",
    Surname: "Britton",
    "Primary Club": "Bromsgrove RC",
    County: "Worcestershire",
    Country: "England",
  },
  733: {
    Title: "",
    Initials: "AK",
    Surname: "Pearse",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  734: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Pattenden",
    "Primary Club": "Woodstock RC",
    County: "Katanora",
    Country: "England",
  },
  735: {
    Title: "Mr",
    Initials: "BJA",
    Surname: "Parke",
    "Primary Club": "Bullet Lodge RC",
    County: "Surrey",
    Country: "England",
  },
  736: {
    Title: "Mr",
    Initials: "SC",
    Surname: "Postins",
    "Primary Club": "OO Troubleshooters",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  737: {
    Title: "Mr",
    Initials: "D",
    Surname: "Perella",
    "Primary Club": "BBC Rifle Club",
    County: "Buckinghamshire",
    Country: "",
  },
  738: {
    Title: "Mr",
    Initials: "GD",
    Surname: "Palmer",
    "Primary Club": "Wandsworth FBRC",
    County: "Cheshire",
    Country: "England",
  },
  739: {
    Title: "",
    Initials: "DJ",
    Surname: "Pooley",
    "Primary Club": "LMRA",
    County: "Hertfordshire",
    Country: "England",
  },
  740: {
    Title: "Mr",
    Initials: "C",
    Surname: "Brooks",
    "Primary Club": "Old Marlburian RC",
    County: "Hampshire",
    Country: "England",
  },
  741: {
    Title: "Dr",
    Initials: "AJ",
    Surname: "Pilgrim",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  742: {
    Title: "",
    Initials: "PE",
    Surname: "Page",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  743: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Quy",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  744: {
    Title: "WO2",
    Initials: "PF",
    Surname: "Quilliam",
    "Primary Club": "A.T.R.C.",
    County: "Hampshire",
    Country: "Isle of Man",
  },
  745: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Raine",
    "Primary Club": "City RC",
    County: "Sussex",
    Country: "England",
  },
  746: {
    Title: "",
    Initials: "W",
    Surname: "Rea",
    "Primary Club": "A.T.R.C.",
    County: "Essex",
    Country: "Ireland",
  },
  747: {
    Title: "Mr",
    Initials: "CW",
    Surname: "Brown",
    "Primary Club": "Uppingham Veterans RC",
    County: "Buckinghamshire",
    Country: "England",
  },
  748: {
    Title: "Mr",
    Initials: "DFP",
    Surname: "Richards",
    "Primary Club": "Old Marlburian RC",
    County: "Hampshire",
    Country: "England",
  },
  749: {
    Title: "Mr",
    Initials: "WCP",
    Surname: "Richards",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  750: {
    Title: "Major",
    Initials: "JM",
    Surname: "Riches",
    "Primary Club": "A.T.R.C.",
    County: "Hampshire",
    Country: "England",
  },
  751: {
    Title: "Mr",
    Initials: "Ian",
    Surname: "Brown",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  752: {
    Title: "",
    Initials: "RJ",
    Surname: "Ritchie",
    "Primary Club": "Cornwall R.C",
    County: "",
    Country: "",
  },
  753: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Roalf",
    "Primary Club": "Ashford & District RC",
    County: "Kent",
    Country: "England",
  },
  754: {
    Title: "Mr",
    Initials: "BFC",
    Surname: "Roberts",
    "Primary Club": "Little Clacton",
    County: "Essex",
    Country: "England",
  },
  755: {
    Title: "Mr",
    Initials: "RM",
    Surname: "Roberts",
    "Primary Club": "Ibis RC",
    County: "Sussex",
    Country: "England",
  },
  756: {
    Title: "Mr",
    Initials: "V",
    Surname: "Robertshaw",
    "Primary Club": "Leeds RC",
    County: "Yorkshire",
    Country: "England",
  },
  757: {
    Title: "Wg Cdr",
    Initials: "A",
    Surname: "Robertson",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "England",
  },
  758: {
    Title: "Mr",
    Initials: "IW",
    Surname: "Robertson",
    "Primary Club": "RAFTRC",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  759: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Robinson",
    "Primary Club": "OCRA",
    County: "Dorset",
    Country: "England",
  },
  760: {
    Title: "Mr",
    Initials: "RP",
    Surname: "Rosling",
    "Primary Club": "Old Nottinghamians",
    County: "",
    Country: "England",
  },
  761: {
    Title: "Mr",
    Initials: "WJ",
    Surname: "Rowland",
    "Primary Club": "Ibis RC",
    County: "",
    Country: "England",
  },
  762: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Rowell",
    "Primary Club": "Manydown RC",
    County: "Hampshire",
    Country: "England",
  },
  763: {
    Title: "Mr",
    Initials: "CJF",
    Surname: "Rowley",
    "Primary Club": "NLRC",
    County: "Norfolk",
    Country: "Scotland",
  },
  764: {
    Title: "Miss",
    Initials: "IFE",
    Surname: "Rowley",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "Scotland",
  },
  765: {
    Title: "Mr",
    Initials: "TG",
    Surname: "Rylands",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  766: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Ritchie",
    "Primary Club": "Wandsworth FBRC",
    County: "Gloucestershire",
    Country: "England",
  },
  767: {
    Title: "",
    Initials: "A",
    Surname: "Rennison",
    "Primary Club": "Caterham & District R.C",
    County: "",
    Country: "",
  },
  768: {
    Title: "Mrs",
    Initials: "LJ",
    Surname: "Brister",
    "Primary Club": "",
    County: "",
    Country: "Wales",
  },
  769: {
    Title: "Mr",
    Initials: "A",
    Surname: "Ringer",
    "Primary Club": "UVRC",
    County: "Norfolk",
    Country: "England",
  },
  770: {
    Title: "Flt Lt",
    Initials: "BN",
    Surname: "Roth",
    "Primary Club": "NLRC",
    County: "Kent",
    Country: "Scotland",
  },
  771: {
    Title: "Mr",
    Initials: "KWD",
    Surname: "Ramsey",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  772: {
    Title: "Mr",
    Initials: "M",
    Surname: "Reid",
    "Primary Club": "Somerset RA",
    County: "Somerset",
    Country: "England",
  },
  773: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Rowland",
    "Primary Club": "West Atholl RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  774: {
    Title: "Lt Cdr.",
    Initials: "S",
    Surname: "Roots",
    "Primary Club": "NACRA",
    County: "Somerset",
    Country: "England",
  },
  775: {
    Title: "Mr",
    Initials: "EBD",
    Surname: "Reis",
    "Primary Club": "Wellington College",
    County: "Wiltshire",
    Country: "England",
  },
  776: {
    Title: "",
    Initials: "RA",
    Surname: "Restell",
    "Primary Club": "Eagle R & P.C",
    County: "Berkshire",
    Country: "England",
  },
  778: {
    Title: "Mr",
    Initials: "AJD",
    Surname: "Saunders",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  779: {
    Title: "",
    Initials: "W",
    Surname: "Schattenberg",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  780: {
    Title: "Mr",
    Initials: "JE",
    Surname: "Scobie",
    "Primary Club": "",
    County: "",
    Country: "Scotland",
  },
  781: {
    Title: "",
    Initials: "DM",
    Surname: "Seagrove",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  782: {
    Title: "Mr",
    Initials: "RF",
    Surname: "Burden",
    "Primary Club": "Dorchester",
    County: "Oxfordshire",
    Country: "England",
  },
  783: {
    Title: "",
    Initials: "S",
    Surname: "Schapira",
    "Primary Club": "West Atholl",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  784: {
    Title: "Mr",
    Initials: "GRP",
    Surname: "Shapland",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  785: {
    Title: "Mr",
    Initials: "PW",
    Surname: "Sharman",
    "Primary Club": "NRC of S",
    County: "Wiltshire",
    Country: "Scotland",
  },
  786: {
    Title: "Mr",
    Initials: "BR",
    Surname: "Shouler",
    "Primary Club": "Langar RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  787: {
    Title: "",
    Initials: "DE",
    Surname: "Simkin",
    "Primary Club": "A.T.S.C",
    County: "Worcestershire",
    Country: "England",
  },
  788: {
    Title: "Rev Dr",
    Initials: "A",
    Surname: "Simpson",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "Scotland",
  },
  789: {
    Title: "Mr",
    Initials: "SM",
    Surname: "Smallwood",
    "Primary Club": "NLRC",
    County: "Norfolk",
    Country: "England",
  },
  790: {
    Title: "Mr",
    Initials: "DM",
    Surname: "Smith",
    "Primary Club": "NRA",
    County: "Nottinghamshire",
    Country: "England",
  },
  791: {
    Title: "Flt Lt (Re",
    Initials: "MJM",
    Surname: "Smith",
    "Primary Club": "RAF (TR) C",
    County: "Bedfordshire",
    Country: "United Kingdom",
  },
  792: {
    Title: "",
    Initials: "PR",
    Surname: "Smith",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  793: {
    Title: "",
    Initials: "GD",
    Surname: "Snooks",
    "Primary Club": "N.R.A",
    County: "",
    Country: "",
  },
  794: {
    Title: "",
    Initials: "HG",
    Surname: "Southwood",
    "Primary Club": "National Westminster Bank",
    County: "Essex",
    Country: "England",
  },
  795: {
    Title: "Mr",
    Initials: "RHT",
    Surname: "Sowerby",
    "Primary Club": "ATRC",
    County: "Sussex",
    Country: "England",
  },
  796: {
    Title: "",
    Initials: "AHW",
    Surname: "Spencer",
    "Primary Club": "Zambia R.C",
    County: "",
    Country: "Zambia",
  },
  797: {
    Title: "Mr",
    Initials: "BGS",
    Surname: "Bush",
    "Primary Club": "Lloyds TSBRC",
    County: "Essex",
    Country: "England",
  },
  798: {
    Title: "Mr",
    Initials: "R",
    Surname: "Stafford",
    "Primary Club": "OCRA",
    County: "County of London",
    Country: "England",
  },
  799: {
    Title: "Mr",
    Initials: "ND",
    Surname: "Stangroom",
    "Primary Club": "OGRE",
    County: "",
    Country: "England",
  },
  800: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Steele",
    "Primary Club": "Carshalton RC",
    County: "Surrey",
    Country: "England",
  },
  801: {
    Title: "",
    Initials: "DR",
    Surname: "Stevens",
    "Primary Club": "Ibis R.C",
    County: "Hereford & Worcs",
    Country: "England",
  },
  802: {
    Title: "Mr",
    Initials: "PRC",
    Surname: "Stevens",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  803: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Stewart",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "England",
  },
  804: {
    Title: "Dr",
    Initials: "M",
    Surname: "Schwarz",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  805: {
    Title: "Mr",
    Initials: "E",
    Surname: "Stuart-Bamford",
    "Primary Club": "Club 25",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  806: {
    Title: "Mr",
    Initials: "NS",
    Surname: "Suffield-Jones",
    "Primary Club": "Berkshire RC",
    County: "Berkshire",
    Country: "England",
  },
  807: {
    Title: "",
    Initials: "AM",
    Surname: "Sutherland",
    "Primary Club": "Barclays Bank R.C",
    County: "",
    Country: "Scotland",
  },
  808: {
    Title: "Mr",
    Initials: "JPL",
    Surname: "Sweet",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  809: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Sykes",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "United States",
  },
  810: {
    Title: "",
    Initials: "RJB",
    Surname: "Murphy",
    "Primary Club": "KCS Old Boys R.C",
    County: "",
    Country: "",
  },
  811: {
    Title: "",
    Initials: "RS",
    Surname: "Spottiswoode",
    "Primary Club": "Horsham R. & P. C.",
    County: "Sussex",
    Country: "",
  },
  812: {
    Title: "Mr",
    Initials: "ITG",
    Surname: "Shaw",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "Scotland",
  },
  813: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Bysshe",
    "Primary Club": "Ricochet RC",
    County: "Berkshire",
    Country: "England",
  },
  814: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Sherratt",
    "Primary Club": "Club 25",
    County: "Hampshire",
    Country: "England",
  },
  815: {
    Title: "Dr",
    Initials: "RG",
    Surname: "Sturton",
    "Primary Club": "Windsor RC",
    County: "",
    Country: "",
  },
  816: {
    Title: "",
    Initials: "DH",
    Surname: "Sims",
    "Primary Club": "Sheffield R.C",
    County: "",
    Country: "",
  },
  817: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Stirland",
    "Primary Club": "Club 25",
    County: "Berkshire",
    Country: "England",
  },
  818: {
    Title: "",
    Initials: "R",
    Surname: "Addy",
    "Primary Club": "Leeds Service R.C",
    County: "Yorkshire",
    Country: "England",
  },
  819: {
    Title: "",
    Initials: "JC",
    Surname: "Scane",
    "Primary Club": "W.F.R.A Bromsgrove",
    County: "Worcestershire",
    Country: "England",
  },
  820: {
    Title: "",
    Initials: "DB",
    Surname: "Sutcliffe",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  821: {
    Title: "Mr",
    Initials: "H",
    Surname: "Sanders",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  822: {
    Title: "Mr",
    Initials: "ID",
    Surname: "Southerton",
    "Primary Club": "Chichester RC",
    County: "",
    Country: "",
  },
  823: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Stone",
    "Primary Club": "NLRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  824: {
    Title: "Mr",
    Initials: "JPM",
    Surname: "Stevens",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  825: {
    Title: "Mr",
    Initials: "R",
    Surname: "Shaw",
    "Primary Club": "Sheffield RC",
    County: "Derbyshire",
    Country: "England",
  },
  826: {
    Title: "",
    Initials: "WJN",
    Surname: "Sampson",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  827: {
    Title: "Dr",
    Initials: "GCD",
    Surname: "Barnett",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  828: {
    Title: "",
    Initials: "G",
    Surname: "Stewart",
    "Primary Club": "Dorking & District R.C",
    County: "Surrey",
    Country: "Scotland",
  },
  829: {
    Title: "Mrs",
    Initials: "E",
    Surname: "Smallwood",
    "Primary Club": "Pumas RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  830: {
    Title: "Brig",
    Initials: "AJW",
    Surname: "Sturrock",
    "Primary Club": "ATSC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  831: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Smith",
    "Primary Club": "Club 25",
    County: "Buckinghamshire",
    Country: "England",
  },
  832: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Benest",
    "Primary Club": "Old Victorians RC",
    County: "",
    Country: "Jersey",
  },
  833: {
    Title: "",
    Initials: "ST",
    Surname: "Seale",
    "Primary Club": "N.R.A",
    County: "",
    Country: "Germany",
  },
  834: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Archer",
    "Primary Club": "Pickering RP Club",
    County: "County Durham",
    Country: "England",
  },
  835: {
    Title: "Mr",
    Initials: "NRJ",
    Surname: "Brasier",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  836: {
    Title: "Mr",
    Initials: "CW",
    Surname: "Slator",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  837: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Stone",
    "Primary Club": "Leatherhead RC",
    County: "Surrey",
    Country: "England",
  },
  838: {
    Title: "Mr",
    Initials: "JSP",
    Surname: "Spender",
    "Primary Club": "Old Marlburian RC",
    County: "",
    Country: "England",
  },
  839: {
    Title: "",
    Initials: "TF",
    Surname: "Spencer",
    "Primary Club": "Old Johnian R.C",
    County: "Surrey",
    Country: "England",
  },
  840: {
    Title: "",
    Initials: "JS",
    Surname: "Smith",
    "Primary Club": "",
    County: "",
    Country: "Falkland Islands",
  },
  841: {
    Title: "Mr",
    Initials: "RK",
    Surname: "Sheead",
    "Primary Club": "City RC",
    County: "Hertfordshire",
    Country: "England",
  },
  842: {
    Title: "",
    Initials: "P",
    Surname: "Schumann",
    "Primary Club": "ATSC",
    County: "Derbyshire",
    Country: "England",
  },
  843: {
    Title: "Mrs",
    Initials: "LK",
    Surname: "Mace",
    "Primary Club": "Manydown RC",
    County: "Hampshire",
    Country: "England",
  },
  844: {
    Title: "Mr",
    Initials: "T",
    Surname: "Steele",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  845: {
    Title: "Mr",
    Initials: "R",
    Surname: "Stewart",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  846: {
    Title: "Wg Cdr",
    Initials: "CR",
    Surname: "Smith",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  847: {
    Title: "Mrs",
    Initials: "C",
    Surname: "Beecher",
    "Primary Club": "Stock Exchange RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  848: {
    Title: "Mr",
    Initials: "J",
    Surname: "Shanks",
    "Primary Club": "UK Police",
    County: "Devon",
    Country: "Ireland",
  },
  849: {
    Title: "Mr",
    Initials: "PBM",
    Surname: "Smith",
    "Primary Club": "Rand RC",
    County: "",
    Country: "South Africa",
  },
  850: {
    Title: "Mr",
    Initials: "B",
    Surname: "Taylor",
    "Primary Club": "ATSC",
    County: "Kent",
    Country: "England",
  },
  851: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Taylor",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  852: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Taylor",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  853: {
    Title: "Mr",
    Initials: "N",
    Surname: "Thomas",
    "Primary Club": "Civil Service RA",
    County: "Hertfordshire",
    Country: "",
  },
  854: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Thomas",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  855: {
    Title: "Mr",
    Initials: "GG",
    Surname: "Thompson",
    "Primary Club": "Sidcup",
    County: "Kent",
    Country: "England",
  },
  856: {
    Title: "Mr",
    Initials: "RBC",
    Surname: "Baker",
    "Primary Club": "Old Marlburian RC",
    County: "Kent",
    Country: "England",
  },
  857: {
    Title: "Mr",
    Initials: "JMA",
    Surname: "Thompson",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  858: {
    Title: "Mr",
    Initials: "WHA",
    Surname: "Thompson",
    "Primary Club": "WFRA Bromsgrove",
    County: "Worcestershire",
    Country: "England",
  },
  859: {
    Title: "Dr",
    Initials: "KD",
    Surname: "Thomson",
    "Primary Club": "NLRC",
    County: "Berkshire",
    Country: "Scotland",
  },
  860: {
    Title: "Mr",
    Initials: "ATB",
    Surname: "Tompson",
    "Primary Club": "Old Wellingburian",
    County: "Hertfordshire",
    Country: "England",
  },
  861: {
    Title: "Mr",
    Initials: "MK",
    Surname: "Townsend",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  862: {
    Title: "Mr",
    Initials: "CN",
    Surname: "Tremlett",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  863: {
    Title: "Mr",
    Initials: "KR",
    Surname: "Trowbridge",
    "Primary Club": "RAFTRC",
    County: "Lincolnshire",
    Country: "Ireland",
  },
  864: {
    Title: "",
    Initials: "A St G",
    Surname: "Tucker",
    "Primary Club": "Twickenham R.C",
    County: "Surrey",
    Country: "England",
  },
  865: {
    Title: "",
    Initials: "IT",
    Surname: "Tucker",
    "Primary Club": "Stock Exchange RC",
    County: "Essex",
    Country: "Scotland",
  },
  866: {
    Title: "",
    Initials: "R",
    Surname: "Turvey",
    "Primary Club": "Kidderminister R.C",
    County: "Worcestershire",
    Country: "England",
  },
  867: {
    Title: "Capt",
    Initials: "JI",
    Surname: "Tozer",
    "Primary Club": "ATSC",
    County: "Devon",
    Country: "England",
  },
  868: {
    Title: "Mr",
    Initials: "PR",
    Surname: "Thompson",
    "Primary Club": "NLRC",
    County: "Kent",
    Country: "England",
  },
  869: {
    Title: "Lt Cdr",
    Initials: "IR",
    Surname: "Thomson",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  870: {
    Title: "",
    Initials: "DR",
    Surname: "Taylor",
    "Primary Club": "Newport West R.C",
    County: "Monmouthshire",
    Country: "Wales",
  },
  871: {
    Title: "",
    Initials: "MR",
    Surname: "Townsend",
    "Primary Club": "NLRC",
    County: "",
    Country: "",
  },
  872: {
    Title: "",
    Initials: "W",
    Surname: "Tarr",
    "Primary Club": "Harare R.C",
    County: "",
    Country: "South Africa",
  },
  873: {
    Title: "",
    Initials: "PA",
    Surname: "Tonkin",
    "Primary Club": "LMRA",
    County: "Kent",
    Country: "England",
  },
  874: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Tuck",
    "Primary Club": "RNTRC",
    County: "Surrey",
    Country: "England",
  },
  875: {
    Title: "Mr",
    Initials: "T",
    Surname: "Brooking",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  876: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Thomson",
    "Primary Club": "West Suffolk RC",
    County: "Suffolk",
    Country: "England",
  },
  877: {
    Title: "Mr",
    Initials: "J",
    Surname: "Tuffen",
    "Primary Club": "LMRA",
    County: "Essex",
    Country: "England",
  },
  878: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Underwood",
    "Primary Club": "Old Guildfordians RC",
    County: "Surrey",
    Country: "England",
  },
  879: {
    Title: "Dr",
    Initials: "MWM",
    Surname: "Veltman-Grisenthwaite",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  880: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Verduyn",
    "Primary Club": "Welwyn Phoenix",
    County: "Hertfordshire",
    Country: "England",
  },
  881: {
    Title: "Mr",
    Initials: "TL",
    Surname: "Bedwell",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  882: {
    Title: "Mr",
    Initials: "AGH",
    Surname: "Van Koetsveld",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "England",
  },
  883: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Bridger",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  884: {
    Title: "",
    Initials: "RJ",
    Surname: "Van Lingen",
    "Primary Club": "S A Veterans",
    County: "Gauteng North",
    Country: "South Africa",
  },
  885: {
    Title: "",
    Initials: "RA",
    Surname: "Bailey",
    "Primary Club": "Ibis R.C",
    County: "",
    Country: "",
  },
  886: {
    Title: "",
    Initials: "HC",
    Surname: "Vanhee",
    "Primary Club": "IRC",
    County: "",
    Country: "",
  },
  887: {
    Title: "Mr",
    Initials: "R",
    Surname: "Winney",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  888: {
    Title: "Mr",
    Initials: "WP",
    Surname: "Wakefield",
    "Primary Club": "OBSS",
    County: "Oxfordshire",
    Country: "England",
  },
  889: {
    Title: "Mr",
    Initials: "P",
    Surname: "Wale",
    "Primary Club": "Lloyds TSBRC",
    County: "Essex",
    Country: "England",
  },
  890: {
    Title: "Dr",
    Initials: "C",
    Surname: "Wall",
    "Primary Club": "Ricochet RC",
    County: "",
    Country: "England",
  },
  891: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Warburton",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  892: {
    Title: "Mr",
    Initials: "HN",
    Surname: "Watson",
    "Primary Club": "RNTRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  893: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Barlow",
    "Primary Club": "Blue Circle Swanscombe RC",
    County: "",
    Country: "",
  },
  894: {
    Title: "",
    Initials: "CAW",
    Surname: "Webb",
    "Primary Club": "N.R.A",
    County: "Sussex",
    Country: "England",
  },
  895: {
    Title: "Mr",
    Initials: "OK",
    Surname: "Weber",
    "Primary Club": "City RC",
    County: "",
    Country: "",
  },
  896: {
    Title: "Mr",
    Initials: "JGM",
    Surname: "Webster",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  897: {
    Title: "Mr",
    Initials: "TR",
    Surname: "Webster",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  898: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Welford",
    "Primary Club": "OCRA",
    County: "London",
    Country: "England",
  },
  899: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Welsh",
    "Primary Club": "East Bristol RC",
    County: "Gloucestershire",
    Country: "England",
  },
  900: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Weston",
    "Primary Club": "Kidderminster RC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  901: {
    Title: "Mr",
    Initials: "R",
    Surname: "Whitby",
    "Primary Club": "Hopton Supper Club",
    County: "",
    Country: "England",
  },
  902: {
    Title: "Mr",
    Initials: "CA",
    Surname: "White",
    "Primary Club": "Altcar RC",
    County: "County Durham",
    Country: "England",
  },
  903: {
    Title: "Mr",
    Initials: "JE",
    Surname: "White",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "West Indies",
  },
  904: {
    Title: "Mr",
    Initials: "RB",
    Surname: "White",
    "Primary Club": "RNTRC",
    County: "Cornwall",
    Country: "England",
  },
  905: {
    Title: "Mr",
    Initials: "K",
    Surname: "Whitecross",
    "Primary Club": "Welsh RA",
    County: "Glamorgan",
    Country: "Wales",
  },
  906: {
    Title: "Mr",
    Initials: "LT",
    Surname: "Wicker",
    "Primary Club": "Hailsham RC",
    County: "Sussex",
    Country: "England",
  },
  907: {
    Title: "Miss",
    Initials: "ME",
    Surname: "Wicker",
    "Primary Club": "Hailsham RC",
    County: "Kent",
    Country: "England",
  },
  908: {
    Title: "Mr",
    Initials: "DL",
    Surname: "Wiles",
    "Primary Club": "Sheffield RC",
    County: "Yorkshire",
    Country: "England",
  },
  909: {
    Title: "",
    Initials: "CW",
    Surname: "Williams",
    "Primary Club": "Cornwall R.C",
    County: "Cornwall",
    Country: "England",
  },
  910: {
    Title: "Mr",
    Initials: "GA",
    Surname: "Williamson",
    "Primary Club": "Midland Counties RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  911: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Wilson",
    "Primary Club": "",
    County: "",
    Country: "Wales",
  },
  912: {
    Title: "",
    Initials: "TL",
    Surname: "Wilson",
    "Primary Club": "Rainham & District",
    County: "",
    Country: "",
  },
  913: {
    Title: "Mr",
    Initials: "A",
    Surname: "Wombell",
    "Primary Club": "RNTRC",
    County: "Sussex",
    Country: "England",
  },
  914: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Wood",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  915: {
    Title: "Mr",
    Initials: "JDR",
    Surname: "Walker",
    "Primary Club": "Nottingham City RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  916: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Wood",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "",
  },
  917: {
    Title: "Mr",
    Initials: "HRM",
    Surname: "Bailie",
    "Primary Club": "Irish Rifle Club",
    County: "Ulster",
    Country: "Ireland",
  },
  918: {
    Title: "Dr",
    Initials: "R",
    Surname: "Woodside",
    "Primary Club": "RNTRC",
    County: "Dorset",
    Country: "England",
  },
  919: {
    Title: "",
    Initials: "PF",
    Surname: "Wookey",
    "Primary Club": "N.L.R.C.",
    County: "",
    Country: "",
  },
  920: {
    Title: "Mr",
    Initials: "JO",
    Surname: "Wright",
    "Primary Club": "NRA",
    County: "County of London",
    Country: "England",
  },
  921: {
    Title: "",
    Initials: "RP",
    Surname: "Wilson",
    "Primary Club": "Ulster R.A",
    County: "Ulster",
    Country: "Ireland",
  },
  922: {
    Title: "Mr",
    Initials: "BT",
    Surname: "Wood",
    "Primary Club": "NRA",
    County: "Yorkshire",
    Country: "",
  },
  923: {
    Title: "Mr",
    Initials: "SMC",
    Surname: "Wilson",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  924: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Whatton",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  925: {
    Title: "Mr",
    Initials: "KM",
    Surname: "Willingale",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  926: {
    Title: "Ms",
    Initials: "RA",
    Surname: "Wiltshire",
    "Primary Club": "Old Epsomian RC",
    County: "Yorkshire",
    Country: "Scotland",
  },
  927: {
    Title: "Mr",
    Initials: "SC",
    Surname: "Williamson",
    "Primary Club": "RAFTRC",
    County: "Sussex",
    Country: "England",
  },
  928: {
    Title: "Mr",
    Initials: "CP",
    Surname: "Weeden",
    "Primary Club": "NLRC",
    County: "Kent",
    Country: "England",
  },
  929: {
    Title: "Dr",
    Initials: "MW",
    Surname: "Wrigley",
    "Primary Club": "NLRC",
    County: "Middlesex",
    Country: "Scotland",
  },
  930: {
    Title: "Mr",
    Initials: "GS",
    Surname: "Winfield",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  931: {
    Title: "Miss",
    Initials: "VSD",
    Surname: "Barber",
    "Primary Club": "O.G.R.E",
    County: "Norfolk",
    Country: "",
  },
  932: {
    Title: "",
    Initials: "DJE",
    Surname: "Beer",
    "Primary Club": "Old Epsomians",
    County: "",
    Country: "",
  },
  933: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Watson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  934: {
    Title: "",
    Initials: "KM",
    Surname: "Wilson",
    "Primary Club": "Chepstow R.C",
    County: "Gloucestershire",
    Country: "Wales",
  },
  935: {
    Title: "",
    Initials: "GB",
    Surname: "Walker",
    "Primary Club": "UK Police",
    County: "Devon",
    Country: "Wales",
  },
  936: {
    Title: "Major",
    Initials: "S",
    Surname: "Armour",
    "Primary Club": "N.L.R.C.",
    County: "Suffolk",
    Country: "England",
  },
  937: {
    Title: "",
    Initials: "J",
    Surname: "Wulfraat",
    "Primary Club": "K.S.V Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  938: {
    Title: "",
    Initials: "SG",
    Surname: "Broughton",
    "Primary Club": "West Suffolk R.C",
    County: "Suffolk",
    Country: "England",
  },
  939: {
    Title: "",
    Initials: "S",
    Surname: "Wiktorowski",
    "Primary Club": "PPRA",
    County: "Derbyshire",
    Country: "Canada",
  },
  940: {
    Title: "Mr",
    Initials: "BF",
    Surname: "Witts",
    "Primary Club": "RNTRC",
    County: "Hampshire",
    Country: "England",
  },
  941: {
    Title: "Dr",
    Initials: "EH",
    Surname: "Walker",
    "Primary Club": "Artists",
    County: "",
    Country: "",
  },
  942: {
    Title: "Colonel",
    Initials: "JR",
    Surname: "Bradley",
    "Primary Club": "A.T.S.C",
    County: "",
    Country: "",
  },
  943: {
    Title: "",
    Initials: "JH",
    Surname: "Winn",
    "Primary Club": "N.L.R.C.",
    County: "Ulster",
    Country: "Ireland",
  },
  944: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Barry",
    "Primary Club": "Stock Exchange RC",
    County: "Norfolk",
    Country: "Ireland",
  },
  945: {
    Title: "Mr",
    Initials: "EC",
    Surname: "Wood",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  946: {
    Title: "Mr",
    Initials: "W",
    Surname: "Deschacht",
    "Primary Club": "UFAC Belgium",
    County: "",
    Country: "Belgium",
  },
  947: {
    Title: "",
    Initials: "PD",
    Surname: "Wheelen",
    "Primary Club": "Bromsgrove R.C",
    County: "Hereford & Worcs",
    Country: "",
  },
  948: {
    Title: "",
    Initials: "MGB",
    Surname: "Wilson",
    "Primary Club": "O.G.R.E",
    County: "Norfolk",
    Country: "England",
  },
  949: {
    Title: "",
    Initials: "P",
    Surname: "Yeomans",
    "Primary Club": "Cornwall R.C",
    County: "Cornwall",
    Country: "England",
  },
  950: {
    Title: "",
    Initials: "SE",
    Surname: "Yeomans",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "Wales",
  },
  951: {
    Title: "Mr",
    Initials: "D",
    Surname: "Young",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  952: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Carson",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  953: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "OLeary",
    "Primary Club": "Manydown RC",
    County: "Hampshire",
    Country: "England",
  },
  954: {
    Title: "Mr",
    Initials: "DJH",
    Surname: "Birrell",
    "Primary Club": "Old Glenalmond RC",
    County: "Oxfordshire",
    Country: "Scotland",
  },
  955: {
    Title: "Mr",
    Initials: "J",
    Surname: "Leys",
    "Primary Club": "UFAC Belgium",
    County: "",
    Country: "Belgium",
  },
  956: {
    Title: "Mr",
    Initials: "GM",
    Surname: "Martens",
    "Primary Club": "UFAC Belgium",
    County: "",
    Country: "Belgium",
  },
  957: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Lygoe",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  958: {
    Title: "Dr",
    Initials: "PJ",
    Surname: "Morris",
    "Primary Club": "LMRA",
    County: "Cambridgeshire",
    Country: "Wales",
  },
  959: {
    Title: "Mr",
    Initials: "T",
    Surname: "Bliss",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  960: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Woolger",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  961: {
    Title: "Mr",
    Initials: "PM",
    Surname: "de Voil",
    "Primary Club": "OO Troubleshooters",
    County: "Leicestershire",
    Country: "England",
  },
  962: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Ball",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  963: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Amy",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  964: {
    Title: "Mr",
    Initials: "JMS",
    Surname: "Samson",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  965: {
    Title: "M",
    Initials: "AMG",
    Surname: "Fortune",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  966: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Brook",
    "Primary Club": "LMRA",
    County: "Wiltshire",
    Country: "England",
  },
  967: {
    Title: "Mr",
    Initials: "GE",
    Surname: "Morris",
    "Primary Club": "Uppingham Veterans RC",
    County: "Cambridgeshire",
    Country: "Wales",
  },
  968: {
    Title: "Mr",
    Initials: "WRD",
    Surname: "Mott",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  969: {
    Title: "Mrs",
    Initials: "SJ",
    Surname: "Binder - Swash",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  970: {
    Title: "SSGT",
    Initials: "RH",
    Surname: "Kitson",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  971: {
    Title: "Mr",
    Initials: "J",
    Surname: "de Bruijn",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  972: {
    Title: "Mr",
    Initials: "B",
    Surname: "Horwood",
    "Primary Club": "Invercargill",
    County: "Jersey",
    Country: "Jersey",
  },
  973: {
    Title: "",
    Initials: "L",
    Surname: "D'Amour",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  974: {
    Title: "Mrs",
    Initials: "P",
    Surname: "Dahlstrom",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  975: {
    Title: "",
    Initials: "C",
    Surname: "Dahlstrom",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  976: {
    Title: "Mr",
    Initials: "PJR",
    Surname: "Holden",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  977: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Glen",
    "Primary Club": "West Atholl RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  978: {
    Title: "",
    Initials: "R",
    Surname: "Woodley",
    "Primary Club": "Langar",
    County: "Nottinghamshire",
    Country: "England",
  },
  979: {
    Title: "",
    Initials: "RM",
    Surname: "Brough",
    "Primary Club": "Old Guildfordians R.C",
    County: "",
    Country: "",
  },
  980: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Haward",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  981: {
    Title: "Mr",
    Initials: "IDC",
    Surname: "Brechin",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  982: {
    Title: "",
    Initials: "PJ",
    Surname: "Pritchard",
    "Primary Club": "Somerset RA",
    County: "Somerset",
    Country: "England",
  },
  983: {
    Title: "",
    Initials: "HW",
    Surname: "Kilpatrick",
    "Primary Club": "East of Scotland R.C",
    County: "East of Scotland",
    Country: "Scotland",
  },
  984: {
    Title: "Mr",
    Initials: "SP",
    Surname: "Harding",
    "Primary Club": "Surrey RA",
    County: "Kent",
    Country: "England",
  },
  985: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Patel",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  986: {
    Title: "Mr",
    Initials: "F",
    Surname: "Bilton",
    "Primary Club": "West Suffolk RC",
    County: "Suffolk",
    Country: "England",
  },
  987: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Mackay",
    "Primary Club": "RNTRC",
    County: "Bedfordshire",
    Country: "Scotland",
  },
  988: {
    Title: "Mr",
    Initials: "P",
    Surname: "Monaghan",
    "Primary Club": "Central Tasmania RC",
    County: "",
    Country: "Australia",
  },
  989: {
    Title: "Flt Lt",
    Initials: "B",
    Surname: "Ashton",
    "Primary Club": "RAFTRC",
    County: "Middlesex",
    Country: "England",
  },
  990: {
    Title: "",
    Initials: "RD",
    Surname: "Bennison",
    "Primary Club": "Barclays Bank R.C",
    County: "Surrey",
    Country: "England",
  },
  991: {
    Title: "Mrs",
    Initials: "JA",
    Surname: "Cane",
    "Primary Club": "Old Guildfordians",
    County: "Essex",
    Country: "England",
  },
  992: {
    Title: "Dr",
    Initials: "SS",
    Surname: "Horner",
    "Primary Club": "Old Marlburian RC",
    County: "Dorset",
    Country: "England",
  },
  993: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Eayrs",
    "Primary Club": "Dursley",
    County: "Gloucestershire",
    Country: "England",
  },
  994: {
    Title: "Miss",
    Initials: "DS",
    Surname: "Hume",
    "Primary Club": "NLRC",
    County: "",
    Country: "Scotland",
  },
  995: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Bullock",
    "Primary Club": "Langar RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  996: {
    Title: "",
    Initials: "MP",
    Surname: "Chorlton",
    "Primary Club": "101 R.C",
    County: "Lancaster",
    Country: "England",
  },
  997: {
    Title: "",
    Initials: "R",
    Surname: "Barnard",
    "Primary Club": "Midland Counties R.C",
    County: "Warwickshire",
    Country: "England",
  },
  998: {
    Title: "Dr",
    Initials: "CCA",
    Surname: "Roud-Mayne",
    "Primary Club": "N.L.R.C.",
    County: "Bedfordshire",
    Country: "England",
  },
  999: {
    Title: "Mr",
    Initials: "A",
    Surname: "McLeod",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1000: {
    Title: "Mrs",
    Initials: "C",
    Surname: "Goberville",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1001: {
    Title: "Miss",
    Initials: "A",
    Surname: "Ogilvie",
    "Primary Club": "NLRC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1002: {
    Title: "Mr",
    Initials: "AS",
    Surname: "Errey",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  1003: {
    Title: "",
    Initials: "AD",
    Surname: "Lewis",
    "Primary Club": "Stock Exchange RC",
    County: "County of London",
    Country: "Wales",
  },
  1004: {
    Title: "Mr",
    Initials: "ID",
    Surname: "Markwell",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  1005: {
    Title: "",
    Initials: "AGF",
    Surname: "Hill",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  1006: {
    Title: "Mr",
    Initials: "TAW",
    Surname: "Walters",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1007: {
    Title: "",
    Initials: "WB",
    Surname: "Loan",
    "Primary Club": "Comber R.C",
    County: "Ulster",
    Country: "Ireland",
  },
  1008: {
    Title: "Mr",
    Initials: "AS",
    Surname: "Fraser",
    "Primary Club": "ATRC",
    County: "Somerset",
    Country: "England",
  },
  1009: {
    Title: "Mr",
    Initials: "EJH",
    Surname: "Pullen",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1010: {
    Title: "Mr",
    Initials: "SJV",
    Surname: "Osmond",
    "Primary Club": "Manchester RC",
    County: "Lincolnshire",
    Country: "England",
  },
  1011: {
    Title: "Mr",
    Initials: "AF",
    Surname: "Aspin",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  1012: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Bissonnette",
    "Primary Club": "NCRRA",
    County: "",
    Country: "Canada",
  },
  1013: {
    Title: "Mrs",
    Initials: "A-M",
    Surname: "Logan",
    "Primary Club": "Wandsworth FBRC",
    County: "Norfolk",
    Country: "England",
  },
  1014: {
    Title: "Mr",
    Initials: "BA",
    Surname: "Young",
    "Primary Club": "Wandsworth FBRC",
    County: "Lancashire",
    Country: "England",
  },
  1015: {
    Title: "",
    Initials: "DS",
    Surname: "Skippen",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1016: {
    Title: "Mr",
    Initials: "HTH",
    Surname: "Hodges",
    "Primary Club": "Kent",
    County: "Kent",
    Country: "England",
  },
  1017: {
    Title: "Maj",
    Initials: "AG",
    Surname: "McLean",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1018: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Atkinson",
    "Primary Club": "Dorking & District RC",
    County: "Surrey",
    Country: "England",
  },
  1019: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Capell",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1020: {
    Title: "Miss",
    Initials: "MZJ",
    Surname: "Boston",
    "Primary Club": "OGRE",
    County: "Lincolnshire",
    Country: "England",
  },
  1021: {
    Title: "Mr",
    Initials: "MCJ",
    Surname: "Button",
    "Primary Club": "Wandsworth FBRC",
    County: "Lincolnshire",
    Country: "England",
  },
  1022: {
    Title: "Mr",
    Initials: "J",
    Surname: "Munnion",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  1023: {
    Title: "Mr",
    Initials: "M",
    Surname: "Wentges",
    "Primary Club": "Irish Rifle Club",
    County: "",
    Country: "Ireland",
  },
  1024: {
    Title: "Mr",
    Initials: "RJH",
    Surname: "Birtwistle",
    "Primary Club": "Manydown RC",
    County: "Hampshire",
    Country: "England",
  },
  1025: {
    Title: "",
    Initials: "MP",
    Surname: "Workman",
    "Primary Club": "Dursley",
    County: "Gloucestershire",
    Country: "England",
  },
  1026: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Speirs",
    "Primary Club": "Newcastle RC",
    County: "Northumberland",
    Country: "England",
  },
  1027: {
    Title: "",
    Initials: "DJ",
    Surname: "Sax",
    "Primary Club": "Artists",
    County: "Sussex",
    Country: "England",
  },
  1028: {
    Title: "Mr",
    Initials: "T",
    Surname: "Horrocks",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  1029: {
    Title: "",
    Initials: "MR",
    Surname: "Vernon-Stroud",
    "Primary Club": "St Andrews Uni. R.C",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1030: {
    Title: "Miss",
    Initials: "V",
    Surname: "Tracey",
    "Primary Club": "Ibis R.C",
    County: "Buckinghamshire",
    Country: "Scotland",
  },
  1031: {
    Title: "Mr",
    Initials: "T",
    Surname: "Horsfield",
    "Primary Club": "Ross-on-Wye",
    County: "Hereford & Worcs",
    Country: "England",
  },
  1032: {
    Title: "Mr",
    Initials: "T",
    Surname: "Ginestet",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1033: {
    Title: "",
    Initials: "G",
    Surname: "Bohme",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1034: {
    Title: "",
    Initials: "AMC",
    Surname: "Baker",
    "Primary Club": "N.L.R.C.",
    County: "Cheshire",
    Country: "England",
  },
  1035: {
    Title: "Mr",
    Initials: "NS",
    Surname: "Roberts",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  1036: {
    Title: "",
    Initials: "RHPA",
    Surname: "Butcher",
    "Primary Club": "Artists",
    County: "Wiltshire",
    Country: "England",
  },
  1037: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Ayres",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  1038: {
    Title: "",
    Initials: "AF",
    Surname: "Baudains",
    "Primary Club": "Jersey R.C",
    County: "Jersey",
    Country: "Jersey",
  },
  1039: {
    Title: "",
    Initials: "N",
    Surname: "Baker",
    "Primary Club": "Club 25",
    County: "Berkshire",
    Country: "England",
  },
  1040: {
    Title: "",
    Initials: "JM",
    Surname: "Buchanan",
    "Primary Club": "Roseville NSW Aust.",
    County: "NSW",
    Country: "Australia",
  },
  1041: {
    Title: "Mr",
    Initials: "M",
    Surname: "Ayling",
    "Primary Club": "RNTRC",
    County: "Sussex",
    Country: "England",
  },
  1042: {
    Title: "",
    Initials: "A",
    Surname: "Bellingan",
    "Primary Club": "Stock Exchange RC",
    County: "",
    Country: "",
  },
  1043: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Cade",
    "Primary Club": "NLRC",
    County: "Hertfordshire",
    Country: "England",
  },
  1044: {
    Title: "",
    Initials: "SA",
    Surname: "Calder",
    "Primary Club": "N.L.R.C.",
    County: "Sussex",
    Country: "",
  },
  1045: {
    Title: "Wg Cdr",
    Initials: "D",
    Surname: "Calvert",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1046: {
    Title: "",
    Initials: "A",
    Surname: "Whitington",
    "Primary Club": "Ledbury",
    County: "Gloucestershire",
    Country: "England",
  },
  1047: {
    Title: "Mr",
    Initials: "BV",
    Surname: "Cambray",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1048: {
    Title: "",
    Initials: "B",
    Surname: "Rushworth",
    "Primary Club": "Artists",
    County: "",
    Country: "",
  },
  1049: {
    Title: "Dr",
    Initials: "JS",
    Surname: "Rennie",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1050: {
    Title: "",
    Initials: "JFW",
    Surname: "Smith",
    "Primary Club": "",
    County: "Surrey",
    Country: "England",
  },
  1051: {
    Title: "Dr",
    Initials: "PJ",
    Surname: "Rae",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "Scotland",
  },
  1052: {
    Title: "Mr",
    Initials: "FJ",
    Surname: "Carlin",
    "Primary Club": "RAFTRC",
    County: "Sussex",
    Country: "Ireland",
  },
  1053: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Carmichael",
    "Primary Club": "NRA",
    County: "Surrey",
    Country: "England",
  },
  1054: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Hatfield",
    "Primary Club": "NRA",
    County: "Nottinghamshire",
    Country: "England",
  },
  1055: {
    Title: "Mr",
    Initials: "AA",
    Surname: "Williams",
    "Primary Club": "NRA",
    County: "Cambridgeshire",
    Country: "England",
  },
  1056: {
    Title: "",
    Initials: "VC",
    Surname: "Carpenter",
    "Primary Club": "Lloyds TSBRC",
    County: "Devon",
    Country: "United Kingdom",
  },
  1057: {
    Title: "Miss",
    Initials: "DL",
    Surname: "Fenn",
    "Primary Club": "NLRC",
    County: "Suffolk",
    Country: "England",
  },
  1058: {
    Title: "Dr",
    Initials: "JW",
    Surname: "Dyas",
    "Primary Club": "ATSC",
    County: "Gloucestershire",
    Country: "England",
  },
  1059: {
    Title: "",
    Initials: "CJ",
    Surname: "McCallum",
    "Primary Club": "Falkland Islands RA",
    County: "",
    Country: "Falkland Islands",
  },
  1060: {
    Title: "",
    Initials: "SJ",
    Surname: "Carter",
    "Primary Club": "RNRRA",
    County: "Sussex",
    Country: "England",
  },
  1061: {
    Title: "",
    Initials: "JT",
    Surname: "Elliott",
    "Primary Club": "Aberdeen University R.C",
    County: "Aberdeenshire",
    Country: "",
  },
  1062: {
    Title: "",
    Initials: "TJ",
    Surname: "Coleridge",
    "Primary Club": "Stock Exchange RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  1063: {
    Title: "Mrs",
    Initials: "DL",
    Surname: "Park",
    "Primary Club": "OGRE",
    County: "Suffolk",
    Country: "England",
  },
  1064: {
    Title: "",
    Initials: "JY",
    Surname: "O'Leary",
    "Primary Club": "W.R.A",
    County: "",
    Country: "Wales",
  },
  1065: {
    Title: "",
    Initials: "L",
    Surname: "Chadderton",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  1066: {
    Title: "Mrs",
    Initials: "SL",
    Surname: "Jones",
    "Primary Club": "N.R.A",
    County: "",
    Country: "",
  },
  1067: {
    Title: "Cdt",
    Initials: "W",
    Surname: "Hyde Parker",
    "Primary Club": "Gresham's R.C",
    County: "Suffolk",
    Country: "England",
  },
  1068: {
    Title: "Mr",
    Initials: "G",
    Surname: "Williams",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "Wales",
  },
  1069: {
    Title: "Cdt",
    Initials: "GE",
    Surname: "Haines",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1070: {
    Title: "",
    Initials: "RA",
    Surname: "Lee",
    "Primary Club": "N.L.R.C.",
    County: "Norfolk",
    Country: "England",
  },
  1071: {
    Title: "Cdt",
    Initials: "RS",
    Surname: "Moore",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1072: {
    Title: "Mr",
    Initials: "P",
    Surname: "Delforno",
    "Primary Club": "City of Newcastle RC",
    County: "County Durham",
    Country: "England",
  },
  1073: {
    Title: "",
    Initials: "P",
    Surname: "Bernard-Carter",
    "Primary Club": "Old Wellingtonians",
    County: "Berkshire",
    Country: "England",
  },
  1074: {
    Title: "",
    Initials: "AJ",
    Surname: "Brockhurst",
    "Primary Club": "Northants - Leics - Rutland RC",
    County: "Northants - Leics - Rutland & Leicestershir",
    Country: "England",
  },
  1075: {
    Title: "",
    Initials: "KE",
    Surname: "Chard",
    "Primary Club": "City RC",
    County: "Devon",
    Country: "England",
  },
  1076: {
    Title: "Mr",
    Initials: "TR",
    Surname: "Harrison",
    "Primary Club": "Old Malvernians",
    County: "",
    Country: "England",
  },
  1077: {
    Title: "",
    Initials: "TE",
    Surname: "Green",
    "Primary Club": "Concord R.C",
    County: "Sydney",
    Country: "Australia",
  },
  1078: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Cann",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1079: {
    Title: "Wg Cdr",
    Initials: "IA",
    Surname: "Mollan",
    "Primary Club": "RAF Air Cmd",
    County: "Co Down",
    Country: "",
  },
  1080: {
    Title: "Mr",
    Initials: "GC",
    Surname: "Childs",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1081: {
    Title: "Mr",
    Initials: "GC",
    Surname: "Childs",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1082: {
    Title: "Mr",
    Initials: "A",
    Surname: "Chown",
    "Primary Club": "Lloyds TSBRC",
    County: "Surrey",
    Country: "England",
  },
  1083: {
    Title: "",
    Initials: "AJ",
    Surname: "Clark",
    "Primary Club": "R.N.T.R.C",
    County: "Hampshire",
    Country: "England",
  },
  1084: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Clark",
    "Primary Club": "LMRA",
    County: "Hampshire",
    Country: "England",
  },
  1085: {
    Title: "",
    Initials: "AE",
    Surname: "Clarke",
    "Primary Club": "A.T.R.C.",
    County: "Hampshire",
    Country: "Scotland",
  },
  1086: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Clarke",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1087: {
    Title: "Mr",
    Initials: "GL",
    Surname: "Clarke",
    "Primary Club": "Darlington R & PC",
    County: "County of Durham",
    Country: "England",
  },
  1088: {
    Title: "Mr",
    Initials: "TP",
    Surname: "Clarke",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1089: {
    Title: "Mr",
    Initials: "DP",
    Surname: "Cole",
    "Primary Club": "RAFTRC",
    County: "Hampshire",
    Country: "England",
  },
  1090: {
    Title: "Mr",
    Initials: "N",
    Surname: "Cole-Hawkins",
    "Primary Club": "NLRC",
    County: "London",
    Country: "England",
  },
  1091: {
    Title: "",
    Initials: "H",
    Surname: "Annand",
    "Primary Club": "Leeds Service R.C",
    County: "Yorkshire",
    Country: "England",
  },
  1092: {
    Title: "Mr",
    Initials: "PR",
    Surname: "Coley",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1093: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Collings",
    "Primary Club": "",
    County: "NSW",
    Country: "Australia",
  },
  1094: {
    Title: "",
    Initials: "PJ",
    Surname: "Arrowsmith",
    "Primary Club": "N.R.A",
    County: "Surrey",
    Country: "England",
  },
  1095: {
    Title: "",
    Initials: "RD",
    Surname: "Comins",
    "Primary Club": "Huddersfield R.C",
    County: "Yorkshire",
    Country: "England",
  },
  1096: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Comins",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  1097: {
    Title: "",
    Initials: "MI",
    Surname: "Cook",
    "Primary Club": "N.R.A",
    County: "Devon",
    Country: "England",
  },
  1098: {
    Title: "Col",
    Initials: "D",
    Surname: "Cooper",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Hampshire",
    Country: "England",
  },
  1100: {
    Title: "Mr",
    Initials: "G",
    Surname: "Cox",
    "Primary Club": "RAFTRC",
    County: "Suffolk",
    Country: "England",
  },
  1101: {
    Title: "Mr",
    Initials: "PAE",
    Surname: "Charlton",
    "Primary Club": "Stock Exchange RC",
    County: "",
    Country: "Ireland",
  },
  1102: {
    Title: "",
    Initials: "DS",
    Surname: "Crisford",
    "Primary Club": "Ibis R.C",
    County: "Kent",
    Country: "England",
  },
  1103: {
    Title: "Mr",
    Initials: "MT",
    Surname: "Critchell",
    "Primary Club": "Chichester RC",
    County: "Sussex",
    Country: "England",
  },
  1104: {
    Title: "Mr",
    Initials: "BE",
    Surname: "Cudby",
    "Primary Club": "Stock Exchange RC",
    County: "County of London",
    Country: "England",
  },
  1105: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Cullimore",
    "Primary Club": "LMRA",
    County: "Buckinghamshire",
    Country: "England",
  },
  1106: {
    Title: "Mr",
    Initials: "RM",
    Surname: "Culverwell",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  1107: {
    Title: "Mr",
    Initials: "DB",
    Surname: "Cumming",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  1108: {
    Title: "",
    Initials: "IL",
    Surname: "Cummings",
    "Primary Club": "Horsham R. & P. C.",
    County: "Sussex",
    Country: "England",
  },
  1109: {
    Title: "",
    Initials: "CWA",
    Surname: "Cunningham",
    "Primary Club": "N.L.R.C.",
    County: "Hampshire",
    Country: "England",
  },
  1110: {
    Title: "Mr",
    Initials: "RJS",
    Surname: "Curtis",
    "Primary Club": "Uppingham Veterans RC",
    County: "Herefordshire",
    Country: "England",
  },
  1111: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Custance Baker",
    "Primary Club": "Old Marlburian RC",
    County: "Wiltshire",
    Country: "England",
  },
  1112: {
    Title: "",
    Initials: "IH",
    Surname: "Czapiewski",
    "Primary Club": "Ibis R.C",
    County: "Gloucestershire",
    Country: "England",
  },
  1113: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Crispin",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  1114: {
    Title: "Mr",
    Initials: "RI",
    Surname: "Coventry",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  1115: {
    Title: "Mr",
    Initials: "DMJ",
    Surname: "Coutts",
    "Primary Club": "Wandsworth FBRC",
    County: "Norfolk",
    Country: "England",
  },
  1116: {
    Title: "Mr",
    Initials: "GA",
    Surname: "Cann",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1117: {
    Title: "Mr",
    Initials: "BW",
    Surname: "Cairns",
    "Primary Club": "Old Haberdashers",
    County: "Hertfordshire",
    Country: "England",
  },
  1118: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Cosway",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1119: {
    Title: "Mr",
    Initials: "RJSG",
    Surname: "Clark",
    "Primary Club": "RAFTRC",
    County: "Buckinghamshire",
    Country: "England",
  },
  1120: {
    Title: "Mrs",
    Initials: "PA",
    Surname: "Cudby",
    "Primary Club": "Stock Exchange RC",
    County: "County of London",
    Country: "Ireland",
  },
  1121: {
    Title: "Major",
    Initials: "RJ",
    Surname: "Aitken",
    "Primary Club": "East of Scotland RC",
    County: "",
    Country: "England",
  },
  1122: {
    Title: "",
    Initials: "IF",
    Surname: "Coventry",
    "Primary Club": "Manchester RC",
    County: "",
    Country: "",
  },
  1123: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Corner",
    "Primary Club": "West Suffolk RC",
    County: "Suffolk",
    Country: "",
  },
  1124: {
    Title: "Mr",
    Initials: "DR",
    Surname: "Armstrong",
    "Primary Club": "Old Guildfordians RC",
    County: "London",
    Country: "England",
  },
  1125: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Coffin",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1126: {
    Title: "",
    Initials: "N",
    Surname: "Claus",
    "Primary Club": "IRC",
    County: "",
    Country: "Netherlands",
  },
  1127: {
    Title: "Mr",
    Initials: "SH",
    Surname: "Cox",
    "Primary Club": "ATSC",
    County: "Gloucestershire",
    Country: "England",
  },
  1128: {
    Title: "Major",
    Initials: "PDE",
    Surname: "Chase",
    "Primary Club": "N.L.R.C.",
    County: "",
    Country: "Barbados",
  },
  1129: {
    Title: "Mr",
    Initials: "R de V",
    Surname: "Chase",
    "Primary Club": "NLRC",
    County: "Hertfordshire",
    Country: "",
  },
  1130: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Chalker",
    "Primary Club": "Guernsey RC",
    County: "",
    Country: "Guernsey",
  },
  1131: {
    Title: "",
    Initials: "PAR",
    Surname: "Chapman",
    "Primary Club": "A.T.S.C",
    County: "Sussex",
    Country: "England",
  },
  1132: {
    Title: "",
    Initials: "GKJ",
    Surname: "Chapman",
    "Primary Club": "South London R.C",
    County: "Dorset",
    Country: "England",
  },
  1133: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Chorlton",
    "Primary Club": "101 RC",
    County: "Lancashire",
    Country: "England",
  },
  1134: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Cane",
    "Primary Club": "Old Guildfordians RC",
    County: "Wiltshire",
    Country: "England",
  },
  1135: {
    Title: "Dr",
    Initials: "AMW",
    Surname: "Cargill Thompson",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1136: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Cross",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  1137: {
    Title: "Mr",
    Initials: "JRD",
    Surname: "Clegg",
    "Primary Club": "Wandsworth FBRC",
    County: "Lancashire",
    Country: "England",
  },
  1138: {
    Title: "Cpl",
    Initials: "NM",
    Surname: "Atkinson",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1139: {
    Title: "Cdt",
    Initials: "DJG",
    Surname: "Berkeley",
    "Primary Club": "Wellington College",
    County: "Surrey",
    Country: "England",
  },
  1140: {
    Title: "Mr",
    Initials: "BE",
    Surname: "Bullard",
    "Primary Club": "Uppingham Veterans RC",
    County: "",
    Country: "England",
  },
  1141: {
    Title: "Sgt",
    Initials: "S",
    Surname: "Burke",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1142: {
    Title: "L/Cpl",
    Initials: "JDN",
    Surname: "Davidson",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  1143: {
    Title: "Dr",
    Initials: "M",
    Surname: "Davidson",
    "Primary Club": "N.L.R.C.",
    County: "",
    Country: "",
  },
  1144: {
    Title: "CdtCpl",
    Initials: "A",
    Surname: "Ferries",
    "Primary Club": "607(Nearmouth)Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  1145: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Freer",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1146: {
    Title: "Mr",
    Initials: "GK",
    Surname: "Goodwin",
    "Primary Club": "Falkland Islands RA",
    County: "",
    Country: "Falkland Islands",
  },
  1147: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Gregory",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1148: {
    Title: "LCpl",
    Initials: "MD",
    Surname: "Groves",
    "Primary Club": "1(WFR)Coy Herf'd&WorcsACF",
    County: "",
    Country: "",
  },
  1149: {
    Title: "OCdt",
    Initials: "GM",
    Surname: "Hillier",
    "Primary Club": "University of London",
    County: "Shropshire",
    Country: "Scotland",
  },
  1150: {
    Title: "Cdt",
    Initials: "SH",
    Surname: "Horiu Chi",
    "Primary Club": "Wellington College",
    County: "",
    Country: "Japan",
  },
  1152: {
    Title: "",
    Initials: "EH",
    Surname: "Jenkins",
    "Primary Club": "Edinburgh University R.C",
    County: "",
    Country: "",
  },
  1153: {
    Title: "Cpl",
    Initials: "G",
    Surname: "Jones",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  1154: {
    Title: "",
    Initials: "M",
    Surname: "Junor",
    "Primary Club": "Glenalmond College",
    County: "",
    Country: "",
  },
  1155: {
    Title: "Cdt",
    Initials: "SS",
    Surname: "Lean",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  1156: {
    Title: "Cdt",
    Initials: "IM",
    Surname: "Madison",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1157: {
    Title: "Miss",
    Initials: "CE",
    Surname: "Marwood",
    "Primary Club": "Newcastle Uni RC",
    County: "Northumberland",
    Country: "England",
  },
  1158: {
    Title: "Cpl",
    Initials: "FL",
    Surname: "Maudling",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1159: {
    Title: "Cdt",
    Initials: "SI",
    Surname: "Mohacek",
    "Primary Club": "Sevenoaks School",
    County: "Sussex",
    Country: "England",
  },
  1160: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Monk",
    "Primary Club": "Oxfordshire ACF",
    County: "Oxfordshire",
    Country: "England",
  },
  1161: {
    Title: "",
    Initials: "JJ",
    Surname: "Morrison",
    "Primary Club": "Edinburgh University R.C",
    County: "",
    Country: "Scotland",
  },
  1162: {
    Title: "",
    Initials: "AA",
    Surname: "Nicholson",
    "Primary Club": "Oxford University R.C",
    County: "Buckinghamshire",
    Country: "England",
  },
  1163: {
    Title: "Sgt",
    Initials: "C",
    Surname: "Nixon",
    "Primary Club": "Campbell College C.C.F",
    County: "",
    Country: "Ireland",
  },
  1164: {
    Title: "Miss",
    Initials: "CJ",
    Surname: "O'Hagan",
    "Primary Club": "Reading University",
    County: "",
    Country: "",
  },
  1165: {
    Title: "",
    Initials: "TK",
    Surname: "Olliver",
    "Primary Club": "Bath University R.C",
    County: "Somerset",
    Country: "England",
  },
  1166: {
    Title: "Mr",
    Initials: "J",
    Surname: "Paul",
    "Primary Club": "Old Wellingtonians",
    County: "Berkshire",
    Country: "Scotland",
  },
  1167: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Pellett",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "Glamorgan",
    Country: "Wales",
  },
  1168: {
    Title: "",
    Initials: "CD",
    Surname: "Robertson",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  1169: {
    Title: "Cdt",
    Initials: "LM",
    Surname: "Seales",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1170: {
    Title: "Cdt",
    Initials: "RF",
    Surname: "Smyth",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1171: {
    Title: "Cdt",
    Initials: "WGJ",
    Surname: "Stancliffe",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  1172: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Stone",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1173: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Sturge",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1174: {
    Title: "L/Cpl",
    Initials: "WJC",
    Surname: "Sutcliffe",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  1175: {
    Title: "Mr",
    Initials: "ER",
    Surname: "Welford",
    "Primary Club": "Old Wellingtonians",
    County: "Hampshire",
    Country: "England",
  },
  1176: {
    Title: "F/Sgt",
    Initials: "M",
    Surname: "Wilson",
    "Primary Club": "Yorks NSW ACF",
    County: "",
    Country: "",
  },
  1177: {
    Title: "Miss",
    Initials: "CV",
    Surname: "Wilton",
    "Primary Club": "RNTRC",
    County: "County of London",
    Country: "England",
  },
  1178: {
    Title: "Cdt",
    Initials: "RC",
    Surname: "Wilton",
    "Primary Club": "Sevenoaks School",
    County: "County of London",
    Country: "England",
  },
  1179: {
    Title: "Mr",
    Initials: "CR",
    Surname: "Aitken",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1180: {
    Title: "",
    Initials: "JV",
    Surname: "Alexander",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1181: {
    Title: "",
    Initials: "JO",
    Surname: "Apperley",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  1182: {
    Title: "Dr",
    Initials: "JC",
    Surname: "Austin",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1183: {
    Title: "Cdt Cpl",
    Initials: "JM",
    Surname: "Austin",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1184: {
    Title: "",
    Initials: "NJ",
    Surname: "Badenhorst",
    "Primary Club": "Otjiwarongo",
    County: "",
    Country: "Namibia",
  },
  1185: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Bailey",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1186: {
    Title: "",
    Initials: "JDK",
    Surname: "Baker",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1187: {
    Title: "",
    Initials: "A",
    Surname: "Basson",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  1188: {
    Title: "Lt Col",
    Initials: "A",
    Surname: "Bell",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  1189: {
    Title: "",
    Initials: "DEH",
    Surname: "Benest",
    "Primary Club": "Jersey R.C",
    County: "Jersey",
    Country: "Jersey",
  },
  1190: {
    Title: "",
    Initials: "IG",
    Surname: "Benton",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  1191: {
    Title: "Major",
    Initials: "ES",
    Surname: "Bergsma",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  1192: {
    Title: "",
    Initials: "JM",
    Surname: "Berms",
    "Primary Club": "K.S.V Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  1193: {
    Title: "",
    Initials: "TP",
    Surname: "Berry",
    "Primary Club": "Australian Rifle Team",
    County: "Queensland",
    Country: "Australia",
  },
  1194: {
    Title: "",
    Initials: "WC",
    Surname: "Berta",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1195: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Betts",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1196: {
    Title: "",
    Initials: "AK",
    Surname: "Bhargava",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  1197: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Blain",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  1198: {
    Title: "",
    Initials: "JR",
    Surname: "Bondfield",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1199: {
    Title: "",
    Initials: "RP",
    Surname: "Bondfield",
    "Primary Club": "Australian Schools Combin",
    County: "Queensland",
    Country: "Australia",
  },
  1200: {
    Title: "Mr",
    Initials: "AS",
    Surname: "Bosman",
    "Primary Club": "Wonderboom RC",
    County: "",
    Country: "South Africa",
  },
  1201: {
    Title: "",
    Initials: "AP",
    Surname: "Botes",
    "Primary Club": "Windhoek Club",
    County: "",
    Country: "Namibia",
  },
  1202: {
    Title: "",
    Initials: "V",
    Surname: "Bow",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1203: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Bowman",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1204: {
    Title: "",
    Initials: "NC",
    Surname: "Boyde",
    "Primary Club": "Stock Exchange RC",
    County: "County of London",
    Country: "England",
  },
  1205: {
    Title: "",
    Initials: "J",
    Surname: "Braam",
    "Primary Club": "E.R.A Netherlands",
    County: "",
    Country: "Netherlands",
  },
  1206: {
    Title: "",
    Initials: "G",
    Surname: "Bramley",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  1207: {
    Title: "",
    Initials: "PD",
    Surname: "Bramley",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  1208: {
    Title: "",
    Initials: "NJR",
    Surname: "Brand",
    "Primary Club": "Ooste Club",
    County: "",
    Country: "Namibia",
  },
  1209: {
    Title: "",
    Initials: "JF",
    Surname: "Bremner",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1210: {
    Title: "",
    Initials: "FM",
    Surname: "Browning",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1211: {
    Title: "",
    Initials: "JJ",
    Surname: "Bruwer",
    "Primary Club": "Baviaanspoort",
    County: "",
    Country: "South Africa",
  },
  1212: {
    Title: "Mrs",
    Initials: "CA",
    Surname: "Bullock",
    "Primary Club": "Ontario Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  1213: {
    Title: "",
    Initials: "JC",
    Surname: "Bullock",
    "Primary Club": "Ontario Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  1214: {
    Title: "",
    Initials: "GJP",
    Surname: "Burger",
    "Primary Club": "Pretoria-East",
    County: "",
    Country: "South Africa",
  },
  1215: {
    Title: "",
    Initials: "A",
    Surname: "Busby",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1216: {
    Title: "",
    Initials: "DR",
    Surname: "Bush",
    "Primary Club": "Texas Team",
    County: "Texas",
    Country: "United States of America",
  },
  1217: {
    Title: "",
    Initials: "JA",
    Surname: "Button",
    "Primary Club": "Louth RC",
    County: "Lincolnshire",
    Country: "",
  },
  1218: {
    Title: "",
    Initials: "PS",
    Surname: "Buys",
    "Primary Club": "South African National Te",
    County: "Gauteng North",
    Country: "South Africa",
  },
  1219: {
    Title: "LCol",
    Initials: "PF",
    Surname: "Cambridge",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "",
  },
  1220: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Caske",
    "Primary Club": "Natives RC",
    County: "",
    Country: "Australia",
  },
  1221: {
    Title: "",
    Initials: "DJ",
    Surname: "Cassidy",
    "Primary Club": "Australian Schools Combin",
    County: "Queensland",
    Country: "Australia",
  },
  1222: {
    Title: "",
    Initials: "GR",
    Surname: "Cassidy",
    "Primary Club": "Ontario Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  1223: {
    Title: "Lt Col",
    Initials: "LL",
    Surname: "Cebula",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1224: {
    Title: "",
    Initials: "DS",
    Surname: "Chalmers",
    "Primary Club": "Victoria Goodwill Team",
    County: "Victoria",
    Country: "Australia",
  },
  1225: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Chapman",
    "Primary Club": "Canada",
    County: "Devon",
    Country: "Canada",
  },
  1226: {
    Title: "",
    Initials: "GW",
    Surname: "Cheek",
    "Primary Club": "Falkland Islands R.A.",
    County: "",
    Country: "Falkland Islands",
  },
  1227: {
    Title: "Cdt",
    Initials: "CS",
    Surname: "Cherry",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  1228: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Chivers",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "England",
  },
  1229: {
    Title: "",
    Initials: "W",
    Surname: "Chong",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1230: {
    Title: "",
    Initials: "PB",
    Surname: "Church",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1231: {
    Title: "",
    Initials: "ES",
    Surname: "Claassen",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1232: {
    Title: "",
    Initials: "G",
    Surname: "Clement",
    "Primary Club": "Trinidad R.A",
    County: "",
    Country: "Trinidad",
  },
  1234: {
    Title: "Mr",
    Initials: "R",
    Surname: "Cole",
    "Primary Club": "Old Stamfordians",
    County: "Lincolnshire",
    Country: "",
  },
  1235: {
    Title: "",
    Initials: "TE",
    Surname: "Cole",
    "Primary Club": "TMT",
    County: "Texas",
    Country: "United States of America",
  },
  1236: {
    Title: "Lt",
    Initials: "DJH",
    Surname: "Coleman",
    "Primary Club": "Australian Army",
    County: "NSW",
    Country: "Australia",
  },
  1237: {
    Title: "WO2",
    Initials: "CCC",
    Surname: "Coley",
    "Primary Club": "Jamaica R.A",
    County: "",
    Country: "Jamaica",
  },
  1238: {
    Title: "",
    Initials: "FC",
    Surname: "Conner",
    "Primary Club": "Victoria Goodwill Team",
    County: "Victoria",
    Country: "Australia",
  },
  1239: {
    Title: "",
    Initials: "VE",
    Surname: "Conner",
    "Primary Club": "Victoria Goodwill Team",
    County: "Victoria",
    Country: "Australia",
  },
  1240: {
    Title: "",
    Initials: "KJ",
    Surname: "Cooke",
    "Primary Club": "Australia",
    County: "NSW",
    Country: "Australia",
  },
  1241: {
    Title: "Mr",
    Initials: "J",
    Surname: "Corbett",
    "Primary Club": "City RC",
    County: "",
    Country: "Australia",
  },
  1242: {
    Title: "",
    Initials: "LJ",
    Surname: "Corkett",
    "Primary Club": "B.C.R.C",
    County: "Buckinghamshire",
    Country: "England",
  },
  1243: {
    Title: "",
    Initials: "DN",
    Surname: "Crawford",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1244: {
    Title: "",
    Initials: "GJ",
    Surname: "Crowe",
    "Primary Club": "Australian Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1245: {
    Title: "",
    Initials: "RD",
    Surname: "Cruikshank",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1246: {
    Title: "Capt",
    Initials: "KA",
    Surname: "Cunningham",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1247: {
    Title: "Mr",
    Initials: "",
    Surname: "CURA E",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  1248: {
    Title: "",
    Initials: "DG",
    Surname: "Daines",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1249: {
    Title: "",
    Initials: "JC",
    Surname: "Davies",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1250: {
    Title: "",
    Initials: "KB",
    Surname: "De Klerk",
    "Primary Club": "Maritzburg R.A",
    County: "Natal",
    Country: "South Africa",
  },
  1251: {
    Title: "",
    Initials: "O",
    Surname: "De Klerk",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1252: {
    Title: "",
    Initials: "AP",
    Surname: "de Niekerk",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1253: {
    Title: "",
    Initials: "P",
    Surname: "Deloume",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1254: {
    Title: "",
    Initials: "RE",
    Surname: "DePasque",
    "Primary Club": "California",
    County: "",
    Country: "United States of America",
  },
  1255: {
    Title: "Dr",
    Initials: "PV",
    Surname: "Divasto",
    "Primary Club": "Texas Team",
    County: "Texas",
    Country: "United States of America",
  },
  1256: {
    Title: "Mr",
    Initials: "P",
    Surname: "Dobell",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1257: {
    Title: "Mr",
    Initials: "PAB",
    Surname: "Dodd",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  1258: {
    Title: "",
    Initials: "APC",
    Surname: "Dodds",
    "Primary Club": "Kenya Regiment R.C",
    County: "",
    Country: "Kenya",
  },
  1259: {
    Title: "CWO",
    Initials: "C",
    Surname: "Donkin",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1260: {
    Title: "",
    Initials: "PD",
    Surname: "Dorrer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1261: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Douglas",
    "Primary Club": "Guyana NRA",
    County: "",
    Country: "West Indies",
  },
  1262: {
    Title: "",
    Initials: "W",
    Surname: "Du Plooy",
    "Primary Club": "Pretoria-East",
    County: "",
    Country: "South Africa",
  },
  1263: {
    Title: "",
    Initials: "JG",
    Surname: "Du Toit",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  1264: {
    Title: "WOII",
    Initials: "I",
    Surname: "Duba",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1265: {
    Title: "Mr",
    Initials: "J",
    Surname: "Dugas",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1266: {
    Title: "",
    Initials: "M",
    Surname: "Dunia",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1267: {
    Title: "",
    Initials: "AA",
    Surname: "Dyason",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1268: {
    Title: "",
    Initials: "AV",
    Surname: "Edwards",
    "Primary Club": "Australian U25",
    County: "NSW",
    Country: "Australia",
  },
  1269: {
    Title: "ACP",
    Initials: "S",
    Surname: "Ekeno",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  1270: {
    Title: "",
    Initials: "J",
    Surname: "Emson",
    "Primary Club": "Hampshire",
    County: "Hampshire",
    Country: "England",
  },
  1271: {
    Title: "",
    Initials: "DV",
    Surname: "Enslin",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1272: {
    Title: "Col",
    Initials: "KJ",
    Surname: "Erdman",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1273: {
    Title: "",
    Initials: "A",
    Surname: "Faruki",
    "Primary Club": "BCRA",
    County: "",
    Country: "Canada",
  },
  1274: {
    Title: "",
    Initials: "JHC",
    Surname: "Fennell",
    "Primary Club": "A.T.S.C",
    County: "Kent",
    Country: "England",
  },
  1275: {
    Title: "",
    Initials: "R",
    Surname: "Feustel",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  1276: {
    Title: "Mr",
    Initials: "MSE",
    Surname: "Firman",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  1277: {
    Title: "",
    Initials: "LA",
    Surname: "Fisher",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1278: {
    Title: "Capt",
    Initials: "JG",
    Surname: "Fittler",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  1279: {
    Title: "",
    Initials: "JP",
    Surname: "Fitzgerald",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1280: {
    Title: "",
    Initials: "DR",
    Surname: "Flaharty",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1281: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Flatman",
    "Primary Club": "Oxfordshire ACF",
    County: "",
    Country: "",
  },
  1282: {
    Title: "",
    Initials: "JH",
    Surname: "Forrest",
    "Primary Club": "Malvern",
    County: "",
    Country: "New Zealand",
  },
  1283: {
    Title: "",
    Initials: "D",
    Surname: "Fortunov",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1284: {
    Title: "",
    Initials: "JJ",
    Surname: "Fouche",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1285: {
    Title: "",
    Initials: "J",
    Surname: "Fourie",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1286: {
    Title: "",
    Initials: "JH",
    Surname: "Franklin",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1287: {
    Title: "",
    Initials: "MD",
    Surname: "Furzer",
    "Primary Club": "Jersey R.C",
    County: "",
    Country: "Jersey",
  },
  1288: {
    Title: "",
    Initials: "DW",
    Surname: "Gardiner",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  1289: {
    Title: "",
    Initials: "HMJ",
    Surname: "Gerber",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1290: {
    Title: "",
    Initials: "RWB",
    Surname: "Giles",
    "Primary Club": "Australian U25",
    County: "NSW",
    Country: "Australia",
  },
  1291: {
    Title: "Maj",
    Initials: "AW",
    Surname: "Gill",
    "Primary Club": "ATSC",
    County: "London",
    Country: "Scotland",
  },
  1292: {
    Title: "",
    Initials: "GW",
    Surname: "Glasgow",
    "Primary Club": "New Zealand Goodwill",
    County: "",
    Country: "New Zealand",
  },
  1294: {
    Title: "",
    Initials: "BN",
    Surname: "Gordon",
    "Primary Club": "Queensland Team",
    County: "Queensland",
    Country: "Australia",
  },
  1295: {
    Title: "",
    Initials: "SH",
    Surname: "Goslin",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  1296: {
    Title: "",
    Initials: "GJF",
    Surname: "Gous",
    "Primary Club": "Windhoek Club",
    County: "",
    Country: "Namibia",
  },
  1297: {
    Title: "Capt",
    Initials: "MWA",
    Surname: "Graves",
    "Primary Club": "Australian Army",
    County: "NSW",
    Country: "Australia",
  },
  1298: {
    Title: "",
    Initials: "IT",
    Surname: "Griffiths",
    "Primary Club": "Grafton Rifle Club",
    County: "NSW",
    Country: "Australia",
  },
  1299: {
    Title: "",
    Initials: "JH",
    Surname: "Grobler",
    "Primary Club": "S A Veterans",
    County: "Gauteng",
    Country: "South Africa",
  },
  1300: {
    Title: "",
    Initials: "PJ",
    Surname: "Grobler",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1301: {
    Title: "Mr",
    Initials: "GE",
    Surname: "Grosskreutz",
    "Primary Club": "ATSC",
    County: "",
    Country: "Australia",
  },
  1302: {
    Title: "",
    Initials: "A",
    Surname: "Grundy",
    "Primary Club": "Ontario R.C",
    County: "Ontario",
    Country: "Canada",
  },
  1303: {
    Title: "",
    Initials: "RA",
    Surname: "Gustin",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1304: {
    Title: "",
    Initials: "JB",
    Surname: "Haasbroek",
    "Primary Club": "Witwatersrand",
    County: "Highveld Provinces",
    Country: "South Africa",
  },
  1305: {
    Title: "Dr",
    Initials: "KE",
    Surname: "Hammond",
    "Primary Club": "Jamaica R.A",
    County: "",
    Country: "Jamaica",
  },
  1306: {
    Title: "",
    Initials: "DL",
    Surname: "Harris",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1307: {
    Title: "",
    Initials: "C",
    Surname: "Hatton",
    "Primary Club": "Chichester R.C",
    County: "Sussex",
    Country: "",
  },
  1308: {
    Title: "Mrs",
    Initials: "JE",
    Surname: "Hausler",
    "Primary Club": "Brisbane RC",
    County: "",
    Country: "Australia",
  },
  1309: {
    Title: "",
    Initials: "R",
    Surname: "Hayter",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1310: {
    Title: "",
    Initials: "TB",
    Surname: "Hayter",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1311: {
    Title: "Major",
    Initials: "RG",
    Surname: "Herms",
    "Primary Club": "British Columbia Team",
    County: "",
    Country: "Canada",
  },
  1312: {
    Title: "Dr",
    Initials: "DR",
    Surname: "Hewitt",
    "Primary Club": "Queensland Team",
    County: "Queensland",
    Country: "Australia",
  },
  1313: {
    Title: "",
    Initials: "JV",
    Surname: "Hill",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1314: {
    Title: "Miss",
    Initials: "MM",
    Surname: "Hill",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1315: {
    Title: "Mr",
    Initials: "B",
    Surname: "Hirst",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "England",
  },
  1316: {
    Title: "",
    Initials: "RM",
    Surname: "Hobbs",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  1317: {
    Title: "",
    Initials: "MA",
    Surname: "Hockings",
    "Primary Club": "Ontario R.C",
    County: "Ontario",
    Country: "Canada",
  },
  1318: {
    Title: "Mr",
    Initials: "GH",
    Surname: "Holden",
    "Primary Club": "Stawell",
    County: "",
    Country: "Australia",
  },
  1319: {
    Title: "",
    Initials: "C",
    Surname: "Holloway",
    "Primary Club": "South Africa",
    County: "Gauteng North",
    Country: "South Africa",
  },
  1320: {
    Title: "",
    Initials: "C",
    Surname: "Honey",
    "Primary Club": "Otjiwarongo",
    County: "",
    Country: "Namibia",
  },
  1321: {
    Title: "",
    Initials: "PJ",
    Surname: "Hooton",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  1322: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Hordle",
    "Primary Club": "Jersey RC",
    County: "",
    Country: "Jersey",
  },
  1323: {
    Title: "Sgt",
    Initials: "TR",
    Surname: "Houghton",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  1324: {
    Title: "",
    Initials: "DLC",
    Surname: "Hui",
    "Primary Club": "Australian U25",
    County: "NSW",
    Country: "Australia",
  },
  1325: {
    Title: "Cdt Cpl",
    Initials: "KC",
    Surname: "Hullah",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1326: {
    Title: "",
    Initials: "CMJ",
    Surname: "Hunter",
    "Primary Club": "Bristol University",
    County: "Warwickshire",
    Country: "United Kingdom",
  },
  1327: {
    Title: "",
    Initials: "S",
    Surname: "Hyman",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Holland",
  },
  1328: {
    Title: "",
    Initials: "EL",
    Surname: "Illston",
    "Primary Club": "Cheltenham",
    County: "",
    Country: "New Zealand",
  },
  1329: {
    Title: "",
    Initials: "J",
    Surname: "Ingoldsby",
    "Primary Club": "Ontario R.C",
    County: "Ontario",
    Country: "Canada",
  },
  1330: {
    Title: "Miss",
    Initials: "KAN",
    Surname: "Jack",
    "Primary Club": "NLRC",
    County: "Kent",
    Country: "Scotland",
  },
  1331: {
    Title: "",
    Initials: "MR",
    Surname: "Jackman",
    "Primary Club": "Victoria Goodwill Team",
    County: "Victoria",
    Country: "Australia",
  },
  1332: {
    Title: "",
    Initials: "JJ",
    Surname: "Jackson",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1333: {
    Title: "",
    Initials: "CJ",
    Surname: "Jacobs",
    "Primary Club": "South African U25",
    County: "Gauteng",
    Country: "South Africa",
  },
  1334: {
    Title: "",
    Initials: "AM",
    Surname: "Jacques",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1335: {
    Title: "",
    Initials: "DJ",
    Surname: "Jaeger",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1336: {
    Title: "",
    Initials: "TM",
    Surname: "Joannedys",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "United Kingdom",
  },
  1337: {
    Title: "",
    Initials: "DL",
    Surname: "Jolly",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1338: {
    Title: "S/Sgt",
    Initials: "AA",
    Surname: "Jones",
    "Primary Club": "Barbados R.A",
    County: "",
    Country: "Barbados",
  },
  1339: {
    Title: "",
    Initials: "CC",
    Surname: "Jones",
    "Primary Club": "California",
    County: "",
    Country: "United States of America",
  },
  1340: {
    Title: "",
    Initials: "JT",
    Surname: "Jones",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "",
  },
  1341: {
    Title: "Miss",
    Initials: "J",
    Surname: "Jordan",
    "Primary Club": "Barbados R.A",
    County: "",
    Country: "Barbados",
  },
  1342: {
    Title: "",
    Initials: "NG",
    Surname: "Keighley",
    "Primary Club": "A Coy",
    County: "",
    Country: "",
  },
  1343: {
    Title: "",
    Initials: "F",
    Surname: "Kimani",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1344: {
    Title: "SSgt",
    Initials: "E",
    Surname: "Kimisik",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1345: {
    Title: "",
    Initials: "L",
    Surname: "King",
    "Primary Club": "Barbados R.A",
    County: "",
    Country: "Barbados",
  },
  1346: {
    Title: "",
    Initials: "R",
    Surname: "Kinney",
    "Primary Club": "New Zealand Goodwill",
    County: "",
    Country: "New Zealand",
  },
  1347: {
    Title: "Sgt",
    Initials: "D",
    Surname: "Kinuthia",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1348: {
    Title: "",
    Initials: "WJ",
    Surname: "Kiss",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1349: {
    Title: "Mr",
    Initials: "R",
    Surname: "Kligge",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1350: {
    Title: "Sgt",
    Initials: "P",
    Surname: "Kokon",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  1351: {
    Title: "PTE",
    Initials: "SI",
    Surname: "Kremer",
    "Primary Club": "Australian Army",
    County: "NSW",
    Country: "Australia",
  },
  1352: {
    Title: "",
    Initials: "JH",
    Surname: "Labuschagne",
    "Primary Club": "Ooste Club",
    County: "",
    Country: "Namibia",
  },
  1353: {
    Title: "",
    Initials: "D",
    Surname: "Ladrach",
    "Primary Club": "Texas Team",
    County: "Texas",
    Country: "United States of America",
  },
  1354: {
    Title: "",
    Initials: "TSA",
    Surname: "Lahtinen",
    "Primary Club": "U.L.R.C",
    County: "",
    Country: "",
  },
  1355: {
    Title: "",
    Initials: "WR",
    Surname: "Lancaster",
    "Primary Club": "Zimbabwe Team",
    County: "",
    Country: "Zimbabwe",
  },
  1356: {
    Title: "Mr",
    Initials: "WA",
    Surname: "Langley",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "",
  },
  1357: {
    Title: "Miss",
    Initials: "LS",
    Surname: "Larsson",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1358: {
    Title: "",
    Initials: "RJ",
    Surname: "Lauterbach",
    "Primary Club": "South African National Te",
    County: "Gauteng North",
    Country: "South Africa",
  },
  1359: {
    Title: "",
    Initials: "CD",
    Surname: "Lavelle",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1360: {
    Title: "Col",
    Initials: "CH",
    Surname: "Le Roux",
    "Primary Club": "S A Veterans",
    County: "Gauteng",
    Country: "South Africa",
  },
  1361: {
    Title: "Mr",
    Initials: "D",
    Surname: "Lee",
    "Primary Club": "Jamaica R.A",
    County: "",
    Country: "Jamaica",
  },
  1362: {
    Title: "",
    Initials: "JA",
    Surname: "Lee",
    "Primary Club": "Trinidad R.A",
    County: "",
    Country: "Trinidad & Tobago",
  },
  1363: {
    Title: "Mr",
    Initials: "DFN",
    Surname: "Leguern",
    "Primary Club": "NRA",
    County: "Dorset",
    Country: "England",
  },
  1364: {
    Title: "",
    Initials: "G",
    Surname: "Levy",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1365: {
    Title: "",
    Initials: "I",
    Surname: "Lewis",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "England",
  },
  1366: {
    Title: "",
    Initials: "T",
    Surname: "Lintner",
    "Primary Club": "Texas Team",
    County: "Texas",
    Country: "United States of America",
  },
  1367: {
    Title: "Ms",
    Initials: "U",
    Surname: "Locher",
    "Primary Club": "British Alpine Rifles",
    County: "",
    Country: "Switzerland",
  },
  1368: {
    Title: "",
    Initials: "LE",
    Surname: "Lovemore",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  1369: {
    Title: "Mr",
    Initials: "SL",
    Surname: "Lucas",
    "Primary Club": "RNTRC",
    County: "Devon",
    Country: "England",
  },
  1370: {
    Title: "",
    Initials: "D",
    Surname: "Mackie",
    "Primary Club": "Comber R.C",
    County: "Ulster",
    Country: "Ireland",
  },
  1371: {
    Title: "",
    Initials: "LD",
    Surname: "Marett",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1372: {
    Title: "Mr",
    Initials: "A",
    Surname: "Marion",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1373: {
    Title: "",
    Initials: "JH",
    Surname: "Maritz",
    "Primary Club": "South Africa",
    County: "Northern Cape",
    Country: "South Africa",
  },
  1374: {
    Title: "L/Cpl",
    Initials: "CW",
    Surname: "Martin",
    "Primary Club": "Australian Army",
    County: "Victoria",
    Country: "Australia",
  },
  1375: {
    Title: "Maj Rtd",
    Initials: "P",
    Surname: "Mathenge",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1376: {
    Title: "",
    Initials: "TN",
    Surname: "Maynard",
    "Primary Club": "DCRA",
    County: "Ontario",
    Country: "Canada",
  },
  1377: {
    Title: "Sgt",
    Initials: "A",
    Surname: "McCann",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  1378: {
    Title: "",
    Initials: "GE",
    Surname: "McCartney",
    "Primary Club": "PPRA",
    County: "Manitoba",
    Country: "Canada",
  },
  1379: {
    Title: "Mrs",
    Initials: "WA",
    Surname: "McGuigan",
    "Primary Club": "",
    County: "NSW",
    Country: "Australia",
  },
  1380: {
    Title: "Mr",
    Initials: "JN",
    Surname: "Mclaren",
    "Primary Club": "Masterton",
    County: "",
    Country: "New Zealand",
  },
  1381: {
    Title: "Miss",
    Initials: "JS",
    Surname: "McMahon",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1383: {
    Title: "Mr",
    Initials: "RC",
    Surname: "Mead",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  1384: {
    Title: "Mr",
    Initials: "B",
    Surname: "Mehrtens",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  1385: {
    Title: "Ms",
    Initials: "LK",
    Surname: "Miller",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1386: {
    Title: "",
    Initials: "JD",
    Surname: "Mitchley",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  1387: {
    Title: "Mr",
    Initials: "FM",
    Surname: "Mohideen",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1388: {
    Title: "",
    Initials: "MH",
    Surname: "Mohideen",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1389: {
    Title: "",
    Initials: "PM",
    Surname: "Monteil",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  1390: {
    Title: "",
    Initials: "JA",
    Surname: "Moore",
    "Primary Club": "Australian Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1391: {
    Title: "",
    Initials: "JE",
    Surname: "Moore",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1392: {
    Title: "Lt Col",
    Initials: "RG",
    Surname: "Moore",
    "Primary Club": "Ontario R.C",
    County: "Ontario",
    Country: "Canada",
  },
  1393: {
    Title: "",
    Initials: "M",
    Surname: "Mortensen",
    "Primary Club": "Oxford University R.C",
    County: "Hertfordshire",
    Country: "",
  },
  1394: {
    Title: "",
    Initials: "DM",
    Surname: "Moseley",
    "Primary Club": "Texas Team",
    County: "Texas",
    Country: "United States of America",
  },
  1395: {
    Title: "Mrs",
    Initials: "SM",
    Surname: "Mott",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  1396: {
    Title: "WOII",
    Initials: "EK",
    Surname: "Muleyi",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1397: {
    Title: "",
    Initials: "AA",
    Surname: "Mullin",
    "Primary Club": "Winona RC",
    County: "Ontario",
    Country: "Canada",
  },
  1398: {
    Title: "",
    Initials: "AE",
    Surname: "Murray",
    "Primary Club": "Gresham's R.C",
    County: "",
    Country: "Australia",
  },
  1399: {
    Title: "",
    Initials: "ER",
    Surname: "Murray",
    "Primary Club": "Queensland Team",
    County: "Queensland",
    Country: "Australia",
  },
  1400: {
    Title: "Sgt",
    Initials: "TG",
    Surname: "Murray",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  1401: {
    Title: "Insp",
    Initials: "J",
    Surname: "Musyoka",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  1402: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Mwangi",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  1403: {
    Title: "Mrs",
    Initials: "D",
    Surname: "Negus",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  1404: {
    Title: "Mr",
    Initials: "SM",
    Surname: "Negus",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "Australia",
  },
  1405: {
    Title: "Miss",
    Initials: "KA",
    Surname: "Nelson",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1406: {
    Title: "Mr",
    Initials: "DF",
    Surname: "Nesbitt",
    "Primary Club": "Darlington R & PC",
    County: "County of Durham",
    Country: "England",
  },
  1407: {
    Title: "Major",
    Initials: "D",
    Surname: "Ngatia",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  1408: {
    Title: "Maj",
    Initials: "J",
    Surname: "Nguli",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  1409: {
    Title: "",
    Initials: "DFG",
    Surname: "Nichols",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  1410: {
    Title: "",
    Initials: "D",
    Surname: "Noble",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  1411: {
    Title: "",
    Initials: "BC",
    Surname: "Norman",
    "Primary Club": "Zimbabwe Team",
    County: "",
    Country: "Zimbabwe",
  },
  1412: {
    Title: "Cdt",
    Initials: "W",
    Surname: "Norris",
    "Primary Club": "Campbell College C.C.F",
    County: "",
    Country: "",
  },
  1413: {
    Title: "Mr",
    Initials: "HP",
    Surname: "Noss",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1414: {
    Title: "Mr",
    Initials: "E",
    Surname: "Nyagah",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1415: {
    Title: "Dr",
    Initials: "BE",
    Surname: "O'Brien",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1416: {
    Title: "Cpl",
    Initials: "E",
    Surname: "Odeo",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  1417: {
    Title: "",
    Initials: "P",
    Surname: "Papasideris",
    "Primary Club": "PPRA",
    County: "Alberta",
    Country: "Canada",
  },
  1418: {
    Title: "",
    Initials: "MO",
    Surname: "Parvess",
    "Primary Club": "269 Rifle Club",
    County: "",
    Country: "Zimbabwe",
  },
  1419: {
    Title: "",
    Initials: "PJ",
    Surname: "Paterson",
    "Primary Club": "Old Framlinghamians R.C",
    County: "Essex",
    Country: "United Kingdom",
  },
  1420: {
    Title: "Cdt",
    Initials: "AD",
    Surname: "Patrick",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1421: {
    Title: "",
    Initials: "TS",
    Surname: "Paty",
    "Primary Club": "Trinidad R.A",
    County: "",
    Country: "Trinidad & Tobago",
  },
  1422: {
    Title: "",
    Initials: "PW",
    Surname: "Peck",
    "Primary Club": "F.I.D.F. RC",
    County: "",
    Country: "Falkland Islands",
  },
  1423: {
    Title: "Mr",
    Initials: "MP",
    Surname: "Perez",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "West Indies",
  },
  1424: {
    Title: "Mr",
    Initials: "M",
    Surname: "Persaud",
    "Primary Club": "Guyana NRA",
    County: "",
    Country: "Guyana",
  },
  1425: {
    Title: "",
    Initials: "DR",
    Surname: "Pettersson",
    "Primary Club": "Falkland Islands R.A.",
    County: "",
    Country: "Falkland Islands",
  },
  1426: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Pezzaniti",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1427: {
    Title: "Cpl",
    Initials: "AC",
    Surname: "Phipps",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1428: {
    Title: "",
    Initials: "EW",
    Surname: "Pintard",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1429: {
    Title: "Cdt",
    Initials: "MJP",
    Surname: "Playford",
    "Primary Club": "Norfolk ACF",
    County: "Norfolk",
    Country: "England",
  },
  1430: {
    Title: "",
    Initials: "NC",
    Surname: "Pogson",
    "Primary Club": "Bermuda",
    County: "",
    Country: "Bermuda",
  },
  1431: {
    Title: "",
    Initials: "NA",
    Surname: "Potts",
    "Primary Club": "California",
    County: "",
    Country: "United States of America",
  },
  1432: {
    Title: "Lt Col",
    Initials: "AJ",
    Surname: "Praysner",
    "Primary Club": "Ontario Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  1433: {
    Title: "",
    Initials: "JLJ",
    Surname: "Pringle",
    "Primary Club": "Zimbabwe Team",
    County: "",
    Country: "Zimbabwe",
  },
  1434: {
    Title: "Mr",
    Initials: "PGW",
    Surname: "Purdy",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  1435: {
    Title: "Mr",
    Initials: "WH",
    Surname: "Putter",
    "Primary Club": "RNTRC",
    County: "Dorset",
    Country: "England",
  },
  1436: {
    Title: "",
    Initials: "LW",
    Surname: "Quick",
    "Primary Club": "Ontario R.C",
    County: "Ontario",
    Country: "Canada",
  },
  1437: {
    Title: "Mr",
    Initials: "CHT",
    Surname: "Quinn",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1438: {
    Title: "",
    Initials: "VC",
    Surname: "Quixley",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1439: {
    Title: "Major",
    Initials: "SC",
    Surname: "Rayner",
    "Primary Club": "Bermuda",
    County: "",
    Country: "Bermuda",
  },
  1440: {
    Title: "",
    Initials: "CA",
    Surname: "Reid",
    "Primary Club": "Bermuda",
    County: "",
    Country: "Bermuda",
  },
  1441: {
    Title: "",
    Initials: "JJ",
    Surname: "Rhynard",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1442: {
    Title: "",
    Initials: "ER",
    Surname: "Robinson",
    "Primary Club": "Bermuda",
    County: "",
    Country: "Bermuda",
  },
  1443: {
    Title: "",
    Initials: "L",
    Surname: "Romalho",
    "Primary Club": "Guyana N.R.A",
    County: "",
    Country: "Guyana",
  },
  1444: {
    Title: "",
    Initials: "H",
    Surname: "Ropars",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1445: {
    Title: "",
    Initials: "NA",
    Surname: "Rosewell",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "",
  },
  1446: {
    Title: "",
    Initials: "A",
    Surname: "Rowlands",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1447: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Rowlands",
    "Primary Club": "Lyndhurst RC",
    County: "",
    Country: "Australia",
  },
  1448: {
    Title: "",
    Initials: "RW",
    Surname: "Rowlands",
    "Primary Club": "Malvern",
    County: "",
    Country: "New Zealand",
  },
  1449: {
    Title: "Insp",
    Initials: "",
    Surname: "Sanudin Md Isa",
    "Primary Club": "Royal Malaysian Police",
    County: "",
    Country: "Malaysia",
  },
  1450: {
    Title: "",
    Initials: "JL",
    Surname: "Scarborough",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1451: {
    Title: "Col",
    Initials: "CE",
    Surname: "Schulenburg",
    "Primary Club": "S A Veterans",
    County: "",
    Country: "South Africa",
  },
  1452: {
    Title: "",
    Initials: "GI",
    Surname: "Shar",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1453: {
    Title: "",
    Initials: "D",
    Surname: "Shea",
    "Primary Club": "California",
    County: "",
    Country: "United States of America",
  },
  1454: {
    Title: "",
    Initials: "MB",
    Surname: "Shouler",
    "Primary Club": "Langar",
    County: "Nottinghamshire",
    Country: "England",
  },
  1455: {
    Title: "Mr",
    Initials: "P",
    Surname: "Sirett",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  1456: {
    Title: "Capt",
    Initials: "RG",
    Surname: "Skelton",
    "Primary Club": "City of Brisbane RC",
    County: "Queensland",
    Country: "Australia",
  },
  1457: {
    Title: "Mr",
    Initials: "EM",
    Surname: "Sloane",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1458: {
    Title: "",
    Initials: "M",
    Surname: "Smereka",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1459: {
    Title: "",
    Initials: "CA",
    Surname: "Smith",
    "Primary Club": "Jamaica R.A",
    County: "",
    Country: "Jamaica",
  },
  1460: {
    Title: "",
    Initials: "GRH",
    Surname: "Smith",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  1461: {
    Title: "",
    Initials: "J",
    Surname: "Soanes",
    "Primary Club": "Trinidad R.A",
    County: "",
    Country: "Trinidad & Tobago",
  },
  1462: {
    Title: "Major",
    Initials: "SG",
    Surname: "Soskin",
    "Primary Club": "OMRC",
    County: "Bedfordshire",
    Country: "England",
  },
  1463: {
    Title: "",
    Initials: "BJ",
    Surname: "Southall",
    "Primary Club": "New Zealand Goodwill",
    County: "",
    Country: "New Zealand",
  },
  1464: {
    Title: "",
    Initials: "JA",
    Surname: "Southall",
    "Primary Club": "New Zealand Goodwill",
    County: "",
    Country: "New Zealand",
  },
  1465: {
    Title: "Mrs",
    Initials: "CV",
    Surname: "Sproul",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1466: {
    Title: "",
    Initials: "ML",
    Surname: "Sproul",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1467: {
    Title: "Cdt",
    Initials: "GC",
    Surname: "Staal",
    "Primary Club": "Athelings",
    County: "Jersey",
    Country: "Jersey",
  },
  1468: {
    Title: "",
    Initials: "D",
    Surname: "Stanoev",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1469: {
    Title: "Mrs",
    Initials: "U",
    Surname: "Steinbruck",
    "Primary Club": "Ooste Club",
    County: "",
    Country: "Namibia",
  },
  1470: {
    Title: "",
    Initials: "RC",
    Surname: "Steketee",
    "Primary Club": "",
    County: "",
    Country: "United States of America",
  },
  1471: {
    Title: "",
    Initials: "WI",
    Surname: "Stewart",
    "Primary Club": "California",
    County: "",
    Country: "United States of America",
  },
  1472: {
    Title: "",
    Initials: "EE",
    Surname: "Stigant",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  1473: {
    Title: "GYSgt",
    Initials: "DW",
    Surname: "Stovall",
    "Primary Club": "RMRA",
    County: "",
    Country: "",
  },
  1474: {
    Title: "",
    Initials: "A",
    Surname: "Stuart",
    "Primary Club": "Barbados R.A",
    County: "",
    Country: "Barbados",
  },
  1475: {
    Title: "",
    Initials: "RD",
    Surname: "Sullivan",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  1476: {
    Title: "Miss",
    Initials: "E",
    Surname: "Sutton",
    "Primary Club": "Edinburgh University R.C",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1477: {
    Title: "",
    Initials: "SJS",
    Surname: "Suttor",
    "Primary Club": "Sydney RC",
    County: "",
    Country: "Australia",
  },
  1478: {
    Title: "",
    Initials: "P",
    Surname: "Swales",
    "Primary Club": "Canada U25 Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  1479: {
    Title: "",
    Initials: "PD",
    Surname: "Swart",
    "Primary Club": "Windhoek Club",
    County: "",
    Country: "Namibia",
  },
  1480: {
    Title: "",
    Initials: "MF",
    Surname: "Sweet",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1481: {
    Title: "Sgt",
    Initials: "PR",
    Surname: "Swindell",
    "Primary Club": "Kwazulu Natal",
    County: "",
    Country: "",
  },
  1482: {
    Title: "",
    Initials: "I",
    Surname: "Szabo",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  1483: {
    Title: "",
    Initials: "AH",
    Surname: "Takaoka",
    "Primary Club": "California",
    County: "",
    Country: "United States of America",
  },
  1484: {
    Title: "",
    Initials: "CG",
    Surname: "Tang Choon",
    "Primary Club": "Trinidad R.A",
    County: "",
    Country: "Trinidad & Tobago",
  },
  1485: {
    Title: "",
    Initials: "GJ",
    Surname: "Taras",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1486: {
    Title: "Capt",
    Initials: "G",
    Surname: "Taylor",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1487: {
    Title: "Mr",
    Initials: "K",
    Surname: "Taylor",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  1488: {
    Title: "",
    Initials: "LJ",
    Surname: "Taylor",
    "Primary Club": "Harare R.C",
    County: "Mashobaland",
    Country: "Zimbabwe",
  },
  1489: {
    Title: "",
    Initials: "MD",
    Surname: "Taylor",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1490: {
    Title: "",
    Initials: "M",
    Surname: "Teglasi",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1491: {
    Title: "Mr",
    Initials: "BL",
    Surname: "Thomson",
    "Primary Club": "Karramomus Rifle Club",
    County: "",
    Country: "Australia",
  },
  1492: {
    Title: "Mrs",
    Initials: "HM",
    Surname: "Thomson",
    "Primary Club": "Victoria Goodwill Team",
    County: "Victoria",
    Country: "Australia",
  },
  1493: {
    Title: "",
    Initials: "AW",
    Surname: "Tickell",
    "Primary Club": "Victoria Goodwill Team",
    County: "Victoria",
    Country: "Australia",
  },
  1494: {
    Title: "",
    Initials: "M",
    Surname: "Tilton",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1495: {
    Title: "Mr",
    Initials: "K",
    Surname: "Todd MBE",
    "Primary Club": "City RC",
    County: "Hampshire",
    Country: "England",
  },
  1496: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Tracey",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  1497: {
    Title: "",
    Initials: "IW",
    Surname: "Trevathen",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  1498: {
    Title: "",
    Initials: "TA",
    Surname: "Trigg",
    "Primary Club": "Central Australian RC",
    County: "Northern Territory",
    Country: "Australia",
  },
  1499: {
    Title: "",
    Initials: "SW",
    Surname: "Trott",
    "Primary Club": "Bermuda",
    County: "",
    Country: "Bermuda",
  },
  1500: {
    Title: "Cdt",
    Initials: "JA",
    Surname: "Turner",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  1501: {
    Title: "",
    Initials: "DGP",
    Surname: "Vamplew",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1502: {
    Title: "",
    Initials: "SW",
    Surname: "Van Der Meerwe",
    "Primary Club": "Windhoek Club",
    County: "",
    Country: "Namibia",
  },
  1503: {
    Title: "Mrs",
    Initials: "E",
    Surname: "Van der Vyver",
    "Primary Club": "Potchefstroom Defence",
    County: "Highveld Provinces",
    Country: "South Africa",
  },
  1504: {
    Title: "",
    Initials: "FC",
    Surname: "Van der Vyver",
    "Primary Club": "Potchefstroom Defence",
    County: "Highveld Provinces",
    Country: "South Africa",
  },
  1505: {
    Title: "",
    Initials: "B",
    Surname: "Van Marter",
    "Primary Club": "Texas Team",
    County: "",
    Country: "United States of America",
  },
  1506: {
    Title: "",
    Initials: "SJ",
    Surname: "Van Ness Jr",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1507: {
    Title: "Capt",
    Initials: "RL",
    Surname: "Van Romburgh",
    "Primary Club": "Western Provinces",
    County: "",
    Country: "South Africa",
  },
  1508: {
    Title: "",
    Initials: "JP",
    Surname: "van Rooyen",
    "Primary Club": "South African National Te",
    County: "",
    Country: "South Africa",
  },
  1509: {
    Title: "",
    Initials: "JW",
    Surname: "Van Rooyen",
    "Primary Club": "South African U25",
    County: "Gauteng North",
    Country: "South Africa",
  },
  1510: {
    Title: "",
    Initials: "A",
    Surname: "Van Tergouw",
    "Primary Club": "K.S.V Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  1511: {
    Title: "",
    Initials: "JH",
    Surname: "Van Wolferen",
    "Primary Club": "E.R.A Netherlands",
    County: "",
    Country: "Netherlands",
  },
  1512: {
    Title: "Gen",
    Initials: "EA",
    Surname: "Venter",
    "Primary Club": "S A Veterans",
    County: "Gauteng North",
    Country: "South Africa",
  },
  1513: {
    Title: "RQMS",
    Initials: "ARL",
    Surname: "Walker",
    "Primary Club": "Kelvinside Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  1514: {
    Title: "Mr",
    Initials: "GM",
    Surname: "Walker",
    "Primary Club": "Central Tasmania RC",
    County: "",
    Country: "Australia",
  },
  1515: {
    Title: "Lt",
    Initials: "MA",
    Surname: "Way",
    "Primary Club": "Australian Army",
    County: "",
    Country: "Australia",
  },
  1516: {
    Title: "Mrs",
    Initials: "GD",
    Surname: "Webb-Enslin",
    "Primary Club": "Australian Rifle Team",
    County: "Queensland",
    Country: "Australia",
  },
  1517: {
    Title: "",
    Initials: "AC",
    Surname: "Webster",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1518: {
    Title: "",
    Initials: "R",
    Surname: "Weir",
    "Primary Club": "Malvern",
    County: "",
    Country: "New Zealand",
  },
  1519: {
    Title: "",
    Initials: "PS",
    Surname: "Welgemoed",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  1520: {
    Title: "",
    Initials: "S",
    Surname: "Wellington",
    "Primary Club": "Wonderboom RC",
    County: "Highveld Provinces",
    Country: "South Africa",
  },
  1521: {
    Title: "Mr",
    Initials: "K",
    Surname: "Westling",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1522: {
    Title: "",
    Initials: "LC",
    Surname: "Whitnack",
    "Primary Club": "British Columbia Team",
    County: "BC",
    Country: "Canada",
  },
  1523: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Wilkinson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1524: {
    Title: "",
    Initials: "P",
    Surname: "Willener",
    "Primary Club": "British Alpine Rifles",
    County: "",
    Country: "Switzerland",
  },
  1525: {
    Title: "",
    Initials: "SJ",
    Surname: "Williams",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  1526: {
    Title: "Miss",
    Initials: "AJ",
    Surname: "Wilson",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  1527: {
    Title: "Miss",
    Initials: "EA",
    Surname: "Wilson",
    "Primary Club": "Canada U25 Rifle Team",
    County: "BC",
    Country: "Canada",
  },
  1528: {
    Title: "Mrs",
    Initials: "SJ",
    Surname: "Witney",
    "Primary Club": "Falkland Islands R.A.",
    County: "",
    Country: "Falkland Islands",
  },
  1529: {
    Title: "",
    Initials: "P",
    Surname: "Wong",
    "Primary Club": "Australian U25",
    County: "NSW",
    Country: "Australia",
  },
  1530: {
    Title: "Mr",
    Initials: "H",
    Surname: "Wong Shui",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1531: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Woodward",
    "Primary Club": "Welsh RA",
    County: "County of London",
    Country: "Wales",
  },
  1532: {
    Title: "",
    Initials: "SA",
    Surname: "Wotherspoon",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1533: {
    Title: "",
    Initials: "NC",
    Surname: "Yakey",
    "Primary Club": "",
    County: "",
    Country: "United States of America",
  },
  1534: {
    Title: "Eur Ing",
    Initials: "C",
    Surname: "Cole",
    "Primary Club": "Wyke Manor",
    County: "Hampshire",
    Country: "",
  },
  1535: {
    Title: "Mr",
    Initials: "WC",
    Surname: "Cuthbert",
    "Primary Club": "PSSA",
    County: "",
    Country: "England",
  },
  1536: {
    Title: "Capt",
    Initials: "HJ",
    Surname: "Adcroft",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "New Zealand",
  },
  1537: {
    Title: "",
    Initials: "TS",
    Surname: "Charrington",
    "Primary Club": "Artists",
    County: "",
    Country: "",
  },
  1538: {
    Title: "Mrs",
    Initials: "D",
    Surname: "Deroy Parker",
    "Primary Club": "32nd 61st Surrey Home Gua",
    County: "Surrey",
    Country: "England",
  },
  1539: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Dodd",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  1540: {
    Title: "Mr",
    Initials: "N",
    Surname: "Anderson",
    "Primary Club": "Dungannon",
    County: "Tyrone",
    Country: "Ireland",
  },
  1541: {
    Title: "Lt Cdr",
    Initials: "RJF",
    Surname: "Dawson",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1542: {
    Title: "Mr",
    Initials: "DAN",
    Surname: "Elliott",
    "Primary Club": "Eagles RC",
    County: "Berkshire",
    Country: "England",
  },
  1543: {
    Title: "Mr",
    Initials: "DGF",
    Surname: "Evans",
    "Primary Club": "NRA",
    County: "Hampshire",
    Country: "England",
  },
  1544: {
    Title: "Mr",
    Initials: "KJ",
    Surname: "Aldridge",
    "Primary Club": "Falkland Islands RA",
    County: "",
    Country: "Falkland Islands",
  },
  1545: {
    Title: "",
    Initials: "MD",
    Surname: "Ford",
    "Primary Club": "Queensland Team",
    County: "Queensland",
    Country: "Australia",
  },
  1546: {
    Title: "Flt Lt",
    Initials: "IM",
    Surname: "Fenton",
    "Primary Club": "RAFSAA",
    County: "",
    Country: "Sierre Leone",
  },
  1547: {
    Title: "",
    Initials: "GD",
    Surname: "Ferre",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  1548: {
    Title: "Mr",
    Initials: "GAN",
    Surname: "Anderson",
    "Primary Club": "OMRC",
    County: "",
    Country: "",
  },
  1549: {
    Title: "",
    Initials: "B",
    Surname: "Hayes",
    "Primary Club": "West Suffolk R.C",
    County: "Lincolnshire",
    Country: "England",
  },
  1550: {
    Title: "",
    Initials: "P",
    Surname: "Henworth",
    "Primary Club": "Kidderminister R.C",
    County: "Hereford & Worcs",
    Country: "England",
  },
  1551: {
    Title: "Mr",
    Initials: "S",
    Surname: "Hepworth",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "Great Britain",
  },
  1552: {
    Title: "Mr",
    Initials: "JRJ",
    Surname: "Hinde",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  1553: {
    Title: "",
    Initials: "PJ",
    Surname: "Hobson",
    "Primary Club": "R.N.T.R.C",
    County: "Hampshire",
    Country: "England",
  },
  1554: {
    Title: "",
    Initials: "BG",
    Surname: "Hulatt",
    "Primary Club": "Ibis R.C",
    County: "County of London",
    Country: "",
  },
  1555: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Holdsworth",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  1556: {
    Title: "",
    Initials: "MGL",
    Surname: "Hodges",
    "Primary Club": "A.T.S.C",
    County: "",
    Country: "",
  },
  1557: {
    Title: "Mr",
    Initials: "TC",
    Surname: "Higgins",
    "Primary Club": "Old Guildfordians RC",
    County: "Surrey",
    Country: "England",
  },
  1558: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Hambro",
    "Primary Club": "NRA",
    County: "County of London",
    Country: "England",
  },
  1559: {
    Title: "Mr",
    Initials: "P",
    Surname: "Barber",
    "Primary Club": "City RC",
    County: "Hampshire",
    Country: "England",
  },
  1560: {
    Title: "Mr",
    Initials: "R",
    Surname: "Jackson",
    "Primary Club": "Chepstow RC",
    County: "South Wales",
    Country: "Wales",
  },
  1561: {
    Title: "Dr",
    Initials: "JC",
    Surname: "Jobling",
    "Primary Club": "Langar RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  1562: {
    Title: "",
    Initials: "MJ",
    Surname: "Johnston",
    "Primary Club": "Comber R.C",
    County: "Tyrone",
    Country: "N. Ireland",
  },
  1563: {
    Title: "Mr",
    Initials: "D",
    Surname: "Johnson",
    "Primary Club": "Bromsgrove RC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  1564: {
    Title: "Dr",
    Initials: "AM",
    Surname: "Jory",
    "Primary Club": "Guernsey RC",
    County: "London",
    Country: "Guernsey",
  },
  1565: {
    Title: "",
    Initials: "RJ",
    Surname: "Knight",
    "Primary Club": "Lloyds TSBRC",
    County: "Devon",
    Country: "England",
  },
  1566: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Large",
    "Primary Club": "Bullet Lodge RC",
    County: "Essex",
    Country: "England",
  },
  1567: {
    Title: "Lt Col",
    Initials: "CST",
    Surname: "Lehmann",
    "Primary Club": "ATSC",
    County: "Cheshire",
    Country: "England",
  },
  1568: {
    Title: "Major",
    Initials: "DA",
    Surname: "Livingstone",
    "Primary Club": "London Scottish R.C",
    County: "Fife & Kinross",
    Country: "Scotland",
  },
  1569: {
    Title: "Mr",
    Initials: "MDA",
    Surname: "Bentata",
    "Primary Club": "ATSC",
    County: "Lancashire",
    Country: "England",
  },
  1570: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Livett",
    "Primary Club": "NLRC",
    County: "Oxfordshire",
    Country: "England",
  },
  1571: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Lee",
    "Primary Club": "Sheffield R.C",
    County: "Yorkshire",
    Country: "England",
  },
  1572: {
    Title: "Mr",
    Initials: "HK",
    Surname: "Lottritz",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1573: {
    Title: "",
    Initials: "FA",
    Surname: "Bird",
    "Primary Club": "Dorking & District RC",
    County: "Kent",
    Country: "England",
  },
  1574: {
    Title: "Mr",
    Initials: "R",
    Surname: "Blackall",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  1575: {
    Title: "Mr",
    Initials: "NP",
    Surname: "Moxon",
    "Primary Club": "RAFTRC",
    County: "Wiltshire",
    Country: "England",
  },
  1576: {
    Title: "Mr",
    Initials: "AME",
    Surname: "Adams",
    "Primary Club": "Lloyds TSBRC",
    County: "Sussex",
    Country: "England",
  },
  1577: {
    Title: "Mrs",
    Initials: "K",
    Surname: "Le Quesne",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  1578: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Mullin",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1579: {
    Title: "",
    Initials: "T",
    Surname: "McIvor",
    "Primary Club": "Dungannon",
    County: "Tyrone",
    Country: "Ireland",
  },
  1580: {
    Title: "",
    Initials: "JR",
    Surname: "Mansfield",
    "Primary Club": "Brecon RC",
    County: "",
    Country: "Wales",
  },
  1581: {
    Title: "SAC",
    Initials: "JEB",
    Surname: "Mawby",
    "Primary Club": "R.A.F. Strike Command",
    County: "",
    Country: "",
  },
  1582: {
    Title: "Mr",
    Initials: "DR",
    Surname: "Niblett",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "England",
  },
  1583: {
    Title: "Mr",
    Initials: "EL",
    Surname: "Orlik",
    "Primary Club": "NRA",
    County: "Essex",
    Country: "England",
  },
  1584: {
    Title: "Mrs.",
    Initials: "JR",
    Surname: "Orpen-Smellie",
    "Primary Club": "Porthcawl RC",
    County: "Glamorgan",
    Country: "Wales",
  },
  1585: {
    Title: "",
    Initials: "LH",
    Surname: "Ovenden",
    "Primary Club": "N.R.A",
    County: "",
    Country: "Belgium",
  },
  1586: {
    Title: "",
    Initials: "JE",
    Surname: "Oliver-Bellasis",
    "Primary Club": "Manydown Rifle Club",
    County: "",
    Country: "",
  },
  1587: {
    Title: "",
    Initials: "JS",
    Surname: "Pile",
    "Primary Club": "Old Cranleighians",
    County: "",
    Country: "",
  },
  1588: {
    Title: "Mr",
    Initials: "GH",
    Surname: "Porter",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  1589: {
    Title: "",
    Initials: "AG",
    Surname: "Peden",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1590: {
    Title: "",
    Initials: "SJ",
    Surname: "Payn",
    "Primary Club": "Jersey R.C",
    County: "",
    Country: "Jersey",
  },
  1591: {
    Title: "",
    Initials: "D",
    Surname: "Brigden",
    "Primary Club": "32nd 61st Surrey Home Gua",
    County: "Surrey",
    Country: "England",
  },
  1592: {
    Title: "Mrs",
    Initials: "REG",
    Surname: "Brion",
    "Primary Club": "Parthian",
    County: "Surrey",
    Country: "England",
  },
  1593: {
    Title: "",
    Initials: "J",
    Surname: "Brogden",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  1594: {
    Title: "",
    Initials: "MA",
    Surname: "Pole-Evans",
    "Primary Club": "Falkland Islands R.A.",
    County: "",
    Country: "Falkland Islands",
  },
  1595: {
    Title: "Mr",
    Initials: "PD",
    Surname: "Le Poidevin",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  1596: {
    Title: "",
    Initials: "K",
    Surname: "Rathbone",
    "Primary Club": "Huddersfield RC",
    County: "Lancashire",
    Country: "England",
  },
  1597: {
    Title: "Mr",
    Initials: "JT",
    Surname: "Rayner",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1598: {
    Title: "Mr",
    Initials: "DF",
    Surname: "Robinson",
    "Primary Club": "LMRA",
    County: "Surrey",
    Country: "England",
  },
  1599: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Bryant",
    "Primary Club": "RAFTRC",
    County: "Suffolk",
    Country: "England",
  },
  1600: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Reynolds",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "England",
  },
  1601: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Sainter",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  1602: {
    Title: "Col",
    Initials: "FC",
    Surname: "Sykes",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "England",
  },
  1604: {
    Title: "",
    Initials: "NC",
    Surname: "Simons",
    "Primary Club": "Bermuda",
    County: "",
    Country: "Bermuda",
  },
  1605: {
    Title: "",
    Initials: "DF",
    Surname: "Sewell",
    "Primary Club": "DCRA",
    County: "Manitoba",
    Country: "Canada",
  },
  1606: {
    Title: "Mrs",
    Initials: "D",
    Surname: "Sewell",
    "Primary Club": "DCRA",
    County: "Manitoba",
    Country: "Canada",
  },
  1607: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Temperley",
    "Primary Club": "Stock Exchange RC",
    County: "London",
    Country: "England",
  },
  1608: {
    Title: "",
    Initials: "MC",
    Surname: "Turner",
    "Primary Club": "RAFTRC",
    County: "Yorkshire",
    Country: "England",
  },
  1609: {
    Title: "",
    Initials: "PDC",
    Surname: "Turner",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "",
  },
  1610: {
    Title: "",
    Initials: "W",
    Surname: "Turner",
    "Primary Club": "MCRC",
    County: "Nottinghamshire",
    Country: "",
  },
  1611: {
    Title: "Dr",
    Initials: "NP",
    Surname: "Tucker",
    "Primary Club": "OGRE",
    County: "Suffolk",
    Country: "Scotland",
  },
  1612: {
    Title: "",
    Initials: "L",
    Surname: "Temme",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  1613: {
    Title: "Mr",
    Initials: "MWT",
    Surname: "Walton",
    "Primary Club": "Stock Exchange RC",
    County: "County of London",
    Country: "England",
  },
  1614: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Warwick",
    "Primary Club": "ISIS Rifle & Pistol Club",
    County: "Oxfordshire",
    Country: "England",
  },
  1615: {
    Title: "Mr",
    Initials: "NR",
    Surname: "Wells",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1616: {
    Title: "",
    Initials: "GM",
    Surname: "Yannaghas",
    "Primary Club": "N.L.R.C.",
    County: "",
    Country: "",
  },
  1617: {
    Title: "Mr",
    Initials: "R",
    Surname: "Kloosterman",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1618: {
    Title: "M",
    Initials: "P",
    Surname: "Le Cam",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1619: {
    Title: "M",
    Initials: "JF",
    Surname: "Boussaingault",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1620: {
    Title: "",
    Initials: "D",
    Surname: "Bedding",
    "Primary Club": "Radlett",
    County: "Hertfordshire",
    Country: "England",
  },
  1621: {
    Title: "Ms",
    Initials: "SL",
    Surname: "Ramsay",
    "Primary Club": "Ontario R.C",
    County: "Ontario",
    Country: "Canada",
  },
  1622: {
    Title: "",
    Initials: "RDC",
    Surname: "Burch",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  1623: {
    Title: "Mr",
    Initials: "AA",
    Surname: "Jobling",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "England",
  },
  1624: {
    Title: "Mr",
    Initials: "RD",
    Surname: "Macmillan",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1625: {
    Title: "Mr",
    Initials: "JRW",
    Surname: "Postle",
    "Primary Club": "Old Epsomian RC",
    County: "Gloucestershire",
    Country: "England",
  },
  1626: {
    Title: "",
    Initials: "NE",
    Surname: "Scudder",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  1627: {
    Title: "Mr",
    Initials: "TF",
    Surname: "Kurn",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1628: {
    Title: "",
    Initials: "HJ",
    Surname: "Burton",
    "Primary Club": "City RC",
    County: "",
    Country: "",
  },
  1629: {
    Title: "",
    Initials: "JP",
    Surname: "Bouin",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "Normandy",
  },
  1630: {
    Title: "Mr",
    Initials: "NBS",
    Surname: "Logan",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1631: {
    Title: "Mrs",
    Initials: "T",
    Surname: "Capell",
    "Primary Club": "Cornwall R.C",
    County: "Cornwall",
    Country: "England",
  },
  1632: {
    Title: "",
    Initials: "JK",
    Surname: "McPhee",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1633: {
    Title: "Mr",
    Initials: "HL",
    Surname: "Ingram",
    "Primary Club": "NRA",
    County: "",
    Country: "France",
  },
  1634: {
    Title: "",
    Initials: "PH",
    Surname: "Jackson",
    "Primary Club": "West of Scotland R.C",
    County: "",
    Country: "Scotland",
  },
  1635: {
    Title: "Mrs",
    Initials: "CIM",
    Surname: "Le Poidevin",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  1636: {
    Title: "",
    Initials: "WL",
    Surname: "Buxton",
    "Primary Club": "Old Marlburian R.C",
    County: "",
    Country: "",
  },
  1637: {
    Title: "",
    Initials: "AJ",
    Surname: "Barr",
    "Primary Club": "N.L.R.C.",
    County: "County of London",
    Country: "Scotland",
  },
  1638: {
    Title: "Mr",
    Initials: "SG",
    Surname: "Cuddy",
    "Primary Club": "Dungannon",
    County: "Tyrone",
    Country: "Ireland",
  },
  1639: {
    Title: "Dr",
    Initials: "PFBH",
    Surname: "Buchanan",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  1640: {
    Title: "Mr",
    Initials: "MB",
    Surname: "Buckley",
    "Primary Club": "New Zealand",
    County: "Lancashire",
    Country: "England",
  },
  1641: {
    Title: "",
    Initials: "B",
    Surname: "Lequertier",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1642: {
    Title: "",
    Initials: "L",
    Surname: "Winter",
    "Primary Club": "Ruislip RC",
    County: "Middlesex",
    Country: "England",
  },
  1643: {
    Title: "Mr",
    Initials: "A",
    Surname: "Alvey",
    "Primary Club": "Huddersfield RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  1644: {
    Title: "",
    Initials: "JH",
    Surname: "Foulston",
    "Primary Club": "N.R.A",
    County: "Suffolk",
    Country: "England",
  },
  1645: {
    Title: "Miss",
    Initials: "AM",
    Surname: "Brown",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1646: {
    Title: "",
    Initials: "M",
    Surname: "Mercer",
    "Primary Club": "32nd 61st Surrey Home Gua",
    County: "Surrey",
    Country: "",
  },
  1647: {
    Title: "Ms",
    Initials: "HM",
    Surname: "Griffiths",
    "Primary Club": "Pacific RC",
    County: "",
    Country: "Australia",
  },
  1648: {
    Title: "",
    Initials: "G",
    Surname: "Kinross",
    "Primary Club": "BBC R.C",
    County: "",
    Country: "",
  },
  1649: {
    Title: "Mrs.",
    Initials: "ME",
    Surname: "Restell",
    "Primary Club": "Eagle R & P.C",
    County: "Berkshire",
    Country: "England",
  },
  1650: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Leece",
    "Primary Club": "LMRA",
    County: "Hampshire",
    Country: "Isle of Man",
  },
  1651: {
    Title: "Mr",
    Initials: "D",
    Surname: "Parr",
    "Primary Club": "West Atholl RC",
    County: "",
    Country: "Scotland",
  },
  1652: {
    Title: "Mr",
    Initials: "KG",
    Surname: "Ellett",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1653: {
    Title: "",
    Initials: "JJK",
    Surname: "Sheldon",
    "Primary Club": "A.T.R.C.",
    County: "Hampshire",
    Country: "England",
  },
  1654: {
    Title: "Miss",
    Initials: "DK",
    Surname: "Upshall",
    "Primary Club": "SCRA",
    County: "Somerset",
    Country: "",
  },
  1655: {
    Title: "Mr",
    Initials: "DGM",
    Surname: "Coleman",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  1656: {
    Title: "",
    Initials: "AJ",
    Surname: "Collins",
    "Primary Club": "Barclays Bank R.C",
    County: "",
    Country: "",
  },
  1657: {
    Title: "",
    Initials: "RJ",
    Surname: "Cushing",
    "Primary Club": "Guernsey R.C",
    County: "Guernsey",
    Country: "Channel Islands",
  },
  1658: {
    Title: "Miss",
    Initials: "MN",
    Surname: "Chombart",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1659: {
    Title: "Mr",
    Initials: "SL",
    Surname: "Cleveland",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  1660: {
    Title: "Sqn Ldr",
    Initials: "KP",
    Surname: "Cressy",
    "Primary Club": "RAF Air Cmd",
    County: "Buckinghamshire",
    Country: "England",
  },
  1661: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Bednarz",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1662: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Beecroft",
    "Primary Club": "Athelings",
    County: "Derbyshire",
    Country: "England",
  },
  1663: {
    Title: "Cdt",
    Initials: "CJB",
    Surname: "Berragan",
    "Primary Club": "Wellington College",
    County: "Wiltshire",
    Country: "England",
  },
  1664: {
    Title: "",
    Initials: "AHM",
    Surname: "Bhalsod",
    "Primary Club": "U.L.R.C",
    County: "Cambridgeshire",
    Country: "England",
  },
  1665: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Bleakley",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1666: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Caouette",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1667: {
    Title: "",
    Initials: "DJ",
    Surname: "Caughey",
    "Primary Club": "Comber R.C",
    County: "Ulster",
    Country: "N. Ireland",
  },
  1668: {
    Title: "Cdt",
    Initials: "RA",
    Surname: "Chamings",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  1669: {
    Title: "Miss",
    Initials: "JK",
    Surname: "Charlton",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "Netherlands",
  },
  1670: {
    Title: "",
    Initials: "BM",
    Surname: "Chowdhary",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "Ireland",
  },
  1671: {
    Title: "Mr",
    Initials: "RE",
    Surname: "Clarke",
    "Primary Club": "Uppingham Veterans RC",
    County: "Lincolnshire",
    Country: "England",
  },
  1672: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Coffin",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1673: {
    Title: "",
    Initials: "JDA",
    Surname: "Conradie",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  1674: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Cooper",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1675: {
    Title: "",
    Initials: "NP",
    Surname: "Craven",
    "Primary Club": "St Andrews Uni. R.C",
    County: "Angus",
    Country: "Scotland",
  },
  1676: {
    Title: "",
    Initials: "J",
    Surname: "Cuneo",
    "Primary Club": "Australian Schools Combin",
    County: "NSW",
    Country: "Australia",
  },
  1677: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Currie",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1678: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Cuthbert",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1679: {
    Title: "",
    Initials: "OS",
    Surname: "Davis",
    "Primary Club": "C.U.R.A",
    County: "Gloucestershire",
    Country: "England",
  },
  1680: {
    Title: "",
    Initials: "PR",
    Surname: "de C Stock",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Channel Islands",
  },
  1681: {
    Title: "",
    Initials: "PP",
    Surname: "de Vries",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  1682: {
    Title: "",
    Initials: "C",
    Surname: "Duguid",
    "Primary Club": "Guyana N.R.A",
    County: "",
    Country: "Guyana",
  },
  1683: {
    Title: "",
    Initials: "PT",
    Surname: "Eckley",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "",
  },
  1684: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Ferguson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1685: {
    Title: "",
    Initials: "S",
    Surname: "Fisher",
    "Primary Club": "Wandsworth",
    County: "County of London",
    Country: "England",
  },
  1686: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Fournier",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1687: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Fulthorpe",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1688: {
    Title: "Ms",
    Initials: "A",
    Surname: "Gagne",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1689: {
    Title: "",
    Initials: "CD",
    Surname: "Gibson",
    "Primary Club": "Aberdeen University R.C",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  1690: {
    Title: "",
    Initials: "J",
    Surname: "Graves",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  1691: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Gregoire",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1692: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Hall",
    "Primary Club": "Wellington College",
    County: "Kent",
    Country: "",
  },
  1693: {
    Title: "Ms",
    Initials: "E",
    Surname: "Hamilton",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  1694: {
    Title: "Mr",
    Initials: "MG",
    Surname: "Hargreaves",
    "Primary Club": "University of Durham",
    County: "Yorkshire",
    Country: "England",
  },
  1695: {
    Title: "",
    Initials: "S",
    Surname: "Harless",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  1696: {
    Title: "Cdt",
    Initials: "AP",
    Surname: "Hayle",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "",
  },
  1697: {
    Title: "",
    Initials: "J",
    Surname: "Higgins",
    "Primary Club": "",
    County: "",
    Country: "Canada",
  },
  1698: {
    Title: "Cdt",
    Initials: "RL",
    Surname: "Hill",
    "Primary Club": "Athelings",
    County: "Yorkshire",
    Country: "England",
  },
  1699: {
    Title: "",
    Initials: "BTK",
    Surname: "Hopson",
    "Primary Club": "OURC",
    County: "Buckinghamshire",
    Country: "England",
  },
  1700: {
    Title: "",
    Initials: "J",
    Surname: "Hurd",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  1701: {
    Title: "Cdt",
    Initials: "SS",
    Surname: "Hutcheson",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  1702: {
    Title: "",
    Initials: "J",
    Surname: "Imhoff",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  1703: {
    Title: "",
    Initials: "DA",
    Surname: "Jacques",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  1704: {
    Title: "",
    Initials: "GR",
    Surname: "Jordan",
    "Primary Club": "Edinburgh University R.C",
    County: "",
    Country: "Scotland",
  },
  1705: {
    Title: "Cpl",
    Initials: "PJ",
    Surname: "Kirby",
    "Primary Club": "Malvern College",
    County: "Midland Counties",
    Country: "England",
  },
  1706: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Lafleur",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1707: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Lovett",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1708: {
    Title: "CWO",
    Initials: "P",
    Surname: "Marwood",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1709: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Mijic",
    "Primary Club": "Malvern College",
    County: "",
    Country: "",
  },
  1710: {
    Title: "Cdt",
    Initials: "JR",
    Surname: "Moss",
    "Primary Club": "Wellington College",
    County: "Surrey",
    Country: "",
  },
  1711: {
    Title: "Cdt",
    Initials: "RW",
    Surname: "Nieuwburg",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  1712: {
    Title: "",
    Initials: "LC",
    Surname: "O'Donnell",
    "Primary Club": "NSW Rifle Team",
    County: "NSW",
    Country: "Australia",
  },
  1713: {
    Title: "Cdt",
    Initials: "JW",
    Surname: "Pearson",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "",
  },
  1714: {
    Title: "Cdt",
    Initials: "AR",
    Surname: "Prideaux",
    "Primary Club": "Uppingham Veterans RC",
    County: "",
    Country: "",
  },
  1715: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Riopel",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1716: {
    Title: "Mr",
    Initials: "RJA",
    Surname: "Ritchie",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  1717: {
    Title: "Sgt",
    Initials: "T",
    Surname: "Routledge",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  1718: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Savard",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1719: {
    Title: "Cdt",
    Initials: "CDA",
    Surname: "Sears",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "United Kingdom",
  },
  1720: {
    Title: "Cdt",
    Initials: "RA",
    Surname: "Shanks",
    "Primary Club": "St. Johns School",
    County: "Surrey",
    Country: "England",
  },
  1721: {
    Title: "Mrs",
    Initials: "AH",
    Surname: "Shepherd",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  1722: {
    Title: "L/Cpl",
    Initials: "JN",
    Surname: "Shooter",
    "Primary Club": "Cranbrook School",
    County: "",
    Country: "",
  },
  1723: {
    Title: "",
    Initials: "CF",
    Surname: "Simon",
    "Primary Club": "Jersey R.A",
    County: "Jersey",
    Country: "Jersey",
  },
  1724: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Smith",
    "Primary Club": "Wellington College",
    County: "",
    Country: "England",
  },
  1725: {
    Title: "Mr",
    Initials: "A",
    Surname: "Smith",
    "Primary Club": "Epsom College",
    County: "Hampshire",
    Country: "England",
  },
  1726: {
    Title: "",
    Initials: "L",
    Surname: "Smith",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  1727: {
    Title: "",
    Initials: "JN",
    Surname: "Thompson",
    "Primary Club": "Highgate School CCF",
    County: "Hertfordshire",
    Country: "England",
  },
  1728: {
    Title: "Miss",
    Initials: "C",
    Surname: "Tremblay",
    "Primary Club": "Canada U25 Rifle Team",
    County: "Quebec",
    Country: "Canada",
  },
  1729: {
    Title: "",
    Initials: "T",
    Surname: "Tubb",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  1730: {
    Title: "Miss",
    Initials: "REW",
    Surname: "Wenham",
    "Primary Club": "WRA",
    County: "Glamorgan",
    Country: "Wales",
  },
  1731: {
    Title: "Cdt",
    Initials: "SA",
    Surname: "Wilson",
    "Primary Club": "Campbell College C.C.F",
    County: "Ulster",
    Country: "Ireland",
  },
  1732: {
    Title: "",
    Initials: "AYLW",
    Surname: "Wong",
    "Primary Club": "Perse CCF",
    County: "Cambridgeshire",
    Country: "England",
  },
  1733: {
    Title: "Sgt",
    Initials: "A",
    Surname: "Young",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  1734: {
    Title: "",
    Initials: "A",
    Surname: "Zoetmulder",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Holland",
  },
  1735: {
    Title: "",
    Initials: "WD",
    Surname: "Adams",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1736: {
    Title: "Miss",
    Initials: "SG",
    Surname: "Adamson",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  1737: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Albinus",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "Glamorgan",
    Country: "Wales",
  },
  1738: {
    Title: "",
    Initials: "W",
    Surname: "Amberg",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "",
  },
  1739: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Athkravisoonthorn",
    "Primary Club": "Epsom College",
    County: "",
    Country: "",
  },
  1740: {
    Title: "Mr",
    Initials: "PDB",
    Surname: "Ayre",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1741: {
    Title: "",
    Initials: "TR",
    Surname: "Bamford",
    "Primary Club": "Edinburgh University R.C",
    County: "",
    Country: "England",
  },
  1742: {
    Title: "",
    Initials: "DG",
    Surname: "Barnes",
    "Primary Club": "Leiston Patriotic",
    County: "Suffolk",
    Country: "",
  },
  1743: {
    Title: "",
    Initials: "RP",
    Surname: "Batty",
    "Primary Club": "University of London",
    County: "Surrey",
    Country: "England",
  },
  1744: {
    Title: "",
    Initials: "SP",
    Surname: "Berkemeyer",
    "Primary Club": "MRA",
    County: "Natal",
    Country: "South Africa",
  },
  1745: {
    Title: "",
    Initials: "RG",
    Surname: "Best",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1746: {
    Title: "",
    Initials: "TE",
    Surname: "Bower",
    "Primary Club": "Cheltenham College C.C.F",
    County: "Gloucestershire",
    Country: "England",
  },
  1747: {
    Title: "Cdt",
    Initials: "PK",
    Surname: "Boyle",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "N. Ireland",
  },
  1748: {
    Title: "Cdt",
    Initials: "SSJ",
    Surname: "Brearley",
    "Primary Club": "Uppingham School",
    County: "County of London",
    Country: "England",
  },
  1749: {
    Title: "",
    Initials: "GR",
    Surname: "Breitmeyer",
    "Primary Club": "N.L.R.C.",
    County: "Sussex",
    Country: "England",
  },
  1750: {
    Title: "",
    Initials: "N",
    Surname: "Brown",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1751: {
    Title: "",
    Initials: "M",
    Surname: "Browning",
    "Primary Club": "USA Young Eagles",
    County: "",
    Country: "United States of America",
  },
  1752: {
    Title: "",
    Initials: "TH",
    Surname: "Brutten",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  1753: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Buck",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  1754: {
    Title: "",
    Initials: "DJ",
    Surname: "Bullen",
    "Primary Club": "",
    County: "ACT",
    Country: "Australia",
  },
  1755: {
    Title: "Cdt",
    Initials: "MN",
    Surname: "Burdeniuk",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1756: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Calvert",
    "Primary Club": "Old Stamfordians",
    County: "Cambridgeshire",
    Country: "Ireland",
  },
  1757: {
    Title: "",
    Initials: "O",
    Surname: "Campbell",
    "Primary Club": "Reading University",
    County: "",
    Country: "United Kingdom",
  },
  1758: {
    Title: "Miss",
    Initials: "EJ",
    Surname: "Cannings",
    "Primary Club": "UVRC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  1759: {
    Title: "Ms",
    Initials: "SER",
    Surname: "Carlton",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  1760: {
    Title: "Mr",
    Initials: "TA",
    Surname: "Chapman",
    "Primary Club": "Old Framlinghamians RC",
    County: "Norfolk",
    Country: "England",
  },
  1761: {
    Title: "",
    Initials: "G",
    Surname: "Chase",
    "Primary Club": "NSRA",
    County: "",
    Country: "Canada",
  },
  1762: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Chen",
    "Primary Club": "Cheltenham College C.C.F",
    County: "",
    Country: "",
  },
  1763: {
    Title: "Cdt",
    Initials: "DJD",
    Surname: "Christie",
    "Primary Club": "Campbell College C.C.F",
    County: "Ulster",
    Country: "Ireland",
  },
  1764: {
    Title: "",
    Initials: "JH",
    Surname: "Clipstone",
    "Primary Club": "Rand R.C",
    County: "",
    Country: "South Africa",
  },
  1765: {
    Title: "Cdt",
    Initials: "MH",
    Surname: "Cloughley",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  1766: {
    Title: "Mrs",
    Initials: "SNJ",
    Surname: "Collyer",
    "Primary Club": "East Grinstead",
    County: "Sussex",
    Country: "England",
  },
  1767: {
    Title: "",
    Initials: "HJ",
    Surname: "Combrink",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  1768: {
    Title: "",
    Initials: "JP",
    Surname: "Cooke",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1769: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Cooney",
    "Primary Club": "Comber RC",
    County: "",
    Country: "Ireland",
  },
  1770: {
    Title: "Miss",
    Initials: "LJ",
    Surname: "Corner",
    "Primary Club": "N.R.A",
    County: "Dorset",
    Country: "England",
  },
  1771: {
    Title: "Cdt",
    Initials: "JAG",
    Surname: "Corry",
    "Primary Club": "Athelings",
    County: "",
    Country: "N. Ireland",
  },
  1772: {
    Title: "Cdt",
    Initials: "SB",
    Surname: "Coster",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  1773: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Cox",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "",
  },
  1774: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Crann",
    "Primary Club": "607(Nearmouth)Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  1775: {
    Title: "",
    Initials: "DS",
    Surname: "Creasey",
    "Primary Club": "Ibis R.C",
    County: "",
    Country: "",
  },
  1776: {
    Title: "Cdt",
    Initials: "KA",
    Surname: "Cross",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "England",
  },
  1777: {
    Title: "",
    Initials: "PC",
    Surname: "de Jager",
    "Primary Club": "",
    County: "",
    Country: "South Africa",
  },
  1778: {
    Title: "Cdt",
    Initials: "DB",
    Surname: "De la Haye",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  1779: {
    Title: "",
    Initials: "RIC",
    Surname: "Denman",
    "Primary Club": "O.G.R.E",
    County: "Surrey",
    Country: "England",
  },
  1780: {
    Title: "Mrs",
    Initials: "S",
    Surname: "Dennis",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  1781: {
    Title: "Cpl",
    Initials: "K",
    Surname: "Denny",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  1782: {
    Title: "",
    Initials: "GE",
    Surname: "Dewhirst",
    "Primary Club": "University of London",
    County: "County Durham",
    Country: "England",
  },
  1783: {
    Title: "Cdt Sgt",
    Initials: "SJG",
    Surname: "Dickens",
    "Primary Club": "111 (Sunderland) Sqn",
    County: "County Durham",
    Country: "England",
  },
  1784: {
    Title: "Cdt",
    Initials: "JR",
    Surname: "Dixon",
    "Primary Club": "Uppingham Veterans RC",
    County: "Suffolk",
    Country: "England",
  },
  1785: {
    Title: "Cdt",
    Initials: "DWD",
    Surname: "Dobbin",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1786: {
    Title: "Cdt",
    Initials: "NW",
    Surname: "Drapeau",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1787: {
    Title: "",
    Initials: "EJB",
    Surname: "Duggleby",
    "Primary Club": "Leeds R.C",
    County: "Yorkshire",
    Country: "Great Britain",
  },
  1788: {
    Title: "",
    Initials: "SG",
    Surname: "East",
    "Primary Club": "",
    County: "Victoria",
    Country: "Australia",
  },
  1789: {
    Title: "",
    Initials: "GC",
    Surname: "Epp",
    "Primary Club": "RMRC",
    County: "",
    Country: "United States of America",
  },
  1790: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Felton",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  1791: {
    Title: "Cdt",
    Initials: "RA",
    Surname: "Ferguson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1792: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Finlay",
    "Primary Club": "St Andrews University",
    County: "Yorkshire",
    Country: "Scotland",
  },
  1793: {
    Title: "",
    Initials: "JH",
    Surname: "Fouche",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  1794: {
    Title: "Mr",
    Initials: "AJH",
    Surname: "Fowler",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  1795: {
    Title: "",
    Initials: "JH",
    Surname: "Freebairn",
    "Primary Club": "Balaklava",
    County: "",
    Country: "",
  },
  1796: {
    Title: "Mr",
    Initials: "WS",
    Surname: "Freebairn",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  1797: {
    Title: "L/Cpl",
    Initials: "MP",
    Surname: "Garbutt",
    "Primary Club": "R.G.S Guildford",
    County: "Surrey",
    Country: "",
  },
  1798: {
    Title: "",
    Initials: "M",
    Surname: "Garcia",
    "Primary Club": "Euroa",
    County: "Victoria",
    Country: "Australia",
  },
  1799: {
    Title: "Cdt",
    Initials: "JC",
    Surname: "Green",
    "Primary Club": "Yorks NSW ACF",
    County: "",
    Country: "",
  },
  1800: {
    Title: "Mrs",
    Initials: "VW",
    Surname: "Hall",
    "Primary Club": "DCRA",
    County: "Ontario",
    Country: "Canada",
  },
  1801: {
    Title: "",
    Initials: "JS",
    Surname: "Harless II",
    "Primary Club": "USA Young Eagles",
    County: "",
    Country: "United States of America",
  },
  1802: {
    Title: "Cdt",
    Initials: "NA",
    Surname: "Harlow",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  1803: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Harten",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1804: {
    Title: "Cdt",
    Initials: "JJ",
    Surname: "Harvey",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  1805: {
    Title: "Mr",
    Initials: "",
    Surname: "Hashim Mohamad",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  1806: {
    Title: "Mr",
    Initials: "E",
    Surname: "Hewitt",
    "Primary Club": "Old Carthusians",
    County: "Hampshire",
    Country: "England",
  },
  1807: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Hilton-Johnson",
    "Primary Club": "Bedfordians RC",
    County: "Oxfordshire",
    Country: "England",
  },
  1808: {
    Title: "Cdt",
    Initials: "JD",
    Surname: "Hogan",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  1809: {
    Title: "Mr",
    Initials: "LE",
    Surname: "Horwood",
    "Primary Club": "Stawell",
    County: "",
    Country: "Australia",
  },
  1810: {
    Title: "",
    Initials: "SJ",
    Surname: "Howard",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  1811: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Howard-Pearce",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "United Kingdom",
  },
  1812: {
    Title: "Mr",
    Initials: "APH",
    Surname: "Hoyle",
    "Primary Club": "Manchester",
    County: "Cumbria",
    Country: "England",
  },
  1813: {
    Title: "Cdt",
    Initials: "CW",
    Surname: "Hughes",
    "Primary Club": "Campbell College C.C.F",
    County: "",
    Country: "",
  },
  1814: {
    Title: "",
    Initials: "SJB",
    Surname: "Humphrey",
    "Primary Club": "Edinburgh University R.C",
    County: "Grampian",
    Country: "Scotland",
  },
  1815: {
    Title: "",
    Initials: "GF",
    Surname: "Hutton",
    "Primary Club": "",
    County: "Hawaii",
    Country: "United States of America",
  },
  1816: {
    Title: "S/Sgt",
    Initials: "R",
    Surname: "Ingram",
    "Primary Club": "Angus & Dundee Cdt Bn",
    County: "Angus",
    Country: "Scotland",
  },
  1817: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Jackson",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  1818: {
    Title: "Cdt",
    Initials: "PJJ",
    Surname: "Jackson",
    "Primary Club": "Cheltenham College C.C.F",
    County: "Oxfordshire",
    Country: "England",
  },
  1819: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Jarritt",
    "Primary Club": "City RC",
    County: "",
    Country: "England",
  },
  1820: {
    Title: "Mr",
    Initials: "ERT",
    Surname: "Jeens",
    "Primary Club": "Welsh RA",
    County: "London",
    Country: "Wales",
  },
  1821: {
    Title: "Mr",
    Initials: "HRT",
    Surname: "Jeens",
    "Primary Club": "Old Marlburian RC",
    County: "County of London",
    Country: "Wales",
  },
  1822: {
    Title: "Cdt",
    Initials: "RE",
    Surname: "Jimenez",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1823: {
    Title: "Cdt",
    Initials: "HE",
    Surname: "Jones",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1824: {
    Title: "",
    Initials: "V",
    Surname: "Kozicki",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1825: {
    Title: "",
    Initials: "W",
    Surname: "Kuhnt",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  1826: {
    Title: "",
    Initials: "AJ",
    Surname: "Kumar",
    "Primary Club": "OGRC",
    County: "",
    Country: "",
  },
  1827: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Labbe",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1828: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Langley",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  1829: {
    Title: "Cdt",
    Initials: "JP",
    Surname: "Larbastier",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "",
  },
  1830: {
    Title: "Mr",
    Initials: "PDR",
    Surname: "Law",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1831: {
    Title: "",
    Initials: "NJA",
    Surname: "Le Cocq",
    "Primary Club": "Jersey R.A",
    County: "",
    Country: "Jersey",
  },
  1832: {
    Title: "Cdt",
    Initials: "JYH",
    Surname: "Lee",
    "Primary Club": "Epsom College",
    County: "",
    Country: "",
  },
  1833: {
    Title: "",
    Initials: "KM",
    Surname: "MacDonald",
    "Primary Club": "West Atholl",
    County: "",
    Country: "Scotland",
  },
  1834: {
    Title: "",
    Initials: "WA",
    Surname: "MacFarlane",
    "Primary Club": "Canberra RC",
    County: "ACT",
    Country: "Australia",
  },
  1835: {
    Title: "Cdt",
    Initials: "CML",
    Surname: "Macintosh",
    "Primary Club": "Athelings",
    County: "",
    Country: "Scotland",
  },
  1836: {
    Title: "Miss",
    Initials: "T",
    Surname: "Stuart-Gordon",
    "Primary Club": "LMRA",
    County: "Sussex",
    Country: "",
  },
  1837: {
    Title: "Miss",
    Initials: "N",
    Surname: "Malkin",
    "Primary Club": "City of Newcastle RC",
    County: "County Durham",
    Country: "England",
  },
  1838: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Mallet",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1839: {
    Title: "",
    Initials: "SG",
    Surname: "Maree",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  1840: {
    Title: "Miss",
    Initials: "SE",
    Surname: "Marshall",
    "Primary Club": "Edinburgh University R.C",
    County: "Ulster",
    Country: "Ireland",
  },
  1841: {
    Title: "",
    Initials: "CK",
    Surname: "Martin",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1842: {
    Title: "Cdt LS",
    Initials: "AR",
    Surname: "Mathew",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Great Britain",
  },
  1843: {
    Title: "Cdt",
    Initials: "PH",
    Surname: "Mayne",
    "Primary Club": "Uppingham Veterans RC",
    County: "Rutland",
    Country: "England",
  },
  1844: {
    Title: "",
    Initials: "TR",
    Surname: "McInnis",
    "Primary Club": "NCRRA",
    County: "",
    Country: "Canada",
  },
  1845: {
    Title: "Mr",
    Initials: "N",
    Surname: "McKendrick",
    "Primary Club": "OGRE",
    County: "Lincolnshire",
    Country: "England",
  },
  1846: {
    Title: "Miss",
    Initials: "E",
    Surname: "McMullan",
    "Primary Club": "URA",
    County: "Ulster",
    Country: "Ireland",
  },
  1847: {
    Title: "Bdr",
    Initials: "HC",
    Surname: "McNeilly",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  1848: {
    Title: "",
    Initials: "GL",
    Surname: "Mincham",
    "Primary Club": "",
    County: "South Australia",
    Country: "Australia",
  },
  1849: {
    Title: "Miss",
    Initials: "LA",
    Surname: "Moore",
    "Primary Club": "Edinburgh University Rifl",
    County: "East of Scotland",
    Country: "Ireland",
  },
  1850: {
    Title: "Dr",
    Initials: "GP",
    Surname: "Nelson",
    "Primary Club": "Manchester RC",
    County: "Northumberland",
    Country: "England",
  },
  1851: {
    Title: "Cdt",
    Initials: "TR",
    Surname: "Neudorf",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1852: {
    Title: "Cdt",
    Initials: "KW",
    Surname: "Nicoll",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1853: {
    Title: "Cdt",
    Initials: "PJ",
    Surname: "Noble",
    "Primary Club": "Athelings",
    County: "Jersey",
    Country: "Jersey",
  },
  1854: {
    Title: "Miss",
    Initials: "EL",
    Surname: "Norman",
    "Primary Club": "Jersey R.C",
    County: "",
    Country: "Jersey",
  },
  1855: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Nowacki",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "Jersey",
  },
  1856: {
    Title: "Miss",
    Initials: "J",
    Surname: "Nutt",
    "Primary Club": "Dungannon",
    County: "Tyrone",
    Country: "Ireland",
  },
  1857: {
    Title: "",
    Initials: "AP",
    Surname: "Pamment",
    "Primary Club": "SLRC",
    County: "Essex",
    Country: "England",
  },
  1858: {
    Title: "",
    Initials: "HS",
    Surname: "Panesar",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "England",
  },
  1859: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Pascoe",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1860: {
    Title: "",
    Initials: "TR",
    Surname: "Paterson",
    "Primary Club": "Nottingham University",
    County: "",
    Country: "",
  },
  1861: {
    Title: "Cdt",
    Initials: "TE",
    Surname: "Pennock",
    "Primary Club": "Malvern College",
    County: "",
    Country: "",
  },
  1862: {
    Title: "Cdt",
    Initials: "JC",
    Surname: "Peterson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1863: {
    Title: "",
    Initials: "RR",
    Surname: "Philipson-Stow",
    "Primary Club": "A.T.R.C.",
    County: "",
    Country: "",
  },
  1864: {
    Title: "Cdt",
    Initials: "CV",
    Surname: "Phillips",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "",
  },
  1865: {
    Title: "",
    Initials: "M",
    Surname: "Plug",
    "Primary Club": "KNSC",
    County: "",
    Country: "Holland",
  },
  1866: {
    Title: "",
    Initials: "DR",
    Surname: "Proctor",
    "Primary Club": "Reading University",
    County: "",
    Country: "",
  },
  1867: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Quinn",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  1868: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Ranger",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1869: {
    Title: "Mr",
    Initials: "PL",
    Surname: "Redstone",
    "Primary Club": "Canada",
    County: "Hertfordshire",
    Country: "Canada",
  },
  1870: {
    Title: "Miss",
    Initials: "KM",
    Surname: "Reid",
    "Primary Club": "Leeds RC",
    County: "Yorkshire",
    Country: "England",
  },
  1871: {
    Title: "",
    Initials: "CAHS",
    Surname: "Robinson",
    "Primary Club": "Northumbrian OTC",
    County: "Middlesex",
    Country: "England",
  },
  1872: {
    Title: "",
    Initials: "MT",
    Surname: "Ryan",
    "Primary Club": "URA",
    County: "",
    Country: "Ireland",
  },
  1873: {
    Title: "Cdt",
    Initials: "RJ",
    Surname: "Saint",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  1875: {
    Title: "",
    Initials: "O",
    Surname: "Shabbert",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  1876: {
    Title: "",
    Initials: "B",
    Surname: "Shanson",
    "Primary Club": "Edinburgh University R.C",
    County: "County of London",
    Country: "England",
  },
  1877: {
    Title: "Mr",
    Initials: "DMC",
    Surname: "Sharp",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  1878: {
    Title: "Sgt",
    Initials: "G",
    Surname: "Smith",
    "Primary Club": "",
    County: "Angus",
    Country: "Scotland",
  },
  1879: {
    Title: "Miss",
    Initials: "T",
    Surname: "Speir",
    "Primary Club": "Marlow",
    County: "Buckinghamshire",
    Country: "England",
  },
  1880: {
    Title: "Cdt",
    Initials: "ATG",
    Surname: "Squires",
    "Primary Club": "Cheltenham College C.C.F",
    County: "Gloucestershire",
    Country: "England",
  },
  1881: {
    Title: "Cdt Sgt",
    Initials: "BWJ",
    Surname: "Squires",
    "Primary Club": "Cheltenham College C.C.F",
    County: "County of London",
    Country: "England",
  },
  1882: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Stark",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  1883: {
    Title: "",
    Initials: "B",
    Surname: "Starks",
    "Primary Club": "Cheltenham",
    County: "Gloucestershire",
    Country: "United Kingdom",
  },
  1884: {
    Title: "Mr",
    Initials: "RMR",
    Surname: "Stearn",
    "Primary Club": "RNTRC",
    County: "Norfolk",
    Country: "England",
  },
  1885: {
    Title: "Cdt",
    Initials: "RPS",
    Surname: "Stewart",
    "Primary Club": "Cranbrook School",
    County: "Kent",
    Country: "England",
  },
  1886: {
    Title: "",
    Initials: "A",
    Surname: "Steyn",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  1887: {
    Title: "",
    Initials: "S",
    Surname: "Steyn",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  1888: {
    Title: "Cdt",
    Initials: "LB",
    Surname: "Taggart",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1890: {
    Title: "",
    Initials: "JC",
    Surname: "Theriault",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  1891: {
    Title: "",
    Initials: "EL",
    Surname: "Townsend",
    "Primary Club": "C.U.R.A",
    County: "Kent",
    Country: "England",
  },
  1892: {
    Title: "Cdt",
    Initials: "RG",
    Surname: "Tregaskes",
    "Primary Club": "RGS Guildford",
    County: "",
    Country: "",
  },
  1893: {
    Title: "Cdt",
    Initials: "OGP",
    Surname: "Troth",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  1894: {
    Title: "",
    Initials: "RWM",
    Surname: "Tuke",
    "Primary Club": "Old Carthusians",
    County: "Devon",
    Country: "",
  },
  1895: {
    Title: "",
    Initials: "PJ",
    Surname: "van der Walt",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  1896: {
    Title: "",
    Initials: "R",
    Surname: "Von Selle",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  1897: {
    Title: "",
    Initials: "MAP",
    Surname: "Vos",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Holland",
  },
  1898: {
    Title: "",
    Initials: "AG",
    Surname: "Warrian",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  1899: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Watts",
    "Primary Club": "Oxfordshire ACF",
    County: "Oxfordshire",
    Country: "England",
  },
  1900: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Weir",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1901: {
    Title: "Miss",
    Initials: "EA",
    Surname: "Weston",
    "Primary Club": "C.U.R.A",
    County: "Nottinghamshire",
    Country: "England",
  },
  1902: {
    Title: "Cdt",
    Initials: "CR",
    Surname: "Wheatley-Hubbard",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  1903: {
    Title: "Cdt",
    Initials: "B",
    Surname: "White",
    "Primary Club": "Oxfordshire ACF",
    County: "Oxfordshire",
    Country: "England",
  },
  1904: {
    Title: "Cdt",
    Initials: "W",
    Surname: "White",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1905: {
    Title: "",
    Initials: "NA",
    Surname: "Wilkinson",
    "Primary Club": "Sheffield RC",
    County: "Derbyshire",
    Country: "England",
  },
  1906: {
    Title: "",
    Initials: "DJ",
    Surname: "Williams",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "Wales",
  },
  1907: {
    Title: "Cdt",
    Initials: "CA",
    Surname: "Wolsey",
    "Primary Club": "Campbell College C.C.F",
    County: "Down",
    Country: "N. Ireland",
  },
  1908: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Wong",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1909: {
    Title: "Cdt",
    Initials: "JR",
    Surname: "Wyatt",
    "Primary Club": "Athelings",
    County: "Hampshire",
    Country: "England",
  },
  1910: {
    Title: "Mr",
    Initials: "I",
    Surname: "Cheeseman",
    "Primary Club": "ATSC",
    County: "",
    Country: "USA",
  },
  1911: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Clifford",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "Australia",
  },
  1912: {
    Title: "",
    Initials: "PG",
    Surname: "Daniel",
    "Primary Club": "Old Wellingtonians",
    County: "",
    Country: "",
  },
  1913: {
    Title: "Mr",
    Initials: "GV",
    Surname: "Feast",
    "Primary Club": "Radlett",
    County: "Hertfordshire",
    Country: "England",
  },
  1914: {
    Title: "Wg Cdr",
    Initials: "CM",
    Surname: "Fopp",
    "Primary Club": "RAFTRC",
    County: "Middlesex",
    Country: "England",
  },
  1915: {
    Title: "",
    Initials: "DM",
    Surname: "Higginson",
    "Primary Club": "101 R.C",
    County: "Lancashire",
    Country: "England",
  },
  1916: {
    Title: "Mr",
    Initials: "JAC",
    Surname: "McAllister",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  1917: {
    Title: "",
    Initials: "JA",
    Surname: "McKinnell",
    "Primary Club": "Ricochet R.C",
    County: "",
    Country: "",
  },
  1918: {
    Title: "Dr.",
    Initials: "RW",
    Surname: "Nash",
    "Primary Club": "N.R.A",
    County: "",
    Country: "",
  },
  1919: {
    Title: "",
    Initials: "J",
    Surname: "Parker",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  1920: {
    Title: "Mr",
    Initials: "PD",
    Surname: "Pelly",
    "Primary Club": "Old Marlburian RC",
    County: "Sussex",
    Country: "England",
  },
  1921: {
    Title: "",
    Initials: "L",
    Surname: "Pearton",
    "Primary Club": "Senghenydd",
    County: "Monmouthshire",
    Country: "Wales",
  },
  1922: {
    Title: "Mr",
    Initials: "HW",
    Surname: "Riley",
    "Primary Club": "City RC",
    County: "County of London",
    Country: "England",
  },
  1923: {
    Title: "",
    Initials: "HJH",
    Surname: "Stafford",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  1924: {
    Title: "",
    Initials: "J",
    Surname: "Hibbins",
    "Primary Club": "Dungannon",
    County: "Cambridgeshire",
    Country: "England",
  },
  1925: {
    Title: "",
    Initials: "MW",
    Surname: "Hibbins",
    "Primary Club": "",
    County: "Cambridgeshire",
    Country: "England",
  },
  1926: {
    Title: "Mr",
    Initials: "M",
    Surname: "Maksimovic",
    "Primary Club": "LMRA",
    County: "Lincolnshire",
    Country: "England",
  },
  1927: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Garnett",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  1928: {
    Title: "",
    Initials: "T",
    Surname: "O'Hanlon-Smith",
    "Primary Club": "",
    County: "Essex",
    Country: "United Kingdom",
  },
  1929: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Good",
    "Primary Club": "Hurstpierpoint",
    County: "Sussex",
    Country: "England",
  },
  1930: {
    Title: "",
    Initials: "CR",
    Surname: "Davis",
    "Primary Club": "Ibis R.C",
    County: "",
    Country: "",
  },
  1931: {
    Title: "",
    Initials: "S",
    Surname: "Ingram",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  1932: {
    Title: "Mrs",
    Initials: "MM",
    Surname: "Norman",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  1933: {
    Title: "",
    Initials: "N",
    Surname: "Washington",
    "Primary Club": "N.R.A",
    County: "Oxfordshire",
    Country: "England",
  },
  1934: {
    Title: "Mr",
    Initials: "S",
    Surname: "Robinson",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  1935: {
    Title: "Mr",
    Initials: "EJ",
    Surname: "Hobbs",
    "Primary Club": "",
    County: "South Wales",
    Country: "Wales",
  },
  1936: {
    Title: "",
    Initials: "AM",
    Surname: "Cooney",
    "Primary Club": "Langar",
    County: "Nottinghamshire",
    Country: "England",
  },
  1937: {
    Title: "",
    Initials: "A",
    Surname: "Bowry",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  1938: {
    Title: "",
    Initials: "SB",
    Surname: "Pitcher",
    "Primary Club": "Jersey R.A",
    County: "Jersey",
    Country: "Great Britain",
  },
  1939: {
    Title: "",
    Initials: "AMC",
    Surname: "Wilson",
    "Primary Club": "NRA",
    County: "",
    Country: "Ireland",
  },
  1940: {
    Title: "Mr",
    Initials: "A",
    Surname: "Coleman-Cooke",
    "Primary Club": "Welwyn Phoenix",
    County: "Yorkshire",
    Country: "England",
  },
  1941: {
    Title: "",
    Initials: "AD",
    Surname: "Christofi",
    "Primary Club": "Chichester R.C",
    County: "",
    Country: "",
  },
  1942: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Chapman-Sheath",
    "Primary Club": "NLRC",
    County: "Lincolnshire",
    Country: "England",
  },
  1943: {
    Title: "",
    Initials: "AG",
    Surname: "Baker",
    "Primary Club": "",
    County: "Derbyshire",
    Country: "",
  },
  1944: {
    Title: "Cdt",
    Initials: "FL",
    Surname: "Beadle",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  1945: {
    Title: "",
    Initials: "BHG",
    Surname: "Boehme",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  1946: {
    Title: "",
    Initials: "RT",
    Surname: "Bradley",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "",
  },
  1947: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Burke",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  1948: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Cardinal",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  1949: {
    Title: "",
    Initials: "CF",
    Surname: "Clark",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1950: {
    Title: "",
    Initials: "GJ",
    Surname: "Cleary",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  1951: {
    Title: "Cdt",
    Initials: "EK",
    Surname: "Clipsham",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  1952: {
    Title: "",
    Initials: "MJ",
    Surname: "Collings",
    "Primary Club": "Te Puke",
    County: "",
    Country: "New Zealand",
  },
  1953: {
    Title: "",
    Initials: "DL",
    Surname: "Crandall",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1954: {
    Title: "Mr",
    Initials: "CMS",
    Surname: "Dawes",
    "Primary Club": "Old Guildfordians RC",
    County: "",
    Country: "",
  },
  1955: {
    Title: "Miss",
    Initials: "RC",
    Surname: "Dixon",
    "Primary Club": "OURC",
    County: "Oxfordshire",
    Country: "England",
  },
  1956: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Donnelly",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "S Glamorgan",
    Country: "Wales",
  },
  1957: {
    Title: "Cdt",
    Initials: "EM",
    Surname: "Dunnet",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  1958: {
    Title: "Mr",
    Initials: "J",
    Surname: "Fong Yew",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "Trinidad",
  },
  1959: {
    Title: "Miss",
    Initials: "HA",
    Surname: "Foster",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  1960: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Friling",
    "Primary Club": "ATSC",
    County: "Gloucestershire",
    Country: "England",
  },
  1961: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Glover",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "S Glamorgan",
    Country: "Wales",
  },
  1962: {
    Title: "Cdt",
    Initials: "NV",
    Surname: "Hall",
    "Primary Club": "Marlborough College",
    County: "Oxfordshire",
    Country: "England",
  },
  1963: {
    Title: "",
    Initials: "WL",
    Surname: "Hammers",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  1964: {
    Title: "",
    Initials: "DH",
    Surname: "Hausmann",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  1965: {
    Title: "Dr",
    Initials: "SA",
    Surname: "Hebbes",
    "Primary Club": "City RC",
    County: "Gloucestershire",
    Country: "England",
  },
  1966: {
    Title: "Cdt LCpl",
    Initials: "NJE",
    Surname: "Holland",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  1967: {
    Title: "",
    Initials: "MA",
    Surname: "Homer",
    "Primary Club": "Trinidad R.A",
    County: "",
    Country: "Trinidad",
  },
  1968: {
    Title: "",
    Initials: "RA",
    Surname: "Jones",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1969: {
    Title: "",
    Initials: "JGRC",
    Surname: "Jowett",
    "Primary Club": "C.U.R.A",
    County: "Surrey",
    Country: "England",
  },
  1970: {
    Title: "Dr",
    Initials: "CL",
    Surname: "Chorlton",
    "Primary Club": "101 RC",
    County: "Lancashire",
    Country: "England",
  },
  1971: {
    Title: "Mr",
    Initials: "JWE",
    Surname: "Lothian",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  1972: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Lyons",
    "Primary Club": "OURC",
    County: "Ulster",
    Country: "Ireland",
  },
  1973: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "MacKeith",
    "Primary Club": "Old Epsomian RC",
    County: "Somerset",
    Country: "Scotland",
  },
  1974: {
    Title: "Mr",
    Initials: "JCF",
    Surname: "Marshall",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Scotland",
  },
  1975: {
    Title: "Mr",
    Initials: "KC",
    Surname: "Marshall",
    "Primary Club": "NRA",
    County: "Berkshire",
    Country: "Wales",
  },
  1976: {
    Title: "",
    Initials: "MP",
    Surname: "McCullough",
    "Primary Club": "OURC",
    County: "Ulster",
    Country: "Ireland",
  },
  1977: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Morris",
    "Primary Club": "Stock Exchange RC",
    County: "Surrey",
    Country: "England",
  },
  1978: {
    Title: "Maj",
    Initials: "DJC",
    Surname: "Nelson",
    "Primary Club": "Jamaica RA",
    County: "",
    Country: "Jamaica",
  },
  1979: {
    Title: "Mr",
    Initials: "TJD",
    Surname: "Raincock",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  1980: {
    Title: "Mr",
    Initials: "G",
    Surname: "Rasmussen",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  1981: {
    Title: "Cdt Sgt",
    Initials: "AJ",
    Surname: "Read",
    "Primary Club": "2297 Sqn ATC",
    County: "Devon",
    Country: "England",
  },
  1982: {
    Title: "Miss",
    Initials: "IA",
    Surname: "Reder",
    "Primary Club": "Cheltenham College C.C.F",
    County: "",
    Country: "Ukraine",
  },
  1983: {
    Title: "Mr",
    Initials: "JMG",
    Surname: "Redman",
    "Primary Club": "NRA",
    County: "Kent",
    Country: "Ireland",
  },
  1984: {
    Title: "Cdt Cpl",
    Initials: "AJ",
    Surname: "Richmond",
    "Primary Club": "2390 Sqn ATC",
    County: "",
    Country: "",
  },
  1985: {
    Title: "",
    Initials: "BA",
    Surname: "Rollins",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  1986: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Scaglione",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  1987: {
    Title: "",
    Initials: "AM",
    Surname: "Sedgwick",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "United Kingdom",
  },
  1988: {
    Title: "Mr",
    Initials: "BMA",
    Surname: "Stevens",
    "Primary Club": "City RC",
    County: "London",
    Country: "England",
  },
  1989: {
    Title: "Mr",
    Initials: "M",
    Surname: "Stewart",
    "Primary Club": "West of Scotland RC",
    County: "West of Scotland",
    Country: "Ireland",
  },
  1990: {
    Title: "Cdt LCpl",
    Initials: "AJ",
    Surname: "Stilwell",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  1991: {
    Title: "Mr",
    Initials: "PRD",
    Surname: "Stock",
    "Primary Club": "Jersey RA",
    County: "Norfolk",
    Country: "Jersey",
  },
  1992: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Thomson",
    "Primary Club": "Old Bradfieldians",
    County: "Surrey",
    Country: "",
  },
  1993: {
    Title: "Mr",
    Initials: "N",
    Surname: "Thorburn",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  1994: {
    Title: "Mr",
    Initials: "J",
    Surname: "Wallace",
    "Primary Club": "ATSC",
    County: "Co Down",
    Country: "Northern Ireland",
  },
  1995: {
    Title: "Mr",
    Initials: "REWD",
    Surname: "Watt",
    "Primary Club": "OURC",
    County: "Ulster",
    Country: "Ireland",
  },
  1996: {
    Title: "",
    Initials: "RJ",
    Surname: "Wayman",
    "Primary Club": "Durham University",
    County: "Northants - Leics - Rutland",
    Country: "Great Britain",
  },
  1997: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Whitby",
    "Primary Club": "Hopton Supper Club",
    County: "",
    Country: "England",
  },
  1998: {
    Title: "Cdt Sgt",
    Initials: "E",
    Surname: "Whitcombe",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  1999: {
    Title: "Mr",
    Initials: "DF",
    Surname: "Williams",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  2000: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Willis",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2001: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Worthington",
    "Primary Club": "607(Nearmouth)Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  2002: {
    Title: "Mr",
    Initials: "BH",
    Surname: "Yates",
    "Primary Club": "Uppingham Veterans RC",
    County: "Lincolnshire",
    Country: "England",
  },
  2003: {
    Title: "Mr",
    Initials: "SM",
    Surname: "Friling",
    "Primary Club": "ATSC",
    County: "Gloucestershire",
    Country: "England",
  },
  2004: {
    Title: "Mr",
    Initials: "C",
    Surname: "Shorthouse",
    "Primary Club": "British Snipers",
    County: "Surrey",
    Country: "England",
  },
  2005: {
    Title: "",
    Initials: "WS",
    Surname: "Cowell",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2006: {
    Title: "",
    Initials: "J",
    Surname: "Toye",
    "Primary Club": "OBRC",
    County: "",
    Country: "",
  },
  2007: {
    Title: "",
    Initials: "PC",
    Surname: "Duxbury",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  2008: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Dyball",
    "Primary Club": "Wandsworth FBRC",
    County: "Dorset",
    Country: "Scotland",
  },
  2009: {
    Title: "Mr",
    Initials: "F",
    Surname: "De Castro",
    "Primary Club": "TNV France",
    County: "",
    Country: "France",
  },
  2010: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Scott",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "Scotland",
  },
  2011: {
    Title: "Mr",
    Initials: "S",
    Surname: "Larter-Whitcher",
    "Primary Club": "Hampshire RA",
    County: "Hampshire",
    Country: "Germany",
  },
  2012: {
    Title: "Dr",
    Initials: "F",
    Surname: "O'Sullivan",
    "Primary Club": "N.R.A",
    County: "Devon",
    Country: "Ireland",
  },
  2013: {
    Title: "MEng",
    Initials: "DJ",
    Surname: "Doyle",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "",
  },
  2014: {
    Title: "Mr",
    Initials: "SW",
    Surname: "Kemp",
    "Primary Club": "Old Johnian RC",
    County: "Hampshire",
    Country: "England",
  },
  2015: {
    Title: "Dr",
    Initials: "P",
    Surname: "Mackey",
    "Primary Club": "S.E.R.C.",
    County: "Sussex",
    Country: "N. Ireland",
  },
  2016: {
    Title: "Mr",
    Initials: "AI",
    Surname: "Lawrie",
    "Primary Club": "Old Stamfordians",
    County: "Lincolnshire",
    Country: "Scotland",
  },
  2017: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Lawrie",
    "Primary Club": "Old Stamfordians",
    County: "Lincolnshire",
    Country: "Scotland",
  },
  2018: {
    Title: "Mrs",
    Initials: "SG",
    Surname: "Sirett",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Jersey",
  },
  2019: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Curd",
    "Primary Club": "Folkestone RC",
    County: "Kent",
    Country: "England",
  },
  2020: {
    Title: "",
    Initials: "CJH",
    Surname: "Abri",
    "Primary Club": "Bedford Mod Schl",
    County: "Bedfordshire",
    Country: "England",
  },
  2021: {
    Title: "Mrs",
    Initials: "JG",
    Surname: "Adamson",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2022: {
    Title: "",
    Initials: "JM",
    Surname: "Adell",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2023: {
    Title: "",
    Initials: "MR",
    Surname: "Al-Warith Al- Shidi",
    "Primary Club": "Cheltenham College C.C.F",
    County: "",
    Country: "Oman",
  },
  2024: {
    Title: "Mr",
    Initials: "J",
    Surname: "Alford",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "",
  },
  2025: {
    Title: "Cdt",
    Initials: "Z",
    Surname: "Amran",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  2026: {
    Title: "Miss",
    Initials: "SIM",
    Surname: "Anderson",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  2027: {
    Title: "OCdt",
    Initials: "JM",
    Surname: "Anthistle",
    "Primary Club": "A.T.S.C",
    County: "Wiltshire",
    Country: "Great Britain",
  },
  2028: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Attali",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2029: {
    Title: "Mr",
    Initials: "A",
    Surname: "Othman",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  2030: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Baker",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "S Glamorgan",
    Country: "Wales",
  },
  2031: {
    Title: "Miss",
    Initials: "L",
    Surname: "Barnes",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2032: {
    Title: "SSgt",
    Initials: "N",
    Surname: "Batai",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2033: {
    Title: "Mrs",
    Initials: "AJ",
    Surname: "Bates",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2034: {
    Title: "",
    Initials: "CA",
    Surname: "Bayne",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2035: {
    Title: "",
    Initials: "J",
    Surname: "Beltran",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  2037: {
    Title: "",
    Initials: "EC",
    Surname: "Biggar",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2038: {
    Title: "",
    Initials: "DJ",
    Surname: "Biggs",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2039: {
    Title: "Flt Lt",
    Initials: "K",
    Surname: "Bissell",
    "Primary Club": "LMRA",
    County: "Derbyshire",
    Country: "England",
  },
  2040: {
    Title: "",
    Initials: "D",
    Surname: "Blair",
    "Primary Club": "Guyana N.R.A",
    County: "",
    Country: "Guyana",
  },
  2041: {
    Title: "",
    Initials: "HM",
    Surname: "Blair",
    "Primary Club": "",
    County: "Kent",
    Country: "New Zealand",
  },
  2042: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Blewitt",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2043: {
    Title: "",
    Initials: "H",
    Surname: "Bock",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  2044: {
    Title: "Mr",
    Initials: "RES",
    Surname: "Bogie",
    "Primary Club": "City RC",
    County: "",
    Country: "Ireland",
  },
  2045: {
    Title: "Mrs",
    Initials: "N",
    Surname: "Borrett",
    "Primary Club": "Pretoria-East",
    County: "",
    Country: "South Africa",
  },
  2046: {
    Title: "",
    Initials: "AJ",
    Surname: "Bosman",
    "Primary Club": "Wonderboom RC",
    County: "",
    Country: "South Africa",
  },
  2048: {
    Title: "",
    Initials: "CN",
    Surname: "Branson",
    "Primary Club": "RAFTRC",
    County: "Cumbria",
    Country: "England",
  },
  2049: {
    Title: "",
    Initials: "M",
    Surname: "Brazeau",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2050: {
    Title: "",
    Initials: "TF",
    Surname: "Breedt",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2051: {
    Title: "Cdt",
    Initials: "DC",
    Surname: "Britten",
    "Primary Club": "St. Johns School",
    County: "Surrey",
    Country: "England",
  },
  2052: {
    Title: "Cdt",
    Initials: "KL",
    Surname: "Buffoni",
    "Primary Club": "Athelings",
    County: "Yorkshire",
    Country: "",
  },
  2053: {
    Title: "",
    Initials: "B",
    Surname: "Bullock",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2054: {
    Title: "SAC",
    Initials: "FA",
    Surname: "Burgwyn",
    "Primary Club": "R.A.F. Strike Command",
    County: "Wiltshire",
    Country: "",
  },
  2055: {
    Title: "Mr",
    Initials: "NH",
    Surname: "Burton",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  2056: {
    Title: "Cdt",
    Initials: "NJB",
    Surname: "Bussell",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "England",
  },
  2057: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Butler",
    "Primary Club": "607(Nearmouth)Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  2058: {
    Title: "",
    Initials: "DR",
    Surname: "Cadden",
    "Primary Club": "Australian Rifle Team",
    County: "Queensland",
    Country: "Australia",
  },
  2059: {
    Title: "Cdt Cpl",
    Initials: "L",
    Surname: "Chan",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  2060: {
    Title: "LCP",
    Initials: "JB",
    Surname: "Charlton",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "England",
  },
  2061: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Cheruyiot",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2062: {
    Title: "",
    Initials: "R",
    Surname: "Cheu-Choi",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Holland",
  },
  2063: {
    Title: "",
    Initials: "KD",
    Surname: "Chittock",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2064: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Churchill",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2065: {
    Title: "Cdt",
    Initials: "AJ",
    Surname: "Coates",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2066: {
    Title: "Cdt",
    Initials: "BP",
    Surname: "Cochrane",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  2067: {
    Title: "",
    Initials: "AJ",
    Surname: "Coello",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2068: {
    Title: "Cdt",
    Initials: "ETC",
    Surname: "Collas",
    "Primary Club": "Wellington College",
    County: "Oxfordshire",
    Country: "",
  },
  2069: {
    Title: "",
    Initials: "NL",
    Surname: "Collings",
    "Primary Club": "Windsor RC",
    County: "Buckinghamshire",
    Country: "England",
  },
  2070: {
    Title: "",
    Initials: "CR",
    Surname: "Collins",
    "Primary Club": "Univerity of Southampton",
    County: "Hampshire",
    Country: "Scotland",
  },
  2071: {
    Title: "",
    Initials: "JA",
    Surname: "Considine",
    "Primary Club": "Bradfield College",
    County: "",
    Country: "",
  },
  2072: {
    Title: "",
    Initials: "T",
    Surname: "Cooper",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2073: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Cowan",
    "Primary Club": "Oakham School",
    County: "Rutland",
    Country: "England",
  },
  2074: {
    Title: "",
    Initials: "NF",
    Surname: "Crawford",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2075: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Crema",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2076: {
    Title: "Sgt",
    Initials: "N",
    Surname: "Cumming Bruce",
    "Primary Club": "",
    County: "Berkshire",
    Country: "",
  },
  2077: {
    Title: "Cdt",
    Initials: "DJ",
    Surname: "Cummings",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  2078: {
    Title: "Cpl",
    Initials: "T",
    Surname: "Dack",
    "Primary Club": "",
    County: "South Yorkshire",
    Country: "United Kingdom",
  },
  2079: {
    Title: "Mr",
    Initials: "NT",
    Surname: "Davies",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  2080: {
    Title: "",
    Initials: "R",
    Surname: "Davis",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  2082: {
    Title: "",
    Initials: "JPR",
    Surname: "De Quidt",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "United Kingdom",
  },
  2083: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Dodson",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2084: {
    Title: "",
    Initials: "SM",
    Surname: "Dodson",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2085: {
    Title: "",
    Initials: "JAP",
    Surname: "Du Toit",
    "Primary Club": "S A Veterans",
    County: "",
    Country: "South Africa",
  },
  2086: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Dunbar",
    "Primary Club": "ATSC",
    County: "Devon",
    Country: "England",
  },
  2087: {
    Title: "",
    Initials: "GR",
    Surname: "Duncan",
    "Primary Club": "Central Australian RC",
    County: "",
    Country: "Australia",
  },
  2088: {
    Title: "Mrs",
    Initials: "R",
    Surname: "Duncan",
    "Primary Club": "Australia",
    County: "Queensland",
    Country: "Australia",
  },
  2089: {
    Title: "",
    Initials: "WK",
    Surname: "Dunlap",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2090: {
    Title: "Cadet",
    Initials: "JP",
    Surname: "Edmund Hotton",
    "Primary Club": "R.G.S Guildford",
    County: "Surrey",
    Country: "England",
  },
  2091: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Emanuels",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2092: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Ferguson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2093: {
    Title: "",
    Initials: "W",
    Surname: "Flentje",
    "Primary Club": "Altcar RC",
    County: "Cheshire",
    Country: "England",
  },
  2094: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Foden",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2095: {
    Title: "",
    Initials: "M",
    Surname: "Forrest-Browning",
    "Primary Club": "USA Goodwill",
    County: "",
    Country: "United States of America",
  },
  2096: {
    Title: "Sgt",
    Initials: "OJL",
    Surname: "Foster",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "England",
  },
  2097: {
    Title: "",
    Initials: "TI",
    Surname: "Fouche",
    "Primary Club": "S A Veterans",
    County: "",
    Country: "South Africa",
  },
  2098: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Fourie",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2099: {
    Title: "Cdt Sgt",
    Initials: "HH",
    Surname: "Fowler",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  2100: {
    Title: "",
    Initials: "B",
    Surname: "Fox",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2101: {
    Title: "",
    Initials: "J",
    Surname: "Fox",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2102: {
    Title: "Cdt LCpl",
    Initials: "JB",
    Surname: "Frazer Pimblett",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "England",
  },
  2103: {
    Title: "Miss",
    Initials: "AK",
    Surname: "Freebairn",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2104: {
    Title: "",
    Initials: "AS",
    Surname: "Frost",
    "Primary Club": "C.U.R.A",
    County: "Hampshire",
    Country: "England",
  },
  2105: {
    Title: "",
    Initials: "J",
    Surname: "Gaines",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2106: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Gauthier",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2107: {
    Title: "",
    Initials: "AE",
    Surname: "Gerald",
    "Primary Club": "Cheltenham College C.C.F",
    County: "Gloucestershire",
    Country: "England",
  },
  2108: {
    Title: "",
    Initials: "AJ",
    Surname: "Gibbs",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2109: {
    Title: "Cdt",
    Initials: "KK",
    Surname: "Gillam",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "United Kingdom",
  },
  2110: {
    Title: "Cdt",
    Initials: "CE",
    Surname: "Glen",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  2111: {
    Title: "Cdt Cpl",
    Initials: "RPA",
    Surname: "Goatly",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "England",
  },
  2112: {
    Title: "Cdt Cpl",
    Initials: "F",
    Surname: "Golden",
    "Primary Club": "111 (Sunderland) Sqn",
    County: "County Durham",
    Country: "England",
  },
  2113: {
    Title: "",
    Initials: "JL",
    Surname: "Goldfarb",
    "Primary Club": "University of Reading",
    County: "Berkshire",
    Country: "England",
  },
  2114: {
    Title: "",
    Initials: "WP",
    Surname: "Grenness",
    "Primary Club": "Benalla",
    County: "",
    Country: "Australia",
  },
  2115: {
    Title: "",
    Initials: "AC",
    Surname: "Greyling",
    "Primary Club": "S A Veterans",
    County: "Gauteng North",
    Country: "South Africa",
  },
  2116: {
    Title: "",
    Initials: "CFS",
    Surname: "Greyling",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2117: {
    Title: "Mr",
    Initials: "MG",
    Surname: "Grimsdell",
    "Primary Club": "CGRA",
    County: "Gloucestershire",
    Country: "England",
  },
  2118: {
    Title: "",
    Initials: "CW",
    Surname: "Grinham",
    "Primary Club": "Yorkshire R.A",
    County: "NSW",
    Country: "Australia",
  },
  2119: {
    Title: "",
    Initials: "JJ",
    Surname: "Grobler",
    "Primary Club": "South Africa",
    County: "Gauteng",
    Country: "South Africa",
  },
  2120: {
    Title: "",
    Initials: "R",
    Surname: "Gross",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2121: {
    Title: "Cpl",
    Initials: "ED",
    Surname: "Halford",
    "Primary Club": "Oxford University RC",
    County: "Kent",
    Country: "England",
  },
  2122: {
    Title: "Mr",
    Initials: "",
    Surname: "Hamdan Rahmat",
    "Primary Club": "",
    County: "",
    Country: "Malaysia",
  },
  2123: {
    Title: "",
    Initials: "RJ",
    Surname: "Hassall",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2124: {
    Title: "",
    Initials: "AB",
    Surname: "Hawes",
    "Primary Club": "Brisbane RC",
    County: "Queensland",
    Country: "Australia",
  },
  2125: {
    Title: "",
    Initials: "CHE",
    Surname: "Hawthorne",
    "Primary Club": "S A Veterans",
    County: "Kwazulu-Natal",
    Country: "South Africa",
  },
  2126: {
    Title: "Miss",
    Initials: "SL",
    Surname: "Hayes",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "",
  },
  2127: {
    Title: "Maj",
    Initials: "NT",
    Surname: "Healy",
    "Primary Club": "ATSC",
    County: "Ulster",
    Country: "Ireland",
  },
  2128: {
    Title: "",
    Initials: "PJ",
    Surname: "Hearle",
    "Primary Club": "Univ of Qld",
    County: "Queensland",
    Country: "Australia",
  },
  2129: {
    Title: "Cdt",
    Initials: "LH",
    Surname: "Hearn",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2130: {
    Title: "Mr",
    Initials: "RJK",
    Surname: "Heathcote",
    "Primary Club": "Newcastle University",
    County: "Hertfordshire",
    Country: "England",
  },
  2131: {
    Title: "",
    Initials: "WE",
    Surname: "Hedley",
    "Primary Club": "A.T.R.C.",
    County: "Devon",
    Country: "England",
  },
  2132: {
    Title: "Maj",
    Initials: "JD",
    Surname: "Hoham",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2133: {
    Title: "",
    Initials: "T",
    Surname: "Hoham",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2134: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Horton",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "England",
  },
  2135: {
    Title: "Cdt Sgt",
    Initials: "WAM",
    Surname: "Howard",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  2136: {
    Title: "",
    Initials: "J",
    Surname: "Hudak",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2137: {
    Title: "",
    Initials: "G",
    Surname: "Ilse",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  2138: {
    Title: "",
    Initials: "NR",
    Surname: "Ingham",
    "Primary Club": "",
    County: "Kent",
    Country: "England",
  },
  2139: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Ippolito",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2140: {
    Title: "",
    Initials: "HC",
    Surname: "Jacobs",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2141: {
    Title: "Cdt",
    Initials: "RS",
    Surname: "Jelley",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "",
  },
  2142: {
    Title: "Mr",
    Initials: "R",
    Surname: "Johansen",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2143: {
    Title: "",
    Initials: "R",
    Surname: "Jones",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2144: {
    Title: "",
    Initials: "BS",
    Surname: "Joubert",
    "Primary Club": "S A Veterans",
    County: "Gauteng North",
    Country: "South Africa",
  },
  2145: {
    Title: "Cpl",
    Initials: "FF",
    Surname: "Kalu",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  2146: {
    Title: "Mr",
    Initials: "",
    Surname: "Kamaruddin Mat Noh",
    "Primary Club": "",
    County: "",
    Country: "Malaysia",
  },
  2147: {
    Title: "GySgt",
    Initials: "DB",
    Surname: "Karcher",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2148: {
    Title: "",
    Initials: "BR",
    Surname: "Kaufman",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2149: {
    Title: "Cdt",
    Initials: "CN",
    Surname: "Kendrick",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2150: {
    Title: "Miss",
    Initials: "AL",
    Surname: "Kent",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "",
  },
  2151: {
    Title: "",
    Initials: "P",
    Surname: "Kerr",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2152: {
    Title: "ACP",
    Initials: "S",
    Surname: "Kihara",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  2153: {
    Title: "Mr",
    Initials: "RDP",
    Surname: "Knapstein",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2154: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Koda",
    "Primary Club": "Wellington College",
    County: "",
    Country: "",
  },
  2155: {
    Title: "",
    Initials: "H",
    Surname: "Konrad",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2156: {
    Title: "",
    Initials: "H",
    Surname: "Kremer",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2157: {
    Title: "",
    Initials: "PA",
    Surname: "LaBerge",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2158: {
    Title: "Mr",
    Initials: "S",
    Surname: "Lai",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2159: {
    Title: "",
    Initials: "W",
    Surname: "Lair",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2160: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Lariviere",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2161: {
    Title: "Miss",
    Initials: "C",
    Surname: "Le Maitre",
    "Primary Club": "101 R.C",
    County: "Lancashire",
    Country: "England",
  },
  2162: {
    Title: "",
    Initials: "CL",
    Surname: "Leister",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2163: {
    Title: "",
    Initials: "S",
    Surname: "Letoiya",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2164: {
    Title: "",
    Initials: "DKK",
    Surname: "Lin",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "",
  },
  2165: {
    Title: "Cdt",
    Initials: "TW",
    Surname: "Llanwarne",
    "Primary Club": "",
    County: "Kent",
    Country: "England",
  },
  2167: {
    Title: "Mrs",
    Initials: "AMJ",
    Surname: "Mace",
    "Primary Club": "N.L.R.C.",
    County: "County of London",
    Country: "France",
  },
  2168: {
    Title: "P/C",
    Initials: "J",
    Surname: "Macharia",
    "Primary Club": "Kenya Regiment R.C",
    County: "",
    Country: "Kenya",
  },
  2169: {
    Title: "",
    Initials: "GJ",
    Surname: "Maio",
    "Primary Club": "University of Reading",
    County: "Berkshire",
    Country: "England",
  },
  2170: {
    Title: "Miss",
    Initials: "R",
    Surname: "Maly",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2171: {
    Title: "",
    Initials: "RM",
    Surname: "Mango",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2172: {
    Title: "Cdt",
    Initials: "BJR",
    Surname: "Marengo",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  2173: {
    Title: "Cdt",
    Initials: "CP",
    Surname: "Marshall",
    "Primary Club": "111 (Sunderland) Sqn",
    County: "County Durham",
    Country: "England",
  },
  2174: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Marshall",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  2175: {
    Title: "Cdt LCpl",
    Initials: "R",
    Surname: "Martin",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2176: {
    Title: "",
    Initials: "ME",
    Surname: "Maurer",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2177: {
    Title: "",
    Initials: "MW",
    Surname: "Mayo",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2178: {
    Title: "Mr",
    Initials: "TTW",
    Surname: "McBride",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2179: {
    Title: "",
    Initials: "AC",
    Surname: "McCready",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2180: {
    Title: "",
    Initials: "JA",
    Surname: "McKerron",
    "Primary Club": "",
    County: "Ulster",
    Country: "Ireland",
  },
  2181: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "McQuillan",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  2182: {
    Title: "Cdt",
    Initials: "N",
    Surname: "McRae",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2183: {
    Title: "Cdt",
    Initials: "NAL",
    Surname: "Meredith",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  2184: {
    Title: "",
    Initials: "R",
    Surname: "Metaxas",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2185: {
    Title: "",
    Initials: "LP",
    Surname: "Metcalf",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2186: {
    Title: "Mr",
    Initials: "MRM",
    Surname: "Millar",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2187: {
    Title: "Mr",
    Initials: "R",
    Surname: "Modenbach",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2188: {
    Title: "",
    Initials: "T",
    Surname: "Mollwitz",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2189: {
    Title: "",
    Initials: "JF",
    Surname: "Monchiet",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  2190: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Montaser",
    "Primary Club": "607(Nearmouth)Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  2191: {
    Title: "",
    Initials: "H",
    Surname: "Mruck",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2193: {
    Title: "",
    Initials: "BK",
    Surname: "Muller",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2194: {
    Title: "Cpl",
    Initials: "P",
    Surname: "Munene",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2195: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Muthee",
    "Primary Club": "Kenya R.A",
    County: "",
    Country: "Kenya",
  },
  2196: {
    Title: "",
    Initials: "J",
    Surname: "Myburg",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  2197: {
    Title: "Ms",
    Initials: "VD",
    Surname: "Newman",
    "Primary Club": "Jamaica R.A",
    County: "",
    Country: "Jamaica",
  },
  2198: {
    Title: "Cdt",
    Initials: "TF",
    Surname: "Newsome",
    "Primary Club": "",
    County: "Yorkshire",
    Country: "England",
  },
  2199: {
    Title: "Cdt JCpl",
    Initials: "MP",
    Surname: "Nieuwburg",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2200: {
    Title: "Cdt Cpl",
    Initials: "SW",
    Surname: "Noble",
    "Primary Club": "Campbell College C.C.F",
    County: "Down",
    Country: "N. Ireland",
  },
  2201: {
    Title: "",
    Initials: "PN",
    Surname: "Noss",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2202: {
    Title: "",
    Initials: "S",
    Surname: "Ohlinger",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2203: {
    Title: "",
    Initials: "H",
    Surname: "Orav",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2204: {
    Title: "Miss",
    Initials: "S",
    Surname: "Pagel",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2205: {
    Title: "",
    Initials: "TJ",
    Surname: "Paterson",
    "Primary Club": "Newcastle Uni. R.C",
    County: "Berkshire",
    Country: "England",
  },
  2206: {
    Title: "",
    Initials: "PS",
    Surname: "Pearce",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2207: {
    Title: "Cdt",
    Initials: "MDA",
    Surname: "Peebles",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  2208: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Peters",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2209: {
    Title: "",
    Initials: "FO",
    Surname: "Pinyon",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2210: {
    Title: "Mrs",
    Initials: "I",
    Surname: "Pioche",
    "Primary Club": "",
    County: "",
    Country: "France",
  },
  2211: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Pioche",
    "Primary Club": "",
    County: "",
    Country: "France",
  },
  2212: {
    Title: "Cdt",
    Initials: "AE",
    Surname: "Poisson",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2213: {
    Title: "",
    Initials: "NMS",
    Surname: "Pradham",
    "Primary Club": "Cheltenham College C.C.F",
    County: "",
    Country: "Wales",
  },
  2214: {
    Title: "Mr",
    Initials: "E",
    Surname: "Praslick",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  2215: {
    Title: "",
    Initials: "NF",
    Surname: "Pringle",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2216: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Purdy",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  2217: {
    Title: "Cdt Sgt",
    Initials: "RL",
    Surname: "Raybold",
    "Primary Club": "Bedford Mod Schl",
    County: "",
    Country: "",
  },
  2218: {
    Title: "",
    Initials: "IH",
    Surname: "Rayner",
    "Primary Club": "Univ of Qld",
    County: "Queensland",
    Country: "Australia",
  },
  2219: {
    Title: "Cdt LCpl",
    Initials: "BM",
    Surname: "Rees",
    "Primary Club": "Oxfordshire ACF",
    County: "Buckinghamshire",
    Country: "England",
  },
  2220: {
    Title: "",
    Initials: "KR",
    Surname: "Reeve",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2221: {
    Title: "",
    Initials: "MO",
    Surname: "Richards",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "England",
  },
  2222: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Richardson",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  2223: {
    Title: "",
    Initials: "S",
    Surname: "Riles",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2224: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Rissanen",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2225: {
    Title: "",
    Initials: "JA",
    Surname: "Roach",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2226: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Robert",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2227: {
    Title: "Dr",
    Initials: "JG",
    Surname: "Robertson",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  2228: {
    Title: "P/C",
    Initials: "MK",
    Surname: "Rop",
    "Primary Club": "Kenya Regiment R.C",
    County: "",
    Country: "Kenya",
  },
  2229: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Ross",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2230: {
    Title: "",
    Initials: "JP",
    Surname: "Rousseau",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  2231: {
    Title: "Mr",
    Initials: "R",
    Surname: "Mohamad",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  2232: {
    Title: "CWO3",
    Initials: "PE",
    Surname: "Rucks Jr",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2233: {
    Title: "",
    Initials: "RW",
    Surname: "Rush",
    "Primary Club": "Australian Rifle Team",
    County: "Queensland",
    Country: "Australia",
  },
  2234: {
    Title: "Mr",
    Initials: "TC",
    Surname: "Rylands",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  2235: {
    Title: "Cdt",
    Initials: "DW",
    Surname: "Sanderson",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "",
  },
  2236: {
    Title: "",
    Initials: "J",
    Surname: "Sanger",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  2237: {
    Title: "Cdt",
    Initials: "MS",
    Surname: "Saunders",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  2238: {
    Title: "",
    Initials: "RL",
    Surname: "Schlam",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2239: {
    Title: "",
    Initials: "T",
    Surname: "Schroeder",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2240: {
    Title: "",
    Initials: "RJS",
    Surname: "Scoones",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  2241: {
    Title: "Lt Col",
    Initials: "BAR",
    Surname: "Scott",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2242: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Seebohm",
    "Primary Club": "OO Troubleshooters",
    County: "Hertfordshire",
    Country: "Scotland",
  },
  2243: {
    Title: "",
    Initials: "RS",
    Surname: "Sekellick",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2244: {
    Title: "Mr",
    Initials: "",
    Surname: "Shahfame Ramly",
    "Primary Club": "",
    County: "",
    Country: "Malaysia",
  },
  2245: {
    Title: "Cdt",
    Initials: "JR",
    Surname: "Shanahan",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2246: {
    Title: "Cdt",
    Initials: "RJ",
    Surname: "Shaw",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  2247: {
    Title: "",
    Initials: "BMG",
    Surname: "Shouler",
    "Primary Club": "Langar",
    County: "Nottinghamshire",
    Country: "England",
  },
  2248: {
    Title: "",
    Initials: "DG",
    Surname: "Simpson",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2249: {
    Title: "",
    Initials: "RH",
    Surname: "Sinclair",
    "Primary Club": "S A Veterans",
    County: "Gauteng North",
    Country: "South Africa",
  },
  2250: {
    Title: "Mrs",
    Initials: "MA",
    Surname: "Skelton",
    "Primary Club": "City of Brisbane RC",
    County: "Queensland",
    Country: "Australia",
  },
  2251: {
    Title: "Lt Col",
    Initials: "PG",
    Surname: "Slabber",
    "Primary Club": "S A Veterans",
    County: "Gauteng",
    Country: "South Africa",
  },
  2252: {
    Title: "",
    Initials: "JD",
    Surname: "Sleep",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  2253: {
    Title: "Cdt",
    Initials: "OGAD",
    Surname: "Smith",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  2254: {
    Title: "",
    Initials: "RD",
    Surname: "Smith",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2255: {
    Title: "Mr",
    Initials: "J",
    Surname: "Snowden",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2256: {
    Title: "Cdt Cplt",
    Initials: "SJ",
    Surname: "Steel",
    "Primary Club": "324 Sqn ATC",
    County: "",
    Country: "",
  },
  2257: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Steele",
    "Primary Club": "",
    County: "",
    Country: "New Zealand",
  },
  2258: {
    Title: "WO",
    Initials: "JRA",
    Surname: "Surette",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2259: {
    Title: "",
    Initials: "D",
    Surname: "Surmat",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2260: {
    Title: "Mr",
    Initials: "DAJ",
    Surname: "Talbot",
    "Primary Club": "Royal Ordnance Nottingham",
    County: "Nottinghamshire",
    Country: "England",
  },
  2261: {
    Title: "Maj",
    Initials: "D",
    Surname: "Thompson",
    "Primary Club": "Australian Army",
    County: "",
    Country: "Australia",
  },
  2262: {
    Title: "",
    Initials: "MRP",
    Surname: "Thorogood",
    "Primary Club": "Old Glenalmond R.C",
    County: "",
    Country: "United Kingdom",
  },
  2263: {
    Title: "",
    Initials: "GL",
    Surname: "Tierney",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2264: {
    Title: "",
    Initials: "SN",
    Surname: "Todd",
    "Primary Club": "Salisbury R & PC",
    County: "Wiltshire",
    Country: "England",
  },
  2265: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Tremblay",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2266: {
    Title: "",
    Initials: "P",
    Surname: "Tremblay",
    "Primary Club": "DCRA",
    County: "Quebec",
    Country: "Canada",
  },
  2267: {
    Title: "Mr",
    Initials: "PT",
    Surname: "Tremblay",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2268: {
    Title: "",
    Initials: "J",
    Surname: "Upton",
    "Primary Club": "USA Under 25",
    County: "",
    Country: "United States of America",
  },
  2269: {
    Title: "",
    Initials: "AP",
    Surname: "Van Niekerk",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2270: {
    Title: "",
    Initials: "CA",
    Surname: "Van Niekerk",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2271: {
    Title: "LAC",
    Initials: "D",
    Surname: "van Oosterhout",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2272: {
    Title: "",
    Initials: "RJ",
    Surname: "van Rensburg",
    "Primary Club": "S A Veterans",
    County: "",
    Country: "South Africa",
  },
  2273: {
    Title: "Dr",
    Initials: "WJ",
    Surname: "van Rooyen",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2274: {
    Title: "",
    Initials: "RJJ",
    Surname: "van Vuuren",
    "Primary Club": "S A Veterans",
    County: "Gauteng North",
    Country: "South Africa",
  },
  2275: {
    Title: "Mnr",
    Initials: "CDP",
    Surname: "van Zyl",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2276: {
    Title: "Miss",
    Initials: "EJ",
    Surname: "Venables",
    "Primary Club": "Southampton Uni",
    County: "Devon",
    Country: "England",
  },
  2277: {
    Title: "",
    Initials: "A",
    Surname: "Visser",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2278: {
    Title: "Cdt Sgt",
    Initials: "JA",
    Surname: "Waghorn",
    "Primary Club": "",
    County: "Berkshire",
    Country: "",
  },
  2279: {
    Title: "Cdt Sgt",
    Initials: "JM",
    Surname: "Watson",
    "Primary Club": "36(Hetton le Hole) Sqn AT",
    County: "County Durham",
    Country: "England",
  },
  2280: {
    Title: "Cdt",
    Initials: "DJAW",
    Surname: "Webb",
    "Primary Club": "Wellington College",
    County: "Sussex",
    Country: "",
  },
  2281: {
    Title: "",
    Initials: "TJ",
    Surname: "Whitaker",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2282: {
    Title: "",
    Initials: "MW",
    Surname: "Wiendlocha",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2283: {
    Title: "Cdt",
    Initials: "O",
    Surname: "Williams",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2284: {
    Title: "",
    Initials: "K",
    Surname: "Winter",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2285: {
    Title: "Sgt",
    Initials: "LL",
    Surname: "Wong",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "England",
  },
  2286: {
    Title: "Mr",
    Initials: "W",
    Surname: "Wood",
    "Primary Club": "Bunhill Rifle Club",
    County: "Surrey",
    Country: "England",
  },
  2287: {
    Title: "",
    Initials: "SB",
    Surname: "Young",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2288: {
    Title: "Mr",
    Initials: "SE",
    Surname: "Young",
    "Primary Club": "Surrey RA",
    County: "Lancashire",
    Country: "England",
  },
  2289: {
    Title: "Mrs",
    Initials: "TH",
    Surname: "Luckman",
    "Primary Club": "101 RC",
    County: "Lancashire",
    Country: "England",
  },
  2290: {
    Title: "Mrs",
    Initials: "NJ",
    Surname: "Zinsmaster-Mayo",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2291: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Kent",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2292: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Seager",
    "Primary Club": "Dorset Riflemen",
    County: "Hampshire",
    Country: "",
  },
  2293: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Symonds",
    "Primary Club": "Old Malvernians",
    County: "Hereford & Worcs",
    Country: "England",
  },
  2294: {
    Title: "Mr",
    Initials: "PR",
    Surname: "Tyldesley",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "Ireland",
  },
  2295: {
    Title: "",
    Initials: "DB",
    Surname: "Bell",
    "Primary Club": "Huddersfield R.C",
    County: "Yorkshire",
    Country: "England",
  },
  2296: {
    Title: "Capt",
    Initials: "H",
    Surname: "Banks",
    "Primary Club": "A.T.R.C.",
    County: "Suffolk",
    Country: "England",
  },
  2297: {
    Title: "",
    Initials: "H",
    Surname: "Woolschot",
    "Primary Club": "Queensland Team",
    County: "Queensland",
    Country: "Australia",
  },
  2299: {
    Title: "",
    Initials: "S",
    Surname: "James",
    "Primary Club": "Bedfordshire County",
    County: "Bedfordshire",
    Country: "United Kingdom",
  },
  2300: {
    Title: "Mr",
    Initials: "CI",
    Surname: "Cooper",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  2301: {
    Title: "Mr",
    Initials: "VK",
    Surname: "Thickett",
    "Primary Club": "Twickenham",
    County: "Middlesex",
    Country: "England",
  },
  2302: {
    Title: "Mr",
    Initials: "J",
    Surname: "Campbell",
    "Primary Club": "Altcar RC",
    County: "",
    Country: "",
  },
  2303: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Stewart",
    "Primary Club": "Marquis",
    County: "Surrey",
    Country: "England",
  },
  2304: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Stewart",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  2305: {
    Title: "Mr",
    Initials: "J",
    Surname: "Elgin",
    "Primary Club": "NLRC",
    County: "Buckinghamshire",
    Country: "",
  },
  2306: {
    Title: "",
    Initials: "HF",
    Surname: "Forgie",
    "Primary Club": "",
    County: "",
    Country: "Scotland",
  },
  2307: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Shouler",
    "Primary Club": "Langar RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  2308: {
    Title: "Mr",
    Initials: "GC",
    Surname: "King",
    "Primary Club": "Ibis RC",
    County: "Kent",
    Country: "England",
  },
  2309: {
    Title: "",
    Initials: "TJ",
    Surname: "Whiteside",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  2310: {
    Title: "Mr",
    Initials: "CP",
    Surname: "Norton",
    "Primary Club": "Ross-on-Wye",
    County: "Hereford & Worcs",
    Country: "England",
  },
  2311: {
    Title: "Mr",
    Initials: "J",
    Surname: "Robbins",
    "Primary Club": "Petworth RC",
    County: "",
    Country: "England",
  },
  2312: {
    Title: "Mr",
    Initials: "TS",
    Surname: "McDowell",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "Wales",
  },
  2313: {
    Title: "Dr",
    Initials: "J St J",
    Surname: "Mehta",
    "Primary Club": "Old Framlinghamians RC",
    County: "Essex",
    Country: "Scotland",
  },
  2314: {
    Title: "",
    Initials: "J",
    Surname: "Verdier",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  2315: {
    Title: "Mr",
    Initials: "JBE",
    Surname: "Personne",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  2316: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Anderson",
    "Primary Club": "NLRC",
    County: "Oxfordshire",
    Country: "Scotland",
  },
  2317: {
    Title: "Mr",
    Initials: "SCK",
    Surname: "Agnew",
    "Primary Club": "Surrey RA",
    County: "",
    Country: "United Kingdom",
  },
  2318: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Anderson",
    "Primary Club": "University of London",
    County: "Surrey",
    Country: "England",
  },
  2319: {
    Title: "L/Cpl",
    Initials: "JK",
    Surname: "Armstrong",
    "Primary Club": "Surrey ACF",
    County: "",
    Country: "",
  },
  2320: {
    Title: "L/Cpl",
    Initials: "J",
    Surname: "Ashall",
    "Primary Club": "Derbyshire ACF",
    County: "",
    Country: "England",
  },
  2321: {
    Title: "L/Cpl",
    Initials: "P",
    Surname: "Ashall",
    "Primary Club": "Derbyshire ACF",
    County: "",
    Country: "England",
  },
  2322: {
    Title: "Mr",
    Initials: "L",
    Surname: "Bacon",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "",
  },
  2323: {
    Title: "Cpl",
    Initials: "MP",
    Surname: "Bateman",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2324: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Benest",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2325: {
    Title: "",
    Initials: "I",
    Surname: "Benusik",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2326: {
    Title: "",
    Initials: "JB",
    Surname: "Berg",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2327: {
    Title: "Cdt",
    Initials: "JC",
    Surname: "Billot",
    "Primary Club": "Athelings",
    County: "Jersey",
    Country: "Jersey",
  },
  2328: {
    Title: "Cdt",
    Initials: "IM",
    Surname: "Blondel",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2329: {
    Title: "Miss",
    Initials: "C",
    Surname: "Boake",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2330: {
    Title: "",
    Initials: "R",
    Surname: "Borgeaud",
    "Primary Club": "OURC",
    County: "Oxfordshire",
    Country: "England",
  },
  2331: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Bryant",
    "Primary Club": "Middlesex & NW London ACF",
    County: "County of London",
    Country: "",
  },
  2332: {
    Title: "Cdt",
    Initials: "PIK",
    Surname: "Budden",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2333: {
    Title: "Lt Col",
    Initials: "WDH",
    Surname: "Burden",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  2334: {
    Title: "",
    Initials: "S",
    Surname: "Callan",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2335: {
    Title: "Cdt",
    Initials: "BD",
    Surname: "Chamberlain",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2336: {
    Title: "Sgt",
    Initials: "R",
    Surname: "Chepkok",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2337: {
    Title: "Ms",
    Initials: "N",
    Surname: "Clara",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2338: {
    Title: "Cdt",
    Initials: "TQ",
    Surname: "Clark",
    "Primary Club": "1297 Sqn ATC",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  2339: {
    Title: "Cpl",
    Initials: "P",
    Surname: "Coleman",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2340: {
    Title: "Miss",
    Initials: "ERL",
    Surname: "Coombs",
    "Primary Club": "Cheltenham College CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  2341: {
    Title: "Cdt",
    Initials: "JD",
    Surname: "Cormie",
    "Primary Club": "Athelings",
    County: "Angus",
    Country: "Scotland",
  },
  2342: {
    Title: "Cdt",
    Initials: "JD",
    Surname: "Cromarty",
    "Primary Club": "Athelings",
    County: "Cambridgeshire",
    Country: "England",
  },
  2343: {
    Title: "Miss",
    Initials: "K",
    Surname: "Cross",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2344: {
    Title: "",
    Initials: "FHJ d G",
    Surname: "Crowley",
    "Primary Club": "Glenalmond College",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2346: {
    Title: "Cpl",
    Initials: "APT",
    Surname: "D'Authreau",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2347: {
    Title: "CDT",
    Initials: "C",
    Surname: "Dale",
    "Primary Club": "",
    County: "Kent",
    Country: "",
  },
  2348: {
    Title: "",
    Initials: "B",
    Surname: "Davidson",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2349: {
    Title: "Mrs",
    Initials: "F",
    Surname: "Day",
    "Primary Club": "LMRA",
    County: "Suffolk",
    Country: "England",
  },
  2350: {
    Title: "",
    Initials: "AC",
    Surname: "de Jong",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2351: {
    Title: "",
    Initials: "B",
    Surname: "Dean",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2352: {
    Title: "Miss",
    Initials: "CJ",
    Surname: "Dee",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  2353: {
    Title: "Mr",
    Initials: "EC",
    Surname: "Dickson",
    "Primary Club": "Old Marlburian RC",
    County: "Hampshire",
    Country: "England",
  },
  2354: {
    Title: "Mr",
    Initials: "L",
    Surname: "Dodington-Boyes",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2355: {
    Title: "Mr",
    Initials: "HR",
    Surname: "Donaldson",
    "Primary Club": "Dungannon",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  2356: {
    Title: "Sqn Ldr",
    Initials: "RL",
    Surname: "Douglas",
    "Primary Club": "RAF Air Cmd",
    County: "West of Scotland",
    Country: "Scotland",
  },
  2357: {
    Title: "Mr",
    Initials: "AF",
    Surname: "Downer",
    "Primary Club": "Dorking & District RC",
    County: "Sussex",
    Country: "England",
  },
  2358: {
    Title: "",
    Initials: "FC",
    Surname: "du Plessis",
    "Primary Club": "",
    County: "",
    Country: "South Africa",
  },
  2359: {
    Title: "",
    Initials: "A",
    Surname: "Dugal",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2360: {
    Title: "Mr",
    Initials: "BR",
    Surname: "Ebbetts",
    "Primary Club": "Old Wellingtonians",
    County: "Berkshire",
    Country: "England",
  },
  2361: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Edwards",
    "Primary Club": "Derbyshire ACF",
    County: "Yorkshire",
    Country: "England",
  },
  2362: {
    Title: "Mr",
    Initials: "P",
    Surname: "Eggemann",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2363: {
    Title: "",
    Initials: "WA",
    Surname: "Eilbeck",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  2364: {
    Title: "Mr",
    Initials: "H",
    Surname: "Emck",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "England",
  },
  2365: {
    Title: "",
    Initials: "BJ",
    Surname: "Farrer",
    "Primary Club": "Bedford Mod Schl",
    County: "Bedfordshire",
    Country: "England",
  },
  2366: {
    Title: "Cpl",
    Initials: "G",
    Surname: "Farrow",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2367: {
    Title: "Ms",
    Initials: "A",
    Surname: "Felder",
    "Primary Club": "NCRRA",
    County: "",
    Country: "Canada",
  },
  2368: {
    Title: "Cdt Sgt",
    Initials: "SJB",
    Surname: "Ferguson",
    "Primary Club": "Campbell College C.C.F",
    County: "Down",
    Country: "N. Ireland",
  },
  2369: {
    Title: "",
    Initials: "DM",
    Surname: "Findlay",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "",
  },
  2370: {
    Title: "",
    Initials: "OF",
    Surname: "Fischer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2371: {
    Title: "",
    Initials: "T",
    Surname: "Fitzgerald",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2372: {
    Title: "",
    Initials: "M",
    Surname: "Flikweert",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  2373: {
    Title: "CCH",
    Initials: "M",
    Surname: "Fritte",
    "Primary Club": "",
    County: "",
    Country: "France",
  },
  2374: {
    Title: "Capt",
    Initials: "NJ",
    Surname: "Gamp",
    "Primary Club": "ATRC",
    County: "Kent",
    Country: "England",
  },
  2375: {
    Title: "Mr",
    Initials: "SIG",
    Surname: "Gluck",
    "Primary Club": "New York R&P Association",
    County: "",
    Country: "USA",
  },
  2376: {
    Title: "Cdt",
    Initials: "GJP",
    Surname: "Goatly",
    "Primary Club": "Marlborough College",
    County: "Dorset",
    Country: "England",
  },
  2377: {
    Title: "",
    Initials: "J",
    Surname: "Gogne",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2378: {
    Title: "",
    Initials: "XL",
    Surname: "Gonin",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2379: {
    Title: "",
    Initials: "DA",
    Surname: "Gorokhov",
    "Primary Club": "Durham University",
    County: "",
    Country: "",
  },
  2380: {
    Title: "",
    Initials: "JR",
    Surname: "Graves",
    "Primary Club": "Reading University",
    County: "Essex",
    Country: "England",
  },
  2381: {
    Title: "",
    Initials: "LW",
    Surname: "Gray",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2382: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Greene",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2383: {
    Title: "Mr",
    Initials: "P",
    Surname: "Gregorio",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  2384: {
    Title: "",
    Initials: "PG",
    Surname: "Guse",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2385: {
    Title: "Mr",
    Initials: "J",
    Surname: "Halahan",
    "Primary Club": "Old Framlinghamians RC",
    County: "London",
    Country: "Ireland",
  },
  2386: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Haley",
    "Primary Club": "Welsh RA",
    County: "Gloucestershire",
    Country: "Wales",
  },
  2387: {
    Title: "Miss",
    Initials: "C",
    Surname: "Hammond",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2388: {
    Title: "",
    Initials: "MD",
    Surname: "Hanson",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2389: {
    Title: "Mr",
    Initials: "SHJ",
    Surname: "Harley",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2390: {
    Title: "Gnr",
    Initials: "AW",
    Surname: "Hayward",
    "Primary Club": "ATSC",
    County: "Sussex",
    Country: "England",
  },
  2391: {
    Title: "Miss",
    Initials: "C",
    Surname: "Hillier",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2392: {
    Title: "Miss",
    Initials: "FM",
    Surname: "Hinson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Lancashire",
    Country: "England",
  },
  2393: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Hoare",
    "Primary Club": "Bucks ACF",
    County: "Buckinghamshire",
    Country: "",
  },
  2394: {
    Title: "Miss",
    Initials: "RE",
    Surname: "Hooper",
    "Primary Club": "OURC",
    County: "Gloucestershire",
    Country: "England",
  },
  2395: {
    Title: "RSM",
    Initials: "WR",
    Surname: "Howard",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  2396: {
    Title: "Sgt",
    Initials: "EJP",
    Surname: "Hutton",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  2397: {
    Title: "",
    Initials: "J",
    Surname: "Ihugo",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2398: {
    Title: "Mr",
    Initials: "HCG",
    Surname: "Ives",
    "Primary Club": "NLRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  2399: {
    Title: "Cdt",
    Initials: "OT",
    Surname: "Jackman",
    "Primary Club": "Bradfield College",
    County: "Surrey",
    Country: "England",
  },
  2400: {
    Title: "Mr",
    Initials: "G",
    Surname: "Jarret",
    "Primary Club": "ATSC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2401: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Jenkins",
    "Primary Club": "RNTRC",
    County: "",
    Country: "Wales",
  },
  2402: {
    Title: "Cdt",
    Initials: "ER",
    Surname: "Jones",
    "Primary Club": "Shrewsbury School CCF",
    County: "Shropshire",
    Country: "England",
  },
  2403: {
    Title: "",
    Initials: "T",
    Surname: "Jones",
    "Primary Club": "St Lawrence College",
    County: "Kent",
    Country: "England",
  },
  2404: {
    Title: "Sgt",
    Initials: "TEC",
    Surname: "Jowett",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  2405: {
    Title: "Sgt",
    Initials: "G",
    Surname: "Kariuki",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2406: {
    Title: "Cdt",
    Initials: "TRB",
    Surname: "Kendrick",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2407: {
    Title: "ACP",
    Initials: "S",
    Surname: "Kihara Maina",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2408: {
    Title: "Capt",
    Initials: "W",
    Surname: "Kilmartin",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2409: {
    Title: "PC",
    Initials: "D",
    Surname: "Kirui",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2410: {
    Title: "Cdt",
    Initials: "PS",
    Surname: "Knight",
    "Primary Club": "Athelings",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2411: {
    Title: "Cdt",
    Initials: "GM",
    Surname: "Kohler",
    "Primary Club": "",
    County: "",
    Country: "Scotland",
  },
  2412: {
    Title: "Mr",
    Initials: "G",
    Surname: "Krebber",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2413: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Kuntz",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2414: {
    Title: "Miss",
    Initials: "EC",
    Surname: "Lamont",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  2415: {
    Title: "Mr",
    Initials: "GAE",
    Surname: "Larcombe",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "England",
  },
  2416: {
    Title: "",
    Initials: "P",
    Surname: "Lepariyo",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2417: {
    Title: "",
    Initials: "DJ",
    Surname: "Mair",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2418: {
    Title: "Mr",
    Initials: "EJK",
    Surname: "Malleson",
    "Primary Club": "NLRC",
    County: "Gloucestershire",
    Country: "",
  },
  2419: {
    Title: "",
    Initials: "E",
    Surname: "Marr",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2420: {
    Title: "Mr",
    Initials: "IJ",
    Surname: "McCallum",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2421: {
    Title: "Mr",
    Initials: "ASH",
    Surname: "McCullough",
    "Primary Club": "City RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2422: {
    Title: "",
    Initials: "RG",
    Surname: "McDonald",
    "Primary Club": "OURC",
    County: "Oxfordshire",
    Country: "South Africa",
  },
  2423: {
    Title: "Cdt",
    Initials: "JA",
    Surname: "McKernan",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  2424: {
    Title: "",
    Initials: "KG",
    Surname: "McNeil",
    "Primary Club": "Glenalmond College",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2425: {
    Title: "Mr",
    Initials: "PCT",
    Surname: "Meatyard",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  2426: {
    Title: "OS",
    Initials: "TP",
    Surname: "Millar",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2427: {
    Title: "Mr",
    Initials: "DCB",
    Surname: "Mitchell",
    "Primary Club": "Old Marlburian RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  2428: {
    Title: "Mr",
    Initials: "",
    Surname: "Mohd Rosafindi Ahmad",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  2429: {
    Title: "Mr",
    Initials: "S",
    Surname: "Monaghan",
    "Primary Club": "Bunhill Lodge",
    County: "Middlesex",
    Country: "Ireland",
  },
  2430: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Monro",
    "Primary Club": "Exeter University RC",
    County: "Devon",
    Country: "England",
  },
  2431: {
    Title: "Mr",
    Initials: "",
    Surname: "Muhd Asmawie Yaacob",
    "Primary Club": "",
    County: "",
    Country: "Malaysia",
  },
  2432: {
    Title: "",
    Initials: "A",
    Surname: "Muller",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2434: {
    Title: "Insp",
    Initials: "G",
    Surname: "Muturi",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2435: {
    Title: "Sgt",
    Initials: "CD",
    Surname: "Nelson",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2436: {
    Title: "",
    Initials: "RTG",
    Surname: "Nelson",
    "Primary Club": "Heriot-Watt URC",
    County: "Ulster",
    Country: "Ireland",
  },
  2437: {
    Title: "Miss",
    Initials: "OC",
    Surname: "Newhouse",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  2438: {
    Title: "Cpl",
    Initials: "G",
    Surname: "Nixon",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2439: {
    Title: "Mr",
    Initials: "DE",
    Surname: "Nuthall",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2440: {
    Title: "Cdt",
    Initials: "GEH",
    Surname: "Oates",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2441: {
    Title: "",
    Initials: "PK",
    Surname: "Odenwalder",
    "Primary Club": "Cheltenham College CCF",
    County: "",
    Country: "Germany",
  },
  2442: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Owen",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2443: {
    Title: "Cdt",
    Initials: "NG",
    Surname: "Paluch",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2444: {
    Title: "LSgt",
    Initials: "JPJ",
    Surname: "Parker",
    "Primary Club": "ATRC",
    County: "Hampshire",
    Country: "England",
  },
  2445: {
    Title: "",
    Initials: "J",
    Surname: "Pearson",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  2446: {
    Title: "Ms",
    Initials: "BE",
    Surname: "Pendley",
    "Primary Club": "Mars & Minerva",
    County: "Wiltshire",
    Country: "England",
  },
  2448: {
    Title: "Mr",
    Initials: "TF",
    Surname: "Pimblett",
    "Primary Club": "Old Sedberghians",
    County: "Lancashire",
    Country: "England",
  },
  2449: {
    Title: "MWO",
    Initials: "CE",
    Surname: "Pothier",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2450: {
    Title: "Sgt",
    Initials: "NMS",
    Surname: "Pradhan",
    "Primary Club": "Cheltenham College CCF",
    County: "",
    Country: "Wales",
  },
  2451: {
    Title: "",
    Initials: "FR",
    Surname: "Pullar",
    "Primary Club": "Glenalmond College",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2452: {
    Title: "Cdt",
    Initials: "GR",
    Surname: "Radev",
    "Primary Club": "Middlesex & NW London ACF",
    County: "County of London",
    Country: "England",
  },
  2453: {
    Title: "Cdt",
    Initials: "CMJ",
    Surname: "Redburn",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "",
  },
  2454: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Reddish",
    "Primary Club": "Durham University",
    County: "Derbyshire",
    Country: "England",
  },
  2455: {
    Title: "Sgt",
    Initials: "RG",
    Surname: "Richardson",
    "Primary Club": "Dungannon",
    County: "Tyrone",
    Country: "Ireland",
  },
  2456: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Robeson",
    "Primary Club": "Bradfield College",
    County: "Oxfordshire",
    Country: "England",
  },
  2457: {
    Title: "",
    Initials: "PO",
    Surname: "Rokke",
    "Primary Club": "SERC",
    County: "",
    Country: "",
  },
  2459: {
    Title: "Cdt",
    Initials: "NJ",
    Surname: "Sarafilovic",
    "Primary Club": "Athelings",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2460: {
    Title: "Cfn",
    Initials: "JP",
    Surname: "Scarr",
    "Primary Club": "ATSC",
    County: "Cambridgeshire",
    Country: "England",
  },
  2461: {
    Title: "Sgt",
    Initials: "HA",
    Surname: "Scott",
    "Primary Club": "Clifton College",
    County: "Avon",
    Country: "England",
  },
  2462: {
    Title: "",
    Initials: "HS",
    Surname: "Seeger",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2463: {
    Title: "Cpl",
    Initials: "JS",
    Surname: "Serem",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  2464: {
    Title: "Mr",
    Initials: "OJ",
    Surname: "Siddall",
    "Primary Club": "Old Tonbridgian",
    County: "Sussex",
    Country: "England",
  },
  2465: {
    Title: "Cdt",
    Initials: "HP",
    Surname: "Snyder",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2466: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Spencer",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  2467: {
    Title: "Mr",
    Initials: "RN",
    Surname: "Stebbings",
    "Primary Club": "BYSA",
    County: "County of Durham",
    Country: "England",
  },
  2468: {
    Title: "Mr",
    Initials: "WFS",
    Surname: "Stewart",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2469: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Stockbridge",
    "Primary Club": "261 Sqn ATC",
    County: "",
    Country: "",
  },
  2470: {
    Title: "Mr",
    Initials: "HCG",
    Surname: "Sutton",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  2471: {
    Title: "Mr",
    Initials: "CS",
    Surname: "Taljaard",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2472: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Taylor",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2473: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Tustin",
    "Primary Club": "Wellington College",
    County: "Cambridgeshire",
    Country: "",
  },
  2474: {
    Title: "Mr",
    Initials: "CR",
    Surname: "Tyler",
    "Primary Club": "Chobham & District RC",
    County: "Surrey",
    Country: "England",
  },
  2475: {
    Title: "Miss",
    Initials: "A",
    Surname: "van Zyl",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  2476: {
    Title: "Mr",
    Initials: "WAF",
    Surname: "Vaz",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2477: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Villeneuve",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2478: {
    Title: "Mr",
    Initials: "TP",
    Surname: "Wharram",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2479: {
    Title: "Cdt",
    Initials: "CM",
    Surname: "White",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2480: {
    Title: "Cdt",
    Initials: "CW",
    Surname: "Whiteford",
    "Primary Club": "Athelings",
    County: "",
    Country: "Scotland",
  },
  2481: {
    Title: "",
    Initials: "JAB",
    Surname: "Whittaker",
    "Primary Club": "Reading University",
    County: "Hampshire",
    Country: "",
  },
  2482: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Whitwood",
    "Primary Club": "Athelings",
    County: "County Durham",
    Country: "England",
  },
  2483: {
    Title: "Cdt",
    Initials: "ML",
    Surname: "Wiscombe",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2484: {
    Title: "Fg Off",
    Initials: "EL",
    Surname: "Wolstenholm",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "",
  },
  2485: {
    Title: "Mr",
    Initials: "KMS",
    Surname: "Wong",
    "Primary Club": "LMRA",
    County: "Derbyshire",
    Country: "England",
  },
  2486: {
    Title: "Miss",
    Initials: "FS",
    Surname: "Reynolds",
    "Primary Club": "Gresham's RC",
    County: "Norfolk",
    Country: "Australia",
  },
  2487: {
    Title: "Dr",
    Initials: "HA",
    Surname: "Day",
    "Primary Club": "LMRA",
    County: "Suffolk",
    Country: "England",
  },
  2488: {
    Title: "Mr",
    Initials: "G",
    Surname: "Marples",
    "Primary Club": "Lloyds TSBRC",
    County: "",
    Country: "England",
  },
  2489: {
    Title: "",
    Initials: "DA",
    Surname: "Robinson",
    "Primary Club": "NRA",
    County: "Hampshire",
    Country: "England",
  },
  2490: {
    Title: "Dr",
    Initials: "CM",
    Surname: "Sturges",
    "Primary Club": "Old Alleynians",
    County: "Gloucestershire",
    Country: "England",
  },
  2491: {
    Title: "Mr",
    Initials: "AEC",
    Surname: "Briant",
    "Primary Club": "Room RC",
    County: "Sussex",
    Country: "England",
  },
  2492: {
    Title: "Mr",
    Initials: "AM",
    Surname: "Bullen",
    "Primary Club": "Welwyn Phoenix",
    County: "Norfolk",
    Country: "England",
  },
  2493: {
    Title: "Mr",
    Initials: "JWH",
    Surname: "Bell",
    "Primary Club": "West Atholl RC",
    County: "Perthshire",
    Country: "Scotland",
  },
  2494: {
    Title: "Mr",
    Initials: "CM",
    Surname: "Geldart",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "",
  },
  2495: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Sienkiewicz",
    "Primary Club": "KCS Old Boys RC",
    County: "Surrey",
    Country: "England",
  },
  2496: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Reeve",
    "Primary Club": "Wandsworth FBRC",
    County: "Suffolk",
    Country: "England",
  },
  2497: {
    Title: "Mr",
    Initials: "MT",
    Surname: "West",
    "Primary Club": "Westerham RC",
    County: "Kent",
    Country: "England",
  },
  2498: {
    Title: "Mr",
    Initials: "AT",
    Surname: "House",
    "Primary Club": "NRA",
    County: "County of London",
    Country: "England",
  },
  2499: {
    Title: "Mr",
    Initials: "P",
    Surname: "Halford",
    "Primary Club": "KCRA",
    County: "Kent",
    Country: "England",
  },
  2500: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Davies",
    "Primary Club": "Oratory Cardinals RC",
    County: "Berkshire",
    Country: "England",
  },
  2501: {
    Title: "Mr",
    Initials: "WM",
    Surname: "Jennings",
    "Primary Club": "Midland RC",
    County: "",
    Country: "Ireland",
  },
  2502: {
    Title: "",
    Initials: "DE",
    Surname: "Coates",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2503: {
    Title: "Mr",
    Initials: "JJS",
    Surname: "Hooper",
    "Primary Club": "ISIS Rifle & Pistol Club",
    County: "Oxfordshire",
    Country: "England",
  },
  2504: {
    Title: "",
    Initials: "VW",
    Surname: "Thomas",
    "Primary Club": "Bromsgrove RC",
    County: "Worcestershire",
    Country: "",
  },
  2505: {
    Title: "LCpl",
    Initials: "TJ",
    Surname: "Roberts",
    "Primary Club": "ATSC",
    County: "Sussex",
    Country: "England",
  },
  2506: {
    Title: "Mr",
    Initials: "M",
    Surname: "Robson",
    "Primary Club": "WRA Worcester",
    County: "Hereford & Worcs",
    Country: "England",
  },
  2507: {
    Title: "Mr",
    Initials: "RC",
    Surname: "Oxford",
    "Primary Club": "NLRC",
    County: "Kent",
    Country: "Wales",
  },
  2508: {
    Title: "Mr",
    Initials: "R",
    Surname: "Solomon",
    "Primary Club": "Wandsworth FBRC",
    County: "Sussex",
    Country: "England",
  },
  2509: {
    Title: "Miss",
    Initials: "MJ",
    Surname: "Anderson",
    "Primary Club": "CNRC",
    County: "",
    Country: "Scotland",
  },
  2510: {
    Title: "Miss",
    Initials: "DE",
    Surname: "Andrews",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  2511: {
    Title: "Sgt",
    Initials: "N",
    Surname: "Argo",
    "Primary Club": "2452 ATC",
    County: "",
    Country: "Scotland",
  },
  2512: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Atkin",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2514: {
    Title: "Mr",
    Initials: "BG",
    Surname: "Bailey",
    "Primary Club": "Ruislip RC",
    County: "Gloucestershire",
    Country: "England",
  },
  2515: {
    Title: "Mr",
    Initials: "RFM",
    Surname: "Baird",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  2516: {
    Title: "Mr",
    Initials: "DW",
    Surname: "Baker",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  2517: {
    Title: "Miss",
    Initials: "EH",
    Surname: "Barr",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "Scotland",
  },
  2518: {
    Title: "",
    Initials: "W",
    Surname: "Batstone",
    "Primary Club": "Heriot-Watt URC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2519: {
    Title: "Cdt",
    Initials: "AGC",
    Surname: "Baudains",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2520: {
    Title: "",
    Initials: "JR",
    Surname: "Benchley",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2522: {
    Title: "Mr",
    Initials: "C",
    Surname: "Berntsen",
    "Primary Club": "Falkland Islands RA",
    County: "",
    Country: "Falkland Islands",
  },
  2523: {
    Title: "",
    Initials: "CG",
    Surname: "Blumson",
    "Primary Club": "Old Berkhamstedians",
    County: "Hertfordshire",
    Country: "",
  },
  2524: {
    Title: "Miss",
    Initials: "LMA",
    Surname: "Bogie",
    "Primary Club": "OCRA",
    County: "Gloucestershire",
    Country: "England",
  },
  2525: {
    Title: "Cdt",
    Initials: "AJ",
    Surname: "Bolton King",
    "Primary Club": "Uppingham School",
    County: "Rutland",
    Country: "",
  },
  2526: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Boustead",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2527: {
    Title: "Mr",
    Initials: "GCB",
    Surname: "Brett",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  2528: {
    Title: "Miss",
    Initials: "CE",
    Surname: "Briscoe",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  2529: {
    Title: "Pte",
    Initials: "KTS",
    Surname: "Burden",
    "Primary Club": "ATSC",
    County: "Derbyshire",
    Country: "England",
  },
  2530: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Burden",
    "Primary Club": "SUOTC",
    County: "Surrey",
    Country: "England",
  },
  2531: {
    Title: "Miss",
    Initials: "MU",
    Surname: "Burger",
    "Primary Club": "Greshams RC",
    County: "",
    Country: "South Africa",
  },
  2532: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Cahill",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "Ireland",
  },
  2533: {
    Title: "Cpl",
    Initials: "CW",
    Surname: "Campbell",
    "Primary Club": "ATRC",
    County: "Ulster",
    Country: "Ireland",
  },
  2534: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Carrier",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2535: {
    Title: "Mr",
    Initials: "BN",
    Surname: "Carter",
    "Primary Club": "",
    County: "",
    Country: "New Zealand",
  },
  2536: {
    Title: "Mr",
    Initials: "TJC",
    Surname: "Caulfield",
    "Primary Club": "Cheltenham College CCF",
    County: "",
    Country: "England",
  },
  2537: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Charters",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  2538: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Chu",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "Hong Kong",
  },
  2539: {
    Title: "Miss",
    Initials: "CL",
    Surname: "Clarke",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2540: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Clow",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2541: {
    Title: "",
    Initials: "DW",
    Surname: "Coleman",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2542: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Colman",
    "Primary Club": "Athelings",
    County: "County Durham",
    Country: "",
  },
  2543: {
    Title: "L/Cpl",
    Initials: "RAB",
    Surname: "Cook",
    "Primary Club": "Bunhill Lodge",
    County: "County of London",
    Country: "",
  },
  2544: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Corrin",
    "Primary Club": "Athelings",
    County: "Lincolnshire",
    Country: "",
  },
  2545: {
    Title: "Cdt",
    Initials: "CJ",
    Surname: "Coupland",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  2546: {
    Title: "Dr",
    Initials: "C",
    Surname: "De Beer",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  2547: {
    Title: "",
    Initials: "JA",
    Surname: "De Beer",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  2548: {
    Title: "",
    Initials: "M",
    Surname: "Dida",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2549: {
    Title: "",
    Initials: "PL",
    Surname: "Dingle",
    "Primary Club": "Blair Atholl",
    County: "Perthshire",
    Country: "Scotland",
  },
  2550: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Doddington-Boyes",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2551: {
    Title: "Mr",
    Initials: "TM",
    Surname: "Drysdale",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  2552: {
    Title: "Cdt",
    Initials: "JW",
    Surname: "Du Feu",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2553: {
    Title: "",
    Initials: "JO",
    Surname: "Du Plessis",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2554: {
    Title: "",
    Initials: "JA",
    Surname: "Du Toit",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2555: {
    Title: "",
    Initials: "FAE",
    Surname: "Ellis",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2556: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Ferguson",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2557: {
    Title: "",
    Initials: "RS",
    Surname: "Fincham",
    "Primary Club": "Upper Hutt",
    County: "",
    Country: "New Zealand",
  },
  2558: {
    Title: "",
    Initials: "NJF",
    Surname: "Firth",
    "Primary Club": "Durham University",
    County: "Nottinghamshire",
    Country: "England",
  },
  2559: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Gaucher",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2560: {
    Title: "",
    Initials: "A",
    Surname: "Ghavami",
    "Primary Club": "CUOTC",
    County: "Cambridgeshire",
    Country: "England",
  },
  2561: {
    Title: "WO2",
    Initials: "T",
    Surname: "Gibson",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2562: {
    Title: "Mr",
    Initials: "HWR",
    Surname: "Gilbert",
    "Primary Club": "OCRC",
    County: "Berkshire",
    Country: "England",
  },
  2563: {
    Title: "Cdt",
    Initials: "EJ",
    Surname: "Giron Marquez",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2564: {
    Title: "PC",
    Initials: "A",
    Surname: "Gitonga",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  2565: {
    Title: "",
    Initials: "J",
    Surname: "Glover",
    "Primary Club": "Hereford & Worcester",
    County: "Hereford & Worcs",
    Country: "",
  },
  2566: {
    Title: "Miss",
    Initials: "CJ",
    Surname: "Gomez",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "Trinidad",
  },
  2567: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Goodwin",
    "Primary Club": "Falkland Islands RA",
    County: "",
    Country: "Falkland Islands",
  },
  2568: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Green",
    "Primary Club": "ATSC",
    County: "Lancashire",
    Country: "England",
  },
  2569: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Groom",
    "Primary Club": "CNRC",
    County: "",
    Country: "",
  },
  2571: {
    Title: "Cdt Cpl",
    Initials: "LA",
    Surname: "Harris",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  2572: {
    Title: "",
    Initials: "TJ",
    Surname: "Harrison",
    "Primary Club": "",
    County: "Berkshire",
    Country: "England",
  },
  2573: {
    Title: "",
    Initials: "JS",
    Surname: "Heaton",
    "Primary Club": "Heriot-Watt URC",
    County: "East of Scotland",
    Country: "Wales",
  },
  2574: {
    Title: "OCdt",
    Initials: "R",
    Surname: "Hebb",
    "Primary Club": "SUOTC",
    County: "Yorkshire",
    Country: "England",
  },
  2575: {
    Title: "",
    Initials: "CJH",
    Surname: "Heckstall-Smith",
    "Primary Club": "Wellingborough",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  2576: {
    Title: "",
    Initials: "IR",
    Surname: "Hogg",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2577: {
    Title: "Mr",
    Initials: "RC",
    Surname: "Holloway",
    "Primary Club": "NLRC",
    County: "County of London",
    Country: "South Africa",
  },
  2578: {
    Title: "",
    Initials: "J",
    Surname: "Horlock",
    "Primary Club": "Whitgift Veterans",
    County: "Hereford & Worcs",
    Country: "",
  },
  2579: {
    Title: "Mr",
    Initials: "WJM",
    Surname: "Taylor",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  2580: {
    Title: "Mr",
    Initials: "SG",
    Surname: "Hunter",
    "Primary Club": "Tageme RC",
    County: "Kent",
    Country: "England",
  },
  2581: {
    Title: "",
    Initials: "SLG",
    Surname: "Husheer",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "New Zealand",
  },
  2582: {
    Title: "",
    Initials: "AD",
    Surname: "Jackman",
    "Primary Club": "Old Epsomians",
    County: "Surrey",
    Country: "",
  },
  2583: {
    Title: "Mr",
    Initials: "ER",
    Surname: "Johnson",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  2584: {
    Title: "Maj",
    Initials: "CP",
    Surname: "Judge",
    "Primary Club": "ATSC",
    County: "County of London",
    Country: "England",
  },
  2585: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Judge",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  2586: {
    Title: "C/Sgt",
    Initials: "AL",
    Surname: "Kamli",
    "Primary Club": "Bunhill Lodge",
    County: "County of London",
    Country: "",
  },
  2587: {
    Title: "Dr",
    Initials: "T",
    Surname: "Keen",
    "Primary Club": "Somerset CRA",
    County: "Somerset",
    Country: "England",
  },
  2588: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Kelly",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Scotland",
  },
  2589: {
    Title: "Miss",
    Initials: "HC",
    Surname: "Kent",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2590: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Kershaw",
    "Primary Club": "Gresham's RC",
    County: "Norfolk",
    Country: "England",
  },
  2591: {
    Title: "",
    Initials: "CW",
    Surname: "King",
    "Primary Club": "ULRC",
    County: "County of London",
    Country: "England",
  },
  2592: {
    Title: "Miss",
    Initials: "RE",
    Surname: "Knight",
    "Primary Club": "Edinburgh University RC",
    County: "Suffolk",
    Country: "England",
  },
  2593: {
    Title: "",
    Initials: "JS",
    Surname: "Kruger",
    "Primary Club": "",
    County: "",
    Country: "South Africa",
  },
  2594: {
    Title: "Cdt",
    Initials: "M",
    Surname: "La Flamme",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2595: {
    Title: "Miss",
    Initials: "RL",
    Surname: "Laird",
    "Primary Club": "Irish Rifle Club",
    County: "Ulster",
    Country: "Ireland",
  },
  2596: {
    Title: "Cdt",
    Initials: "MC",
    Surname: "Lariviere",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2597: {
    Title: "Cdt",
    Initials: "JP",
    Surname: "Laurenson",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2598: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Lauzon",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2599: {
    Title: "Mr",
    Initials: "J",
    Surname: "Le Cheminant",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  2600: {
    Title: "",
    Initials: "J",
    Surname: "Le Roux",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  2601: {
    Title: "",
    Initials: "JJ",
    Surname: "Le Roux",
    "Primary Club": "Peninsula RC",
    County: "",
    Country: "South Africa",
  },
  2602: {
    Title: "",
    Initials: "M",
    Surname: "Ling",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2603: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Livingston",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2604: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Loberg",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2605: {
    Title: "Cdt",
    Initials: "TAO",
    Surname: "Lothian",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  2606: {
    Title: "",
    Initials: "AC",
    Surname: "Louw",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2607: {
    Title: "",
    Initials: "WJ",
    Surname: "Lowe",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  2608: {
    Title: "Cdt",
    Initials: "M",
    Surname: "MacInnis",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2609: {
    Title: "Cdt Cpl",
    Initials: "RA",
    Surname: "Makepeace",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  2610: {
    Title: "",
    Initials: "GAY",
    Surname: "McBride",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2611: {
    Title: "J/Cpl",
    Initials: "RD",
    Surname: "McCall",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2612: {
    Title: "",
    Initials: "P",
    Surname: "McCullough",
    "Primary Club": "",
    County: "",
    Country: "Ireland",
  },
  2613: {
    Title: "Cdt",
    Initials: "K",
    Surname: "McKenna",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2614: {
    Title: "Cdt",
    Initials: "AJ",
    Surname: "McKenzie",
    "Primary Club": "Gresham's RC",
    County: "Norfolk",
    Country: "Scotland",
  },
  2615: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Merchant",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2616: {
    Title: "",
    Initials: "DG",
    Surname: "Meredith",
    "Primary Club": "Bavanspoort",
    County: "Gauteng",
    Country: "South Africa",
  },
  2617: {
    Title: "Mr",
    Initials: "S",
    Surname: "Mortimer",
    "Primary Club": "Newcastle Uni RC",
    County: "Devon",
    Country: "",
  },
  2618: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Moskovich",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2619: {
    Title: "Cdt",
    Initials: "DH",
    Surname: "Muckle",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2620: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Mustard",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2621: {
    Title: "PC",
    Initials: "M",
    Surname: "Muya",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  2622: {
    Title: "",
    Initials: "DN",
    Surname: "Naumann",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "England",
  },
  2623: {
    Title: "Cdt",
    Initials: "LW",
    Surname: "Naylor",
    "Primary Club": "Yorks NSW ACF",
    County: "Yorkshire",
    Country: "England",
  },
  2624: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Ndungu",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  2625: {
    Title: "Cpl",
    Initials: "F",
    Surname: "Ndungu",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  2626: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Newby",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2627: {
    Title: "",
    Initials: "BJ",
    Surname: "Newman",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  2628: {
    Title: "Mnr",
    Initials: "RG",
    Surname: "Nortier",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2630: {
    Title: "Revd",
    Initials: "KM",
    Surname: "O'Brien",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Ireland",
  },
  2631: {
    Title: "Sgt",
    Initials: "D",
    Surname: "OKelly",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "England",
  },
  2632: {
    Title: "",
    Initials: "RJ",
    Surname: "Opperman",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  2633: {
    Title: "Mr",
    Initials: "WD",
    Surname: "Owen",
    "Primary Club": "Oxford University RC",
    County: "Hampshire",
    Country: "England",
  },
  2634: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Page",
    "Primary Club": "NLRC",
    County: "County of Durham",
    Country: "Canada",
  },
  2635: {
    Title: "Mr",
    Initials: "KJA",
    Surname: "Painter",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "Scotland",
  },
  2636: {
    Title: "",
    Initials: "CM",
    Surname: "Palmer",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "England",
  },
  2637: {
    Title: "Mr",
    Initials: "R",
    Surname: "Parsons",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  2638: {
    Title: "Cdt",
    Initials: "CD",
    Surname: "Paterson",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  2639: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Paterson",
    "Primary Club": "",
    County: "",
    Country: "Scotland",
  },
  2640: {
    Title: "Cdt",
    Initials: "OJ",
    Surname: "Peggram",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  2642: {
    Title: "",
    Initials: "JRN",
    Surname: "Plunket",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2643: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Poirier",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2644: {
    Title: "Cdt",
    Initials: "JJ",
    Surname: "Pollock",
    "Primary Club": "Athelings",
    County: "Yorkshire",
    Country: "England",
  },
  2645: {
    Title: "Spr",
    Initials: "KJ",
    Surname: "Potter",
    "Primary Club": "ATRC",
    County: "Buckinghamshire",
    Country: "England",
  },
  2646: {
    Title: "Mr",
    Initials: "DJF",
    Surname: "Rees",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  2647: {
    Title: "Sgt",
    Initials: "J",
    Surname: "Richardson",
    "Primary Club": "Derbyshire ACF",
    County: "",
    Country: "",
  },
  2648: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Rowley",
    "Primary Club": "Newcastle Uni RC",
    County: "Norfolk",
    Country: "England",
  },
  2649: {
    Title: "Miss",
    Initials: "LV",
    Surname: "Rylands",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  2650: {
    Title: "",
    Initials: "CO",
    Surname: "Scheepers",
    "Primary Club": "Bloemfontein Bisley Club",
    County: "",
    Country: "South Africa",
  },
  2651: {
    Title: "",
    Initials: "O",
    Surname: "Scullion",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2652: {
    Title: "Mr",
    Initials: "DM",
    Surname: "Sherville",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  2653: {
    Title: "Mr",
    Initials: "NP",
    Surname: "Silvester",
    "Primary Club": "Old Johnian RC",
    County: "Oxfordshire",
    Country: "England",
  },
  2654: {
    Title: "Cdt",
    Initials: "PT",
    Surname: "Skelton",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "Ireland",
  },
  2656: {
    Title: "Mr",
    Initials: "RJW",
    Surname: "Smeltzer",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2657: {
    Title: "Major",
    Initials: "WF",
    Surname: "Squires",
    "Primary Club": "ATRC",
    County: "Cheshire",
    Country: "England",
  },
  2658: {
    Title: "",
    Initials: "DAP",
    Surname: "Stanley",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2659: {
    Title: "Mr",
    Initials: "N",
    Surname: "Stebbings",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  2660: {
    Title: "Dr",
    Initials: "G",
    Surname: "Stevenson",
    "Primary Club": "Darlington R & PC",
    County: "County of Durham",
    Country: "England",
  },
  2661: {
    Title: "",
    Initials: "GE",
    Surname: "Stewart",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "Scotland",
  },
  2662: {
    Title: "Cdt",
    Initials: "WS",
    Surname: "Stewart",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  2663: {
    Title: "",
    Initials: "J",
    Surname: "Sullivan",
    "Primary Club": "Oratory School",
    County: "",
    Country: "United States of America",
  },
  2664: {
    Title: "Mr",
    Initials: "L",
    Surname: "Tomlinson",
    "Primary Club": "West of Scotland RC",
    County: "",
    Country: "Scotland",
  },
  2665: {
    Title: "Cdt",
    Initials: "JDR",
    Surname: "Vincent",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  2666: {
    Title: "Mr",
    Initials: "PW",
    Surname: "Wakefield",
    "Primary Club": "ISIS Rifle & Pistol Club",
    County: "Oxfordshire",
    Country: "England",
  },
  2667: {
    Title: "Mr",
    Initials: "TEW",
    Surname: "Ward",
    "Primary Club": "Old Wellingtonians",
    County: "Sussex",
    Country: "Wales",
  },
  2669: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Weeks",
    "Primary Club": "Teignbridge & District",
    County: "",
    Country: "England",
  },
  2670: {
    Title: "",
    Initials: "J",
    Surname: "Willcocks",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2671: {
    Title: "Cdt",
    Initials: "ML",
    Surname: "Williams",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2672: {
    Title: "Cdt",
    Initials: "SH",
    Surname: "Williams",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  2673: {
    Title: "Mr",
    Initials: "AJC",
    Surname: "Wilson",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  2674: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Winchester",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2675: {
    Title: "Mr",
    Initials: "G",
    Surname: "Woodman",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2676: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Woodman",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2677: {
    Title: "Ms",
    Initials: "ZE",
    Surname: "Woodroffe",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2678: {
    Title: "",
    Initials: "BW",
    Surname: "Yarmoshuk",
    "Primary Club": "",
    County: "",
    Country: "Canada",
  },
  2679: {
    Title: "Mrs",
    Initials: "KM",
    Surname: "Young",
    "Primary Club": "101 RC",
    County: "Lancashire",
    Country: "Scotland",
  },
  2680: {
    Title: "",
    Initials: "JP",
    Surname: "Evans",
    "Primary Club": "Room RC",
    County: "Surrey",
    Country: "England",
  },
  2681: {
    Title: "Dr",
    Initials: "G",
    Surname: "Kolbe",
    "Primary Club": "West of Scotland RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  2682: {
    Title: "Dr",
    Initials: "SA",
    Surname: "Brooks",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "England",
  },
  2683: {
    Title: "Mr",
    Initials: "PJW",
    Surname: "Sparks",
    "Primary Club": "Mansfield & District R&PC",
    County: "",
    Country: "",
  },
  2684: {
    Title: "Mr",
    Initials: "NR",
    Surname: "Steadman",
    "Primary Club": "Long Parish",
    County: "Buckinghamshire",
    Country: "",
  },
  2685: {
    Title: "",
    Initials: "M",
    Surname: "Lee",
    "Primary Club": "NLRC",
    County: "Hampshire",
    Country: "England",
  },
  2686: {
    Title: "Mr",
    Initials: "K",
    Surname: "Baxby",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2687: {
    Title: "F/L",
    Initials: "D",
    Surname: "Saunders",
    "Primary Club": "Bearstead & Thurnham",
    County: "Kent",
    Country: "England",
  },
  2688: {
    Title: "Mrs",
    Initials: "A",
    Surname: "Dixon",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  2689: {
    Title: "",
    Initials: "SR",
    Surname: "Jones",
    "Primary Club": "Artists",
    County: "",
    Country: "England",
  },
  2690: {
    Title: "Miss",
    Initials: "HM",
    Surname: "Freeman-Owen",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2691: {
    Title: "Mr",
    Initials: "FJ",
    Surname: "Roberts",
    "Primary Club": "Dartford RC",
    County: "",
    Country: "",
  },
  2692: {
    Title: "Mrs",
    Initials: "JF",
    Surname: "Davies",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "Ireland",
  },
  2693: {
    Title: "",
    Initials: "B",
    Surname: "Gower",
    "Primary Club": "NRA",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  2694: {
    Title: "Mr",
    Initials: "G",
    Surname: "Trembath",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  2696: {
    Title: "Mr",
    Initials: "BRW",
    Surname: "Parker",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  2697: {
    Title: "Ms",
    Initials: "S",
    Surname: "Cheslyn-Curtis",
    "Primary Club": "NLRC",
    County: "Gloucestershire",
    Country: "England",
  },
  2698: {
    Title: "Dr",
    Initials: "P",
    Surname: "Holdstock",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  2699: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Wilson",
    "Primary Club": "Hailsham RC",
    County: "Sussex",
    Country: "England",
  },
  2700: {
    Title: "",
    Initials: "A",
    Surname: "Brothers",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  2701: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Simmonds",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "",
  },
  2702: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Pay",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  2703: {
    Title: "",
    Initials: "T",
    Surname: "Little",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  2704: {
    Title: "",
    Initials: "TJ",
    Surname: "Amy",
    "Primary Club": "Jersey R.A",
    County: "Jersey",
    Country: "Jersey",
  },
  2705: {
    Title: "",
    Initials: "RP",
    Surname: "Bawden",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2706: {
    Title: "Cdt",
    Initials: "CJ",
    Surname: "Beddoe",
    "Primary Club": "Epsom College",
    County: "",
    Country: "",
  },
  2707: {
    Title: "",
    Initials: "SL",
    Surname: "Chung",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "Trinidad",
  },
  2708: {
    Title: "Cdt",
    Initials: "APC",
    Surname: "Crow",
    "Primary Club": "Athelings",
    County: "Kent",
    Country: "England",
  },
  2709: {
    Title: "",
    Initials: "AI",
    Surname: "Hakim",
    "Primary Club": "Old Johnian R.C",
    County: "Surrey",
    Country: "England",
  },
  2710: {
    Title: "Cdt",
    Initials: "JAG",
    Surname: "Hurden",
    "Primary Club": "Uppingham Veterans RC",
    County: "Suffolk",
    Country: "England",
  },
  2711: {
    Title: "",
    Initials: "NB",
    Surname: "James",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  2712: {
    Title: "Cdt",
    Initials: "PD",
    Surname: "Joseph",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  2713: {
    Title: "",
    Initials: "HB",
    Surname: "Kamp",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2714: {
    Title: "",
    Initials: "BK",
    Surname: "Languille",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2715: {
    Title: "Cdt",
    Initials: "JSL",
    Surname: "Lavoie",
    "Primary Club": "RCAC Bisley Team",
    County: "",
    Country: "Canada",
  },
  2716: {
    Title: "Mr",
    Initials: "D",
    Surname: "Mesletsky",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2717: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Mills",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "Glamorgan",
    Country: "Wales",
  },
  2718: {
    Title: "",
    Initials: "PA",
    Surname: "O'Callaghan",
    "Primary Club": "W.R.A",
    County: "Glamorgan",
    Country: "Wales",
  },
  2719: {
    Title: "",
    Initials: "FI",
    Surname: "Oldfield-Box",
    "Primary Club": "N.L.R.C.",
    County: "Berkshire",
    Country: "England",
  },
  2720: {
    Title: "",
    Initials: "PG",
    Surname: "Oxnam",
    "Primary Club": "Oakham School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  2721: {
    Title: "CFS",
    Initials: "MC",
    Surname: "Phelan",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2722: {
    Title: "",
    Initials: "NA",
    Surname: "Porter",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  2723: {
    Title: "Lady",
    Initials: "JM",
    Surname: "McQuillan",
    "Primary Club": "Surrey RA",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2724: {
    Title: "WO2",
    Initials: "T",
    Surname: "Sands",
    "Primary Club": "RMRA",
    County: "",
    Country: "",
  },
  2725: {
    Title: "",
    Initials: "PMA",
    Surname: "Savage",
    "Primary Club": "Chobham & District R.C",
    County: "Surrey",
    Country: "England",
  },
  2726: {
    Title: "Sgt",
    Initials: "J",
    Surname: "Seegolun",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "S Glamorgan",
    Country: "Wales",
  },
  2727: {
    Title: "Mr",
    Initials: "J",
    Surname: "Binder - Swash",
    "Primary Club": "OSM",
    County: "Essex",
    Country: "England",
  },
  2728: {
    Title: "",
    Initials: "TS",
    Surname: "Sweetnam",
    "Primary Club": "Comber R.C",
    County: "Ulster",
    Country: "Ireland",
  },
  2729: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Tapster",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  2730: {
    Title: "Cdt",
    Initials: "JA",
    Surname: "Tumelty",
    "Primary Club": "Athelings",
    County: "South Wales",
    Country: "Wales",
  },
  2731: {
    Title: "Mr",
    Initials: "OH",
    Surname: "Vallis",
    "Primary Club": "LMRA",
    County: "Kent",
    Country: "England",
  },
  2732: {
    Title: "",
    Initials: "SF",
    Surname: "Van der Vyver",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "England",
  },
  2733: {
    Title: "Mr",
    Initials: "ANR",
    Surname: "Walker",
    "Primary Club": "Old Framlinghamians RC",
    County: "Surrey",
    Country: "Scotland",
  },
  2734: {
    Title: "Mr",
    Initials: "MP",
    Surname: "Watkins",
    "Primary Club": "Abergavenny RC",
    County: "South Wales",
    Country: "Wales",
  },
  2735: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Wilde",
    "Primary Club": "SCRA",
    County: "Somerset",
    Country: "England",
  },
  2736: {
    Title: "Mr",
    Initials: "R",
    Surname: "Harwood",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  2737: {
    Title: "Mr",
    Initials: "ATC",
    Surname: "Collins",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2738: {
    Title: "Cdt",
    Initials: "PAE",
    Surname: "Efthymiou",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  2739: {
    Title: "",
    Initials: "PM",
    Surname: "Golds",
    "Primary Club": "U.L.R.C",
    County: "Sussex",
    Country: "England",
  },
  2740: {
    Title: "Cpl",
    Initials: "TC",
    Surname: "Hammett",
    "Primary Club": "RGS",
    County: "",
    Country: "",
  },
  2741: {
    Title: "",
    Initials: "P",
    Surname: "Lyon",
    "Primary Club": "Reading University",
    County: "",
    Country: "",
  },
  2742: {
    Title: "",
    Initials: "RM",
    Surname: "Mathew",
    "Primary Club": "Athelings",
    County: "Jersey",
    Country: "Jersey",
  },
  2743: {
    Title: "",
    Initials: "JH",
    Surname: "Middleton",
    "Primary Club": "OCRA",
    County: "Hampshire",
    Country: "",
  },
  2744: {
    Title: "",
    Initials: "PR",
    Surname: "Mills",
    "Primary Club": "Old Carthusians",
    County: "Sussex",
    Country: "England",
  },
  2745: {
    Title: "Cdt",
    Initials: "JAR",
    Surname: "Park",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "England",
  },
  2746: {
    Title: "L Cpl",
    Initials: "JT",
    Surname: "Perry",
    "Primary Club": "ATRC",
    County: "Derbyshire",
    Country: "England",
  },
  2747: {
    Title: "",
    Initials: "JJE",
    Surname: "Porter",
    "Primary Club": "1st (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  2748: {
    Title: "",
    Initials: "AM",
    Surname: "Rainbow",
    "Primary Club": "Oxon RA",
    County: "Oxfordshire",
    Country: "England",
  },
  2749: {
    Title: "",
    Initials: "HC",
    Surname: "Reynolds",
    "Primary Club": "Bristol University",
    County: "Cambridgeshire",
    Country: "England",
  },
  2750: {
    Title: "Miss",
    Initials: "CA",
    Surname: "Riley",
    "Primary Club": "Derbyshire RA",
    County: "Derbyshire",
    Country: "England",
  },
  2751: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Spencer",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  2752: {
    Title: "Sgt",
    Initials: "",
    Surname: "Rajeli Asri",
    "Primary Club": "Royal Malaysian Police",
    County: "",
    Country: "Malaysia",
  },
  2753: {
    Title: "",
    Initials: "RA",
    Surname: "Bickell",
    "Primary Club": "Queensland Team",
    County: "Queensland",
    Country: "Australia",
  },
  2754: {
    Title: "Sgt",
    Initials: "JK",
    Surname: "Birgen",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2755: {
    Title: "Mr",
    Initials: "LR",
    Surname: "Braithwaite",
    "Primary Club": "Guyana NRA",
    County: "",
    Country: "West Indies",
  },
  2756: {
    Title: "Mr",
    Initials: "ES",
    Surname: "Compton",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  2757: {
    Title: "",
    Initials: "AJ",
    Surname: "Coots",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2758: {
    Title: "",
    Initials: "DMC",
    Surname: "Dodds",
    "Primary Club": "Rand RC",
    County: "Gauteng",
    Country: "South Africa",
  },
  2759: {
    Title: "Mr",
    Initials: "FM",
    Surname: "Evers",
    "Primary Club": "International RC",
    County: "",
    Country: "Netherlands",
  },
  2760: {
    Title: "",
    Initials: "NC",
    Surname: "Forward",
    "Primary Club": "KCS Old Boys R.C",
    County: "",
    Country: "",
  },
  2761: {
    Title: "",
    Initials: "RR",
    Surname: "Goodluck",
    "Primary Club": "Guyana NRA",
    County: "",
    Country: "Guyana",
  },
  2762: {
    Title: "",
    Initials: "DG",
    Surname: "Grummt",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2763: {
    Title: "",
    Initials: "AS",
    Surname: "Kolenko",
    "Primary Club": "Ontario Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  2764: {
    Title: "Dr",
    Initials: "GD",
    Surname: "Launcelot",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2765: {
    Title: "Mr",
    Initials: "EL",
    Surname: "Legei",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  2766: {
    Title: "Mr",
    Initials: "RK",
    Surname: "Modenbach",
    "Primary Club": "",
    County: "",
    Country: "Germany",
  },
  2767: {
    Title: "Miss",
    Initials: "EJ",
    Surname: "Nuttall",
    "Primary Club": "ATSC",
    County: "Lancashire",
    Country: "England",
  },
  2768: {
    Title: "Lt Gen",
    Initials: "L",
    Surname: "O'Donnell",
    "Primary Club": "Australian Army",
    County: "NSW",
    Country: "Australia",
  },
  2769: {
    Title: "Lt Col",
    Initials: "JF",
    Surname: "Rice",
    "Primary Club": "USA National",
    County: "",
    Country: "United States of America",
  },
  2770: {
    Title: "",
    Initials: "AD",
    Surname: "Scates",
    "Primary Club": "NRRC",
    County: "Natal",
    Country: "South Africa",
  },
  2771: {
    Title: "Mr",
    Initials: "S",
    Surname: "Sehmi",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2773: {
    Title: "Mr",
    Initials: "AT",
    Surname: "Zaidee Mohd Zain",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  2774: {
    Title: "",
    Initials: "SC",
    Surname: "Bradshaw",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  2775: {
    Title: "",
    Initials: "DW",
    Surname: "Cotterill",
    "Primary Club": "Australian U25",
    County: "NSW",
    Country: "Australia",
  },
  2776: {
    Title: "Mr",
    Initials: "ME",
    Surname: "Fray",
    "Primary Club": "",
    County: "Hampshire",
    Country: "Wales",
  },
  2777: {
    Title: "Miss",
    Initials: "MM",
    Surname: "Gallagher",
    "Primary Club": "USA",
    County: "",
    Country: "USA",
  },
  2778: {
    Title: "Miss",
    Initials: "SJ",
    Surname: "Gallagher",
    "Primary Club": "USA Young Eagles",
    County: "",
    Country: "United States of America",
  },
  2779: {
    Title: "",
    Initials: "JK",
    Surname: "Harless",
    "Primary Club": "USA Young Eagles",
    County: "",
    Country: "United States of America",
  },
  2780: {
    Title: "Cdt",
    Initials: "TL",
    Surname: "Haskell",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  2781: {
    Title: "Cdt",
    Initials: "RJA",
    Surname: "Hinde",
    "Primary Club": "Wellington College",
    County: "Hampshire",
    Country: "England",
  },
  2782: {
    Title: "Cdt",
    Initials: "WTC",
    Surname: "Hinde",
    "Primary Club": "Wellington College",
    County: "Surrey",
    Country: "England",
  },
  2783: {
    Title: "C/Sgt",
    Initials: "RTS",
    Surname: "Holtum",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2784: {
    Title: "F/Sgt",
    Initials: "NJG",
    Surname: "Jenkins",
    "Primary Club": "Uppingham School",
    County: "Rutland",
    Country: "England",
  },
  2785: {
    Title: "Cdt",
    Initials: "LE",
    Surname: "Jones",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  2786: {
    Title: "",
    Initials: "JR",
    Surname: "Kovac",
    "Primary Club": "USA Goodwill",
    County: "",
    Country: "United States of America",
  },
  2787: {
    Title: "Cdt",
    Initials: "JPE",
    Surname: "McBride",
    "Primary Club": "Campbell College C.C.F",
    County: "",
    Country: "",
  },
  2788: {
    Title: "",
    Initials: "ADN",
    Surname: "Noel",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2789: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Norris",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  2790: {
    Title: "Cpl",
    Initials: "MJR",
    Surname: "Rogers",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  2791: {
    Title: "Cdt",
    Initials: "HE",
    Surname: "Snelgrove",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  2792: {
    Title: "",
    Initials: "CDG",
    Surname: "Spurr",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2793: {
    Title: "Cdt",
    Initials: "CE",
    Surname: "Tootell",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  2794: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Whatley",
    "Primary Club": "Cranbrook School",
    County: "Kent",
    Country: "England",
  },
  2795: {
    Title: "Mr",
    Initials: "A",
    Surname: "Burton",
    "Primary Club": "Guernsey RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  2796: {
    Title: "Mr",
    Initials: "TA",
    Surname: "Cochrane",
    "Primary Club": "Old Guildfordians",
    County: "Surrey",
    Country: "England",
  },
  2797: {
    Title: "",
    Initials: "JS",
    Surname: "Colquhoun",
    "Primary Club": "Manydown Rifle Club",
    County: "Oxfordshire",
    Country: "England",
  },
  2798: {
    Title: "",
    Initials: "BSA",
    Surname: "Lacy",
    "Primary Club": "Edinburgh University R.C",
    County: "Buckinghamshire",
    Country: "Ireland",
  },
  2799: {
    Title: "",
    Initials: "KM",
    Surname: "Maddison",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "England",
  },
  2800: {
    Title: "Mr",
    Initials: "CSJ",
    Surname: "Marlow",
    "Primary Club": "Bromsgrove RC",
    County: "Hereford & Worcs",
    Country: "Wales",
  },
  2801: {
    Title: "Mr",
    Initials: "PAM",
    Surname: "Schouten Trautig",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  2802: {
    Title: "",
    Initials: "T",
    Surname: "Schroder",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2803: {
    Title: "",
    Initials: "WJ",
    Surname: "Tanzer",
    "Primary Club": "Sevenoaks School",
    County: "Sussex",
    Country: "England",
  },
  2804: {
    Title: "Cdt",
    Initials: "NE",
    Surname: "Termeer",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2805: {
    Title: "",
    Initials: "OT",
    Surname: "Wheble",
    "Primary Club": "Reading University",
    County: "Devon",
    Country: "England",
  },
  2806: {
    Title: "Mr",
    Initials: "M G",
    Surname: "Whicher",
    "Primary Club": "Old Guildfordians RC",
    County: "Berkshire",
    Country: "England",
  },
  2807: {
    Title: "Cdt",
    Initials: "JH",
    Surname: "Bannock",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2808: {
    Title: "Cpl",
    Initials: "BE",
    Surname: "Barber",
    "Primary Club": "Christ's Hospital",
    County: "Sussex",
    Country: "England",
  },
  2809: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Benterud",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2810: {
    Title: "Mrs",
    Initials: "A",
    Surname: "Bathurst",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "Northern Ireland",
  },
  2811: {
    Title: "",
    Initials: "DM",
    Surname: "Campbell",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2813: {
    Title: "Cdt",
    Initials: "MC",
    Surname: "Cleary",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "",
  },
  2814: {
    Title: "Cdt",
    Initials: "MJ",
    Surname: "Cleary",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2815: {
    Title: "",
    Initials: "CV",
    Surname: "Cole",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  2816: {
    Title: "Cdt",
    Initials: "ES",
    Surname: "Colin",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2817: {
    Title: "",
    Initials: "DR",
    Surname: "Collings",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  2818: {
    Title: "Cdt",
    Initials: "CA",
    Surname: "Cook",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2819: {
    Title: "Sgt",
    Initials: "S",
    Surname: "Cote",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2820: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Cotillard",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  2821: {
    Title: "Cdt",
    Initials: "AD",
    Surname: "Coulton",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2822: {
    Title: "Sgt",
    Initials: "TRK",
    Surname: "Coulton",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2823: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Crann",
    "Primary Club": "607(Nearmouth)Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  2824: {
    Title: "",
    Initials: "JP",
    Surname: "Creedon",
    "Primary Club": "City RC",
    County: "Hampshire",
    Country: "Scotland",
  },
  2825: {
    Title: "Cdt",
    Initials: "KF",
    Surname: "Crickmar",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  2826: {
    Title: "Mr",
    Initials: "SFD",
    Surname: "Dash",
    "Primary Club": "Sussex RC",
    County: "Sussex",
    Country: "England",
  },
  2827: {
    Title: "Cdt",
    Initials: "MCD",
    Surname: "Day",
    "Primary Club": "Cheltenham College CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  2828: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Deyell",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2829: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Donnelly",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  2830: {
    Title: "Cdt",
    Initials: "MF",
    Surname: "Faucher",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2831: {
    Title: "",
    Initials: "F",
    Surname: "Fladmark",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  2832: {
    Title: "Cdt",
    Initials: "j",
    Surname: "Forward",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2833: {
    Title: "",
    Initials: "AC",
    Surname: "French",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "England",
  },
  2834: {
    Title: "Cdt",
    Initials: "RP",
    Surname: "Furlong",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2835: {
    Title: "Cpl",
    Initials: "PR",
    Surname: "Gordon",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2836: {
    Title: "",
    Initials: "DBA",
    Surname: "Graham",
    "Primary Club": "Lee Enfield RA",
    County: "",
    Country: "",
  },
  2837: {
    Title: "LCpl",
    Initials: "NW",
    Surname: "Grima",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2838: {
    Title: "",
    Initials: "DW",
    Surname: "Grove",
    "Primary Club": "Exeter School CCF",
    County: "Devon",
    Country: "England",
  },
  2839: {
    Title: "Mr",
    Initials: "",
    Surname: "Halim Jaafar",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  2840: {
    Title: "",
    Initials: "GFJ",
    Surname: "Halsey",
    "Primary Club": "Durham University",
    County: "Hertfordshire",
    Country: "England",
  },
  2841: {
    Title: "",
    Initials: "F",
    Surname: "Hansel",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2842: {
    Title: "WO2",
    Initials: "JR",
    Surname: "Haskins",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2844: {
    Title: "Cdt",
    Initials: "JAH",
    Surname: "Hengen",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2845: {
    Title: "PHK",
    Initials: "GH",
    Surname: "Herter",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2846: {
    Title: "Cdt",
    Initials: "TH",
    Surname: "Hickey",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2847: {
    Title: "Cpl",
    Initials: "JS",
    Surname: "Honner",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2848: {
    Title: "",
    Initials: "SR",
    Surname: "Hussey",
    "Primary Club": "U.L.R.C",
    County: "Dorset",
    Country: "Great Britain",
  },
  2849: {
    Title: "Mr",
    Initials: "",
    Surname: "Hussin Mat Jais",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  2850: {
    Title: "Cdt",
    Initials: "AD",
    Surname: "Islas",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2851: {
    Title: "WO2",
    Initials: "D",
    Surname: "Jessup",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2852: {
    Title: "",
    Initials: "P",
    Surname: "Jmaeff",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2853: {
    Title: "Mrs",
    Initials: "EG",
    Surname: "Charlton",
    "Primary Club": "Old Guildfordians RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  2854: {
    Title: "Rfn",
    Initials: "A",
    Surname: "Keely",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2855: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Kennedy",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2856: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Kershaw",
    "Primary Club": "Gresham's R.C",
    County: "Norfolk",
    Country: "England",
  },
  2857: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Lambe",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2859: {
    Title: "Cdt",
    Initials: "PD",
    Surname: "Lanouette",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2860: {
    Title: "",
    Initials: "B",
    Surname: "Large",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  2861: {
    Title: "",
    Initials: "TEM",
    Surname: "Law",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  2862: {
    Title: "Cdt",
    Initials: "FL",
    Surname: "Lewis",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  2863: {
    Title: "",
    Initials: "M",
    Surname: "Lottritz",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2864: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Marshall",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2866: {
    Title: "",
    Initials: "JM",
    Surname: "Moree",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Netherlands",
  },
  2867: {
    Title: "",
    Initials: "F",
    Surname: "Morgan",
    "Primary Club": "Wodonga RC",
    County: "Victoria",
    Country: "Australia",
  },
  2868: {
    Title: "",
    Initials: "H",
    Surname: "Mostofi",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  2869: {
    Title: "",
    Initials: "DJ",
    Surname: "Mott",
    "Primary Club": "Welwyn Phoenix",
    County: "Northants - Leics - Rutland",
    Country: "United Kingdom",
  },
  2870: {
    Title: "M.",
    Initials: "P",
    Surname: "Mullot",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  2872: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Norris",
    "Primary Club": "Campbell College C.C.F",
    County: "Ulster",
    Country: "Ireland",
  },
  2873: {
    Title: "",
    Initials: "R",
    Surname: "Nuttall",
    "Primary Club": "Derbyshire RA",
    County: "Derbyshire",
    Country: "England",
  },
  2874: {
    Title: "Dr",
    Initials: "CJ",
    Surname: "Padbury",
    "Primary Club": "City RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  2875: {
    Title: "Cdt Cpl",
    Initials: "OJJ",
    Surname: "Palasmith",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  2876: {
    Title: "Cdt",
    Initials: "CJ",
    Surname: "Paquette",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2877: {
    Title: "Mr",
    Initials: "JAM",
    Surname: "Paton",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  2878: {
    Title: "Cdt",
    Initials: "SP",
    Surname: "Patterson",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "",
  },
  2879: {
    Title: "Cpl",
    Initials: "L",
    Surname: "Payne",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  2880: {
    Title: "Mr",
    Initials: "R",
    Surname: "Peckett",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  2881: {
    Title: "",
    Initials: "BJ",
    Surname: "Pieters",
    "Primary Club": "South Africa",
    County: "Gauteng",
    Country: "South Africa",
  },
  2882: {
    Title: "",
    Initials: "NP",
    Surname: "Pinks",
    "Primary Club": "NLRC",
    County: "Norfolk",
    Country: "England",
  },
  2883: {
    Title: "",
    Initials: "MF",
    Surname: "Rahman",
    "Primary Club": "Canada U25 Rifle Team",
    County: "Ontario",
    Country: "Canada",
  },
  2884: {
    Title: "",
    Initials: "KJ",
    Surname: "Reid",
    "Primary Club": "",
    County: "Kent",
    Country: "England",
  },
  2885: {
    Title: "Cdt",
    Initials: "LE",
    Surname: "Roberts",
    "Primary Club": "111 (Sunderland) Sqn",
    County: "County Durham",
    Country: "Great Britain",
  },
  2886: {
    Title: "",
    Initials: "ERL",
    Surname: "Ross",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2887: {
    Title: "Cpl",
    Initials: "SW",
    Surname: "Roth",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2889: {
    Title: "Cdt",
    Initials: "TB",
    Surname: "Ryan",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "",
  },
  2890: {
    Title: "",
    Initials: "NS",
    Surname: "Saloman",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2891: {
    Title: "Cdt",
    Initials: "JB",
    Surname: "Scharfegger",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2892: {
    Title: "Cdt",
    Initials: "LM",
    Surname: "Scoville",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2893: {
    Title: "Miss",
    Initials: "TE",
    Surname: "Shields",
    "Primary Club": "URA",
    County: "County Down",
    Country: "N. Ireland",
  },
  2894: {
    Title: "Sgt",
    Initials: "LE",
    Surname: "Silver",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2895: {
    Title: "",
    Initials: "JN",
    Surname: "Simpson",
    "Primary Club": "Bedfordians",
    County: "Surrey",
    Country: "Great Britain",
  },
  2896: {
    Title: "",
    Initials: "GE d'E",
    Surname: "Skipwith",
    "Primary Club": "Edinburgh University R.C",
    County: "Middlesex",
    Country: "England",
  },
  2897: {
    Title: "",
    Initials: "P",
    Surname: "Slowe",
    "Primary Club": "Guyana N.R.A",
    County: "",
    Country: "Guyana",
  },
  2898: {
    Title: "C/Cpl",
    Initials: "M",
    Surname: "Sommerville",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  2899: {
    Title: "",
    Initials: "RM",
    Surname: "Spencer",
    "Primary Club": "Huddersfield R.C",
    County: "",
    Country: "",
  },
  2900: {
    Title: "Cdt",
    Initials: "CAF",
    Surname: "Stening",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2901: {
    Title: "",
    Initials: "FJ",
    Surname: "Sweatman",
    "Primary Club": "Christ's Hospital",
    County: "Devon",
    Country: "England",
  },
  2902: {
    Title: "Cdt",
    Initials: "KP",
    Surname: "Taylor",
    "Primary Club": "Campbell College C.C.F",
    County: "",
    Country: "",
  },
  2903: {
    Title: "Cdt Cpl",
    Initials: "SJ",
    Surname: "Taylor",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "Ireland",
  },
  2904: {
    Title: "",
    Initials: "RF",
    Surname: "Teustel",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2905: {
    Title: "Cdt",
    Initials: "SL",
    Surname: "Thompson",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2906: {
    Title: "Mrs",
    Initials: "NT",
    Surname: "Tompkins",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  2907: {
    Title: "Cdt",
    Initials: "FT",
    Surname: "Tremblay",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2908: {
    Title: "Sgt",
    Initials: "AJ",
    Surname: "Turner",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  2909: {
    Title: "Cdt",
    Initials: "HJ",
    Surname: "Turner",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "",
  },
  2910: {
    Title: "Cdt",
    Initials: "BA",
    Surname: "Usher",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2911: {
    Title: "CUO",
    Initials: "CP",
    Surname: "van Schalkwyk",
    "Primary Club": "Australian Cadets",
    County: "",
    Country: "Australia",
  },
  2912: {
    Title: "Cdt",
    Initials: "KA",
    Surname: "Webb",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  2913: {
    Title: "",
    Initials: "MS",
    Surname: "Westmoquette",
    "Primary Club": "ULRC",
    County: "Sussex",
    Country: "England",
  },
  2914: {
    Title: "",
    Initials: "MP",
    Surname: "Wheatcroft",
    "Primary Club": "C.U.R.A",
    County: "Cambridgeshire",
    Country: "England",
  },
  2915: {
    Title: "Sgt",
    Initials: "MJ",
    Surname: "White",
    "Primary Club": "St. Johns School",
    County: "",
    Country: "",
  },
  2916: {
    Title: "",
    Initials: "R",
    Surname: "Whitney",
    "Primary Club": "U.S.A",
    County: "",
    Country: "United States of America",
  },
  2917: {
    Title: "",
    Initials: "WDC",
    Surname: "Wilcox",
    "Primary Club": "Cheshire Police",
    County: "Cheshire",
    Country: "Wales",
  },
  2918: {
    Title: "",
    Initials: "GA",
    Surname: "Wittorff",
    "Primary Club": "Bunbury",
    County: "",
    Country: "Australia",
  },
  2919: {
    Title: "",
    Initials: "D",
    Surname: "Worheide",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  2922: {
    Title: "Cpl",
    Initials: "MR",
    Surname: "Zynda",
    "Primary Club": "Devon ACF",
    County: "Devon",
    Country: "England",
  },
  2923: {
    Title: "",
    Initials: "JB",
    Surname: "Crawford",
    "Primary Club": "NACRA",
    County: "Somerset",
    Country: "England",
  },
  2924: {
    Title: "Capt",
    Initials: "NA",
    Surname: "Hawkins",
    "Primary Club": "ATRC",
    County: "Essex",
    Country: "England",
  },
  2925: {
    Title: "",
    Initials: "I",
    Surname: "Macarty",
    "Primary Club": "Croydon R&PC",
    County: "",
    Country: "",
  },
  2926: {
    Title: "",
    Initials: "I",
    Surname: "Annand",
    "Primary Club": "N.L.R.C.",
    County: "County of London",
    Country: "England",
  },
  2927: {
    Title: "",
    Initials: "J",
    Surname: "Dalley",
    "Primary Club": "Ricochet R.C",
    County: "Essex",
    Country: "England",
  },
  2928: {
    Title: "",
    Initials: "D",
    Surname: "Shaw",
    "Primary Club": "Marylebone R&PC",
    County: "",
    Country: "",
  },
  2929: {
    Title: "",
    Initials: "DA",
    Surname: "Reynolds",
    "Primary Club": "National Westminster Bank",
    County: "",
    Country: "",
  },
  2930: {
    Title: "",
    Initials: "KA",
    Surname: "McLuckie",
    "Primary Club": "Sidcup",
    County: "Kent",
    Country: "England",
  },
  2931: {
    Title: "",
    Initials: "C",
    Surname: "Brannigan",
    "Primary Club": "Comber R.C",
    County: "Ulster",
    Country: "Ireland",
  },
  2932: {
    Title: "Mr",
    Initials: "NBG",
    Surname: "Alliston",
    "Primary Club": "Uppingham Veterans RC",
    County: "Leicestershire",
    Country: "England",
  },
  2933: {
    Title: "Cdt",
    Initials: "SJ",
    Surname: "Balbach",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  2934: {
    Title: "Mr",
    Initials: "A",
    Surname: "Biggs",
    "Primary Club": "RNTRC",
    County: "Derbyshire",
    Country: "England",
  },
  2935: {
    Title: "Mr",
    Initials: "D",
    Surname: "Blake",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  2936: {
    Title: "Mr",
    Initials: "MI",
    Surname: "Buchanan",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  2937: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Clapham",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  2938: {
    Title: "Mr",
    Initials: "ARG",
    Surname: "Cross",
    "Primary Club": "NLRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  2939: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Dawes",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "England",
  },
  2940: {
    Title: "",
    Initials: "HSM",
    Surname: "Elliott",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "England",
  },
  2941: {
    Title: "Mr",
    Initials: "BM",
    Surname: "Ellis",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2942: {
    Title: "Cdt",
    Initials: "MA",
    Surname: "Forsyth",
    "Primary Club": "Angus & Dundee Cdt Bn",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2943: {
    Title: "Cdt",
    Initials: "SJ",
    Surname: "Gilbert",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  2944: {
    Title: "Mr",
    Initials: "HJ",
    Surname: "Golaszewski",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  2945: {
    Title: "Mr",
    Initials: "OP",
    Surname: "Henry",
    "Primary Club": "MCC RC Australia",
    County: "",
    Country: "Australia",
  },
  2946: {
    Title: "",
    Initials: "HK",
    Surname: "Krueger",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2947: {
    Title: "Cdt",
    Initials: "MLV",
    Surname: "Latimer",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  2948: {
    Title: "Cdt",
    Initials: "AJ",
    Surname: "Maltby",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  2949: {
    Title: "Sgt",
    Initials: "MGD",
    Surname: "McArdle",
    "Primary Club": "Tonbridge School",
    County: "Sussex",
    Country: "England",
  },
  2950: {
    Title: "Mr",
    Initials: "PR",
    Surname: "McCurley",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  2951: {
    Title: "SSgt",
    Initials: "SL",
    Surname: "McIlreavy",
    "Primary Club": "1st (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  2952: {
    Title: "Mr",
    Initials: "JMC",
    Surname: "McSparron-Edwards",
    "Primary Club": "LMRA",
    County: "Yorkshire",
    Country: "England",
  },
  2953: {
    Title: "Cdt",
    Initials: "RE",
    Surname: "Molloy",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  2954: {
    Title: "Dr",
    Initials: "JGR",
    Surname: "Munn",
    "Primary Club": "Chobham & District RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  2955: {
    Title: "Cdt",
    Initials: "JARF",
    Surname: "Roulston",
    "Primary Club": "Sedbergh School",
    County: "Northumberland",
    Country: "England",
  },
  2956: {
    Title: "",
    Initials: "FC",
    Surname: "Yip",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  2957: {
    Title: "Cpl",
    Initials: "JM",
    Surname: "Gatere",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  2958: {
    Title: "Miss",
    Initials: "JK",
    Surname: "Hanna",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  2959: {
    Title: "Mr",
    Initials: "H",
    Surname: "Mitera",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  2960: {
    Title: "Mr",
    Initials: "HGM",
    Surname: "Mooiman",
    "Primary Club": "KSV Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  2961: {
    Title: "Mr",
    Initials: "JCB",
    Surname: "Peachey",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "",
  },
  2962: {
    Title: "",
    Initials: "WA",
    Surname: "Botha",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  2963: {
    Title: "Mr",
    Initials: "M",
    Surname: "Tompkins",
    "Primary Club": "USA",
    County: "Ulster",
    Country: "USA",
  },
  2964: {
    Title: "Miss",
    Initials: "TI",
    Surname: "Macleod",
    "Primary Club": "St Andrews Uni. R.C",
    County: "",
    Country: "",
  },
  2965: {
    Title: "Mr",
    Initials: "RI",
    Surname: "Nelson",
    "Primary Club": "Old Stamfordians",
    County: "Lincolnshire",
    Country: "England",
  },
  2966: {
    Title: "Mr",
    Initials: "NM",
    Surname: "Bartlett",
    "Primary Club": "Etonian RC",
    County: "",
    Country: "Wales",
  },
  2967: {
    Title: "",
    Initials: "WJ",
    Surname: "Oddy",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  2968: {
    Title: "Miss",
    Initials: "JM",
    Surname: "Campbell-Smith",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2969: {
    Title: "Mr",
    Initials: "MH",
    Surname: "Davis",
    "Primary Club": "OCRA",
    County: "London",
    Country: "England",
  },
  2970: {
    Title: "",
    Initials: "JA",
    Surname: "de Havilland",
    "Primary Club": "N.R.A",
    County: "",
    Country: "England",
  },
  2971: {
    Title: "Mr",
    Initials: "DK",
    Surname: "Haskell",
    "Primary Club": "OCRA",
    County: "Hampshire",
    Country: "England",
  },
  2972: {
    Title: "Mr",
    Initials: "JEB",
    Surname: "Hissey",
    "Primary Club": "English VIII",
    County: "",
    Country: "",
  },
  2973: {
    Title: "Mr",
    Initials: "CJF",
    Surname: "Hayes",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "England",
  },
  2974: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Hissey",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  2975: {
    Title: "Mr",
    Initials: "RS",
    Surname: "Kenchington",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  2976: {
    Title: "Mr",
    Initials: "CD",
    Surname: "Law",
    "Primary Club": "LPSC",
    County: "Middlesex",
    Country: "England",
  },
  2977: {
    Title: "",
    Initials: "JA",
    Surname: "Lyons",
    "Primary Club": "N.L.R.C.",
    County: "",
    Country: "Ireland",
  },
  2978: {
    Title: "Mr",
    Initials: "AWC",
    Surname: "Meldrum",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  2980: {
    Title: "Mr",
    Initials: "WM",
    Surname: "McDowell",
    "Primary Club": "NRA",
    County: "Berkshire",
    Country: "Ireland",
  },
  2981: {
    Title: "Mr",
    Initials: "CDF",
    Surname: "Boylan",
    "Primary Club": "Irish Rifle Club",
    County: "Kent",
    Country: "Ireland",
  },
  2982: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Peck",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  2983: {
    Title: "Mr",
    Initials: "KM",
    Surname: "Pilcher",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  2984: {
    Title: "",
    Initials: "A",
    Surname: "Pitcher",
    "Primary Club": "N.R.A",
    County: "Essex",
    Country: "England",
  },
  2985: {
    Title: "",
    Initials: "B",
    Surname: "Tostevin",
    "Primary Club": "RAFTRC",
    County: "Lincolnshire",
    Country: "England",
  },
  2986: {
    Title: "",
    Initials: "RPG",
    Surname: "Voremberg",
    "Primary Club": "N.R.A",
    County: "",
    Country: "England",
  },
  2987: {
    Title: "Mr",
    Initials: "AWC",
    Surname: "Meldrum",
    "Primary Club": "NRC of Scotland",
    County: "Sussex",
    Country: "Scotland",
  },
  2988: {
    Title: "Mr",
    Initials: "GEW",
    Surname: "Blakeney",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2989: {
    Title: "",
    Initials: "RS",
    Surname: "Temme",
    "Primary Club": "N.R.A",
    County: "",
    Country: "",
  },
  2990: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Campbell-Smith",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2991: {
    Title: "",
    Initials: "J",
    Surname: "Llandro",
    "Primary Club": "C.U.R.A",
    County: "",
    Country: "Great Britain",
  },
  2992: {
    Title: "Mrs",
    Initials: "D",
    Surname: "Bridger",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "Wales",
  },
  2993: {
    Title: "",
    Initials: "JR",
    Surname: "Doran",
    "Primary Club": "Herts & Essex",
    County: "Essex",
    Country: "England",
  },
  2994: {
    Title: "Mr",
    Initials: "MFD",
    Surname: "Brown",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2995: {
    Title: "Mr",
    Initials: "HM",
    Surname: "Butcher",
    "Primary Club": "Metropolitan Police SC",
    County: "",
    Country: "Scotland",
  },
  2996: {
    Title: "Mr",
    Initials: "BC",
    Surname: "Mackie",
    "Primary Club": "NRC of S",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  2997: {
    Title: "Mr",
    Initials: "MW",
    Surname: "Andrews",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  2998: {
    Title: "Mr",
    Initials: "MAM",
    Surname: "Crichton Maitland",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  2999: {
    Title: "Mr",
    Initials: "TG",
    Surname: "Mack",
    "Primary Club": "NLRC",
    County: "",
    Country: "Scotland",
  },
  3000: {
    Title: "",
    Initials: "JF",
    Surname: "Frey",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  3001: {
    Title: "Cdt",
    Initials: "RJM",
    Surname: "Holtum",
    "Primary Club": "Athelings",
    County: "Somerset",
    Country: "England",
  },
  3002: {
    Title: "Mr",
    Initials: "S",
    Surname: "Ndambuki",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3003: {
    Title: "",
    Initials: "H",
    Surname: "Ohlsen",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  3004: {
    Title: "",
    Initials: "T",
    Surname: "Ozawa",
    "Primary Club": "",
    County: "",
    Country: "Japan",
  },
  3005: {
    Title: "Mr",
    Initials: "YS",
    Surname: "Sakakibara",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  3006: {
    Title: "Mr",
    Initials: "U",
    Surname: "Soelzer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3007: {
    Title: "",
    Initials: "K",
    Surname: "Thumschat",
    "Primary Club": "",
    County: "",
    Country: "Germany",
  },
  3008: {
    Title: "Mr",
    Initials: "DRC",
    Surname: "Brechin",
    "Primary Club": "NRC of S",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3009: {
    Title: "Mr",
    Initials: "DN",
    Surname: "Lindsay",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3010: {
    Title: "Mr",
    Initials: "O",
    Surname: "Jones",
    "Primary Club": "NRA",
    County: "Norfolk",
    Country: "England",
  },
  3013: {
    Title: "",
    Initials: "P",
    Surname: "Doerrer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3014: {
    Title: "",
    Initials: "M",
    Surname: "Hochmuth",
    "Primary Club": "B.D.M.P Germany",
    County: "",
    Country: "Germany",
  },
  3015: {
    Title: "Cdt",
    Initials: "AD",
    Surname: "Jackson",
    "Primary Club": "1297 Sqn ATC",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  3016: {
    Title: "",
    Initials: "JC",
    Surname: "Lall",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "Trinidad & Tobago",
  },
  3017: {
    Title: "",
    Initials: "JG",
    Surname: "Schafferius",
    "Primary Club": "Australia",
    County: "Queensland",
    Country: "Australia",
  },
  3018: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Barlow",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3020: {
    Title: "",
    Initials: "DA",
    Surname: "Rose",
    "Primary Club": "Australian Schools",
    County: "NSW",
    Country: "Australia",
  },
  3022: {
    Title: "Mr",
    Initials: "B",
    Surname: "Bowden",
    "Primary Club": "Central RC",
    County: "",
    Country: "Australia",
  },
  3024: {
    Title: "Mr",
    Initials: "WLL",
    Surname: "Lewis",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  3025: {
    Title: "Sgt",
    Initials: "SJ",
    Surname: "Hier",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "Wales",
  },
  3026: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Westlake",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3027: {
    Title: "Mrs",
    Initials: "SP",
    Surname: "Thorogood",
    "Primary Club": "Welsh RA",
    County: "Gloucestershire",
    Country: "Wales",
  },
  3028: {
    Title: "Mrs",
    Initials: "J",
    Surname: "Belanger",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3029: {
    Title: "Mr",
    Initials: "H",
    Surname: "Ball",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "Wales",
  },
  3030: {
    Title: "Cdt",
    Initials: "RJW",
    Surname: "Waters",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3031: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Borland",
    "Primary Club": "Fife & Kinross FBRC",
    County: "Shropshire",
    Country: "Scotland",
  },
  3032: {
    Title: "Flt Lt",
    Initials: "G",
    Surname: "Basnett",
    "Primary Club": "RAF Air Cmd",
    County: "Cheshire",
    Country: "England",
  },
  3033: {
    Title: "Mr",
    Initials: "JM",
    Surname: "De Kort",
    "Primary Club": "F Class Netherlands",
    County: "",
    Country: "Netherlands",
  },
  3034: {
    Title: "Mr",
    Initials: "R",
    Surname: "Van niel",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  3035: {
    Title: "Mr",
    Initials: "JJW",
    Surname: "Edwards",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3036: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Bowley",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "",
    Country: "Wales",
  },
  3037: {
    Title: "Mr",
    Initials: "SG",
    Surname: "Gilbert",
    "Primary Club": "URA",
    County: "Ulster",
    Country: "Ireland",
  },
  3038: {
    Title: "Miss",
    Initials: "KE",
    Surname: "Simpson",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "",
  },
  3039: {
    Title: "Miss",
    Initials: "HM",
    Surname: "Taylor",
    "Primary Club": "Irish Rifle Club",
    County: "Northumberland",
    Country: "Ireland",
  },
  3040: {
    Title: "Mr",
    Initials: "SKC",
    Surname: "Hunter",
    "Primary Club": "Old Epsomian RC",
    County: "",
    Country: "Scotland",
  },
  3041: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Creedy",
    "Primary Club": "Yorks N&W ACF",
    County: "",
    Country: "",
  },
  3042: {
    Title: "Mr",
    Initials: "CD",
    Surname: "Pocock",
    "Primary Club": "Durham University",
    County: "Surrey",
    Country: "England",
  },
  3043: {
    Title: "Miss",
    Initials: "ACL",
    Surname: "Lean",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3044: {
    Title: "Mr",
    Initials: "WJS",
    Surname: "Hayman",
    "Primary Club": "Bunhill Rifle Club",
    County: "Middlesex",
    Country: "England",
  },
  3046: {
    Title: "Miss",
    Initials: "TD",
    Surname: "Duhre",
    "Primary Club": "Newcastle Uni RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3047: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Spinney",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3048: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Branch",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3049: {
    Title: "Mr",
    Initials: "R",
    Surname: "Mills",
    "Primary Club": "OCRC",
    County: "Sussex",
    Country: "England",
  },
  3050: {
    Title: "Mr",
    Initials: "SLB",
    Surname: "Borin",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3052: {
    Title: "Miss",
    Initials: "ER",
    Surname: "Bagge",
    "Primary Club": "Newcastle University Stud",
    County: "Norfolk",
    Country: "England",
  },
  3054: {
    Title: "Mr",
    Initials: "TCR",
    Surname: "Richmond",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3055: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Jeal",
    "Primary Club": "ATSC",
    County: "Buckinghamshire",
    Country: "England",
  },
  3056: {
    Title: "Mr",
    Initials: "FR",
    Surname: "Rahman",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3057: {
    Title: "Mr",
    Initials: "PCA",
    Surname: "Slater",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  3058: {
    Title: "Mr",
    Initials: "IH",
    Surname: "Hindshaw",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3059: {
    Title: "Mr",
    Initials: "RB",
    Surname: "Morgan",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3060: {
    Title: "Mr",
    Initials: "PH",
    Surname: "Muckle",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  3061: {
    Title: "Miss",
    Initials: "AL",
    Surname: "Lamont",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3062: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Mayers",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  3063: {
    Title: "Dr",
    Initials: "MA",
    Surname: "Szymankiewicz",
    "Primary Club": "Chobham & District RC",
    County: "Surrey",
    Country: "England",
  },
  3064: {
    Title: "Mr",
    Initials: "AN",
    Surname: "Tongue",
    "Primary Club": "ATSC",
    County: "Kent",
    Country: "England",
  },
  3066: {
    Title: "Miss",
    Initials: "EF",
    Surname: "Parker",
    "Primary Club": "Yorks N&W ACF",
    County: "",
    Country: "",
  },
  3067: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Churchwell",
    "Primary Club": "Durham University",
    County: "Middlesex",
    Country: "England",
  },
  3068: {
    Title: "Cdt",
    Initials: "SG",
    Surname: "Gowin",
    "Primary Club": "Hants and IOW ACF",
    County: "Hampshire",
    Country: "England",
  },
  3069: {
    Title: "Lt",
    Initials: "DP",
    Surname: "Mills",
    "Primary Club": "ATRC",
    County: "Oxfordshire",
    Country: "England",
  },
  3070: {
    Title: "Mr",
    Initials: "RN",
    Surname: "Burkill",
    "Primary Club": "OURC",
    County: "County of London",
    Country: "Wales",
  },
  3071: {
    Title: "Mr",
    Initials: "C",
    Surname: "Beesley",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  3072: {
    Title: "Mr",
    Initials: "J",
    Surname: "Dawes",
    "Primary Club": "SUOTC",
    County: "Hampshire",
    Country: "England",
  },
  3073: {
    Title: "Miss",
    Initials: "FC",
    Surname: "Barrell",
    "Primary Club": "NRA",
    County: "Shropshire",
    Country: "Wales",
  },
  3074: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Webster",
    "Primary Club": "Heriot-Watt URC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3075: {
    Title: "Mr",
    Initials: "PTB",
    Surname: "Brett",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3076: {
    Title: "Mr",
    Initials: "IA",
    Surname: "Green",
    "Primary Club": "Royal Navy",
    County: "Somerset",
    Country: "",
  },
  3077: {
    Title: "Cdt",
    Initials: "TPJ",
    Surname: "Cook",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "Wales",
  },
  3078: {
    Title: "Mr",
    Initials: "D",
    Surname: "Booker",
    "Primary Club": "Newcastle University",
    County: "Warwickshire",
    Country: "England",
  },
  3079: {
    Title: "Miss",
    Initials: "JC",
    Surname: "Carson",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  3080: {
    Title: "Mr",
    Initials: "SME",
    Surname: "Hayton",
    "Primary Club": "Old Cranleighians",
    County: "Berkshire",
    Country: "England",
  },
  3081: {
    Title: "Miss",
    Initials: "EC",
    Surname: "Sigournay",
    "Primary Club": "SUOTC",
    County: "Sussex",
    Country: "England",
  },
  3082: {
    Title: "Ms",
    Initials: "KMB",
    Surname: "Brewer",
    "Primary Club": "OURC",
    County: "Oxfordshire",
    Country: "",
  },
  3083: {
    Title: "Mr",
    Initials: "DB",
    Surname: "Thomas",
    "Primary Club": "Reading University",
    County: "Berkshire",
    Country: "England",
  },
  3084: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Corrall",
    "Primary Club": "Reading University",
    County: "Oxfordshire",
    Country: "England",
  },
  3085: {
    Title: "Major",
    Initials: "M",
    Surname: "Geernaert-Davies",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  3086: {
    Title: "Mr",
    Initials: "LV",
    Surname: "Vanstone",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3087: {
    Title: "Mr",
    Initials: "MAS",
    Surname: "Sammels",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3088: {
    Title: "Cdt",
    Initials: "KP",
    Surname: "Pilcher",
    "Primary Club": "Cambs ACF",
    County: "Cambridgeshire",
    Country: "England",
  },
  3089: {
    Title: "Miss",
    Initials: "S",
    Surname: "Williams",
    "Primary Club": "Hants and IOW ACF",
    County: "Gloucestershire",
    Country: "Ireland",
  },
  3090: {
    Title: "Miss",
    Initials: "IS",
    Surname: "Klymchuk",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "New Zealand",
  },
  3091: {
    Title: "Mr",
    Initials: "GJ",
    Surname: "Stephen",
    "Primary Club": "Glasgow University RC",
    County: "West of Scotland",
    Country: "",
  },
  3092: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Williams",
    "Primary Club": "Lloyds TSB RC",
    County: "Lancashire",
    Country: "England",
  },
  3093: {
    Title: "Mr",
    Initials: "LRJ",
    Surname: "Allen",
    "Primary Club": "Glasgow University RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3094: {
    Title: "Mr",
    Initials: "RD",
    Surname: "Rudduck",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  3095: {
    Title: "Mr",
    Initials: "WP",
    Surname: "Hanley",
    "Primary Club": "Surrey RA",
    County: "Northants - Leics - Rutland",
    Country: "Scotland",
  },
  3096: {
    Title: "Cdt",
    Initials: "AW",
    Surname: "Dowle",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "England",
  },
  3098: {
    Title: "Mr",
    Initials: "OSS",
    Surname: "Stewart",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3100: {
    Title: "Mr",
    Initials: "MRT",
    Surname: "Tahir",
    "Primary Club": "OURC",
    County: "Oxfordshire",
    Country: "England",
  },
  3101: {
    Title: "Mr",
    Initials: "A",
    Surname: "Freemantle",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3102: {
    Title: "Mrs",
    Initials: "E",
    Surname: "Clarke",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3103: {
    Title: "Cdt",
    Initials: "AM",
    Surname: "Clark",
    "Primary Club": "Athelings",
    County: "Dorset",
    Country: "England",
  },
  3104: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Bryan",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  3105: {
    Title: "Mr",
    Initials: "P",
    Surname: "Cameron Taylor",
    "Primary Club": "BBC RC",
    County: "County of London",
    Country: "England",
  },
  3106: {
    Title: "Mr",
    Initials: "P",
    Surname: "Carneau",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  3107: {
    Title: "Mr",
    Initials: "RS",
    Surname: "Hayden",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  3108: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Smith",
    "Primary Club": "Ibis English",
    County: "Kent",
    Country: "England",
  },
  3109: {
    Title: "Mrs",
    Initials: "A",
    Surname: "Uhlig",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3110: {
    Title: "Mr",
    Initials: "AL",
    Surname: "Wort",
    "Primary Club": "Dorking & District RC",
    County: "Surrey",
    Country: "",
  },
  3111: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Duffy",
    "Primary Club": "Metropolitan Police SC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3112: {
    Title: "Mr",
    Initials: "IC",
    Surname: "Mowbray",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  3113: {
    Title: "Mr",
    Initials: "MW",
    Surname: "Brown",
    "Primary Club": "RAFTRC",
    County: "Nottinghamshire",
    Country: "England",
  },
  3114: {
    Title: "Mr",
    Initials: "LP",
    Surname: "Fenlon",
    "Primary Club": "MNSCI",
    County: "",
    Country: "Republic of Ireland",
  },
  3115: {
    Title: "Mr",
    Initials: "EB",
    Surname: "Haynes",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  3116: {
    Title: "Mr",
    Initials: "C",
    Surname: "Steele-Benny",
    "Primary Club": "RAF Air Cmd",
    County: "Wiltshire",
    Country: "England",
  },
  3119: {
    Title: "Miss",
    Initials: "JE",
    Surname: "Newing",
    "Primary Club": "BBC Rifle Club",
    County: "Kent",
    Country: "England",
  },
  3120: {
    Title: "Mr",
    Initials: "P",
    Surname: "Waldron",
    "Primary Club": "Glascoed",
    County: "South Wales",
    Country: "Wales",
  },
  3121: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Frost",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  3122: {
    Title: "Mr",
    Initials: "D",
    Surname: "Morgan",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  3123: {
    Title: "Mr",
    Initials: "DN",
    Surname: "Webber",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  3124: {
    Title: "Mr",
    Initials: "G",
    Surname: "Baillie",
    "Primary Club": "NRA",
    County: "Ulster",
    Country: "Ireland",
  },
  3125: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Burgess",
    "Primary Club": "West Wales ML",
    County: "",
    Country: "Wales",
  },
  3126: {
    Title: "Mr",
    Initials: "C",
    Surname: "Asquith",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3127: {
    Title: "Mr",
    Initials: "GL",
    Surname: "James",
    "Primary Club": "Welsh RA",
    County: "",
    Country: "Wales",
  },
  3128: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Evans",
    "Primary Club": "Wandsworth FBRC",
    County: "Somerset",
    Country: "Wales",
  },
  3129: {
    Title: "Mr",
    Initials: "RV",
    Surname: "Thomas",
    "Primary Club": "Bletchingly RBL",
    County: "Surrey",
    Country: "",
  },
  3130: {
    Title: "",
    Initials: "J",
    Surname: "Stewart",
    "Primary Club": "NRA",
    County: "Ulster",
    Country: "Ireland",
  },
  3131: {
    Title: "Mr",
    Initials: "S",
    Surname: "Scott",
    "Primary Club": "Carshalton Rifle Club",
    County: "Surrey",
    Country: "Scotland",
  },
  3132: {
    Title: "Mr",
    Initials: "S",
    Surname: "Marcus",
    "Primary Club": "SMKSG",
    County: "",
    Country: "Switzerland",
  },
  3133: {
    Title: "Miss",
    Initials: "AEL",
    Surname: "West",
    "Primary Club": "LMRA",
    County: "Surrey",
    Country: "England",
  },
  3134: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Baker",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  3135: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Hill",
    "Primary Club": "Grove Small Arms",
    County: "",
    Country: "England",
  },
  3136: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Alexander",
    "Primary Club": "Dungannon",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  3137: {
    Title: "Mr",
    Initials: "JT",
    Surname: "Kennedy",
    "Primary Club": "Nottingham City RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  3138: {
    Title: "Mr",
    Initials: "OEJ",
    Surname: "Spencer",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  3139: {
    Title: "Mr",
    Initials: "RE",
    Surname: "Nott",
    "Primary Club": "Windsor RC",
    County: "Berkshire",
    Country: "England",
  },
  3140: {
    Title: "Mr",
    Initials: "PR",
    Surname: "Bain",
    "Primary Club": "Explorers RC",
    County: "",
    Country: "Australia",
  },
  3141: {
    Title: "Mr",
    Initials: "DAG",
    Surname: "Mitchell",
    "Primary Club": "OWRC",
    County: "Berkshire",
    Country: "England",
  },
  3142: {
    Title: "Mr",
    Initials: "S",
    Surname: "Doble",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  3143: {
    Title: "Mr",
    Initials: "MW",
    Surname: "Latimer",
    "Primary Club": "URA",
    County: "Ulster",
    Country: "Ireland",
  },
  3144: {
    Title: "Mr",
    Initials: "TW",
    Surname: "Hunter",
    "Primary Club": "Old Epsomian RC",
    County: "County of London",
    Country: "Scotland",
  },
  3145: {
    Title: "Dr",
    Initials: "IT",
    Surname: "Matsumoto",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "England",
  },
  3146: {
    Title: "Mr",
    Initials: "R",
    Surname: "Carter",
    "Primary Club": "Bexleyheath SC",
    County: "Kent",
    Country: "England",
  },
  3147: {
    Title: "Mr",
    Initials: "E",
    Surname: "Robertson",
    "Primary Club": "Wellington College",
    County: "County of London",
    Country: "Scotland",
  },
  3148: {
    Title: "Mr",
    Initials: "JFJ",
    Surname: "Bryson",
    "Primary Club": "Cambridge University RA",
    County: "Surrey",
    Country: "England",
  },
  3149: {
    Title: "Mr",
    Initials: "CR",
    Surname: "Atherton",
    "Primary Club": "Raglan TSC",
    County: "",
    Country: "Australia",
  },
  3150: {
    Title: "Mr",
    Initials: "WA",
    Surname: "Wright",
    "Primary Club": "Wingham RC",
    County: "",
    Country: "Australia",
  },
  3151: {
    Title: "Mr",
    Initials: "J",
    Surname: "Kielly",
    "Primary Club": "Pheasant Creek",
    County: "",
    Country: "Australia",
  },
  3152: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Hill",
    "Primary Club": "Queensland",
    County: "",
    Country: "Australia",
  },
  3153: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Halloran",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  3154: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Freebairn",
    "Primary Club": "Balaklava",
    County: "",
    Country: "Australia",
  },
  3155: {
    Title: "Mr",
    Initials: "TS",
    Surname: "Courtney",
    "Primary Club": "Maryborough",
    County: "",
    Country: "Australia",
  },
  3156: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Ruddell",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  3157: {
    Title: "Cdt",
    Initials: "ME",
    Surname: "Lecavalier",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3158: {
    Title: "Cdt",
    Initials: "ML",
    Surname: "Laflamme",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3159: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Lanigan",
    "Primary Club": "Old Tonbridgian",
    County: "Kent",
    Country: "Ireland",
  },
  3160: {
    Title: "Cpl",
    Initials: "JI",
    Surname: "Phomboro",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3161: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Clement",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3162: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Kleynhans",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  3163: {
    Title: "Mr",
    Initials: "H",
    Surname: "Kruger",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3164: {
    Title: "Mr",
    Initials: "MK",
    Surname: "Knackstedt",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3165: {
    Title: "Mr",
    Initials: "D",
    Surname: "Brough",
    "Primary Club": "CNRC",
    County: "",
    Country: "England",
  },
  3166: {
    Title: "Mr",
    Initials: "C",
    Surname: "Clifford",
    "Primary Club": "Fassaroe",
    County: "",
    Country: "Ireland",
  },
  3167: {
    Title: "Mr",
    Initials: "PB",
    Surname: "Vamplew",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3168: {
    Title: "Cdt Sgt",
    Initials: "WH",
    Surname: "Hall",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  3169: {
    Title: "Mr",
    Initials: "TAD",
    Surname: "Lilley",
    "Primary Club": "SRA",
    County: "Hampshire",
    Country: "England",
  },
  3170: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Corfield",
    "Primary Club": "LMRA",
    County: "Middlesex",
    Country: "Wales",
  },
  3171: {
    Title: "Cdt",
    Initials: "BB",
    Surname: "Baker",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3172: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Audet-Hall",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3173: {
    Title: "Miss",
    Initials: "D",
    Surname: "O'Kelly",
    "Primary Club": "Irish Rifle Club",
    County: "Gloucestershire",
    Country: "Ireland",
  },
  3174: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Enger",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3175: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Comeau",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3176: {
    Title: "Mr",
    Initials: "P",
    Surname: "Rutherford",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3177: {
    Title: "Mr",
    Initials: "WA",
    Surname: "Odhiambo",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3178: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Lekalaile",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3179: {
    Title: "Cpl",
    Initials: "JB",
    Surname: "Kipkorir",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3180: {
    Title: "Mr",
    Initials: "U",
    Surname: "Solzer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3181: {
    Title: "Cdt Cpl",
    Initials: "MM",
    Surname: "Wright",
    "Primary Club": "Bedford Mod Schl",
    County: "Bedfordshire",
    Country: "England",
  },
  3182: {
    Title: "Cdt Cpl",
    Initials: "C",
    Surname: "Ridley",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  3183: {
    Title: "Cdt",
    Initials: "X",
    Surname: "Wang",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3184: {
    Title: "WPC",
    Initials: "C",
    Surname: "Jerop",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3186: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Dodds",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  3188: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Tremblett",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3190: {
    Title: "Mr",
    Initials: "J",
    Surname: "McKellar",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3191: {
    Title: "Cpl",
    Initials: "W",
    Surname: "Kerema",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3192: {
    Title: "PC",
    Initials: "J",
    Surname: "Ngari",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3193: {
    Title: "Mr",
    Initials: "EFG",
    Surname: "Voisin",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  3194: {
    Title: "Mr",
    Initials: "K",
    Surname: "Feustel",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3195: {
    Title: "Mr",
    Initials: "ATH",
    Surname: "Hodge",
    "Primary Club": "Bedford Mod Schl",
    County: "Bedfordshire",
    Country: "England",
  },
  3196: {
    Title: "Mr",
    Initials: "PW",
    Surname: "Hornsby",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3197: {
    Title: "Cdt",
    Initials: "KJ",
    Surname: "Montagnese",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3200: {
    Title: "",
    Initials: "N",
    Surname: "Bonaya",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3201: {
    Title: "J/Cpl",
    Initials: "TMJ",
    Surname: "Burgess",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "England",
  },
  3202: {
    Title: "Mr",
    Initials: "GEH",
    Surname: "Pilkington-Oates",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3203: {
    Title: "Cdt",
    Initials: "CC",
    Surname: "Congram",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3204: {
    Title: "",
    Initials: "E",
    Surname: "Stolk",
    "Primary Club": "KSV Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  3206: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Belyea",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3207: {
    Title: "",
    Initials: "SA",
    Surname: "Illsley",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3208: {
    Title: "Miss",
    Initials: "HC",
    Surname: "Born",
    "Primary Club": "Exeter University RC",
    County: "Devon",
    Country: "England",
  },
  3209: {
    Title: "Mr",
    Initials: "T",
    Surname: "Simpson",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3210: {
    Title: "Mrs",
    Initials: "MJ",
    Surname: "Van Strien-Buitelaar",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  3211: {
    Title: "Cdt",
    Initials: "TJ",
    Surname: "Walkey",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3212: {
    Title: "Mr",
    Initials: "JRO",
    Surname: "Boyd",
    "Primary Club": "Surrey RA",
    County: "Gloucestershire",
    Country: "",
  },
  3213: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Capron",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3214: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Meyers",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3215: {
    Title: "",
    Initials: "FK",
    Surname: "Kinyua",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3216: {
    Title: "Sgt",
    Initials: "SDJ",
    Surname: "Glanville",
    "Primary Club": "RAF Air Cmd",
    County: "Hampshire",
    Country: "England",
  },
  3217: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Belanger",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3218: {
    Title: "Spr",
    Initials: "RA",
    Surname: "Lote",
    "Primary Club": "ATRC",
    County: "",
    Country: "England",
  },
  3219: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Beland",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3221: {
    Title: "Cdt",
    Initials: "JAH",
    Surname: "Le Maistre",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  3222: {
    Title: "2LT",
    Initials: "PA",
    Surname: "Finn",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  3223: {
    Title: "C/Sgt",
    Initials: "PR",
    Surname: "Moore",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  3224: {
    Title: "Mr",
    Initials: "OJ",
    Surname: "Neill",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  3225: {
    Title: "Mr",
    Initials: "A",
    Surname: "Seifried",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3226: {
    Title: "Mr",
    Initials: "K",
    Surname: "Tumschat",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3227: {
    Title: "Cdt",
    Initials: "HFM",
    Surname: "Hemsley",
    "Primary Club": "Wellington College",
    County: "Somerset",
    Country: "England",
  },
  3228: {
    Title: "Cdt",
    Initials: "NE",
    Surname: "Johnson",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "",
  },
  3229: {
    Title: "L/Cpl",
    Initials: "AJM",
    Surname: "Maher",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3230: {
    Title: "Capt",
    Initials: "MJ",
    Surname: "Purdy",
    "Primary Club": "ATSC",
    County: "Norfolk",
    Country: "England",
  },
  3231: {
    Title: "Mr",
    Initials: "TEO",
    Surname: "Richards",
    "Primary Club": "Hampshire RA",
    County: "Hampshire",
    Country: "England",
  },
  3234: {
    Title: "Cdt Bdr",
    Initials: "RJM",
    Surname: "McIlveen",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  3235: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Wigmore",
    "Primary Club": "Athelings",
    County: "Norfolk",
    Country: "England",
  },
  3236: {
    Title: "Cdt",
    Initials: "GWD",
    Surname: "Davies",
    "Primary Club": "GB U25",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3237: {
    Title: "Mr",
    Initials: "G",
    Surname: "Wright",
    "Primary Club": "Harrovian RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3238: {
    Title: "Cdt Sgt",
    Initials: "NR",
    Surname: "Rewston",
    "Primary Club": "Notts ACF",
    County: "Nottinghamshire",
    Country: "England",
  },
  3239: {
    Title: "CSgt",
    Initials: "JWP",
    Surname: "Taylor",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3240: {
    Title: "Mr",
    Initials: "M",
    Surname: "Fox",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3241: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "McIntosh",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "Australia",
  },
  3242: {
    Title: "Cdt",
    Initials: "V",
    Surname: "Wanie",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3243: {
    Title: "PC",
    Initials: "CJ",
    Surname: "Enyatta",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3244: {
    Title: "Mr",
    Initials: "GD",
    Surname: "Orchard",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  3245: {
    Title: "Cdt",
    Initials: "MJL",
    Surname: "Lidgitt",
    "Primary Club": "Durham University",
    County: "Gloucestershire",
    Country: "England",
  },
  3246: {
    Title: "Cdt C/Sgt",
    Initials: "IJ",
    Surname: "Ruggles-Brise",
    "Primary Club": "Harrow School CCF",
    County: "Middlesex",
    Country: "England",
  },
  3247: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Tong",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "Hong Kong",
  },
  3248: {
    Title: "POC",
    Initials: "AJP",
    Surname: "Lamb",
    "Primary Club": "Sea Cadets",
    County: "Cornwall",
    Country: "England",
  },
  3250: {
    Title: "Cdt",
    Initials: "TJL",
    Surname: "Garton",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  3251: {
    Title: "Mr",
    Initials: "JT",
    Surname: "Boyd",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  3252: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Allan",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  3253: {
    Title: "Cpl",
    Initials: "P",
    Surname: "Lepario",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3254: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Briggs",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  3255: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Munzel",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3256: {
    Title: "Mr",
    Initials: "T",
    Surname: "Roxborough",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3257: {
    Title: "Mr",
    Initials: "H",
    Surname: "Buitelaar",
    "Primary Club": "IRC Holland",
    County: "",
    Country: "Netherlands",
  },
  3258: {
    Title: "Lt",
    Initials: "MA",
    Surname: "Veti",
    "Primary Club": "RNTRC",
    County: "Hampshire",
    Country: "England",
  },
  3259: {
    Title: "F/Sgt",
    Initials: "S",
    Surname: "Leonard",
    "Primary Club": "Durham/Northumberland ATC",
    County: "County Durham",
    Country: "England",
  },
  3260: {
    Title: "Cdt",
    Initials: "AP",
    Surname: "Lacombe",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3261: {
    Title: "Mr",
    Initials: "DMN",
    Surname: "Newman",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3262: {
    Title: "Mr",
    Initials: "FCP",
    Surname: "Mostyn",
    "Primary Club": "Birmingham University RC",
    County: "Buckinghamshire",
    Country: "England",
  },
  3263: {
    Title: "L Cpl",
    Initials: "SL",
    Surname: "Ridley",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3264: {
    Title: "Mr",
    Initials: "AED",
    Surname: "Webster",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3265: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Inwood",
    "Primary Club": "OCRC",
    County: "Berkshire",
    Country: "England",
  },
  3266: {
    Title: "Mr",
    Initials: "P",
    Surname: "Stanley",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  3267: {
    Title: "Cdt C/Sgt",
    Initials: "BH",
    Surname: "Tomkins",
    "Primary Club": "Harrow School CCF",
    County: "Middlesex",
    Country: "England",
  },
  3268: {
    Title: "Mr",
    Initials: "EC",
    Surname: "Farries",
    "Primary Club": "Exeter School CCF",
    County: "Devon",
    Country: "England",
  },
  3270: {
    Title: "Cdt",
    Initials: "AM",
    Surname: "Winchester",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  3271: {
    Title: "POMEM(M)",
    Initials: "AJ",
    Surname: "Heywood",
    "Primary Club": "RNTRC",
    County: "",
    Country: "",
  },
  3272: {
    Title: "Mr",
    Initials: "S",
    Surname: "De Gruchy",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3273: {
    Title: "Cdt Cpl",
    Initials: "RJ",
    Surname: "Stilgoe",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  3274: {
    Title: "Miss",
    Initials: "K",
    Surname: "Uphill",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  3275: {
    Title: "LC",
    Initials: "L",
    Surname: "Main",
    "Primary Club": "Sea Cadets",
    County: "Gwent",
    Country: "England",
  },
  3276: {
    Title: "Mr",
    Initials: "R",
    Surname: "Deane",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3277: {
    Title: "Mr",
    Initials: "TO",
    Surname: "Overbury",
    "Primary Club": "Cheltenham College CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  3278: {
    Title: "Mr",
    Initials: "OC",
    Surname: "Shave",
    "Primary Club": "CURA",
    County: "",
    Country: "",
  },
  3279: {
    Title: "Cdt Cpl",
    Initials: "CD",
    Surname: "Paluch",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3280: {
    Title: "Mr",
    Initials: "GC",
    Surname: "Strong",
    "Primary Club": "Durham University",
    County: "Norfolk",
    Country: "England",
  },
  3281: {
    Title: "Mr",
    Initials: "AK",
    Surname: "Daneshmend",
    "Primary Club": "Exeter School CCF",
    County: "Devon",
    Country: "",
  },
  3282: {
    Title: "Cdt",
    Initials: "AL",
    Surname: "Pimblett",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "",
  },
  3283: {
    Title: "Mr",
    Initials: "AWJ",
    Surname: "Logan",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "Scotland",
  },
  3284: {
    Title: "Mr",
    Initials: "RK",
    Surname: "Berghus",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3285: {
    Title: "Cpl",
    Initials: "GDW",
    Surname: "Weir",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3286: {
    Title: "",
    Initials: "MJC",
    Surname: "Hamill",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3287: {
    Title: "Mr",
    Initials: "G",
    Surname: "Hart",
    "Primary Club": "Bedfordians RC",
    County: "Norfolk",
    Country: "England",
  },
  3288: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Quinn",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3289: {
    Title: "Mr",
    Initials: "D",
    Surname: "Couch",
    "Primary Club": "Cornwall RC",
    County: "Cornwall",
    Country: "England",
  },
  3291: {
    Title: "Cdt",
    Initials: "HJH",
    Surname: "Hudson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Rutland",
    Country: "England",
  },
  3292: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Hillman",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  3293: {
    Title: "Miss",
    Initials: "CML",
    Surname: "Mackintosh",
    "Primary Club": "St Andrews University",
    County: "Surrey",
    Country: "Scotland",
  },
  3294: {
    Title: "Mr",
    Initials: "MT",
    Surname: "Phipps",
    "Primary Club": "Exeter University RC",
    County: "Berkshire",
    Country: "England",
  },
  3295: {
    Title: "Mr",
    Initials: "AJK",
    Surname: "Kelly",
    "Primary Club": "Wellingborough",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  3296: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Wort",
    "Primary Club": "Dorking & District RC",
    County: "",
    Country: "",
  },
  3297: {
    Title: "Cpl",
    Initials: "R",
    Surname: "Cook",
    "Primary Club": "Bunhill Rifle Club",
    County: "County of London",
    Country: "England",
  },
  3298: {
    Title: "LC",
    Initials: "S",
    Surname: "Pinches",
    "Primary Club": "Nuneaton Sea Cadets",
    County: "",
    Country: "",
  },
  3299: {
    Title: "WO1",
    Initials: "JR",
    Surname: "Davies",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "Wales",
  },
  3300: {
    Title: "Mr",
    Initials: "NC",
    Surname: "Bartholomew",
    "Primary Club": "NRA",
    County: "Suffolk",
    Country: "England",
  },
  3301: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Atkins",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3302: {
    Title: "Mr",
    Initials: "WJ",
    Surname: "Myburgh",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  3303: {
    Title: "Mr",
    Initials: "R",
    Surname: "Johnson",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  3305: {
    Title: "Mr",
    Initials: "N",
    Surname: "Beesley",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "Scotland",
  },
  3306: {
    Title: "Sqn Ldr",
    Initials: "RC",
    Surname: "Andrews",
    "Primary Club": "",
    County: "",
    Country: "New Zealand",
  },
  3307: {
    Title: "Mr",
    Initials: "C",
    Surname: "Russell",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3308: {
    Title: "Mr",
    Initials: "A",
    Surname: "Tikkanen",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3309: {
    Title: "Mr",
    Initials: "PJS",
    Surname: "Smith",
    "Primary Club": "St Nicholas RPC",
    County: "Kent",
    Country: "England",
  },
  3310: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Baxter",
    "Primary Club": "NSWRA",
    County: "",
    Country: "Australia",
  },
  3312: {
    Title: "Mr",
    Initials: "D",
    Surname: "de Vere",
    "Primary Club": "Old Marlburian RC",
    County: "London",
    Country: "England",
  },
  3313: {
    Title: "Mr",
    Initials: "R",
    Surname: "McIlveen",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  3314: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Benest",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3315: {
    Title: "Mr",
    Initials: "C",
    Surname: "Waldron",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3316: {
    Title: "Miss",
    Initials: "NR",
    Surname: "Rothwell",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  3317: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Hohenberg",
    "Primary Club": "Oratory School",
    County: "",
    Country: "",
  },
  3318: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Mitchell",
    "Primary Club": "Cardinals",
    County: "Berkshire",
    Country: "England",
  },
  3319: {
    Title: "Mr",
    Initials: "J",
    Surname: "Maguire",
    "Primary Club": "West Wallsend RC",
    County: "",
    Country: "Australia",
  },
  3320: {
    Title: "Mr",
    Initials: "G",
    Surname: "Bendik",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3321: {
    Title: "Mr",
    Initials: "K",
    Surname: "McCamley",
    "Primary Club": "Surrey RA",
    County: "",
    Country: "Australia",
  },
  3322: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Waters",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  3323: {
    Title: "Miss",
    Initials: "GJF",
    Surname: "Ogden",
    "Primary Club": "Yorkshire RA",
    County: "Yorkshire",
    Country: "England",
  },
  3325: {
    Title: "Mr",
    Initials: "IW",
    Surname: "Monroe",
    "Primary Club": "Durham University",
    County: "County Durham",
    Country: "England",
  },
  3326: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Wallington",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3327: {
    Title: "Mr",
    Initials: "D",
    Surname: "Ferdinand",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3328: {
    Title: "Mr",
    Initials: "PB",
    Surname: "Cutts",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  3329: {
    Title: "Miss",
    Initials: "SE",
    Surname: "Lawrence",
    "Primary Club": "Malvern College",
    County: "Worcestershire",
    Country: "England",
  },
  3331: {
    Title: "WO2",
    Initials: "DN",
    Surname: "Budden",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3332: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Wier",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3333: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Newman",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3334: {
    Title: "Mr",
    Initials: "FJT",
    Surname: "Puckle Hobbs",
    "Primary Club": "Athelings",
    County: "Wiltshire",
    Country: "England",
  },
  3335: {
    Title: "Cdt Sgt",
    Initials: "J",
    Surname: "Saville",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "",
  },
  3336: {
    Title: "Capt",
    Initials: "H",
    Surname: "Bennett",
    "Primary Club": "ATSC",
    County: "Norfolk",
    Country: "England",
  },
  3338: {
    Title: "Cdt",
    Initials: "MJP",
    Surname: "Purdy",
    "Primary Club": "Gresham's RC",
    County: "Norfolk",
    Country: "England",
  },
  3339: {
    Title: "Cdt",
    Initials: "HZ",
    Surname: "Taylor",
    "Primary Club": "Gresham's RC",
    County: "Norfolk",
    Country: "England",
  },
  3341: {
    Title: "Mr",
    Initials: "M",
    Surname: "Neal",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3342: {
    Title: "Mr",
    Initials: "COB",
    Surname: "Perkins",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "",
  },
  3343: {
    Title: "Mr",
    Initials: "GJM",
    Surname: "Maio",
    "Primary Club": "University of Reading",
    County: "Berkshire",
    Country: "England",
  },
  3345: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Horn",
    "Primary Club": "Reading University",
    County: "Berkshire",
    Country: "England",
  },
  3346: {
    Title: "Miss",
    Initials: "JJ",
    Surname: "Carson",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  3348: {
    Title: "Mr",
    Initials: "AD",
    Surname: "McDougall",
    "Primary Club": "North Sydney",
    County: "",
    Country: "Australia",
  },
  3349: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "McGuigan",
    "Primary Club": "NSWRA",
    County: "",
    Country: "Australia",
  },
  3350: {
    Title: "Mr",
    Initials: "C",
    Surname: "Rivett-Carnac",
    "Primary Club": "Old Epsomian RC",
    County: "Gloucestershire",
    Country: "England",
  },
  3351: {
    Title: "Mr",
    Initials: "PRK",
    Surname: "Kenrick",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  3352: {
    Title: "CDT",
    Initials: "CE",
    Surname: "Hearsum",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  3354: {
    Title: "Mr",
    Initials: "DGEM",
    Surname: "Mutter",
    "Primary Club": "Darlington R & PC",
    County: "County Durham",
    Country: "England",
  },
  3355: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Griffin",
    "Primary Club": "University of London",
    County: "Buckinghamshire",
    Country: "",
  },
  3356: {
    Title: "Mrs",
    Initials: "BA",
    Surname: "Bell",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  3357: {
    Title: "Ms",
    Initials: "RL",
    Surname: "Furniss",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3358: {
    Title: "Mr",
    Initials: "SRJ",
    Surname: "Joshi",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3359: {
    Title: "Cdt",
    Initials: "OS",
    Surname: "Stewart",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3362: {
    Title: "Cdt",
    Initials: "NM",
    Surname: "Taylor",
    "Primary Club": "Athelings",
    County: "Somerset",
    Country: "England",
  },
  3363: {
    Title: "Mr",
    Initials: "T",
    Surname: "Bulmer",
    "Primary Club": "Athelings",
    County: "Somerset",
    Country: "England",
  },
  3364: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Salmon",
    "Primary Club": "Bedford Mod Schl",
    County: "Bedfordshire",
    Country: "England",
  },
  3365: {
    Title: "Mr",
    Initials: "JG",
    Surname: "Glen",
    "Primary Club": "Old Glenalmond RC",
    County: "Perthshire",
    Country: "Scotland",
  },
  3367: {
    Title: "Mr",
    Initials: "C",
    Surname: "Hildebrandt",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3368: {
    Title: "Mr",
    Initials: "M",
    Surname: "Knackstedt",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3369: {
    Title: "Miss",
    Initials: "C",
    Surname: "Lemmer",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  3370: {
    Title: "Mr",
    Initials: "B",
    Surname: "Anscombe",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3371: {
    Title: "Mr",
    Initials: "G",
    Surname: "Drewett",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3374: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Du Toit",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  3375: {
    Title: "Mr",
    Initials: "FW",
    Surname: "Viljoen",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  3376: {
    Title: "Cdt",
    Initials: "TFF",
    Surname: "Smart",
    "Primary Club": "Cheltenham College",
    County: "Gloucestershire",
    Country: "",
  },
  3378: {
    Title: "Mrs",
    Initials: "L",
    Surname: "Healy",
    "Primary Club": "ATSC",
    County: "",
    Country: "Jersey",
  },
  3379: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Overbury",
    "Primary Club": "Cheltenham College",
    County: "Gloucestershire",
    Country: "England",
  },
  3380: {
    Title: "Mrs",
    Initials: "LF",
    Surname: "Andrews",
    "Primary Club": "Club 25",
    County: "Hampshire",
    Country: "England",
  },
  3381: {
    Title: "Mr",
    Initials: "AM",
    Surname: "West",
    "Primary Club": "LMRA",
    County: "Surrey",
    Country: "England",
  },
  3382: {
    Title: "Mr",
    Initials: "A",
    Surname: "Godliman",
    "Primary Club": "OWRC",
    County: "Berkshire",
    Country: "",
  },
  3383: {
    Title: "Cfn",
    Initials: "S",
    Surname: "Kirkcaldy",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "",
  },
  3385: {
    Title: "Mr",
    Initials: "BN",
    Surname: "Craig",
    "Primary Club": "OCRA",
    County: "Cheshire",
    Country: "England",
  },
  3386: {
    Title: "Mr",
    Initials: "JMP",
    Surname: "Fitzsimons",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  3387: {
    Title: "Mr",
    Initials: "GP",
    Surname: "Perron",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3388: {
    Title: "Mr",
    Initials: "PT",
    Surname: "Dwyer",
    "Primary Club": "Kent CRA",
    County: "Kent",
    Country: "England",
  },
  3389: {
    Title: "Miss",
    Initials: "TAM",
    Surname: "Hutton",
    "Primary Club": "2452 ATC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3390: {
    Title: "Mr",
    Initials: "LJ",
    Surname: "Downie",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3391: {
    Title: "LT",
    Initials: "R",
    Surname: "Whalen",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  3392: {
    Title: "WO2",
    Initials: "M",
    Surname: "Carberry",
    "Primary Club": "ATSC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3394: {
    Title: "Miss",
    Initials: "CMA",
    Surname: "O'Donovan",
    "Primary Club": "Durham University",
    County: "Surrey",
    Country: "England",
  },
  3395: {
    Title: "Sjt",
    Initials: "M",
    Surname: "House",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  3396: {
    Title: "Miss",
    Initials: "HL",
    Surname: "Landrum",
    "Primary Club": "Dorset ACF",
    County: "Lancashire",
    Country: "England",
  },
  3397: {
    Title: "Cdt",
    Initials: "GA",
    Surname: "Hopkinson",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Scotland",
  },
  3398: {
    Title: "Mr",
    Initials: "TE",
    Surname: "Hirst",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  3399: {
    Title: "Cdt",
    Initials: "JE",
    Surname: "McGuire",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  3401: {
    Title: "Mr",
    Initials: "ACH",
    Surname: "Bryson",
    "Primary Club": "OWRC",
    County: "Surrey",
    Country: "England",
  },
  3402: {
    Title: "Mr",
    Initials: "HW",
    Surname: "Orpen-Smellie",
    "Primary Club": "Old Wellingtonians",
    County: "Essex",
    Country: "England",
  },
  3403: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Idris",
    "Primary Club": "Wellington College",
    County: "",
    Country: "Malaysia",
  },
  3404: {
    Title: "Mr",
    Initials: "J",
    Surname: "Walsby-Tickle",
    "Primary Club": "Birkenhead School CCF",
    County: "Cheshire",
    Country: "England",
  },
  3405: {
    Title: "Sgt",
    Initials: "DG",
    Surname: "Slaughter",
    "Primary Club": "Bunhill Rifle Club",
    County: "Middlesex",
    Country: "Ireland",
  },
  3406: {
    Title: "Mr",
    Initials: "F",
    Surname: "Zeresenai",
    "Primary Club": "Bunhill Rifle Club",
    County: "Middlesex",
    Country: "",
  },
  3407: {
    Title: "Mr",
    Initials: "JSW",
    Surname: "Hayman",
    "Primary Club": "Bunhill Rifle Club",
    County: "Middlesex",
    Country: "England",
  },
  3408: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Congram",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3409: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Jmyi",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3410: {
    Title: "Cdt",
    Initials: "MRS",
    Surname: "Badmington",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3411: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Morrison",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3412: {
    Title: "Cdt",
    Initials: "DG",
    Surname: "Galati",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3413: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Edge",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3414: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Dickenson",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3415: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Desilets",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3416: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Churchill",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3417: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Brisebois",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3418: {
    Title: "Cdt",
    Initials: "DR",
    Surname: "Benterud",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3419: {
    Title: "Cdt",
    Initials: "CM",
    Surname: "MacDonald",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3420: {
    Title: "Cdt",
    Initials: "KL",
    Surname: "LeBlanc",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3421: {
    Title: "Cdt",
    Initials: "EL",
    Surname: "Lafleur",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3422: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Jones",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3423: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Poulin",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3424: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Stuckless",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3425: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Taylor",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3426: {
    Title: "Mr",
    Initials: "CT",
    Surname: "Leyden",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  3427: {
    Title: "Miss",
    Initials: "LAE",
    Surname: "Crowson",
    "Primary Club": "Old Sedberghians",
    County: "Yorkshire",
    Country: "England",
  },
  3428: {
    Title: "Mr",
    Initials: "SS",
    Surname: "Qaisar",
    "Primary Club": "Durham University",
    County: "Cheshire",
    Country: "Scotland",
  },
  3429: {
    Title: "Cdt",
    Initials: "CR",
    Surname: "McCall",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  3430: {
    Title: "Monsieur",
    Initials: "B",
    Surname: "Berthineau",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  3431: {
    Title: "Sgt",
    Initials: "MD",
    Surname: "Argo",
    "Primary Club": "2452 Shawlands ATC",
    County: "",
    Country: "Scotland",
  },
  3432: {
    Title: "Mrs",
    Initials: "MF",
    Surname: "Shepherd",
    "Primary Club": "Endeavour RC",
    County: "",
    Country: "Australia",
  },
  3434: {
    Title: "Mr",
    Initials: "JEC",
    Surname: "Branch",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3435: {
    Title: "Miss",
    Initials: "JYSL",
    Surname: "Lau",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  3436: {
    Title: "Ms",
    Initials: "RM",
    Surname: "Shepherd",
    "Primary Club": "Endeavour RC",
    County: "",
    Country: "Australia",
  },
  3437: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Skellett",
    "Primary Club": "Welwyn Phoenix",
    County: "Hertfordshire",
    Country: "England",
  },
  3438: {
    Title: "Mr",
    Initials: "NDJ",
    Surname: "Gaywood",
    "Primary Club": "NRC of Scotland",
    County: "West of Scotland",
    Country: "England",
  },
  3439: {
    Title: "Miss",
    Initials: "JM",
    Surname: "Waters",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3440: {
    Title: "Mrs",
    Initials: "AM",
    Surname: "Tracey",
    "Primary Club": "LMRA",
    County: "",
    Country: "",
  },
  3441: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Peebles",
    "Primary Club": "ARA",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3442: {
    Title: "Mr",
    Initials: "SG",
    Surname: "Turner",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  3443: {
    Title: "Mr",
    Initials: "M",
    Surname: "Rewston",
    "Primary Club": "Huddersfield RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  3444: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Lindsay III",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3445: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Hemingway",
    "Primary Club": "University of Southampton",
    County: "Hampshire",
    Country: "England",
  },
  3446: {
    Title: "Miss",
    Initials: "SJ",
    Surname: "Hunter",
    "Primary Club": "University of Southampton",
    County: "Yorkshire",
    Country: "England",
  },
  3447: {
    Title: "Mr",
    Initials: "DT",
    Surname: "Foy",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  3448: {
    Title: "Mr",
    Initials: "TME",
    Surname: "Ince",
    "Primary Club": "Birkenhead School CCF",
    County: "Cheshire",
    Country: "England",
  },
  3449: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Wells",
    "Primary Club": "",
    County: "Surrey",
    Country: "England",
  },
  3450: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Hyndman",
    "Primary Club": "Old Guildfordians RC",
    County: "County of London",
    Country: "Northern Ireland",
  },
  3451: {
    Title: "",
    Initials: "JD",
    Surname: "Van Oppen",
    "Primary Club": "RGS Guildford",
    County: "Hampshire",
    Country: "England",
  },
  3452: {
    Title: "Mr",
    Initials: "AJW",
    Surname: "Erskine",
    "Primary Club": "Surrey RA",
    County: "Ulster",
    Country: "Ireland",
  },
  3453: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Baker",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3454: {
    Title: "Sjt",
    Initials: "BG",
    Surname: "Mulvey",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3455: {
    Title: "Bdr",
    Initials: "K",
    Surname: "Towers",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3456: {
    Title: "Miss",
    Initials: "H",
    Surname: "Kent",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3457: {
    Title: "Mr",
    Initials: "D",
    Surname: "Odutola",
    "Primary Club": "Trinidad RA",
    County: "Gloucestershire",
    Country: "",
  },
  3458: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Lau",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  3459: {
    Title: "Mr",
    Initials: "GCRG",
    Surname: "Greenbury",
    "Primary Club": "Cambridge University RA",
    County: "Gloucestershire",
    Country: "Wales",
  },
  3460: {
    Title: "Mr",
    Initials: "T",
    Surname: "Waycott",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  3461: {
    Title: "Cpl",
    Initials: "D",
    Surname: "Slone",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3462: {
    Title: "Sgt",
    Initials: "AP",
    Surname: "Mitchinson",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3463: {
    Title: "Sgt",
    Initials: "K",
    Surname: "Waterston",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3464: {
    Title: "Sgt",
    Initials: "AP",
    Surname: "Barton",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3465: {
    Title: "Mr",
    Initials: "U",
    Surname: "Adendorff",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  3466: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Brenchley",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  3467: {
    Title: "Ms",
    Initials: "M",
    Surname: "Ilse",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  3468: {
    Title: "Mr",
    Initials: "SF",
    Surname: "Scheepers",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  3469: {
    Title: "Mr",
    Initials: "SFC",
    Surname: "Churchill",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  3470: {
    Title: "Mr",
    Initials: "S",
    Surname: "Burford",
    "Primary Club": "Cinque Ports",
    County: "Sussex",
    Country: "England",
  },
  3471: {
    Title: "Mr",
    Initials: "D",
    Surname: "Marsden",
    "Primary Club": "Geraldton RC",
    County: "",
    Country: "Australia",
  },
  3472: {
    Title: "Mr",
    Initials: "HH",
    Surname: "Prinsloo",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  3473: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Slabbert",
    "Primary Club": "North West",
    County: "",
    Country: "South Africa",
  },
  3474: {
    Title: "Mr",
    Initials: "PW",
    Surname: "Coen",
    "Primary Club": "North West",
    County: "",
    Country: "South Africa",
  },
  3475: {
    Title: "Mnr",
    Initials: "PJ",
    Surname: "Slabbert",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  3476: {
    Title: "Mr",
    Initials: "O",
    Surname: "Slabbert",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  3477: {
    Title: "Mr",
    Initials: "PP",
    Surname: "Prinsloo",
    "Primary Club": "South African U25",
    County: "",
    Country: "South Africa",
  },
  3478: {
    Title: "Mr",
    Initials: "DB",
    Surname: "Cotterill",
    "Primary Club": "Bungendore",
    County: "",
    Country: "Australia",
  },
  3482: {
    Title: "Mr",
    Initials: "N",
    Surname: "Maynard",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3485: {
    Title: "Mr",
    Initials: "SJD",
    Surname: "Durrant",
    "Primary Club": "Bunhill Rifle Club",
    County: "Essex",
    Country: "England",
  },
  3486: {
    Title: "Mr",
    Initials: "WJD",
    Surname: "Broad",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  3487: {
    Title: "Miss",
    Initials: "L",
    Surname: "Colley",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  3488: {
    Title: "Cdt",
    Initials: "JMS",
    Surname: "Brind",
    "Primary Club": "Wellington College",
    County: "Wiltshire",
    Country: "England",
  },
  3489: {
    Title: "Mr",
    Initials: "KDA",
    Surname: "Pilcher",
    "Primary Club": "Bunhill Rifle Club",
    County: "Cambridgeshire",
    Country: "England",
  },
  3490: {
    Title: "Mr",
    Initials: "CBC",
    Surname: "Brook",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  3491: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Ritchie",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "",
  },
  3493: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Smith",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3494: {
    Title: "Mr",
    Initials: "HDG",
    Surname: "Hyslop",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3495: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Creber",
    "Primary Club": "Guernsey RC",
    County: "",
    Country: "Guernsey",
  },
  3496: {
    Title: "Miss",
    Initials: "C A",
    Surname: "Staples",
    "Primary Club": "Bunhill Rifle Club",
    County: "Hampshire",
    Country: "England",
  },
  3497: {
    Title: "Mr",
    Initials: "N",
    Surname: "Pinks",
    "Primary Club": "NLRC",
    County: "Norfolk",
    Country: "England",
  },
  3498: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Mandella",
    "Primary Club": "Bunhill Rifle Club",
    County: "Gloucestershire",
    Country: "England",
  },
  3499: {
    Title: "Mr",
    Initials: "JST",
    Surname: "Turley",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "Ireland",
  },
  3501: {
    Title: "Mr",
    Initials: "AL",
    Surname: "James",
    "Primary Club": "Welsh RA",
    County: "",
    Country: "Wales",
  },
  3502: {
    Title: "Miss",
    Initials: "E",
    Surname: "Cohen",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "",
  },
  3503: {
    Title: "Miss",
    Initials: "EL",
    Surname: "Hoolahan",
    "Primary Club": "Metropolitan Police SC",
    County: "Kent",
    Country: "England",
  },
  3504: {
    Title: "Mrs",
    Initials: "FK",
    Surname: "Day",
    "Primary Club": "Hopton Supper Club",
    County: "",
    Country: "England",
  },
  3505: {
    Title: "Mr",
    Initials: "F",
    Surname: "Roberts",
    "Primary Club": "South London RC",
    County: "",
    Country: "",
  },
  3506: {
    Title: "Mr",
    Initials: "C",
    Surname: "Heckstill-Smith",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  3507: {
    Title: "Mr",
    Initials: "M",
    Surname: "McCullough",
    "Primary Club": "Oxford University RC",
    County: "",
    Country: "",
  },
  3508: {
    Title: "Mr",
    Initials: "BRF",
    Surname: "Perkins",
    "Primary Club": "Oxford University RC",
    County: "Hampshire",
    Country: "England",
  },
  3509: {
    Title: "Cpl",
    Initials: "D",
    Surname: "Smith",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  3510: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Smith",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  3511: {
    Title: "Sqn Ldr",
    Initials: "HM",
    Surname: "French",
    "Primary Club": "RAFTRC",
    County: "Kent",
    Country: "England",
  },
  3512: {
    Title: "Cpl",
    Initials: "P",
    Surname: "Prys-Roberts",
    "Primary Club": "Bristol Filton Sea Cadets",
    County: "Avon",
    Country: "",
  },
  3513: {
    Title: "Bdr",
    Initials: "K",
    Surname: "Butler",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  3514: {
    Title: "Flt Lt",
    Initials: "MR",
    Surname: "Leyman",
    "Primary Club": "RAF Air Cmd",
    County: "Devon",
    Country: "England",
  },
  3515: {
    Title: "L/Cpl",
    Initials: "RV",
    Surname: "Stirling",
    "Primary Club": "Rugby Sea Cadets",
    County: "",
    Country: "",
  },
  3516: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Newton",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  3518: {
    Title: "Mr",
    Initials: "ZH",
    Surname: "Hamsan",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  3519: {
    Title: "Mr",
    Initials: "ZA",
    Surname: "Zain",
    "Primary Club": "Malaysia Rifle Team",
    County: "",
    Country: "Malaysia",
  },
  3520: {
    Title: "Mr",
    Initials: "M",
    Surname: "Ford",
    "Primary Club": "Old Cheltonians",
    County: "Gloucestershire",
    Country: "England",
  },
  3521: {
    Title: "Mr",
    Initials: "JHL",
    Surname: "Longworth",
    "Primary Club": "St Johns School",
    County: "Somerset",
    Country: "England",
  },
  3522: {
    Title: "Mr",
    Initials: "ADJ",
    Surname: "Stewart",
    "Primary Club": "ATSC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3523: {
    Title: "Cdt",
    Initials: "MSM",
    Surname: "Clayton",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3524: {
    Title: "Mr",
    Initials: "JPS",
    Surname: "Scarr",
    "Primary Club": "ATSC",
    County: "",
    Country: "",
  },
  3525: {
    Title: "Mr",
    Initials: "S",
    Surname: "Witorowski",
    "Primary Club": "Ontario RC",
    County: "",
    Country: "Canada",
  },
  3526: {
    Title: "Mr",
    Initials: "G",
    Surname: "Blakeney",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  3527: {
    Title: "POC",
    Initials: "M",
    Surname: "Collis",
    "Primary Club": "SCC Cambridge",
    County: "Cambridgeshire",
    Country: "England",
  },
  3528: {
    Title: "Mr",
    Initials: "JHC",
    Surname: "Bayne",
    "Primary Club": "Room RC",
    County: "Sussex",
    Country: "England",
  },
  3530: {
    Title: "",
    Initials: "ER",
    Surname: "McDowell",
    "Primary Club": "OURC",
    County: "",
    Country: "",
  },
  3532: {
    Title: "Mr",
    Initials: "R",
    Surname: "Sampson",
    "Primary Club": "North Herts Rifles",
    County: "Hertfordshire",
    Country: "England",
  },
  3534: {
    Title: "Miss",
    Initials: "JRA",
    Surname: "Mcintosh",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3535: {
    Title: "Mr",
    Initials: "GL",
    Surname: "Barker",
    "Primary Club": "University of Southampton",
    County: "Devon",
    Country: "England",
  },
  3536: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Coupland",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  3537: {
    Title: "Miss",
    Initials: "IRJD",
    Surname: "Stevenson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Derbyshire",
    Country: "England",
  },
  3538: {
    Title: "Mr",
    Initials: "KZ",
    Surname: "Von Csefalvay-Bartal",
    "Primary Club": "Oxford University RC",
    County: "",
    Country: "",
  },
  3539: {
    Title: "Mr",
    Initials: "BH",
    Surname: "Jones",
    "Primary Club": "Altcar RC",
    County: "",
    Country: "England",
  },
  3540: {
    Title: "Cpl",
    Initials: "SD",
    Surname: "Hutchison",
    "Primary Club": "2452 Shawlands ATC",
    County: "",
    Country: "Scotland",
  },
  3541: {
    Title: "Mr",
    Initials: "A",
    Surname: "Brunton-Reed",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3542: {
    Title: "Miss",
    Initials: "DLT",
    Surname: "Taylor",
    "Primary Club": "Somerset CRA",
    County: "Somerset",
    Country: "England",
  },
  3544: {
    Title: "Mr",
    Initials: "BAAC",
    Surname: "Sandford",
    "Primary Club": "Royal School Armagh",
    County: "Ulster",
    Country: "Ireland",
  },
  3545: {
    Title: "Miss",
    Initials: "KM",
    Surname: "Parks",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Co Tyrone",
    Country: "Ireland",
  },
  3546: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Gaskell",
    "Primary Club": "Durham University",
    County: "",
    Country: "",
  },
  3547: {
    Title: "Mr",
    Initials: "ADO",
    Surname: "Oates",
    "Primary Club": "Reading University",
    County: "Hertfordshire",
    Country: "England",
  },
  3548: {
    Title: "Mr",
    Initials: "BG",
    Surname: "White",
    "Primary Club": "Old Johnian RC",
    County: "Surrey",
    Country: "England",
  },
  3549: {
    Title: "Mr",
    Initials: "AJK",
    Surname: "Knight",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  3550: {
    Title: "Mr",
    Initials: "E",
    Surname: "McConnell",
    "Primary Club": "Edinburgh University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3551: {
    Title: "Mr",
    Initials: "HD",
    Surname: "Worheide",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3552: {
    Title: "Mr",
    Initials: "AM",
    Surname: "Norman",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3553: {
    Title: "Miss",
    Initials: "JA",
    Surname: "McArdle",
    "Primary Club": "Wandsworth FBRC",
    County: "County of London",
    Country: "Ireland",
  },
  3554: {
    Title: "Mr",
    Initials: "ND",
    Surname: "Trewhella",
    "Primary Club": "Wellington College",
    County: "Hampshire",
    Country: "England",
  },
  3555: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Fullerton",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  3559: {
    Title: "Mrs",
    Initials: "VKR",
    Surname: "Duffy",
    "Primary Club": "Metropolitan Police SC",
    County: "Dumfries & Galloway",
    Country: "Scotland",
  },
  3560: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Shaw",
    "Primary Club": "Huddersfield RC",
    County: "Derbyshire",
    Country: "England",
  },
  3561: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Webb",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3562: {
    Title: "Mr",
    Initials: "WA",
    Surname: "Hardy",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "England",
  },
  3564: {
    Title: "",
    Initials: "J",
    Surname: "Glen",
    "Primary Club": "Glenalmond College",
    County: "Perthshire",
    Country: "Scotland",
  },
  3565: {
    Title: "Miss",
    Initials: "GR",
    Surname: "Redman",
    "Primary Club": "2391 Sqn ATC",
    County: "Dorset",
    Country: "Wales",
  },
  3566: {
    Title: "Cpl",
    Initials: "HREH",
    Surname: "Howse",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  3567: {
    Title: "",
    Initials: "B",
    Surname: "Turner",
    "Primary Club": "Malvern College",
    County: "",
    Country: "",
  },
  3568: {
    Title: "Miss",
    Initials: "FE",
    Surname: "Purdy",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  3569: {
    Title: "Mr",
    Initials: "D",
    Surname: "Purcell",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3570: {
    Title: "Mr",
    Initials: "T",
    Surname: "Firth",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3572: {
    Title: "Flt Sgt",
    Initials: "A",
    Surname: "Clark",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3573: {
    Title: "Mr",
    Initials: "DH",
    Surname: "Moss",
    "Primary Club": "New Forest SC",
    County: "Hampshire",
    Country: "England",
  },
  3574: {
    Title: "Mr",
    Initials: "ED",
    Surname: "Carrick",
    "Primary Club": "Glasgow University RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3577: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Paquette",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3578: {
    Title: "Mr",
    Initials: "HJ",
    Surname: "Mitera",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3579: {
    Title: "Mr",
    Initials: "DW",
    Surname: "Wulff",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3581: {
    Title: "Mr",
    Initials: "M",
    Surname: "Liversage",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3582: {
    Title: "Mr",
    Initials: "DK",
    Surname: "Kirui",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3583: {
    Title: "Mr",
    Initials: "HR",
    Surname: "Woryna",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3584: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Dekort",
    "Primary Club": "SV Breda",
    County: "",
    Country: "Netherlands",
  },
  3588: {
    Title: "Mr",
    Initials: "M",
    Surname: "Woolger",
    "Primary Club": "Horsham R&PC",
    County: "Sussex",
    Country: "England",
  },
  3589: {
    Title: "Mr",
    Initials: "MP",
    Surname: "Plug",
    "Primary Club": "F Class Netherlands",
    County: "",
    Country: "Netherlands",
  },
  3591: {
    Title: "Mr",
    Initials: "P",
    Surname: "Sandie",
    "Primary Club": "West Atholl RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3595: {
    Title: "Cdt",
    Initials: "JWB",
    Surname: "Ball",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "Wales",
  },
  3596: {
    Title: "M",
    Initials: "O",
    Surname: "Larrue",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  3597: {
    Title: "Mr",
    Initials: "P",
    Surname: "Valentin",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3598: {
    Title: "Mrs",
    Initials: "N",
    Surname: "Valentin",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3599: {
    Title: "RSM",
    Initials: "GPR",
    Surname: "Hall",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3600: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Sonnenberg",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  3601: {
    Title: "Mr",
    Initials: "NJC",
    Surname: "Thomas",
    "Primary Club": "Civil Service RA",
    County: "Hertfordshire",
    Country: "",
  },
  3602: {
    Title: "Mr",
    Initials: "GD",
    Surname: "Erskine",
    "Primary Club": "NRA",
    County: "Surrey",
    Country: "Ireland",
  },
  3603: {
    Title: "Cdt",
    Initials: "MC",
    Surname: "Evans",
    "Primary Club": "Cheltenham College",
    County: "Gloucestershire",
    Country: "Wales",
  },
  3604: {
    Title: "Cdt",
    Initials: "CE",
    Surname: "McSparron-Edwards",
    "Primary Club": "Cheltenham College",
    County: "Wiltshire",
    Country: "Scotland",
  },
  3605: {
    Title: "Cdt",
    Initials: "LJ",
    Surname: "Craigen",
    "Primary Club": "Cheltenham College",
    County: "",
    Country: "England",
  },
  3606: {
    Title: "Cdt",
    Initials: "MJ",
    Surname: "Strittmatter",
    "Primary Club": "Cheltenham College",
    County: "",
    Country: "Germany",
  },
  3607: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Donoghue",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3608: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Parrott",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3609: {
    Title: "WOII",
    Initials: "K",
    Surname: "Kiarie",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3611: {
    Title: "Cdt",
    Initials: "V",
    Surname: "Carter",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3612: {
    Title: "Mr",
    Initials: "B",
    Surname: "Lottritz",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3613: {
    Title: "WOII",
    Initials: "J",
    Surname: "Mwebia",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3615: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Jarvis",
    "Primary Club": "County of Gloucester RA",
    County: "Gloucestershire",
    Country: "England",
  },
  3616: {
    Title: "Cdt",
    Initials: "O",
    Surname: "Ahmed",
    "Primary Club": "2452 Shawlands ATC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3619: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Ndegwa",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3620: {
    Title: "Miss",
    Initials: "SEA",
    Surname: "Ellis",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  3621: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Bruder",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3622: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Stain",
    "Primary Club": "Notts ACF",
    County: "Nottinghamshire",
    Country: "",
  },
  3623: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Lister",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3624: {
    Title: "Mr",
    Initials: "JE",
    Surname: "Diviney",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3625: {
    Title: "Mr",
    Initials: "F",
    Surname: "Stewart",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3626: {
    Title: "Mr",
    Initials: "FF",
    Surname: "Lalear",
    "Primary Club": "Prairie Provinces",
    County: "",
    Country: "",
  },
  3627: {
    Title: "Cpl",
    Initials: "N",
    Surname: "Tucker",
    "Primary Club": "RGS High Wycombe",
    County: "Buckinghamshire",
    Country: "England",
  },
  3628: {
    Title: "Mr",
    Initials: "O",
    Surname: "Van der Sluis",
    "Primary Club": "IRC Holland",
    County: "Noord-Holland",
    Country: "Netherlands",
  },
  3629: {
    Title: "Mr",
    Initials: "GA",
    Surname: "Buchannon",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3630: {
    Title: "Miss",
    Initials: "NRR",
    Surname: "Rothwell",
    "Primary Club": "Athelings",
    County: "Derbyshire",
    Country: "England",
  },
  3631: {
    Title: "Mr",
    Initials: "JSR",
    Surname: "Rea",
    "Primary Club": "Glasgow University RC",
    County: "East of Scotland",
    Country: "Northern Ireland",
  },
  3632: {
    Title: "Miss",
    Initials: "SC",
    Surname: "Yates",
    "Primary Club": "British Snipers",
    County: "Surrey",
    Country: "England",
  },
  3633: {
    Title: "Cpl",
    Initials: "N",
    Surname: "Elson",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  3634: {
    Title: "Mr",
    Initials: "JE",
    Surname: "Diviney",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3635: {
    Title: "Mr",
    Initials: "CMS",
    Surname: "Duly",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3636: {
    Title: "Mr",
    Initials: "OC",
    Surname: "Collas",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3637: {
    Title: "C/Sgt",
    Initials: "P",
    Surname: "Hunter",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3638: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Le Vaillant",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  3639: {
    Title: "Cpl",
    Initials: "T",
    Surname: "O'Brien",
    "Primary Club": "Plymouth & Cornwall ATC",
    County: "Devon",
    Country: "England",
  },
  3640: {
    Title: "Mr",
    Initials: "S",
    Surname: "Moss",
    "Primary Club": "Reading University",
    County: "Berkshire",
    Country: "England",
  },
  3641: {
    Title: "Miss",
    Initials: "CK",
    Surname: "Skelton",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  3642: {
    Title: "Cpl",
    Initials: "J",
    Surname: "East",
    "Primary Club": "607 (Wearmouth) Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  3644: {
    Title: "Sgt",
    Initials: "SG",
    Surname: "Rose",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  3645: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Osmont",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3647: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Trochimchuk",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3648: {
    Title: "Mr",
    Initials: "A",
    Surname: "Katona",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3649: {
    Title: "Miss",
    Initials: "NJS",
    Surname: "Sarafilovic",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3650: {
    Title: "Mr",
    Initials: "P",
    Surname: "Lucey",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3652: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Pym",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "",
  },
  3653: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Messiaen",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3654: {
    Title: "Mr",
    Initials: "C",
    Surname: "Kluh",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3655: {
    Title: "Mr",
    Initials: "D",
    Surname: "Adams",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  3657: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Berry",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3660: {
    Title: "Mr",
    Initials: "CD",
    Surname: "Atkins",
    "Primary Club": "Surrey RA",
    County: "",
    Country: "England",
  },
  3662: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Ward",
    "Primary Club": "Uppingham Veterans RC",
    County: "Gloucestershire",
    Country: "England",
  },
  3664: {
    Title: "Miss",
    Initials: "HP",
    Surname: "McCullough",
    "Primary Club": "Irish Rifle Club",
    County: "Ulster",
    Country: "Ireland",
  },
  3666: {
    Title: "Capt",
    Initials: "GT",
    Surname: "Heyes",
    "Primary Club": "ATSC",
    County: "",
    Country: "",
  },
  3667: {
    Title: "Mr",
    Initials: "TJG",
    Surname: "Greenhalgh",
    "Primary Club": "ATSC",
    County: "Derbyshire",
    Country: "",
  },
  3669: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Adams",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3670: {
    Title: "Mr",
    Initials: "AG",
    Surname: "Lloyd",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  3671: {
    Title: "Sgt",
    Initials: "MR",
    Surname: "Reed",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  3672: {
    Title: "Miss",
    Initials: "JL",
    Surname: "Barber",
    "Primary Club": "University of Southampton",
    County: "Hampshire",
    Country: "",
  },
  3673: {
    Title: "",
    Initials: "TJH",
    Surname: "Hatch",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "England",
  },
  3675: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Marshall",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3677: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Boyd",
    "Primary Club": "Athelings",
    County: "",
    Country: "",
  },
  3678: {
    Title: "Miss",
    Initials: "A",
    Surname: "Durrant",
    "Primary Club": "Bunhill Rifle Club",
    County: "Cambridgeshire",
    Country: "England",
  },
  3679: {
    Title: "Cpl",
    Initials: "D",
    Surname: "Kiprotich",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3680: {
    Title: "Miss",
    Initials: "O",
    Surname: "Valentin",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3681: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Coatsworth",
    "Primary Club": "RNTRC",
    County: "Durham",
    Country: "England",
  },
  3682: {
    Title: "Miss",
    Initials: "LP",
    Surname: "McKibbin",
    "Primary Club": "Glenalmond College",
    County: "Perthshire",
    Country: "Scotland",
  },
  3683: {
    Title: "Mr",
    Initials: "P",
    Surname: "Kirtchev",
    "Primary Club": "Bunhill Rifle Club",
    County: "County of London",
    Country: "England",
  },
  3684: {
    Title: "Cpl",
    Initials: "PS",
    Surname: "Lehmann",
    "Primary Club": "ATRC",
    County: "",
    Country: "England",
  },
  3685: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Simpson",
    "Primary Club": "Glenalmond College",
    County: "",
    Country: "Scotland",
  },
  3686: {
    Title: "Miss",
    Initials: "MEP",
    Surname: "Bryant",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "England",
  },
  3687: {
    Title: "Mr",
    Initials: "SO",
    Surname: "Jauncey",
    "Primary Club": "Jersey RA",
    County: "Wiltshire",
    Country: "Jersey",
  },
  3688: {
    Title: "",
    Initials: "PJS",
    Surname: "Evans",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "England",
  },
  3689: {
    Title: "Miss",
    Initials: "J",
    Surname: "Simpson",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3690: {
    Title: "Miss",
    Initials: "C",
    Surname: "Halleran",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3691: {
    Title: "Mr",
    Initials: "TAE",
    Surname: "Giulian",
    "Primary Club": "NRA",
    County: "Suffolk",
    Country: "",
  },
  3692: {
    Title: "Cdt Cpl",
    Initials: "RCA",
    Surname: "Tunnard",
    "Primary Club": "Wellington College",
    County: "",
    Country: "England",
  },
  3693: {
    Title: "Mr",
    Initials: "PERD",
    Surname: "Hunt",
    "Primary Club": "City RC",
    County: "",
    Country: "",
  },
  3694: {
    Title: "Mr",
    Initials: "A",
    Surname: "Coakes",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  3695: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Mulcahy",
    "Primary Club": "Old Lancing Rifle Club",
    County: "Sussex",
    Country: "England",
  },
  3696: {
    Title: "Mr",
    Initials: "FPR",
    Surname: "Northam",
    "Primary Club": "North Herts Rifles",
    County: "Hertfordshire",
    Country: "England",
  },
  3697: {
    Title: "Mr",
    Initials: "EJ",
    Surname: "Gardener",
    "Primary Club": "NRA",
    County: "Devon",
    Country: "England",
  },
  3698: {
    Title: "Mr",
    Initials: "AEM",
    Surname: "Mateiro",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  3699: {
    Title: "Mr",
    Initials: "EFBG",
    Surname: "Glanville",
    "Primary Club": "Southampton RC",
    County: "Hampshire",
    Country: "England",
  },
  3700: {
    Title: "",
    Initials: "D",
    Surname: "Trowsdale",
    "Primary Club": "Norfolk",
    County: "",
    Country: "England",
  },
  3701: {
    Title: "Cdt",
    Initials: "GT",
    Surname: "Jones",
    "Primary Club": "Uppingham School",
    County: "",
    Country: "",
  },
  3702: {
    Title: "Mr",
    Initials: "A",
    Surname: "Phythian",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3703: {
    Title: "M",
    Initials: "M",
    Surname: "Garreau",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3705: {
    Title: "Mr",
    Initials: "AK",
    Surname: "Glen",
    "Primary Club": "Basingstoke 1945 Club",
    County: "Hampshire",
    Country: "England",
  },
  3707: {
    Title: "Mr",
    Initials: "ORW",
    Surname: "Russell",
    "Primary Club": "Old Guildfordians RC",
    County: "Sussex",
    Country: "Wales",
  },
  3709: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Jones",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "",
  },
  3710: {
    Title: "Cpl",
    Initials: "NG",
    Surname: "Stewart",
    "Primary Club": "RAF Air Cmd",
    County: "",
    Country: "",
  },
  3711: {
    Title: "Dr",
    Initials: "ITR",
    Surname: "Jobling",
    "Primary Club": "Langar RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  3712: {
    Title: "Mr",
    Initials: "CAS",
    Surname: "Penrose",
    "Primary Club": "Newcastle Uni RC",
    County: "Essex",
    Country: "",
  },
  3714: {
    Title: "",
    Initials: "B",
    Surname: "Henry Benjamin",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  3715: {
    Title: "Mr",
    Initials: "WJ",
    Surname: "Joicey",
    "Primary Club": "Oundle School",
    County: "",
    Country: "England",
  },
  3716: {
    Title: "Mr",
    Initials: "KA",
    Surname: "Van Ast",
    "Primary Club": "",
    County: "",
    Country: "Netherlands",
  },
  3717: {
    Title: "Mr",
    Initials: "RCG",
    Surname: "Knight",
    "Primary Club": "ATSC",
    County: "Berkshire",
    Country: "England",
  },
  3718: {
    Title: "Mr",
    Initials: "RH",
    Surname: "Platt",
    "Primary Club": "Stanmore R&PC",
    County: "",
    Country: "",
  },
  3719: {
    Title: "Mr",
    Initials: "P",
    Surname: "Lusted",
    "Primary Club": "Imperial College RPC",
    County: "County of London",
    Country: "",
  },
  3720: {
    Title: "Mr",
    Initials: "NM",
    Surname: "Duncan",
    "Primary Club": "Cardinals",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3721: {
    Title: "Mr",
    Initials: "PC",
    Surname: "Coulboute",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3723: {
    Title: "Mr",
    Initials: "N",
    Surname: "Harman",
    "Primary Club": "Whitgift Veterans",
    County: "County of London",
    Country: "England",
  },
  3724: {
    Title: "Cdt",
    Initials: "KE",
    Surname: "Ross (cancelled)",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  3725: {
    Title: "Mr",
    Initials: "N",
    Surname: "Yarmolenko",
    "Primary Club": "Wellington College",
    County: "",
    Country: "Russia",
  },
  3730: {
    Title: "Mr",
    Initials: "P",
    Surname: "Todd",
    "Primary Club": "Comber RC",
    County: "Co Down",
    Country: "Ireland",
  },
  3731: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Pinch",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3732: {
    Title: "Ms",
    Initials: "HK",
    Surname: "Mallinckrodt",
    "Primary Club": "Bunhill Rifle Club",
    County: "Gloucestershire",
    Country: "Netherlands",
  },
  3734: {
    Title: "Mr",
    Initials: "A",
    Surname: "Lakhani",
    "Primary Club": "1403 (Reford) Sqn ATC",
    County: "Derbyshire",
    Country: "England",
  },
  3736: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Haines",
    "Primary Club": "Athelings",
    County: "Northants - Leics - Rutland",
    Country: "Ireland",
  },
  3737: {
    Title: "Miss",
    Initials: "HLL",
    Surname: "Landrum",
    "Primary Club": "Athelings",
    County: "Dorset",
    Country: "",
  },
  3738: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Gagnon",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3739: {
    Title: "Mr",
    Initials: "CK",
    Surname: "Saina",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3741: {
    Title: "Mr",
    Initials: "BS",
    Surname: "Preece",
    "Primary Club": "Pumas RC",
    County: "",
    Country: "England",
  },
  3743: {
    Title: "Mr",
    Initials: "R",
    Surname: "Hirst",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  3744: {
    Title: "Mr",
    Initials: "A",
    Surname: "Rowley",
    "Primary Club": "Uppingham Veterans RC",
    County: "Norfolk",
    Country: "",
  },
  3745: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Fullerton",
    "Primary Club": "Dungannon",
    County: "Ulster",
    Country: "Ireland",
  },
  3746: {
    Title: "Cdt",
    Initials: "SM",
    Surname: "Amin",
    "Primary Club": "University of London",
    County: "Surrey",
    Country: "England",
  },
  3747: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Rogowski",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3748: {
    Title: "Mr",
    Initials: "M",
    Surname: "Yego",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3749: {
    Title: "",
    Initials: "C",
    Surname: "Witham",
    "Primary Club": "Framlingham College CCF",
    County: "Suffolk",
    Country: "",
  },
  3750: {
    Title: "Mr",
    Initials: "RIB",
    Surname: "Bramwell",
    "Primary Club": "Uppingham Veterans RC",
    County: "Lincolnshire",
    Country: "",
  },
  3751: {
    Title: "Mr",
    Initials: "C",
    Surname: "Milton",
    "Primary Club": "Old Guildfordians RC",
    County: "Surrey",
    Country: "England",
  },
  3752: {
    Title: "Cdt",
    Initials: "TFFS",
    Surname: "Smart",
    "Primary Club": "Cheltenham College",
    County: "Hampshire",
    Country: "England",
  },
  3753: {
    Title: "Mr",
    Initials: "TW",
    Surname: "Tatchell",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  3754: {
    Title: "Mr",
    Initials: "HAD",
    Surname: "Howard",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  3755: {
    Title: "Mr",
    Initials: "DJD",
    Surname: "Dick",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3756: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Chetwyn",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "England",
  },
  3757: {
    Title: "PC",
    Initials: "F",
    Surname: "Muema",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3758: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Livingstone",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "Scotland",
  },
  3760: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Slaughter",
    "Primary Club": "Athelings",
    County: "County of London",
    Country: "Ireland",
  },
  3761: {
    Title: "Miss",
    Initials: "NR",
    Surname: "Ball",
    "Primary Club": "Reading University",
    County: "Berkshire",
    Country: "England",
  },
  3762: {
    Title: "Mr",
    Initials: "A",
    Surname: "Edwards",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  3763: {
    Title: "Mr",
    Initials: "OJN",
    Surname: "Lyon",
    "Primary Club": "Old Wellingtonians",
    County: "Surrey",
    Country: "England",
  },
  3764: {
    Title: "Mr",
    Initials: "O",
    Surname: "Banks",
    "Primary Club": "Wellington College",
    County: "Hampshire",
    Country: "England",
  },
  3765: {
    Title: "Mr",
    Initials: "R",
    Surname: "Grafton",
    "Primary Club": "Old Guildfordians RC",
    County: "",
    Country: "Ireland",
  },
  3766: {
    Title: "RSM",
    Initials: "L",
    Surname: "Waude",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3767: {
    Title: "Cdt",
    Initials: "R",
    Surname: "McCutcheon",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3768: {
    Title: "Sgt",
    Initials: "D",
    Surname: "Lambert",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  3770: {
    Title: "Mr",
    Initials: "IJ",
    Surname: "Milward",
    "Primary Club": "ATSC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  3771: {
    Title: "Mr",
    Initials: "D",
    Surname: "Hausmann",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  3772: {
    Title: "Mr",
    Initials: "B",
    Surname: "Humphreys",
    "Primary Club": "Wellingborough",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  3773: {
    Title: "Mr",
    Initials: "M",
    Surname: "Dodd",
    "Primary Club": "Falkland Islands RA",
    County: "",
    Country: "Falkland Islands",
  },
  3774: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Williams",
    "Primary Club": "Wellington College",
    County: "Hampshire",
    Country: "England",
  },
  3775: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Emmens",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3776: {
    Title: "Cdt",
    Initials: "CGD",
    Surname: "Dickson",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3777: {
    Title: "Cdt",
    Initials: "D",
    Surname: "McGlynn",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3779: {
    Title: "Mr",
    Initials: "GHM",
    Surname: "Tonks",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  3780: {
    Title: "Cdt",
    Initials: "ERT",
    Surname: "Torrance",
    "Primary Club": "Athelings",
    County: "",
    Country: "",
  },
  3781: {
    Title: "Miss",
    Initials: "S",
    Surname: "Ponter",
    "Primary Club": "Jersey RA",
    County: "East of Scotland",
    Country: "Jersey",
  },
  3782: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Ferguson",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3783: {
    Title: "Miss",
    Initials: "C",
    Surname: "Choquette",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3784: {
    Title: "Mr",
    Initials: "MAS",
    Surname: "Robson",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3786: {
    Title: "Mr",
    Initials: "HJG",
    Surname: "Green",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  3787: {
    Title: "Mr",
    Initials: "R",
    Surname: "Clark",
    "Primary Club": "Glasgow University RC",
    County: "",
    Country: "",
  },
  3789: {
    Title: "Miss",
    Initials: "AZ",
    Surname: "Zulkharnain",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  3790: {
    Title: "Mr",
    Initials: "GJO",
    Surname: "Pearson",
    "Primary Club": "Reading University",
    County: "Dorset",
    Country: "England",
  },
  3791: {
    Title: "",
    Initials: "M",
    Surname: "Roper",
    "Primary Club": "Malvern Sea Cadets",
    County: "",
    Country: "",
  },
  3792: {
    Title: "Miss",
    Initials: "TR",
    Surname: "Rewston",
    "Primary Club": "Notts ACF",
    County: "Nottinghamshire",
    Country: "",
  },
  3793: {
    Title: "Cpl",
    Initials: "N",
    Surname: "Jones",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  3794: {
    Title: "Mr",
    Initials: "T",
    Surname: "Allthorpe-Mullis",
    "Primary Club": "Uppingham School",
    County: "County of London",
    Country: "",
  },
  3795: {
    Title: "Sgt",
    Initials: "S",
    Surname: "Fahy",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "England",
  },
  3796: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Man",
    "Primary Club": "Reading University",
    County: "Berkshire",
    Country: "England",
  },
  3797: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Cerdan",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3798: {
    Title: "Mr",
    Initials: "MG",
    Surname: "Guy",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  3799: {
    Title: "Mr",
    Initials: "EJ",
    Surname: "Joseph",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "",
  },
  3800: {
    Title: "Mr",
    Initials: "JB",
    Surname: "Corr",
    "Primary Club": "Sea Cadets",
    County: "Cheshire",
    Country: "England",
  },
  3801: {
    Title: "Mr",
    Initials: "CLW",
    Surname: "Whale",
    "Primary Club": "Bunhill Rifle Club",
    County: "Middlesex",
    Country: "England",
  },
  3802: {
    Title: "Miss",
    Initials: "KE",
    Surname: "Pope",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "Ireland",
  },
  3803: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Kimondo",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3804: {
    Title: "Mr",
    Initials: "RP",
    Surname: "Birch",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "",
  },
  3805: {
    Title: "Miss",
    Initials: "C",
    Surname: "Pearce",
    "Primary Club": "Cambridge University RA",
    County: "Middlesex",
    Country: "England",
  },
  3806: {
    Title: "Mr",
    Initials: "BB",
    Surname: "Henry",
    "Primary Club": "Jamaica RA",
    County: "",
    Country: "Jamaica",
  },
  3807: {
    Title: "Miss",
    Initials: "C",
    Surname: "Peacock",
    "Primary Club": "Reading University",
    County: "East of Scotland",
    Country: "",
  },
  3808: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Brooker",
    "Primary Club": "Oxford University RC",
    County: "Berkshire",
    Country: "Scotland",
  },
  3810: {
    Title: "Miss",
    Initials: "F",
    Surname: "Danczak",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  3811: {
    Title: "Cdt Sgt",
    Initials: "A",
    Surname: "Cannon",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  3813: {
    Title: "Sgt",
    Initials: "T",
    Surname: "Colquitt",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  3814: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Hassan",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3815: {
    Title: "Sgt",
    Initials: "LS",
    Surname: "Bugmann",
    "Primary Club": "Plymouth & Cornwall ATC",
    County: "Devon",
    Country: "England",
  },
  3816: {
    Title: "Cdt PO",
    Initials: "J",
    Surname: "Palmer",
    "Primary Club": "Framlingham College CCF",
    County: "Suffolk",
    Country: "",
  },
  3817: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Norejko",
    "Primary Club": "Llandovery College",
    County: "",
    Country: "",
  },
  3818: {
    Title: "Cpl",
    Initials: "H",
    Surname: "Gaughan",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3819: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Stevenson",
    "Primary Club": "RCAC",
    County: "",
    Country: "Canada",
  },
  3820: {
    Title: "Mrs",
    Initials: "SR",
    Surname: "Hinde",
    "Primary Club": "Old Wellingburian",
    County: "Cambridgeshire",
    Country: "Denmark",
  },
  3821: {
    Title: "Mr",
    Initials: "D",
    Surname: "Carpenter",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  3822: {
    Title: "Mr",
    Initials: "A",
    Surname: "Rathwell",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3823: {
    Title: "Mr",
    Initials: "TC",
    Surname: "Perkins",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3824: {
    Title: "Mr",
    Initials: "M",
    Surname: "Dechamplain",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3825: {
    Title: "Mr",
    Initials: "M",
    Surname: "Wolf",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3826: {
    Title: "",
    Initials: "WM",
    Surname: "Watts",
    "Primary Club": "DCRA",
    County: "Alberta",
    Country: "Canada",
  },
  3827: {
    Title: "Miss",
    Initials: "KA",
    Surname: "Fitton",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  3828: {
    Title: "Mr",
    Initials: "WR",
    Surname: "Flintoft",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  3829: {
    Title: "Mr",
    Initials: "VAAM",
    Surname: "Sessink",
    "Primary Club": "F Class Netherlands",
    County: "",
    Country: "Netherlands",
  },
  3830: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Turner",
    "Primary Club": "Marquis",
    County: "Berkshire",
    Country: "England",
  },
  3831: {
    Title: "Mr",
    Initials: "M",
    Surname: "Been",
    "Primary Club": "NRA",
    County: "",
    Country: "Netherlands",
  },
  3832: {
    Title: "Mr",
    Initials: "JWM",
    Surname: "Murphy",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3833: {
    Title: "Mr",
    Initials: "A",
    Surname: "Brough",
    "Primary Club": "CNRC",
    County: "",
    Country: "England",
  },
  3834: {
    Title: "Mr",
    Initials: "S",
    Surname: "Balolia",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3835: {
    Title: "Mr",
    Initials: "LT",
    Surname: "Taite",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3836: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Traylor",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3837: {
    Title: "Mr",
    Initials: "JG",
    Surname: "Cochran",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3838: {
    Title: "Mr",
    Initials: "DG",
    Surname: "Gosnell",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3839: {
    Title: "Mr",
    Initials: "R",
    Surname: "Bock (Jr)",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3840: {
    Title: "Mr",
    Initials: "RT",
    Surname: "Bock",
    "Primary Club": "USA National",
    County: "Dorset",
    Country: "USA",
  },
  3841: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Brewer",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3842: {
    Title: "Mr",
    Initials: "DO",
    Surname: "Morris",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3843: {
    Title: "Mr",
    Initials: "BWO",
    Surname: "Otey",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3844: {
    Title: "Mr",
    Initials: "E",
    Surname: "Bair",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3845: {
    Title: "Mr",
    Initials: "PH",
    Surname: "Wilson",
    "Primary Club": "Mars & Minerva",
    County: "",
    Country: "England",
  },
  3846: {
    Title: "Mr",
    Initials: "MWJ",
    Surname: "De Kort",
    "Primary Club": "F Class Netherlands",
    County: "",
    Country: "Netherlands",
  },
  3847: {
    Title: "",
    Initials: "A",
    Surname: "Kritzinger",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  3848: {
    Title: "Mr",
    Initials: "SP",
    Surname: "Barrett",
    "Primary Club": "Welsh RA",
    County: "Lancashire",
    Country: "Wales",
  },
  3849: {
    Title: "Mr",
    Initials: "MAD",
    Surname: "Downey",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3850: {
    Title: "Mr",
    Initials: "J",
    Surname: "Cronje",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3851: {
    Title: "Mr",
    Initials: "DRB",
    Surname: "Bailey",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3852: {
    Title: "Mr",
    Initials: "L",
    Surname: "Bartholome",
    "Primary Club": "USA National",
    County: "",
    Country: "USA",
  },
  3853: {
    Title: "Mr",
    Initials: "GJ",
    Surname: "Phelan",
    "Primary Club": "Yarram",
    County: "",
    Country: "Australia",
  },
  3854: {
    Title: "Mr",
    Initials: "SW",
    Surname: "Jones",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "",
  },
  3855: {
    Title: "Mr",
    Initials: "A",
    Surname: "Lilley",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  3856: {
    Title: "Mr",
    Initials: "DG",
    Surname: "Kumbang",
    "Primary Club": "CURA",
    County: "",
    Country: "",
  },
  3857: {
    Title: "Mr",
    Initials: "EK",
    Surname: "Kerfoot",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  3858: {
    Title: "Mr",
    Initials: "DRJF",
    Surname: "Forster",
    "Primary Club": "Cambridge University RA",
    County: "Buckinghamshire",
    Country: "Belgium",
  },
  3859: {
    Title: "Mr",
    Initials: "SM",
    Surname: "Murugesu",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  3860: {
    Title: "Miss",
    Initials: "L",
    Surname: "Saxton",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Ireland",
  },
  3861: {
    Title: "Mr",
    Initials: "AM",
    Surname: "Gent",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  3862: {
    Title: "Mr",
    Initials: "FWA",
    Surname: "Coetzee",
    "Primary Club": "",
    County: "",
    Country: "South Africa",
  },
  3863: {
    Title: "Mr",
    Initials: "LC",
    Surname: "James",
    "Primary Club": "RNTRC",
    County: "Northants - Leics - Rutland",
    Country: "Wales",
  },
  3864: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Hull",
    "Primary Club": "Hampshire RA",
    County: "Hampshire",
    Country: "England",
  },
  3865: {
    Title: "Mr",
    Initials: "JB",
    Surname: "Hull",
    "Primary Club": "Uppingham Veterans RC",
    County: "Hampshire",
    Country: "England",
  },
  3866: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Vaillancourt",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3867: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Sims",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  3868: {
    Title: "Mrs",
    Initials: "HA",
    Surname: "Cook",
    "Primary Club": "Old Wellingtonians",
    County: "Devon",
    Country: "England",
  },
  3869: {
    Title: "Cdt",
    Initials: "WBP",
    Surname: "Hutton",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "",
  },
  3870: {
    Title: "Mr",
    Initials: "LS",
    Surname: "Otundo",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3871: {
    Title: "Cdt",
    Initials: "FX",
    Surname: "Cliche",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  3872: {
    Title: "Mr",
    Initials: "SW",
    Surname: "Cullinan",
    "Primary Club": "Middelburg",
    County: "",
    Country: "South Africa",
  },
  3873: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Holmes",
    "Primary Club": "Manchester RC",
    County: "Lancashire",
    Country: "England",
  },
  3874: {
    Title: "Mr",
    Initials: "W",
    Surname: "Hooper",
    "Primary Club": "RNTRC",
    County: "East of Scotland",
    Country: "",
  },
  3875: {
    Title: "Cdt",
    Initials: "S",
    Surname: "McEvoy",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3876: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Poulter",
    "Primary Club": "Athelings",
    County: "Dorset",
    Country: "England",
  },
  3877: {
    Title: "Mr",
    Initials: "H",
    Surname: "Hattingh",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3878: {
    Title: "Cdt",
    Initials: "D",
    Surname: "St-Cyr Brossard",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3879: {
    Title: "Cdt",
    Initials: "KX",
    Surname: "Zhao",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3880: {
    Title: "Sjt",
    Initials: "C",
    Surname: "Atherton",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3881: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Letendre",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3882: {
    Title: "Mr",
    Initials: "GH",
    Surname: "Pretorius",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3883: {
    Title: "Cdt FS",
    Initials: "T",
    Surname: "Marsh",
    "Primary Club": "126 Sqn ATC",
    County: "Derbyshire",
    Country: "England",
  },
  3884: {
    Title: "Mr",
    Initials: "T",
    Surname: "Smith",
    "Primary Club": "LMRA",
    County: "Leicestershire",
    Country: "England",
  },
  3885: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Martin",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3886: {
    Title: "Mr",
    Initials: "OL",
    Surname: "Ryndziewicz",
    "Primary Club": "Surrey RA",
    County: "Gloucestershire",
    Country: "England",
  },
  3887: {
    Title: "2Lt",
    Initials: "RJ",
    Surname: "Kellett",
    "Primary Club": "Sedbergh School",
    County: "Lancashire",
    Country: "England",
  },
  3888: {
    Title: "Mr",
    Initials: "MAJ",
    Surname: "Barber",
    "Primary Club": "RNTRC",
    County: "Guernsey",
    Country: "",
  },
  3889: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Garnett",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3890: {
    Title: "Cpl",
    Initials: "P",
    Surname: "Calvert",
    "Primary Club": "ATSC",
    County: "",
    Country: "",
  },
  3891: {
    Title: "Mr",
    Initials: "LRM",
    Surname: "Irungu",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3892: {
    Title: "Mr",
    Initials: "N",
    Surname: "Mringie",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3893: {
    Title: "Miss",
    Initials: "RR",
    Surname: "Davis",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3894: {
    Title: "Mr",
    Initials: "GM",
    Surname: "Gee",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  3895: {
    Title: "Mr",
    Initials: "A",
    Surname: "Cummings",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "Ireland",
  },
  3896: {
    Title: "Mr",
    Initials: "D",
    Surname: "Cottrill",
    "Primary Club": "RAFTRC",
    County: "Lincolnshire",
    Country: "England",
  },
  3897: {
    Title: "Cdt",
    Initials: "AE",
    Surname: "Corner",
    "Primary Club": "Wellington College",
    County: "Middlesex",
    Country: "England",
  },
  3898: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Sangster",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3899: {
    Title: "Mr",
    Initials: "LJ",
    Surname: "Potgiter",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3900: {
    Title: "Mr",
    Initials: "CRW",
    Surname: "Downing",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  3901: {
    Title: "Mr",
    Initials: "ET",
    Surname: "Egne",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  3902: {
    Title: "Mr",
    Initials: "J",
    Surname: "Macaulay",
    "Primary Club": "ATSC",
    County: "",
    Country: "",
  },
  3903: {
    Title: "Mr",
    Initials: "GED",
    Surname: "Hart",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  3904: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Moylan",
    "Primary Club": "ACNSTC",
    County: "Midland Counties",
    Country: "England",
  },
  3905: {
    Title: "Mr",
    Initials: "OCIK",
    Surname: "Kember",
    "Primary Club": "Uppingham School",
    County: "Norfolk",
    Country: "England",
  },
  3906: {
    Title: "Mr",
    Initials: "TJG",
    Surname: "Hendriks",
    "Primary Club": "Old Tonbridgian",
    County: "Kent",
    Country: "Jamaica",
  },
  3907: {
    Title: "Dr",
    Initials: "CW",
    Surname: "Freeman",
    "Primary Club": "Old Haberdashers",
    County: "Middlesex",
    Country: "England",
  },
  3908: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Morris",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  3909: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Atherton",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3910: {
    Title: "Mr",
    Initials: "PC",
    Surname: "Champagne",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  3911: {
    Title: "Cdt",
    Initials: "CJ",
    Surname: "Beynon",
    "Primary Club": "Cheltenham College",
    County: "",
    Country: "England",
  },
  3912: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Beaulne",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3913: {
    Title: "Mr",
    Initials: "J",
    Surname: "Balson",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "England",
  },
  3914: {
    Title: "Mrs",
    Initials: "AM",
    Surname: "McDougall",
    "Primary Club": "North Sydney",
    County: "",
    Country: "Australia",
  },
  3915: {
    Title: "Cpl",
    Initials: "T",
    Surname: "Ford",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "",
  },
  3916: {
    Title: "Mr",
    Initials: "A",
    Surname: "Sladek",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3917: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Smith",
    "Primary Club": "Athelings",
    County: "Jersey",
    Country: "",
  },
  3918: {
    Title: "Miss",
    Initials: "L",
    Surname: "Lewin",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3919: {
    Title: "Miss",
    Initials: "CA",
    Surname: "Evans",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "Wales",
  },
  3920: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Deveau",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3921: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Devine",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  3922: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Wilks",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  3923: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Evans",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  3924: {
    Title: "Mr",
    Initials: "BSC",
    Surname: "Kelso",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "Ireland",
  },
  3925: {
    Title: "Mr",
    Initials: "WF",
    Surname: "De Beer",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3926: {
    Title: "Cdt",
    Initials: "O",
    Surname: "Oppelt",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3927: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Wathier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  3928: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Smart",
    "Primary Club": "396 (Paisley) Sqn ATC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3929: {
    Title: "Mrs",
    Initials: "HV",
    Surname: "Golaszewski",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  3930: {
    Title: "Cdt",
    Initials: "HSJC",
    Surname: "Cubbin",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  3931: {
    Title: "Mr",
    Initials: "OS",
    Surname: "Ridd",
    "Primary Club": "Old Tonbridgian",
    County: "Sussex",
    Country: "England",
  },
  3932: {
    Title: "Mr",
    Initials: "LP",
    Surname: "Davison",
    "Primary Club": "University of London",
    County: "Yorkshire",
    Country: "England",
  },
  3933: {
    Title: "Cdt",
    Initials: "OTP",
    Surname: "Purdy",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  3934: {
    Title: "Mr",
    Initials: "IMR",
    Surname: "Power",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  3935: {
    Title: "Mr",
    Initials: "JN",
    Surname: "Lowe",
    "Primary Club": "ATSC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3936: {
    Title: "Miss",
    Initials: "F",
    Surname: "Tancred-Holmes",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  3937: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Pelletier",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3938: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Webber",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  3939: {
    Title: "Miss",
    Initials: "M",
    Surname: "Miller",
    "Primary Club": "University of London",
    County: "Surrey",
    Country: "England",
  },
  3940: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Passmore",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Wales",
  },
  3941: {
    Title: "Mr",
    Initials: "J",
    Surname: "Britz",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3942: {
    Title: "Mr",
    Initials: "RJH",
    Surname: "Hurdle",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "England",
  },
  3943: {
    Title: "Sgt",
    Initials: "JR",
    Surname: "Kennedy",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "England",
  },
  3944: {
    Title: "Cdt",
    Initials: "RJA",
    Surname: "Argo",
    "Primary Club": "Aberdeen University RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  3945: {
    Title: "Mr",
    Initials: "EK",
    Surname: "Ngetich",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  3946: {
    Title: "Sgt",
    Initials: "H",
    Surname: "Walker",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3947: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Urquhart",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3948: {
    Title: "Mr",
    Initials: "J",
    Surname: "Bradshaw",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3949: {
    Title: "Mr",
    Initials: "AJM",
    Surname: "Mazumdar",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  3950: {
    Title: "LBdr",
    Initials: "A",
    Surname: "Shead",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3951: {
    Title: "Cdt",
    Initials: "GBA",
    Surname: "Astin",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  3952: {
    Title: "Miss",
    Initials: "RC",
    Surname: "Stevens",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3953: {
    Title: "Miss",
    Initials: "EK",
    Surname: "Gibson",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  3954: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Nash",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3955: {
    Title: "Mr",
    Initials: "MJB",
    Surname: "Benning",
    "Primary Club": "Newcastle Uni RC",
    County: "Yorkshire",
    Country: "England",
  },
  3956: {
    Title: "Miss",
    Initials: "M",
    Surname: "Ang",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "England",
  },
  3957: {
    Title: "Mr",
    Initials: "K",
    Surname: "Gladding",
    "Primary Club": "Uppingham School",
    County: "",
    Country: "England",
  },
  3958: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Crease",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  3959: {
    Title: "Mr",
    Initials: "HJM",
    Surname: "Awin",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  3960: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Macrae",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3961: {
    Title: "Cdt",
    Initials: "TA",
    Surname: "Arrowsmith",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  3962: {
    Title: "Mr",
    Initials: "J",
    Surname: "Allum",
    "Primary Club": "NLRC",
    County: "Berkshire",
    Country: "England",
  },
  3963: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Gradwell",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3964: {
    Title: "Mr",
    Initials: "EJE",
    Surname: "Mearns",
    "Primary Club": "Bradfield College",
    County: "Oxfordshire",
    Country: "",
  },
  3965: {
    Title: "Mr",
    Initials: "FC",
    Surname: "Hummert",
    "Primary Club": "Wellington College",
    County: "Oxfordshire",
    Country: "Germany",
  },
  3966: {
    Title: "Mr",
    Initials: "DP",
    Surname: "Marshall",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Wales",
  },
  3967: {
    Title: "Mr",
    Initials: "TCE",
    Surname: "Elworthy",
    "Primary Club": "Oxford University RC",
    County: "Yorkshire",
    Country: "Wales",
  },
  3968: {
    Title: "Mr",
    Initials: "DDR",
    Surname: "Warner",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "England",
  },
  3969: {
    Title: "Cpl",
    Initials: "L",
    Surname: "Thirlaway",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3970: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Bedard",
    "Primary Club": "RCAC National RifleTeam",
    County: "",
    Country: "Canada",
  },
  3971: {
    Title: "Mrs",
    Initials: "LE",
    Surname: "Haley",
    "Primary Club": "Nottingham City RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  3972: {
    Title: "Miss",
    Initials: "H",
    Surname: "McLoughlin",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3973: {
    Title: "Sgt",
    Initials: "C",
    Surname: "Beddow",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  3974: {
    Title: "Mr",
    Initials: "TG",
    Surname: "Gordon",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  3975: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Carruthers",
    "Primary Club": "396 (Paisley) Sqn ATC",
    County: "Renfrewshire",
    Country: "Scotland",
  },
  3976: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Cronje",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  3977: {
    Title: "Cdt",
    Initials: "CAS",
    Surname: "Lucas",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3978: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Gould",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  3979: {
    Title: "Cpl",
    Initials: "U",
    Surname: "Qazi",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  3980: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Molesworth",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "England",
  },
  3981: {
    Title: "Sgt",
    Initials: "JT",
    Surname: "McKay",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3982: {
    Title: "Capt",
    Initials: "JJ",
    Surname: "Jalili",
    "Primary Club": "ATSC",
    County: "Oxfordshire",
    Country: "England",
  },
  3983: {
    Title: "Mr",
    Initials: "PMJ",
    Surname: "Tooley",
    "Primary Club": "Stock Exchange RC",
    County: "Essex",
    Country: "England",
  },
  3984: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Butler",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  3985: {
    Title: "Cdt CSM",
    Initials: "A",
    Surname: "Pattison",
    "Primary Club": "Wellingborough",
    County: "Northants - Leics - Rutland",
    Country: "",
  },
  3986: {
    Title: "Sgt",
    Initials: "GM",
    Surname: "Irish",
    "Primary Club": "NLRC",
    County: "Gloucestershire",
    Country: "England",
  },
  3987: {
    Title: "Miss",
    Initials: "RM",
    Surname: "Camm",
    "Primary Club": "RAFTRC",
    County: "Lincolnshire",
    Country: "England",
  },
  3988: {
    Title: "Mr",
    Initials: "C",
    Surname: "Downer",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  3989: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Buchanan",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3990: {
    Title: "Mr",
    Initials: "B",
    Surname: "Jarvis",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  3991: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Veich",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  3992: {
    Title: "LCpl",
    Initials: "LA",
    Surname: "Volkwein",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  3993: {
    Title: "Mrs",
    Initials: "S",
    Surname: "Stebbings",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  3994: {
    Title: "Cdt",
    Initials: "GA",
    Surname: "Buchanan",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3995: {
    Title: "Miss",
    Initials: "HR",
    Surname: "Charles",
    "Primary Club": "Reading University",
    County: "Essex",
    Country: "England",
  },
  3996: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Critchlow",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  3997: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Ferris",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  3998: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Folbigg",
    "Primary Club": "Cambs ACF",
    County: "Cambridgeshire",
    Country: "",
  },
  3999: {
    Title: "Mr",
    Initials: "NB",
    Surname: "Burns",
    "Primary Club": "Oxford University RC",
    County: "Somerset",
    Country: "England",
  },
  4000: {
    Title: "Sjt",
    Initials: "S",
    Surname: "Altus",
    "Primary Club": "Athelings",
    County: "Gloucestershire",
    Country: "England",
  },
  4001: {
    Title: "Lt",
    Initials: "WR",
    Surname: "Hooper",
    "Primary Club": "Royal Navy",
    County: "",
    Country: "",
  },
  4002: {
    Title: "",
    Initials: "D",
    Surname: "Lakapana",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4003: {
    Title: "Mr",
    Initials: "SRG",
    Surname: "Anselm",
    "Primary Club": "White Horse Lodge SC",
    County: "Lancashire",
    Country: "England",
  },
  4004: {
    Title: "Mr",
    Initials: "G",
    Surname: "Bowden",
    "Primary Club": "East Bristol RC",
    County: "",
    Country: "",
  },
  4005: {
    Title: "Mr",
    Initials: "A",
    Surname: "Brewer",
    "Primary Club": "University of Southampton",
    County: "",
    Country: "",
  },
  4006: {
    Title: "",
    Initials: "D",
    Surname: "Briand",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  4007: {
    Title: "Mr",
    Initials: "D",
    Surname: "Buell",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4008: {
    Title: "Mr",
    Initials: "DR",
    Surname: "Comerford",
    "Primary Club": "MNSCI",
    County: "",
    Country: "Republic of Ireland",
  },
  4009: {
    Title: "",
    Initials: "AB",
    Surname: "Condie",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  4010: {
    Title: "Mr",
    Initials: "FXC",
    Surname: "Conlon",
    "Primary Club": "USA",
    County: "",
    Country: "",
  },
  4011: {
    Title: "Mr",
    Initials: "M",
    Surname: "Daish",
    "Primary Club": "SPRC",
    County: "Derbyshire",
    Country: "England",
  },
  4012: {
    Title: "Mr",
    Initials: "A",
    Surname: "Dau",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4013: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Day",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  4014: {
    Title: "Mr",
    Initials: "W",
    Surname: "Dean",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4015: {
    Title: "Mr",
    Initials: "SW",
    Surname: "Donaldson",
    "Primary Club": "101 RC",
    County: "Lancashire",
    Country: "England",
  },
  4016: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Els",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4017: {
    Title: "Miss",
    Initials: "PA",
    Surname: "Evans",
    "Primary Club": "Welsh RA",
    County: "Berkshire",
    Country: "Wales",
  },
  4018: {
    Title: "J/Cpl",
    Initials: "AF",
    Surname: "Filhol",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "Ireland",
  },
  4019: {
    Title: "Mr",
    Initials: "AM",
    Surname: "Gent",
    "Primary Club": "English VIII",
    County: "Derbyshire",
    Country: "England",
  },
  4020: {
    Title: "Mr",
    Initials: "E",
    Surname: "Hooper",
    "Primary Club": "Reading University",
    County: "Gloucestershire",
    Country: "England",
  },
  4021: {
    Title: "Mr",
    Initials: "GE",
    Surname: "Hutley",
    "Primary Club": "Reading University",
    County: "Essex",
    Country: "England",
  },
  4022: {
    Title: "Mr",
    Initials: "NG",
    Surname: "Kelly",
    "Primary Club": "MNSCI",
    County: "",
    Country: "Republic of Ireland",
  },
  4023: {
    Title: "Mr",
    Initials: "JWK",
    Surname: "Kilgallon",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  4024: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Kruger",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4025: {
    Title: "Mr",
    Initials: "J",
    Surname: "Lepeuve",
    "Primary Club": "TNV France",
    County: "",
    Country: "France",
  },
  4026: {
    Title: "Miss",
    Initials: "JE",
    Surname: "Lewsey",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  4027: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Marsh",
    "Primary Club": "SCRA",
    County: "",
    Country: "England",
  },
  4028: {
    Title: "Mr",
    Initials: "M",
    Surname: "Mead",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Isle of Man",
  },
  4029: {
    Title: "Mr",
    Initials: "M",
    Surname: "Milanuk",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4030: {
    Title: "Mr",
    Initials: "M",
    Surname: "Miller",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4031: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Morley",
    "Primary Club": "Wandsworth FBRC",
    County: "Devon",
    Country: "England",
  },
  4032: {
    Title: "Mr",
    Initials: "CK",
    Surname: "Murphy",
    "Primary Club": "MNSCI",
    County: "",
    Country: "Republic of Ireland",
  },
  4033: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Nice",
    "Primary Club": "ATSC",
    County: "Suffolk",
    Country: "England",
  },
  4034: {
    Title: "Mr",
    Initials: "SWO",
    Surname: "Owen",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  4035: {
    Title: "Mr",
    Initials: "S",
    Surname: "Pate",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4036: {
    Title: "Mr",
    Initials: "P",
    Surname: "Phillips",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4037: {
    Title: "Mr",
    Initials: "I",
    Surname: "Potgieter",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4038: {
    Title: "Mr",
    Initials: "J",
    Surname: "Rorer",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4039: {
    Title: "Mr",
    Initials: "B",
    Surname: "Sauve",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4040: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Tracey",
    "Primary Club": "Queensland",
    County: "",
    Country: "Australia",
  },
  4041: {
    Title: "Mr",
    Initials: "HW",
    Surname: "Van der Linden",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4042: {
    Title: "Mr",
    Initials: "FH",
    Surname: "Van der Merwe",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4043: {
    Title: "Mr",
    Initials: "FC",
    Surname: "Van Niekerk",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4044: {
    Title: "",
    Initials: "JK",
    Surname: "Walsh",
    "Primary Club": "Dublin Rifle Club",
    County: "",
    Country: "Ireland",
  },
  4045: {
    Title: "Cpl",
    Initials: "K",
    Surname: "Weatherall",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4046: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Webb",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  4047: {
    Title: "Mr",
    Initials: "J",
    Surname: "Weil",
    "Primary Club": "Team USA - F-T/R",
    County: "",
    Country: "USA",
  },
  4048: {
    Title: "Mr",
    Initials: "K",
    Surname: "Witthaus",
    "Primary Club": "SABU",
    County: "",
    Country: "South Africa",
  },
  4049: {
    Title: "Mr",
    Initials: "S",
    Surname: "Broad",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  4050: {
    Title: "Mr",
    Initials: "LR",
    Surname: "Chisholm",
    "Primary Club": "High Power RA",
    County: "Buckinghamshire",
    Country: "England",
  },
  4051: {
    Title: "Mr",
    Initials: "R",
    Surname: "James",
    "Primary Club": "RAFTRC",
    County: "Suffolk",
    Country: "",
  },
  4052: {
    Title: "Mr",
    Initials: "L",
    Surname: "Lozano",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  4053: {
    Title: "Dr",
    Initials: "EL",
    Surname: "Sweet",
    "Primary Club": "NLRC",
    County: "Hertfordshire",
    Country: "Scotland",
  },
  4054: {
    Title: "Mr",
    Initials: "R",
    Surname: "Cole",
    "Primary Club": "USA",
    County: "",
    Country: "United States of America",
  },
  4055: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Wilson",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4056: {
    Title: "Dr",
    Initials: "DJR",
    Surname: "Lloyd",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "Wales",
  },
  4057: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Abrahams",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  4062: {
    Title: "Mr",
    Initials: "A",
    Surname: "Jenkins",
    "Primary Club": "WDRC",
    County: "Surrey",
    Country: "England",
  },
  4064: {
    Title: "M",
    Initials: "A",
    Surname: "Papezyk",
    "Primary Club": "",
    County: "",
    Country: "France",
  },
  4065: {
    Title: "",
    Initials: "FP",
    Surname: "Papezyk",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  4067: {
    Title: "Mr",
    Initials: "FT",
    Surname: "Bodman",
    "Primary Club": "NRA",
    County: "Hampshire",
    Country: "England",
  },
  4071: {
    Title: "O/Cdt",
    Initials: "BA",
    Surname: "Boyle",
    "Primary Club": "ATSC",
    County: "Nottinghamshire",
    Country: "Ireland",
  },
  4087: {
    Title: "SAC",
    Initials: "J",
    Surname: "Thompstone",
    "Primary Club": "RAFTRC",
    County: "Nottinghamshire",
    Country: "England",
  },
  4098: {
    Title: "Mr",
    Initials: "MC",
    Surname: "Cooper",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  4099: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Rowell",
    "Primary Club": "University of Southampton",
    County: "Devon",
    Country: "England",
  },
  4106: {
    Title: "Mr",
    Initials: "SHM",
    Surname: "Keith",
    "Primary Club": "LMRA",
    County: "Wiltshire",
    Country: "Ireland",
  },
  4140: {
    Title: "Miss",
    Initials: "SJ",
    Surname: "Dick",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4143: {
    Title: "Mr",
    Initials: "K",
    Surname: "Crabtree",
    "Primary Club": "LMRA",
    County: "Middlesex",
    Country: "England",
  },
  4196: {
    Title: "Miss",
    Initials: "LG",
    Surname: "Taylor",
    "Primary Club": "Old Epsomian RC",
    County: "Lancashire",
    Country: "England",
  },
  4253: {
    Title: "Miss",
    Initials: "GE",
    Surname: "Coburn",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  4271: {
    Title: "Miss",
    Initials: "KR",
    Surname: "Sharpington",
    "Primary Club": "Cambridge University RA",
    County: "Buckinghamshire",
    Country: "England",
  },
  4272: {
    Title: "Mr",
    Initials: "T",
    Surname: "Croucher",
    "Primary Club": "Cambridge University RA",
    County: "Kent",
    Country: "England",
  },
  4273: {
    Title: "Mr",
    Initials: "TGS",
    Surname: "Sherwin",
    "Primary Club": "Oxford University RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  4274: {
    Title: "Mr",
    Initials: "R",
    Surname: "Adler",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4275: {
    Title: "Mr",
    Initials: "AD",
    Surname: "Jenkins",
    "Primary Club": "Waverley & District",
    County: "Surrey",
    Country: "England",
  },
  4276: {
    Title: "Mr",
    Initials: "R",
    Surname: "Eckbauer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4279: {
    Title: "Mr",
    Initials: "DM",
    Surname: "Tree",
    "Primary Club": "Stevenage RC",
    County: "Hertfordshire",
    Country: "",
  },
  4281: {
    Title: "Mr",
    Initials: "AT",
    Surname: "Wyspianski",
    "Primary Club": "",
    County: "Middlesex",
    Country: "",
  },
  4282: {
    Title: "Miss",
    Initials: "C",
    Surname: "Choquette",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4283: {
    Title: "Mr",
    Initials: "NJB",
    Surname: "Blunsom",
    "Primary Club": "UKCRT",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4285: {
    Title: "Mr",
    Initials: "SAJ",
    Surname: "Dowley",
    "Primary Club": "Surrey RA",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4286: {
    Title: "Mr",
    Initials: "CC",
    Surname: "Chevrier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4287: {
    Title: "Miss",
    Initials: "CHM",
    Surname: "Mckeever",
    "Primary Club": "UKCRT",
    County: "Cambridgeshire",
    Country: "England",
  },
  4288: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Dugal",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4289: {
    Title: "Mr",
    Initials: "K",
    Surname: "Dauncey",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4290: {
    Title: "Cdt",
    Initials: "DSC",
    Surname: "Chadwick",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4291: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Bullock",
    "Primary Club": "NLRC",
    County: "Nottinghamshire",
    Country: "England",
  },
  4292: {
    Title: "Cdt",
    Initials: "CR",
    Surname: "Rancourt",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4293: {
    Title: "Miss",
    Initials: "A",
    Surname: "Southall",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "England",
  },
  4294: {
    Title: "Mr",
    Initials: "B",
    Surname: "Newgard",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4295: {
    Title: "Mr",
    Initials: "SWH",
    Surname: "Huish",
    "Primary Club": "University of Southampton",
    County: "Devon",
    Country: "England",
  },
  4296: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Atyeo",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4297: {
    Title: "Mr",
    Initials: "LW",
    Surname: "Belton",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  4298: {
    Title: "Mr",
    Initials: "TA",
    Surname: "French",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  4299: {
    Title: "Mr",
    Initials: "ES",
    Surname: "Maclean",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4301: {
    Title: "Cdt",
    Initials: "KD",
    Surname: "Dycer",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "",
  },
  4302: {
    Title: "Mr",
    Initials: "ST",
    Surname: "Welsh",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  4303: {
    Title: "Cdt",
    Initials: "LA",
    Surname: "Sutton",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  4304: {
    Title: "Mr",
    Initials: "RS",
    Surname: "Binns",
    "Primary Club": "LMRA",
    County: "Berkshire",
    Country: "England",
  },
  4306: {
    Title: "Mr",
    Initials: "N",
    Surname: "Houle",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4307: {
    Title: "Mr",
    Initials: "BK",
    Surname: "McFall",
    "Primary Club": "University of Southampton",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  4308: {
    Title: "Cdt",
    Initials: "CW",
    Surname: "Boag",
    "Primary Club": "Black Watch ACF",
    County: "",
    Country: "",
  },
  4309: {
    Title: "Miss",
    Initials: "ATJV",
    Surname: "Voorthuyzen",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  4310: {
    Title: "Miss",
    Initials: "KMA",
    Surname: "Atkinson",
    "Primary Club": "1344 (Cardiff) ATC",
    County: "South Wales",
    Country: "Wales",
  },
  4313: {
    Title: "Mr",
    Initials: "PW",
    Surname: "Glen",
    "Primary Club": "West Atholl RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4314: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Frank",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  4315: {
    Title: "Mr",
    Initials: "TJB",
    Surname: "Barkel",
    "Primary Club": "Bunhill Rifle Club",
    County: "Buckinghamshire",
    Country: "England",
  },
  4316: {
    Title: "Mr",
    Initials: "AD",
    Surname: "Partridge",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  4317: {
    Title: "Cpl",
    Initials: "N",
    Surname: "McGowan",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  4318: {
    Title: "Miss",
    Initials: "CJ",
    Surname: "Moogk",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4320: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Wright",
    "Primary Club": "Sheffield RC",
    County: "Yorkshire",
    Country: "England",
  },
  4321: {
    Title: "Mr",
    Initials: "CN",
    Surname: "Jaynes",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4322: {
    Title: "Mr",
    Initials: "MB",
    Surname: "Grant",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4323: {
    Title: "Sjt",
    Initials: "P",
    Surname: "Tucker",
    "Primary Club": "UKCRT",
    County: "Somerset",
    Country: "England",
  },
  4324: {
    Title: "Mr",
    Initials: "C",
    Surname: "Palfrey",
    "Primary Club": "Wiltshire ACF",
    County: "Wiltshire",
    Country: "England",
  },
  4325: {
    Title: "Miss",
    Initials: "IS",
    Surname: "O'Bryan",
    "Primary Club": "Cambs ACF",
    County: "Cambridgeshire",
    Country: "England",
  },
  4326: {
    Title: "Miss",
    Initials: "CM",
    Surname: "McKenzie",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4327: {
    Title: "Sjt",
    Initials: "J",
    Surname: "McCready",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  4328: {
    Title: "LCpl",
    Initials: "L",
    Surname: "Le Breuilly",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "England",
  },
  4329: {
    Title: "Miss",
    Initials: "EP",
    Surname: "Bryant",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "",
  },
  4330: {
    Title: "Mr",
    Initials: "KSPB",
    Surname: "Brown",
    "Primary Club": "2391 Sqn ATC",
    County: "Dorset",
    Country: "",
  },
  4331: {
    Title: "LCpl",
    Initials: "A",
    Surname: "Gray",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  4332: {
    Title: "Mr",
    Initials: "FL",
    Surname: "Laberge",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4333: {
    Title: "Cdt",
    Initials: "BJB",
    Surname: "Briggs",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  4334: {
    Title: "Cdt",
    Initials: "PI",
    Surname: "Mare",
    "Primary Club": "Victoria College",
    County: "",
    Country: "Jersey",
  },
  4335: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Johnston",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4336: {
    Title: "CSgt",
    Initials: "A",
    Surname: "McBain",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  4337: {
    Title: "Mr",
    Initials: "TV",
    Surname: "Vlad",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4338: {
    Title: "Mr",
    Initials: "FTG",
    Surname: "Amy",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  4339: {
    Title: "Mr",
    Initials: "MS",
    Surname: "Savage",
    "Primary Club": "Newcastle Uni RC",
    County: "Ulster",
    Country: "Ireland",
  },
  4340: {
    Title: "Mr",
    Initials: "I",
    Surname: "Brotherston",
    "Primary Club": "East of Scotland RC",
    County: "W Lothian",
    Country: "Scotland",
  },
  4341: {
    Title: "Cdt",
    Initials: "MT",
    Surname: "Lehmann",
    "Primary Club": "Glasgow University RC",
    County: "",
    Country: "",
  },
  4342: {
    Title: "Cdt",
    Initials: "GBA",
    Surname: "Anderson",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  4343: {
    Title: "Mr",
    Initials: "S",
    Surname: "Livingston",
    "Primary Club": "City of Newcastle RC",
    County: "County Durham",
    Country: "England",
  },
  4344: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Jardine",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4345: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Day",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4346: {
    Title: "LCpl",
    Initials: "B",
    Surname: "Robson",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "",
  },
  4347: {
    Title: "Cdt",
    Initials: "AR",
    Surname: "Riopel",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4348: {
    Title: "Mr",
    Initials: "SMW",
    Surname: "Clifford",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4349: {
    Title: "Mr",
    Initials: "S",
    Surname: "Sharma",
    "Primary Club": "Sedbergh School",
    County: "Gloucestershire",
    Country: "Wales",
  },
  4350: {
    Title: "Miss",
    Initials: "JC",
    Surname: "Melling",
    "Primary Club": "Exeter University RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  4351: {
    Title: "Miss",
    Initials: "PK",
    Surname: "Kirk",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4352: {
    Title: "Mr",
    Initials: "L",
    Surname: "Gibbons",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  4354: {
    Title: "Miss",
    Initials: "C H",
    Surname: "Sayers",
    "Primary Club": "Cambridge University RA",
    County: "Gloucestershire",
    Country: "England",
  },
  4355: {
    Title: "Mr",
    Initials: "EA",
    Surname: "Graves",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4357: {
    Title: "Cpl",
    Initials: "T",
    Surname: "Shair",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4358: {
    Title: "Cpl",
    Initials: "LM",
    Surname: "Potter",
    "Primary Club": "Eastbourne SCC",
    County: "Sussex",
    Country: "",
  },
  4359: {
    Title: "Pte",
    Initials: "G",
    Surname: "Evans",
    "Primary Club": "2R Welsh",
    County: "",
    Country: "Wales",
  },
  4360: {
    Title: "Mr",
    Initials: "TM",
    Surname: "Mason",
    "Primary Club": "City of Newcastle RC",
    County: "County Durham",
    Country: "",
  },
  4361: {
    Title: "Mr",
    Initials: "NH",
    Surname: "Heal",
    "Primary Club": "Wiltshire ACF",
    County: "Wiltshire",
    Country: "England",
  },
  4362: {
    Title: "Miss",
    Initials: "ER",
    Surname: "Chamberlain",
    "Primary Club": "Exeter School CCF",
    County: "Devon",
    Country: "England",
  },
  4363: {
    Title: "Mr",
    Initials: "RPF",
    Surname: "Fagan",
    "Primary Club": "Old Sedberghians",
    County: "Cheshire",
    Country: "England",
  },
  4364: {
    Title: "LCpl",
    Initials: "WA",
    Surname: "Shelton",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "England",
  },
  4366: {
    Title: "Jnr Cpl",
    Initials: "HL",
    Surname: "Holah",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "",
  },
  4367: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Surmon",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  4368: {
    Title: "Mr",
    Initials: "AJP",
    Surname: "Paluch",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  4369: {
    Title: "Pte",
    Initials: "C",
    Surname: "Howells",
    "Primary Club": "2R Welsh",
    County: "Gwent",
    Country: "Wales",
  },
  4370: {
    Title: "Cpl",
    Initials: "JH",
    Surname: "Holding",
    "Primary Club": "Athelings",
    County: "",
    Country: "",
  },
  4371: {
    Title: "Miss",
    Initials: "EKB",
    Surname: "Beech",
    "Primary Club": "Bunhill Rifle Club",
    County: "Dorset",
    Country: "England",
  },
  4372: {
    Title: "Cdt",
    Initials: "ASW",
    Surname: "Waterworth",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  4373: {
    Title: "Mr",
    Initials: "CMAG",
    Surname: "Greaves",
    "Primary Club": "Sea Cadets",
    County: "Cheshire",
    Country: "England",
  },
  4374: {
    Title: "Cdt",
    Initials: "DB",
    Surname: "O'Hare",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  4375: {
    Title: "LC",
    Initials: "R",
    Surname: "Laughlin",
    "Primary Club": "Keighley",
    County: "Yorkshire",
    Country: "England",
  },
  4376: {
    Title: "Sjt",
    Initials: "CD",
    Surname: "Bean",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  4377: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Hay",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "Australia",
  },
  4378: {
    Title: "Cpl",
    Initials: "K",
    Surname: "Perrett",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "England",
  },
  4379: {
    Title: "Cdt",
    Initials: "JM",
    Surname: "Price",
    "Primary Club": "Cheltenham College",
    County: "",
    Country: "",
  },
  4380: {
    Title: "Mr",
    Initials: "H",
    Surname: "Tancred-Holmes",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "England",
  },
  4381: {
    Title: "Mr",
    Initials: "JRC",
    Surname: "Stanford-Beale",
    "Primary Club": "Cardinals",
    County: "Berkshire",
    Country: "England",
  },
  4382: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Jordan",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4383: {
    Title: "WO2",
    Initials: "J",
    Surname: "Petrie",
    "Primary Club": "Stamford School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4384: {
    Title: "Sgt",
    Initials: "H",
    Surname: "Martin",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4385: {
    Title: "Mr",
    Initials: "DR",
    Surname: "MacCallum",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4386: {
    Title: "JCpl",
    Initials: "DRS",
    Surname: "Stringer",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4387: {
    Title: "Sgt",
    Initials: "L",
    Surname: "Tweddell",
    "Primary Club": "Durham ATC",
    County: "Northumberland",
    Country: "England",
  },
  4388: {
    Title: "Mr",
    Initials: "J",
    Surname: "Ferrand",
    "Primary Club": "Uppingham Veterans RC",
    County: "",
    Country: "",
  },
  4389: {
    Title: "Mr",
    Initials: "HMES",
    Surname: "Swindon",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  4390: {
    Title: "Ms",
    Initials: "AM",
    Surname: "Strachan",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "Scotland",
  },
  4391: {
    Title: "Cdt LCpl",
    Initials: "SJ",
    Surname: "Pudney",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "England",
  },
  4392: {
    Title: "Mr",
    Initials: "FAN",
    Surname: "Neathercoat",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  4393: {
    Title: "Mr",
    Initials: "RO",
    Surname: "Wade",
    "Primary Club": "Shanklin",
    County: "Hampshire",
    Country: "England",
  },
  4394: {
    Title: "Mr",
    Initials: "JBG",
    Surname: "Shepherd",
    "Primary Club": "Old Guildfordians RC",
    County: "London",
    Country: "Scotland",
  },
  4395: {
    Title: "Mr",
    Initials: "K",
    Surname: "Duffy",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  4396: {
    Title: "Mr",
    Initials: "W",
    Surname: "Berty",
    "Primary Club": "Imperial College RPC",
    County: "Yorkshire",
    Country: "England",
  },
  4397: {
    Title: "L Cpl",
    Initials: "HA",
    Surname: "Brown",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  4398: {
    Title: "Cdt",
    Initials: "TK",
    Surname: "Dodds",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "England",
  },
  4399: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Matthewson",
    "Primary Club": "Tonbridge School",
    County: "",
    Country: "England",
  },
  4400: {
    Title: "Cdt",
    Initials: "BM",
    Surname: "Morton",
    "Primary Club": "Cheltenham College",
    County: "",
    Country: "England",
  },
  4401: {
    Title: "Mr",
    Initials: "BDW",
    Surname: "Parsons",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  4402: {
    Title: "Miss",
    Initials: "KB",
    Surname: "Pelton",
    "Primary Club": "University of London",
    County: "",
    Country: "",
  },
  4403: {
    Title: "Mr",
    Initials: "KW",
    Surname: "Sage",
    "Primary Club": "Old Berkhamstedians",
    County: "Hertfordshire",
    Country: "",
  },
  4404: {
    Title: "Cdt",
    Initials: "Z",
    Surname: "Slater",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "England",
  },
  4405: {
    Title: "Mr",
    Initials: "CJM",
    Surname: "Maxwell",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  4406: {
    Title: "Miss",
    Initials: "HM",
    Surname: "Messam",
    "Primary Club": "Sea Cadets",
    County: "Lancashire",
    Country: "England",
  },
  4407: {
    Title: "Mr",
    Initials: "T",
    Surname: "Newson",
    "Primary Club": "",
    County: "Sussex",
    Country: "England",
  },
  4408: {
    Title: "Mr",
    Initials: "JWS",
    Surname: "Scott",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "",
  },
  4410: {
    Title: "Mrs",
    Initials: "SI",
    Surname: "Webb",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "Scotland",
  },
  4411: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Spink",
    "Primary Club": "NRA",
    County: "Surrey",
    Country: "",
  },
  4412: {
    Title: "Mr",
    Initials: "TB",
    Surname: "Barna",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  4413: {
    Title: "Mr",
    Initials: "G",
    Surname: "Sarin",
    "Primary Club": "Stock Exchange RC",
    County: "London",
    Country: "India",
  },
  4414: {
    Title: "Mr",
    Initials: "M",
    Surname: "Pearson",
    "Primary Club": "27th London Putney RC",
    County: "",
    Country: "England",
  },
  4415: {
    Title: "",
    Initials: "RT",
    Surname: "Cherryman",
    "Primary Club": "NLRC",
    County: "",
    Country: "",
  },
  4416: {
    Title: "Mr",
    Initials: "S",
    Surname: "Hardy",
    "Primary Club": "Spent Cases Shooting Club",
    County: "Sussex",
    Country: "",
  },
  4417: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Wood",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4418: {
    Title: "Mr",
    Initials: "M",
    Surname: "Pozzebon",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4419: {
    Title: "Mr",
    Initials: "NG",
    Surname: "Smith",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4420: {
    Title: "Mr",
    Initials: "BGE",
    Surname: "Picton",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4421: {
    Title: "Mr",
    Initials: "EI",
    Surname: "Cartwright",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  4422: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Whalan",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4423: {
    Title: "Mr",
    Initials: "I",
    Surname: "Francis",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4424: {
    Title: "Mr",
    Initials: "J",
    Surname: "Pyne",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4425: {
    Title: "Mr",
    Initials: "KRN",
    Surname: "Nelson",
    "Primary Club": "PPRA",
    County: "",
    Country: "Canada",
  },
  4427: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Shehan",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4428: {
    Title: "",
    Initials: "JL",
    Surname: "Breeze",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4429: {
    Title: "Ms",
    Initials: "TC",
    Surname: "East",
    "Primary Club": "Canada",
    County: "Lancashire",
    Country: "Canada",
  },
  4430: {
    Title: "Mr",
    Initials: "A",
    Surname: "Rempel",
    "Primary Club": "PPRA",
    County: "",
    Country: "Canada",
  },
  4431: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Grant",
    "Primary Club": "PPRA",
    County: "",
    Country: "Canada",
  },
  4432: {
    Title: "",
    Initials: "F",
    Surname: "Mwema",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4433: {
    Title: "Miss",
    Initials: "ER",
    Surname: "Lewin",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  4434: {
    Title: "",
    Initials: "J",
    Surname: "Muriuki",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4435: {
    Title: "",
    Initials: "J",
    Surname: "Sichei",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4436: {
    Title: "Mr",
    Initials: "JE",
    Surname: "Fraser",
    "Primary Club": "",
    County: "",
    Country: "West Indies",
  },
  4437: {
    Title: "",
    Initials: "J",
    Surname: "Kamau",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4439: {
    Title: "Miss",
    Initials: "CLP",
    Surname: "Peacock",
    "Primary Club": "Glenalmond College",
    County: "Perthshire",
    Country: "",
  },
  4440: {
    Title: "Miss",
    Initials: "M",
    Surname: "McLoughlin",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "England",
  },
  4441: {
    Title: "Mr",
    Initials: "SH",
    Surname: "Hamano",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  4442: {
    Title: "Mr",
    Initials: "SM",
    Surname: "Matsuno",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  4443: {
    Title: "Mr",
    Initials: "TS",
    Surname: "Sugita",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  4444: {
    Title: "Mr",
    Initials: "DH",
    Surname: "Brown",
    "Primary Club": "Australian U25",
    County: "",
    Country: "Australia",
  },
  4445: {
    Title: "Flt Lt",
    Initials: "J",
    Surname: "Fitzpatrick",
    "Primary Club": "RAFTRC",
    County: "Oxfordshire",
    Country: "England",
  },
  4446: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Hissey",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  4447: {
    Title: "Mr",
    Initials: "I",
    Surname: "Miles",
    "Primary Club": "NLRC",
    County: "",
    Country: "",
  },
  4448: {
    Title: "Mr",
    Initials: "SH",
    Surname: "Harada",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  4449: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Fulmer",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  4450: {
    Title: "Mrs",
    Initials: "JA",
    Surname: "Wagland",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4451: {
    Title: "Mr",
    Initials: "J",
    Surname: "Huber",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4452: {
    Title: "Mr",
    Initials: "NA",
    Surname: "Cock",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4453: {
    Title: "Mr",
    Initials: "B",
    Surname: "Langille",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  4454: {
    Title: "Mr",
    Initials: "MJLA",
    Surname: "Landreville",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  4455: {
    Title: "Mr",
    Initials: "MTM",
    Surname: "Macleod",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  4456: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Ashton",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4457: {
    Title: "Mr",
    Initials: "HD",
    Surname: "Werner",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4458: {
    Title: "Mr",
    Initials: "SM",
    Surname: "McGinnis",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  4459: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Grundy",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  4460: {
    Title: "Miss",
    Initials: "R",
    Surname: "Werner",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4461: {
    Title: "Mr",
    Initials: "W",
    Surname: "Quick",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  4462: {
    Title: "Mr",
    Initials: "HH",
    Surname: "Hashizume",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  4463: {
    Title: "Mr",
    Initials: "MT",
    Surname: "Tsunesada",
    "Primary Club": "Japan",
    County: "",
    Country: "Japan",
  },
  4465: {
    Title: "Mr",
    Initials: "LO",
    Surname: "Larrue",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  4466: {
    Title: "Cdt Sgt",
    Initials: "GC",
    Surname: "Coburn",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  4467: {
    Title: "Mr",
    Initials: "ETE",
    Surname: "Egre",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  4468: {
    Title: "Mr",
    Initials: "TA",
    Surname: "Purvis",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  4469: {
    Title: "Miss",
    Initials: "Z",
    Surname: "Latimer",
    "Primary Club": "Irish Rifle Club",
    County: "Ulster",
    Country: "Ireland",
  },
  4470: {
    Title: "Mr",
    Initials: "MP",
    Surname: "Hudson",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "England",
  },
  4471: {
    Title: "Mr",
    Initials: "OCRB",
    Surname: "Ballsdon",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  4472: {
    Title: "Prof",
    Initials: "MJ",
    Surname: "Guille",
    "Primary Club": "Manydown RC",
    County: "Hampshire",
    Country: "Guernsey",
  },
  4473: {
    Title: "Mr",
    Initials: "AT",
    Surname: "Pettigrew",
    "Primary Club": "Queensland",
    County: "",
    Country: "Australia",
  },
  4474: {
    Title: "Mr",
    Initials: "N",
    Surname: "Watson",
    "Primary Club": "Dursley",
    County: "Gloucestershire",
    Country: "England",
  },
  4475: {
    Title: "Mr",
    Initials: "GJ",
    Surname: "Dyson",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  4476: {
    Title: "Mr",
    Initials: "DRG",
    Surname: "Rich",
    "Primary Club": "KRRC",
    County: "",
    Country: "Australia",
  },
  4477: {
    Title: "Mr",
    Initials: "BW",
    Surname: "Schlam",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4478: {
    Title: "Mr",
    Initials: "GC",
    Surname: "Edser",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4479: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Cuthbert",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4480: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Thurtell",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  4482: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Pierre",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "France",
  },
  4483: {
    Title: "LTC",
    Initials: "RM",
    Surname: "Koch",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4484: {
    Title: "Mr",
    Initials: "J",
    Surname: "Kaufmann",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4485: {
    Title: "Mr",
    Initials: "K",
    Surname: "Kroll",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4486: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Le Cheminant",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  4487: {
    Title: "Mr",
    Initials: "BH",
    Surname: "Huget",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4488: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Hayter",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  4489: {
    Title: "Mr",
    Initials: "MM",
    Surname: "Moore",
    "Primary Club": "Athelings",
    County: "",
    Country: "England",
  },
  4490: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Ng",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  4492: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Burger",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  4493: {
    Title: "Mr",
    Initials: "HS",
    Surname: "Bush",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  4494: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Hager",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  4495: {
    Title: "Mr",
    Initials: "X",
    Surname: "Barrette Lecoin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4496: {
    Title: "Cdt",
    Initials: "AT",
    Surname: "Talbot",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4497: {
    Title: "Mr",
    Initials: "R",
    Surname: "Van Den Bossche",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Belgium",
  },
  4498: {
    Title: "Mr",
    Initials: "AG",
    Surname: "Jones",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  4499: {
    Title: "Cdt",
    Initials: "SM",
    Surname: "Montreuil",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4500: {
    Title: "Cdt",
    Initials: "AJH",
    Surname: "Hines",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4501: {
    Title: "Mr",
    Initials: "J",
    Surname: "Hager",
    "Primary Club": "Kathu",
    County: "",
    Country: "South Africa",
  },
  4502: {
    Title: "Cdt",
    Initials: "OS",
    Surname: "Marshall",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "",
  },
  4503: {
    Title: "Mr",
    Initials: "WJ",
    Surname: "Gilpin",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  4504: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Macdonald",
    "Primary Club": "Glasgow University RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  4505: {
    Title: "Ms",
    Initials: "I",
    Surname: "Butland",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4506: {
    Title: "Mr",
    Initials: "BP",
    Surname: "Etienne",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  4507: {
    Title: "Mr",
    Initials: "PF",
    Surname: "Davel",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  4508: {
    Title: "Mr",
    Initials: "ZCY",
    Surname: "Cheung",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "Hong Kong",
  },
  4509: {
    Title: "Cdt",
    Initials: "JC",
    Surname: "Charbonneau",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4510: {
    Title: "Mid",
    Initials: "CE",
    Surname: "Mawson",
    "Primary Club": "RNTRC",
    County: "Norfolk",
    Country: "England",
  },
  4511: {
    Title: "Mr",
    Initials: "CM",
    Surname: "McGuile",
    "Primary Club": "Glasgow University RC",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  4512: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Aube",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4513: {
    Title: "Mr",
    Initials: "S",
    Surname: "Hunt",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  4514: {
    Title: "Miss",
    Initials: "LR",
    Surname: "McKerrell",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  4515: {
    Title: "Ms",
    Initials: "CH",
    Surname: "Hearn",
    "Primary Club": "Canada",
    County: "",
    Country: "",
  },
  4516: {
    Title: "Mr",
    Initials: "AE",
    Surname: "Leigh-Pemberton",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Germany",
  },
  4517: {
    Title: "Mr",
    Initials: "MJL",
    Surname: "Traynor",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "Wales",
  },
  4518: {
    Title: "Ms",
    Initials: "MB",
    Surname: "Burke",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4519: {
    Title: "LCpl",
    Initials: "H",
    Surname: "Abdille",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4520: {
    Title: "Mr",
    Initials: "M",
    Surname: "Eifler",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  4521: {
    Title: "Cpl",
    Initials: "O",
    Surname: "Holloway",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  4522: {
    Title: "Cdt",
    Initials: "AD",
    Surname: "Despres",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4523: {
    Title: "Mr",
    Initials: "DF",
    Surname: "Nesbit",
    "Primary Club": "Darlington R & PC",
    County: "County Durham",
    Country: "England",
  },
  4524: {
    Title: "Cdt",
    Initials: "JWJ",
    Surname: "Annison",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  4525: {
    Title: "Miss",
    Initials: "AM",
    Surname: "Gray",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4526: {
    Title: "Sgt",
    Initials: "RT",
    Surname: "Taylor",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4527: {
    Title: "Mr",
    Initials: "GMO",
    Surname: "Overton",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "",
  },
  4528: {
    Title: "Mr",
    Initials: "BBB",
    Surname: "Bingham",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4529: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Chebii",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4530: {
    Title: "Cdt",
    Initials: "WJ",
    Surname: "Briggs",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  4531: {
    Title: "Sgt",
    Initials: "JE",
    Surname: "Lawson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4532: {
    Title: "Mr",
    Initials: "PO",
    Surname: "Oppelt",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4533: {
    Title: "Miss",
    Initials: "LE",
    Surname: "Stokes",
    "Primary Club": "Athelings",
    County: "Cheshire",
    Country: "England",
  },
  4534: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Easden",
    "Primary Club": "RGS High Wycombe",
    County: "",
    Country: "",
  },
  4535: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Copeland",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4536: {
    Title: "Mr",
    Initials: "TM",
    Surname: "Stevenson",
    "Primary Club": "Darlington R & PC",
    County: "County of Durham",
    Country: "England",
  },
  4537: {
    Title: "Mr",
    Initials: "O",
    Surname: "Rolfe",
    "Primary Club": "NLRC",
    County: "Oxfordshire",
    Country: "England",
  },
  4538: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Houston",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4539: {
    Title: "Mr",
    Initials: "TF",
    Surname: "Fielder",
    "Primary Club": "",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4540: {
    Title: "Mr",
    Initials: "LMD",
    Surname: "Cheek",
    "Primary Club": "Old Guildfordians RC",
    County: "London",
    Country: "Ireland",
  },
  4541: {
    Title: "Sgt",
    Initials: "SP",
    Surname: "Ireson",
    "Primary Club": "",
    County: "Dorset",
    Country: "England",
  },
  4542: {
    Title: "Mr",
    Initials: "MJW",
    Surname: "Willing",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  4543: {
    Title: "Cdt",
    Initials: "NJ",
    Surname: "Bass",
    "Primary Club": "Wellington College",
    County: "Middlesex",
    Country: "England",
  },
  4544: {
    Title: "Mr",
    Initials: "CC",
    Surname: "Cockerill",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  4545: {
    Title: "Spr",
    Initials: "L",
    Surname: "Rooney",
    "Primary Club": "ATSC",
    County: "Derbyshire",
    Country: "England",
  },
  4546: {
    Title: "Cdt",
    Initials: "B",
    Surname: "McLain",
    "Primary Club": "Canada",
    County: "British Columbia",
    Country: "Canada",
  },
  4547: {
    Title: "Sgt",
    Initials: "RK",
    Surname: "Littleproud",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  4548: {
    Title: "Mr",
    Initials: "FCD",
    Surname: "Flanagan",
    "Primary Club": "CURA",
    County: "",
    Country: "Scotland",
  },
  4549: {
    Title: "Cdt",
    Initials: "EEP",
    Surname: "Pomeroy",
    "Primary Club": "Fettes College",
    County: "County of London",
    Country: "England",
  },
  4550: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Bell",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  4551: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Ekiru",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4552: {
    Title: "Mr",
    Initials: "WJD",
    Surname: "Dineen",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "",
  },
  4553: {
    Title: "Mr",
    Initials: "LID",
    Surname: "Alban-Davies",
    "Primary Club": "Tonbridge School",
    County: "",
    Country: "Wales",
  },
  4554: {
    Title: "Ms",
    Initials: "SM",
    Surname: "Williams",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  4555: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Yu",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  4556: {
    Title: "",
    Initials: "W",
    Surname: "Dooley",
    "Primary Club": "Stamford School",
    County: "Lincolnshire",
    Country: "",
  },
  4557: {
    Title: "Cdt",
    Initials: "S",
    Surname: "James",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  4558: {
    Title: "Mr",
    Initials: "G",
    Surname: "Meijer",
    "Primary Club": "KSV Rotterdam",
    County: "",
    Country: "Netherlands",
  },
  4559: {
    Title: "Mr",
    Initials: "DI",
    Surname: "Lekapana",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4560: {
    Title: "Cpl",
    Initials: "L",
    Surname: "Sawe",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4561: {
    Title: "Mr",
    Initials: "JY",
    Surname: "Savage",
    "Primary Club": "Newcastle Uni RC",
    County: "Oxfordshire",
    Country: "",
  },
  4562: {
    Title: "Mr",
    Initials: "RH",
    Surname: "Young",
    "Primary Club": "Glasgow University RC",
    County: "Yorkshire",
    Country: "England",
  },
  4563: {
    Title: "Mr",
    Initials: "BA",
    Surname: "Ross",
    "Primary Club": "Epsom College",
    County: "County of London",
    Country: "England",
  },
  4564: {
    Title: "Sgt",
    Initials: "AJW",
    Surname: "Woodward",
    "Primary Club": "Exeter School CCF",
    County: "Devon",
    Country: "",
  },
  4565: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Lunn",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  4566: {
    Title: "Sgt",
    Initials: "AS",
    Surname: "Davis",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "",
  },
  4567: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Venter",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  4568: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Drape",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  4569: {
    Title: "Cdt",
    Initials: "OG",
    Surname: "Graziosi",
    "Primary Club": "Wellington College",
    County: "Middlesex",
    Country: "England",
  },
  4570: {
    Title: "Mr",
    Initials: "TR",
    Surname: "Ward",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  4571: {
    Title: "Mr",
    Initials: "TAN",
    Surname: "Dowd",
    "Primary Club": "NRA",
    County: "Surrey",
    Country: "England",
  },
  4572: {
    Title: "Mr",
    Initials: "LK",
    Surname: "Kucharczyk",
    "Primary Club": "Clifton College",
    County: "Somerset",
    Country: "",
  },
  4573: {
    Title: "Mr",
    Initials: "NJ",
    Surname: "Hall",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  4574: {
    Title: "Miss",
    Initials: "T",
    Surname: "Smith",
    "Primary Club": "Cambs ACF",
    County: "Cambridgeshire",
    Country: "England",
  },
  4575: {
    Title: "Mr",
    Initials: "MAG",
    Surname: "Woodrow",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  4576: {
    Title: "Mr",
    Initials: "ADWH",
    Surname: "Wheeler",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  4577: {
    Title: "Sgt",
    Initials: "A",
    Surname: "Todd",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4578: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Mbugua",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4579: {
    Title: "Sgt",
    Initials: "J",
    Surname: "Dixon",
    "Primary Club": "ACF",
    County: "",
    Country: "England",
  },
  4580: {
    Title: "Sgt",
    Initials: "T",
    Surname: "Biggins",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  4581: {
    Title: "Sjt",
    Initials: "C",
    Surname: "Naumann",
    "Primary Club": "Glos ACF",
    County: "Gloucestershire",
    Country: "England",
  },
  4582: {
    Title: "Sgt",
    Initials: "S",
    Surname: "Pevler",
    "Primary Club": "Notts ACF",
    County: "Nottinghamshire",
    Country: "England",
  },
  4583: {
    Title: "Mr",
    Initials: "PHC",
    Surname: "Costello",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  4584: {
    Title: "Mr",
    Initials: "THD",
    Surname: "Dodds",
    "Primary Club": "Royal Navy",
    County: "Kent",
    Country: "Wales",
  },
  4585: {
    Title: "LCpl",
    Initials: "J",
    Surname: "Gair",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  4586: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Towler",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  4587: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Davidson",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  4588: {
    Title: "Mr",
    Initials: "LVW",
    Surname: "Brand",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  4589: {
    Title: "LCpl",
    Initials: "F",
    Surname: "Mohammed",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  4590: {
    Title: "Mr",
    Initials: "DHP",
    Surname: "Phillips",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "Scotland",
  },
  4591: {
    Title: "Mr",
    Initials: "NRT",
    Surname: "Lennon",
    "Primary Club": "Dungannon",
    County: "Ulster",
    Country: "Ireland",
  },
  4592: {
    Title: "Cdt",
    Initials: "AS",
    Surname: "Voden",
    "Primary Club": "Cheltenham College",
    County: "",
    Country: "",
  },
  4593: {
    Title: "AB",
    Initials: "IG",
    Surname: "Elbrow",
    "Primary Club": "RNTRC",
    County: "",
    Country: "",
  },
  4594: {
    Title: "Mr",
    Initials: "LWHR",
    Surname: "Stewart",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4595: {
    Title: "Mr",
    Initials: "TLB",
    Surname: "Burton",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  4596: {
    Title: "Dr",
    Initials: "DA",
    Surname: "Firth",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  4597: {
    Title: "Miss",
    Initials: "CS",
    Surname: "Baltesz",
    "Primary Club": "Clifton College",
    County: "Gloucestershire",
    Country: "",
  },
  4598: {
    Title: "Cpl",
    Initials: "FM",
    Surname: "King",
    "Primary Club": "Wellington College",
    County: "Kent",
    Country: "Scotland",
  },
  4599: {
    Title: "Cpl",
    Initials: "T",
    Surname: "Wynn",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "",
  },
  4600: {
    Title: "Mr",
    Initials: "LA",
    Surname: "Bridle",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "England",
  },
  4601: {
    Title: "Mr",
    Initials: "WO",
    Surname: "Tooth",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  4602: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Higson",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "Wales",
  },
  4603: {
    Title: "Mr",
    Initials: "AM",
    Surname: "Sadler",
    "Primary Club": "BYSA",
    County: "Essex",
    Country: "England",
  },
  4604: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Manchip",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "",
  },
  4605: {
    Title: "Miss",
    Initials: "AF",
    Surname: "Foggett",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  4606: {
    Title: "Mr",
    Initials: "MC",
    Surname: "Ayers",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "",
  },
  4607: {
    Title: "Mr",
    Initials: "ID",
    Surname: "Chapman",
    "Primary Club": "Chelmsford 1944",
    County: "Essex",
    Country: "England",
  },
  4608: {
    Title: "Mr",
    Initials: "J",
    Surname: "Howe",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  4609: {
    Title: "Mr",
    Initials: "YJ",
    Surname: "Joubel",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  4610: {
    Title: "Mr",
    Initials: "P",
    Surname: "Morgan",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  4611: {
    Title: "Mr",
    Initials: "QH",
    Surname: "Nguyen",
    "Primary Club": "France",
    County: "",
    Country: "France",
  },
  4612: {
    Title: "Mr",
    Initials: "SJM",
    Surname: "Rowe",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  4613: {
    Title: "Mr",
    Initials: "IJ",
    Surname: "Spruce",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "",
  },
  4614: {
    Title: "Mr",
    Initials: "IJ",
    Surname: "Spruce",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "",
  },
  4615: {
    Title: "Dr",
    Initials: "JG",
    Surname: "Sullivan",
    "Primary Club": "Chelmsford 1944",
    County: "Buckinghamshire",
    Country: "",
  },
  4616: {
    Title: "Mr",
    Initials: "J",
    Surname: "Chambers",
    "Primary Club": "NRA",
    County: "Berkshire",
    Country: "England",
  },
  4617: {
    Title: "Mr",
    Initials: "GM",
    Surname: "Clark",
    "Primary Club": "Marlow",
    County: "",
    Country: "England",
  },
  4618: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Cookson",
    "Primary Club": "Christchurch GC",
    County: "Hampshire",
    Country: "England",
  },
  4619: {
    Title: "Mr",
    Initials: "PR",
    Surname: "May",
    "Primary Club": "Oxford RC",
    County: "Oxfordshire",
    Country: "",
  },
  4620: {
    Title: "Mr",
    Initials: "CC",
    Surname: "Jones",
    "Primary Club": "NRA",
    County: "Oxfordshire",
    Country: "",
  },
  4621: {
    Title: "Mr",
    Initials: "J",
    Surname: "Ford",
    "Primary Club": "Surrey RA",
    County: "Gloucestershire",
    Country: "Scotland",
  },
  4622: {
    Title: "Mr",
    Initials: "TE",
    Surname: "Brankin-Frisby",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  4623: {
    Title: "Mr",
    Initials: "MG",
    Surname: "Faulkner",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  4624: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Carr",
    "Primary Club": "Chichester RC",
    County: "Sussex",
    Country: "England",
  },
  4625: {
    Title: "Mr",
    Initials: "B",
    Surname: "Stoichescu",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  4626: {
    Title: "Mr",
    Initials: "FGAC",
    Surname: "Becker",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "England",
  },
  4627: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Ratcliff",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  4628: {
    Title: "Cdt",
    Initials: "JF",
    Surname: "Meyer",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  4629: {
    Title: "Cdt",
    Initials: "WFC",
    Surname: "Heseltine-Carp",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  4630: {
    Title: "Miss",
    Initials: "E",
    Surname: "Dixon",
    "Primary Club": "Gloucestershire RC",
    County: "Gloucestershire",
    Country: "England",
  },
  4631: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Warburton",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  4632: {
    Title: "Mr",
    Initials: "TE",
    Surname: "Parker",
    "Primary Club": "Imperial College RPC",
    County: "Cambridgeshire",
    Country: "",
  },
  4633: {
    Title: "Mr",
    Initials: "CAY",
    Surname: "Young",
    "Primary Club": "Oxford University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  4634: {
    Title: "Mr",
    Initials: "JN",
    Surname: "Campbell",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "Scotland",
  },
  4635: {
    Title: "Mr",
    Initials: "D",
    Surname: "Moore",
    "Primary Club": "Bunhill Rifle Club",
    County: "Hereford & Worcs",
    Country: "England",
  },
  4636: {
    Title: "Mr",
    Initials: "KKW",
    Surname: "Lai",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "",
  },
  4637: {
    Title: "Mr",
    Initials: "CDCS",
    Surname: "Smith",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  4638: {
    Title: "Mrs",
    Initials: "OG",
    Surname: "Goberville",
    "Primary Club": "Normandy RC",
    County: "",
    Country: "France",
  },
  4729: {
    Title: "Mr",
    Initials: "CGC",
    Surname: "Costello",
    "Primary Club": "Glasgow University RC",
    County: "East of Scotland",
    Country: "New Zealand",
  },
  5000: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Aantjes",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5001: {
    Title: "Mr",
    Initials: "C",
    Surname: "Akers",
    "Primary Club": "Lee Enfield RA",
    County: "",
    Country: "",
  },
  5002: {
    Title: "Mr",
    Initials: "BHA",
    Surname: "Allen",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  5003: {
    Title: "Mr",
    Initials: "FJ",
    Surname: "Anderson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5004: {
    Title: "Dr",
    Initials: "AI",
    Surname: "Ashworth",
    "Primary Club": "Dursley",
    County: "Gloucestershire",
    Country: "England",
  },
  5005: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Badman",
    "Primary Club": "Imperial College RPC",
    County: "County of London",
    Country: "England",
  },
  5006: {
    Title: "Mr",
    Initials: "AB",
    Surname: "Banea",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5007: {
    Title: "Mr",
    Initials: "R",
    Surname: "Bennett",
    "Primary Club": "SCRA",
    County: "Sussex",
    Country: "England",
  },
  5008: {
    Title: "Mr",
    Initials: "MF",
    Surname: "Boucher",
    "Primary Club": "NLRC",
    County: "Gloucestershire",
    Country: "Ireland",
  },
  5009: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Bowey",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  5010: {
    Title: "Mr",
    Initials: "CM",
    Surname: "Bowring",
    "Primary Club": "OCRA",
    County: "Gloucestershire",
    Country: "England",
  },
  5011: {
    Title: "Mr",
    Initials: "IW",
    Surname: "Boxall",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  5012: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Boyle",
    "Primary Club": "Sedgemoor TSC",
    County: "Somerset",
    Country: "England",
  },
  5013: {
    Title: "Mr",
    Initials: "AJB",
    Surname: "Brennan",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5014: {
    Title: "Mr",
    Initials: "S",
    Surname: "Brewer-Marchant",
    "Primary Club": "Surrey RA",
    County: "Yorkshire",
    Country: "England",
  },
  5015: {
    Title: "Mr",
    Initials: "M",
    Surname: "Bumford",
    "Primary Club": "Welsh RA",
    County: "Gloucestershire",
    Country: "Wales",
  },
  5016: {
    Title: "Cpl",
    Initials: "JAC",
    Surname: "Burgess",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5017: {
    Title: "CSgt",
    Initials: "JA",
    Surname: "Butcher",
    "Primary Club": "Cambs ACF",
    County: "Cambridgeshire",
    Country: "England",
  },
  5018: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Butt",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  5019: {
    Title: "Mr",
    Initials: "ZPC",
    Surname: "Cairns",
    "Primary Club": "",
    County: "Ulster",
    Country: "Ireland",
  },
  5020: {
    Title: "Sjt",
    Initials: "J",
    Surname: "Calcutt",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5021: {
    Title: "CI",
    Initials: "JL",
    Surname: "Calcutt",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5022: {
    Title: "Mr",
    Initials: "MJC",
    Surname: "Cameron",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5023: {
    Title: "Ms",
    Initials: "ESC",
    Surname: "Campbell",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "Canada",
  },
  5024: {
    Title: "Mr",
    Initials: "MS",
    Surname: "Cavie",
    "Primary Club": "Bullet Lodge RC",
    County: "Essex",
    Country: "England",
  },
  5025: {
    Title: "Mr",
    Initials: "MSC",
    Surname: "Chandler",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "England",
  },
  5026: {
    Title: "Miss",
    Initials: "BTYC",
    Surname: "Cheung",
    "Primary Club": "Epsom College",
    County: "",
    Country: "England",
  },
  5027: {
    Title: "Mr",
    Initials: "D",
    Surname: "Clark",
    "Primary Club": "ATSC",
    County: "County of Durham",
    Country: "England",
  },
  5028: {
    Title: "Miss",
    Initials: "AJ",
    Surname: "Clarke",
    "Primary Club": "Surrey RA",
    County: "Nottinghamshire",
    Country: "England",
  },
  5029: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Clegg",
    "Primary Club": "",
    County: "Ulster",
    Country: "Ireland",
  },
  5030: {
    Title: "Mr",
    Initials: "JFC",
    Surname: "Collis",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5031: {
    Title: "Mr",
    Initials: "TABC",
    Surname: "Coonan",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "England",
  },
  5032: {
    Title: "Cdt",
    Initials: "SA",
    Surname: "Cox",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "England",
  },
  5033: {
    Title: "Lt",
    Initials: "A",
    Surname: "Crockett",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5034: {
    Title: "Cdt",
    Initials: "PC",
    Surname: "De Souza",
    "Primary Club": "Athelings",
    County: "Kent",
    Country: "England",
  },
  5035: {
    Title: "Mr",
    Initials: "P",
    Surname: "Dean",
    "Primary Club": "LMRA",
    County: "Middlesex",
    Country: "England",
  },
  5036: {
    Title: "Mr",
    Initials: "Q",
    Surname: "Den Rooijen",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5037: {
    Title: "Mr",
    Initials: "DMD",
    Surname: "Dineen",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "",
  },
  5038: {
    Title: "Mr",
    Initials: "TA",
    Surname: "Dowley",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "England",
  },
  5039: {
    Title: "Mr",
    Initials: "MTJ",
    Surname: "Downes",
    "Primary Club": "RGS Guildford",
    County: "",
    Country: "",
  },
  5040: {
    Title: "Mr",
    Initials: "SRE",
    Surname: "Ewing",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5041: {
    Title: "Cdt",
    Initials: "BA",
    Surname: "Fenn",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "",
  },
  5042: {
    Title: "Sgt",
    Initials: "L",
    Surname: "Fennell",
    "Primary Club": "Black Watch ACF",
    County: "Perthshire",
    Country: "England",
  },
  5043: {
    Title: "Mr",
    Initials: "KE",
    Surname: "Ferguson",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  5044: {
    Title: "Dr",
    Initials: "HM",
    Surname: "Fisher",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  5045: {
    Title: "Miss",
    Initials: "BR",
    Surname: "Fraser-Burn",
    "Primary Club": "OGRE",
    County: "Suffolk",
    Country: "Scotland",
  },
  5046: {
    Title: "",
    Initials: "GP",
    Surname: "Gannon",
    "Primary Club": "OURC",
    County: "Lancashire",
    Country: "England",
  },
  5047: {
    Title: "Miss",
    Initials: "H",
    Surname: "Gedney",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "England",
  },
  5048: {
    Title: "Mr",
    Initials: "MG",
    Surname: "Godin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5049: {
    Title: "Mr",
    Initials: "P",
    Surname: "Goubert",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5050: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Gow",
    "Primary Club": "Fife & Kinross FBRC",
    County: "",
    Country: "Scotland",
  },
  5051: {
    Title: "Mr",
    Initials: "RSG",
    Surname: "Graham",
    "Primary Club": "Athelings",
    County: "West of Scotland",
    Country: "Scotland",
  },
  5052: {
    Title: "Lcpl",
    Initials: "CG",
    Surname: "Gray",
    "Primary Club": "Oratory School",
    County: "",
    Country: "",
  },
  5053: {
    Title: "Ms",
    Initials: "N",
    Surname: "Greenleaf",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5054: {
    Title: "Mr",
    Initials: "MF",
    Surname: "Halloran",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5055: {
    Title: "Sgt",
    Initials: "L",
    Surname: "Hamilton",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  5056: {
    Title: "Cfn",
    Initials: "SJ",
    Surname: "Hartnett",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  5057: {
    Title: "Miss",
    Initials: "SH",
    Surname: "Hassan",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5058: {
    Title: "Cpl",
    Initials: "F",
    Surname: "Hawes",
    "Primary Club": "CCRS",
    County: "Suffolk",
    Country: "England",
  },
  5059: {
    Title: "Mr",
    Initials: "B",
    Surname: "Heatley",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  5060: {
    Title: "Mr",
    Initials: "FM",
    Surname: "Herve",
    "Primary Club": "CPC France",
    County: "",
    Country: "",
  },
  5061: {
    Title: "LCpl",
    Initials: "SA",
    Surname: "Hill",
    "Primary Club": "ATSC",
    County: "Co Antrim",
    Country: "Northern Ireland",
  },
  5062: {
    Title: "Mr",
    Initials: "R",
    Surname: "Hodgson-Jones",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5063: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Horder",
    "Primary Club": "Sydney RC",
    County: "",
    Country: "",
  },
  5064: {
    Title: "Mr",
    Initials: "NE",
    Surname: "Hudleston",
    "Primary Club": "Bedfordians RC",
    County: "",
    Country: "",
  },
  5065: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Hunting",
    "Primary Club": "Uppingham Veterans RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5066: {
    Title: "",
    Initials: "K",
    Surname: "Jahnke",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5067: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "James",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  5068: {
    Title: "LCpl",
    Initials: "C",
    Surname: "Jowett",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5069: {
    Title: "Ms",
    Initials: "G",
    Surname: "Kaile",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  5070: {
    Title: "Mr",
    Initials: "JK",
    Surname: "Kelleher",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  5071: {
    Title: "Mr",
    Initials: "NNT",
    Surname: "Kerins",
    "Primary Club": "Guernsey RC",
    County: "Northumberland",
    Country: "Guernsey",
  },
  5072: {
    Title: "Mr",
    Initials: "RD",
    Surname: "Kierstead",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  5073: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Kuppen",
    "Primary Club": "NLRC",
    County: "",
    Country: "Netherlands",
  },
  5074: {
    Title: "",
    Initials: "R",
    Surname: "Lang",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5075: {
    Title: "Mr",
    Initials: "WHCL",
    Surname: "Ledsham",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "",
  },
  5076: {
    Title: "Mr",
    Initials: "MLH",
    Surname: "Lee",
    "Primary Club": "Oxford University RC",
    County: "",
    Country: "Hong Kong",
  },
  5077: {
    Title: "LCpl",
    Initials: "HYH",
    Surname: "Lei",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5078: {
    Title: "Cdt",
    Initials: "DW",
    Surname: "Leitch",
    "Primary Club": "Athelings",
    County: "Berkshire",
    Country: "England",
  },
  5079: {
    Title: "Mr",
    Initials: "SL",
    Surname: "Lévesque",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5080: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Lowes",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5081: {
    Title: "Miss",
    Initials: "PG",
    Surname: "Macdonald",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5082: {
    Title: "Col",
    Initials: "RH",
    Surname: "MacKeith",
    "Primary Club": "Old Epsomian RC",
    County: "",
    Country: "",
  },
  5083: {
    Title: "Mr",
    Initials: "M",
    Surname: "Mann",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5084: {
    Title: "Miss",
    Initials: "E",
    Surname: "Marsh",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "",
  },
  5085: {
    Title: "Cdt",
    Initials: "HG",
    Surname: "Martin",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5086: {
    Title: "Mr",
    Initials: "MM",
    Surname: "Matloob",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5087: {
    Title: "Miss",
    Initials: "AL",
    Surname: "Matthews",
    "Primary Club": "Newcastle Uni RC",
    County: "Cheshire",
    Country: "Ireland",
  },
  5088: {
    Title: "Mr",
    Initials: "DEM",
    Surname: "Matthews",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  5089: {
    Title: "Miss",
    Initials: "M",
    Surname: "Mcaleenan",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5090: {
    Title: "Miss",
    Initials: "KE",
    Surname: "McGuigan",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "",
  },
  5091: {
    Title: "Miss",
    Initials: "S",
    Surname: "Mcintosh",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5092: {
    Title: "Miss",
    Initials: "RA",
    Surname: "McNaught",
    "Primary Club": "Greshams RC",
    County: "",
    Country: "Australia",
  },
  5093: {
    Title: "Mr",
    Initials: "N",
    Surname: "Meintjes",
    "Primary Club": "NRA",
    County: "Hampshire",
    Country: "South Africa",
  },
  5094: {
    Title: "Ms",
    Initials: "AM",
    Surname: "Michano",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5095: {
    Title: "Mr",
    Initials: "TEM",
    Surname: "Mills",
    "Primary Club": "Oxford University RC",
    County: "Kent",
    Country: "England",
  },
  5096: {
    Title: "Mr",
    Initials: "J",
    Surname: "Minien",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  5097: {
    Title: "Mr",
    Initials: "AI",
    Surname: "Morgan",
    "Primary Club": "St Johns School",
    County: "Surrey",
    Country: "England",
  },
  5098: {
    Title: "Mr",
    Initials: "HMS",
    Surname: "Mott",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  5099: {
    Title: "Cdt",
    Initials: "EN",
    Surname: "Ng",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5100: {
    Title: "Gdsm",
    Initials: "L",
    Surname: "Nicholson",
    "Primary Club": "ATSC",
    County: "Derbyshire",
    Country: "England",
  },
  5101: {
    Title: "Mr",
    Initials: "TO",
    Surname: "Orpen-Smellie",
    "Primary Club": "Athelings",
    County: "Essex",
    Country: "England",
  },
  5102: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Palmer-Makowski",
    "Primary Club": "RGS Guildford",
    County: "",
    Country: "",
  },
  5103: {
    Title: "Ms",
    Initials: "L",
    Surname: "Paulson",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5104: {
    Title: "Spr",
    Initials: "CA",
    Surname: "Pearce",
    "Primary Club": "ATSC",
    County: "",
    Country: "",
  },
  5105: {
    Title: "Mr",
    Initials: "AGPP",
    Surname: "Poulter",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "England",
  },
  5106: {
    Title: "Mr",
    Initials: "S",
    Surname: "Poultney",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "England",
  },
  5107: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Ramsden",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5108: {
    Title: "Ms",
    Initials: "WF",
    Surname: "Reid",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  5109: {
    Title: "Cdt",
    Initials: "Z",
    Surname: "Rudge",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "",
  },
  5110: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Salji",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "Ireland",
  },
  5111: {
    Title: "Mr",
    Initials: "SJB",
    Surname: "Sander",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  5112: {
    Title: "Miss",
    Initials: "M",
    Surname: "Sanders",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5113: {
    Title: "",
    Initials: "W",
    Surname: "Schaffner",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5114: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Shelton",
    "Primary Club": "Langar RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  5115: {
    Title: "Mr",
    Initials: "VAS",
    Surname: "Shenshin",
    "Primary Club": "Fettes College",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5117: {
    Title: "Mr",
    Initials: "MS",
    Surname: "Smith",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5118: {
    Title: "LCpl",
    Initials: "TJ",
    Surname: "Smith",
    "Primary Club": "Sedbergh School",
    County: "Cheshire",
    Country: "England",
  },
  5119: {
    Title: "Cdt",
    Initials: "WJC",
    Surname: "Smith",
    "Primary Club": "Oundle School",
    County: "Norfolk",
    Country: "England",
  },
  5120: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Smith-Maxwell",
    "Primary Club": "Glenalmond College",
    County: "Perthshire",
    Country: "",
  },
  5121: {
    Title: "Mrs",
    Initials: "A",
    Surname: "Soelzer-Hermes",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5122: {
    Title: "Mr",
    Initials: "BN",
    Surname: "Southern",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5123: {
    Title: "Sjt",
    Initials: "A",
    Surname: "Swaffield",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  5124: {
    Title: "Mr",
    Initials: "JAT",
    Surname: "Taylor",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5125: {
    Title: "Sjt",
    Initials: "M",
    Surname: "Taylor",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5126: {
    Title: "Mr",
    Initials: "O",
    Surname: "Taylor",
    "Primary Club": "Shrewsbury School CCF",
    County: "Cheshire",
    Country: "England",
  },
  5127: {
    Title: "Miss",
    Initials: "AT",
    Surname: "Tessier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5128: {
    Title: "Mr",
    Initials: "BT",
    Surname: "Thebeau",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5129: {
    Title: "Mrs",
    Initials: "DA",
    Surname: "Thompson",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5130: {
    Title: "Miss",
    Initials: "LC",
    Surname: "Thompson",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5131: {
    Title: "Mr",
    Initials: "RWT",
    Surname: "Thornton",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "",
  },
  5132: {
    Title: "Mr",
    Initials: "RP",
    Surname: "Thorp",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5133: {
    Title: "Cuo",
    Initials: "J",
    Surname: "Tordoff",
    "Primary Club": "Athelings",
    County: "Northumberland",
    Country: "England",
  },
  5134: {
    Title: "Mr",
    Initials: "ST",
    Surname: "Treacy",
    "Primary Club": "Old Framlinghamians RC",
    County: "Buckinghamshire",
    Country: "England",
  },
  5135: {
    Title: "Mr",
    Initials: "JT",
    Surname: "Truelove",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5136: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Waite",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5137: {
    Title: "Mr",
    Initials: "I",
    Surname: "Webb",
    "Primary Club": "LMRA",
    County: "Kent",
    Country: "",
  },
  5138: {
    Title: "Mr",
    Initials: "AS",
    Surname: "Webber",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5139: {
    Title: "Mr",
    Initials: "FSW",
    Surname: "Whitehead",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "England",
  },
  5140: {
    Title: "Mr",
    Initials: "CH",
    Surname: "Wijsman",
    "Primary Club": "Old Wellingtonians",
    County: "Buckinghamshire",
    Country: "England",
  },
  5141: {
    Title: "Mr",
    Initials: "CBW",
    Surname: "Wilcox",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5142: {
    Title: "Lcpl",
    Initials: "REW",
    Surname: "Williams",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5143: {
    Title: "Cdt",
    Initials: "JF",
    Surname: "Wimpeney",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5144: {
    Title: "Mr",
    Initials: "WJ",
    Surname: "Woodward",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  5145: {
    Title: "Bdr",
    Initials: "D",
    Surname: "Yhia",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5146: {
    Title: "Mr",
    Initials: "HGW",
    Surname: "Hawes",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  5147: {
    Title: "Mrs",
    Initials: "JE",
    Surname: "Dickson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Lincolnshire",
    Country: "England",
  },
  5148: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Mackenzie",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  5149: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Haasbroek",
    "Primary Club": "Witwatersrand",
    County: "",
    Country: "South Africa",
  },
  5150: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Bale",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5151: {
    Title: "Miss",
    Initials: "JL",
    Surname: "Bale",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5152: {
    Title: "Mr",
    Initials: "FB",
    Surname: "Bezeau",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5153: {
    Title: "Mr",
    Initials: "PK",
    Surname: "Geelmuyden",
    "Primary Club": "Grove Small Arms",
    County: "Suffolk",
    Country: "England",
  },
  5154: {
    Title: "Mr",
    Initials: "M",
    Surname: "Miles",
    "Primary Club": "Pennine SSA",
    County: "Norfolk",
    Country: "England",
  },
  5155: {
    Title: "Mr",
    Initials: "SD",
    Surname: "Baldock",
    "Primary Club": "Chelmsford 1944",
    County: "Essex",
    Country: "England",
  },
  5156: {
    Title: "Mr",
    Initials: "PBI",
    Surname: "Milnes",
    "Primary Club": "",
    County: "Hampshire",
    Country: "Great Britain",
  },
  5157: {
    Title: "Mr",
    Initials: "S",
    Surname: "Bailey",
    "Primary Club": "",
    County: "Suffolk",
    Country: "",
  },
  5158: {
    Title: "Mrs",
    Initials: "E",
    Surname: "Davis",
    "Primary Club": "Dorset Riflemen",
    County: "Dorset",
    Country: "",
  },
  5159: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Maher",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  5160: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Wood",
    "Primary Club": "Katandra RC",
    County: "",
    Country: "Australia",
  },
  5161: {
    Title: "Mr",
    Initials: "BH",
    Surname: "Hayes",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  5162: {
    Title: "Mr",
    Initials: "RCH",
    Surname: "Courtney",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5163: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Bailey",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5164: {
    Title: "",
    Initials: "P",
    Surname: "Wagner",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5165: {
    Title: "Mr",
    Initials: "RF",
    Surname: "Thorp",
    "Primary Club": "",
    County: "Sussex",
    Country: "England",
  },
  5166: {
    Title: "Mr",
    Initials: "P F M",
    Surname: "Ryan",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "Jersey",
  },
  5167: {
    Title: "Pte",
    Initials: "L",
    Surname: "Bennett",
    "Primary Club": "ATSC",
    County: "Lancashire",
    Country: "England",
  },
  5168: {
    Title: "Mr",
    Initials: "JDH",
    Surname: "Hearn",
    "Primary Club": "Canada",
    County: "",
    Country: "",
  },
  5169: {
    Title: "Mr",
    Initials: "DTA",
    Surname: "Cobb",
    "Primary Club": "Wandsworth FBRC",
    County: "London",
    Country: "England",
  },
  5170: {
    Title: "Mr.",
    Initials: "MT",
    Surname: "TrŽpanier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5171: {
    Title: "Air Cdre",
    Initials: "JAF",
    Surname: "Ford",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "England",
  },
  5172: {
    Title: "Cadet",
    Initials: "M",
    Surname: "Rainier-Kirkwood",
    "Primary Club": "Athelings",
    County: "Berkshire",
    Country: "England",
  },
  5173: {
    Title: "C/Cpl",
    Initials: "TMK",
    Surname: "Hampson",
    "Primary Club": "Framlingham College CCF",
    County: "Suffolk",
    Country: "England",
  },
  5174: {
    Title: "Sgt",
    Initials: "A",
    Surname: "Dodds",
    "Primary Club": "Durham ATC",
    County: "Northumberland",
    Country: "England",
  },
  5175: {
    Title: "Mr",
    Initials: "NF",
    Surname: "Flint",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5176: {
    Title: "Mr",
    Initials: "MJB",
    Surname: "Barker",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "",
  },
  5177: {
    Title: "Miss",
    Initials: "IMC",
    Surname: "Cubitt",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5178: {
    Title: "Mr",
    Initials: "J",
    Surname: "Lei",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Hong Kong",
  },
  5179: {
    Title: "Mr",
    Initials: "BP",
    Surname: "Creane",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "Ireland",
  },
  5180: {
    Title: "Mr",
    Initials: "CMT",
    Surname: "Truman",
    "Primary Club": "Nottinghamshire RC",
    County: "Nottinghamshire",
    Country: "England",
  },
  5181: {
    Title: "Miss",
    Initials: "CM",
    Surname: "Jones",
    "Primary Club": "ATSC",
    County: "",
    Country: "Wales",
  },
  5182: {
    Title: "Sgt",
    Initials: "JH",
    Surname: "Howard",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5183: {
    Title: "Miss",
    Initials: "CLM",
    Surname: "Nettie",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5184: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Day",
    "Primary Club": "RNTRC",
    County: "",
    Country: "Canada",
  },
  5185: {
    Title: "Cpl",
    Initials: "CI",
    Surname: "Walker",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5186: {
    Title: "MR",
    Initials: "WDLM",
    Surname: "De La Mare",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  5187: {
    Title: "Miss",
    Initials: "CE",
    Surname: "Davies",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  5188: {
    Title: "Mr",
    Initials: "EJT",
    Surname: "Thomas",
    "Primary Club": "Imperial College RPC",
    County: "Surrey",
    Country: "England",
  },
  5189: {
    Title: "Mr.",
    Initials: "B",
    Surname: "Chiang",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5190: {
    Title: "Mr",
    Initials: "SDKM",
    Surname: "Mansley",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  5191: {
    Title: "Mr",
    Initials: "B",
    Surname: "Hendrikz",
    "Primary Club": "",
    County: "",
    Country: "South Africa",
  },
  5192: {
    Title: "Lt",
    Initials: "B",
    Surname: "Fung",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5193: {
    Title: "Maj",
    Initials: "JME",
    Surname: "Cook",
    "Primary Club": "ARA",
    County: "Hampshire",
    Country: "England",
  },
  5194: {
    Title: "Mr",
    Initials: "AM",
    Surname: "Mallette",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5195: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Ramage",
    "Primary Club": "Welsh RA",
    County: "Nottinghamshire",
    Country: "Wales",
  },
  5196: {
    Title: "Mr",
    Initials: "MM",
    Surname: "Makin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5197: {
    Title: "Mr",
    Initials: "PG",
    Surname: "Gilleece",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  5198: {
    Title: "Mr.",
    Initials: "L.M.",
    Surname: "Wilford",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5199: {
    Title: "",
    Initials: "JS",
    Surname: "Fourie",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  5200: {
    Title: "Mr",
    Initials: "MS",
    Surname: "Shaw",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "Scotland",
  },
  5201: {
    Title: "Mr",
    Initials: "JTM",
    Surname: "Magill",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  5202: {
    Title: "Mr.",
    Initials: "K.W",
    Surname: "Fierling",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5203: {
    Title: "Mr",
    Initials: "MS",
    Surname: "Le Maitre",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  5204: {
    Title: "Mr",
    Initials: "M",
    Surname: "Witherley",
    "Primary Club": "City of Newcastle RC",
    County: "Northumberland",
    Country: "England",
  },
  5205: {
    Title: "Miss",
    Initials: "A",
    Surname: "Strain",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  5206: {
    Title: "Miss",
    Initials: "RD",
    Surname: "Dunn",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5207: {
    Title: "Mr",
    Initials: "CM",
    Surname: "Nunan",
    "Primary Club": "RNTRC",
    County: "South Wales",
    Country: "Wales",
  },
  5208: {
    Title: "Miss",
    Initials: "LK",
    Surname: "Broomfield",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5209: {
    Title: "Cpl",
    Initials: "SJ",
    Surname: "Day",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5210: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Armstrong",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5211: {
    Title: "Cdt",
    Initials: "ML",
    Surname: "Henderson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5212: {
    Title: "",
    Initials: "GJ",
    Surname: "De Kock",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  5213: {
    Title: "LCpl",
    Initials: "K",
    Surname: "Lacey",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  5214: {
    Title: "Mr",
    Initials: "RT",
    Surname: "MacLeod",
    "Primary Club": "Old Wellingtonians",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5215: {
    Title: "Mr.",
    Initials: "PR",
    Surname: "Roy",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5216: {
    Title: "Cdt Cpl",
    Initials: "AJT",
    Surname: "Innes-Whitehouse",
    "Primary Club": "Wellington College",
    County: "Sussex",
    Country: "England",
  },
  5217: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Horrell",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5218: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Norman",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5219: {
    Title: "Mr.",
    Initials: "JA",
    Surname: "Schakel",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5220: {
    Title: "Sgt",
    Initials: "BA",
    Surname: "Beadle",
    "Primary Club": "Suffolk CRA",
    County: "Suffolk",
    Country: "",
  },
  5221: {
    Title: "Cdt",
    Initials: "EH",
    Surname: "Gittins",
    "Primary Club": "Tonbridge School",
    County: "Sussex",
    Country: "",
  },
  5222: {
    Title: "",
    Initials: "S",
    Surname: "Ishak",
    "Primary Club": "",
    County: "",
    Country: "Malaysia",
  },
  5223: {
    Title: "Mr.",
    Initials: "DTC",
    Surname: "Lohnes",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5224: {
    Title: "Ms",
    Initials: "NM",
    Surname: "MacDougall",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5225: {
    Title: "Miss",
    Initials: "KE",
    Surname: "Milner",
    "Primary Club": "City of Newcastle RC",
    County: "County of Durham",
    Country: "England",
  },
  5226: {
    Title: "Mr",
    Initials: "ES",
    Surname: "Scougall",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5227: {
    Title: "Mr",
    Initials: "PJG",
    Surname: "Barber",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  5228: {
    Title: "Mr",
    Initials: "EWV",
    Surname: "Feast",
    "Primary Club": "Radlett",
    County: "Hertfordshire",
    Country: "England",
  },
  5229: {
    Title: "Cdt",
    Initials: "SMB",
    Surname: "Maitland",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  5230: {
    Title: "Mr",
    Initials: "WAR",
    Surname: "Yeo",
    "Primary Club": "Lincolnshire ACF",
    County: "Lincolnshire",
    Country: "",
  },
  5231: {
    Title: "Mr",
    Initials: "OJLW",
    Surname: "Woolcott",
    "Primary Club": "Old Guildfordians RC",
    County: "Devon",
    Country: "England",
  },
  5232: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Walker",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5233: {
    Title: "Mr",
    Initials: "LS",
    Surname: "Skinner",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "",
  },
  5234: {
    Title: "Mr.",
    Initials: "JTH",
    Surname: "Jones",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5235: {
    Title: "LCpl",
    Initials: "K",
    Surname: "Burnell",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5236: {
    Title: "CSjt",
    Initials: "LG",
    Surname: "Gamble",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  5237: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Johnston",
    "Primary Club": "University of Nottingham",
    County: "Sussex",
    Country: "Ireland",
  },
  5239: {
    Title: "Mr",
    Initials: "A",
    Surname: "Tucker",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5240: {
    Title: "F/Sgt",
    Initials: "E",
    Surname: "Brown",
    "Primary Club": "Durham ATC",
    County: "County Durham",
    Country: "England",
  },
  5241: {
    Title: "Miss",
    Initials: "B",
    Surname: "Charlton",
    "Primary Club": "University of London",
    County: "Northumberland",
    Country: "England",
  },
  5242: {
    Title: "Cpl",
    Initials: "S",
    Surname: "Maundrill",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  5243: {
    Title: "Mr",
    Initials: "OWTR",
    Surname: "Rowe",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  5244: {
    Title: "Dr",
    Initials: "HE",
    Surname: "Walker",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5245: {
    Title: "Mr",
    Initials: "A",
    Surname: "Aryaeipour",
    "Primary Club": "Humberside & S Yorks ACF",
    County: "Yorkshire",
    Country: "England",
  },
  5246: {
    Title: "Mr",
    Initials: "DCJ",
    Surname: "Poxon",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5247: {
    Title: "Mr",
    Initials: "R",
    Surname: "Hedley",
    "Primary Club": "GB U19",
    County: "Gloucestershire",
    Country: "England",
  },
  5248: {
    Title: "Dr",
    Initials: "HE",
    Surname: "Nuttall",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5249: {
    Title: "Dr",
    Initials: "PJ",
    Surname: "Taylor",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5250: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Lee",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "Hong Kong",
  },
  5251: {
    Title: "",
    Initials: "RK",
    Surname: "Leite",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5252: {
    Title: "Mr",
    Initials: "JE",
    Surname: "Bell",
    "Primary Club": "Sedbergh School",
    County: "Northumberland",
    Country: "England",
  },
  5253: {
    Title: "Mr",
    Initials: "OZ",
    Surname: "Wong",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5254: {
    Title: "Mr",
    Initials: "YW",
    Surname: "Thorne",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  5255: {
    Title: "Mr",
    Initials: "ELM",
    Surname: "Macaulay",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "Ireland",
  },
  5256: {
    Title: "Lcpl",
    Initials: "JCH",
    Surname: "Lee",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "",
  },
  5257: {
    Title: "",
    Initials: "K",
    Surname: "Pehiee",
    "Primary Club": "",
    County: "",
    Country: "Malaysia",
  },
  5258: {
    Title: "CSjt",
    Initials: "L",
    Surname: "Scull",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  5259: {
    Title: "LCpl",
    Initials: "N",
    Surname: "Vincent",
    "Primary Club": "Hants and IOW ACF",
    County: "",
    Country: "",
  },
  5260: {
    Title: "Cpl",
    Initials: "CX",
    Surname: "Legge",
    "Primary Club": "Lincolnshire ACF",
    County: "Lincolnshire",
    Country: "United Kingdom",
  },
  5261: {
    Title: "Mr",
    Initials: "D",
    Surname: "Furner",
    "Primary Club": "",
    County: "Hampshire",
    Country: "",
  },
  5262: {
    Title: "Mr",
    Initials: "TP",
    Surname: "Shaw",
    "Primary Club": "Cardinals",
    County: "Berkshire",
    Country: "England",
  },
  5263: {
    Title: "FSgt",
    Initials: "T",
    Surname: "Low",
    "Primary Club": "Durham ATC",
    County: "Northumberland",
    Country: "England",
  },
  5264: {
    Title: "LCpl",
    Initials: "MJ",
    Surname: "Newton",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5265: {
    Title: "Mr",
    Initials: "DT",
    Surname: "Taylor",
    "Primary Club": "ATSC",
    County: "",
    Country: "England",
  },
  5266: {
    Title: "Mr",
    Initials: "MB",
    Surname: "Bree",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5267: {
    Title: "Cadet",
    Initials: "R",
    Surname: "Williams",
    "Primary Club": "Eastbourne SCC",
    County: "Sussex",
    Country: "England",
  },
  5268: {
    Title: "Miss",
    Initials: "T",
    Surname: "Myagkova",
    "Primary Club": "Sedbergh School",
    County: "Gloucestershire",
    Country: "England",
  },
  5269: {
    Title: "Mr",
    Initials: "KI",
    Surname: "Ortmann",
    "Primary Club": "OCRA",
    County: "",
    Country: "Germany",
  },
  5270: {
    Title: "",
    Initials: "HJ",
    Surname: "Diederichs",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  5271: {
    Title: "Mr",
    Initials: "MA",
    Surname: "Chance",
    "Primary Club": "Club 25",
    County: "County Durham",
    Country: "England",
  },
  5272: {
    Title: "Mr",
    Initials: "HD",
    Surname: "Quilliam",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  5273: {
    Title: "Miss",
    Initials: "MC",
    Surname: "Montgomery",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5274: {
    Title: "",
    Initials: "GPJ",
    Surname: "Nel",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5275: {
    Title: "Mr",
    Initials: "BDJ",
    Surname: "Jones",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "United Kingdom",
  },
  5276: {
    Title: "Cdt",
    Initials: "BG",
    Surname: "Chamberlain",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5277: {
    Title: "Cdt",
    Initials: "REJ",
    Surname: "Mcgregor",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5278: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Coombes",
    "Primary Club": "Durham ATC",
    County: "Northumberland",
    Country: "England",
  },
  5279: {
    Title: "Miss",
    Initials: "AV",
    Surname: "Virani",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5280: {
    Title: "Cdt",
    Initials: "JJ",
    Surname: "Cracknell",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5281: {
    Title: "Mr",
    Initials: "E",
    Surname: "Lawrence",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  5282: {
    Title: "Cdt",
    Initials: "CWF",
    Surname: "Kingston",
    "Primary Club": "Tonbridge School",
    County: "Kent",
    Country: "",
  },
  5283: {
    Title: "Mr",
    Initials: "MB",
    Surname: "Benka",
    "Primary Club": "Oxford RC",
    County: "Oxfordshire",
    Country: "",
  },
  5284: {
    Title: "Miss",
    Initials: "CL",
    Surname: "Coutts",
    "Primary Club": "Wandsworth FBRC",
    County: "Buckinghamshire",
    Country: "England",
  },
  5285: {
    Title: "Miss",
    Initials: "E",
    Surname: "Potter",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5286: {
    Title: "Sgt",
    Initials: "D",
    Surname: "Sibbald",
    "Primary Club": "Durham ATC",
    County: "Northumberland",
    Country: "England",
  },
  5287: {
    Title: "Miss",
    Initials: "HL",
    Surname: "Wills",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5288: {
    Title: "Miss",
    Initials: "VRM",
    Surname: "Jowett",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5289: {
    Title: "Mr",
    Initials: "EF",
    Surname: "Farley",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  5290: {
    Title: "LCpl",
    Initials: "RS",
    Surname: "Scadding",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  5291: {
    Title: "Mister",
    Initials: "AI",
    Surname: "Ishaq",
    "Primary Club": "University of Edinburgh",
    County: "",
    Country: "Malaysia",
  },
  5292: {
    Title: "Mr",
    Initials: "W",
    Surname: "Jowett",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "",
  },
  5293: {
    Title: "SSgt",
    Initials: "PJ",
    Surname: "Perry",
    "Primary Club": "Cambs ACF",
    County: "Cambridgeshire",
    Country: "England",
  },
  5294: {
    Title: "Cdt",
    Initials: "EMO",
    Surname: "Johnson",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5295: {
    Title: "Sgt",
    Initials: "TH",
    Surname: "Durell",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5296: {
    Title: "Mr",
    Initials: "GH",
    Surname: "Bentley",
    "Primary Club": "Athelings",
    County: "Yorkshire",
    Country: "England",
  },
  5297: {
    Title: "Miss",
    Initials: "L",
    Surname: "Dell",
    "Primary Club": "University of London",
    County: "London",
    Country: "England",
  },
  5298: {
    Title: "Mr",
    Initials: "B",
    Surname: "Innis",
    "Primary Club": "University of London",
    County: "London",
    Country: "England",
  },
  5299: {
    Title: "MR",
    Initials: "DF",
    Surname: "Leonard",
    "Primary Club": "",
    County: "",
    Country: "Wales",
  },
  5300: {
    Title: "Mr",
    Initials: "R",
    Surname: "Lindsay",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  5301: {
    Title: "Mr",
    Initials: "D",
    Surname: "Rajnauth",
    "Primary Club": "Trinidad RA",
    County: "",
    Country: "Trinidad",
  },
  5302: {
    Title: "Lady",
    Initials: "CLAJ",
    Surname: "Tudor-Parry",
    "Primary Club": "",
    County: "",
    Country: "Wales",
  },
  5303: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Whatton",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5304: {
    Title: "Mr",
    Initials: "OLGA",
    Surname: "Armstrong",
    "Primary Club": "Epsom College",
    County: "",
    Country: "",
  },
  5305: {
    Title: "F/O",
    Initials: "RJ",
    Surname: "Clegg",
    "Primary Club": "RAFTRC",
    County: "Surrey",
    Country: "England",
  },
  5306: {
    Title: "Cdt CSM",
    Initials: "CT",
    Surname: "Dawes",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5307: {
    Title: "Mr",
    Initials: "CG",
    Surname: "Green",
    "Primary Club": "Brunel University",
    County: "Middlesex",
    Country: "England",
  },
  5308: {
    Title: "Mr",
    Initials: "NWN",
    Surname: "Newbould",
    "Primary Club": "Aberdeen University RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5309: {
    Title: "Mr",
    Initials: "B",
    Surname: "Graham",
    "Primary Club": "NRA",
    County: "Gloucestershire",
    Country: "England",
  },
  5310: {
    Title: "Mr",
    Initials: "R",
    Surname: "Pook",
    "Primary Club": "",
    County: "Sussex",
    Country: "England",
  },
  5311: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Carson",
    "Primary Club": "Pumas RC",
    County: "Hampshire",
    Country: "Scotland",
  },
  5312: {
    Title: "Mr",
    Initials: "HOB",
    Surname: "Boyd",
    "Primary Club": "Greensleeves",
    County: "Gloucestershire",
    Country: "",
  },
  5313: {
    Title: "Mr",
    Initials: "JL",
    Surname: "Brooking",
    "Primary Club": "Sussex RC",
    County: "Sussex",
    Country: "England",
  },
  5314: {
    Title: "Mr",
    Initials: "N",
    Surname: "Gates",
    "Primary Club": "Cambridge University RA",
    County: "Hertfordshire",
    Country: "England",
  },
  5315: {
    Title: "Mr",
    Initials: "K",
    Surname: "Sitathani",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "",
  },
  5316: {
    Title: "Mr",
    Initials: "SG",
    Surname: "Hunt",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5317: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Rickman",
    "Primary Club": "Jamaica RA",
    County: "",
    Country: "Jamaica",
  },
  5318: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Collins",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  5319: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Parr",
    "Primary Club": "NRA",
    County: "County of London",
    Country: "Scotland",
  },
  5320: {
    Title: "Mr",
    Initials: "EWK",
    Surname: "Leonard-Pugh",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Wales",
  },
  5321: {
    Title: "Dr",
    Initials: "DM",
    Surname: "Clarke",
    "Primary Club": "",
    County: "Surrey",
    Country: "England",
  },
  5322: {
    Title: "Mr",
    Initials: "CMG",
    Surname: "Gregory",
    "Primary Club": "Cambridgeshire",
    County: "Cambridgeshire",
    Country: "England",
  },
  5323: {
    Title: "MR",
    Initials: "DL",
    Surname: "Lee",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  5324: {
    Title: "Miss",
    Initials: "JP",
    Surname: "Patterson",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  5325: {
    Title: "Mr",
    Initials: "GHC",
    Surname: "Wirgman",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5326: {
    Title: "Mr",
    Initials: "WF",
    Surname: "Livesey",
    "Primary Club": "Old Guildfordians RC",
    County: "Ulster",
    Country: "Ireland",
  },
  5327: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Marriott",
    "Primary Club": "",
    County: "Nottinghamshire",
    Country: "England",
  },
  5328: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Lee",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "",
  },
  5330: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Baldwin",
    "Primary Club": "Stock Exchange RC",
    County: "Buckinghamshire",
    Country: "England",
  },
  5331: {
    Title: "Ms",
    Initials: "MC",
    Surname: "Taillefer",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  5332: {
    Title: "Mr",
    Initials: "M",
    Surname: "Meyer",
    "Primary Club": "NRA",
    County: "",
    Country: "Germany",
  },
  5334: {
    Title: "Mr",
    Initials: "J",
    Surname: "Hawke",
    "Primary Club": "Oxford University RC",
    County: "Gloucestershire",
    Country: "England",
  },
  5335: {
    Title: "Cdt",
    Initials: "CD",
    Surname: "Smith",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5336: {
    Title: "Miss",
    Initials: "AE",
    Surname: "Manley",
    "Primary Club": "Cheltenham",
    County: "Gloucestershire",
    Country: "England",
  },
  5337: {
    Title: "Mr",
    Initials: "M",
    Surname: "Raiber",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5338: {
    Title: "Mr",
    Initials: "A",
    Surname: "Eckardt",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5339: {
    Title: "SSgt",
    Initials: "PJ",
    Surname: "Kenmir",
    "Primary Club": "Pates Grammar CCF",
    County: "Glamorgan",
    Country: "England",
  },
  5340: {
    Title: "",
    Initials: "A",
    Surname: "Rieger",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5341: {
    Title: "Mr",
    Initials: "K",
    Surname: "Capaldi",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5343: {
    Title: "Mr",
    Initials: "M",
    Surname: "Raps",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5344: {
    Title: "Mr",
    Initials: "D",
    Surname: "Taylor",
    "Primary Club": "Marylebone R&PC",
    County: "Surrey",
    Country: "England",
  },
  5345: {
    Title: "Mr",
    Initials: "ARW",
    Surname: "Smith",
    "Primary Club": "Ulster RA",
    County: "Ulster",
    Country: "Ireland",
  },
  5346: {
    Title: "Mr",
    Initials: "WN",
    Surname: "White",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  5347: {
    Title: "Mr",
    Initials: "G",
    Surname: "Stewart",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5348: {
    Title: "Mr",
    Initials: "GRG",
    Surname: "Garro",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  5349: {
    Title: "Mr",
    Initials: "R le R",
    Surname: "Bird",
    "Primary Club": "Old Lawrentians",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5350: {
    Title: "Mr",
    Initials: "W",
    Surname: "Johnson",
    "Primary Club": "Cambridge University RA",
    County: "Surrey",
    Country: "England",
  },
  5351: {
    Title: "Mr",
    Initials: "RB",
    Surname: "Fitzsimmons",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5352: {
    Title: "Mr",
    Initials: "G",
    Surname: "Wiens",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5353: {
    Title: "Sgt",
    Initials: "M",
    Surname: "MacKenzie",
    "Primary Club": "Black Watch ACF",
    County: "Perthshire",
    Country: "Scotland",
  },
  5354: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Shalom",
    "Primary Club": "Athelings",
    County: "Norfolk",
    Country: "England",
  },
  5355: {
    Title: "Cdt",
    Initials: "CC",
    Surname: "Chan",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5356: {
    Title: "Dr",
    Initials: "LV",
    Surname: "Boddy",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  5357: {
    Title: "Mr",
    Initials: "A",
    Surname: "Taylor",
    "Primary Club": "Welsh RA",
    County: "",
    Country: "Wales",
  },
  5358: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Fox",
    "Primary Club": "Midland Counties RC",
    County: "",
    Country: "England",
  },
  5359: {
    Title: "Cdt",
    Initials: "JET",
    Surname: "Dowley",
    "Primary Club": "Cheltenham College",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5360: {
    Title: "CSgt",
    Initials: "WC",
    Surname: "Compton",
    "Primary Club": "Athelings",
    County: "Hampshire",
    Country: "England",
  },
  5361: {
    Title: "Mr",
    Initials: "LA",
    Surname: "Reid",
    "Primary Club": "Rolls-Royce Derby R & PC",
    County: "Derbyshire",
    Country: "England",
  },
  5362: {
    Title: "WO",
    Initials: "E",
    Surname: "Zhang",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5363: {
    Title: "MWO",
    Initials: "A",
    Surname: "Wlodarczyk",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5364: {
    Title: "MWO",
    Initials: "C",
    Surname: "Perry",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5365: {
    Title: "WO",
    Initials: "A",
    Surname: "Tardif",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5366: {
    Title: "MWO",
    Initials: "R",
    Surname: "Robinson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5367: {
    Title: "MWO",
    Initials: "Z",
    Surname: "Pelletier-Mercier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5368: {
    Title: "WO",
    Initials: "Z",
    Surname: "Montreuil",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5369: {
    Title: "MWO",
    Initials: "T",
    Surname: "Moeller",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5370: {
    Title: "Sgt",
    Initials: "C",
    Surname: "McArthur",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5371: {
    Title: "WO",
    Initials: "D",
    Surname: "Lomack",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5372: {
    Title: "WO",
    Initials: "F",
    Surname: "Lemoine",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5373: {
    Title: "MWO",
    Initials: "A",
    Surname: "La Sala",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5374: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Kirkeby",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5375: {
    Title: "WO",
    Initials: "T",
    Surname: "Fortin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5376: {
    Title: "WO",
    Initials: "M",
    Surname: "DeYoung",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5377: {
    Title: "Sgt",
    Initials: "Y",
    Surname: "Chun",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5378: {
    Title: "CWO",
    Initials: "J",
    Surname: "Brown",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5379: {
    Title: "MWO",
    Initials: "D",
    Surname: "Arlow",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5381: {
    Title: "Mr",
    Initials: "AH",
    Surname: "Hill",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "",
  },
  5382: {
    Title: "Pte",
    Initials: "HG",
    Surname: "Asquith-Beard",
    "Primary Club": "ATSC",
    County: "Berkshire",
    Country: "England",
  },
  5383: {
    Title: "Mr",
    Initials: "M",
    Surname: "Hartung",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5384: {
    Title: "Sgt",
    Initials: "RN",
    Surname: "Volkwein",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5385: {
    Title: "Sgt",
    Initials: "HC",
    Surname: "Harris",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5386: {
    Title: "Mr",
    Initials: "CAD",
    Surname: "Flanagan",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5387: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Godwin-Austen",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5388: {
    Title: "Cdt",
    Initials: "LY",
    Surname: "Chan",
    "Primary Club": "Epsom College",
    County: "",
    Country: "Hong Kong",
  },
  5389: {
    Title: "Mr",
    Initials: "HRA",
    Surname: "Goddard",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5390: {
    Title: "Miss",
    Initials: "KA",
    Surname: "Fleck",
    "Primary Club": "Cambridge University RA",
    County: "Yorkshire",
    Country: "England",
  },
  5391: {
    Title: "Mr",
    Initials: "WB",
    Surname: "Ross",
    "Primary Club": "Cambridge University RA",
    County: "Yorkshire",
    Country: "Scotland",
  },
  5392: {
    Title: "",
    Initials: "NJ",
    Surname: "Beloussov",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5393: {
    Title: "Cdt",
    Initials: "DL",
    Surname: "Osmond",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5394: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Brooke",
    "Primary Club": "Northumbria ACF",
    County: "Northumberland",
    Country: "England",
  },
  5395: {
    Title: "Bdr",
    Initials: "N",
    Surname: "Reid",
    "Primary Club": "Northumbria ACF",
    County: "Northumberland",
    Country: "England",
  },
  5396: {
    Title: "SSgt",
    Initials: "J",
    Surname: "Furness",
    "Primary Club": "Northumbria ACF",
    County: "Northumberland",
    Country: "England",
  },
  5397: {
    Title: "Mr",
    Initials: "BG",
    Surname: "Greasley",
    "Primary Club": "ATSC",
    County: "",
    Country: "England",
  },
  5398: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Parker",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  5399: {
    Title: "Cdt",
    Initials: "SWH",
    Surname: "Norval",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  5401: {
    Title: "Sgt",
    Initials: "O",
    Surname: "McCulloch",
    "Primary Club": "Hants and IOW ACF",
    County: "Hampshire",
    Country: "England",
  },
  5402: {
    Title: "Cdt",
    Initials: "BL",
    Surname: "Wijsmuller",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "England",
  },
  5403: {
    Title: "Mr",
    Initials: "AJOC",
    Surname: "Collins",
    "Primary Club": "St Albans School",
    County: "Hertfordshire",
    Country: "England",
  },
  5404: {
    Title: "Mr",
    Initials: "MRWJ",
    Surname: "Jones",
    "Primary Club": "Cambridge University RA",
    County: "Dorset",
    Country: "England",
  },
  5405: {
    Title: "Mr",
    Initials: "DF",
    Surname: "Charlton",
    "Primary Club": "Oakham School",
    County: "Leicestershire",
    Country: "England",
  },
  5406: {
    Title: "Cdt",
    Initials: "CWS",
    Surname: "Simionesu-Marin",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5407: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Moor",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5408: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Elliott",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5409: {
    Title: "Mr",
    Initials: "A",
    Surname: "Niblett",
    "Primary Club": "RAFTRC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  5410: {
    Title: "Cpl",
    Initials: "AMJ",
    Surname: "Gilchrist",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "England",
  },
  5411: {
    Title: "Cdt",
    Initials: "MJ",
    Surname: "Sheehy",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5412: {
    Title: "Mr",
    Initials: "ST",
    Surname: "Taylor",
    "Primary Club": "Old Stamfordians",
    County: "Lincolnshire",
    Country: "England",
  },
  5413: {
    Title: "Cadet",
    Initials: "CTR",
    Surname: "Gicquel",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5414: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Chisholm",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  5415: {
    Title: "Mr",
    Initials: "TN",
    Surname: "Nicholls",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  5416: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Baldry",
    "Primary Club": "NRA",
    County: "Lincolnshire",
    Country: "England",
  },
  5418: {
    Title: "Mr",
    Initials: "WH",
    Surname: "Hall",
    "Primary Club": "Athelings",
    County: "Berkshire",
    Country: "England",
  },
  5419: {
    Title: "Miss",
    Initials: "GI",
    Surname: "Izzard",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5420: {
    Title: "Mr",
    Initials: "RH",
    Surname: "Horne",
    "Primary Club": "Bradfield College",
    County: "Oxfordshire",
    Country: "England",
  },
  5421: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Bates",
    "Primary Club": "Bradfield College",
    County: "Devon",
    Country: "England",
  },
  5422: {
    Title: "Mr",
    Initials: "JK",
    Surname: "Kelly",
    "Primary Club": "Bradfield College",
    County: "Oxfordshire",
    Country: "England",
  },
  5423: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Clewer",
    "Primary Club": "LMRA",
    County: "Hereford & Worcs",
    Country: "England",
  },
  5424: {
    Title: "Mr",
    Initials: "LA",
    Surname: "Alder",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5425: {
    Title: "Miss",
    Initials: "IM",
    Surname: "Macleod",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5426: {
    Title: "Mr",
    Initials: "SG",
    Surname: "Gordon",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  5427: {
    Title: "Mr",
    Initials: "MH",
    Surname: "Heil",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5428: {
    Title: "MR",
    Initials: "R",
    Surname: "Ganguly",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  5430: {
    Title: "Mr",
    Initials: "H",
    Surname: "Lee",
    "Primary Club": "Oxford University RC",
    County: "Hertfordshire",
    Country: "England",
  },
  5431: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Lee",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "",
  },
  5432: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Clayton",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5433: {
    Title: "Mr",
    Initials: "JK",
    Surname: "Kelleher",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5434: {
    Title: "Mr",
    Initials: "WG",
    Surname: "Griffiths",
    "Primary Club": "ATRC",
    County: "Hereford & Worcs",
    Country: "Wales",
  },
  5436: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Grainger",
    "Primary Club": "BYSA",
    County: "Northumberland",
    Country: "England",
  },
  5437: {
    Title: "Gdsm",
    Initials: "AEA",
    Surname: "Hinton",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  5438: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Russell",
    "Primary Club": "Old Wellingburian",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5439: {
    Title: "Sgt",
    Initials: "CS",
    Surname: "Neuhauser",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5440: {
    Title: "CSM",
    Initials: "GW",
    Surname: "Holland",
    "Primary Club": "Oxford University RC",
    County: "Kent",
    Country: "England",
  },
  5441: {
    Title: "Cdt",
    Initials: "TB",
    Surname: "Charles",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5442: {
    Title: "Miss",
    Initials: "KL",
    Surname: "Peters",
    "Primary Club": "Durham University",
    County: "Buckinghamshire",
    Country: "England",
  },
  5443: {
    Title: "CPL",
    Initials: "J",
    Surname: "Duncan",
    "Primary Club": "607 (Wearmouth) Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  5445: {
    Title: "Cpl",
    Initials: "D",
    Surname: "Saunders",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  5446: {
    Title: "Miss",
    Initials: "ZN",
    Surname: "Northam",
    "Primary Club": "GB U25",
    County: "Hertfordshire",
    Country: "England",
  },
  5447: {
    Title: "Cdt Sgt",
    Initials: "AY",
    Surname: "Sayers",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5448: {
    Title: "Miss",
    Initials: "KM",
    Surname: "Muhammad",
    "Primary Club": "Wellington College",
    County: "Middlesex",
    Country: "England",
  },
  5449: {
    Title: "Mr",
    Initials: "OL",
    Surname: "Leith",
    "Primary Club": "ACF",
    County: "",
    Country: "England",
  },
  5450: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Robinson",
    "Primary Club": "Athelings",
    County: "Guernsey",
    Country: "",
  },
  5451: {
    Title: "Dr",
    Initials: "CPDP",
    Surname: "Lister",
    "Primary Club": "Old Framlinghamians RC",
    County: "Hampshire",
    Country: "England",
  },
  5452: {
    Title: "Cdt",
    Initials: "CI",
    Surname: "Alder",
    "Primary Club": "Wellington College",
    County: "Middlesex",
    Country: "England",
  },
  5453: {
    Title: "Miss",
    Initials: "KA",
    Surname: "Hinkly",
    "Primary Club": "Old Wellingtonians",
    County: "Somerset",
    Country: "England",
  },
  5454: {
    Title: "Cdt WO2",
    Initials: "CPM",
    Surname: "Kent-Payne",
    "Primary Club": "Wellington College",
    County: "Yorkshire",
    Country: "Northern Ireland",
  },
  5455: {
    Title: "Cdt Cpl",
    Initials: "MG",
    Surname: "O'Hare",
    "Primary Club": "Wellington College",
    County: "Wiltshire",
    Country: "England",
  },
  5456: {
    Title: "Mr",
    Initials: "JEK",
    Surname: "Pepera-Hibbert",
    "Primary Club": "Old Wellingtonians",
    County: "Lancashire",
    Country: "England",
  },
  5457: {
    Title: "Cpl",
    Initials: "C",
    Surname: "Eaton",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5458: {
    Title: "",
    Initials: "JW",
    Surname: "Lim",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5459: {
    Title: "Mr",
    Initials: "HJ",
    Surname: "Laing",
    "Primary Club": "Newcastle Uni RC",
    County: "Northumberland",
    Country: "England",
  },
  5460: {
    Title: "Mr",
    Initials: "FJ",
    Surname: "Slevin",
    "Primary Club": "Havant RC",
    County: "",
    Country: "England",
  },
  5461: {
    Title: "Cpl",
    Initials: "MR",
    Surname: "Robinson",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  5462: {
    Title: "Mr",
    Initials: "CE",
    Surname: "Dart",
    "Primary Club": "Cardinals",
    County: "Berkshire",
    Country: "Ireland",
  },
  5463: {
    Title: "Mr",
    Initials: "C",
    Surname: "Pirouet",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5464: {
    Title: "Mr",
    Initials: "AH",
    Surname: "Hood",
    "Primary Club": "Bunhill Rifle Club",
    County: "Suffolk",
    Country: "",
  },
  5465: {
    Title: "Cdt",
    Initials: "CS",
    Surname: "Donaghy",
    "Primary Club": "Northumbria ACF",
    County: "Northumberland",
    Country: "England",
  },
  5466: {
    Title: "Csgt",
    Initials: "L",
    Surname: "Collins",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5467: {
    Title: "Sgt",
    Initials: "T",
    Surname: "Booth",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5468: {
    Title: "CSgt",
    Initials: "M",
    Surname: "Hedley",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5470: {
    Title: "LBdr",
    Initials: "L",
    Surname: "Girard",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5471: {
    Title: "",
    Initials: "AWS",
    Surname: "Sykes",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "England",
  },
  5473: {
    Title: "Mr",
    Initials: "P",
    Surname: "Kiveleeti",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  5475: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Ashby",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "",
  },
  5476: {
    Title: "WO",
    Initials: "P",
    Surname: "Asselin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5477: {
    Title: "Mr",
    Initials: "EGH",
    Surname: "Atkins",
    "Primary Club": "Old Wellingtonians",
    County: "Berkshire",
    Country: "England",
  },
  5478: {
    Title: "Mr",
    Initials: "TOA",
    Surname: "Atkinson",
    "Primary Club": "Oakham School",
    County: "Rutland",
    Country: "England",
  },
  5479: {
    Title: "Mr",
    Initials: "A",
    Surname: "Baldry",
    "Primary Club": "Wessex GC",
    County: "Wiltshire",
    Country: "England",
  },
  5480: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Bamber",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "Scotland",
  },
  5481: {
    Title: "Mr",
    Initials: "J",
    Surname: "Barlow",
    "Primary Club": "Glasgow University RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  5482: {
    Title: "Ms",
    Initials: "V",
    Surname: "Bieger",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5483: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Blumsom",
    "Primary Club": "OBRC",
    County: "Hertfordshire",
    Country: "England",
  },
  5484: {
    Title: "Miss",
    Initials: "HPC",
    Surname: "Bramwell",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  5485: {
    Title: "Ms",
    Initials: "AB",
    Surname: "Braun",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "Canada",
  },
  5486: {
    Title: "Mr",
    Initials: "CPP",
    Surname: "Brewin",
    "Primary Club": "Surrey University RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5487: {
    Title: "WO",
    Initials: "J",
    Surname: "Brodeur",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5489: {
    Title: "Miss",
    Initials: "AJ",
    Surname: "Carnell",
    "Primary Club": "Charterhouse",
    County: "Hereford & Worcs",
    Country: "Wales",
  },
  5490: {
    Title: "Cdt",
    Initials: "CD",
    Surname: "Carratu",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5491: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Cavanagh",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  5492: {
    Title: "WO",
    Initials: "F",
    Surname: "Clark",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5493: {
    Title: "Cdt Cpl",
    Initials: "OAC",
    Surname: "Clark",
    "Primary Club": "Oratory School",
    County: "Berkshire",
    Country: "England",
  },
  5494: {
    Title: "Sgt",
    Initials: "E",
    Surname: "Clarke",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5495: {
    Title: "Mr",
    Initials: "MC",
    Surname: "Cockerill",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5497: {
    Title: "Mr",
    Initials: "OM",
    Surname: "Conway",
    "Primary Club": "Oakham School",
    County: "Leicestershire",
    Country: "England",
  },
  5498: {
    Title: "Mr",
    Initials: "WC",
    Surname: "Cowen",
    "Primary Club": "Old Sedberghians",
    County: "Yorkshire",
    Country: "England",
  },
  5499: {
    Title: "Cdt",
    Initials: "WP",
    Surname: "Cubitt",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5500: {
    Title: "WO",
    Initials: "J",
    Surname: "Czuba",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5501: {
    Title: "Mr",
    Initials: "TJR",
    Surname: "De La Cour",
    "Primary Club": "Exeter University RC",
    County: "Jersey",
    Country: "Jersey",
  },
  5502: {
    Title: "Mr",
    Initials: "AD",
    Surname: "De Vivenot",
    "Primary Club": "Cambridge University RA",
    County: "Middlesex",
    Country: "England",
  },
  5503: {
    Title: "Cpl",
    Initials: "EJ",
    Surname: "Dell",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5504: {
    Title: "Mr",
    Initials: "H",
    Surname: "Dodds",
    "Primary Club": "OTRC",
    County: "",
    Country: "",
  },
  5505: {
    Title: "2Lt",
    Initials: "KS",
    Surname: "Dods",
    "Primary Club": "BYSA",
    County: "County Durham",
    Country: "United Kingdom",
  },
  5506: {
    Title: "Cdt Cpl",
    Initials: "K",
    Surname: "Drelaud",
    "Primary Club": "Humberside & S Yorks ACF",
    County: "Yorkshire",
    Country: "England",
  },
  5507: {
    Title: "Cdt",
    Initials: "JA",
    Surname: "Edwards",
    "Primary Club": "Wellington College",
    County: "Hampshire",
    Country: "England",
  },
  5508: {
    Title: "Mr",
    Initials: "S",
    Surname: "Edwards",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5509: {
    Title: "Mr",
    Initials: "TO",
    Surname: "Fermor",
    "Primary Club": "Old Suttonians",
    County: "Kent",
    Country: "England",
  },
  5510: {
    Title: "Miss",
    Initials: "AF",
    Surname: "Fernandes",
    "Primary Club": "Oakham School",
    County: "",
    Country: "",
  },
  5511: {
    Title: "Mr",
    Initials: "SJA",
    Surname: "Fielding-Isaacs",
    "Primary Club": "",
    County: "",
    Country: "Canada",
  },
  5512: {
    Title: "Mr",
    Initials: "T",
    Surname: "Foote",
    "Primary Club": "ATSC",
    County: "Gloucestershire",
    Country: "England",
  },
  5513: {
    Title: "Cdt LCpl",
    Initials: "SW",
    Surname: "Franklin",
    "Primary Club": "Surrey ACF",
    County: "Yorkshire",
    Country: "England",
  },
  5514: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Gambling",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  5516: {
    Title: "Mr",
    Initials: "NG",
    Surname: "Gibbins",
    "Primary Club": "Mosman-Neutral Bay RC",
    County: "",
    Country: "Australia",
  },
  5517: {
    Title: "Mr",
    Initials: "HRA",
    Surname: "Goddard",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5518: {
    Title: "Mr",
    Initials: "J",
    Surname: "Gough",
    "Primary Club": "Dorset Riflemen",
    County: "Hampshire",
    Country: "England",
  },
  5519: {
    Title: "Miss",
    Initials: "SG",
    Surname: "Gould",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  5520: {
    Title: "Sgt",
    Initials: "P",
    Surname: "Green",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5521: {
    Title: "Mr",
    Initials: "LA",
    Surname: "Greenaway",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  5522: {
    Title: "Mr",
    Initials: "CL",
    Surname: "Greenhough",
    "Primary Club": "Winfarthing",
    County: "Suffolk",
    Country: "England",
  },
  5523: {
    Title: "Mr",
    Initials: "PJ",
    Surname: "Harkins",
    "Primary Club": "101 RC",
    County: "",
    Country: "England",
  },
  5524: {
    Title: "Mr",
    Initials: "OH",
    Surname: "Hawes",
    "Primary Club": "Glasgow University RC",
    County: "West of Scotland",
    Country: "England",
  },
  5525: {
    Title: "Mr",
    Initials: "L",
    Surname: "Hegarty",
    "Primary Club": "Basildon R&PC",
    County: "",
    Country: "",
  },
  5526: {
    Title: "WO",
    Initials: "V",
    Surname: "Ho",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5527: {
    Title: "Mr",
    Initials: "JF",
    Surname: "Hollingdale",
    "Primary Club": "Old Wellingtonians",
    County: "London",
    Country: "Scotland",
  },
  5528: {
    Title: "Mr",
    Initials: "OH",
    Surname: "Hood",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  5529: {
    Title: "Mr",
    Initials: "OGE",
    Surname: "Howard-Vyse",
    "Primary Club": "Uppingham Veterans RC",
    County: "Wiltshire",
    Country: "",
  },
  5530: {
    Title: "LCpl",
    Initials: "WCW",
    Surname: "Howseman",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "",
  },
  5531: {
    Title: "Mr",
    Initials: "R",
    Surname: "Hu",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5532: {
    Title: "Lt Cdr",
    Initials: "GMC",
    Surname: "Hunter",
    "Primary Club": "RNTRC",
    County: "",
    Country: "Scotland",
  },
  5533: {
    Title: "Mr",
    Initials: "C",
    Surname: "Irwin",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5534: {
    Title: "Mr",
    Initials: "R",
    Surname: "Islam",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5535: {
    Title: "Mr",
    Initials: "T",
    Surname: "Jaggard",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5536: {
    Title: "LCpl",
    Initials: "SD",
    Surname: "James",
    "Primary Club": "ATSC",
    County: "",
    Country: "England",
  },
  5537: {
    Title: "Mr",
    Initials: "AF",
    Surname: "Jenvey",
    "Primary Club": "RAFTRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  5538: {
    Title: "MWO",
    Initials: "Y",
    Surname: "Jin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5539: {
    Title: "Mr",
    Initials: "A",
    Surname: "Jones",
    "Primary Club": "Old Guildfordians RC",
    County: "Surrey",
    Country: "Wales",
  },
  5540: {
    Title: "WO1",
    Initials: "W",
    Surname: "Jury-Simpson",
    "Primary Club": "Cheltenham College",
    County: "Gloucestershire",
    Country: "England",
  },
  5541: {
    Title: "Mr",
    Initials: "CK",
    Surname: "Kailayapillai",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5542: {
    Title: "Cdt",
    Initials: "MJS",
    Surname: "Keeler",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5543: {
    Title: "Miss",
    Initials: "AK",
    Surname: "Kellett",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5544: {
    Title: "Ms",
    Initials: "KP",
    Surname: "Kent",
    "Primary Club": "Lyndhurst RC",
    County: "",
    Country: "",
  },
  5545: {
    Title: "Miss",
    Initials: "ME",
    Surname: "Kenyon",
    "Primary Club": "Edinburgh University RC",
    County: "Lancashire",
    Country: "England",
  },
  5546: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Kirkillo-Stacewicz",
    "Primary Club": "Bedfordians RC",
    County: "Bedfordshire",
    Country: "England",
  },
  5547: {
    Title: "WO",
    Initials: "A",
    Surname: "Kurd",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5548: {
    Title: "Mr",
    Initials: "T",
    Surname: "Laing-Baker",
    "Primary Club": "LMRA",
    County: "County of London",
    Country: "England",
  },
  5549: {
    Title: "OCdt",
    Initials: "G",
    Surname: "Lapointe",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5550: {
    Title: "Mr",
    Initials: "MDE",
    Surname: "Larcombe",
    "Primary Club": "Old Epsomian RC",
    County: "Lancashire",
    Country: "England",
  },
  5551: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Le Cheminant",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  5552: {
    Title: "Capt",
    Initials: "RW",
    Surname: "Leonard",
    "Primary Club": "Peveril Rifle Club",
    County: "",
    Country: "Isle of Man",
  },
  5553: {
    Title: "Miss",
    Initials: "K",
    Surname: "Lightbown",
    "Primary Club": "NLRC",
    County: "Oxfordshire",
    Country: "Wales",
  },
  5554: {
    Title: "Mr",
    Initials: "JL",
    Surname: "Lim",
    "Primary Club": "University of London",
    County: "County of London",
    Country: "Malaysia",
  },
  5555: {
    Title: "Mr",
    Initials: "D",
    Surname: "Lister",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  5556: {
    Title: "Mr",
    Initials: "TO",
    Surname: "Little",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "Wales",
  },
  5557: {
    Title: "C/Sgt",
    Initials: "M",
    Surname: "Loughhead",
    "Primary Club": "Northumbria ACF",
    County: "Northumberland",
    Country: "England",
  },
  5558: {
    Title: "Sgt",
    Initials: "J",
    Surname: "Lumley",
    "Primary Club": "Northumbria ACF",
    County: "Northumberland",
    Country: "England",
  },
  5559: {
    Title: "Mr",
    Initials: "ML",
    Surname: "Lungu",
    "Primary Club": "241 Sqn",
    County: "Essex",
    Country: "England",
  },
  5560: {
    Title: "Cdt",
    Initials: "TA",
    Surname: "Maclaren",
    "Primary Club": "Cheltenham College",
    County: "Gloucestershire",
    Country: "",
  },
  5561: {
    Title: "Mr",
    Initials: "MM",
    Surname: "Magill",
    "Primary Club": "2390 Sqn ATC",
    County: "Ulster",
    Country: "",
  },
  5562: {
    Title: "Mr",
    Initials: "SMB",
    Surname: "Maitland Bondonneau",
    "Primary Club": "Oratory School",
    County: "Oxfordshire",
    Country: "",
  },
  5563: {
    Title: "Mr",
    Initials: "D",
    Surname: "Mathieson",
    "Primary Club": "Uppingham Veterans RC",
    County: "Lincolnshire",
    Country: "",
  },
  5564: {
    Title: "Mr",
    Initials: "HM",
    Surname: "Mccaughey",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  5565: {
    Title: "Mr",
    Initials: "A",
    Surname: "Mcconnell",
    "Primary Club": "St Andrews University",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5566: {
    Title: "",
    Initials: "CM",
    Surname: "McManus",
    "Primary Club": "ARA",
    County: "",
    Country: "England",
  },
  5567: {
    Title: "Mr",
    Initials: "MM",
    Surname: "McMillan",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "",
  },
  5568: {
    Title: "Miss",
    Initials: "AC",
    Surname: "Melling",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5569: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Mendis",
    "Primary Club": "Cheltenham College",
    County: "Gloucestershire",
    Country: "England",
  },
  5570: {
    Title: "Mr",
    Initials: "DM",
    Surname: "Mills",
    "Primary Club": "LRPC",
    County: "Hereford & Worcs",
    Country: "England",
  },
  5571: {
    Title: "Mr",
    Initials: "TM",
    Surname: "Minter",
    "Primary Club": "Bunhill Rifle Club",
    County: "Essex",
    Country: "England",
  },
  5572: {
    Title: "WO",
    Initials: "C",
    Surname: "Moase",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5573: {
    Title: "Sgt",
    Initials: "O",
    Surname: "Money-Kyrle",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5574: {
    Title: "Mr",
    Initials: "M",
    Surname: "Naiman",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  5575: {
    Title: "Mr",
    Initials: "R",
    Surname: "Nowinski",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  5576: {
    Title: "Mr",
    Initials: "G",
    Surname: "Oakland",
    "Primary Club": "Athelings",
    County: "Berkshire",
    Country: "England",
  },
  5577: {
    Title: "",
    Initials: "S",
    Surname: "Ouellet",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5578: {
    Title: "Dr",
    Initials: "JW",
    Surname: "Page",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5579: {
    Title: "Mr",
    Initials: "J D",
    Surname: "Parsons",
    "Primary Club": "27th London Putney RC",
    County: "Kent",
    Country: "England",
  },
  5580: {
    Title: "Cpl",
    Initials: "E",
    Surname: "Partington",
    "Primary Club": "Framlingham College CCF",
    County: "Suffolk",
    Country: "England",
  },
  5581: {
    Title: "Miss",
    Initials: "M",
    Surname: "Patrick",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5582: {
    Title: "Miss",
    Initials: "MP",
    Surname: "Patton",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  5583: {
    Title: "Miss",
    Initials: "J",
    Surname: "Powell",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  5584: {
    Title: "WO",
    Initials: "A",
    Surname: "Ressi",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5585: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Rigby",
    "Primary Club": "University of London",
    County: "Cheshire",
    Country: "England",
  },
  5586: {
    Title: "Mr",
    Initials: "J",
    Surname: "Robinson",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Wales",
  },
  5587: {
    Title: "WO",
    Initials: "R",
    Surname: "Rousseau",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5588: {
    Title: "Mr",
    Initials: "J",
    Surname: "Scambler",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "England",
  },
  5589: {
    Title: "Dipl. Ing.",
    Initials: "KSC",
    Surname: "Schwegler",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5590: {
    Title: "Mr",
    Initials: "EA",
    Surname: "Sewell",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  5591: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Shakhman",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5592: {
    Title: "Mr",
    Initials: "P",
    Surname: "Shambrook",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  5593: {
    Title: "LCpl",
    Initials: "H",
    Surname: "Shaw",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5594: {
    Title: "Mr",
    Initials: "LJ",
    Surname: "Smith",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "England",
  },
  5595: {
    Title: "Cdt RSM",
    Initials: "O",
    Surname: "Smith",
    "Primary Club": "Humberside & S Yorks ACF",
    County: "Yorkshire",
    Country: "England",
  },
  5596: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Smith",
    "Primary Club": "Chichester RC",
    County: "Sussex",
    Country: "",
  },
  5597: {
    Title: "Cpl",
    Initials: "BCN",
    Surname: "Southworth",
    "Primary Club": "Framlingham College CCF",
    County: "Suffolk",
    Country: "England",
  },
  5598: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Stephenson",
    "Primary Club": "Athelings",
    County: "Surrey",
    Country: "England",
  },
  5599: {
    Title: "Mr",
    Initials: "JS",
    Surname: "Stewart",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  5600: {
    Title: "WO",
    Initials: "T",
    Surname: "Swanson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5601: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Sweegers",
    "Primary Club": "Lyndhurst RC",
    County: "",
    Country: "",
  },
  5602: {
    Title: "Mr",
    Initials: "AT",
    Surname: "Thomas",
    "Primary Club": "Newcastle Uni RC",
    County: "Yorkshire",
    Country: "",
  },
  5603: {
    Title: "Ms",
    Initials: "KT",
    Surname: "Tulloch",
    "Primary Club": "Chichester RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5604: {
    Title: "WO",
    Initials: "J",
    Surname: "Vallee",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5605: {
    Title: "WO",
    Initials: "T",
    Surname: "Vila",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5606: {
    Title: "Cdt",
    Initials: "AE",
    Surname: "Vlas",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5607: {
    Title: "Mr",
    Initials: "CR",
    Surname: "Vonchek",
    "Primary Club": "Old Wellingtonians",
    County: "Hampshire",
    Country: "England",
  },
  5608: {
    Title: "WO",
    Initials: "A",
    Surname: "Walton",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5609: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Welch",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  5610: {
    Title: "Mr",
    Initials: "D",
    Surname: "Wescott",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "England",
  },
  5611: {
    Title: "Cdt W/O",
    Initials: "HL",
    Surname: "Wigston",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5612: {
    Title: "Miss",
    Initials: "ML",
    Surname: "Wilkins",
    "Primary Club": "Bradfield College",
    County: "Hampshire",
    Country: "England",
  },
  5613: {
    Title: "Mr",
    Initials: "S",
    Surname: "Wingrove",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "Australia",
  },
  5614: {
    Title: "Miss",
    Initials: "PL",
    Surname: "Winter",
    "Primary Club": "Bunhill Rifle Club",
    County: "Hampshire",
    Country: "England",
  },
  5615: {
    Title: "Cpl",
    Initials: "W",
    Surname: "Woods Ballard",
    "Primary Club": "Woodbridge School",
    County: "",
    Country: "England",
  },
  5616: {
    Title: "Cdt",
    Initials: "IO",
    Surname: "Young",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  5617: {
    Title: "Mr",
    Initials: "CSP",
    Surname: "Gray",
    "Primary Club": "Old Etonians",
    County: "Hertfordshire",
    Country: "England",
  },
  5618: {
    Title: "Dr",
    Initials: "SYM",
    Surname: "Ali",
    "Primary Club": "NLRC",
    County: "Kent",
    Country: "England",
  },
  5619: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Ambler",
    "Primary Club": "NRA",
    County: "",
    Country: "",
  },
  5620: {
    Title: "Mr",
    Initials: "AA",
    Surname: "Antoniewicz",
    "Primary Club": "NRA",
    County: "Essex",
    Country: "England",
  },
  5621: {
    Title: "Cdt",
    Initials: "HMC",
    Surname: "Ardern",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5622: {
    Title: "Mr",
    Initials: "GDA",
    Surname: "Auty",
    "Primary Club": "Perse CCF",
    County: "Cambridgeshire",
    Country: "England",
  },
  5623: {
    Title: "Miss",
    Initials: "MB",
    Surname: "Bamford",
    "Primary Club": "Athelings",
    County: "County Durham",
    Country: "England",
  },
  5624: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Bell",
    "Primary Club": "Northumberland",
    County: "Northumberland",
    Country: "England",
  },
  5625: {
    Title: "Mr",
    Initials: "LB",
    Surname: "Bennett",
    "Primary Club": "Wellington College",
    County: "County of London",
    Country: "England",
  },
  5626: {
    Title: "WO",
    Initials: "J",
    Surname: "Bernier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5627: {
    Title: "MWO",
    Initials: "A",
    Surname: "Bewli",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5628: {
    Title: "Mr",
    Initials: "HP",
    Surname: "Bielefeldt",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5629: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Blackburn",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5630: {
    Title: "Lt",
    Initials: "E",
    Surname: "Bosse",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5631: {
    Title: "Mr",
    Initials: "J",
    Surname: "Bowsher",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  5632: {
    Title: "MWO",
    Initials: "D",
    Surname: "Brais",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5633: {
    Title: "Mr",
    Initials: "RB",
    Surname: "Brewer-Marchant",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  5634: {
    Title: "Mr",
    Initials: "AE",
    Surname: "Bromley",
    "Primary Club": "Old Suttonians",
    County: "Kent",
    Country: "England",
  },
  5635: {
    Title: "Mr",
    Initials: "B",
    Surname: "Burton",
    "Primary Club": "ATSC",
    County: "Lincolnshire",
    Country: "England",
  },
  5636: {
    Title: "Mrs",
    Initials: "L",
    Surname: "Bushell",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  5637: {
    Title: "Cdt",
    Initials: "F",
    Surname: "Cade",
    "Primary Club": "BCRT",
    County: "Yorkshire",
    Country: "England",
  },
  5638: {
    Title: "WO",
    Initials: "B",
    Surname: "Chaisson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5639: {
    Title: "Cpl",
    Initials: "AL",
    Surname: "Chapman",
    "Primary Club": "",
    County: "Yorkshire",
    Country: "England",
  },
  5640: {
    Title: "Miss",
    Initials: "F",
    Surname: "Chesterfield",
    "Primary Club": "Cambridge University RA",
    County: "Sussex",
    Country: "England",
  },
  5641: {
    Title: "Mr",
    Initials: "JLC",
    Surname: "Christian",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  5642: {
    Title: "Mr",
    Initials: "CC",
    Surname: "Clifford",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  5643: {
    Title: "Mr",
    Initials: "M",
    Surname: "Connole",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5644: {
    Title: "MWO",
    Initials: "T",
    Surname: "Dalin",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5645: {
    Title: "Mr",
    Initials: "BZ",
    Surname: "Danziger",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  5646: {
    Title: "Cadet",
    Initials: "LD",
    Surname: "Davies",
    "Primary Club": "",
    County: "South Wales",
    Country: "Wales",
  },
  5647: {
    Title: "Mr",
    Initials: "OD",
    Surname: "De Gruchy",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5648: {
    Title: "Sgt",
    Initials: "CEA",
    Surname: "Depla",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5649: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Dixon",
    "Primary Club": "Bradfield College",
    County: "Buckinghamshire",
    Country: "England",
  },
  5650: {
    Title: "Mr",
    Initials: "HD",
    Surname: "Donovan",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  5651: {
    Title: "Mr",
    Initials: "G",
    Surname: "Donsworth",
    "Primary Club": "Framlingham College CCF",
    County: "Suffolk",
    Country: "England",
  },
  5652: {
    Title: "",
    Initials: "D",
    Surname: "Du Toit",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  5653: {
    Title: "",
    Initials: "P",
    Surname: "Du Toit",
    "Primary Club": "South Africa",
    County: "",
    Country: "South Africa",
  },
  5654: {
    Title: "Mr",
    Initials: "H",
    Surname: "Durkie",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5655: {
    Title: "MWO",
    Initials: "G",
    Surname: "Dusseault",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5656: {
    Title: "Mr",
    Initials: "PD",
    Surname: "Dyer",
    "Primary Club": "Newcastle Uni RC",
    County: "Berkshire",
    Country: "",
  },
  5657: {
    Title: "Miss",
    Initials: "C",
    Surname: "Eastwick-Field",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  5658: {
    Title: "Sgt",
    Initials: "T",
    Surname: "Elvin",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5659: {
    Title: "WO",
    Initials: "K",
    Surname: "Evans",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5660: {
    Title: "Mr",
    Initials: "OR",
    Surname: "Farrell",
    "Primary Club": "Old Wellingtonians",
    County: "Yorkshire",
    Country: "Wales",
  },
  5661: {
    Title: "Mr",
    Initials: "T",
    Surname: "Frame",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5662: {
    Title: "Mr",
    Initials: "PF",
    Surname: "Francois",
    "Primary Club": "France",
    County: "",
    Country: "",
  },
  5663: {
    Title: "Mr",
    Initials: "SWM",
    Surname: "Freeborn",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "Scotland",
  },
  5664: {
    Title: "Cpl",
    Initials: "OSF",
    Surname: "Fretz",
    "Primary Club": "Lancing College",
    County: "County of London",
    Country: "England",
  },
  5665: {
    Title: "WO",
    Initials: "G",
    Surname: "Gagne",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5666: {
    Title: "Mr",
    Initials: "TG",
    Surname: "Garfield-Bennett",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Wales",
  },
  5667: {
    Title: "Mrs",
    Initials: "CJ",
    Surname: "Gomez-Graham",
    "Primary Club": "SRA",
    County: "",
    Country: "Trinidad & Tobago",
  },
  5668: {
    Title: "Miss",
    Initials: "O",
    Surname: "Graham",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  5669: {
    Title: "",
    Initials: "PA",
    Surname: "Haasbroek",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5670: {
    Title: "Miss",
    Initials: "R",
    Surname: "Harrison",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  5671: {
    Title: "",
    Initials: "JJ",
    Surname: "Hendrikz",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5672: {
    Title: "Dr",
    Initials: "N",
    Surname: "Hendrikz",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  5673: {
    Title: "Miss",
    Initials: "JM",
    Surname: "Hince",
    "Primary Club": "Old Wellingtonians",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5674: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Hoo",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5675: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Hopkins",
    "Primary Club": "Lloyds TSBRC",
    County: "Devon",
    Country: "England",
  },
  5676: {
    Title: "WO",
    Initials: "E",
    Surname: "Hoyes",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5677: {
    Title: "Mr",
    Initials: "OM",
    Surname: "Hudson",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5678: {
    Title: "Col",
    Initials: "SJ",
    Surname: "Isherwood",
    "Primary Club": "Lee Enfield RA",
    County: "Surrey",
    Country: "England",
  },
  5679: {
    Title: "Cdt",
    Initials: "A",
    Surname: "James",
    "Primary Club": "",
    County: "Gloucestershire",
    Country: "England",
  },
  5680: {
    Title: "",
    Initials: "LJ",
    Surname: "Jansen van Rensburg",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5681: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Jean_marc",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5682: {
    Title: "Mr",
    Initials: "R",
    Surname: "Johnson",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Scotland",
  },
  5683: {
    Title: "WO",
    Initials: "T",
    Surname: "Jones",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5684: {
    Title: "Mr",
    Initials: "PK",
    Surname: "Kapkundos",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  5685: {
    Title: "WO",
    Initials: "P",
    Surname: "Karran",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5686: {
    Title: "Sgt",
    Initials: "CK",
    Surname: "Keenan",
    "Primary Club": "Dorset ACF",
    County: "Dorset",
    Country: "England",
  },
  5687: {
    Title: "Mr",
    Initials: "CK",
    Surname: "Kerr",
    "Primary Club": "ATSC",
    County: "Gloucestershire",
    Country: "England",
  },
  5688: {
    Title: "MWO",
    Initials: "A",
    Surname: "King",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5689: {
    Title: "Mr",
    Initials: "RK",
    Surname: "Knowles",
    "Primary Club": "Abergavenny RC",
    County: "South Wales",
    Country: "",
  },
  5690: {
    Title: "MWO",
    Initials: "M",
    Surname: "Kommes",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5691: {
    Title: "Mr",
    Initials: "M",
    Surname: "Le Vasseur",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "",
  },
  5692: {
    Title: "Mr",
    Initials: "NL",
    Surname: "Lehrter",
    "Primary Club": "Cambridge University RA",
    County: "Surrey",
    Country: "",
  },
  5693: {
    Title: "Miss",
    Initials: "K",
    Surname: "Lindsay",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5694: {
    Title: "Mr",
    Initials: "JWM",
    Surname: "Longhurst",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  5695: {
    Title: "Mr",
    Initials: "I",
    Surname: "Makarenko",
    "Primary Club": "Dorset Riflemen",
    County: "Berkshire",
    Country: "England",
  },
  5696: {
    Title: "Mr",
    Initials: "L",
    Surname: "Malcic",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "England",
  },
  5697: {
    Title: "Miss",
    Initials: "HJ",
    Surname: "Mansell",
    "Primary Club": "English VIII",
    County: "",
    Country: "England",
  },
  5698: {
    Title: "Mr",
    Initials: "ZS",
    Surname: "Martin",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5699: {
    Title: "Mr",
    Initials: "S",
    Surname: "Mason",
    "Primary Club": "Wellington College",
    County: "County of London",
    Country: "England",
  },
  5700: {
    Title: "Mr",
    Initials: "A",
    Surname: "Masson",
    "Primary Club": "Uppingham School",
    County: "Rutland",
    Country: "",
  },
  5701: {
    Title: "Mr",
    Initials: "SWM",
    Surname: "McBain",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  5702: {
    Title: "Miss",
    Initials: "EM",
    Surname: "McGinnis",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5703: {
    Title: "WO",
    Initials: "V",
    Surname: "Melanson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5704: {
    Title: "Miss",
    Initials: "M",
    Surname: "Message",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5705: {
    Title: "WO",
    Initials: "J",
    Surname: "Metcalfe",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5706: {
    Title: "Mr",
    Initials: "AH",
    Surname: "Methven",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5707: {
    Title: "Mr",
    Initials: "GM",
    Surname: "Michel",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5708: {
    Title: "Mr",
    Initials: "B",
    Surname: "Millard",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  5709: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Morrison",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5710: {
    Title: "Mr",
    Initials: "R",
    Surname: "Murray",
    "Primary Club": "Athelings",
    County: "Berkshire",
    Country: "Scotland",
  },
  5711: {
    Title: "Mr",
    Initials: "S",
    Surname: "Murray",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Scotland",
  },
  5712: {
    Title: "Mr",
    Initials: "D",
    Surname: "Neal",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "England",
  },
  5713: {
    Title: "MR",
    Initials: "PG",
    Surname: "Newman",
    "Primary Club": "RAFTRC",
    County: "Oxfordshire",
    Country: "England",
  },
  5714: {
    Title: "Mr",
    Initials: "GFH",
    Surname: "Nichols",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "England",
  },
  5715: {
    Title: "Mr",
    Initials: "JAH",
    Surname: "Nicholls",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5716: {
    Title: "Mr",
    Initials: "HN",
    Surname: "Niu",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "",
  },
  5717: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "North",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5718: {
    Title: "Mr",
    Initials: "MHN",
    Surname: "Palmer",
    "Primary Club": "Old Epsomian RC",
    County: "Surrey",
    Country: "England",
  },
  5719: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Parsons",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  5720: {
    Title: "Mr",
    Initials: "B",
    Surname: "Pepin",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5721: {
    Title: "Mr",
    Initials: "H",
    Surname: "Percival",
    "Primary Club": "Old Suttonians",
    County: "Kent",
    Country: "England",
  },
  5722: {
    Title: "CWO",
    Initials: "JA",
    Surname: "Perron",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5723: {
    Title: "Lady",
    Initials: "RE",
    Surname: "Picknett-Powell",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5724: {
    Title: "Mr",
    Initials: "S",
    Surname: "Pigeon",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5725: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Piper",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  5726: {
    Title: "Mr",
    Initials: "TP",
    Surname: "Pollard",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Wales",
  },
  5727: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Price",
    "Primary Club": "Oyster Rifle Club",
    County: "",
    Country: "England",
  },
  5728: {
    Title: "WO",
    Initials: "E",
    Surname: "Pritchard",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5729: {
    Title: "Mr",
    Initials: "VP",
    Surname: "Prokopyuk",
    "Primary Club": "Lancing College",
    County: "",
    Country: "",
  },
  5730: {
    Title: "Mr",
    Initials: "MPJ",
    Surname: "Pybus",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "England",
  },
  5731: {
    Title: "Miss",
    Initials: "J",
    Surname: "Ramsden",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5732: {
    Title: "Mr",
    Initials: "J",
    Surname: "Rankin",
    "Primary Club": "NRC of Scotland",
    County: "West of Scotland",
    Country: "Scotland",
  },
  5733: {
    Title: "",
    Initials: "DR",
    Surname: "Reineke",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5734: {
    Title: "Cdt",
    Initials: "HS",
    Surname: "Robertson",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  5735: {
    Title: "Mr",
    Initials: "D",
    Surname: "Rosenberger",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5736: {
    Title: "Mr",
    Initials: "T",
    Surname: "Rule",
    "Primary Club": "Lloyds TSBRC",
    County: "Cornwall",
    Country: "",
  },
  5737: {
    Title: "Mr",
    Initials: "WS",
    Surname: "Sheng",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "",
  },
  5738: {
    Title: "Mr",
    Initials: "MP",
    Surname: "Sheppard",
    "Primary Club": "Lloyds TSBRC",
    County: "Lancashire",
    Country: "Wales",
  },
  5739: {
    Title: "Mr",
    Initials: "AT",
    Surname: "Talwar",
    "Primary Club": "Air Training Corps",
    County: "Derbyshire",
    Country: "England",
  },
  5740: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Tancred",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5741: {
    Title: "Mr",
    Initials: "CT",
    Surname: "Tanfin",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  5742: {
    Title: "Mr",
    Initials: "JI",
    Surname: "Thombora",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  5743: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Thorp",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "England",
  },
  5744: {
    Title: "Mr",
    Initials: "DT",
    Surname: "Thursfield",
    "Primary Club": "Durham University",
    County: "Hertfordshire",
    Country: "England",
  },
  5745: {
    Title: "Mr",
    Initials: "OJ",
    Surname: "Trehearne",
    "Primary Club": "Air Training Corps",
    County: "Sussex",
    Country: "England",
  },
  5746: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Tunsley",
    "Primary Club": "Durham University",
    County: "Cambridgeshire",
    Country: "England",
  },
  5747: {
    Title: "Mr",
    Initials: "CV",
    Surname: "Van Beveren",
    "Primary Club": "Old Johnian RC",
    County: "Berkshire",
    Country: "England",
  },
  5748: {
    Title: "Mr",
    Initials: "AV",
    Surname: "Vitart",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5749: {
    Title: "CDT",
    Initials: "M",
    Surname: "Wall",
    "Primary Club": "Epsom College",
    County: "",
    Country: "Scotland",
  },
  5750: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Walley",
    "Primary Club": "607 (Wearmouth) Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  5751: {
    Title: "Sgt",
    Initials: "R",
    Surname: "Walley",
    "Primary Club": "Air Training Corps",
    County: "County Durham",
    Country: "England",
  },
  5752: {
    Title: "Mr",
    Initials: "JB",
    Surname: "Wallis",
    "Primary Club": "SCRA",
    County: "Somerset",
    Country: "England",
  },
  5753: {
    Title: "Mr",
    Initials: "M",
    Surname: "Warne",
    "Primary Club": "Shield RC",
    County: "Surrey",
    Country: "England",
  },
  5754: {
    Title: "Mr",
    Initials: "M",
    Surname: "Webb",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  5755: {
    Title: "Mr",
    Initials: "HEJ",
    Surname: "Welch",
    "Primary Club": "Athelings",
    County: "Cumbria",
    Country: "England",
  },
  5756: {
    Title: "Mr",
    Initials: "JW",
    Surname: "West",
    "Primary Club": "NRA",
    County: "Norfolk",
    Country: "England",
  },
  5757: {
    Title: "Mr",
    Initials: "SN",
    Surname: "West",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  5758: {
    Title: "Lt RN",
    Initials: "TJ",
    Surname: "Westmas",
    "Primary Club": "RNTRC",
    County: "",
    Country: "",
  },
  5759: {
    Title: "WO",
    Initials: "S",
    Surname: "Wilkinson-DeRoche",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5760: {
    Title: "Mr",
    Initials: "L",
    Surname: "Wittenburg",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  5761: {
    Title: "",
    Initials: "G",
    Surname: "Witthaus",
    "Primary Club": "South Africa U-19",
    County: "",
    Country: "South Africa",
  },
  5762: {
    Title: "Mr",
    Initials: "W",
    Surname: "Wycherley",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5763: {
    Title: "Dr",
    Initials: "J",
    Surname: "Xiao",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "England",
  },
  5764: {
    Title: "Mr",
    Initials: "GA",
    Surname: "Aitchison",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "Australia",
  },
  5765: {
    Title: "Miss",
    Initials: "R",
    Surname: "Allan",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5766: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Amoroso",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5767: {
    Title: "Miss",
    Initials: "D",
    Surname: "Armstrong",
    "Primary Club": "Sedbergh School",
    County: "Northumberland",
    Country: "England",
  },
  5768: {
    Title: "Mr",
    Initials: "T",
    Surname: "Baillon",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5769: {
    Title: "Mr",
    Initials: "DB",
    Surname: "Ball",
    "Primary Club": "Leeds Service RC",
    County: "Yorkshire",
    Country: "England",
  },
  5770: {
    Title: "Mr",
    Initials: "R",
    Surname: "Batchelor",
    "Primary Club": "Welsh RA",
    County: "",
    Country: "Wales",
  },
  5771: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Beaumont",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5772: {
    Title: "Mr",
    Initials: "M",
    Surname: "Bell",
    "Primary Club": "Old Oakhamians RC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5773: {
    Title: "Mr",
    Initials: "A",
    Surname: "Bennett-Wallis",
    "Primary Club": "Bradfield College",
    County: "Oxfordshire",
    Country: "England",
  },
  5774: {
    Title: "Mr",
    Initials: "S",
    Surname: "Benson",
    "Primary Club": "ATSC",
    County: "South Wales",
    Country: "England",
  },
  5775: {
    Title: "Mr",
    Initials: "G",
    Surname: "Bible",
    "Primary Club": "ATSC",
    County: "Wiltshire",
    Country: "England",
  },
  5776: {
    Title: "Mr",
    Initials: "A",
    Surname: "Booker",
    "Primary Club": "SCRA",
    County: "Somerset",
    Country: "England",
  },
  5777: {
    Title: "Mr",
    Initials: "GPC",
    Surname: "Bramwell",
    "Primary Club": "Altcar RC",
    County: "Lancashire",
    Country: "England",
  },
  5778: {
    Title: "LCpl",
    Initials: "J",
    Surname: "Brown",
    "Primary Club": "Northumbria ACF",
    County: "Buckinghamshire",
    Country: "England",
  },
  5779: {
    Title: "Miss",
    Initials: "A",
    Surname: "Burrows",
    "Primary Club": "Old Stamfordians",
    County: "Lincolnshire",
    Country: "England",
  },
  5780: {
    Title: "Miss",
    Initials: "HNL",
    Surname: "Butler",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  5781: {
    Title: "Mr",
    Initials: "T",
    Surname: "Chapman",
    "Primary Club": "North Herts Rifles",
    County: "Hertfordshire",
    Country: "England",
  },
  5782: {
    Title: "Mr",
    Initials: "FA",
    Surname: "Chapman-Sheath",
    "Primary Club": "NLRC",
    County: "Lincolnshire",
    Country: "England",
  },
  5783: {
    Title: "Mr",
    Initials: "A",
    Surname: "Cheetham",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5784: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Ching",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  5785: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Clark",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  5786: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Clark",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5787: {
    Title: "Mr",
    Initials: "R",
    Surname: "Clarkson",
    "Primary Club": "Mars & Minerva",
    County: "Hampshire",
    Country: "England",
  },
  5788: {
    Title: "Miss",
    Initials: "EC",
    Surname: "Colgan",
    "Primary Club": "607 (Wearmouth) Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  5789: {
    Title: "LCpl",
    Initials: "B",
    Surname: "Corbishley",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5790: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Corkum",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5791: {
    Title: "Mr",
    Initials: "LR",
    Surname: "Coventry",
    "Primary Club": "Manchester RC",
    County: "Cheshire",
    Country: "England",
  },
  5792: {
    Title: "Mr",
    Initials: "DC",
    Surname: "Crook",
    "Primary Club": "Cardiff University",
    County: "South Wales",
    Country: "England",
  },
  5793: {
    Title: "Cdt",
    Initials: "TC",
    Surname: "Cubitt",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5794: {
    Title: "Miss",
    Initials: "CH",
    Surname: "Cuming",
    "Primary Club": "NLRC",
    County: "Yorkshire",
    Country: "England",
  },
  5795: {
    Title: "LCpl",
    Initials: "J",
    Surname: "Davies",
    "Primary Club": "Northumbria ACF",
    County: "Buckinghamshire",
    Country: "England",
  },
  5796: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Desjardins",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5797: {
    Title: "Mr",
    Initials: "PA",
    Surname: "Dommett",
    "Primary Club": "Tonbridge R&PC",
    County: "",
    Country: "England",
  },
  5798: {
    Title: "Mr",
    Initials: "T",
    Surname: "Douglas",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5799: {
    Title: "LCpl",
    Initials: "A",
    Surname: "Egereva",
    "Primary Club": "Berkshire ACF",
    County: "",
    Country: "",
  },
  5800: {
    Title: "Mr",
    Initials: "D",
    Surname: "Epton",
    "Primary Club": "Oakham School",
    County: "Lincolnshire",
    Country: "England",
  },
  5801: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Fantozzi",
    "Primary Club": "Northumbria ACF",
    County: "Buckinghamshire",
    Country: "England",
  },
  5802: {
    Title: "Mr",
    Initials: "I",
    Surname: "Farbon",
    "Primary Club": "LMRA",
    County: "Cambridgeshire",
    Country: "England",
  },
  5803: {
    Title: "Mr",
    Initials: "C",
    Surname: "Farr",
    "Primary Club": "Somerset CRA",
    County: "",
    Country: "England",
  },
  5804: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Fata",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5805: {
    Title: "Cdt LCpl",
    Initials: "S",
    Surname: "Flanagan",
    "Primary Club": "Athelings",
    County: "Hampshire",
    Country: "England",
  },
  5806: {
    Title: "Cdt",
    Initials: "Z",
    Surname: "Flavell",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5807: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Fox",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  5808: {
    Title: "Miss",
    Initials: "K",
    Surname: "Foye",
    "Primary Club": "Oxford University RC",
    County: "Gloucestershire",
    Country: "England",
  },
  5809: {
    Title: "Mr",
    Initials: "S",
    Surname: "Giannikas",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5810: {
    Title: "Cdt",
    Initials: "H",
    Surname: "Glawson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5811: {
    Title: "Mr",
    Initials: "J",
    Surname: "Goss",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Channel Islands",
  },
  5812: {
    Title: "Miss",
    Initials: "EF",
    Surname: "Graham",
    "Primary Club": "Exeter University RC",
    County: "Dorset",
    Country: "England",
  },
  5813: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Guy",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5814: {
    Title: "Mr",
    Initials: "C",
    Surname: "Hayes",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  5815: {
    Title: "Mr",
    Initials: "BAM",
    Surname: "Hebblethwaite",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  5816: {
    Title: "Mr",
    Initials: "AW",
    Surname: "Hellings",
    "Primary Club": "Old Guildfordians RC",
    County: "Hampshire",
    Country: "England",
  },
  5817: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Hewitt",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5818: {
    Title: "Mr",
    Initials: "M",
    Surname: "Horton-Baker",
    "Primary Club": "Durham University",
    County: "Yorkshire",
    Country: "Wales",
  },
  5819: {
    Title: "Miss",
    Initials: "AH",
    Surname: "Howell",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  5820: {
    Title: "Mr",
    Initials: "D",
    Surname: "Howseman",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  5821: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Huang",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5822: {
    Title: "Miss",
    Initials: "CH",
    Surname: "Hudson",
    "Primary Club": "607 (Wearmouth) Sqn ATC",
    County: "County Durham",
    Country: "England",
  },
  5823: {
    Title: "Ms",
    Initials: "MT",
    Surname: "Hughes",
    "Primary Club": "Caterham & District RC",
    County: "Surrey",
    Country: "England",
  },
  5824: {
    Title: "Sgt",
    Initials: "O",
    Surname: "Hutt",
    "Primary Club": "BCRT",
    County: "Perthshire",
    Country: "Scotland",
  },
  5825: {
    Title: "Miss",
    Initials: "AI",
    Surname: "Islam",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5826: {
    Title: "Miss",
    Initials: "C",
    Surname: "Jacklin",
    "Primary Club": "Oxford University RC",
    County: "Lincolnshire",
    Country: "England",
  },
  5827: {
    Title: "Miss",
    Initials: "BJ",
    Surname: "James",
    "Primary Club": "241 Sqn",
    County: "Essex",
    Country: "England",
  },
  5828: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Jatoi",
    "Primary Club": "",
    County: "",
    Country: "Pakistan",
  },
  5829: {
    Title: "Mr",
    Initials: "J",
    Surname: "Johnston",
    "Primary Club": "1st (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  5830: {
    Title: "Cdt Sgt",
    Initials: "TR",
    Surname: "Jones",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5831: {
    Title: "Miss",
    Initials: "K",
    Surname: "Kapustina",
    "Primary Club": "OGRE",
    County: "Norfolk",
    Country: "England",
  },
  5832: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Kavanagh",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5833: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Keates",
    "Primary Club": "ATSC",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5834: {
    Title: "C/Smg",
    Initials: "J",
    Surname: "Kelly",
    "Primary Club": "Berkshire ACF",
    County: "Berkshire",
    Country: "England",
  },
  5835: {
    Title: "Mr",
    Initials: "FK",
    Surname: "Key",
    "Primary Club": "OGRE",
    County: "Lincolnshire",
    Country: "England",
  },
  5836: {
    Title: "Mr",
    Initials: "CK",
    Surname: "Kiptarus",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  5837: {
    Title: "Mr",
    Initials: "NK",
    Surname: "Konovalchik",
    "Primary Club": "LMRA",
    County: "Middlesex",
    Country: "England",
  },
  5838: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Laflamme",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5839: {
    Title: "Cdt",
    Initials: "W",
    Surname: "Lam",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5840: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Liu",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5841: {
    Title: "Cadet",
    Initials: "L",
    Surname: "Lyons",
    "Primary Club": "UKCRT",
    County: "Gloucestershire",
    Country: "England",
  },
  5842: {
    Title: "CWO",
    Initials: "R",
    Surname: "Melhuish",
    "Primary Club": "Welsh RA",
    County: "",
    Country: "Wales",
  },
  5843: {
    Title: "LCpl",
    Initials: "H",
    Surname: "Milner-Benham",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5844: {
    Title: "Mr",
    Initials: "CM",
    Surname: "Moore",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  5845: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Mulholland",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5846: {
    Title: "Mr",
    Initials: "W",
    Surname: "Mulholland",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5847: {
    Title: "Cadet",
    Initials: "J",
    Surname: "Murphy",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  5848: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Naidu",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5849: {
    Title: "Mr",
    Initials: "LO",
    Surname: "O'Connell",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5850: {
    Title: "Mr",
    Initials: "LVG",
    Surname: "O'Flynn",
    "Primary Club": "Old Guildfordians RC",
    County: "Surrey",
    Country: "Ireland",
  },
  5851: {
    Title: "Mr",
    Initials: "LP",
    Surname: "Parkins",
    "Primary Club": "Athelings",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5852: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Perry",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5853: {
    Title: "Mr",
    Initials: "D",
    Surname: "Raybould",
    "Primary Club": "Staveley",
    County: "",
    Country: "England",
  },
  5854: {
    Title: "Mr",
    Initials: "P",
    Surname: "Reddish",
    "Primary Club": "ATSC",
    County: "Nottinghamshire",
    Country: "England",
  },
  5855: {
    Title: "Mr",
    Initials: "IR",
    Surname: "Reeves",
    "Primary Club": "Oakham School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5856: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Rennie",
    "Primary Club": "Cambridge University RA",
    County: "Sussex",
    Country: "England",
  },
  5857: {
    Title: "Mr",
    Initials: "J",
    Surname: "Robbins",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5858: {
    Title: "Mr",
    Initials: "A",
    Surname: "Rooney",
    "Primary Club": "Oakham School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  5859: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Rorison",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  5860: {
    Title: "Mrs",
    Initials: "N",
    Surname: "Rossignol",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5861: {
    Title: "Mr",
    Initials: "MS",
    Surname: "Sayers",
    "Primary Club": "Durham ACF",
    County: "County Durham",
    Country: "England",
  },
  5862: {
    Title: "Hon",
    Initials: "RMJ",
    Surname: "Sharman",
    "Primary Club": "Dorset Riflemen",
    County: "Wiltshire",
    Country: "England",
  },
  5863: {
    Title: "Miss",
    Initials: "G",
    Surname: "Shearer",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  5864: {
    Title: "Mrs",
    Initials: "CE",
    Surname: "Silver",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  5865: {
    Title: "Mr",
    Initials: "M",
    Surname: "Silver",
    "Primary Club": "NRA",
    County: "Sussex",
    Country: "England",
  },
  5866: {
    Title: "Miss",
    Initials: "S",
    Surname: "Sindoi",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  5867: {
    Title: "Mr",
    Initials: "AS",
    Surname: "Smyth",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  5868: {
    Title: "Mr",
    Initials: "B",
    Surname: "Soelzer",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  5869: {
    Title: "Dr",
    Initials: "G",
    Surname: "Stewart",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  5870: {
    Title: "Mr",
    Initials: "M",
    Surname: "Strudwick",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  5871: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Sutton",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Scotland",
  },
  5872: {
    Title: "Cdt",
    Initials: "TT",
    Surname: "Tancred",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5873: {
    Title: "Mr",
    Initials: "LT",
    Surname: "Taylor",
    "Primary Club": "2nd (NI) BN ACF",
    County: "Ulster",
    Country: "Ireland",
  },
  5874: {
    Title: "Cdt Cpl",
    Initials: "G",
    Surname: "Templar",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "",
  },
  5875: {
    Title: "Mr",
    Initials: "GR",
    Surname: "Thomas",
    "Primary Club": "LMRA",
    County: "South Wales",
    Country: "Wales",
  },
  5876: {
    Title: "Miss",
    Initials: "LT",
    Surname: "Thomas",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  5877: {
    Title: "Dr",
    Initials: "ML",
    Surname: "Thomas",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  5878: {
    Title: "Mr",
    Initials: "WT",
    Surname: "Tiffin",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  5879: {
    Title: "Cdt",
    Initials: "AJ",
    Surname: "Todd",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  5880: {
    Title: "Mr",
    Initials: "M",
    Surname: "Todd",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  5881: {
    Title: "Mr",
    Initials: "H",
    Surname: "Vaughan-Johnson",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  5882: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Vinarski",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5883: {
    Title: "Mr",
    Initials: "A",
    Surname: "Wahid",
    "Primary Club": "NRA",
    County: "Middlesex",
    Country: "Pakistan",
  },
  5884: {
    Title: "Mr",
    Initials: "G",
    Surname: "Waugh",
    "Primary Club": "CNRC",
    County: "",
    Country: "England",
  },
  5885: {
    Title: "Mr",
    Initials: "GN",
    Surname: "Welch",
    "Primary Club": "Sedbergh School",
    County: "Yorkshire",
    Country: "England",
  },
  5886: {
    Title: "Miss",
    Initials: "S",
    Surname: "Wentges",
    "Primary Club": "Irish Rifle Club",
    County: "",
    Country: "Ireland",
  },
  5887: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Whitaker",
    "Primary Club": "Durham University",
    County: "Kent",
    Country: "England",
  },
  5888: {
    Title: "CSgt",
    Initials: "A",
    Surname: "Wilson",
    "Primary Club": "",
    County: "Kent",
    Country: "England",
  },
  5889: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Woodger",
    "Primary Club": "Oxford University RC",
    County: "Sussex",
    Country: "England",
  },
  5890: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Yates",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  5891: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Zhou",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  5994: {
    Title: "Mr",
    Initials: "LS",
    Surname: "Smith",
    "Primary Club": "Old Sedberghians",
    County: "Lancashire",
    Country: "England",
  },
  5995: {
    Title: "Cdt",
    Initials: "HC",
    Surname: "Adams",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  5996: {
    Title: "Mrs",
    Initials: "K",
    Surname: "Airey",
    "Primary Club": "Staveley",
    County: "Derbyshire",
    Country: "England",
  },
  5997: {
    Title: "Mr",
    Initials: "J",
    Surname: "Allan",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  5998: {
    Title: "Mr",
    Initials: "M",
    Surname: "Auriol",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  5999: {
    Title: "Mr",
    Initials: "P",
    Surname: "Auroil",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  6000: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Barbe",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  6001: {
    Title: "Mr",
    Initials: "TJ",
    Surname: "Barclay",
    "Primary Club": "Uppingham Veterans RC",
    County: "Rutland",
    Country: "",
  },
  6002: {
    Title: "Miss",
    Initials: "NE",
    Surname: "Barnes",
    "Primary Club": "Bedfordians RC",
    County: "Hertfordshire",
    Country: "Wales",
  },
  6003: {
    Title: "",
    Initials: "LSG",
    Surname: "Barnett",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6004: {
    Title: "Mr",
    Initials: "F",
    Surname: "Barratt",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "",
  },
  6005: {
    Title: "Miss",
    Initials: "F",
    Surname: "Beard",
    "Primary Club": "",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  6006: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Beddoe",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6007: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Billings",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6008: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Birch",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6009: {
    Title: "Mr",
    Initials: "DM",
    Surname: "Booysen",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  6010: {
    Title: "Mr",
    Initials: "S",
    Surname: "Bose",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6011: {
    Title: "Mr",
    Initials: "H",
    Surname: "Bradshaw",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  6012: {
    Title: "Air Cdre",
    Initials: "ANC",
    Surname: "Bramwell",
    "Primary Club": "Shrewsbury School CCF",
    County: "Lancashire",
    Country: "England",
  },
  6014: {
    Title: "Dr",
    Initials: "PFBH",
    Surname: "Buchanan",
    "Primary Club": "NRC of Scotland",
    County: "",
    Country: "Scotland",
  },
  6015: {
    Title: "Mr",
    Initials: "M",
    Surname: "Budden",
    "Primary Club": "Cardiff University",
    County: "Suffolk",
    Country: "Wales",
  },
  6016: {
    Title: "Mr",
    Initials: "H",
    Surname: "Bull",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  6017: {
    Title: "Mr",
    Initials: "F",
    Surname: "Bull",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6018: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Butler",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  6019: {
    Title: "Mr",
    Initials: "DW",
    Surname: "Campbell",
    "Primary Club": "RAF Air Cadets",
    County: "Aberdeenshire",
    Country: "Scotland",
  },
  6020: {
    Title: "Cdt",
    Initials: "F",
    Surname: "Carrier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6021: {
    Title: "Miss",
    Initials: "C",
    Surname: "Catuogno-Cal",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6022: {
    Title: "Miss",
    Initials: "CS",
    Surname: "Chan",
    "Primary Club": "Stock Exchange RC",
    County: "Kent",
    Country: "England",
  },
  6023: {
    Title: "Mr",
    Initials: "C",
    Surname: "Chen",
    "Primary Club": "BCRT",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6024: {
    Title: "Mr",
    Initials: "SER",
    Surname: "Cherry",
    "Primary Club": "Oxford University RC",
    County: "Surrey",
    Country: "England",
  },
  6025: {
    Title: "Mr",
    Initials: "N",
    Surname: "Clark",
    "Primary Club": "Jaguar RC",
    County: "",
    Country: "England",
  },
  6026: {
    Title: "Ms",
    Initials: "S",
    Surname: "Cleeve",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "",
  },
  6027: {
    Title: "Mr",
    Initials: "T",
    Surname: "Clifford",
    "Primary Club": "Old Lancing Rifle Club",
    County: "Sussex",
    Country: "England",
  },
  6028: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Couillard",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6029: {
    Title: "Mr",
    Initials: "CS",
    Surname: "Courtness",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  6030: {
    Title: "Mr",
    Initials: "D",
    Surname: "Cownley",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  6031: {
    Title: "Mr",
    Initials: "TS",
    Surname: "Craig-Fleming",
    "Primary Club": "Lancing College",
    County: "Sussex",
    Country: "England",
  },
  6032: {
    Title: "Miss",
    Initials: "D",
    Surname: "de Carpentier",
    "Primary Club": "Old Nottinghamians",
    County: "Nottinghamshire",
    Country: "England",
  },
  6033: {
    Title: "Mr",
    Initials: "B",
    Surname: "Digby",
    "Primary Club": "Tugela Commando Club",
    County: "",
    Country: "South Africa",
  },
  6034: {
    Title: "Mr",
    Initials: "HM",
    Surname: "Dodds",
    "Primary Club": "Old Tonbridgian",
    County: "Kent",
    Country: "England",
  },
  6035: {
    Title: "Mr",
    Initials: "M",
    Surname: "Downing",
    "Primary Club": "Dorset Riflemen",
    County: "Gloucestershire",
    Country: "England",
  },
  6036: {
    Title: "Mr",
    Initials: "C",
    Surname: "Drennan",
    "Primary Club": "Welsh RA",
    County: "",
    Country: "Wales",
  },
  6037: {
    Title: "Mr",
    Initials: "P",
    Surname: "Du Toit",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  6038: {
    Title: "Cdt",
    Initials: "LA",
    Surname: "Duffett",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "",
  },
  6039: {
    Title: "Cdt",
    Initials: "JC",
    Surname: "Duffy",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6040: {
    Title: "Fg Off",
    Initials: "L",
    Surname: "Falconbridge",
    "Primary Club": "RAF Air Cmd",
    County: "Surrey",
    Country: "England",
  },
  6041: {
    Title: "Mr",
    Initials: "I",
    Surname: "Farnell",
    "Primary Club": "Club 25",
    County: "Cambridgeshire",
    Country: "England",
  },
  6042: {
    Title: "Miss",
    Initials: "C",
    Surname: "Foley",
    "Primary Club": "Sevenoaks School",
    County: "Surrey",
    Country: "England",
  },
  6043: {
    Title: "Miss",
    Initials: "M",
    Surname: "Foort",
    "Primary Club": "Oakham School",
    County: "",
    Country: "",
  },
  6044: {
    Title: "Mr",
    Initials: "A",
    Surname: "Ford",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  6045: {
    Title: "Miss",
    Initials: "A",
    Surname: "Forsyth",
    "Primary Club": "",
    County: "",
    Country: "Scotland",
  },
  6046: {
    Title: "Mr",
    Initials: "JT",
    Surname: "Foster",
    "Primary Club": "Centerville Small Arms RC",
    County: "",
    Country: "United States",
  },
  6047: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Fournier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6048: {
    Title: "Mr",
    Initials: "D",
    Surname: "Fox",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6049: {
    Title: "Mr",
    Initials: "EPB",
    Surname: "Garnier",
    "Primary Club": "Charterhouse",
    County: "Worcestershire",
    Country: "England",
  },
  6050: {
    Title: "Mr",
    Initials: "M",
    Surname: "Garrood",
    "Primary Club": "Bradfield College",
    County: "Buckinghamshire",
    Country: "England",
  },
  6051: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Giasson-Pitre",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6052: {
    Title: "Cdt",
    Initials: "AL",
    Surname: "Gibbs",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6053: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Gontek",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  6054: {
    Title: "Miss",
    Initials: "AP",
    Surname: "Good",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6055: {
    Title: "Miss",
    Initials: "L",
    Surname: "Gorgens",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  6056: {
    Title: "Mr",
    Initials: "M",
    Surname: "Halstead",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6057: {
    Title: "SAC (T)",
    Initials: "W",
    Surname: "Hammond",
    "Primary Club": "RAF Air Cmd",
    County: "Cornwall",
    Country: "England",
  },
  6058: {
    Title: "Mr",
    Initials: "C",
    Surname: "Harborne",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6059: {
    Title: "Mr",
    Initials: "W",
    Surname: "Hart",
    "Primary Club": "Bedfordians RC",
    County: "Norfolk",
    Country: "England",
  },
  6060: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Hayden",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6061: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Hendrikz",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  6062: {
    Title: "Mr",
    Initials: "N",
    Surname: "Hendrikz",
    "Primary Club": "SA Junior",
    County: "",
    Country: "South Africa",
  },
  6063: {
    Title: "Cdt",
    Initials: "CAV",
    Surname: "Hilton",
    "Primary Club": "Oundle School",
    County: "London",
    Country: "England",
  },
  6064: {
    Title: "Miss",
    Initials: "KG",
    Surname: "Hines",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  6065: {
    Title: "Mr",
    Initials: "J",
    Surname: "Hogan",
    "Primary Club": "Wellington College",
    County: "Oxfordshire",
    Country: "England",
  },
  6066: {
    Title: "Mr",
    Initials: "M",
    Surname: "Horsnell",
    "Primary Club": "Australian Rifle Team",
    County: "",
    Country: "Australia",
  },
  6067: {
    Title: "Cpl",
    Initials: "MS",
    Surname: "House",
    "Primary Club": "RAFTRC",
    County: "Hampshire",
    Country: "England",
  },
  6068: {
    Title: "Mr",
    Initials: "CP",
    Surname: "Hughes",
    "Primary Club": "NRA",
    County: "Hertfordshire",
    Country: "England",
  },
  6069: {
    Title: "Miss",
    Initials: "A",
    Surname: "Hutton",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6070: {
    Title: "Cpl",
    Initials: "LD",
    Surname: "Janney",
    "Primary Club": "ATSC",
    County: "",
    Country: "England",
  },
  6071: {
    Title: "Mr",
    Initials: "J",
    Surname: "Jeffery",
    "Primary Club": "Lyndhurst RC",
    County: "",
    Country: "Australia",
  },
  6072: {
    Title: "Miss",
    Initials: "KJA",
    Surname: "Johnson",
    "Primary Club": "Comber RC",
    County: "Ulster",
    Country: "Ireland",
  },
  6073: {
    Title: "Sgt",
    Initials: "F",
    Surname: "Johnston",
    "Primary Club": "BCRT",
    County: "Essex",
    Country: "",
  },
  6074: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Keeling",
    "Primary Club": "NLRC",
    County: "Sussex",
    Country: "England",
  },
  6075: {
    Title: "Mr",
    Initials: "J",
    Surname: "Kellinger",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  6076: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Kim",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6077: {
    Title: "Mr",
    Initials: "R",
    Surname: "Kinch",
    "Primary Club": "Cardiff University",
    County: "Berkshire",
    Country: "England",
  },
  6078: {
    Title: "Mr",
    Initials: "A",
    Surname: "Kreutz",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  6079: {
    Title: "Miss",
    Initials: "A",
    Surname: "Lawrence",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "",
  },
  6080: {
    Title: "Fg Off",
    Initials: "O",
    Surname: "Lazko",
    "Primary Club": "RAF Air Cmd",
    County: "Hertfordshire",
    Country: "England",
  },
  6081: {
    Title: "Ms",
    Initials: "IJE",
    Surname: "Logan",
    "Primary Club": "Wandsworth FBRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6082: {
    Title: "CSgt",
    Initials: "M",
    Surname: "Luckyn-Malone",
    "Primary Club": "BCRT",
    County: "",
    Country: "Germany",
  },
  6083: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Macdonald",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6084: {
    Title: "Mr",
    Initials: "C",
    Surname: "Maher",
    "Primary Club": "Canberra RC",
    County: "",
    Country: "Australia",
  },
  6085: {
    Title: "Mr",
    Initials: "HM",
    Surname: "Marden",
    "Primary Club": "Durham University",
    County: "Hampshire",
    Country: "England",
  },
  6086: {
    Title: "Mr",
    Initials: "H",
    Surname: "Mayne",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  6087: {
    Title: "LCpl",
    Initials: "RP",
    Surname: "McGowan",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6088: {
    Title: "Cdt",
    Initials: "L",
    Surname: "McPherson",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6089: {
    Title: "Mr",
    Initials: "R",
    Surname: "Middleton",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6090: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Morin",
    "Primary Club": "BCRT",
    County: "Jersey",
    Country: "Jersey",
  },
  6091: {
    Title: "Cdt",
    Initials: "K",
    Surname: "Morrison",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6092: {
    Title: "Miss",
    Initials: "AK",
    Surname: "Morriss",
    "Primary Club": "Bradfield College",
    County: "London",
    Country: "England",
  },
  6093: {
    Title: "Mr",
    Initials: "APW",
    Surname: "Moulton",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6094: {
    Title: "Mr",
    Initials: "EN",
    Surname: "Munro",
    "Primary Club": "West Atholl RC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6095: {
    Title: "CSM",
    Initials: "HM",
    Surname: "Murray",
    "Primary Club": "Athelings",
    County: "Northumberland",
    Country: "England",
  },
  6096: {
    Title: "Mr",
    Initials: "LM",
    Surname: "Mutegi",
    "Primary Club": "",
    County: "",
    Country: "Kenya",
  },
  6097: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Nielsen",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "",
  },
  6098: {
    Title: "Mr",
    Initials: "TVCF",
    Surname: "Nightingale",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6099: {
    Title: "Mr",
    Initials: "BO",
    Surname: "O'Shea-Smith",
    "Primary Club": "SCRA",
    County: "Somerset",
    Country: "England",
  },
  6100: {
    Title: "Mr",
    Initials: "R",
    Surname: "Ochaba",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  6101: {
    Title: "Dr",
    Initials: "Tobias",
    Surname: "Pantke",
    "Primary Club": "Jersey RA",
    County: "",
    Country: "Jersey",
  },
  6102: {
    Title: "Cpl",
    Initials: "P",
    Surname: "Paudyal",
    "Primary Club": "UKCRT",
    County: "Gloucestershire",
    Country: "Scotland",
  },
  6103: {
    Title: "Mr",
    Initials: "J",
    Surname: "Peters",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "USA",
  },
  6105: {
    Title: "Mr",
    Initials: "WP",
    Surname: "Playle",
    "Primary Club": "North Herts Rifles",
    County: "Hertfordshire",
    Country: "England",
  },
  6106: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Powers",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6107: {
    Title: "Mr",
    Initials: "LP",
    Surname: "Prodan",
    "Primary Club": "Italian Sport SU",
    County: "",
    Country: "Italy",
  },
  6108: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Putney",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6109: {
    Title: "Mrs",
    Initials: "GL",
    Surname: "Rantile",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  6110: {
    Title: "Mr",
    Initials: "A",
    Surname: "Richardson",
    "Primary Club": "OO Troubleshooters",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  6111: {
    Title: "Mr",
    Initials: "ND",
    Surname: "Richmond",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "Ireland",
  },
  6112: {
    Title: "LCpl",
    Initials: "B",
    Surname: "Rigo",
    "Primary Club": "BCRT",
    County: "Surrey",
    Country: "",
  },
  6113: {
    Title: "Mr",
    Initials: "JB",
    Surname: "Roberts",
    "Primary Club": "West Atholl RC",
    County: "",
    Country: "Scotland",
  },
  6114: {
    Title: "Miss",
    Initials: "EMG",
    Surname: "Robertson",
    "Primary Club": "East of Scotland RC",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6115: {
    Title: "Mr",
    Initials: "EB",
    Surname: "Robinson",
    "Primary Club": "Hampshire RA",
    County: "Hampshire",
    Country: "England",
  },
  6116: {
    Title: "Cdt Cpl",
    Initials: "M",
    Surname: "Rollisson",
    "Primary Club": "CCRS",
    County: "Derbyshire",
    Country: "England",
  },
  6117: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Shelley",
    "Primary Club": "OO Troubleshooters",
    County: "Suffolk",
    Country: "England",
  },
  6118: {
    Title: "Mr",
    Initials: "M",
    Surname: "Shepherd",
    "Primary Club": "Endeavour RC",
    County: "",
    Country: "Australia",
  },
  6119: {
    Title: "Mr",
    Initials: "JP",
    Surname: "Short",
    "Primary Club": "Athelings",
    County: "",
    Country: "",
  },
  6120: {
    Title: "Mr",
    Initials: "A",
    Surname: "Simpson",
    "Primary Club": "Artists RC",
    County: "Sussex",
    Country: "England",
  },
  6121: {
    Title: "Mr",
    Initials: "W",
    Surname: "Skinner",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  6122: {
    Title: "Mr",
    Initials: "WJ",
    Surname: "Slatford",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  6123: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Smith",
    "Primary Club": "USA",
    County: "",
    Country: "United States",
  },
  6124: {
    Title: "Miss",
    Initials: "EN",
    Surname: "Smith",
    "Primary Club": "Epsom College",
    County: "Sussex",
    Country: "England",
  },
  6125: {
    Title: "Fg Off",
    Initials: "J",
    Surname: "Spratt",
    "Primary Club": "RAF Air Cmd",
    County: "Surrey",
    Country: "England",
  },
  6126: {
    Title: "Mr",
    Initials: "K",
    Surname: "Stewart",
    "Primary Club": "Athelings",
    County: "Ulster",
    Country: "",
  },
  6127: {
    Title: "Miss",
    Initials: "A",
    Surname: "Strong",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  6128: {
    Title: "Mr",
    Initials: "A",
    Surname: "Strudwick",
    "Primary Club": "Sedbergh School",
    County: "",
    Country: "Scotland",
  },
  6129: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Tarnowski",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6130: {
    Title: "Air Cdre",
    Initials: "OMJ",
    Surname: "Taylor",
    "Primary Club": "Shrewsbury School CCF",
    County: "Cheshire",
    Country: "England",
  },
  6131: {
    Title: "Mr",
    Initials: "D",
    Surname: "Thomas Du Toit",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6132: {
    Title: "Mr",
    Initials: "J",
    Surname: "Tomlinson",
    "Primary Club": "English XX",
    County: "Lancashire",
    Country: "England",
  },
  6133: {
    Title: "Mr",
    Initials: "L",
    Surname: "Townshend",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "",
  },
  6134: {
    Title: "Cdt",
    Initials: "V",
    Surname: "Tremblay",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6135: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Tucker",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6136: {
    Title: "Mr",
    Initials: "GDP",
    Surname: "Twinn",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6137: {
    Title: "LCpl",
    Initials: "EM",
    Surname: "Venn",
    "Primary Club": "Somerset ACF",
    County: "Somerset",
    Country: "England",
  },
  6138: {
    Title: "Mr",
    Initials: "E",
    Surname: "Vert",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Russia",
  },
  6139: {
    Title: "Mr",
    Initials: "G",
    Surname: "Viala",
    "Primary Club": "Groupe Mike",
    County: "",
    Country: "",
  },
  6140: {
    Title: "Cdt",
    Initials: "JC",
    Surname: "Voyer",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6141: {
    Title: "Mr",
    Initials: "HJ",
    Surname: "Waldern",
    "Primary Club": "Surrey RA",
    County: "Surrey",
    Country: "England",
  },
  6142: {
    Title: "Mr",
    Initials: "T",
    Surname: "Westmaas",
    "Primary Club": "RNTRC",
    County: "",
    Country: "",
  },
  6143: {
    Title: "Mr",
    Initials: "D",
    Surname: "Wilks",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6144: {
    Title: "Mr",
    Initials: "A",
    Surname: "Williams",
    "Primary Club": "101 RC",
    County: "Lancashire",
    Country: "England",
  },
  6145: {
    Title: "LCpl",
    Initials: "A",
    Surname: "Wiseman",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "Scotland",
  },
  6146: {
    Title: "Cdt",
    Initials: "MG",
    Surname: "Woodcock",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6147: {
    Title: "Miss",
    Initials: "A",
    Surname: "Woodland",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6148: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Woodsford",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6149: {
    Title: "Mr",
    Initials: "J",
    Surname: "Woollett",
    "Primary Club": "Oxford University RC",
    County: "Hampshire",
    Country: "England",
  },
  6150: {
    Title: "Prince",
    Initials: "A",
    Surname: "zu Sayn-Wittgenstein-Sayn",
    "Primary Club": "Charterhouse",
    County: "Shropshire",
    Country: "England",
  },
  6151: {
    Title: "Mr",
    Initials: "N",
    Surname: "Ackerman",
    "Primary Club": "East Bristol RC",
    County: "Somerset",
    Country: "England",
  },
  6152: {
    Title: "Mr",
    Initials: "DH",
    Surname: "Acton",
    "Primary Club": "BYSA",
    County: "London",
    Country: "England",
  },
  6153: {
    Title: "Mr",
    Initials: "W",
    Surname: "Adams",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "England",
  },
  6154: {
    Title: "Mr",
    Initials: "SCK",
    Surname: "Agnew",
    "Primary Club": "Surrey RC",
    County: "Norfolk",
    Country: "England",
  },
  6155: {
    Title: "Mr",
    Initials: "T",
    Surname: "Allen Baptista",
    "Primary Club": "Sevenoaks School",
    County: "Kent",
    Country: "England",
  },
  6156: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Allinott",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6157: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Alvarez",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6158: {
    Title: "Mr",
    Initials: "I",
    Surname: "Archer",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  6159: {
    Title: "Pte",
    Initials: "HG",
    Surname: "Asquith-Beard",
    "Primary Club": "ATSC",
    County: "Berkshire",
    Country: "England",
  },
  6160: {
    Title: "Mr",
    Initials: "O",
    Surname: "Bailey",
    "Primary Club": "North Herts Rifles",
    County: "Hertfordshire",
    Country: "England",
  },
  6161: {
    Title: "Sgt",
    Initials: "M",
    Surname: "Barnes",
    "Primary Club": "BCRT",
    County: "Middlesex",
    Country: "England",
  },
  6162: {
    Title: "Mr",
    Initials: "AC",
    Surname: "Barrell",
    "Primary Club": "Welsh RA",
    County: "Shropshire",
    Country: "Wales",
  },
  6163: {
    Title: "Cpl",
    Initials: "M",
    Surname: "Bell",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  6164: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Bolduc",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6165: {
    Title: "Mr",
    Initials: "T",
    Surname: "Bonner",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6166: {
    Title: "Mr",
    Initials: "TM",
    Surname: "Booth",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6167: {
    Title: "Cdt",
    Initials: "CL",
    Surname: "Breckendridge",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6168: {
    Title: "Mr",
    Initials: "D",
    Surname: "Brewster",
    "Primary Club": "NRA Australia",
    County: "",
    Country: "Australia",
  },
  6169: {
    Title: "Mr",
    Initials: "M",
    Surname: "Brown",
    "Primary Club": "Headley Park",
    County: "Berkshire",
    Country: "England",
  },
  6170: {
    Title: "Cdt Sgt",
    Initials: "EE",
    Surname: "Bruce-Gardyne",
    "Primary Club": "Oundle School",
    County: "Angus",
    Country: "Scotland",
  },
  6171: {
    Title: "CWO",
    Initials: "L",
    Surname: "Bryant",
    "Primary Club": "2077 Sqn ATC",
    County: "",
    Country: "Wales",
  },
  6172: {
    Title: "LCpl",
    Initials: "JRL",
    Surname: "Burch",
    "Primary Club": "BCRT",
    County: "Surrey",
    Country: "England",
  },
  6173: {
    Title: "Mr",
    Initials: "CHS",
    Surname: "Burden",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  6174: {
    Title: "Mr",
    Initials: "S",
    Surname: "Butler",
    "Primary Club": "NRA",
    County: "Berkshire",
    Country: "England",
  },
  6175: {
    Title: "Cdt",
    Initials: "D",
    Surname: "Cai",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6176: {
    Title: "Miss",
    Initials: "A",
    Surname: "Calvert",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  6177: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Cameron",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6178: {
    Title: "Miss",
    Initials: "E",
    Surname: "Campbell",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6179: {
    Title: "Mr",
    Initials: "RW",
    Surname: "Cantello",
    "Primary Club": "Bristol RC",
    County: "Somerset",
    Country: "England",
  },
  6180: {
    Title: "Miss",
    Initials: "V",
    Surname: "Carman",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6181: {
    Title: "Mr",
    Initials: "RA",
    Surname: "Carmichael",
    "Primary Club": "Uppingham Veterans RC",
    County: "Leicestershire",
    Country: "England",
  },
  6182: {
    Title: "Mr",
    Initials: "D",
    Surname: "Chalmers",
    "Primary Club": "Exeter University RC",
    County: "Devon",
    Country: "England",
  },
  6183: {
    Title: "Mr",
    Initials: "P",
    Surname: "Chamberlain",
    "Primary Club": "Colchester RC",
    County: "Essex",
    Country: "England",
  },
  6184: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Chambers",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  6185: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Chinn",
    "Primary Club": "Headley Park",
    County: "",
    Country: "England",
  },
  6186: {
    Title: "Mr",
    Initials: "S",
    Surname: "Clark",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  6187: {
    Title: "Mr",
    Initials: "M",
    Surname: "Clark",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6188: {
    Title: "Mr",
    Initials: "DR",
    Surname: "Colegate",
    "Primary Club": "Ibis English",
    County: "Gloucestershire",
    Country: "England",
  },
  6189: {
    Title: "Mr",
    Initials: "B",
    Surname: "Colman-Sadd",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6190: {
    Title: "Mr",
    Initials: "T",
    Surname: "Colston Sr",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6191: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Cooper",
    "Primary Club": "D&N Wing ATC",
    County: "County Durham",
    Country: "England",
  },
  6192: {
    Title: "Mr",
    Initials: "DS",
    Surname: "Cooper",
    "Primary Club": "Marlow",
    County: "",
    Country: "England",
  },
  6193: {
    Title: "Mr",
    Initials: "F",
    Surname: "Cotton",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6194: {
    Title: "Cdt",
    Initials: "CAJ",
    Surname: "Crosby",
    "Primary Club": "BCRT",
    County: "Berkshire",
    Country: "England",
  },
  6195: {
    Title: "Mr",
    Initials: "CW",
    Surname: "Dale",
    "Primary Club": "Old Suttonians",
    County: "",
    Country: "England",
  },
  6196: {
    Title: "Mr",
    Initials: "O",
    Surname: "Daniel",
    "Primary Club": "",
    County: "",
    Country: "West Indies",
  },
  6197: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Dixon",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6198: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Doody",
    "Primary Club": "BCRT",
    County: "Somerset",
    Country: "England",
  },
  6199: {
    Title: "Mr",
    Initials: "R",
    Surname: "Edwards",
    "Primary Club": "North Herts Rifles",
    County: "Gloucestershire",
    Country: "England",
  },
  6200: {
    Title: "Mr",
    Initials: "MR",
    Surname: "Endres",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6201: {
    Title: "Cdt",
    Initials: "N",
    Surname: "Ertl",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6202: {
    Title: "Mr",
    Initials: "EHB",
    Surname: "Ervine",
    "Primary Club": "Old Suttonians",
    County: "Kent",
    Country: "England",
  },
  6203: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Fata",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6204: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Fifield",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6205: {
    Title: "Mr",
    Initials: "A",
    Surname: "Fitzpatrick",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6206: {
    Title: "Cpl",
    Initials: "A",
    Surname: "Foster",
    "Primary Club": "D&N Wing ATC",
    County: "Northumberland",
    Country: "England",
  },
  6207: {
    Title: "Dr",
    Initials: "T",
    Surname: "Free",
    "Primary Club": "North Warrnambool",
    County: "",
    Country: "Australia",
  },
  6208: {
    Title: "Miss",
    Initials: "H",
    Surname: "Freiman",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  6209: {
    Title: "Mr",
    Initials: "O",
    Surname: "Garfield-Bennett",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Wales",
  },
  6210: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Gendron",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6211: {
    Title: "Mr",
    Initials: "TH",
    Surname: "Glynne-Percy",
    "Primary Club": "Epsom College",
    County: "London",
    Country: "England",
  },
  6212: {
    Title: "Cdt",
    Initials: "GDD",
    Surname: "Godwin-Austen",
    "Primary Club": "Oundle School",
    County: "London",
    Country: "England",
  },
  6213: {
    Title: "Mr",
    Initials: "C",
    Surname: "Gordon",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6214: {
    Title: "Cdt",
    Initials: "F",
    Surname: "Grenier",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6215: {
    Title: "Mr",
    Initials: "NP",
    Surname: "Guernsey",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6216: {
    Title: "Mr",
    Initials: "M",
    Surname: "Hall",
    "Primary Club": "Artists RC",
    County: "Berkshire",
    Country: "England",
  },
  6217: {
    Title: "Mr",
    Initials: "C",
    Surname: "Halloran",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  6218: {
    Title: "Mr",
    Initials: "N",
    Surname: "Hampton",
    "Primary Club": "GB F Class Association",
    County: "",
    Country: "England",
  },
  6219: {
    Title: "Mr",
    Initials: "B",
    Surname: "Harrigan",
    "Primary Club": "Wandsworth FBRC",
    County: "Surrey",
    Country: "England",
  },
  6220: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Harris",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6221: {
    Title: "Mr",
    Initials: "ARP",
    Surname: "Harrison",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6222: {
    Title: "Mr",
    Initials: "EH",
    Surname: "Hatzistefanis",
    "Primary Club": "NRA",
    County: "",
    Country: "Greece",
  },
  6223: {
    Title: "Miss",
    Initials: "A",
    Surname: "Hendy",
    "Primary Club": "Derbyshire ACF",
    County: "Derbyshire",
    Country: "England",
  },
  6224: {
    Title: "Cdt",
    Initials: "SJ",
    Surname: "Hodge",
    "Primary Club": "BCRT",
    County: "Berkshire",
    Country: "England",
  },
  6225: {
    Title: "Mr",
    Initials: "G",
    Surname: "Holloway",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6226: {
    Title: "Mr",
    Initials: "K",
    Surname: "Hoverstad",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6227: {
    Title: "Cdt",
    Initials: "OJ",
    Surname: "Hughes",
    "Primary Club": "BCRT",
    County: "Surrey",
    Country: "England",
  },
  6228: {
    Title: "Mr",
    Initials: "FJ",
    Surname: "Huisamen",
    "Primary Club": "NRA",
    County: "Hertfordshire",
    Country: "",
  },
  6229: {
    Title: "Mr",
    Initials: "GJ",
    Surname: "Huisamen",
    "Primary Club": "NRA",
    County: "Hertfordshire",
    Country: "",
  },
  6230: {
    Title: "Cdt",
    Initials: "BJ",
    Surname: "Hunt",
    "Primary Club": "UKCRT",
    County: "Norfolk",
    Country: "England",
  },
  6231: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Hunt",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6232: {
    Title: "Mr",
    Initials: "A",
    Surname: "Hynd",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6233: {
    Title: "Mr",
    Initials: "JAMM",
    Surname: "Ingham",
    "Primary Club": "Charterhouse",
    County: "London",
    Country: "England",
  },
  6234: {
    Title: "Mr",
    Initials: "PT",
    Surname: "Janski",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6235: {
    Title: "Mr",
    Initials: "L",
    Surname: "Jardine",
    "Primary Club": "NRA",
    County: "Kent",
    Country: "",
  },
  6236: {
    Title: "LCpl",
    Initials: "SE",
    Surname: "Jones",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6237: {
    Title: "Cpl",
    Initials: "SL",
    Surname: "Josling",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6238: {
    Title: "Cdt",
    Initials: "MJ",
    Surname: "Keel",
    "Primary Club": "BCRT",
    County: "Berkshire",
    Country: "England",
  },
  6239: {
    Title: "Mr",
    Initials: "WT",
    Surname: "Kemsley",
    "Primary Club": "RGS Guildford",
    County: "",
    Country: "",
  },
  6240: {
    Title: "Mr",
    Initials: "C",
    Surname: "Kershaw",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  6241: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Key",
    "Primary Club": "UKCRT",
    County: "",
    Country: "England",
  },
  6242: {
    Title: "Mr",
    Initials: "FM",
    Surname: "Key",
    "Primary Club": "OGRE",
    County: "Lincolnshire",
    Country: "England",
  },
  6243: {
    Title: "Mr",
    Initials: "A",
    Surname: "Kofmann",
    "Primary Club": "Marlborough College",
    County: "Wiltshire",
    Country: "Denmark",
  },
  6244: {
    Title: "Mr",
    Initials: "C",
    Surname: "Koros",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  6245: {
    Title: "Mr",
    Initials: "M",
    Surname: "Kuanyshev",
    "Primary Club": "Wellington College",
    County: "County of London",
    Country: "England",
  },
  6246: {
    Title: "Mr",
    Initials: "K",
    Surname: "Kyle",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6247: {
    Title: "Mr",
    Initials: "B",
    Surname: "Lacey",
    "Primary Club": "",
    County: "",
    Country: "Australia",
  },
  6248: {
    Title: "Mr",
    Initials: "J",
    Surname: "Lam",
    "Primary Club": "Lancing College",
    County: "",
    Country: "Hong Kong",
  },
  6249: {
    Title: "Mr",
    Initials: "M",
    Surname: "Lawrence",
    "Primary Club": "Dorset Riflemen",
    County: "Wiltshire",
    Country: "England",
  },
  6250: {
    Title: "Cdt",
    Initials: "F",
    Surname: "Le Marinel",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  6251: {
    Title: "Cdt",
    Initials: "AR",
    Surname: "Le Quesne",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  6252: {
    Title: "Cdt",
    Initials: "JH",
    Surname: "Lee",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6253: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Leneveu",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6254: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Levesque",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6255: {
    Title: "Mr",
    Initials: "X",
    Surname: "Li",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  6256: {
    Title: "Ms",
    Initials: "AC",
    Surname: "Lohnes",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  6257: {
    Title: "Miss",
    Initials: "A",
    Surname: "Longhurst",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  6258: {
    Title: "Mr",
    Initials: "CRW",
    Surname: "Luckyn-Malone",
    "Primary Club": "Epsom College",
    County: "",
    Country: "England",
  },
  6259: {
    Title: "Mr",
    Initials: "Q",
    Surname: "Lyle",
    "Primary Club": "West Atholl RC",
    County: "",
    Country: "",
  },
  6260: {
    Title: "Mr",
    Initials: "A",
    Surname: "Macdonald",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6261: {
    Title: "Mr",
    Initials: "S",
    Surname: "Mackey",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6262: {
    Title: "Cdt Sgt",
    Initials: "DL",
    Surname: "Martin",
    "Primary Club": "Oundle School",
    County: "Cambridgeshire",
    Country: "England",
  },
  6263: {
    Title: "Mr",
    Initials: "J",
    Surname: "Matheson",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  6264: {
    Title: "Mr",
    Initials: "C",
    Surname: "Mckenzie",
    "Primary Club": "BYSA",
    County: "Middlesex",
    Country: "England",
  },
  6265: {
    Title: "Cpl",
    Initials: "A",
    Surname: "McLellan",
    "Primary Club": "UKCRT",
    County: "London",
    Country: "England",
  },
  6266: {
    Title: "Mr",
    Initials: "W",
    Surname: "Meade",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6267: {
    Title: "Cpl",
    Initials: "Z",
    Surname: "Melhuish",
    "Primary Club": "CCRS",
    County: "Glamorgan",
    Country: "Wales",
  },
  6268: {
    Title: "Mr",
    Initials: "O",
    Surname: "Milanovic",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6269: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Moore",
    "Primary Club": "Wimbledon RC",
    County: "",
    Country: "England",
  },
  6270: {
    Title: "Miss",
    Initials: "KL",
    Surname: "Morley",
    "Primary Club": "Exeter School CCF",
    County: "Devon",
    Country: "",
  },
  6271: {
    Title: "Mr",
    Initials: "W",
    Surname: "Morton",
    "Primary Club": "Durham University",
    County: "County Durham",
    Country: "England",
  },
  6272: {
    Title: "Mr",
    Initials: "J",
    Surname: "Mossom",
    "Primary Club": "Imperial College RPC",
    County: "County of London",
    Country: "England",
  },
  6273: {
    Title: "Mr",
    Initials: "A",
    Surname: "Mulla",
    "Primary Club": "St Nicholas RPC",
    County: "Kent",
    Country: "",
  },
  6275: {
    Title: "Miss",
    Initials: "NI",
    Surname: "Murrin",
    "Primary Club": "Wellington College",
    County: "London",
    Country: "England",
  },
  6276: {
    Title: "Mr",
    Initials: "L",
    Surname: "Nagy",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "Slovakia",
  },
  6277: {
    Title: "Mr",
    Initials: "L",
    Surname: "Nell",
    "Primary Club": "NLRC",
    County: "Surrey",
    Country: "England",
  },
  6278: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Neumann",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6279: {
    Title: "Miss",
    Initials: "KS",
    Surname: "Nichols",
    "Primary Club": "ATSC",
    County: "Somerset",
    Country: "England",
  },
  6280: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Oakland",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6281: {
    Title: "Mr",
    Initials: "J",
    Surname: "Overbury",
    "Primary Club": "Old Framlinghamians RC",
    County: "Suffolk",
    Country: "Wales",
  },
  6282: {
    Title: "Mr",
    Initials: "E",
    Surname: "Parkins",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6283: {
    Title: "Cdt",
    Initials: "E",
    Surname: "Pasemko",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6284: {
    Title: "Miss",
    Initials: "N",
    Surname: "Pasternak Albert",
    "Primary Club": "Exeter University RC",
    County: "Devon",
    Country: "England",
  },
  6285: {
    Title: "Mrs",
    Initials: "E",
    Surname: "Patrick",
    "Primary Club": "Roseville",
    County: "",
    Country: "Australia",
  },
  6286: {
    Title: "Mr",
    Initials: "A",
    Surname: "Patrick",
    "Primary Club": "Roseville",
    County: "",
    Country: "Australia",
  },
  6287: {
    Title: "Mr",
    Initials: "S",
    Surname: "Paul",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6288: {
    Title: "Mr",
    Initials: "CI",
    Surname: "Pawlik",
    "Primary Club": "Old Suttonians",
    County: "Hampshire",
    Country: "England",
  },
  6289: {
    Title: "Mr",
    Initials: "C",
    Surname: "Perry",
    "Primary Club": "",
    County: "",
    Country: "West Indies",
  },
  6290: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Peters",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  6291: {
    Title: "Mr",
    Initials: "J",
    Surname: "Phillipps",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  6292: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Philo",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6293: {
    Title: "Cdt RSM",
    Initials: "CP",
    Surname: "Philp",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  6294: {
    Title: "FS",
    Initials: "M",
    Surname: "Phipps",
    "Primary Club": "CCRS",
    County: "",
    Country: "Northern Ireland",
  },
  6295: {
    Title: "Mr",
    Initials: "R",
    Surname: "Pike",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6296: {
    Title: "Mr",
    Initials: "M",
    Surname: "Ponya",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "England",
  },
  6297: {
    Title: "Cpl",
    Initials: "H",
    Surname: "Quillam",
    "Primary Club": "ATSC",
    County: "Hampshire",
    Country: "England",
  },
  6298: {
    Title: "Mr",
    Initials: "AR",
    Surname: "Radkovskii",
    "Primary Club": "Old Framlinghamians RC",
    County: "London",
    Country: "England",
  },
  6299: {
    Title: "Cdt LCpl",
    Initials: "HM",
    Surname: "Read",
    "Primary Club": "Oundle School",
    County: "Lincolnshire",
    Country: "England",
  },
  6300: {
    Title: "Cdt",
    Initials: "P",
    Surname: "Ready",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6301: {
    Title: "Cdt",
    Initials: "KH",
    Surname: "Redpath",
    "Primary Club": "CCRS",
    County: "",
    Country: "",
  },
  6302: {
    Title: "Mr",
    Initials: "CB",
    Surname: "Ridout",
    "Primary Club": "Somerset CRA",
    County: "Dorset",
    Country: "England",
  },
  6303: {
    Title: "Mr",
    Initials: "AD",
    Surname: "Robertson",
    "Primary Club": "Wilbury RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6304: {
    Title: "Mr",
    Initials: "BC",
    Surname: "Robinson",
    "Primary Club": "Irish Rifle Club",
    County: "Ulster",
    Country: "Northern Ireland",
  },
  6305: {
    Title: "Cdt",
    Initials: "FJA",
    Surname: "Robson",
    "Primary Club": "BCRT",
    County: "Norfolk",
    Country: "England",
  },
  6306: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Roche",
    "Primary Club": "UKCRT",
    County: "Berkshire",
    Country: "Ireland",
  },
  6307: {
    Title: "Cdt",
    Initials: "O",
    Surname: "Ross",
    "Primary Club": "CCRS",
    County: "",
    Country: "",
  },
  6308: {
    Title: "Mrs",
    Initials: "K",
    Surname: "Rowe",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6309: {
    Title: "Cpl",
    Initials: "J",
    Surname: "Rowe",
    "Primary Club": "CCRS",
    County: "",
    Country: "England",
  },
  6310: {
    Title: "Mr",
    Initials: "C",
    Surname: "Rowe Jr",
    "Primary Club": "USA National",
    County: "",
    Country: "United States",
  },
  6311: {
    Title: "",
    Initials: "",
    Surname: "rozze",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  6312: {
    Title: "Mr",
    Initials: "JR",
    Surname: "Santana",
    "Primary Club": "CURA",
    County: "",
    Country: "England",
  },
  6313: {
    Title: "Mr",
    Initials: "S",
    Surname: "Schmitz",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  6314: {
    Title: "Mr",
    Initials: "KSC",
    Surname: "Schwegler",
    "Primary Club": "BDMP Germany",
    County: "",
    Country: "Germany",
  },
  6315: {
    Title: "Sgt",
    Initials: "I",
    Surname: "Seddon",
    "Primary Club": "CCRS",
    County: "",
    Country: "Wales",
  },
  6316: {
    Title: "Cdt",
    Initials: "M",
    Surname: "Serr",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6317: {
    Title: "Cdt",
    Initials: "AS",
    Surname: "Shine",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6318: {
    Title: "Mr",
    Initials: "AA",
    Surname: "Singh",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6319: {
    Title: "Mr",
    Initials: "G",
    Surname: "Smeeton",
    "Primary Club": "Staveley",
    County: "Derbyshire",
    Country: "England",
  },
  6320: {
    Title: "Mr",
    Initials: "G",
    Surname: "Smith",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6321: {
    Title: "Mr",
    Initials: "DEC",
    Surname: "Smith",
    "Primary Club": "Leatherhead RC",
    County: "Kent",
    Country: "England",
  },
  6322: {
    Title: "Mr",
    Initials: "J",
    Surname: "Smith",
    "Primary Club": "",
    County: "",
    Country: "United States",
  },
  6323: {
    Title: "Mr",
    Initials: "CH",
    Surname: "Smith",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6324: {
    Title: "Mrs",
    Initials: "C",
    Surname: "Snowden",
    "Primary Club": "New Zealand",
    County: "",
    Country: "New Zealand",
  },
  6325: {
    Title: "Ms",
    Initials: "H",
    Surname: "Sparkes",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6326: {
    Title: "Cdt",
    Initials: "FCS",
    Surname: "Stewart",
    "Primary Club": "BCRT",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6327: {
    Title: "Mr",
    Initials: "AG",
    Surname: "Stewart",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Channel Islands",
  },
  6328: {
    Title: "Dr",
    Initials: "M",
    Surname: "Stopher",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  6329: {
    Title: "Mr",
    Initials: "S",
    Surname: "Studholme",
    "Primary Club": "Exeter University RC",
    County: "Surrey",
    Country: "England",
  },
  6330: {
    Title: "Mr",
    Initials: "DJ",
    Surname: "Studholme",
    "Primary Club": "BYSA",
    County: "Gloucestershire",
    Country: "England",
  },
  6331: {
    Title: "Miss",
    Initials: "TY",
    Surname: "Sunang-Joret",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6332: {
    Title: "Bdr",
    Initials: "L",
    Surname: "Taylor",
    "Primary Club": "BCRT",
    County: "Co Antrim",
    Country: "Northern Ireland",
  },
  6333: {
    Title: "Miss",
    Initials: "LR",
    Surname: "Taylor-Fitzgibbon",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6334: {
    Title: "DR",
    Initials: "ML",
    Surname: "Thomas",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  6335: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Tiffin",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6336: {
    Title: "Mr",
    Initials: "BJ",
    Surname: "Tinegate-Smith",
    "Primary Club": "Brunel University",
    County: "Hertfordshire",
    Country: "England",
  },
  6337: {
    Title: "Miss",
    Initials: "E",
    Surname: "Trace",
    "Primary Club": "Dollar Academy",
    County: "",
    Country: "Scotland",
  },
  6338: {
    Title: "Mr",
    Initials: "HDL",
    Surname: "Turnbull-Hall",
    "Primary Club": "Uppingham School",
    County: "",
    Country: "England",
  },
  6339: {
    Title: "Miss",
    Initials: "LE",
    Surname: "Tuson",
    "Primary Club": "Club 25",
    County: "Surrey",
    Country: "England",
  },
  6340: {
    Title: "LCpl",
    Initials: "ACJ",
    Surname: "Van Grondelle",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "Netherlands",
  },
  6341: {
    Title: "Mr",
    Initials: "PM",
    Surname: "Walters",
    "Primary Club": "Australia",
    County: "",
    Country: "Australia",
  },
  6342: {
    Title: "Mr",
    Initials: "RR",
    Surname: "Warren",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  6343: {
    Title: "Miss",
    Initials: "JC",
    Surname: "Westall",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  6344: {
    Title: "Cdt Cpl",
    Initials: "TRE",
    Surname: "White",
    "Primary Club": "BCRT",
    County: "Surrey",
    Country: "England",
  },
  6345: {
    Title: "Cdt Cpl",
    Initials: "P",
    Surname: "Wilkinson",
    "Primary Club": "RGS Guildford",
    County: "Yorkshire",
    Country: "England",
  },
  6346: {
    Title: "Miss",
    Initials: "S",
    Surname: "Williams",
    "Primary Club": "Old Lancing Rifle Club",
    County: "Sussex",
    Country: "England",
  },
  6347: {
    Title: "Mr",
    Initials: "E",
    Surname: "Woodall",
    "Primary Club": "Woodbridge School",
    County: "Suffolk",
    Country: "England",
  },
  6348: {
    Title: "Mr",
    Initials: "J",
    Surname: "Wu",
    "Primary Club": "DCRA",
    County: "",
    Country: "Canada",
  },
  6349: {
    Title: "",
    Initials: "",
    Surname: "young",
    "Primary Club": "",
    County: "",
    Country: "",
  },
  6350: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Youngman",
    "Primary Club": "Sutton Valence School RC",
    County: "Kent",
    Country: "England",
  },
  6351: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Zheng",
    "Primary Club": "RCAC NRT",
    County: "",
    Country: "Canada",
  },
  6352: {
    Title: "Spr",
    Initials: "M",
    Surname: "Bamsey",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6353: {
    Title: "Miss",
    Initials: "S",
    Surname: "Bladon",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6354: {
    Title: "Mr",
    Initials: "JRL",
    Surname: "Burch",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6355: {
    Title: "Mr",
    Initials: "ED",
    Surname: "Chapman-Sheath",
    "Primary Club": "NLRC",
    County: "Lincolnshire",
    Country: "England",
  },
  6356: {
    Title: "Mr",
    Initials: "JB",
    Surname: "Cheetham",
    "Primary Club": "Pates Grammar CCF",
    County: "",
    Country: "England",
  },
  6357: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Clark",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6358: {
    Title: "Mr",
    Initials: "M",
    Surname: "Crossley",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6359: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Ganesan",
    "Primary Club": "UKCRT",
    County: "Gloucestershire",
    Country: "England",
  },
  6360: {
    Title: "Mr",
    Initials: "J",
    Surname: "haines",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6361: {
    Title: "Cdt",
    Initials: "F",
    Surname: "Lawry",
    "Primary Club": "UKCRT",
    County: "Surrey",
    Country: "England",
  },
  6362: {
    Title: "Cdt",
    Initials: "AR",
    Surname: "Le Quesne",
    "Primary Club": "Victoria College",
    County: "",
    Country: "Jersey",
  },
  6363: {
    Title: "Cdt",
    Initials: "HM",
    Surname: "McCaig",
    "Primary Club": "BCRT",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6364: {
    Title: "Cdt",
    Initials: "T",
    Surname: "Phillimore",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  6365: {
    Title: "Cdt",
    Initials: "AM",
    Surname: "Poulton",
    "Primary Club": "BCRT",
    County: "Surrey",
    Country: "England",
  },
  6366: {
    Title: "Mr",
    Initials: "DA",
    Surname: "Roberts",
    "Primary Club": "Tewkesbury & District RPC",
    County: "Suffolk",
    Country: "England",
  },
  6367: {
    Title: "LCpl",
    Initials: "A",
    Surname: "Shah",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6368: {
    Title: "Cdt",
    Initials: "EJ",
    Surname: "Skelhorn",
    "Primary Club": "BCRT",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6369: {
    Title: "Mr",
    Initials: "BM",
    Surname: "Smith",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6370: {
    Title: "Mrs",
    Initials: "KM",
    Surname: "Stebbings",
    "Primary Club": "BYSA",
    County: "County of Durham",
    Country: "Canada",
  },
  6371: {
    Title: "Cdt",
    Initials: "HB",
    Surname: "Thomson",
    "Primary Club": "BCRT",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6372: {
    Title: "Miss",
    Initials: "CES",
    Surname: "Wiltshire",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6373: {
    Title: "Mr",
    Initials: "R",
    Surname: "Zhou",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6374: {
    Title: "Mr",
    Initials: "T",
    Surname: "Lawson",
    "Primary Club": "RAFTRC",
    County: "",
    Country: "Wales",
  },
  6375: {
    Title: "Mr",
    Initials: "MD",
    Surname: "Seager",
    "Primary Club": "BSRC",
    County: "",
    Country: "Wales",
  },
  6376: {
    Title: "Mrs",
    Initials: "K",
    Surname: "Airey",
    "Primary Club": "Staveley",
    County: "",
    Country: "England",
  },
  6377: {
    Title: "Mr",
    Initials: "P",
    Surname: "Allen",
    "Primary Club": "Swadlincote RPC",
    County: "",
    Country: "England",
  },
  6378: {
    Title: "Mr",
    Initials: "SRG",
    Surname: "Anselm",
    "Primary Club": "White Horse Lodge SC",
    County: "",
    Country: "England",
  },
  6379: {
    Title: "Mr",
    Initials: "CA",
    Surname: "Bailey",
    "Primary Club": "OURC",
    County: "",
    Country: "England",
  },
  6380: {
    Title: "Mr",
    Initials: "D",
    Surname: "Beaumont",
    "Primary Club": "Teesside R&PC",
    County: "",
    Country: "England",
  },
  6381: {
    Title: "Mr",
    Initials: "T",
    Surname: "Bindley",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  6382: {
    Title: "Mr",
    Initials: "JW",
    Surname: "Botha",
    "Primary Club": "Middelburg",
    County: "",
    Country: "South Africa",
  },
  6383: {
    Title: "Mr",
    Initials: "EG",
    Surname: "Campbell",
    "Primary Club": "OURC",
    County: "",
    Country: "Scotland",
  },
  6384: {
    Title: "Mr",
    Initials: "I",
    Surname: "Chenery",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  6385: {
    Title: "Mr",
    Initials: "G L",
    Surname: "Costello",
    "Primary Club": "White Horse Lodge SC",
    County: "",
    Country: "England",
  },
  6386: {
    Title: "Mr",
    Initials: "J",
    Surname: "Derenne",
    "Primary Club": "NRA",
    County: "",
    Country: "France",
  },
  6387: {
    Title: "Mr",
    Initials: "P",
    Surname: "Fellows",
    "Primary Club": "OURC",
    County: "",
    Country: "England",
  },
  6388: {
    Title: "Mr",
    Initials: "N",
    Surname: "Gilbert",
    "Primary Club": "Diggle",
    County: "",
    Country: "England",
  },
  6389: {
    Title: "Mr",
    Initials: "R",
    Surname: "Goodman",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6390: {
    Title: "Mr",
    Initials: "N",
    Surname: "Gould",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  6391: {
    Title: "Mr",
    Initials: "L",
    Surname: "Groves",
    "Primary Club": "Retired Gentlemens RC",
    County: "",
    Country: "England",
  },
  6392: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Hale",
    "Primary Club": "SCRA",
    County: "",
    Country: "England",
  },
  6393: {
    Title: "Mr",
    Initials: "M",
    Surname: "Harris",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6394: {
    Title: "Mr",
    Initials: "M",
    Surname: "Jarram",
    "Primary Club": "Dorset Riflemen",
    County: "",
    Country: "England",
  },
  6395: {
    Title: "Mr",
    Initials: "MG",
    Surname: "Johns",
    "Primary Club": "NLRC",
    County: "",
    Country: "England",
  },
  6396: {
    Title: "Mr",
    Initials: "D",
    Surname: "Jordanou-Bailey",
    "Primary Club": "OURC",
    County: "",
    Country: "England",
  },
  6397: {
    Title: "Mr",
    Initials: "D",
    Surname: "Lomas",
    "Primary Club": "Leek SC",
    County: "",
    Country: "England",
  },
  6398: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Martin",
    "Primary Club": "Oundle RC",
    County: "",
    Country: "England",
  },
  6399: {
    Title: "Dr",
    Initials: "SA",
    Surname: "McDowell",
    "Primary Club": "NRA",
    County: "",
    Country: "Ireland",
  },
  6400: {
    Title: "Mr",
    Initials: "VS",
    Surname: "Meyer",
    "Primary Club": "White Horse Lodge SC",
    County: "",
    Country: "England",
  },
  6401: {
    Title: "Mr",
    Initials: "DC",
    Surname: "OConnor",
    "Primary Club": "NRA",
    County: "",
    Country: "Ireland",
  },
  6402: {
    Title: "Mr",
    Initials: "R",
    Surname: "Page",
    "Primary Club": "Marlow",
    County: "",
    Country: "England",
  },
  6403: {
    Title: "Mr",
    Initials: "T",
    Surname: "Reynolds",
    "Primary Club": "White Horse Lodge SC",
    County: "",
    Country: "England",
  },
  6404: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Rigby",
    "Primary Club": "Leek SC",
    County: "",
    Country: "England",
  },
  6405: {
    Title: "Mr",
    Initials: "D",
    Surname: "Rollafson",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6406: {
    Title: "Mr",
    Initials: "P",
    Surname: "Sammons",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6407: {
    Title: "Mr",
    Initials: "E",
    Surname: "Schulz",
    "Primary Club": "Netherlands F Class",
    County: "",
    Country: "Netherlands",
  },
  6408: {
    Title: "Mr",
    Initials: "PT",
    Surname: "Sheffield",
    "Primary Club": "101 RC",
    County: "",
    Country: "England",
  },
  6409: {
    Title: "Mr",
    Initials: "RG",
    Surname: "Stevens",
    "Primary Club": "Woodstock RC",
    County: "",
    Country: "England",
  },
  6410: {
    Title: "Dr",
    Initials: "CE",
    Surname: "Titmus",
    "Primary Club": "Bassingbourn RPC",
    County: "",
    Country: "England",
  },
  6411: {
    Title: "Mr",
    Initials: "L",
    Surname: "Vinall",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6412: {
    Title: "Mr",
    Initials: "D",
    Surname: "Wallace",
    "Primary Club": "Prestatyn",
    County: "",
    Country: "Wales",
  },
  6413: {
    Title: "Mr",
    Initials: "M",
    Surname: "Withe",
    "Primary Club": "LMRA",
    County: "",
    Country: "England",
  },
  6414: {
    Title: "Mr",
    Initials: "GF",
    Surname: "Zanoni",
    "Primary Club": "GB F Class Association",
    County: "",
    Country: "Italy",
  },
  6415: {
    Title: "Miss",
    Initials: "NI",
    Surname: "Barnes",
    "Primary Club": "Bedfordians RC",
    County: "Hertfordshire",
    Country: "Wales",
  },
  6416: {
    Title: "Mr",
    Initials: "GA",
    Surname: "Barton-Wood",
    "Primary Club": "Southampton University",
    County: "Devon",
    Country: "England",
  },
  6417: {
    Title: "Miss",
    Initials: "M",
    Surname: "Beaton-Jaramillo",
    "Primary Club": "Bradfield College",
    County: "London",
    Country: "England",
  },
  6418: {
    Title: "Miss",
    Initials: "S",
    Surname: "Bladon",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6419: {
    Title: "Cdt",
    Initials: "GM",
    Surname: "Bohn",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6420: {
    Title: "Cdt",
    Initials: "HA",
    Surname: "Broadhurst",
    "Primary Club": "CCRS",
    County: "Norfolk",
    Country: "England",
  },
  6421: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Browne",
    "Primary Club": "CURA",
    County: "Cambridgeshire",
    Country: "Ireland",
  },
  6422: {
    Title: "Mr",
    Initials: "CL",
    Surname: "Bull",
    "Primary Club": "BYSA",
    County: "Dorset",
    Country: "England",
  },
  6423: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Caldwell",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  6424: {
    Title: "LCpl",
    Initials: "OJ",
    Surname: "Christy",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6425: {
    Title: "Cdt",
    Initials: "JS",
    Surname: "Clark",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6426: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Clarke",
    "Primary Club": "UKCRT",
    County: "Norfolk",
    Country: "England",
  },
  6427: {
    Title: "Lt Col",
    Initials: "ARK",
    Surname: "Clayton",
    "Primary Club": "Old Malvernians",
    County: "Lincolnshire",
    Country: "England",
  },
  6428: {
    Title: "Mr",
    Initials: "RJ",
    Surname: "Cooper",
    "Primary Club": "Campbell College CCF",
    County: "Ulster",
    Country: "Ireland",
  },
  6429: {
    Title: "Miss",
    Initials: "ICN",
    Surname: "Cox",
    "Primary Club": "Bradfield College",
    County: "Berkshire",
    Country: "England",
  },
  6430: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Crabb",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6431: {
    Title: "Miss",
    Initials: "BRD",
    Surname: "Digance",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6432: {
    Title: "Mr",
    Initials: "AJC",
    Surname: "Dodds",
    "Primary Club": "LMRA",
    County: "London",
    Country: "New Zealand",
  },
  6433: {
    Title: "Mr",
    Initials: "TAE",
    Surname: "Dorey",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6434: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Dowe",
    "Primary Club": "LMRA",
    County: "Kent",
    Country: "England",
  },
  6435: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Eadon",
    "Primary Club": "CCRS",
    County: "",
    Country: "",
  },
  6436: {
    Title: "Cdt",
    Initials: "KE",
    Surname: "Farley",
    "Primary Club": "UKCRT",
    County: "",
    Country: "England",
  },
  6437: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Farr",
    "Primary Club": "CCRS",
    County: "",
    Country: "",
  },
  6438: {
    Title: "Mr",
    Initials: "DW",
    Surname: "Farwell",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6439: {
    Title: "Cdt",
    Initials: "O",
    Surname: "Frost",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  6440: {
    Title: "Miss",
    Initials: "SM",
    Surname: "Frost",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6441: {
    Title: "Mr",
    Initials: "MJS",
    Surname: "Gerhardt",
    "Primary Club": "Charterhouse",
    County: "Surrey",
    Country: "England",
  },
  6442: {
    Title: "Cdt",
    Initials: "BG",
    Surname: "Glancey",
    "Primary Club": "Greshams RC",
    County: "",
    Country: "England",
  },
  6443: {
    Title: "Mr",
    Initials: "G",
    Surname: "Green",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6444: {
    Title: "Miss",
    Initials: "H",
    Surname: "Grindon",
    "Primary Club": "Wellington College",
    County: "Surrey",
    Country: "England",
  },
  6445: {
    Title: "Miss",
    Initials: "J",
    Surname: "Hamon",
    "Primary Club": "NRA",
    County: "Kent",
    Country: "England",
  },
  6446: {
    Title: "Mr",
    Initials: "EJ",
    Surname: "Harriss",
    "Primary Club": "Imperial College RPC",
    County: "London",
    Country: "England",
  },
  6447: {
    Title: "Miss",
    Initials: "K",
    Surname: "Harriss",
    "Primary Club": "Wellington College",
    County: "Sussex",
    Country: "England",
  },
  6448: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Hazelton",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6449: {
    Title: "Mr",
    Initials: "TDB",
    Surname: "Hebblethwaite",
    "Primary Club": "LMRA",
    County: "London",
    Country: "England",
  },
  6450: {
    Title: "Mr",
    Initials: "TE",
    Surname: "Hill",
    "Primary Club": "Exeter University RC",
    County: "Devon",
    Country: "England",
  },
  6451: {
    Title: "Mr",
    Initials: "LR",
    Surname: "Hinkly",
    "Primary Club": "Charterhouse",
    County: "Surrey",
    Country: "England",
  },
  6452: {
    Title: "Miss",
    Initials: "P",
    Surname: "Hobbs",
    "Primary Club": "Wellington College",
    County: "Middlesex",
    Country: "England",
  },
  6453: {
    Title: "Mr",
    Initials: "A",
    Surname: "Honey",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6454: {
    Title: "Cdt",
    Initials: "LEI",
    Surname: "Horsman Carpenter",
    "Primary Club": "CCRS",
    County: "",
    Country: "England",
  },
  6455: {
    Title: "Mr",
    Initials: "TWG",
    Surname: "Janelid",
    "Primary Club": "Marlborough College",
    County: "",
    Country: "England",
  },
  6456: {
    Title: "Mr",
    Initials: "VT",
    Surname: "Jensen",
    "Primary Club": "Charterhouse",
    County: "Surrey",
    Country: "England",
  },
  6457: {
    Title: "Mr",
    Initials: "J",
    Surname: "Kahtava",
    "Primary Club": "Wellington College",
    County: "Hampshire",
    Country: "Japan",
  },
  6458: {
    Title: "Cdt",
    Initials: "S",
    Surname: "Kasica",
    "Primary Club": "CCRS",
    County: "",
    Country: "",
  },
  6459: {
    Title: "Mrs",
    Initials: "J",
    Surname: "Kirkby",
    "Primary Club": "University of Surrey",
    County: "Surrey",
    Country: "England",
  },
  6460: {
    Title: "Mr",
    Initials: "K",
    Surname: "Kousetta",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6461: {
    Title: "Mr",
    Initials: "I",
    Surname: "Kwon",
    "Primary Club": "Imperial College RPC",
    County: "Surrey",
    Country: "England",
  },
  6462: {
    Title: "Mr",
    Initials: "GJ",
    Surname: "Lake Coghlan",
    "Primary Club": "Charterhouse",
    County: "Surrey",
    Country: "England",
  },
  6463: {
    Title: "Mr",
    Initials: "TW",
    Surname: "Le Huray",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6464: {
    Title: "Mr",
    Initials: "RL",
    Surname: "Lombard",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6465: {
    Title: "Dr",
    Initials: "J",
    Surname: "Mac Taggart",
    "Primary Club": "Exeter University RC",
    County: "Sussex",
    Country: "England",
  },
  6466: {
    Title: "Cdt",
    Initials: "RJ",
    Surname: "Mace",
    "Primary Club": "UKCRT",
    County: "Hampshire",
    Country: "England",
  },
  6467: {
    Title: "Cdt",
    Initials: "JD",
    Surname: "Magalhaes",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Channel Islands",
  },
  6468: {
    Title: "Mr",
    Initials: "JAS",
    Surname: "McCosh",
    "Primary Club": "NLRC",
    County: "London",
    Country: "England",
  },
  6469: {
    Title: "Mr",
    Initials: "RPM",
    Surname: "McKenna",
    "Primary Club": "Elizabeth College",
    County: "",
    Country: "Guernsey",
  },
  6470: {
    Title: "Mr",
    Initials: "A",
    Surname: "Moldoveanu",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6471: {
    Title: "Mr",
    Initials: "JM",
    Surname: "Nippers",
    "Primary Club": "Guernsey RC",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6472: {
    Title: "Mr",
    Initials: "HP",
    Surname: "Nippers",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6473: {
    Title: "Cdt",
    Initials: "I",
    Surname: "Noor",
    "Primary Club": "Wellington College",
    County: "",
    Country: "",
  },
  6474: {
    Title: "Cdt",
    Initials: "SF",
    Surname: "Oldridge",
    "Primary Club": "Jersey RA",
    County: "",
    Country: "Jersey",
  },
  6475: {
    Title: "Cdt",
    Initials: "J",
    Surname: "Orr",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6476: {
    Title: "Dr",
    Initials: "T",
    Surname: "Pantke",
    "Primary Club": "Jersey RC",
    County: "Baleaves",
    Country: "Spain",
  },
  6477: {
    Title: "Mr",
    Initials: "SR",
    Surname: "Parker",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6478: {
    Title: "Mr",
    Initials: "FEG",
    Surname: "Pawlik",
    "Primary Club": "Old Suttonians",
    County: "Kent",
    Country: "England",
  },
  6479: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Pike",
    "Primary Club": "BCRT",
    County: "",
    Country: "",
  },
  6480: {
    Title: "Cdt",
    Initials: "HJ",
    Surname: "Purslow",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6481: {
    Title: "Mr",
    Initials: "EJT",
    Surname: "Quayle",
    "Primary Club": "Epsom College",
    County: "London",
    Country: "England",
  },
  6482: {
    Title: "Cdt",
    Initials: "OW",
    Surname: "Rankin",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6483: {
    Title: "Cdt",
    Initials: "KMR",
    Surname: "Rivoallan",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  6484: {
    Title: "Miss",
    Initials: "EM",
    Surname: "Robinson",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6485: {
    Title: "Mr",
    Initials: "OJ",
    Surname: "Scott",
    "Primary Club": "Epsom College",
    County: "",
    Country: "England",
  },
  6486: {
    Title: "Mr",
    Initials: "MW",
    Surname: "Sheridan-Oliver",
    "Primary Club": "Charterhouse",
    County: "Surrey",
    Country: "England",
  },
  6487: {
    Title: "Cdt",
    Initials: "NR",
    Surname: "Shutt",
    "Primary Club": "Charterhouse",
    County: "",
    Country: "England",
  },
  6488: {
    Title: "Mr",
    Initials: "LG",
    Surname: "Skingley",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6489: {
    Title: "Mr",
    Initials: "TEB",
    Surname: "Smith",
    "Primary Club": "Durham University",
    County: "Kent",
    Country: "England",
  },
  6490: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Stanley",
    "Primary Club": "ATSC",
    County: "Lincolnshire",
    Country: "England",
  },
  6491: {
    Title: "Cdt",
    Initials: "LI",
    Surname: "Stewart",
    "Primary Club": "CCRS",
    County: "Derbyshire",
    Country: "England",
  },
  6492: {
    Title: "Mr",
    Initials: "ASOS",
    Surname: "Stewart",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Channel Islands",
  },
  6493: {
    Title: "Mr",
    Initials: "MJ",
    Surname: "Stewart",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6494: {
    Title: "Mr",
    Initials: "L",
    Surname: "Strangemann",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6495: {
    Title: "Mr",
    Initials: "L",
    Surname: "Stuart",
    "Primary Club": "Wellington College",
    County: "Surrey",
    Country: "England",
  },
  6496: {
    Title: "Mr",
    Initials: "R",
    Surname: "Turner",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6497: {
    Title: "Mr",
    Initials: "B",
    Surname: "Vary",
    "Primary Club": "RGS Guildford",
    County: "",
    Country: "England",
  },
  6498: {
    Title: "Mr",
    Initials: "BJW",
    Surname: "Wardell",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  6499: {
    Title: "Miss",
    Initials: "MJ",
    Surname: "Websper",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6500: {
    Title: "Cdt",
    Initials: "JJ",
    Surname: "Weir",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6501: {
    Title: "Mr",
    Initials: "ACM",
    Surname: "White",
    "Primary Club": "Uppingham School",
    County: "",
    Country: "England",
  },
  6502: {
    Title: "Miss",
    Initials: "GI",
    Surname: "Williams",
    "Primary Club": "Epsom College",
    County: "",
    Country: "England",
  },
  6503: {
    Title: "Cdt",
    Initials: "B",
    Surname: "Williams",
    "Primary Club": "CCRS",
    County: "",
    Country: "",
  },
  6504: {
    Title: "Cdt",
    Initials: "W",
    Surname: "Williamson",
    "Primary Club": "UKCRT",
    County: "",
    Country: "",
  },
  6505: {
    Title: "Miss",
    Initials: "CES",
    Surname: "Wiltshire",
    "Primary Club": "BYSA",
    County: "Yorkshire",
    Country: "England",
  },
  6506: {
    Title: "Mr",
    Initials: "H",
    Surname: "Wogan",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6507: {
    Title: "Miss",
    Initials: "J",
    Surname: "Won",
    "Primary Club": "OURC",
    County: "",
    Country: "England",
  },
  6508: {
    Title: "Mr",
    Initials: "GW",
    Surname: "Wood",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6509: {
    Title: "Miss",
    Initials: "LF",
    Surname: "Yates",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Jersey",
  },
  6510: {
    Title: "Mr",
    Initials: "JA",
    Surname: "Zerby",
    "Primary Club": "NRA",
    County: "Buckinghamshire",
    Country: "USA",
  },
  6511: {
    Title: "Mr",
    Initials: "GE",
    Surname: "Campbell",
    "Primary Club": "OURC",
    County: "",
    Country: "Scotland",
  },
  6512: {
    Title: "Mr",
    Initials: "T",
    Surname: "Cordan",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6513: {
    Title: "Mr",
    Initials: "JH",
    Surname: "Gibson",
    "Primary Club": "BTSC",
    County: "",
    Country: "England",
  },
  6514: {
    Title: "Mr",
    Initials: "C",
    Surname: "Hircombe",
    "Primary Club": "27th London Putney RC",
    County: "Middlesex",
    Country: "England",
  },
  6515: {
    Title: "Mr",
    Initials: "RHJ",
    Surname: "Hoogendoorn",
    "Primary Club": "NRA",
    County: "",
    Country: "Netherlands",
  },
  6516: {
    Title: "Mr",
    Initials: "B",
    Surname: "Moore",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6517: {
    Title: "Mr",
    Initials: "PS",
    Surname: "Russell",
    "Primary Club": "Bridlington R&PC",
    County: "",
    Country: "England",
  },
  6518: {
    Title: "Miss",
    Initials: "CA",
    Surname: "Ryan",
    "Primary Club": "NRA",
    County: "",
    Country: "Wales",
  },
  6519: {
    Title: "Mr",
    Initials: "SA",
    Surname: "Smithson",
    "Primary Club": "Louisa GC",
    County: "",
    Country: "England",
  },
  6520: {
    Title: "Mr",
    Initials: "BA",
    Surname: "Tudgay",
    "Primary Club": "NRA",
    County: "",
    Country: "England",
  },
  6521: {
    Title: "Mr",
    Initials: "WN",
    Surname: "Bartlett",
    "Primary Club": "Eton College",
    County: "",
    Country: "Wales",
  },
  6522: {
    Title: "Miss",
    Initials: "I",
    Surname: "Ziviani Soares",
    "Primary Club": "Cambridge University RA",
    County: "",
    Country: "Italy",
  },
  6523: {
    Title: "Mr",
    Initials: "MC",
    Surname: "Armstrong",
    "Primary Club": "CURA",
    County: "",
    Country: "England",
  },
  6524: {
    Title: "Mr",
    Initials: "F",
    Surname: "Ahmad",
    "Primary Club": "University of Surrey",
    County: "",
    Country: "England",
  },
  6525: {
    Title: "C/Sgt",
    Initials: "H",
    Surname: "Anselm",
    "Primary Club": "Oldham Hulme Grammar",
    County: "Lancashire",
    Country: "England",
  },
  6526: {
    Title: "Mr",
    Initials: "WJM",
    Surname: "Bacon",
    "Primary Club": "Perse RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  6527: {
    Title: "Cdt",
    Initials: "G",
    Surname: "Baker",
    "Primary Club": "Greshams RC",
    County: "Cambridgeshire",
    Country: "England",
  },
  6528: {
    Title: "Mr",
    Initials: "OJ",
    Surname: "Biddle",
    "Primary Club": "BYSA",
    County: "Hertfordshire",
    Country: "England",
  },
  6529: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Jasiak",
    "Primary Club": "NCRRA",
    County: "",
    Country: "Canada",
  },
  6530: {
    Title: "Mr",
    Initials: "P",
    Surname: "Carvell",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  6531: {
    Title: "Mr",
    Initials: "R",
    Surname: "Celesia",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Italy",
  },
  6532: {
    Title: "Mr",
    Initials: "AJ",
    Surname: "Chapman-Sheath",
    "Primary Club": "NLRC",
    County: "Lincolnshire",
    Country: "England",
  },
  6533: {
    Title: "Mr",
    Initials: "AF",
    Surname: "Chidlow",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6534: {
    Title: "Mr",
    Initials: "AP",
    Surname: "Clark",
    "Primary Club": "NLRC",
    County: "Cambridgeshire",
    Country: "Ireland",
  },
  6535: {
    Title: "Miss",
    Initials: "JL",
    Surname: "Comins",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  6536: {
    Title: "GpCapt",
    Initials: "WD",
    Surname: "Cooper",
    "Primary Club": "RAFTRC",
    County: "Cornwall",
    Country: "England",
  },
  6537: {
    Title: "Fg Off",
    Initials: "LS",
    Surname: "Crisp",
    "Primary Club": "RAFSAA",
    County: "",
    Country: "England",
  },
  6538: {
    Title: "Cdt",
    Initials: "LK",
    Surname: "Crosby",
    "Primary Club": "Jersey RC",
    County: "",
    Country: "Jersey",
  },
  6539: {
    Title: "Mr",
    Initials: "A",
    Surname: "Cui",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6540: {
    Title: "Mr",
    Initials: "RS",
    Surname: "Darwish",
    "Primary Club": "Epsom College",
    County: "Yorkshire",
    Country: "England",
  },
  6541: {
    Title: "Miss",
    Initials: "M",
    Surname: "Davies",
    "Primary Club": "Exeter University RC",
    County: "Devon",
    Country: "Wales",
  },
  6542: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Denham",
    "Primary Club": "Cambridge University RA",
    County: "Yorkshire",
    Country: "England",
  },
  6543: {
    Title: "Mr",
    Initials: "J",
    Surname: "Donnelly",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6544: {
    Title: "Miss",
    Initials: "A",
    Surname: "Downie",
    "Primary Club": "UKCRT",
    County: "",
    Country: "England",
  },
  6545: {
    Title: "Mr",
    Initials: "S",
    Surname: "Franck",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "Singapore",
  },
  6546: {
    Title: "Miss",
    Initials: "A",
    Surname: "Fung",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6547: {
    Title: "Cdt",
    Initials: "AJD",
    Surname: "Germain",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  6548: {
    Title: "Mr",
    Initials: "TG",
    Surname: "Gorvett",
    "Primary Club": "Huddersfield RC",
    County: "Yorkshire",
    Country: "England",
  },
  6549: {
    Title: "Mr",
    Initials: "MV",
    Surname: "Gosling",
    "Primary Club": "University of Surrey",
    County: "Essex",
    Country: "England",
  },
  6550: {
    Title: "Miss",
    Initials: "CY",
    Surname: "Gregory",
    "Primary Club": "University of Surrey",
    County: "Yorkshire",
    Country: "Scotland",
  },
  6551: {
    Title: "Ms",
    Initials: "H",
    Surname: "Grisenthwaite",
    "Primary Club": "NLRC",
    County: "Cambridgeshire",
    Country: "England",
  },
  6552: {
    Title: "Cdt",
    Initials: "SR",
    Surname: "Hall",
    "Primary Club": "Jersey RC",
    County: "Jersey",
    Country: "Jersey",
  },
  6553: {
    Title: "Mr",
    Initials: "HJ",
    Surname: "Hamlyn",
    "Primary Club": "Epsom College",
    County: "London",
    Country: "England",
  },
  6554: {
    Title: "Miss",
    Initials: "D",
    Surname: "Harrison",
    "Primary Club": "NLRC",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6555: {
    Title: "Cdt",
    Initials: "LM",
    Surname: "Hart",
    "Primary Club": "Jersey RC",
    County: "",
    Country: "Jersey",
  },
  6556: {
    Title: "Miss",
    Initials: "L",
    Surname: "Hetherington",
    "Primary Club": "CCRS",
    County: "",
    Country: "Wales",
  },
  6557: {
    Title: "Miss",
    Initials: "AE",
    Surname: "Hoile",
    "Primary Club": "Oxford University RC",
    County: "South Wales",
    Country: "Wales",
  },
  6558: {
    Title: "Miss",
    Initials: "LI",
    Surname: "Hughes",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "Wales",
  },
  6559: {
    Title: "Mr",
    Initials: "M",
    Surname: "Hughes",
    "Primary Club": "CCRS",
    County: "",
    Country: "Wales",
  },
  6560: {
    Title: "Mr",
    Initials: "T",
    Surname: "Ishikawa",
    "Primary Club": "MRSA Japan",
    County: "",
    Country: "Japan",
  },
  6561: {
    Title: "Mr",
    Initials: "V",
    Surname: "Jain",
    "Primary Club": "University of Surrey",
    County: "Surrey",
    Country: "England",
  },
  6562: {
    Title: "Mr",
    Initials: "JC",
    Surname: "Jonck",
    "Primary Club": "Wonderboom",
    County: "",
    Country: "South Africa",
  },
  6563: {
    Title: "Sgt",
    Initials: "IC",
    Surname: "Jones",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "England",
  },
  6564: {
    Title: "FS",
    Initials: "JJ",
    Surname: "Joomun",
    "Primary Club": "BCRT",
    County: "",
    Country: "England",
  },
  6565: {
    Title: "Mr",
    Initials: "MEH",
    Surname: "Kapusta",
    "Primary Club": "University of Surrey",
    County: "Surrey",
    Country: "England",
  },
  6566: {
    Title: "Mr",
    Initials: "S",
    Surname: "Kasica",
    "Primary Club": "BCRT",
    County: "",
    Country: "Wales",
  },
  6567: {
    Title: "Mr",
    Initials: "ACN",
    Surname: "Khan",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6568: {
    Title: "Miss",
    Initials: "G",
    Surname: "King",
    "Primary Club": "Wellington College",
    County: "Berkshire",
    Country: "England",
  },
  6569: {
    Title: "Mr",
    Initials: "LA",
    Surname: "Lawrence",
    "Primary Club": "Jersey RC",
    County: "",
    Country: "Jersey",
  },
  6570: {
    Title: "Mr",
    Initials: "NR",
    Surname: "Lewington",
    "Primary Club": "Exeter University RC",
    County: "Suffolk",
    Country: "England",
  },
  6571: {
    Title: "Mr",
    Initials: "WW",
    Surname: "Liu",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "Singapore",
  },
  6572: {
    Title: "Mr",
    Initials: "DG",
    Surname: "Lumley-Wood",
    "Primary Club": "University of Southampton",
    County: "Bedfordshire",
    Country: "England",
  },
  6573: {
    Title: "Miss",
    Initials: "F",
    Surname: "Mahoney",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6574: {
    Title: "Mr",
    Initials: "HM",
    Surname: "Malim",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6575: {
    Title: "Mr",
    Initials: "JD",
    Surname: "Mallabone",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6576: {
    Title: "Miss",
    Initials: "IP",
    Surname: "Martin-Webb",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6577: {
    Title: "Cdt",
    Initials: "TZ",
    Surname: "Maxwell Randeria",
    "Primary Club": "Epsom College",
    County: "London",
    Country: "England",
  },
  6578: {
    Title: "Mr",
    Initials: "TFJ",
    Surname: "McDowell",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "England",
  },
  6579: {
    Title: "Mr",
    Initials: "RM",
    Surname: "McKenna",
    "Primary Club": "BCRT",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6580: {
    Title: "Mr",
    Initials: "CM",
    Surname: "Mckenna",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6581: {
    Title: "Mr",
    Initials: "SJ",
    Surname: "Messenger",
    "Primary Club": "University of Surrey",
    County: "Surrey",
    Country: "England",
  },
  6582: {
    Title: "Miss",
    Initials: "CA",
    Surname: "Mills",
    "Primary Club": "University of Surrey",
    County: "Surrey",
    Country: "England",
  },
  6583: {
    Title: "Miss",
    Initials: "Z",
    Surname: "Moir",
    "Primary Club": "Epsom College",
    County: "Cambridgeshire",
    Country: "England",
  },
  6584: {
    Title: "Mr",
    Initials: "CJ",
    Surname: "Monk",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6585: {
    Title: "Mr",
    Initials: "WFO",
    Surname: "Montgomery",
    "Primary Club": "Elizabeth College",
    County: "Guernsey",
    Country: "Guernsey",
  },
  6586: {
    Title: "Miss",
    Initials: "KC",
    Surname: "Moody",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  6587: {
    Title: "Mr",
    Initials: "W",
    Surname: "Morriss",
    "Primary Club": "Bradfield College",
    County: "London",
    Country: "England",
  },
  6588: {
    Title: "Ms",
    Initials: "I",
    Surname: "Mundia",
    "Primary Club": "Kenya RA",
    County: "",
    Country: "Kenya",
  },
  6589: {
    Title: "Mr",
    Initials: "HD",
    Surname: "O’Connell",
    "Primary Club": "Welsh RA",
    County: "South Wales",
    Country: "Wales",
  },
  6590: {
    Title: "Mr",
    Initials: "P",
    Surname: "Osmond",
    "Primary Club": "Sedbergh School",
    County: "Lincolnshire",
    Country: "England",
  },
  6591: {
    Title: "WO",
    Initials: "SR",
    Surname: "Parker",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6592: {
    Title: "Cdt",
    Initials: "A",
    Surname: "Patel",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6593: {
    Title: "Miss",
    Initials: "SC",
    Surname: "Postins",
    "Primary Club": "Durham University",
    County: "Oxfordshire",
    Country: "England",
  },
  6594: {
    Title: "Mr",
    Initials: "JN",
    Surname: "Prout",
    "Primary Club": "Exeter University RC",
    County: "Berkshire",
    Country: "England",
  },
  6595: {
    Title: "Mr",
    Initials: "OM",
    Surname: "Pryke",
    "Primary Club": "Jersey RA",
    County: "Jersey",
    Country: "Jersey",
  },
  6596: {
    Title: "Mr",
    Initials: "R",
    Surname: "Richards",
    "Primary Club": "UKCRT",
    County: "",
    Country: "England",
  },
  6597: {
    Title: "Mr",
    Initials: "KMR",
    Surname: "Rivoallan",
    "Primary Club": "Victoria College",
    County: "Jersey",
    Country: "Channel Islands",
  },
  6598: {
    Title: "Mr",
    Initials: "LF",
    Surname: "Robson",
    "Primary Club": "Bradfield College",
    County: "Hampshire",
    Country: "England",
  },
  6599: {
    Title: "Miss",
    Initials: "A",
    Surname: "Ross",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6600: {
    Title: "LCpl",
    Initials: "HP",
    Surname: "Ryan",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6601: {
    Title: "LCpl",
    Initials: "JH",
    Surname: "Sale",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "Jersey",
  },
  6602: {
    Title: "Mr",
    Initials: "JJ",
    Surname: "Sauer",
    "Primary Club": "Canada",
    County: "",
    Country: "Canada",
  },
  6603: {
    Title: "Mr",
    Initials: "J",
    Surname: "Scheuerl",
    "Primary Club": "Dollar Academy",
    County: "East of Scotland",
    Country: "Scotland",
  },
  6604: {
    Title: "Mr",
    Initials: "LC",
    Surname: "Schwarz",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "Germany",
  },
  6605: {
    Title: "Cdt",
    Initials: "C",
    Surname: "Sekine",
    "Primary Club": "Sedbergh School",
    County: "Cumbria",
    Country: "England",
  },
  6606: {
    Title: "Mr",
    Initials: "T",
    Surname: "Snaith",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  6607: {
    Title: "Mr",
    Initials: "RP",
    Surname: "Snaith",
    "Primary Club": "Exonia & Tiverton RC",
    County: "Devon",
    Country: "England",
  },
  6608: {
    Title: "Miss",
    Initials: "CJ",
    Surname: "Stirling",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6609: {
    Title: "Cdt",
    Initials: "L",
    Surname: "Tallinn",
    "Primary Club": "Greshams RC",
    County: "Norfolk",
    Country: "Estonia",
  },
  6610: {
    Title: "Miss",
    Initials: "K",
    Surname: "Thomas",
    "Primary Club": "CCRS",
    County: "",
    Country: "Wales",
  },
  6611: {
    Title: "Mr",
    Initials: "KC",
    Surname: "Thorne",
    "Primary Club": "CCRS",
    County: "",
    Country: "England",
  },
  6612: {
    Title: "Mr",
    Initials: "M-Y",
    Surname: "Tsang",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6613: {
    Title: "Mr",
    Initials: "DRM",
    Surname: "Tuke",
    "Primary Club": "Merchiston Castle School",
    County: "",
    Country: "Scotland",
  },
  6614: {
    Title: "Cdt",
    Initials: "JV",
    Surname: "Viney",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6615: {
    Title: "Cdt",
    Initials: "PV",
    Surname: "Viney",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6616: {
    Title: "Cdt",
    Initials: "W",
    Surname: "Viney",
    "Primary Club": "Pates Grammar CCF",
    County: "Gloucestershire",
    Country: "England",
  },
  6617: {
    Title: "Ms",
    Initials: "EL",
    Surname: "Wackan",
    "Primary Club": "Oxford University RC",
    County: "Oxfordshire",
    Country: "England",
  },
  6618: {
    Title: "Cdt",
    Initials: "R",
    Surname: "Wang",
    "Primary Club": "Epsom College",
    County: "London",
    Country: "England",
  },
  6619: {
    Title: "LCpl",
    Initials: "EC",
    Surname: "Wang",
    "Primary Club": "Oundle School",
    County: "Northants - Leics - Rutland",
    Country: "China",
  },
  6620: {
    Title: "Cpl",
    Initials: "RE",
    Surname: "Warden",
    "Primary Club": "Dollar Academy",
    County: "West of Scotland",
    Country: "Scotland",
  },
  6621: {
    Title: "Mr",
    Initials: "KW",
    Surname: "Webb",
    "Primary Club": "Dorking & District RC",
    County: "Surrey",
    Country: "England",
  },
  6622: {
    Title: "Mr",
    Initials: "F",
    Surname: "Weir",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
  6623: {
    Title: "Mr",
    Initials: "A",
    Surname: "Wild",
    "Primary Club": "Tunbridge Wells TSC",
    County: "Kent",
    Country: "England",
  },
  6624: {
    Title: "Mr",
    Initials: "D",
    Surname: "Wild",
    "Primary Club": "Sutton Valence School RC",
    County: "Kent",
    Country: "England",
  },
  6625: {
    Title: "Miss",
    Initials: "JL",
    Surname: "Williams",
    "Primary Club": "Epsom College",
    County: "Surrey",
    Country: "England",
  },
  6626: {
    Title: "Miss",
    Initials: "M",
    Surname: "Williams",
    "Primary Club": "BCRT",
    County: "",
    Country: "Wales",
  },
  6627: {
    Title: "Mr",
    Initials: "p",
    Surname: "Wright",
    "Primary Club": "Staveley",
    County: "Derbyshire",
    Country: "England",
  },
  6628: {
    Title: "Miss",
    Initials: "AR",
    Surname: "Xu",
    "Primary Club": "Cambridge University RA",
    County: "Cambridgeshire",
    Country: "England",
  },
  6629: {
    Title: "Lt Col",
    Initials: "MD",
    Surname: "Yates",
    "Primary Club": "ATSC",
    County: "Oxfordshire",
    Country: "England",
  },
  6630: {
    Title: "Mr",
    Initials: "EJ",
    Surname: "Young",
    "Primary Club": "Southampton University",
    County: "Yorkshire",
    Country: "England",
  },
  6631: {
    Title: "Mr",
    Initials: "A",
    Surname: "Zhang",
    "Primary Club": "RGS Guildford",
    County: "Surrey",
    Country: "England",
  },
};
