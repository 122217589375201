import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const config = {
  apiKey: "AIzaSyDrpr8FqVJZldvEy_2we5fl2STJnjFLZ-g",
  authDomain: "fantasytr-85291.firebaseapp.com",
  projectId: "fantasytr-85291",
  storageBucket: "fantasytr-85291.appspot.com",
  messagingSenderId: "853398967393",
  appId: "1:853398967393:web:5afb50673be36c282f0620",
  measurementId: "G-6KM30J9VB1",
};
const increment = firebase.firestore.FieldValue.increment(1);
class Firebase {
  constructor() {
    firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.functions = firebase.functions();
    this.storage = firebase.storage();
  }

  // *** AUth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () =>
    this.auth.signOut().then(() => {
      window.location.reload(false);
    });

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  //   doSendEmailVerification = () =>
  //     this.auth.currentUser.sendEmailVerification({
  //       url: "https://app.intheoffice.io",
  //     });

  // *** custom user management *** //
  doCreateUserWithEmail = (email) => this.auth.createUser(email);

  currentFirebaseUser = () => this.auth.currentUser;

  currentUserId = (uid) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        return authUser.uid;
      }
    });
  saveNewTeam = (shooters, uid, teamName, name, otp, email, cost) => {
    const batch = this.db.batch();
    const userRef = this.db.doc(`users/${uid}`);
    const teamRef = this.db.collection(`teams`).doc();
    const indTeamRef = this.db.collection(`teams`).doc(`index`);
    const userTeamRef = this.db.doc(`users/${uid}/teams/${teamRef.id}`);
    const previousPoints = 0;
    const previousPointsDate = firebase.firestore.FieldValue.serverTimestamp();
    batch.set(userRef, { email, name, otp }, { merge: true });
    batch.set(
      teamRef,
      {
        shooters,
        uid,
        email,
        teamName,
        name,
        position: null,
        points: 0,
        previousPoints,
        previousPointsDate,
        lowerCaseName: teamName.toLowerCase(),
        cost,
      },
      { merge: true }
    );
    batch.set(
      indTeamRef,
      {
        [teamRef.id]: {
          name,
          teamName,
          position: null,
          points: 0,
          previousPoints,
          previousPointsDate,
          uid,
          cost,
        },
      },
      { merge: true }
    );
    batch.set(
      userTeamRef,
      {
        shooters,
        uid,
        email,
        teamName,
        name,
        position: null,
        points: 0,
        previousPoints,
        previousPointsDate,
        cost,
      },
      { merge: true }
    );
    shooters.map(async (shooter) => {
      const shooterRef = this.db.doc(`shooters/${shooter.trid}`);
      batch.set(
        shooterRef,
        { teams: { [teamRef.id]: teamName }, teamCount: increment },
        { merge: true }
      );
    });
    batch.commit();
  };

  checkName = (teamName) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection("teams")
        .where("lowerCaseName", "==", teamName.toLowerCase()) // Convert name to lowercase
        .get()
        .then((results) => {
          console.log(results, "resultts");
          if (results.empty) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  checkOTP = (OTP) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection("users")
        .where("otp", "==", OTP)
        .get()
        .then((results) => {
          let uid = "";
          if (results.empty) {
            resolve(false);
          } else {
            results.forEach((result) => {
              uid = result.id;
            });

            resolve(uid);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  checkEmail = (email) => {
    return new Promise((resolve, reject) => {
      this.db
        .collection("users")
        .where("email", "==", email)
        .get()
        .then((results) => {
          let uid = "";
          if (results.empty) {
            uid = this.db.collection("users").doc().id;
            console.log(uid, "New UID");
          } else {
            results.forEach((result) => {
              uid = result.id;
            });
            console.log(uid, "USED UID");
          }
          resolve(uid);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPlayer = (email) =>
    this.db.collection("users").where("email", "==", email);

  allTeams = (year) => {
    let dateYear = new Date().getFullYear();
    if (Number(year) < Number(dateYear)) {
      return this.db.doc(`teams/archive/${year}/index`);
    } else {
      return this.db.doc(`teams/index`);
    }
  };
  getTeam = (tid, year) => {
    let dateYear = new Date().getFullYear();
    if (Number(year) < Number(dateYear)) {
      return this.db.doc(`teams/archive/${year}/${tid}`);
    } else {
      return this.db.doc(`teams/${tid}`);
    }
  };
  allUserTeams = (uid) => this.db.collection(`users/${uid}/teams`);
  saveConsent = (profile, form) => {
    const batch = this.db.batch();
    const indexRef = this.db.doc(`shooters/index`);
    const userRef = this.db.doc(`users/${profile.trid}`);
    const shootersRef = this.db.doc(`shooters/${profile.trid}`);
    const dets = {
      shootingGrand: true,
      seed_name: `${profile.title} ${profile.initials} ${profile.surname}`,
      class: form.class,
      email: form.email,
      consent: form.consent,
      club: profile.primaryClub,
      trid: profile.trid,
      country: profile.country,
      county: profile.county,
      seed: profile.seed,
      cost: profile.cost,
    };
    batch.set(userRef, { ...dets }, { merge: true });
    batch.set(
      indexRef,
      {
        [profile.trid]: { ...dets },
      },
      { merge: true }
    );
    batch.set(
      shootersRef,
      {
        ...dets,
      },
      { merge: true }
    );
    batch.commit();
  };
}

export default Firebase;
