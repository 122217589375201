import React, { useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { withFirebase } from "../Firebase";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
} from "@mui/material";
import { FileCopy } from "@mui/icons-material/";
import * as EmailValidator from "email-validator";
import Toast from "../Toast";
import firebase from "firebase/compat/app";
import CircularProgress from "@mui/material/CircularProgress";
import { swearWords } from "../constants/data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function containsSwearWords(text) {
  const regexPattern = new RegExp(swearWords.join("|"), "i");
  return regexPattern.test(text);
}

const TeamConfirmationModal = (props) => {
  const handleClose = () => {
    props.setModalOpen(false);
    setName("");
    setEmail("");
    setTeamName("");
    setUid(false);
    setBankDetails(false);
    setStep(1);
  };
  const [showToast, setShowToast] = useState(false);
  const [name, setName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [uid, setUid] = useState(false);
  const [error, setError] = useState({ teamName: "", name: "", email: "" });
  const [bankDetails, setBankDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const nameErrMsg = "Please enter a team name.";
  const nameTakenMsg =
    "Oops, it looks like that team name is already taken. Please try a different name.";
  const emailErrMsg = "Please enter a valid email.";
  const [step, setStep] = useState(1);

  function delayByOneSecond(callback) {
    setTimeout(() => {
      // Your code to be executed after the delay
      callback(); // Call the callback function
    }, 1000); // 1000 milliseconds = 1 second
  }
  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }
  async function saveTeam() {
    let nameOk = false;
    let swear = false;
    console.log(teamName, "teamName");
    if (teamName === "" || teamName === " ") {
      console.log("<<<<<<");
      setError({ ...error, teamName: nameErrMsg });
      // delayByOneSecond(() => {
      //   setLoading(false);
      // });
    }
    console.log(name, "name");
    if (name === "") {
      setError({ ...error, name: "Please enter your name" });
      // delayByOneSecond(() => {
      //   setLoading(false);
      // });
    }
    if (teamName !== "" && teamName !== " ") {
      const checkedName = await props.firebase.checkName(teamName);
      nameOk = checkedName;

      if (!checkedName) {
        setError({ ...error, teamName: nameTakenMsg });
        // delayByOneSecond(() => {
        //   setLoading(false);
        // });
      }
      swear = containsSwearWords(teamName.toLowerCase());
      console.log(swear);
      if (swear) {
        setError({ ...error, teamName: "Please use nice words" });
        // delayByOneSecond(() => {
        //   setLoading(false);
        // });
      }
    }

    if (name !== "" && nameOk && !swear) {
      setLoading(true);
      let otp = generateRandomString();
      setError({ name: "", email: "", teamName: "" });
      let shooters = [];
      Object.entries(props.pickedTeam).map(([key, value]) => {
        shooters.push({ name: value.seed_name, trid: value.trid });
      });
      var emailSend = firebase.functions().httpsCallable("emails-customEmail");
      let data = {
        subject: "Congratulations on picking your Fantasy Fullbore team",
        email: email,
        paragraphs: [
          `Hi ${name}`,
          `Thank you for picking your team: ${teamName}`,
          `By taking part in Fantasy Fullbore, you are helping the NRA Channel Islands Team 2025.`,
          `In order to complete your entry, you need to transfer your £10 per team entry fee to us.`,
          `Account Name: GB Palma SA 2023`,
          `(Palma team are kindly allowing the use of the bank account whilst the NRA team set something up!)`,
          `Account Number: 50878979`,
          `Sort code: 20-96-89`,

          `Reference: "FF${getLastChunk(name)}
          ${name.slice(0, 1)}-${teamName.slice(0, 5)}"`,
          `Reference format: "FF[SurnameFirstInitial]-[first five characters of team name]"`,

          `Your selected team: `,
        ],
      };
      shooters.map((shooter) => {
        data.paragraphs.push(` - ${shooter.name}`);
      });
      data.paragraphs.push(
        `Thanks so much for your support`,
        `The Fantasy Fullbore Team`
      );

      data.paragraphs.push(
        ``,
        `Want to make edits? Click on "View your Profile" below, and enter this one time password.`,
        `${otp}`,
        `Please note this will change each time you create a team or log in.`
      );

      try {
        //all below was commented out. needs to be there for the team save
        await props.firebase.saveNewTeam(
          shooters,
          uid,
          teamName,
          name,
          otp,
          email.toLowerCase(),
          props.totalCost
        );
        data.actions = {
          0: {
            type: "outlined",
            title: "View your Profile",
            url: `https://FantasyFullbore.co.uk/profile?uid=${uid}`,
          },
          1: {
            type: "outlined",
            title: "Check out other teams",
            url: `https://FantasyFullbore.co.uk/teams`,
          },
        };
        await emailSend(data);
        delayByOneSecond(() => {
          props.setPickedTeam([]);
          const dataToStore = JSON.stringify({
            ...props.localData,
            teamPicking: [],
          });
          props.setLocalData({ ...props.localData, teamPicking: [] });
          localStorage.setItem("fantasyFullbore", dataToStore);
          console.log(data, "DATA");
          setLoading(false);
          setBankDetails(true);
          setShowToast(true);
          setStep(1);
        });
      } catch (error) {
        console.warn(error, "Error saving team");
      }
    }
  }

  async function checkEmail() {
    var emailValid = EmailValidator.validate(email);
    if (!emailValid) {
      setError({ ...error, email: emailErrMsg });
    } else {
      const uidFromDB = await props.firebase.checkEmail(email.toLowerCase());
      setUid(uidFromDB);
      const player = await props.firebase.db
        .collection(`users`)
        .where("email", "==", email.toLowerCase())
        .get();
      console.log(player);
      if (!player.empty) {
        player.forEach((thisPlayer) => {
          console.log(thisPlayer.data());
          setName(thisPlayer.data().name);
        });
      } else {
        setName("");
      }

      setError({ ...error, email: "" });
      setStep(2);
    }
  }
  const stepBack = () => {
    setStep(1);
  };

  const handleClick = () => {
    // setShowToast(true);
  };

  const handleToastClose = () => {
    setShowToast(false);
  };
  function getLastChunk(string) {
    const chunks = string.split(" ");
    return chunks[chunks.length - 1];
  }
  return (
    <div>
      {showToast && (
        <Toast
          message="Thank you for picking your team. Why not double your odds by picking another?"
          onClose={handleToastClose}
        />
      )}
      {props.modalOpen && (
        <Modal
          open={props.modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {!bankDetails ? (
              <Box>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Your Team
                </Typography>
                <Box>
                  <List>
                    {props.pickedTeam.map((member) => (
                      <ListItem dense sx={{ mt: 0, mb: 0 }} key={member.trid}>
                        <ListItemText primary={member.seed_name} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider />
                  <Typography variant="body1" paragraph sx={{ mt: 2, ml: 2 }}>
                    Total Cost: £{props.totalCost.toLocaleString()}
                  </Typography>
                  {step === 1 && (
                    <Box>
                      <Typography>Enter your email address</Typography>
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            border: "1px solid #999999",
                          },
                          mb: 2,
                        }}
                        label="Email address"
                        id="outlined-size-small"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setError({ ...error, email: "" });
                        }}
                        size="small"
                        fullWidth
                        error={error.email !== "" ? true : false}
                        helperText={error.email}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          m: 2,
                        }}
                      >
                        <Box>
                          <Button
                            variant="contained"
                            onClick={() => checkEmail()}
                          >
                            Continue
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {step === 2 && (
                    <Box>
                      {!loading ? (
                        <Box>
                          {console.log(error)}
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid #999999",
                              },
                              mb: 2,
                            }}
                            label="Enter team name"
                            id="outlined-size-small"
                            value={teamName}
                            onChange={(event) => {
                              setTeamName(event.target.value);
                              setError({ ...error, teamName: "" });
                            }}
                            size="small"
                            fullWidth
                            error={error.teamName === "" ? false : true}
                            helperText={error.teamName}
                          />

                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                border: "1px solid #999999",
                              },
                              mb: 2,
                            }}
                            label="Enter your name"
                            id="outlined-size-small"
                            value={name}
                            onChange={(event) => {
                              setName(event.target.value);
                              setError({ ...error, name: "" });
                            }}
                            size="small"
                            fullWidth
                            error={error.name === "" ? false : true}
                            helperText={error.name}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              m: 2,
                            }}
                          >
                            <Box>
                              <Button
                                variant="contained"
                                onClick={() => saveTeam()}
                              >
                                Play for £10
                              </Button>
                            </Box>
                            <Box>
                              <Button onClick={() => stepBack()}>Back</Button>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress color="primary" />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  Thank you
                </Typography>
                <Box>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    Your team has been saved. Please complete your payment of
                    £10 to these bank details:
                  </Typography>
                  <Box sx={{ m: 2, border: "1px solid black" }}>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      GB Palma SA 2023{" "}
                      <IconButton
                        onClick={() =>
                          navigator.clipboard.writeText("GB Palma SA 2023")
                        }
                      >
                        <FileCopy />
                      </IconButton>
                    </Typography>
                    <Typography variant="caption">
                      (Palma team are kindly allowing the use of the bank
                      account whilst the NRA team set something up!)
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      50878979{" "}
                      <IconButton
                        onClick={() =>
                          navigator.clipboard.writeText("50878979")
                        }
                      >
                        <FileCopy />
                      </IconButton>
                    </Typography>

                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      20-96-89{" "}
                      <IconButton
                        onClick={() =>
                          navigator.clipboard.writeText("20-96-89")
                        }
                      >
                        <FileCopy />
                      </IconButton>
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    Please use reference:
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    "FF{getLastChunk(name)}
                    {name.slice(0, 1)}-{teamName.slice(0, 5)}"
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ display: "block", textAlign: "center" }}
                  >
                    Reference format should be: <br />
                    FF[SurnameFirstInitial]-[first five characters of team name]
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    m: 2,
                  }}
                >
                  <Box sx={{ mb: 1 }}>
                    <Button fullWidth onClick={() => handleClose()}>
                      Done
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClose()}
                      fullWidth
                    >
                      Why not double your chances and pick another team?
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default withFirebase(TeamConfirmationModal);
