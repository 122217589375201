import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import {
  AccordionDetails,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

const TeamAccordion = (props) => {
  const [team, setTeam] = useState(false);
  useEffect(() => {
    if (props.tid) {
      const unsubscribe = props.firebase
        .getTeam(props.tid, props.year)
        .onSnapshot((result) => {
          if (result.exists) {
            setTeam({ tid: result.id, ...result.data() });
          }
        });
      return () => unsubscribe();
    }
  }, [props.tid, props.firebase]);
  return (
    <List>
      {team &&
        team.shooters.map((shooter) => (
          <ListItem key={shooter.trid} sx={{ padding: 0 }}>
            <Box sx={{ width: "30%" }}>{shooter.name}</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "70%",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ flex: 1 }}>
                {shooter.Day_1_Total ? shooter.Day_1_Total : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {shooter.Day_2_Total ? shooter.Day_2_Total : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {shooter.Day_3_Total ? shooter.Day_3_Total : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {shooter.Day_4_Total ? shooter.Day_4_Total : 0}
              </Box>
              <Box sx={{ flex: 1 }}>
                {shooter.Day_5_Total ? shooter.Day_5_Total : 0}
              </Box>

              <Box sx={{ flex: 1 }}>
                <Typography>
                  ({shooter.GRAND_MULTIPLIER ? shooter.GRAND_MULTIPLIER : 0})
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                {shooter.TotalPoints ? shooter.TotalPoints : 0}
              </Box>

              <Box sx={{ flex: 1 }}>-</Box>
              <Box sx={{ flex: 1 }}>-</Box>
            </Box>
          </ListItem>
        ))}
    </List>
  );
};

export default withFirebase(TeamAccordion);
