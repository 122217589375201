import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Button, Stack } from "@mui/material";
import ExcelReader from "../ExcelUploader";
import PDFReader from "../PDFReader";
import CsvLoader from "./Setup/csvLoader";
import CsvToJsonConverter from "./Setup/csvToJsonConverter";
import { withFirebase } from "../Firebase";
import firebase from "firebase/compat/app";
import PasswordPopup from "./passwordPopup";
import AdminNav from "./AdminNav";
import Dashboard from "./dashboard";

const Admin = (props) => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  useEffect(() => {
    const retrieveFromSessionStorage = () => {
      const storedData = sessionStorage.getItem("fantasyFullboreLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData.adminLogin) {
          setIsPasswordCorrect(true);
        }
      } else {
        setIsPasswordCorrect(false);
      }
    };
    retrieveFromSessionStorage();
  }, []);

  const handlePasswordSubmit = (password) => {
    // Check if the entered password is correct
    if (password === "Cr@wfurd") {
      setIsPasswordCorrect(true);
      const dataToStore = JSON.stringify({ adminLogin: true });
      sessionStorage.setItem("fantasyFullboreLogin", dataToStore);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isPasswordCorrect && (
        <PasswordPopup onPasswordSubmit={handlePasswordSubmit} />
      )}
      {isPasswordCorrect && (
        <Box sx={{ mt: 10 }}>
          <AdminNav />
          <Dashboard />
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(Admin);
