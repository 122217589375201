import React from "react";
import { Button, Stack } from "@mui/material";
import * as ROUTES from "../../constants/routes";

const AdminNav = (props) => {
  return (
    <Stack direction="row" spacing={2}>
      <Button href={ROUTES.ADMIN}>Admin Dashboard</Button>
      <Button href={ROUTES.ADMINTEAMS}>Manage Teams</Button>
      <Button href={ROUTES.ADMINSHOOTERS}>Manage Shooters</Button>
      <Button href={ROUTES.ADMINSETUP}>Setup Functions</Button>
      <Button href={ROUTES.ADMINFUNCTIONS}>ADMIN Functions</Button>
      <Button href={ROUTES.ADMINMIGRATIONS}>ADMIN MIGRATIONS</Button>
    </Stack>
  );
};

export default AdminNav;
