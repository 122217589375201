import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { withFirebase } from "../Firebase";
import TridLookup from "./tridLookup";
import ConfirmProfile from "./confirmProfile";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import NunitoVariableFont from "../Fonts/Nunito-VariableFont_wght.ttf";
import LOGO from "../static/images/logo.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75vw",
  height: "75vh",
  bgcolor: "background.paper",
  p: 4,
  overflow: "scroll",
  pt: "90px",
};

const EnteringGrand = (props) => {
  const [stage, setStage] = useState(1);
  const [shooterProfile, setShooterProfile] = useState(false);
  const [selectedResult, setSelectedResult] = useState("");
  const handleOpen = () => props.setOpen(true);
  const handleClose = () => {
    props.setOpen(false);
    setStage(1);
    const dataToStore = JSON.stringify({ firstPopup: true });
    localStorage.setItem("fantasyFullbore", dataToStore);
  };

  const saveToLocalStorage = () => {
    const dataToStore = JSON.stringify({ firstPopup: true });
    localStorage.setItem("fantasyFullbore", dataToStore);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <AppBar position="fixed" sx={{ backgroundColor: "#DCDBE0" }}>
          <Toolbar>
            <img
              src={LOGO}
              width="120px"
              style={{ marginTop: "8px", marginLeft: "4px" }}
            />
          </Toolbar>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            onClick={handleClose}
          >
            <Close color="primary" />
          </IconButton>
        </AppBar>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            m: "0 auto",
          }}
        >
          {stage === 1 ? (
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h1"
                paragraph
              >
                Welcome to Fantasy Fullbore
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
              >
                Are you entering the Grand?
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "300px" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ mt: 2 }}
                    fullWidth
                    onClick={() => setStage(2)}
                  >
                    Yes
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ mt: 2 }}
                    fullWidth
                    onClick={() => {
                      handleClose();
                      saveToLocalStorage();
                    }}
                  >
                    No
                  </Button>
                  {/* <Button
                    color="secondary"
                    variant="outlined"
                    sx={{ mt: 2 }}
                    fullWidth
                    onClick={handleClose}
                  >
                    I am not a shooter
                  </Button> */}
                </Box>
              </Box>
            </Box>
          ) : stage === 2 ? (
            <Box sx={{}}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Please find your TRID below and check your details so you can be
                included in Fantasy Fullbore
              </Typography>
              <TridLookup
                setStage={setStage}
                shooterProfile={shooterProfile}
                setShooterProfile={setShooterProfile}
              />
            </Box>
          ) : stage === 3 ? (
            <ConfirmProfile
              setStage={setStage}
              shooterProfile={shooterProfile}
              setShooterProfile={setShooterProfile}
            />
          ) : (
            <React.Fragment>
              <Typography>Thanks</Typography>
              <Button onClick={handleClose}>Close</Button>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default withFirebase(EnteringGrand);
