import React, { useState } from 'react';
import { Button, Box } from '@material-ui/core';
import * as XLSX from 'xlsx';

function ExcelReader() {
  const [data, setData] = useState([]);

  const readExcelFile = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = XLSX.read(bufferArray, { type: 'buffer' });

        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];

        const data = XLSX.utils.sheet_to_json(worksheet, {header: 1});

        const headers = data[0].map((header) => header.toLowerCase());
        const rows = data.slice(1);

        const newData = rows.map((row) =>
          headers.reduce(
            (acc, header, index) => ({
              ...acc,
              [header]: row[index],
            }),
            {}
          )
        );

        resolve(newData);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      setData(data);
    });
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    readExcelFile(file);
  };

  const handleCopy = () => {
    const jsonData = JSON.stringify(data, null, 2);
    navigator.clipboard.writeText(jsonData);
  };

  return (
    <div>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={handleUpload} accept=".xlsx" />
          </Button>
          <Button variant="contained" onClick={handleCopy}>
            Copy JSON
          </Button>
      </Box>

      {data.length > 0 &&
        data.map((row, index) => (
          <div key={index}>
            {Object.keys(row).map((key) => (
              <span key={key}>
                {key}: {row[key]}
              </span>
            ))}
            <br />
          </div>
        ))}
    </div>
  );
}

export default ExcelReader;
