import Instructions from "./instructions";
import { Box } from "@mui/material";

function HowToPlay() {
  return (
    <Box
      sx={{
        height: "calc(100vh - 64px)",
        paddingTop: "64px",
        backgroundColor: "#f3f2f1",
        width: "100vw",
        overflow: "scroll",
      }}
    >
      <Instructions />
    </Box>
  );
}

export default HowToPlay;
