import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
// import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import * as ROUTES from "../constants/routes";
import HelpIcon from "@mui/icons-material/Help";
import LOGO from "../static/images/fantasyfullbore.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import OTPModal from "../Profile/otpModal";

// const useStyles = makeStyles({
//   customFont: {
//     fontFamily: "Nunito Variable, sans-serif",
//     fontWeight: "bold",
//   },
// });
const NavigationHeader = (props) => {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [globalUid, setGlobalUid] = useState(props.globalUid);

  useEffect(() => {
    setGlobalUid(props.globalUid);
  }, [props.globalUid]);
  const isMenuOpen = Boolean(anchorEl);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    // const dataToStore = JSON.stringify({ profileLogin: false });
    // localStorage.setItem("fantasyFullboreLogin", dataToStore);
    localStorage.removeItem("fantasyFullboreLogin");
    props.setGlobalUid(false);
    handleMenuClose();
    if (window.location.pathname == "/profile") {
      window.location.href = "/";
    }
  };
  const menuId = "profile-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {globalUid ? (
        <List dense>
          <ListItemButton href={`${ROUTES.PROFILE}/?uid=${globalUid}`}>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton>
            <ListItemText onClick={() => logout()} primary="Logout" />
          </ListItemButton>
        </List>
      ) : (
        <List dense>
          <ListItemButton>
            <ListItemText
              onClick={() => {
                handleMenuClose();
                setOtpModalOpen(true);
              }}
              primary="Request Login Link"
            />
          </ListItemButton>
        </List>
      )}
    </Menu>
  );
  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton href={ROUTES.DASHBOARD}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton href={ROUTES.HOWTOPLAY}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary="How To Play" />
        </ListItemButton>
        <ListItemButton href={ROUTES.PICK}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Team Selection" />
        </ListItemButton>
        <ListItemButton href={ROUTES.TEAMS}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Teams" />
        </ListItemButton>
        <ListItemButton href={ROUTES.ADMIN}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: "#DCDBE0" }}>
        <OTPModal
          otpModalOpen={otpModalOpen}
          setOtpModalOpen={setOtpModalOpen}
        />
        <Toolbar>
          {/* sx={{position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1}}> */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <Typography
            variant="h6"
            component="span"
            // sx={{ fontFamily: NunitoVariableFont }}
          >
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href={ROUTES.DASHBOARD}
            >
              <img
                src={LOGO}
                width="120px"
                style={{ marginTop: "8px", marginLeft: "4px" }}
              />
            </a>
          </Typography>
          <div style={{ flexGrow: 1 }}></div> {/* Added div for spacing */}
          <IconButton
            color="inherit"
            aria-label="profile"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircleIcon color="primary" />
          </IconButton>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
      {renderMenu}
    </div>
  );
};

export default NavigationHeader;
