import React, { useState } from "react";

const CsvToJsonConverter = () => {
  const [jsonObject, setJsonObject] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csv = e.target.result;
      const lines = csv.split("\n");
      const headers = lines[0].split(",");

      const result = [];
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
          let value = currentLine[j] || ""; // Assign empty string for undefined values

          if (value) {
            value = value.trim(); // Trim whitespace if value is defined
          }

          const sanitizedValue = value.replace(/\r/g, ""); // Remove special characters
          const sanitizedKey = headers[j].replace(/\r/g, ""); // Remove special characters from headers

          obj[sanitizedKey] = sanitizedValue;
        }

        result.push(obj);
      }

      setJsonObject(result);
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      {jsonObject && <pre>{JSON.stringify(jsonObject, null, 2)}</pre>}
    </div>
  );
};

export default CsvToJsonConverter;
