import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Button, Stack } from "@mui/material";
import { withFirebase } from "../Firebase";
import firebase from "firebase/compat/app";
import PasswordPopup from "./passwordPopup";
import MyTeams from "./myTeams";
import MyProfile from "./myProfile";
import ProfileEditTeam from "./profileEditTeam";
const url = new URL(window.location.href);
const searchParams = url.searchParams;
let profileUid = searchParams.get("uid");
console.log(profileUid);
const Profile = (props) => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [teams, setTeams] = useState(false);
  const [tid, setTid] = useState(false);
  const [editTeam, setEditTeam] = useState(false);
  useEffect(() => {
    const retrieveFromSessionStorage = () => {
      console.log(profileUid);
      const storedData = localStorage.getItem("fantasyFullboreLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        console.log(profileUid, parsedData.profileLogin);
        if (profileUid === null) {
          url.searchParams.append("uid", parsedData.profileLogin);
          window.location.href = url.href;
        }
        if (parsedData.profileLogin === profileUid) {
          setIsPasswordCorrect(true);
        }
      } else {
        setIsPasswordCorrect(false);
      }
    };
    retrieveFromSessionStorage();
  }, []);

  const handlePasswordSubmit = async (password) => {
    // Check if the entered password is correct
    let returnedUid = await props.firebase.checkOTP(password);
    console.log(returnedUid);
    if (returnedUid === profileUid) {
      setIsPasswordCorrect(true);
      const dataToStore = JSON.stringify({ profileLogin: profileUid });
      localStorage.setItem("fantasyFullboreLogin", dataToStore);
      await props.firebase.db
        .doc(`users/${returnedUid}`)
        .update({ otp: false });
      props.setGlobalUid(returnedUid);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isPasswordCorrect && (
        <Box>
          {profileUid === null ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              Please click on the link in your One Time Password Email
            </Box>
          ) : (
            <PasswordPopup onPasswordSubmit={handlePasswordSubmit} />
          )}
        </Box>
      )}
      {isPasswordCorrect && (
        <Box sx={{ mt: 10 }}>
          {!editTeam ? (
            <Box>
              <MyProfile teams={teams} setTeams={setTeams} uid={profileUid} />
              <MyTeams
                teams={teams}
                setTeams={setTeams}
                uid={profileUid}
                setEditTeam={setEditTeam}
                setTid={setTid}
              />
            </Box>
          ) : (
            <ProfileEditTeam
              tid={tid}
              setTid={setTid}
              setEditTeam={setEditTeam}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(Profile);
