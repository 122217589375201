import React, { useState, useEffect } from "react";
import { withFirebase } from "../../Firebase";
import firebase from "firebase/compat/app";
import { Box, Button, CircularProgress, TextField } from "@mui/material";

const TeamsMigration = (props) => {
  const [year, setYear] = useState("");
  const [loading, setLoading] = useState(false);

  async function doMigration() {
    setLoading(true);
    if (year === "") {
      alert("Please enter last year");
      return;
    } else {
      const collectionData = await props.firebase.db
        .collection(`teams`)
        // .limit(1)
        .get()

        .then((results) => {
          let data = {};
          if (!results.empty) {
            results.forEach((result) => {
              if (result.id !== "archive") {
                data[result.id] = result.data();
              }
            });
          }
          return data;
        });
      if (Object.keys(collectionData).length >= 0) {
        const batch = props.firebase.db.batch();
        Object.entries(collectionData).forEach(([key, value]) => {
          //Save all teams into the archive doument under the last year collection
          // const archiveRef = props.firebase.db.doc(
          // `teams/archive/${year}/${key}`
          // );
          // batch.set(archiveRef, value);
          //Cleanup the live teams collection
          // const ref = props.firebase.db.doc(`teams/${key}`);
          // console.log(key, "key");
          // batch.delete(ref);
        });
        try {
          await batch.commit();
          setLoading(false);
        } catch (error) {
          console.warn("ERROR saving teams to archive", error);
          setLoading(false);
        }
      }
    }
  }
  async function doShooterMigration() {
    setLoading(true);
    if (year === "") {
      alert("Please enter last year");
      return;
    } else {
      const collectionData = await props.firebase.db
        .collection(`shooters`)
        .get()
        .then((results) => {
          let data = [];
          if (!results.empty) {
            results.forEach((result) => {
              if (result.id !== "index") {
                let obj = {};
                if (
                  result.data().teamCount !== undefined &&
                  result.data().teamCount > 0
                ) {
                  obj.teamCount = result.data().teamCount;
                  obj.teams = result.data().teams;
                }
                if (result.data().currentYear !== undefined) {
                  obj.results = result.data()[`currentYear`];
                }
                if (Object.keys(obj).length >= 1) {
                  obj.id = result.id;
                  data.push({ id: result.id, ...obj });
                }
              }
            });
          }
          return data;
        });
      const chunkSize = 200;
      if (collectionData.length > 0) {
        for (let i = 0; i < collectionData.length; i += chunkSize) {
          const chunk = collectionData.slice(i, i + chunkSize);
          const batch = props.firebase.db.batch();
          console.log(chunk);
          chunk.forEach((value) => {
            const ref = props.firebase.db.doc(
              `shooters/${value.id}/archive/${year}`
            );
            batch.set(ref, value, { merge: true });
            const shooterRef = props.firebase.db.doc(`shooters/${value.id}`); //delete the teams collection
            batch.set(
              shooterRef,
              {
                teams: firebase.firestore.FieldValue.delete(),
                teamCount: 0,
                currentYear: firebase.firestore.FieldValue.delete(),
              },
              { merge: true }
            );
          });
          try {
            await batch.commit();
            setLoading(false);
          } catch (error) {
            console.warn("ERROR saving shooters to archive", error);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }

      // both migrations have been done. Just users still to go.
    }
  }

  async function doUserMigration() {
    setLoading(true);
    let users = await props.firebase.db
      .collection(`users`)
      // .limit(5)
      .get()
      .then((results) => {
        let data = [];
        if (!results.empty) {
          results.forEach((result) => {
            data.push(result.id);
          });
        }
        return data;
      });
    const usersToSave = await processUsers(users);
    const chunkSize = 1;
    for (let i = 0; i < usersToSave.length; i += chunkSize) {
      const chunk = usersToSave.slice(i, i + chunkSize);
      const batch = props.firebase.db.batch();
      console.log(chunk);
      chunk.forEach((teamItem) => {
        Object.entries(teamItem).forEach(([key, value]) => {
          //save the teams collection into the last year collection
          const ref = props.firebase.db.doc(
            `users/${value.uid}/${year}/${key}`
          );
          batch.set(ref, value, { merge: true });
          console.log(key, "key");
          console.log(value, "value");

          //cleanup the previous teams collection
          // const ref = props.firebase.db.doc(`users/${value.uid}/teams/${key}`);
          // batch.delete(ref);
          // console.log(key, "key");
          // console.log(value, "value");
        });
      });
      //   // const ref = props.firebase.db.doc(
      //   // `users/${value.uid}/${year}/${value.id}`
      //   // );
      //   // console.log(`${value.uid}/${year}/${value.id}`, "<<<<");
      //   // batch.set(ref, value, { merge: true });
      // });
      try {
        await batch.commit();
      } catch (error) {
        console.warn("ERROR saving users to archive", error);
      }
    }
    setLoading(false);
  }
  async function processUsers(users) {
    let usersToSave = [];
    for (let i = 0; i < users.length; i++) {
      let user = await props.firebase.db
        .collection(`users/${users[i]}/teams`)
        .get()
        .then((results) => {
          if (!results.empty) {
            console.log(users[i]);
            let data = {};
            results.forEach((result) => {
              data[result.id] = result.data();
            });
            return data;
          } else {
            return null;
          }
        });
      if (user !== null) {
        usersToSave.push(user);
      }
    }
    return usersToSave;
  }

  async function resetConsent() {
    const users = await props.firebase.db
      .collection(`shooters`)
      .get()
      .then((results) => {
        let data = [];
        if (!results.empty) {
          results.forEach((result) => {
            data.push(result.id);
          });
        }
        return data;
      });
    const chunkSize = 100;
    for (let i = 0; i < users.length; i += chunkSize) {
      const chunk = users.slice(i, i + chunkSize);
      const batch = props.firebase.db.batch();
      chunk.forEach((uid) => {
        const ref = props.firebase.db.doc(`shooters/${uid}`);
        batch.set(
          ref,
          {
            consent: false,
            shootingGrand: false,
          },
          { merge: true }
        );
      });
      try {
        await batch.commit();
      } catch (error) {
        console.warn("ERROR saving users to archive", error);
      }
    }
  }
  return (
    <Box>
      {loading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mt: 2 }}>
              <TextField
                value={year}
                label="Last Year"
                onChange={(e) => setYear(e.target.value)}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              {/* <Button
                variant="outlined"
                disabled={year == "" ? true : false}
                onClick={() => doMigration()}
              >
                RUN TEAMS MIGRATION
              </Button> */}
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            {/* <Button
              variant="outlined"
              disabled={year == "" ? true : false}
              onClick={() => doShooterMigration()}
            >
              RUN Shooter MIGRATION
            </Button> */}
          </Box>
          <Box sx={{ mt: 2 }}>
            {/* <Button
              variant="outlined"
              disabled={year == "" ? true : false}
              onClick={() => doUserMigration()}
            >
              USER TEMAS MIGRATION
            </Button> */}
          </Box>
          <Box>
            <Button onClick={() => resetConsent()}>Reset Consent</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(TeamsMigration);
