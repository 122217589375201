import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import firebase from "firebase/compat/app";
import { withFirebase } from "../../Firebase";
import AdminNav from "../AdminNav";
import PasswordPopup from "../passwordPopup";

const AdminTeams = (props) => {
  const [teams, setTeams] = useState(false);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    const retrieveFromSessionStorage = () => {
      const storedData = sessionStorage.getItem("fantasyFullboreLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        if (parsedData.adminLogin) {
          setIsPasswordCorrect(true);
        }
      } else {
        setIsPasswordCorrect(false);
      }
    };
    retrieveFromSessionStorage();
  }, []);

  const handlePasswordSubmit = (password) => {
    // Check if the entered password is correct
    if (password === "Cr@wfurd") {
      setIsPasswordCorrect(true);
      const dataToStore = JSON.stringify({ adminLogin: true });
      sessionStorage.setItem("fantasyFullboreLogin", dataToStore);
    } else {
      alert("Incorrect password. Please try again.");
    }
  };
  useEffect(() => {
    if (!focus) {
      const unsubscribe = props.firebase.allTeams().onSnapshot((results) => {
        console.log(results);
        if (results.exists) {
          let loadedTeams = [];

          Object.entries(results.data()).map(([key, value]) => {
            let reconciled = false;
            if (value.reconciled) {
              reconciled = true;
            }
            loadedTeams.push({ tid: key, ...value, reconciled });
          });
          loadedTeams.sort((a, b) => {
            if (a.teamName === "" && b.teamName === "") {
              return 0; // no change in order for empty team names
            } else if (a.teamName === "") {
              return 1; // a comes after b if a has an empty team name
            } else if (b.teamName === "") {
              return -1; // a comes before b if b has an empty team name
            } else {
              return a.teamName.localeCompare(b.teamName); // sort by team name
            }
          });
          console.log(loadedTeams);
          setTeams(loadedTeams);
        }
      });
      return () => unsubscribe();
    } else {
      const unsubscribe = props.firebase
        .allUserTeams(focus)
        .onSnapshot((results) => {
          console.log(results);
          let loadedTeams = [];
          if (!results.empty) {
            let teams = {};
            results.forEach((result) => {
              teams[result.id] = { ...result.data() };
            });

            Object.entries(teams).map(([key, value]) => {
              loadedTeams.push({ tid: key, ...value });
            });
            loadedTeams.sort((a, b) => a.teamName.localeCompare(b.teamName));
          }
          console.log(loadedTeams);
          setTeams(loadedTeams);
        });
      return () => unsubscribe();
    }
  }, [focus]);

  async function deleteTeam(tid, uid) {
    const team = await props.firebase.getTeam(tid).get();
    console.log(team.data());
    console.log(tid);
    const batch = props.firebase.db.batch();
    const teamIndex = props.firebase.db.doc(`teams/index`);
    const teamRef = props.firebase.db.doc(`teams/${tid}`);
    const userRef = props.firebase.db.doc(`users/${uid}/teams/${tid}`);
    batch.set(
      teamIndex,
      { [tid]: firebase.firestore.FieldValue.delete() },
      { merge: true }
    );
    batch.delete(teamRef);
    batch.delete(userRef);
    team.data().shooters.map((shooter) => {
      const shooterRef = props.firebase.db.doc(`shooters/${shooter.trid}`);
      batch.set(
        shooterRef,
        {
          teamCount: firebase.firestore.FieldValue.increment(-1),
          teams: { [tid]: firebase.firestore.FieldValue.delete() },
        },
        { merge: true }
      );
    });
    try {
      batch.commit();
    } catch (error) {
      console.warn(error, "Error deleting team");
    }
  }

  async function toggleReconcile(tid, uid, reconciled) {
    console.log(tid, reconciled);
    const batch = props.firebase.db.batch();
    const teamIndex = props.firebase.db.doc(`teams/index`);
    const teamRef = props.firebase.db.doc(`teams/${tid}`);
    const userRef = props.firebase.db.doc(`users/${uid}/teams/${tid}`);
    batch.set(
      teamIndex,
      {
        [tid]: {
          reconciled: !reconciled,
        },
      },
      { merge: true }
    );
    batch.set(teamRef, { reconciled: !reconciled }, { merge: true });
    batch.set(userRef, { reconciled: !reconciled }, { merge: true });
    try {
      batch.commit();
    } catch (error) {
      console.warn(error, "Error deleting team");
    }
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isPasswordCorrect && (
        <PasswordPopup onPasswordSubmit={handlePasswordSubmit} />
      )}
      {isPasswordCorrect && (
        <Box sx={{ mt: 10 }}>
          <AdminNav />

          <Typography variant="h4">Manage Teams</Typography>
          {focus && (
            <Box>
              <Typography>Currently Filtered by uid/trid {focus}</Typography>
              <Button onClick={() => setFocus(false)}>Remove Filter</Button>
            </Box>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams &&
                teams.map((team) => (
                  <TableRow key={team.tid}>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>{team.teamName}</Typography>
                        <Typography onClick={() => setFocus(team.uid)}>
                          {team.name}/{team.uid}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={true}
                        onClick={() => deleteTeam(team.tid, team.uid)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        disabled={false}
                        onClick={() =>
                          toggleReconcile(team.tid, team.uid, team.reconciled)
                        }
                      >
                        {team.reconciled ? "Unreconcile" : "Reconcile"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default withFirebase(AdminTeams);
